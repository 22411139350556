import React, { Fragment } from "react";
import { TextField, FormControl } from "@mui/material";
import InputTextStyles from "./InputText.styles";
import TitleComponent from "../TitleComponent";

function InputText({
  label,
  value,
  name,
  handleChange,
  errors,
  touched,
  handleBlur,
  stylesInput,
  centeredLabel,
  InputProps,
  fullWidth,
  insideLabel,
  disabled,
  placeholder,
  labelStyle,
  noLabel = false,
}) {
  const classes = InputTextStyles();
  return (
    <Fragment>
      {label && (
        <FormControl
          className={
            stylesInput
              ? `${stylesInput} ${classes.formContainer}`
              : classes.formContainer
          }
        >
          <TitleComponent
            text={label}
            disabled={disabled}
            center={centeredLabel ? centeredLabel : false}
            titleStyle={labelStyle}
          />
          <TextField
            className={stylesInput ? stylesInput : classes.inputSize}
            color={touched && !errors ? "success" : "primary"}
            size="small"
            name={name}
            value={value}
            onChange={handleChange}
            error={touched && Boolean(errors)}
            helperText={touched && errors && errors}
            onBlur={handleBlur}
            InputProps={InputProps}
            disabled={disabled ? disabled : false}
            placeholder={placeholder}
          />
        </FormControl>
      )}
      {noLabel && (
        <FormControl
          className={
            stylesInput
              ? `${stylesInput} ${classes.formContainer}`
              : classes.formContainer
          }
        >
          <TextField
            className={stylesInput ? stylesInput : classes.inputSize}
            color={touched && !errors ? "success" : "primary"}
            size="small"
            name={name}
            value={value}
            onChange={handleChange}
            error={touched && Boolean(errors)}
            helperText={touched && errors && errors}
            onBlur={handleBlur}
            InputProps={InputProps}
            disabled={disabled ? disabled : false}
            placeholder={placeholder}
          />
        </FormControl>
      )}
      {insideLabel && (
        <TextField
          className={stylesInput}
          color={touched && !errors ? "success" : "primary"}
          size="small"
          fullWidth={fullWidth}
          label={insideLabel}
          InputLabelProps={{
            classes: {
              root: classes.placeholder,
            },
          }}
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          error={touched && Boolean(errors)}
          helperText={touched && errors && errors}
          onBlur={handleBlur}
          disabled={disabled}
        />
      )}
    </Fragment>
  );
}

export default InputText;
