import { Box, Grid, Typography } from "@mui/material";

import bg from "../../assets/procedureType/bg.svg";
import Header from "../home-v2/Header";
import MainContent from "./MainContent";
import Footer from "../home-v2/Footer";
import useStyle from "./styles";
import customColor from "../../theme/customColor";

export const AboutUsPage = () => {
  const css = useStyle();

  return (
    <Box className={css.container}>
      <Box className={css.videoContainer}>
        <img src={bg} alt="video" />
        <Box className={css.main}>
          <Typography
            sx={{
              fontFamily: "Urbanist-Medium",
              fontSize: 45,
              color: "#fff",
              marginTop: "-200px",
            }}
          >
            ¡En Medsi te decimos que
            <span style={{ color: customColor.accentBlue }}> Sí!</span>
          </Typography>
        </Box>
        <Header />
      </Box>
      <MainContent />
      <Footer />
    </Box>
  );
};

export default AboutUsPage;
