import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Hidden,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CosignerReferenceNumberFooter from "../../../assets/common/CosignerReferenceNumberFooter.png";
import SplashFooterLine from "../../../assets/common/SplashFooterLine.svg";
import SplashHeaderLine from "../../../assets/common/SplashHeaderLine.svg";
import { useAppDispatch } from "../../../store/index";

import Logo from "../../../assets/Logos/gradient-logo.svg";
import { coSignerReferenceNumberPage } from "../../../constants/gtm";
import { ROUTES } from "../../../constants/routes";
import getParams from "../../../features/installmentLoan/utils/getParams";
import { setCoSignerReferenceNumber } from "../../../store/slices/auth";
import {
  checkReferenceCode,
  selectCoSignerToken,
  setCoSignerToken,
  setIsUserCosigner,
} from "../../../store/slices/cosigner";
import { GTMClick } from "../../../utils/gtm";
import { errorToast } from "../../../utils/toast";

import ButtonComponent from "../../../components/sharedComponents/ButtonComponent";
import ReferenceNumberStyles from "./ReferenceNumber.styles";

const CosignerConsentReferenceNumber = (props: any) => {
  const classes = ReferenceNumberStyles({});
  const dispatch = useAppDispatch();
  const history = useHistory();

  const inputFirstRef = useRef();
  const inputSecondRef = useRef();
  const inputThirdRef = useRef();
  const inputFouthRef = useRef();
  const inputFifthRef = useRef();
  const inputSixthRef = useRef();
  const inputSeventhRef = useRef();
  const inputEighthRef = useRef();

  const coSignerToken = useSelector(selectCoSignerToken);

  const [error, setError] = useState(false);
  const [values, setValues] = useState({
    inputFirst: "",
    inputSecond: "",
    inputThird: "",
    inputFouth: "",
    inputFifth: "",
    inputSixth: "",
    inputSeventh: "",
    inputEighth: "",
  });

  const [params, setParams] = useState<any>({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(props.location.search);
  //   const param1 = searchParams.get("token");
  //   const param2 = searchParams.get("app_id");
  //   console.log(param1, param2);
  // }, [props.location.search]);

  useEffect(() => {
    const value = getParams(["token"]);
    const app_id = getParams(["app_id"]);
    setParams(value);
    dispatch(setCoSignerToken({ token: value?.token, app_id }));
  }, []);

  useEffect(() => {
    // getStepValue();
  }, [coSignerToken]);

  const handleClick = async () => {
    setIsSubmitting(true);
    GTMClick(coSignerReferenceNumberPage.continue);
    let referenceNumber = "";

    referenceNumber =
      values.inputFirst +
      values.inputSecond +
      values.inputThird +
      values.inputFouth +
      values.inputFifth +
      values.inputSixth +
      values.inputSeventh +
      values.inputEighth;

    try {
      const param = { code: referenceNumber, token: params.token };
      const { status, meta } = await dispatch(
        checkReferenceCode(param)
      ).unwrap();

      if (status === "success") {
        history.push(ROUTES.cosignerConsentSignature);
        dispatch(setIsUserCosigner(true));
        dispatch(setCoSignerReferenceNumber(referenceNumber));
      } else {
        errorToast(meta?.errorMessage);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleFocus = (label, evt) => {
    const value = evt.target.value;
    if (value !== "") {
      let input;
      switch (label) {
        case "First":
          input = inputSecondRef.current;
          break;
        case "Second":
          input = inputThirdRef.current;
          break;
        case "Third":
          input = inputFouthRef.current;
          break;
        case "Fouth":
          input = inputFifthRef.current;
          break;
        case "Fifth":
          input = inputSixthRef.current;
          break;
        case "Sixth":
          input = inputSeventhRef.current;
          break;
        case "Seventh":
          input = inputEighthRef.current;
          break;
        case "Eighth":
          input = inputEighthRef.current;
          break;
        default:
          throw new Error("Something wrong");
      }
      input.focus();
    } else if (value === "") {
      let input;
      switch (label) {
        case "First":
          input = inputFirstRef.current;
          break;
        case "Second":
          input = inputFirstRef.current;
          break;
        case "Third":
          input = inputSecondRef.current;
          break;
        case "Fouth":
          input = inputThirdRef.current;
          break;
        case "Fifth":
          input = inputFouthRef.current;
          break;
        case "Sixth":
          input = inputFifthRef.current;
          break;
        case "Seventh":
          input = inputSixthRef.current;
          break;
        case "Eighth":
          input = inputSeventhRef.current;
          break;
        default:
          throw new Error("Something wrong");
      }
      input.focus();
    }
  };

  const handleKeyDown = (label, evt) => {
    const value = evt.target.value;
    if (
      (evt.key === "Backspace" ||
        evt.code === "Backspace" ||
        evt.keyCode === 8) &&
      value === ""
    ) {
      let input;

      switch (label) {
        case "First":
          input = inputFirstRef.current;
          break;
        case "Second":
          input = inputFirstRef.current;
          break;
        case "Third":
          input = inputSecondRef.current;
          break;
        case "Fouth":
          input = inputThirdRef.current;
          break;
        case "Fifth":
          input = inputFouthRef.current;
          break;
        case "Sixth":
          input = inputFifthRef.current;
          break;
        case "Seventh":
          input = inputSixthRef.current;
          break;
        case "Eighth":
          input = inputSeventhRef.current;
          break;
        default:
          throw new Error("Something wrong");
      }
      input.focus();
    }
  };

  const validation = () => {
    if (
      values.inputFirst === "" ||
      values.inputSecond === "" ||
      values.inputThird === "" ||
      values.inputFouth === "" ||
      values.inputFifth === "" ||
      values.inputSixth === "" ||
      values.inputSeventh === "" ||
      values.inputEighth === ""
    ) {
      return false;
    }

    return true;
  };

  const validationFormatFolio = () => {
    let reg = new RegExp(/^[a-zA-z]+$/);

    if (!validation()) {
      return true;
    } else {
      if (!values.inputFirst.match(reg)) return false;

      if (!values.inputSecond.match(reg)) return false;

      // if (values.inputThird !== "0") return false;

      // if (values.inputFouth !== "0") return false;
    }

    return true;
  };

  let isErrorFormat = validationFormatFolio();

  const didFilled = Object.values(values).every((val) => val);

  return (
    <Box className={classes.content} display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        })}
      >
        <img
          src={SplashHeaderLine}
          alt="logo-icon"
          className={classes.headerLineStyle}
          style={{ maxHeight: "238px" }}
        />
      </Box>

      <Box
        display="flex"
        flex={1}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img src={Logo} className={classes.logo} alt="logo" />

        <Typography variant="h2" className={classes.textTitle}>
          Por favor ingresa el Número de Referencia
          <Hidden smDown>
            <br />
          </Hidden>{" "}
          que se indica en el correo que recibiste de Medsi
        </Typography>

        <Typography variant="h6" className={classes.textReference}>
          Numero de referencia
        </Typography>

        <Grid
          justifyContent="center"
          display="flex"
          alignItems="center"
          className={classes.gridStyle}
          flexDirection="row"
          columnGap={1}
          rowGap={1}
          flexWrap="wrap"
        >
          <TextField
            inputRef={inputFirstRef}
            className={classes.textFieldStyles}
            autoFocus
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("First", e);
              setValues({
                ...values,
                inputFirst: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("First", e);
            }}
          />
          <TextField
            inputRef={inputSecondRef}
            className={classes.textFieldStyles}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Second", e);
              setValues({
                ...values,
                inputSecond: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Second", e);
            }}
          />
          <TextField
            inputRef={inputThirdRef}
            className={classes.textFieldStyles}
            // customInput={TextField}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Third", e);
              setValues({
                ...values,
                inputThird: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Third", e);
            }}
          />
          <TextField
            inputRef={inputFouthRef}
            className={classes.textFieldStyles}
            // customInput={TextField}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Fouth", e);
              setValues({
                ...values,
                inputFouth: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Fouth", e);
            }}
          />
          <TextField
            inputRef={inputFifthRef}
            className={classes.textFieldStyles}
            // customInput={TextField}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Fifth", e);
              setValues({
                ...values,
                inputFifth: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Fifth", e);
            }}
          />
          <TextField
            inputRef={inputSixthRef}
            className={classes.textFieldStyles}
            // customInput={TextField}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Sixth", e);
              setValues({
                ...values,
                inputSixth: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Sixth", e);
            }}
          />
          <TextField
            inputRef={inputSeventhRef}
            className={classes.textFieldStyles}
            // customInput={TextField}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Seventh", e);
              setValues({
                ...values,
                inputSeventh: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Seventh", e);
            }}
          />
          <TextField
            inputRef={inputEighthRef}
            // customInput={TextField}
            inputProps={{
              maxLength: 1,
              className: classes.textBox,
            }}
            onChange={(e) => {
              handleFocus("Eighth", e);
              setValues({
                ...values,
                inputEighth: e.target.value.toUpperCase(),
              });
            }}
            onKeyDown={(e) => {
              handleKeyDown("Eighth", e);
            }}
          />
        </Grid>

        {error && isErrorFormat && (
          <FormHelperText className={classes.error}>
            El folio es incorrecto, si tiene problemas con el <br />
            folio favor de comunicarte al correo contacto@medsi.mx
          </FormHelperText>
        )}

        {!isErrorFormat && (
          <FormHelperText className={classes.error}>
            El folio no tiene el formato válido
          </FormHelperText>
        )}

        <ButtonComponent
          variant="contained"
          className={classes.button}
          onClick={handleClick}
          text="Accesar"
          disabled={!didFilled || isSubmitting}
          loading={isSubmitting}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={(theme) => ({
          [theme.breakpoints.down("sm")]: {
            display: "none",
          },
        })}
      >
        <img
          src={CosignerReferenceNumberFooter}
          alt="logo-icon"
          className={classes.footerLineStyle}
        />
      </Box>
    </Box>
  );
};

export default CosignerConsentReferenceNumber;
