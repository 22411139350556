import { Theme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FC, SVGProps } from 'react';
import { useLocation } from 'react-router-dom';

import {
  useTabContainerStyles,
  useTabStyles,
  useTooltipStyles,
} from './styles';

interface ISidebarItem {
  disabled?: boolean;
  title: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  link?: string;
  tooltip?: string;
}

export interface ISidebarNavigation {
  items: ISidebarItem[];
  selected?: string;
  onChange: (link: string) => void;
}

function a11yProps(index: number) {
  return {
    id: `sidebar-tab-${index}`,
    'aria-controls': `sidebar-tabpanel-${index}`,
  };
}

const SidebarNavigation: FC<ISidebarNavigation> = ({
  items,
  selected,
  onChange,
}) => {
  const tabCss = useTabStyles();
  const tabContainerCss = useTabContainerStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm')
  );
  const isMobileScreen = useMediaQuery('(max-width: 450px)');

  const handleChange = (_: any, newValue: string) => {
    onChange(newValue);
  };
  let location = useLocation();

  const HtmlTooltip = useTooltipStyles(Tooltip);

  return (
    <Tabs
      orientation={'vertical'}
      variant={isSmallScreen && !isMobileScreen ? 'fullWidth' : 'scrollable'}
      TabIndicatorProps={{ className: tabCss.indicator }}
      value={location.pathname + location.search}
      onChange={handleChange}
      classes={tabContainerCss}
      centered={isMobileScreen}
      aria-label='Sidebar Menu'
      scrollButtons='auto'
      data-testid='sidebar-tabs-menu'
    >
      {items.map(({ icon: Icon, ...item }, idx) => (
        <Tab
          style={{ pointerEvents: 'auto' }}
          key={`sidebarItem-${idx}`}
          data-testid={`sidebarItem-${idx}`}
          icon={<Icon />}
          label={
            item.tooltip ? (
              <HtmlTooltip arrow title={item.tooltip} placement='right'>
                <span
                  data-testid={`spanTooltip-${idx}`}
                  className={tabCss.title}
                >
                  {item.title}
                </span>
              </HtmlTooltip>
            ) : (
              <>
                <span>
                  <div className={tabCss.head} />
                  <span className={tabCss.titleStyle}>{item.title}</span>
                  {item.disabled && (
                    <div className={tabCss.chip}>Próximamente</div>
                  )}
                  <div className={tabCss.bottom} />
                </span>
              </>
            )
          }
          {...a11yProps(idx)}
          value={item.link}
          classes={tabCss}
          disabled={item.disabled}
        />
      ))}
    </Tabs>
  );
};

export default SidebarNavigation;
