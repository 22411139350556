import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { customTheme } from "../../features/installmentLoan/theme";
import { URBANIST_FONT } from "../../constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  loginMainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#002539",
  },
  title: {
    fontSize: 20,
    fontFamily: URBANIST_FONT.MEDIUM,
  },
  loginButtonStyle: {
    background: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
    marginTop: "28px !important",
    width: "auto",
    height: "max-content",
    fontSize: 18,
    fontWeight: "bold !important",
    borderRadius: "100px !important",
    padding: "12px 34px !important",
    "&:disabled": {
      opacity: 0.3,
      background: "#999 !important",
      color: "white !important",
    },
  },
  content: {
    width: 318,
    textAlign: "center",
    fontSize: 16,
    fontFamily: URBANIST_FONT.REGULAR,
  },
  passwordAlternativeText: {
    marginTop: 28,
    fontFamily: URBANIST_FONT.SEMIBOLD,
    fontSize: 16,
    lineHeight: "19px",
    textAlign: "center",
    textDecorationLine: "underline",
    color: "#3552CC",
    cursor: "pointer",
  },
  continueButtonStyle: {
    background: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
    marginTop: "12px !important",
    padding: "18px 42px !important",
    fontSize: "16px !important",
    fontFamily: `${URBANIST_FONT.BOLD} !important`,
    borderRadius: "100px !important",
    "&:disabled[type='button']": {
      background: "#999",
      color: "#fff",
    },
  },
}));

export default useStyles;
