import { useSelector } from "react-redux";
import {
  getInstallmentLoanState,
  InstallmentLoanState,
} from "../features/installmentLoan/store/installmentLoan";

import {
  getRepaymentState,
  RepaymentState,
} from "../features/installmentLoan/store/repayment";

import { getAuthState, AuthState } from "./slices/auth";

import {
  getPayBookState,
  PayBookState,
} from "../features/installmentLoan/store/payBook";

import { getUiState, UiState } from "../features/installmentLoan/store/ui";
import { getOnboardingState } from "./slices/onboarding";
import { OnboardingState } from './slices/onboarding/types';

interface useGetStoreResponse {
  installmentLoan: InstallmentLoanState;
  repayment: RepaymentState;
  auth: AuthState;
  payBook: PayBookState;
  ui: UiState;
  onboarding: OnboardingState;
}

const useGetStore = (): useGetStoreResponse => {
  const installmentLoan = useSelector(getInstallmentLoanState);
  const repayment = useSelector(getRepaymentState);
  const auth = useSelector(getAuthState);
  const payBook = useSelector(getPayBookState);
  const ui = useSelector(getUiState);
  const onboarding = useSelector(getOnboardingState);

  return { installmentLoan, repayment, auth, payBook, ui, onboarding };
};

export default useGetStore;
