const { REACT_APP_ENCRYPT_KEY } = process.env;
var CryptoJS = require("crypto-js");
export const encryptPassword = (password) => {
  const ctObj = CryptoJS.AES.encrypt(password, REACT_APP_ENCRYPT_KEY);
  const ctStr = ctObj.toString();
  return ctStr;
};

export const downloadFile = (fileName, data) => {
  let a = document.createElement("a");
  a.href = "data:image/jpeg;base64," + data;
  a.download = `${fileName}.png`;
  a.click();
};

export const emailRegex =
  /^[A-ZáéíñóúüÁÉÍÑÓÚÜ0-9._%+-]+@[A-ZáéíñóúüÁÉÍÑÓÚÜ0-9.-]+\.[A-Z]{2,4}$/i;

export const containsAtLeastEightChars = new RegExp(/^.*.{8,}.*$/);
export const containsAtLeastNumber = new RegExp(/^(?=.*\d).*$/);
export const containsAtLeastOneUpperCase = new RegExp(/^.*[A-Z].*$/);
export const containsSpecialChars = new RegExp(/^(?=.*[*!"/?@#$%^&+=-]).*$/);
