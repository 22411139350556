import { Box, Grid, Typography } from "@mui/material";

import { urls } from "../../config/url";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";
import headerMediumIcon from "../../assets/home/HeaderMediumIcon.png";
import mobileHeaderIcon from "../../assets/home/mobileHeaderIcon.svg";
import { URBANIST } from "../../constants/fontsConst";
import { COLOR_CONSTANTS } from "../../constants/styleConstants";
import BodyContent from "./BodyContent";
import BottomBanner from "./BottomBanner";
import CenterVideo from "./CenterVideo";
import ModalVideo from "./CenterVideo/ModalVideo";
import FaqsContent from "./FaqsContent";
import Footer from "./Footer";
import Header from "./Header";
import MainContent from "./MainContent";
import RequestCreditLineOnLandingPage from "./RequestCreditLineOnLandingPage";
import useStyle from "./styles";
const video1 = require("../../assets/home/video4.png");
export const HomePage = () => {
  const css = useStyle();
  const [showModal, setModal] = useState(false);
  const theme = useTheme();
  const isUnderMedium = useMediaQuery(theme.breakpoints.down(1000));
  const isUnderSmall = useMediaQuery(theme.breakpoints.down(371));

  const handleClick = () => {
    setModal(true);
  };
  const closeModal = () => {
    setModal(false);
  };

  const HeaderContainer = () => (
    <Box className={css.videoContainer}>
      <Box className={css.video}>
        <video width="100%" height="100%" muted autoPlay loop poster={video1}>
          <source src={`${urls.s3Video}/main-video.mp4`} type="video/mp4" />
        </video>
        <Box className={css.content}>
          <Box className="first">Bienvenido a Medsi</Box>
          <Box className="second">
            La solución para que tú y tu familia cuenten con acceso a
            procedimientos médicos y estéticos.
          </Box>
        </Box>
      </Box>
      <Header />
    </Box>
  );

  const MediumHeaderContainer = () => (
    <Box className={css.containerMedium}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          className={css.welcomeMessageContainer}
        >
          <Typography
            sx={{
              fontFamily: URBANIST.SEMIBOLD,
              fontSize: { xs: 25, sm: 22 },
              color: COLOR_CONSTANTS.TEXT_GRAY,
            }}
          >
            ¡Bienvenido a Medsi!
          </Typography>
          <Typography
            className={css.headerTitleDescription}
            sx={{
              fontFamily: URBANIST.SEMIBOLD,
              fontSize: { xs: 20, sm: 22, md: 22 },
              color: COLOR_CONSTANTS.COLOR_WHITE,
              textShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
            }}
          >
            La solución para que tú y tu familia cuenten con acceso a
            procedimientos médicos y estéticos.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={8}>
          <img
            src={isUnderSmall ? mobileHeaderIcon : headerMediumIcon}
            alt="HeaderIcn"
            className={css.imageMediumContainer}
          />
        </Grid>
      </Grid>
      <Header />
    </Box>
  );

  return (
    <Box className={css.container}>
      {isUnderMedium ? <MediumHeaderContainer /> : <HeaderContainer />}
      <RequestCreditLineOnLandingPage />
      <MainContent />
      <CenterVideo handleClick={handleClick} />
      <Box>
        <BodyContent />
        <FaqsContent />
      </Box>
      <BottomBanner />
      <Footer isForm />
      <ModalVideo open={showModal} onClose={closeModal} />
    </Box>
  );
};

export default HomePage;
