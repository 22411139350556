import { FC, useState, ChangeEvent, useEffect } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import TextInput from "../../../features/installmentLoan/components/TextInput";
import Button from "../../../features/installmentLoan/components/Button";
import { emailRegex } from "../../../utils/validations";
import { AUTH_ROUTES, ROUTES } from "../../../constants/routes";

import useCommonStyles from "../styles";
import useStyles from "./styles";
import MettaGradient from "../../../assets/Logos/gradient-logo.svg";
import InputPhone from "../../../components/sharedComponents/InputPhone";
import {
  resendOTP,
  sendOTP,
  verifyOTP,
} from "../../../store/slices/auth/actions";
import MettaSolidBlue from "../../../assets/Logos/mettaSolidBlue.svg";
import {
  API_STATUS,
  FIELD_CHANNEL,
  REQUESTED_SOURCE,
} from "../../../constants/common";
import { useAppDispatch } from "../../../store";

export interface ButtonClickProps {
  email: string;
}

interface OTPPopupProps {
  onSubmit: (payload: ButtonClickProps) => void;
}

const TIMEOUT_MINUTES = 2;
const TIMEOUT_SECONDS = 0;

const OTPPopup: FC<OTPPopupProps> = ({ onSubmit }) => {
  const css = useStyles();
  const commonCss = useCommonStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const [otpValue, setOTPValue] = useState<string>("");
  const [otpId, setOtpId] = useState<string>("");
  const [didSendOTP, setDidSendOTP] = useState<boolean>(false);
  const [semilla, setSemilla] = useState<string>("");
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // setIsResendDisabled(false);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleSendOTP = async () => {
    try {
      const { status, data } = await dispatch(
        sendOTP({
          req_source: REQUESTED_SOURCE.FORGOT_PASSWORD,
          channel: FIELD_CHANNEL.SMS,
          platform: 1,
          send_to: phoneNumberValue,
        })
      ).unwrap();
      if (status === API_STATUS.SUCCESS) {
        setDidSendOTP(true);
        setMinutes(TIMEOUT_MINUTES);
        setSeconds(TIMEOUT_SECONDS);
        setOtpId(data?.id || "");
        setSemilla(data?.semilla);
      }
    } catch (e) {}
  };

  const otpVerification = async () => {
    try {
      const { status, data } = await dispatch(
        verifyOTP({
          id: otpId,
          otp_value: otpValue,
          sent_to: phoneNumberValue,
          req_source: REQUESTED_SOURCE.FORGOT_PASSWORD,
          semilla,
        })
      ).unwrap();

      console.log("VerifyOtpResponse", data);

      if (status === API_STATUS.SUCCESS) {
        return { data, status };
      }
      return { status };
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleOTPSubmit = async () => {
    const otpData = await otpVerification();
    if (otpData?.status === API_STATUS.SUCCESS) {
      history.push(
        `${AUTH_ROUTES.RESET_PASSWORD}?uuid=${otpData?.data?.user_id}&token=${otpData?.data?.password_reset_token}`
      );
    }
  };

  const hanldeResendOTP = async () => {
    try {
      setMinutes(TIMEOUT_MINUTES);
      setSeconds(TIMEOUT_SECONDS);
      const { status, data } = await dispatch(
        resendOTP({
          id: otpId,
          req_source: REQUESTED_SOURCE.FORGOT_PASSWORD,
          channel: FIELD_CHANNEL.SMS,
          platform: 1,
          send_to: phoneNumberValue,
          semilla,
        })
      ).unwrap();

      if (status === API_STATUS.SUCCESS) {
        setOtpId(data?.id || "");
        setSemilla(data?.semilla || "");
      }
    } catch (e) {}

    // finally {
    //   setPreferredLoginMethod(VERIFICATION_METHOD.OTP);
    //   // startTimer();
    // }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img src={MettaGradient} width={92} alt="img blanco" />
      <Box mt={2} className={commonCss.content}>
        Ingrese su número de teléfono registrado y el código de verificación que
        te enviaremos por Whatsapp para recuperar la contraseña
      </Box>

      {didSendOTP ? (
        <>
          <Box mt={4} className={css.otpTitle}>
            Hemos enviado una OTP al número de móvil registrado
          </Box>
          <Box mt={5}>
            <TextInput
              id="otp"
              placeholder="Ingrese su OTP aquí"
              className={css.input}
              value={otpValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setOTPValue(e.target.value);
              }}
              maxLength={6}
            />
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ width: "80%" }}
          >
            {/* {!!minutes ||
              (!!seconds && ( */}
            <span className={css.timer}>
              {`0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}
            </span>
            {/* ))} */}
            <span
              className={css.resendButton}
              onClick={!!minutes || !!seconds ? () => {} : hanldeResendOTP}
              style={{ opacity: !!minutes || !!seconds ? 0.5 : 1 }}
            >
              Reenviar codigo
            </span>
          </Box>
        </>
      ) : (
        <Box mt={5}>
          <InputPhone
            name="phoneNumber"
            label="Número De Teléfono"
            // id="phoneNumber"
            handleChange={(e) => {
              setPhoneNumberValue(e);
            }}
            value={phoneNumberValue}
            errors={() => {}}
            touched={() => {}}
            handleBlur={() => {}}
            centeredLabel={undefined}
            stylesInput={undefined}
          />
        </Box>
      )}

      <Box textAlign="center">
        {didSendOTP ? (
          <Button
            label="Continuar"
            onClick={handleOTPSubmit}
            noCaps
            className={commonCss.loginButtonStyle}
            disabled={otpValue.length < 6}
          />
        ) : (
          <Button
            label="ENVIAR OTP"
            onClick={handleSendOTP}
            className={commonCss.loginButtonStyle}
            disabled={phoneNumberValue?.length < 9}
          />
        )}
        <Box mt={2}>
          <Button
            label="Volver a Medsi"
            variant="text"
            className={css.signupTextStyle}
            onClick={() => {
              history.push(AUTH_ROUTES.LOGIN);
            }}
            noCaps
          />
        </Box>
      </Box>
    </Box>
  );
};

export default OTPPopup;
