import { FC } from "react";

import { IconProps, obsidian } from "./types";

const HomeIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.496 32.186H3.534V16.281H0L16.283 0L32.564 16.281H29.156V32.186H20.069V19.438H12.454L12.496 32.186Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

HomeIcon.defaultProps = {
  fill: obsidian,
};

export default HomeIcon;
