import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, FormControl, Typography } from "@mui/material";
import { useFormik } from "formik";

import { conocerteMejor } from "../../constants";
import { ROUTES } from "../../constants/routes";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import TitleComponent from "../../components/sharedComponents/TitleComponent";
import DropdownMenu from "../../components/sharedComponents/DropdownMenu";
import InputPhone from "../../components/sharedComponents/InputPhone";
import InputText from "../../components/sharedComponents/InputText";
import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import { GTMClick } from "../../utils/gtm";
import { knowYouBetterPage } from "../../constants/gtm";
import { selectUserId, saveUserKnowYouBetter } from "../../store/slices/users";
import { emailRegex } from "../../utils/validations";
import { API_STATUS } from "../../constants/common";
import { errorToast } from "../../utils/toast";
import { useAppDispatch } from "../../store";

import KnowYouBetterStyles from "./KnowYouBetter.styles";

function KnowYouBetter() {
  const classes = KnowYouBetterStyles();
  const history = useHistory();
  const id = useSelector(selectUserId);
  const dispatch = useAppDispatch();

  const { ocupacion, costo, ingreso } = conocerteMejor;

  const validate = (values) => {
    values.occupation === ""
      ? (errors.occupation = "Este campo es requerido")
      : delete errors.occupation;
    if (values.occupation === "Empleado") {
      values.companyName === ""
        ? (errors.companyName = "Este campo es requerido")
        : delete errors.companyName;
      values.otherOcuppation = "";
    } else {
      delete errors.companyName;
    }
    if (values.occupation === "Otro") {
      values.otherOcuppation === ""
        ? (errors.otherOcuppation = "Este campo es requerido")
        : delete errors.otherOcuppation;
      values.companyName = "";
    } else {
      delete errors.otherOcuppation;
    }
    !values.payer
      ? (errors.payer = "Este campo es requerido")
      : delete errors.payer;
    if (values.payer !== "Yo mismo") {
      !values.name
        ? (errors.name = "Este campo es requerido")
        : values.name.length < 3
        ? (errors.name = "mas de 2 caracters")
        : delete errors.name;
      !values.lastName
        ? (errors.lastName = "Este campo es requerido")
        : values.lastName.length < 3
        ? (errors.lastName = "mas de 2 caracters")
        : delete errors.lastName;
      !values.middleName
        ? (errors.middleName = "Este campo es requerido")
        : values.middleName.length < 3
        ? (errors.middleName = "mas de 2 caracters")
        : delete errors.middleName;
      values.phoneNumber.length < 3
        ? (errors.phoneNumber = "Este campo es requerido")
        : values.phoneNumber.length < 12
        ? (errors.phoneNumber = "numeros minios requeridos 10")
        : delete errors.phoneNumber;
      !values.email
        ? (errors.email = "Este campo es requerido")
        : !emailRegex.test(values.email)
        ? (errors.email = "ingresa un correo valido")
        : delete errors.email;
    } else {
      delete errors.name;
      delete errors.lastName;
      delete errors.middleName;
      delete errors.phoneNumber;
      delete errors.email;
    }
    if (values.payer === "Yo mismo") {
      values.name = "";
      values.lastName = "";
      values.middleName = "";
      values.phoneNumber = "";
      values.email = "";
    }
    !values.monthlyIncome
      ? (errors.monthlyIncome = "Este campo es requerido")
      : delete errors.monthlyIncome;
  };

  const formik = useFormik({
    initialValues: {
      occupation: "",
      companyName: "",
      payer: "",
      name: "",
      lastName: "",
      middleName: "",
      phoneNumber: "",
      monthlyIncome: "",
      email: "",
      otherOcuppation: "",
    },
    validate,
    onSubmit: async (values) => {
      GTMClick(knowYouBetterPage.continue);
      const hasCoSigner = values.payer !== "Yo mismo" ? true : false;

      const payloadCoSigner = {
        first_name: values.name,
        last_name: values.lastName,
        // mothersLastName: values.middleName,
        phone: values.phoneNumber,
        email: values.email,
        relation: values.payer,
      };

      const payloadClient = {
        id,
        data: {
          knowYouBetter: {
            occupation: values.occupation,
            companyName: values.companyName,
            payer: hasCoSigner ? payloadCoSigner : "Myself",
            monthlyIncome: values.monthlyIncome,
            otherOcuppation: values.otherOcuppation,
          },
        },
      };

      const res = await dispatch(
        saveUserKnowYouBetter(payloadClient.data.knowYouBetter)
      ).unwrap();

      if (res?.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.coSigneridentityInformation);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    handleChange,
    handleSubmit,
  } = formik;

  let validacion1;
  if (values.occupation === "Empleado" || values.occupation === "Otro") {
    validacion1 =
      values.occupation === "Empleado" && values.companyName
        ? true
        : values.occupation === "Otro" && values.otherOcuppation
        ? true
        : false;
  } else if (values.occupation !== "") {
    validacion1 = true;
  }

  let validation2;
  if (values.payer !== "Yo mismo") {
    validation2 =
      values.name !== "" &&
      values.lastName !== "" &&
      values.middleName &&
      emailRegex.test(values.email) &&
      values.phoneNumber.length === 12
        ? true
        : false;
  } else {
    validation2 = values.payer === "Yo mismo" && true;
  }

  let validacion3 = values.monthlyIncome !== "" ? true : false;

  return (
    <div className={classes.pageContainer}>
      <Box className={classes.headerPageBackground}></Box>
      <form onSubmit={handleSubmit}>
        <Box
          className={classes.formContainer}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img src={Logo} alt="logo" className={classes.logo} />
          <Typography variant="h2" className={classes.textTitle}>
            Queremos conocerte mejor
          </Typography>
          <DropdownMenu
            label="Ocupación"
            optionsObject={ocupacion}
            name="occupation"
            handleChange={handleChange}
            onBlur={handleBlur}
            value={values.occupation}
            errors={errors.occupation}
            touched={touched.occupation}
            centeredLabel={true}
          />
          {values.occupation === "Empleado" && (
            <InputText
              stylesInput={classes.formControl}
              label="Nombre de la Empresa"
              name="companyName"
              handleChange={handleChange}
              value={values.companyName}
              errors={errors.companyName}
              touched={touched.companyName}
              handleBlur={handleBlur}
              centeredLabel={true}
            />
          )}
          {values.occupation === "Otro" && (
            <InputText
              stylesInput={classes.formControl}
              label="Especifica"
              name="otherOcuppation"
              handleChange={handleChange}
              value={values.otherOcuppation}
              errors={errors.otherOcuppation}
              touched={touched.otherOcuppation}
              handleBlur={handleBlur}
              centeredLabel={true}
            />
          )}
          <DropdownMenu
            label="¿Quién cubrirá el costo del procedimiento?"
            optionsObject={costo}
            name="payer"
            handleChange={handleChange}
            value={values.payer}
            touched={touched.payer}
            errors={errors.payer}
            onBlur={handleBlur}
            centeredLabel={true}
          />

          {values.payer !== "Yo mismo" && values.payer !== "" && (
            <React.Fragment>
              <FormControl className={classes.formControlText}>
                <TitleComponent
                  text={
                    <Typography variant="h5" className={classes.text}>
                      Por favor compártenos la siguiente información de la
                      persona que cubrirá el costo del procedimiento:
                    </Typography>
                  }
                />
              </FormControl>

              <InputText
                stylesInput={classes.formControl}
                label="Nombre"
                name="name"
                handleChange={handleChange}
                value={values.name}
                errors={errors.name}
                touched={touched.name}
                handleBlur={handleBlur}
                centeredLabel={true}
              />
              <InputText
                stylesInput={classes.formControl}
                label="Apellido Paterno"
                name="lastName"
                handleChange={handleChange}
                value={values.lastName}
                errors={errors.lastName}
                touched={touched.lastName}
                handleBlur={handleBlur}
                centeredLabel={true}
              />
              <InputText
                stylesInput={classes.formControl}
                label="Apellido Materno"
                name="middleName"
                handleChange={handleChange}
                value={values.middleName}
                errors={errors.middleName}
                touched={touched.middleName}
                handleBlur={handleBlur}
                centeredLabel={true}
              />
              <InputPhone
                stylesInput={classes.formControl}
                label="Número de Celular"
                country="mx"
                name="phoneNumber"
                handleChange={(e) => {
                  setFieldValue("phoneNumber", e);
                }}
                value={values.phoneNumber}
                errors={errors.phoneNumber}
                touched={touched.phoneNumber}
                handleBlur={handleBlur}
                centeredLabel={true}
              />
              <InputText
                stylesInput={classes.formControl}
                label="Correo Electrónico"
                name="email"
                handleChange={handleChange}
                value={values.email}
                errors={errors.email}
                touched={touched.email}
                handleBlur={handleBlur}
                centeredLabel={true}
              />
            </React.Fragment>
          )}
          <DropdownMenu
            label="Ingreso Mensual"
            optionsObject={ingreso}
            name="monthlyIncome"
            handleChange={handleChange}
            value={values.monthlyIncome}
            onBlur={handleBlur}
            touched={touched.monthlyIncome}
            errors={errors.monthlyIncome}
            centeredLabel={true}
          />
          <ButtonComponent
            type="submit"
            text="Continuar"
            disabled={validacion1 && validation2 && validacion3 ? false : true}
          />
        </Box>
      </form>
    </div>
  );
}

export default KnowYouBetter;
