// collection of endpoints goes here
const creditEndPoint = "/credit/api/v1";
const ILEndPoint = `${creditEndPoint}/il`;
const AccessTokenEndPoint = "/v2/lms";
const balanceSummaryEndPoint = "/lms/api/v1";

export const instalmentLoanEndpoints = {
  getPaymentSchedule: `${ILEndPoint}/get-payment-schedule?clId=`,
  saveLoanDetails: `${ILEndPoint}/save-loan-detail`,
  getBalanceSummary: `${ILEndPoint}/get-balance-summary`,
  getTxnList: `${ILEndPoint}/get-txn-list`,
  getBillStatement: `${ILEndPoint}/get-bill-statement/:clId`,
  getRepaymentAmount: `${ILEndPoint}/repayment?date=`,
  postRepaymentAmount: `${ILEndPoint}/repayment`,
  repaymentDetails: `${ILEndPoint}/repayment-detail?txnId=`,
  getLatestTxn: `${creditEndPoint}/lms/txn/list?txn_type=All`,
  getTxnDetails: `${ILEndPoint}/txn?txn_id=`,
  getAccessToken: `${AccessTokenEndPoint}/get-token/`,
  getLmsBalanceSumamry: `${balanceSummaryEndPoint}/loan/balance-summary/`,
  getTransactionList: `${balanceSummaryEndPoint}/loan/txn-list/`,
  getLoanList: `${balanceSummaryEndPoint}/loan/list/`,
};

export const authEndpoints = {
  login: `${creditEndPoint}/login`,
};
