import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles(() => ({
  textInputIconStyle: {
    color: customColor.cotton,
  },
  input: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    "& input": {
      width: 260,
      minWidth: 260,
    },
  },
  passwordItemsContainer: {
    marginLeft: 20,
    marginBottom: 20,
  },
  textClass: {
    color: customColor.cotton,
  },
  signupTextStyle: {
    color: `${customColor.lightAccentBlue} !important`,
    textTransform: "none !important" as "none",
    padding: "0px 0px 4px !important",
    fontWeight: "500 !important",
    fontSize: "18px !important",
  },
  mettaGradientLogo: {
    paddingBottom: 19,
  },
}));

export default useStyles;
