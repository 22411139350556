import { useState, FC } from "react";
import { Box } from "@mui/material";

import LogoWhite from "../../../../assets/header/logo.svg";
import HamburgerMenu from "../../../../assets/header/Hamburger.svg";
import useStyles from "./styles";

export interface MenuProps {
  menuList: any;
}

const MobileMenu: FC<MenuProps> = ({ menuList }) => {
  const css = useStyles();

  return (
    <Box className={css.mobileMenu}>
      {menuList?.map(({ label, icon, onClick, menuCss }) => {
        return (
          <div
            onClick={() => onClick && onClick()}
            className={menuCss || ""}
            key={label}
          >
            {label}
            {icon && <img src={icon} />}
          </div>
        );
      })}
    </Box>
  );
};

const Menu: FC<MenuProps> = ({ menuList }) => {
  const css = useStyles();
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Box className={css.headerMainContainerMobile}>
      <Box
        display="flex"
        flexDirection="row"
        className={"header-row"}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <img src={LogoWhite} alt="medsi-logo" />
        <Box>
          <img
            src={HamburgerMenu}
            onClick={() => setShowMenu(!showMenu)}
            alt="hamburguer-menu"
          />
        </Box>
      </Box>
      {showMenu && (
        <Box className={css.drawer} onClick={() => setShowMenu(false)}>
          <MobileMenu menuList={menuList} />
        </Box>
      )}
    </Box>
  );
};

export default Menu;
