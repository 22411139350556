import { useEffect, useState, useCallback } from 'react';

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const language = navigator.language;

enum TimesOfDay {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  NIGHT = 'night',
}
export type IGreeting = TimesOfDay.MORNING | TimesOfDay.AFTERNOON | TimesOfDay.NIGHT;
export interface IUseTimeOfDay {
  timeOfDay: IGreeting;
  getTimeOfDay: () => void;
}

function useTimeOfDay(): IUseTimeOfDay {
  const [timeOfDay, setGreeting] = useState<IGreeting>(TimesOfDay.MORNING);
  const [hour, setHour] = useState(0);

  const getTimeOfDay = useCallback(() => {
    const currentHour = +new Date().toLocaleString(language, {
      timeZone,
      hour: 'numeric',
      hourCycle: 'h24',
    });
    setHour(currentHour);
  }, []);

  useEffect(() => {
    getTimeOfDay();
  }, []);

  useEffect(() => {
    if (hour >= 0 && hour < 12) {
      setGreeting(TimesOfDay.MORNING);
    } else if (hour >= 12 && hour < 18) {
      setGreeting(TimesOfDay.AFTERNOON);
    } else {
      setGreeting(TimesOfDay.NIGHT);
    }
  }, [hour]);

  return { timeOfDay, getTimeOfDay };
}

export default useTimeOfDay;
