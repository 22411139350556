import { FC } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

import { ILPaymentScheduleItem } from "../../../features/installmentLoan/store/installmentLoan";
import { i18nDate, formatMoney } from "../../../utils/formatter";
import { customTheme } from "../../../features/installmentLoan/theme/index";
import useStyles from "./styles";

interface Column {
  id:
    | "date"
    | "interestAmt"
    | "principalAmt"
    | "principalRemain"
    | "term"
    | "totalAmt"
    | "vatAmt";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: string | number) => string;
}

const columns: Column[] = [
  { id: "term", label: "Término" },
  {
    id: "date",
    label: "Fecha",
    format: (value: string | number) => {
      const date = i18nDate(value.toString(), "es").format("MMMM D[,] yyyy");
      return date.charAt(0).toUpperCase() + date.slice(1);
    },
  },
  {
    id: "principalAmt",
    label: "Principal",
    format: (value: number | string) => formatMoney(+value),
  },
  {
    id: "interestAmt",
    label: "Fee",
    format: (value: number | string) => formatMoney(+value),
  },
  {
    id: "vatAmt",
    label: "IVA",
    format: (value: number | string) => formatMoney(+value),
  },
  {
    id: "totalAmt",
    label: "Pago total",
    format: (value: number | string) => formatMoney(+value),
  },
  {
    id: "principalRemain",
    label: "Balance",
    format: (value: number | string) => formatMoney(+value),
  },
];

export interface ScheduleTableProps {
  paymentScheduleItems: ILPaymentScheduleItem[];
}

const ScheduleTable: FC<ScheduleTableProps> = ({ paymentScheduleItems }) => {
  const css = useStyles();

  const getStyle = (id: string, value: string | number) => {
    if (id === "status") {
      if (value === "NOT_PAID") {
        return customTheme.rose;
      } else {
        return customTheme.brandGreen;
      }
    }
  };

  return (
    <Table aria-label="simple table">
      <TableHead className={css.headerRow}>
        <TableRow>
          {columns.map((column, index) => (
            <TableCell
              key={column.id}
              style={{ top: 57, minWidth: column.minWidth }}
              className={`${css.tableCellStyle} ${
                index === 0
                  ? css.leftBorderTop
                  : index === columns.length - 1
                  ? css.rightBorderTop
                  : ""
              }`}
            >
              <b className={css.headerRowText}>{column.label}</b>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {paymentScheduleItems &&
          paymentScheduleItems?.map((row, rowIndex) => {
            return (
              <TableRow
                hover
                role="checkbox"
                tabIndex={-1}
                key={row.isId}
                className={css.root}
              >
                {columns.map((column, index) => {
                  const value = row[column.id];
                  return (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ color: getStyle(column.id, value) }}
                      className={`${css.tableCellStyle} ${
                        rowIndex === paymentScheduleItems.length - 1
                          ? index === 0
                            ? css.leftBorderBottom
                            : index === columns.length - 1
                            ? css.rightBorderBottom
                            : ""
                          : ""
                      }`}
                    >
                      {column?.format ? column.format(value) : value}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
      </TableBody>
    </Table>
  );
};

export default ScheduleTable;
