import { Box, Grid } from "@mui/material";

import { urls } from "../../../config/url";
import appStore from "../../../assets/home/app-store.svg";
import playStore from "../../../assets/home/playstore.svg";
import GradientLogo from "../../../assets/Logos/gradient-logo.svg";
import useStyles from "./styles";

export const FooterRight = () => {
  const css = useStyles();
  const { newWebUrl, appStoreUrl, playStoreUrl } = urls;

  return (
    <Grid container>
      <Grid item md={6} xs={12}>
        <Box>
          <Box className={css.footerHeadingText}>Medsi</Box>
          <Box mt={1}>
            <a
              href={`${newWebUrl}/privacy-policy`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Aviso de Privacidad</li>
              </ul>
            </a>
          </Box>
          <Box mt={1}>
            <a
              href={`${newWebUrl}/terms`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Términos y Condiciones</li>
              </ul>
            </a>
          </Box>
        </Box>

        <Box mt={2}>
          <Box className={css.footerHeadingText}>Ayuda</Box>
          <Box mt={1}>
            <a
              href={`${newWebUrl}/specialized-service-unit`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Atención a Usuarios</li>
              </ul>
            </a>
          </Box>
          <Box mt={1}>
            <a
              href={`${newWebUrl}/contact`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Contacto</li>
              </ul>
            </a>
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} xs={12}>
        <Box className={css.footerHeadingText}>Descarga la aplicación</Box>

        <Box className={css.medsiLogo}>
          <img
            src={GradientLogo}
            height={18}
            width={41}
            style={{ marginRight: 4 }}
          />
          <span className={css.medsiText}>medsi credit</span>
        </Box>
        <Box mt={2}>
          <a href={appStoreUrl} target="_blank">
            <img src={appStore} />
          </a>
        </Box>
        <Box>
          <a href={playStoreUrl} target="_blank">
            <img src={playStore} />
          </a>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FooterRight;
