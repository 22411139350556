import { useState } from "react";
import {
  Grid,
  Container,
  Box,
  Checkbox,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import CustomSignaturePad from "../../components/sharedComponents/CustomSignaturePad";
import {
  selectCosignerInfo,
  selectUserCoSigner,
  saveUserFinalProcessInformation,
} from "../../store/slices/users";
import {
  selectApplicantInfo,
  finalInfo,
  selectCoSignerToken,
  checkReferenceCode,
} from "../../store/slices/cosigner";
import { verifyPassword } from "../../store/slices/auth/actions";
import { ROUTES } from "../../constants/routes";
import { selectIsCosigner } from "../../store/slices/cosigner";
import { selectUserEmail } from "../../store/slices/users";
import SplashValidation from "../../components/sharedComponents/SplashValidation";
import { GTMClick } from "../../utils/gtm";
import {
  clientSignaturePage,
  coSignerClientSignaturePage,
} from "../../constants/gtm";
import { API_STATUS } from "../../constants/common";
import { errorToast, successToast } from "../../utils/toast";
import { useAppDispatch } from "../../store";
import TextInput from "../../features/installmentLoan/components/TextInput";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import AcceptTermCondtion from "./AcceptTermCondition";
import ClientSignatureStyles from "./styles";

const ClientSignature = () => {
  const classes = ClientSignatureStyles();
  const [imageURL, setImageURL] = useState(null);
  const [imageObj, setImageObj] = useState(null);
  const [validateImage, setValidateImage] = useState(false);
  const [bureauValidate, setBureauValidate] = useState(false);
  const [isValidPassword, setValidPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [open, setOpen] = useState(false);
  const isUserCosigner = selectIsCosigner();
  const coSignerToken = useSelector(selectCoSignerToken);
  const history = useHistory();

  console.log({ isUserCosigner });

  const dispatch = useAppDispatch();

  //hasfolio
  const [loader, setLoader] = useState(false);
  //CosignerInfo
  const { name, lastName, mothersLastName } = useSelector(selectCosignerInfo);

  const { applicantsName } = useSelector(selectApplicantInfo);
  const hasCoSigner = useSelector(selectUserCoSigner);

  const userEmail = useSelector(selectUserEmail);

  const handleClick = async (e) => {
    setLoader(true);
    //Upload signature image
    //if the current uses is not a cosigner, then
    if (isUserCosigner) {
      GTMClick(coSignerClientSignaturePage.continue);
      const res = await dispatch(
        finalInfo({
          token: coSignerToken,
          tnc: "accepted", //have to get from BE
          signature: await imageObj,
        })
      ).unwrap();

      if (res?.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.creditConfirmation);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
    } else {
      GTMClick(clientSignaturePage.continue);
      const res = await dispatch(
        saveUserFinalProcessInformation({
          tnc: "accepted", //have to get from BE
          signature: await imageObj,
        })
      ).unwrap();

      if (res?.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.creditConfirmation);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
    }
  };

  const checkPassword = async (text) => {
    setLoading(true);
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      verifyPassword({
        password: text,
      })
    ).unwrap();
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Password verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const checkReferenceCodeData = async (code) => {
    setLoading(true);
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      checkReferenceCode({
        code,
        isBureauPull: true,
      })
    ).unwrap();
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Number verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (isUserCosigner) {
        checkReferenceCodeData(referenceNumber);
      } else {
        checkPassword(confirmationPassword);
      }
    }
  };

  const cleanSignature = () => {
    setImageURL(null);
    setImageObj(null);
    setValidateImage(false);
  };

  const isBtnEnable = isValidPassword && bureauValidate && validateImage;

  const handleCloseModal = () => {
    setBureauValidate(true);
    setValidPassword(false);
  };

  const handleBureauCheckBox = (e) => {
    if (e.target.checked) {
      setOpen(true);
    } else {
      setBureauValidate(false);
      setValidPassword(false);
      setOpen(false);
    }
  };

  if (loader) {
    return (
      <SplashValidation
        image={4}
        // trueRedirect={false}
      />
    );
  } else {
    return (
      <div className={classes.content}>
        <Box className={classes.headerContainer}></Box>
        <Container component="main">
          <Grid
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <img src={Logo} alt="logo" className={classes.logo} />
            <Typography variant="h1" className={classes.title}>
              ¡Felicidades!
            </Typography>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              className={
                isUserCosigner ? classes.boxContentCoSigner : classes.boxContent
              }
            >
              <CustomSignaturePad
                setValidateImage={setValidateImage}
                imageURL={imageURL}
                setImageURL={setImageURL}
                imageObj={imageObj}
                setImageObj={setImageObj}
              />

              <Typography
                variant="h6"
                className={classes.signAgain}
                onClick={cleanSignature}
              >
                Borrar y firmar de nuevo
              </Typography>

              {/* <ListItem className={`${classes.listItemBank} ${classes.column}`}>
                {isUserCosigner ? (
                  <>
                    <Box className="label">Numero de referencia</Box>
                    <Box display="flex" className={classes.passwordView}>
                      <TextInput
                        id="referenceNumber"
                        placeholder="referencia"
                        className={classes.input}
                        value={referenceNumber}
                        onChange={(e) => {
                          setReferenceNumber(e.target.value?.toUpperCase());
                        }}
                        onKeyDown={handleKeyDown}
                      />
                      <ButtonComponent
                        onClick={() => checkReferenceCodeData(referenceNumber)}
                        text="Verify"
                        className="verifyButton"
                        disabled={!referenceNumber}
                        loading={loading}
                      />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className="label">
                      Ingrese una credencial válida para verificar
                    </Box>
                    <Box display="flex" className={classes.passwordView}>
                      <TextInput
                        id="password"
                        type="password"
                        placeholder="Confirma tu contraseña"
                        className={classes.input}
                        value={confirmationPassword}
                        onChange={(e) => {
                          setConfirmationPassword(e.target.value);
                        }}
                        onKeyDown={handleKeyDown}
                        // onBlur={() => setCheckError(true)}
                      />
                      <ButtonComponent
                        onClick={() => checkPassword(confirmationPassword)}
                        text="Verify"
                        className="verifyButton"
                        disabled={!confirmationPassword}
                        loading={loading}
                      />
                    </Box>
                  </>
                )}
              </ListItem> */}

              {isUserCosigner ? (
                <Typography variant="h3" className={classes.completedProcess}>
                  Has completado con éxito la solicitud de información
                  adicional. Nos pondremos en contacto con{" "}
                  <span className={classes.mainColor}>{applicantsName}</span>{" "}
                  via email de inmediato para notificarle que su proceso de
                  aplicación se ha completado con éxito.
                </Typography>
              ) : hasCoSigner ? (
                <Typography variant="h3" className={classes.completedProcess}>
                  Oprime CONTINUAR para finalizar el proceso de solicitud de
                  crédito. <br />
                  Nos pondremos en contacto con{" "}
                  <span className={classes.mainColor}>
                    {name} {lastName} {mothersLastName}
                  </span>{" "}
                  via email inmeditamente para obtener algo de información
                  adicional.
                </Typography>
              ) : (
                <Typography variant="h3" className={classes.completedProcess}>
                  Oprime CONTINUAR para finalizar el proceso de solicitud de
                  crédito.
                  <br />
                  Nos pondremos en contacto contigo vía email a{" "}
                  <span className={classes.mainColor}>
                    {userEmail ? userEmail : ""}
                  </span>{" "}
                  en las próximas 24 horas.
                </Typography>
              )}

              {!isUserCosigner ? (
                <Box className={classes.lastText}>
                  {/* <img src={LogoText} alt="logo oscuro" /> */}
                  ¡Estás un paso más cerca de alcanzar tu meta !
                </Box>
              ) : null}
            </Box>

            <ButtonComponent
              onClick={handleClick}
              text="Continuar"
              disabled={validateImage ? false : true}
            />
            {/* commit */}
          </Grid>
        </Container>
        <ModalComponent
          open={open}
          body={
            <AcceptTermCondtion setOpen={setOpen} setShow={handleCloseModal} />
          }
          hideBackdrop={false}
          onBackdropClick={handleCloseModal}
        />
      </div>
    );
  }
};

export default ClientSignature;
