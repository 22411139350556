const v1 = "v1";
const v2 = "v2";

export const medsiOnboarding = {
  getSpecialty: `${v1}/get_speciality_type/`,
  getDoctorClinic: `${v1}/get_doctor_clinic/`,
  getClinicBranch: `${v1}/clinic_branch/`,
  getZipCodeInfo: `${v1}/get-info/`,
  getCurpInfo: `${v1}/get_details_from_curp/`,
};

export default medsiOnboarding;
