import { URBANIST } from "../../../constants/fontsConst";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  msg: {
    fontFamily: "SFUIText-Regular",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 1)",
    fontStyle: "italic",
    fontSize: 22,
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
      paddingLeft: 50,
      paddingRight: 50,
    },
   
  },
  title: {
    fontSize: 30,
    color: customColor.black,
    marginTop: theme.spacing(12),
    fontFamily: URBANIST.SEMIBOLD,
    fontWeight: "700",
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      paddingLeft: 50,
      paddingRight: 50,
      textAlign: "center",
      marginTop: theme.spacing(4),
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 80,
      paddingRight: 80,
      textAlign: "center",
    },
  },
  cardListView: {
    marginTop: theme.spacing(6),
    width: "100%",
    padding: "0 10%",
    [theme.breakpoints.down(601)]: {
      marginTop: 0,
      padding: "0 2%",
    },
  },
}));

export default useStyles;
