import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  immediateLoanTxt: {
    color: '#6B7779',
    fontFamily: 'SFUIText-bold',
    fontSize: '22px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '20px',
      fontFamily: 'Urbanist-Bold',
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: '18px',
      fontFamily: 'Urbanist-Bold',
    },
  },
}));

export default useStyles;
