import { FC } from "react";

import { IconProps, obsidian } from "./types";

const EmailCircle: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="118"
      {...props}
      height="118"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_610_461)">
        <path
          d="M58.9044 118.786C47.2556 118.79 35.8675 115.339 26.1802 108.87C16.4929 102.4 8.94153 93.2039 4.48126 82.4429C0.0209932 71.6819 -1.14771 59.84 1.12262 48.4147C3.39294 36.9893 9.00031 26.4939 17.2359 18.2557C25.4715 10.0175 35.9653 4.40668 47.3899 2.13277C58.8146 -0.141148 70.6569 1.02403 81.4193 5.48091C92.1816 9.9378 101.38 17.4862 107.853 27.1715C114.325 36.8567 117.779 48.2438 117.779 59.8925C117.761 75.5032 111.553 90.4696 100.516 101.51C89.4794 112.55 74.5151 118.763 58.9044 118.786V118.786ZM58.9044 4.55849C47.9597 4.55608 37.2601 7.79934 28.1588 13.8781C19.0574 19.9569 11.9632 28.5982 7.77325 38.709C3.58326 48.8199 2.48569 59.9462 4.61944 70.6809C6.7532 81.4156 12.0223 91.2764 19.7606 99.0163C27.4988 106.756 37.3586 112.027 48.0928 114.163C58.827 116.299 69.9535 115.204 80.0652 111.016C90.177 106.829 98.8197 99.7361 104.9 90.6361C110.981 81.5361 114.227 70.8371 114.227 59.8925C114.209 45.2244 108.375 31.1621 98.0042 20.7891C87.6334 10.4162 73.5724 4.57931 58.9044 4.55849V4.55849Z"
          fill="#3552CC"
        />
        <path
          d="M21.25 51.5146L33.2715 90.9385L95.748 71.8779L83.7344 32.4668L21.25 51.5146ZM59.8623 66.1709L25.9375 52.4033L80.3262 35.8125L59.8623 66.1709ZM49.6348 64.4072L34.3948 87.0127L24.3708 54.1563L49.6348 64.4072ZM51.7236 65.25L60.6895 68.8916L66.1016 60.8691L91.0557 71.0068L36.6699 87.5918L51.7236 65.25ZM67.3672 58.9873L82.6094 36.3867L92.625 69.2588L67.3672 58.9873Z"
          fill="#3552CC"
        />
      </g>
      <defs>
        <clipPath id="clip0_610_461">
          <rect width="117.779" height="117.786" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

EmailCircle.defaultProps = {
  fill: obsidian,
};

export default EmailCircle;
