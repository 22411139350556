import { makeStyles } from "@mui/styles";
import { URBANIST_FONT } from "../../../constants/common";
import { customColor } from "../../../theme/customColor";

const RegistroStyles = makeStyles((theme) => ({
  headerContainer: {
    background: "#002539",
    // backgroundImage: `url("data:image/svg+xml,%3Csvg id='Compo' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1920 342.7852'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23abced4;%7D.cls-2%7Bfill:url(%23linear-gradient);%7D.cls-3%7Bfill:url(%23linear-gradient-2);%7D%3C/style%3E%3ClinearGradient id='linear-gradient' y1='164.5' x2='1920' y2='164.5' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%231945d1'/%3E%3Cstop offset='1' stop-color='%23abced4'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' y1='245.2681' x2='1919.917' y2='245.2681' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233552cc'/%3E%3Cstop offset='1' stop-color='%2395d0dd'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EBienvenida header desktop%3C/title%3E%3Cpath class='cls-1' d='M234.2764,234.356s292.1318,56.5312,566.0127-58.625C800.2891,175.731,620.3081,108.731,234.2764,234.356Z'/%3E%3Cpath class='cls-2' d='M0,329S393,157,627,157s486,152,776,152,517-144,517-144V0H0Z'/%3E%3Cpath class='cls-3' d='M.5679,342.7852,0,315.499C13.4194,308.8,339.9478,147.751,621.0986,147.751c139.4468,0,258.3057,37.7724,373.2364,74.311,120.2109,38.2412,244.5351,77.7373,398.9072,77.7373,296.7539,0,524.4824-147.979,526.6748-149.4072l-.501,26.39c-9.5039,6.3072-209.8681,141.5308-526.1738,141.5308-164.5947,0-293.8067-41.0894-418.7969-80.814-115.2363-36.6367-224.0679-71.2344-353.3467-71.2344C355.2661,166.2646,3.7315,341.2134.5679,342.7852Z'/%3E%3C/svg%3E")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    height: 200,
    position: "relative",
    overflow: "hidden",
    [theme.breakpoints.down(1201)]: {
      height: 270,
    },
    [theme.breakpoints.down(601)]: {
      height: 190,
    },
  },
  mainRegistercontainer: {
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 768 1024'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23linear-gradient);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;%7D%3C/style%3E%3ClinearGradient id='linear-gradient' y1='512.0078' x2='767.9775' y2='512.0078' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EBienvenida elementos back%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' y='-0.1758' width='767.9775' height='1024.3672'/%3E%3C/g%3E%3Cg id='Footer_onda' data-name='Footer onda'%3E%3Cpath class='cls-2' d='M0,876.2139s66.3452-15.666,140.8711-15.666c74.5288,0,156.3213,15.666,230.8467,56.2939,74.5283,40.625,167.23,65.583,249.0244,65.583,81.7988,0,146.3262-17.4141,146.3262-17.4141V1024H0Z'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundColor: "##E5E5E5",
    backgroundRepeat: "no-repeat no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  headerTitle: {
    color: theme.palette.primary.white,
    fontSize: "60px !important",
    position: "absolute",
    right: 350,
    top: 36,
    [theme.breakpoints.down(1201)]: {
      fontSize: "60px !important",
      top: 60,
      right: 160,
    },
    [theme.breakpoints.down(601)]: {
      position: "static",
      fontSize: "40px !important",
    },
  },
  headerSubtitle: {
    color: theme.palette.primary.white,
    fontSize: "25px !important",
    position: "absolute",
    right: 314,
    top: 124,
    [theme.breakpoints.down(1201)]: {
      lineHeight: "1.1 !important",
      top: 180,
      right: 125,
    },
    [theme.breakpoints.down(601)]: {
      position: "static",
      fontSize: "15px !important",
    },
  },
  logo: {
    width: 80,
    marginTop: 10,
    [theme.breakpoints.down(901)]: {
      marginTop: 25,
      width: 265,
    },
    [theme.breakpoints.down(601)]: {
      width: 179,
    },
  },
  bienvenidaLarge: {
    marginLeft: "50%",
    marginTop: 50,
    [theme.breakpoints.up(1200)]: {
      marginLeft: "50%",
    },
    [theme.breakpoints.up(1400)]: {
      marginLeft: "55%",
    },
  },
  mettaGradientLogo: {
    marginLeft: 40,
    paddingTop: 58,

    [theme.breakpoints.down(1200)]: {
      paddingTop: 75,
    },
  },
  headerImage: {
    marginLeft: 40,
  },
  headerTextContainer: {
    marginTop: 5,
  },
  input: {
    width: "100%",
    padding: "12.5px 14px ",
    fontSize: "16px",
    marginTop: "5px",
    marginBottom: "5px ",
  },
  notchedOutline: { borderColor: "#000 !important" },
  emailIcon: {
    marginRight: 5,
    [theme.breakpoints.down("sm")]: {
      width: 25,
    },
    [theme.breakpoints.up("sm")]: {
      width: 30,
    },
  },
  passwordItemsContainer: {
    marginBottom: 20,
    marginTop: 2,
  },
  formContainer: {
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
    "@media (min-width: 1200px) and (max-height: 750px)": {
      marginTop: "2%",
    },
    "@media (min-width: 1200px) and (min-height: 751px)": {
      marginTop: "2%",
    },
  },
  headerInput: {
    paddingLeft: 24,
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
    },

    marginTop: 20,
  },
  signupSentenceStyle: {
    fontWeight: "normal",
    fontSize: "18px !important",
    margin: "0 auto",
    // color: theme.palette.primary.gray,
    textAlign: "center",
    fontFamily: URBANIST_FONT.REGULAR,
  },
  signupTextStyle: {
    textTransform: "none !important",
    padding: "0px 0px 4px !important",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    fontFamily: URBANIST_FONT.SEMIBOLD,
    // color: customColor.accentBlue,
    color: customColor.celticBlue,
    textDecoration: "none",
  },

  footerContainer: {
    margin: "30px auto 0",
    padding: "0 20px 64px",
    [theme.breakpoints.up(900)]: {
      width: "900px",
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down(900)]: {
      textAlign: "center",
    },
  },
  footerTextStyle: {
    // fontFamily: "SFUIText-Regular !important",
    fontFamily: `${URBANIST_FONT.REGULAR} !important`,
    // color: `${customColor.accentBlue} !important`,
    color: `${customColor.celticBlue} !important`,
    textTransform: "none !important",
    padding: "0px !important",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "1 !important",
  },
  registerButton: {
    background: "linear-gradient(270deg, #1B63DB 0%, #0ACC97 100%)",
    borderRadius: "100px !important",
    width: "auto",
  },
  title: {
    // color: customColor.accentBlue,
    fontSize: "22px !important",
    margin: "auto",
    // marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
    // width: "75%",
    // fontFamily: "Roboto-Regular !important",
    fontFamily: `${URBANIST_FONT.REGULAR} !important`,
    textAlign: "center",
    "&> b": {
      fontWeight: "900",
    },
    width: "720px",
    [theme.breakpoints.down(900)]: {
      width: "100%",
    },
  },
  highlightTitle: {
    color: customColor.accentBlue,
    fontFamily: URBANIST_FONT.BOLD,
  },
  promoCode: {
    textDecoration: "underline",
    cursor: "pointer",
    textAlign: "center",
    fontFamily: "SFUIText-bold",
    color: "#3552CC",
    fontSize: "22px !important",
  },
  FirsttextPromo: {
    fontFamily: "SFUIText-Regular",
    color: "#3552CC",
    fontSize: "15px",
  },
  SecondtextPromo: {
    fontFamily: "SFUIText-bold",
    fontSize: "15px",
    color: "#3552CC",
    background: "rgba(53, 82, 204, 0.05)",
    borderRadius: "10px",
    paddingRight: "37px",
    paddingTop: "11px",
    paddingBottom: "11px",
    paddingLeft: "10px",
    position: "relative",
    textAlign: "center",
  },

  divAlignment: {
    textAlign: "center",
  },

  formMainContainer: { marginTop: 30 },
  textsm: { fontSize: 14, marginBottom: 20 },
  phoneNumberDisclaimer: {
    fontSize: 14,
    marginTop: "-10px",
    fontFamily: URBANIST_FONT.MEDIUM,
  },
}));

export default RegistroStyles;
