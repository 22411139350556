import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { API_STATUS } from "../../../constants/common";
import { TransactionsItems } from "./common";

import instalmentLoanApi from "../../../apis/instalmentLoan";
import {
  mapBalanceSummary,
  mapLatestTransaction,
  mapTransactionDetail,
} from "./helpers";
import { getProcedureName } from "../../../store/helpers";
import instalmentLoanEndpoints from "../../../apis/instalmentLoan/config";
import instalmentLoanEndpointsV2 from "../../../apis/instalmentLoan/configV2";
import medsiV2Auth from "../../../apis/auth/configV2";
import medsiAuth from "../../../apis/auth/config";
import authApi from "../../../apis/auth";
import authEndPointV2 from "../../../apis/auth/configV2";

export interface CreditRequestDetails {
  prodCode: string;
}

const initialState = {
  loading: false,
  balanceSummary: null,
  loadingBalanceSummary: false,
  isBalanceSummaryLoaded: false,
  loadingTxnList: false,
  repaymentList: [],
  // repayment: {},
  latestTransactions: [],
  txnDetails: null,
  loadedTxnDetails: false,
  txnPaymentId: "",
  paymentScheduleItems: [],
  tncTemplates: [],
  termDetails: [],
  termSelected: null,
  productDetails: null,
  basicApplicationSummary: null,
  applicationSummary: null,
  termDetailsLoading: false,
};

const txnSampleData = {
  data: {
    disbursement: [],
    repayment: [
      {
        id: "txn1",
        date: "2022-01-13 12:33:15",
        principalPaid: 2387.48,
        interestPaid: 250,
        extraAmtPaid: 0,
        totalAmtPaid: 2637.48,
        txnStatus: "SUCCESSFUL",
      },
    ],
  },
  meta: {
    status: "success",
    message: "success",
    filterParam: {
      perPage: 1,
      pageNo: 1,
      totalItem: 1,
      totalPages: 1,
    },
    error: "false",
    success: "false",
    errorCode: "UNKNOWN_ERROR",
    debug: "null",
    pagination: "null",
  },
};

export const getCreditStatement = createAsyncThunk(
  instalmentLoanEndpointsV2.creditStatement,
  async () => {
    const { data } = await instalmentLoanApi.getCreditStatement();
    return data;
  }
);

export const getBalanceSummary = createAsyncThunk(
  instalmentLoanEndpoints.getBalanceSummary,
  async () => {
    const { data } = await instalmentLoanApi.getBalanceSummary();
    const res = mapBalanceSummary(data);
    return res;
  }
);

export const getTxnList = createAsyncThunk(
  instalmentLoanEndpoints.getTxnList,
  async () => {
    return txnSampleData;
  }
);

export const getLatestTransaction = createAsyncThunk(
  instalmentLoanEndpoints.getLatestTxn,
  async () => {
    const { data } = await instalmentLoanApi.getLatestTransaction();
    const res = mapLatestTransaction(data);
    return res;
    // return latestTxnSampleData;
  }
);

export const getTxnDetails = createAsyncThunk(
  instalmentLoanEndpoints.getTxnDetails,
  async (id: string) => {
    const { data } = await instalmentLoanApi.getTransactionDetail({
      id,
    });
    const res = mapTransactionDetail(data);
    return res;
    // return txnSampleData;
  }
);

export const creditRequestDetails = createAsyncThunk(
  instalmentLoanEndpoints.creditRequestDetails,
  async (prodCode: string) => {
    const { data } = await instalmentLoanApi.creditRequestDetails(prodCode);
    return data;
  }
);

export const getCreditMinMax = createAsyncThunk(
  `${instalmentLoanEndpoints.creditMinMax}/get`,
  async () => {
    const { data } = await instalmentLoanApi.getCreditMinMax();
    return data;
  }
);

export const getApplicationBasicDetails = createAsyncThunk(
  `${instalmentLoanEndpoints.basicDetails}/get`,
  async () => {
    const { data } = await instalmentLoanApi.getBasicDetails();
    return data;
  }
);

export const getApplicationSummary = createAsyncThunk(
  `${instalmentLoanEndpoints.applicationSummary}/get`,
  async () => {
    const { data } = await instalmentLoanApi.getApplicationSummary();
    return data;
  }
);

export const getProcedureInfoDetails = createAsyncThunk(
  `${medsiV2Auth.procedureInfo}/get`,
  async () => {
    const { data } = await instalmentLoanApi.getProcedureInfo();
    return data;
  }
);

export const updatePendingProcedureDetails = createAsyncThunk(
  `${instalmentLoanEndpoints.applicationSummary}/patch`,
  async (payload) => {
    const { data } = await instalmentLoanApi.updatedApplicationSummary(payload);
    data.payload = payload;
    return data;
  }
);

export const updatePendingProcedureDetailsV2 = createAsyncThunk(
  `${medsiV2Auth.procedureInfo}/patch`,
  async (payload) => {
    const { data } = await instalmentLoanApi.updatedApplicationSummaryV2(
      payload
    );
    data.payload = payload;
    return data;
  }
);

export const getStepperProcedureInfo = createAsyncThunk(
  `${medsiAuth.applicationProcedure}/Get`,
  async (payload) => {
    const { data } = await instalmentLoanApi.getStepperPendingProcedureInfo(
      payload
    );
    data.payload = payload;
    return data;
  }
);

export const deleteUserPromoCode = createAsyncThunk(
  `${authEndPointV2.submitPromocode}`,

  async (payload: string) => {
    const { data } = await authApi.deleteUserPromoCode(payload);
    return data;
  }
);

export const stepperPendingProcedureInfo = createAsyncThunk(
  `${medsiAuth.applicationProcedure}`,
  async (payload) => {
    const { data } = await instalmentLoanApi.stepperPendingProcedureInfo(
      payload
    );
    data.payload = payload;
    return data;
  }
);

const slice = createSlice({
  name: "installmentLoan",
  initialState: {
    ...initialState,
  } as InstallmentLoanState,
  reducers: {
    setLoadingTxnDetails: (state, action: PayloadAction<boolean>) => {
      state.loadedTxnDetails = action.payload;
    },
    setSelectedTerm: (state, action: PayloadAction<TermDetailsItem>) => {
      state.termSelected = action.payload;
    },
    setCreditLoading: (state, action: PayloadAction<boolean>) => {
      state.termDetailsLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBalanceSummary.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (API_STATUS?.SUCCESS === meta?.status) {
        state.balanceSummary = data.balanceSummary;
        state.paymentScheduleItems = data.paymentSchedule;
        state.isBalanceSummaryLoaded = true;
        state.loadingBalanceSummary = false;
      }
    });
    builder.addCase(getTxnList.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (API_STATUS?.SUCCESS === meta?.status) {
        state.repaymentList = data?.repayment || [];
        state.loadingTxnList = false;
      }
    });
    builder.addCase(getTxnDetails.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (API_STATUS?.SUCCESS === meta?.status) {
        state.txnDetails = data.txnDetails;
        state.loadedTxnDetails = false;
      }
    });
    builder.addCase(getLatestTransaction.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if (API_STATUS?.SUCCESS === meta?.status) {
        state.latestTransactions = data?.latestTransaction || [];
        state.loadingTxnList = false;
      }
    });

    builder.addCase(creditRequestDetails.fulfilled, (state, { payload }) => {
      const {
        data: { tnc_templates, emi_details, requested_amount },
        status,
      } = payload;
      if (API_STATUS?.SUCCESS === status) {
        state.termDetailsLoading = false;
        state.requestedAmount = !isNaN(requested_amount)
          ? +requested_amount
          : 0;
        const tncTemplates = [] as any;
        for (let i in tnc_templates) {
          const item = tnc_templates[i];
          tncTemplates.push({
            content: item.content,
            itemCode: i,
          });
        }
        state.tncTemplates = tncTemplates;
        if (emi_details?.length) {
          state.termDetails = emi_details?.map((item) => item);
          state.termSelected = emi_details[1];
        }
      }
    });

    // get credit max min details
    builder.addCase(getCreditMinMax.fulfilled, (state, { payload }) => {
      const { status = "", data } = payload as any;

      if (API_STATUS?.SUCCESS === status) {
        const {
          max_credit_amount: maxCreditAmount,
          min_credit_amount: minCreditAmount,
          prod_code: prodCode,
          prod_name: prodName,
          clinic_details: list,
        } = data;
        const clinicDetails = list?.map(
          ({ clinic_name: clinicName, max_credit_amount: maxCreditAmount }) => {
            return {
              maxCreditAmount,
              clinicName,
            };
          }
        );

        state.productDetails = {
          maxCreditAmount,
          minCreditAmount,
          prodCode,
          prodName,
          clinicDetails,
        };
      }
    });

    // get basic details
    builder.addCase(
      getApplicationBasicDetails.fulfilled,
      (state, { payload }) => {
        const { status = "", data } = payload as any;
        if (API_STATUS?.SUCCESS === status) {
          state.basicApplicationSummary = {
            lpId: data.lp_id,
            reqAmt: data.req_amt,
            termLength: data.tenure,
            termDuration: data.payment_frequency,
            termAmt: data.term_amt,
            status: data.status,
            refCode: data.ref_code,
            isApplicationSummaryLoaded: true,
          };
        }
      }
    );

    // get basic details
    builder.addCase(getApplicationBasicDetails.pending, (state) => {
      state.basicApplicationSummary = {
        isApplicationSummaryLoaded: false,
      };
    });

    // get application summary
    builder.addCase(getApplicationSummary.fulfilled, (state, { payload }) => {
      const {
        status = "",
        data: {
          application_date: applicationDate,
          application_status: applicationStatus,
          appointment_date: appointmentDate,
          appointment_time: appointmentTime,
          doctor_name: doctorName,
          payment_frequency: paymentFrequency,
          procedure_type: procedureType,
          loan_amount: loanAmount,
          loan_tenure: loanTenure,
          total_payments: totalPayments,
          reference_code: referenceCode = "",
          contract_id: contractId = "",
          repayment_end: repaymentEnd = "",
          repayment_start: repaymentStart = "",
          late_payment_rate: latPaymentRate = "",
          annual_rate: annualRate,
          contract_pdf: contractPdf,
          application_pending: isApplicationPending,
          other_procedure,
          procedure_to_perform,
          email_verified: emailVerified,
          speciality_type: specialtyType,
        },
      } = payload as any;

      if (API_STATUS?.SUCCESS === status) {
        const procedureName = getProcedureName(
          procedure_to_perform,
          other_procedure
        );
        state.applicationSummary = {
          applicationDate,
          applicationStatus,
          appointmentDate,
          appointmentTime,
          doctorName,
          paymentFrequency,
          totalPayments,
          procedureName,
          procedureType,
          loanAmount,
          loanTenure,
          referenceCode,
          contractId,
          latPaymentRate,
          repaymentStart,
          repaymentEnd,
          annualRate,
          contractPdf,
          isApplicationPending,
          emailVerified,
          specialtyType,
        };
      }
    });
  },
});

export type ILPaymentScheduleItem = {
  isId: string;
  term: number;
  date: string;
  principalAmt: number;
  interestAmt: number;
  totalAmt: number;
  principalRemain: number;
  status: string;
};

export type ILBalanceSummary = {
  clId: string;
  loanAmt: number;
  interestRate: number;
  tenure: number;
  emiAmt: number;
  nextDueDate: string;
  nextDueAmt: number;
  status: string;
  percentPaid: number;
  disbursementDate: string;
  prodCode: string;
  outstandingAmt: number;
  vatAmt: number;
  contractPdf?: string;
  paymentFrequency?: string;
  emailVerified?: boolean;
};

export type ILTxnRepaymentItem = {
  id: string;
  date: string;
  principalPaid: number;
  interestPaid: number;
  extraAmtPaid: number;
  totalAmtPaid: number;
  txnStatus: string;
};

export type IlDetailType = {
  interestRate: string | number;
  loanTerm: number | string;
  productCode: string;
  docId: string;
  interestRateDisplay: string | number;
  cat: string | number;
  emi: string | number;
};

export type ILTxnDetails = {
  id: string;
  date: string;
  principalPaid: number;
  interestPaid: number;
  extraAmtPaid: number;
  totalAmtPaid: number;
  vatAmtPaid: number;
  txnStatus: string;
  txnType: string;
};

export type TncTemplateItem = {
  content: string;
  itemCode: string;
};

export type TermDetailsItem = {
  amount: number;
  term: number;
};

export type ClinicDetailsItem = {
  clinicName: string;
  maxCreditAmount?: number;
};

export type ProductDetails = {
  minCreditAmount: number;
  maxCreditAmount: number;
  prodCode: string;
  prodName?: string;
  requestedAmount?: number;
  clinicDetails?: ClinicDetailsItem[];
};

export type BasicApplicationSummary = {
  lpId?: string;
  reqAmt?: number;
  termLength?: number;
  termDuration?: string;
  termAmt?: number;
  status?: string;
  refCode?: string;
  isApplicationSummaryLoaded?: boolean;
};

export type ApplicationSummary = {
  applicationDate: string;
  applicationStatus: string;
  appointmentDate: string;
  appointmentTime: string;
  doctorName: string;
  paymentFrequency: string;
  procedureName: string;
  procedureType: string;
  loanAmount: number;
  loanTenure: number;
  totalPayments: number;
  referenceCode: string | null;
  contractId?: string;
  repaymentEnd?: string;
  repaymentStart?: string;
  latPaymentRate?: string;
  annualRate?: string;
  contractPdf?: string;
  isApplicationPending?: boolean;
  emailVerified?: boolean;
  specialtyType?: string;
};

export type InstallmentLoanState = {
  loading: boolean;
  paymentScheduleItems: ILPaymentScheduleItem[];
  balanceSummary: ILBalanceSummary | null;
  loadingBalanceSummary: boolean;
  isBalanceSummaryLoaded: boolean;
  loadingTxnList: boolean;
  repaymentList: ILTxnRepaymentItem[];
  latestTransactions: TransactionsItems[];
  txnDetails: ILTxnDetails | null;
  loadedTxnDetails: boolean;
  txnPaymentId: string;
  tncTemplates: TncTemplateItem[];
  termDetails: TermDetailsItem[];
  termSelected: TermDetailsItem | null;
  productDetails: ProductDetails | null;
  basicApplicationSummary: BasicApplicationSummary | null;
  applicationSummary: ApplicationSummary | null;
  requestedAmount?: number;
  termDetailsLoading?: boolean;
};

export default slice.reducer;

export const { setLoadingTxnDetails, setSelectedTerm, setCreditLoading } =
  slice.actions;

export const getInstallmentLoanState = (state: any) => state.installmentLoan;
