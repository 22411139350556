import { Box, Grid } from "@mui/material";

import image1 from "../../images/image1.svg";
import image2 from "../../images/image2.svg";
import image3 from "../../images/image3.svg";
import cardBg from "../../images/card-bg.svg";

import useStyle from "./styles";
import Cards from "../Cards";

export const Content = () => {
  const css = useStyle();

  const list = [
    {
      title: "Tú decides",
      content:
        "Tú eliges el procedimiento y el médico que tú quieras. No tienes que elegir entre una red limitada de doctores.",
      image: image1,
    },
    {
      title: "En tus tiempos",
      content:
        "En Medsi tienes la flexibilidad de financiar tu procedimiento a 3, 6 o 9 meses, en pagos quincenales o mensuales a tu preferencia.",
      image: image2,
    },
    {
      title: "Es muy fácil",
      content:
        "El proceso de aplicación en línea te toma menos de 10 minutos y recibes tu aprobación en menos de 24 horas.",
      image: image3,
    },
  ];
  return (
    <Box className={css.container}>
      <Box className={css.body}>
        <Box px={4}>
          <Grid container alignItems="center" textAlign="center">
            {list?.map(({ title, content, image }, index) => {
              return (
                <Grid item md={4} xs={12}>
                  {index !== 0 && <div class={css.line} />}
                  <Box my={3}>
                    <img src={image} className={css.image} />
                    <Box
                      className={css.title}
                      textAlign={index % 2 === 0 ? "right" : "left"}
                    >
                      {title}
                    </Box>
                    <Box
                      className={css.content}
                      textAlign={index % 2 === 0 ? "right" : "left"}
                    >
                      {content}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box className={css.cardContainer}>
        <Box className={css.cardList}>
          <Cards />
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
