import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import "../../../src/input.css";
import EditIcon from "../../components/icons/Edit";
import LockIcon from "../../components/icons/Lock";
import PhoneIcon from "../../components/icons/Phone";
import InputPhone from "../../components/sharedComponents/InputPhone";
import {
  API_STATUS,
  FIELD_CHANNEL,
  REQUESTED_SOURCE,
  URBANIST_FONT,
  VERIFICATION_METHOD,
} from "../../constants/common";
import { INSTALLMENT_LOAN_ROUTES, ROUTES } from "../../constants/routes";
import Button from "../../features/installmentLoan/components/Button";
import TextInput from "../../features/installmentLoan/components/TextInput";
import { setSidebarTabValue } from "../../features/installmentLoan/store/ui";
import { useAppDispatch } from "../../store";
import {
  loginVerifyOTP,
  resendOTP,
  sendOTP,
  updateOnboardingSteps,
} from "../../store/slices/auth/actions";
import { errorToast } from "../../utils/toast";
import useStyles from "./styles";

const TIMEOUT_MINUTES = 2;
const TIMEOUT_SECONDS = 0;

interface IOTPPhoneNumberProps {
  setPreferredLoginMethod: Dispatch<SetStateAction<string>>;
}

const OTPPhoneNumber: React.FC<IOTPPhoneNumberProps> = ({
  setPreferredLoginMethod,
}) => {
  const css = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const flag = new URLSearchParams(search)?.get("flag");

  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const [didSendOTP, setDidSendOTP] = useState<boolean>(false);
  const [otpId, setOtpId] = useState<string>("");
  const [otpValue, setOtpValue] = useState<string>("");
  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);
  const [semilla, setSemilla] = useState<string>("");
  const [otpNumber, setOtpNumber] = useState<string>("");

  const [isSendingOTP, setIsSendingOTP] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // setIsResendDisabled(false);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const handleSendOTP = async () => {
    setIsSendingOTP(true);
    try {
      const { status, data, meta } = await dispatch(
        sendOTP({
          req_source: REQUESTED_SOURCE.LOGIN,
          channel: FIELD_CHANNEL.SMS,
          platform: 1,
          send_to: phoneNumberValue,
        })
      ).unwrap();
      if (status === API_STATUS.SUCCESS) {
        setDidSendOTP(true);
        setMinutes(TIMEOUT_MINUTES);
        setSeconds(TIMEOUT_SECONDS);
        setOtpId(data?.id || "");
        setSemilla(data?.semilla);
        setOtpNumber(phoneNumberValue);
      }
      if (status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
      setIsSendingOTP(false);
    } catch (e) {
      setIsSendingOTP(false);
    }
  };

  const otpVerification = async () => {
    try {
      const verificationResponse = await dispatch(
        loginVerifyOTP({
          id: otpId,
          otp_value: otpValue,
          sent_to: phoneNumberValue,
          req_source: REQUESTED_SOURCE.LOGIN,
          semilla,
        })
      ).unwrap();

      return verificationResponse;
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleLogin = async () => {
    setIsLoggingIn(true);
    const { status, data: otpData, error } = await otpVerification();

    if (status === API_STATUS.SUCCESS && otpData) {
      try {
        const {
          data,
          status = "",
          meta,
        } = await dispatch(updateOnboardingSteps()).unwrap();
        if (status === API_STATUS.SUCCESS) {
          const identify_step = await dispatch(
            updateOnboardingSteps()
          ).unwrap();
          // const page = stepNavigatorMapping[data?.step_no + 1];
          if (flag === "1") {
            history.push(ROUTES.underReviewKYC);
          } else {
            history.push(identify_step?.data?.next_step_url);
          }
        } else if (status === API_STATUS.ERROR) {
          errorToast(meta?.errorMessage);
        }
      } catch (e) {
        console.log("error", e);
        setIsLoggingIn(false);
      } finally {
        setIsLoggingIn(false);
        dispatch(setSidebarTabValue(INSTALLMENT_LOAN_ROUTES.MAIN));
      }
    }
    if (status === API_STATUS.ERROR) {
      errorToast(error?.message);
    }
    setIsLoggingIn(false);
  };

  const startTimer = () => {
    setMinutes(TIMEOUT_MINUTES);
    setSeconds(TIMEOUT_SECONDS);
    hanldeResendOTP();
  };

  const hanldeResendOTP = async () => {
    try {
      const { status, data, meta } = await dispatch(
        resendOTP({
          id: otpId,
          req_source: REQUESTED_SOURCE.LOGIN,
          channel: FIELD_CHANNEL.SMS,
          platform: 1,
          send_to: phoneNumberValue,
          semilla,
        })
      ).unwrap();
      setOtpNumber(phoneNumberValue);

      if (status === API_STATUS.SUCCESS) {
        setOtpId(data?.id || "");
      }
      if (status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
    } catch (e) {}

    // finally {
    //   setPreferredLoginMethod(VERIFICATION_METHOD.OTP);
    //   // startTimer();
    // }
  };

  const handleEdit = () => {
    setDidSendOTP(false);
  };

  return (
    <Box
      style={{
        margin: "0 auto",
        display: "grid",
        placeItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <Typography
            style={{
              color: "#fff",
              fontFamily: URBANIST_FONT.REGULAR,
              marginLeft: 60,
              fontSize: 16,
            }}
          >
            Número de celular (10 dígitos)
          </Typography>
          <div
            className="telInput login"
            style={{
              display: "flex",
              flex: 1,
              alignItems: "center",
            }}
          >
            <span className={css.phoneIconContainer}>
              <PhoneIcon className={css.phoneIconStyle} />
            </span>
            <div style={{ flex: 1, position: "relative" }}>
              <InputPhone
                name="phoneNumber"
                label=""
                handleChange={(e) => {
                  setPhoneNumberValue(e);
                }}
                value={phoneNumberValue}
                errors={() => {}}
                touched={() => {}}
                handleBlur={() => {}}
                centeredLabel={undefined}
                stylesInput={undefined}
                inputStyle={{
                  backgroundColor: "#fff",
                }}
                disabled={didSendOTP}
                buttonStyle={{ backgroundColor: "#fff" }}
                placeholder="Ingrese su número de teléfono registrado de 10 dígitos"
              />
              {didSendOTP && (
                <Box
                  onClick={handleEdit}
                  sx={{
                    cursor: "pointer",
                    position: "absolute",
                    px: 1,
                    right: 2,
                    top: 7,
                    height: 53,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <EditIcon style={{ width: 24, height: 24 }} />
                  {/* <Typography
                    color="#fff"
                    fontFamily={URBANIST_FONT.BOLD}
                    fontSize={16}
                    sx={{
                      textDecoration: "underline",
                      color: customColor.accentBlue,
                    }}
                  >
                    Editar
                  </Typography> */}
                </Box>
              )}
            </div>
          </div>
        </Box>

        {didSendOTP ? (
          <div>
            <Typography
              style={{
                color: "#fff",
                fontFamily: URBANIST_FONT.REGULAR,
                marginLeft: 60,
                fontSize: 16,
              }}
            >
              Ingresar código
            </Typography>
            <TextInput
              id="otp"
              placeholder="Ingresar código"
              icon={<LockIcon className={css.textInputIconStyle} />}
              className={css.input}
              value={otpValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re.test(e.target.value)) {
                  setOtpValue(e.target.value);
                }
              }}
              maxLength={6}
              style={{
                width: "100%",
                height: 48,
                border: "1px solid rgba(0,0,0,0.2)",
                minWidth: "auto",
              }}
            />
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              style={{ paddingLeft: 52 }}
            >
              <Typography
                variant="subtitle2"
                align="center"
                className={css.timerText}
              >
                <span className="timer">
                  {`0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`}
                </span>
                <span
                  className="resend"
                  onClick={() => {
                    if (!minutes && !seconds) {
                      startTimer();
                    }
                  }}
                  style={{
                    opacity: !minutes && !seconds ? 1 : 0.5,
                    cursor: !minutes && !seconds ? "pointer" : "not-allowed",
                  }}
                >
                  Reenviar codigo
                </span>
              </Typography>
            </Grid>

            <Typography
              color="#fff"
              fontSize={16}
              my={2}
              textAlign="center"
              width="100%"
            >
              Escribe el código que enviamos a tu Whatsapp ****
              {otpNumber.slice(otpNumber.length - 4, otpNumber.length)}
            </Typography>

            <div
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                className={css.otpAlternativeText}
                onClick={() => {
                  setPreferredLoginMethod(VERIFICATION_METHOD.PASSWORD);
                }}
              >
                ¿Prefieres iniciar sesión con tu correo electrónico?
              </div>
            </div>
          </div>
        ) : (
          <div style={{ display: "grid", placeItems: "center" }}>
            <div
              className={css.otpAlternativeText}
              onClick={() => {
                setPreferredLoginMethod(VERIFICATION_METHOD.PASSWORD);
              }}
            >
              ¿Prefieres iniciar sesión con tu correo electrónico?
            </div>
          </div>
        )}
      </div>

      <div className={css.loginButtonContainer}>
        {didSendOTP ? (
          <Button
            label="Iniciar Sesión"
            onClick={handleLogin}
            isSubmitting={isLoggingIn}
            className={css.otpLoginButtonStyle}
          />
        ) : (
          <Button
            label="Continuar"
            onClick={handleSendOTP}
            isSubmitting={isSendingOTP}
            className={css.otpLoginButtonStyle}
          />
        )}
      </div>
    </Box>
  );
};

export default OTPPhoneNumber;
