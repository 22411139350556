import { Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import CircleButtonStyles from "./styles";

const CircleButton = (props) => {
  const { monthlyInstallments, selected, amount, isWeeklySelected } = props;
  const classes = CircleButtonStyles();
  const { t } = useTranslation();
  const langPrefix = "instantLineCreditTS.creditRequest";

  const currencyFormat = (num) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  selected &&
    localStorage.setItem(
      "financialOption",
      JSON.stringify({
        monthlyInstallments: monthlyInstallments,
        monthlyPayment: amount,
      })
    );

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.gridStyles}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        className={
          selected ? classes.circleSelected : classes.circleNotSelected
        }
      >
        <Typography variant="h1" className={classes.textNumberMonth}>
          {monthlyInstallments}
        </Typography>
        <Typography variant="subtitle1" className={classes.textMonth}>
          meses
        </Typography>
      </Box>
      <Box className={classes.boxStyle}>
        <Typography variant="subtitle1" className={classes.textMonth}>
          {isWeeklySelected
            ? t(`${langPrefix}.selector.weeklyPayment`, {
                term: monthlyInstallments * 2,
              })
            : t(`${langPrefix}.selector.monthlyPayment`, {
                term: monthlyInstallments,
              })}
        </Typography>

        <Typography variant="h2" className={classes.textLastAmount}>
          ${currencyFormat(amount)}
        </Typography>
      </Box>
    </Box>
  );
};

export default CircleButton;
