import { Box, Grid, Typography } from "@mui/material";

import AccordionComponent from "../../../components/AccordionComponent";
import useStyle from "./styles";
import question from "../../../assets/home/question-v2.svg";
import faqsGirl from "../../../assets/home/faqsGirl.svg";
import { URBANIST } from "../../../constants/fontsConst";

export const FaqsContent = () => {
  const css = useStyle();
  const faqs = [
    {
      title: "¿Cómo funciona esta solución?",
      description:
        "Medsi es tu mejor opción para financiar procedimientos médicos. Para obtener un crédito, debes completar el proceso de solicitud en línea o en la aplicación, compartirnos los datos del médico y del procedimiento que te quieres realizar y elegir el plan de pagos que más te acomoda. ",
    },
    {
      title: "Una vez aprobado el crédito, ¿qué tengo que hacer?",
      description:
        "¡Nada! Nosotros nos encargamos del resto: contactaremos al médico que has elegido y realizaremos el pago directamente al doctor una vez que se realice tu procedimiento. ",
    },
    {
      title:
        "¿Qué tipo de procedimientos puedo financiar a través de esta solución?",
      description:
        "Por el momento, estamos enfocados en procedimientos electivos, principalmente cosméticos y estéticos. Nuestra aspiración es poder cubrir el espectro completo de servicios médicos a través de una mayor variedad de productos y soluciones, que iremos dándote a conocer conforme los vayamos introduciendo.",
    },
  ];

  return (
    <Grid container sx={{ mt: {xs:4, lg:10}, paddingLeft: {xs:4, lg:8} }}>
      <Grid item xs={12} md={4}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "centrer",
          }}
        >
          <Typography
            sx={{
              fontFamily: URBANIST.SEMIBOLD,
              fontSize: { xs: 24 },
            }}
          >
            Preguntas Frecuentes
          </Typography>
          <img src={question} alt="questionMark" />
        </Box>
        <Box sx={{ ml: { xs: 5 } }}>
          <img src={faqsGirl} alt="girlThinking" />
        </Box>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box sx={{ width: {md:650} }}>
          <AccordionComponent list={faqs} />
          <Box textAlign="right" mt={4} className={css.linkContainer}>
            <div className={css.circle} />
            <a href="/faqs" target="_blank" className={css.link}>
              Ver todas las preguntas
            </a>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default FaqsContent;
