import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  customIndicatorStyle: {
    marginLeft: 20,
    color: "#C4C4C4",
    cursor: "pointer",
    width: "16px",
    height: "16px",
    borderRadius: "360px",
  },

  card: {
    width: "80%",
    height: "375px",
    background: "#FFF",
    boxShadow: "0px 10px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0 50px",
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 20,
  },
  imgContainer: {
    height: "auto",
    width: "fit-content",
  },
  cardTitle: {
    fontFamily: "Nexa-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "22px",
    lineHeight: "25px",
    color: "#28447C",
    marginTop: 16,
    [theme.breakpoints.down(601)]: {
      whiteSpace: "normal",
    },
  },
  viewMore: {
    position: "absolute",
    bottom: 50,
    textAlign: "right",
    width: "70%",
    color: "#00B4BD",
    fontFamily: "SFUIText-Regular",
    fontWeight: "600",
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    padding: "3px 0",
  },
  arrowContainer: {
    width: "fit-content",
    height: "max-content",
    marginLeft: 12,
    "& > img": {
      width: 9,
    },
  },
  hoverListItemStyle: {
    fontSize: "15px !important",
    fontWeight: "300 !important",
    color: "#54595f",
    fontFamily: "SFUIText-Regular",
    textAlign: "left",
    [theme.breakpoints.down(601)]: {
      whiteSpace: "break-spaces",
      "&:before": {
        content: "'•'",
        marginRight: 3,
      },
    },
  },
}));

export default useStyles;
