import { Grid, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TermConditionTextStyles from './TermsAndConditions.styles';
import AddCircle from '@mui/icons-material/AddCircle';
import {
  deleteApplicationId,
  getAllApplication,
  submitPromocode,
} from '../../../store/slices/users/actions';
import {
  API_STATUS,
  PROMOCODE_STATUS,
  SIGNATURE_STATUS,
} from '../../../constants/common';
import { successToast, errorToast } from '../../../utils/toast';
import { useHistory } from 'react-router-dom';
import { ROUTES, INSTALLMENT_LOAN_ROUTES } from '../../../constants/routes';
import { getBalanceSummary } from '../../../features/installmentLoan/store/installmentLoan';
import customColor from '../../../theme/customColor';

const CongratulationPromoCode = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    setOpenCongratsPromo,
    setShowPromoCode,
    setPromoPopUp,
    message,
    code,
    validCode,
    setupdateData,
  } = props;
  const classes = TermConditionTextStyles();

  const handleClose = () => {
    setOpenCongratsPromo(false);
  };

  const handleContinue = async () => {
    setPromoPopUp(false);
    setShowPromoCode(true);
    setOpenCongratsPromo(false);
    if (validCode) {
      //call promocdoe api
      const response = await dispatch(
        submitPromocode({ promo_code: code?.toUpperCase() })
      );

      if (response?.payload?.status === API_STATUS.SUCCESS) {
        setupdateData(true);
        successToast(PROMOCODE_STATUS[response.payload.status]);
      } else {
        errorToast(SIGNATURE_STATUS[response.payload.status]);
      }
    }
  };

  return (
    <div className={classes.mainContainer}>
      <Box
        sx={(theme) => ({
          color: customColor.celticBlue,
          fontFamily: 'SFUIText-bold',
          fontSize: '36px',
          marginTop: '79px',
          [theme.breakpoints.down('lg')]: {
            fontFamily: 'Urbanist-Bold',
            fontSize: '30px',
          },
        })}
      >
        Felicidades!
      </Box>
      <Box
        sx={(theme) => ({
          alignItems: 'center',
          marginTop: '70px',
          marginLeft: '70px',
          marginRight: '70px',
          fontSize: '32px',
          [theme.breakpoints.down('lg')]: {
            fontFamily: 'Urbanist-Regular !important',
            fontSize: '20px',
            margin: '2rem auto',
          },
        })}
      >
        {message}
      </Box>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: 50,
          marginTop: 40,
        }}
      >
        <div>
          <button
            style={{
              padding: '16px 92px',
              background: 'linear-gradient(270deg, #1B63DB 0%, #0ACC97 100%)',
              borderRadius: '100px',
              color: '#FFFFFF',
              marginLeft: 20,
            }}
            onClick={handleContinue}
          >
            Continuar
          </button>
        </div>
      </div>
    </div>
  );
};

export default CongratulationPromoCode;
