import InfoIcon from "@mui/icons-material/Info";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import DonoutChart from "simple-react-donut-chart";
import "simple-react-donut-chart/src/style.css";
import installmentLoanApi from "../../../../src/apis/instalmentLoan";
import { DECISION_STATUS, URBANIST_FONT } from "../../../constants/common";
import {
  selectCosignerStatus,
  selectCreditDecision,
} from "../../../store/slices/users";
import { currencyFormat } from "../../../utils";
import useStyles from "./styles";

const CreditDetails = (props) => {
  const {
    decisionStatus,
    creditLimit,
    LmsBalanceSummary,
    dueAmount,
    outstandingAmount,
    dueDate,
    open_to_buy,
    loading,
  } = props;
  const css = useStyles();
  const [open, setOpen] = React.useState(false);
  const [principleAmt, setPrincipleAmt] = React.useState(0);
  const [interestAmt, setInterestAmt] = React.useState(0);
  const [vatAmt, setVatAmt] = React.useState(0);

  const storeCosignerStatus = useSelector(selectCosignerStatus);
  const creditDecision = useSelector(selectCreditDecision);

  const [paymentHtml, renderPaymentHtml] = useState("<div></div>");

  const paymentref = useRef(null);

  const percent = Math.round((open_to_buy / creditLimit) * 100);

  const canAddCosigner =
    creditDecision?.creditEligibilityData?.[0]?.can_add_co_signer || false;

  useEffect(() => {
    let principle;
    let vat;
    let interest;
    if (LmsBalanceSummary) {
      LmsBalanceSummary?.ob_list?.map((each) => {
        each?.items?.map((item) => {
          principle = principle + (item.principal_amt - item.principal_paid);

          interest = interest + (item.interest_amt - item.interest_paid);

          vat = vat + (item.vat_amt - item.vat_paid);
        });
      });
    }
    setPrincipleAmt(principle);
    setVatAmt(vat);
    setInterestAmt(interest);
  }, [LmsBalanceSummary]);

  const handleInfoIcon = () => {
    setOpen(!open);
  };

  const fetchPaymentHtml = async () => {
    if (LmsBalanceSummary?.repayment_amt) {
      const data = await installmentLoanApi.postPaymentInitiate({
        outstanding_amt:
          (LmsBalanceSummary?.repayment_amt &&
            LmsBalanceSummary?.repayment_amt) ||
          0,
      });

      renderPaymentHtml(data?.data || "<div></div>");

      const mexPagoPaymentRef = paymentref?.current;
      if (mexPagoPaymentRef) {
        const FormElement = mexPagoPaymentRef?.querySelector("form");
        FormElement.target = "_blank";
        mexPagoPaymentRef?.querySelector("button[type=submit]")?.click();
        mexPagoPaymentRef?.querySelector("input[type=submit]")?.click();
      }
    }
  };

  return (
    <Box
      sx={(theme) => ({
        background: "#FFFFFF",
        borderRadius: "20px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        position: "relative",
        marginTop: "5px",
        minHeight: "90%",
        // [theme.breakpoints.down("md")]: {
        //   margin: "0px 10px",
        // },
        [theme.breakpoints.down("lg")]: {
          width: "80%",
          margin: "1rem 0px 0px 58px",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          fontFamily: `${URBANIST_FONT.REGULAR} !important`,
        },
        [theme.breakpoints.down("md")]: {
          width: "60%",
          margin: "1rem 0px 1rem 85px",
        },
        [theme.breakpoints.down("tablet")]: {
          width: "90%",
          margin: "1rem auto",
          fontSize: "12px !important",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          padding: "48px",
          paddingBottom: 0,
          [theme.breakpoints.down("sm")]: { padding: "20px" },
          [theme.breakpoints.down("tablet")]: { padding: "1rem 2rem 0" },
        })}
      >
        {/* {decisionStatus && decisionStatus !== "approved" && (
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            background: "black",
            opacity: 0.5,
            borderRadius: 20,
            zIndex: 15,
          }}
        />
      )} */}

        {/* for rejected */}
        {/* {decisionStatus === "rejected" && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            margin: "0 56px",
            background: "#FFFFFF",
            borderRadius: 8,
            textAlign: "center",
            padding: 30,
            zIndex: 20,
          }}
        >
          <p
            style={{
              fontFamily: "SFUIText-Regular",
              fontSize: 18,
              lineHeight: "21px",
              color: "#000000",
            }}
          >
            Your application is rejected. We need more information for better
            underwriting.
          </p>

          <button
            style={{
              background: "#3552CC",
              borderRadius: 8,
              fontWeight: 800,
              fontSize: 14,
              lineHeight: "17px",
              padding: 10,
              color: "white",
              marginTop: 18,
            }}
          >
            Add Co-signer Details
          </button>
        </div>
      )} */}
        {/* for Pending */}
        {/* {decisionStatus === "pending" && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            margin: "0 56px",
            background: "#FFFFFF",
            borderRadius: 8,
            textAlign: "center",
            padding: 30,
            zIndex: 20,
          }}
        >
          <p
            style={{
              fontFamily: "SFUIText-Regular",
              fontSize: 18,
              lineHeight: "21px",
              color: "#000000",
            }}
          >
            We are on the process of reviewing your application.
          </p>

          <button
            style={{
              background: "rgba(248, 200, 60, 0.2)",
              borderRadius: 8,
              fontWeight: 800,
              fontSize: 14,
              lineHeight: "17px",
              padding: "10px 26px",
              color: "#F1A720",
              marginTop: 18,
              cursor: "not-allowed",
            }}
            disabled={true}
          >
            Under Review
          </button>
        </div>
      )} */}
        <div>
          <ReactApexChart
            series={[open_to_buy || 0.0001, outstandingAmount || 0]}
            type="donut"
            width="100%"
            height="100%"
            options={{
              labels: ["Crédito Disponible", "Monto Utilizado"],
              colors: ["#3552CC", "#E6E6E6"],
              chart: {
                type: "donut",
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return `$ ${val === "0.0001" ? 0 : val.toLocaleString()}`;
                  },
                },
              },
              dataLabels: {
                enabled: false,
              },
              states: {
                hover: {
                  filter: {
                    type: "none",
                  },
                },
              },
              plotOptions: {
                pie: {
                  customScale: 1,
                  donut: {
                    size: "80%",
                    labels: {
                      show: true,
                      name: {
                        show: true,
                        // fontSize: "10rem",
                        // fontFamily: "Rubik",
                        color: "#dfsda",
                        offsetY: -10,
                        // formatter: function (val) {
                        //   return val;
                        // },
                      },
                      value: {
                        show: true,
                        // fontSize: "6rem",
                        // fontFamily: "Helvetica, Arial, sans-serif",
                        color: undefined,
                        offsetY: 16,
                        formatter: function (val) {
                          const updatedValue = val === 0.0001 ? 0 : val;
                          return (
                            "$" + parseFloat(updatedValue).toLocaleString()
                          );
                        },
                      },
                      total: {
                        show: true,
                        label: "Total Aprobado",
                        fontSize: "0.8rem",
                        color: "#373d3f",
                        formatter: function (w) {
                          const total = w.globals.seriesTotals.reduce(
                            (a, b) => {
                              return a + b;
                            },
                            0
                          );
                          return (
                            "$" +
                            (total === 0.0001 ? 0 : total).toLocaleString()
                          );
                        },
                      },
                    },
                  },
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: "100%",
                      height: 250,
                    },
                    legend: {
                      position: "bottom",
                    },

                    // plotOptions: {
                    //   pie: {
                    //     customScale: 1.8,
                    //     donut: {
                    //       size: "70%",
                    //       labels: {
                    //         show: true,
                    //         name: {
                    //           show: true,
                    //           color: "#dfsda",
                    //           offsetY: -10,
                    //         },
                    //         value: {
                    //           show: true,
                    //           color: undefined,
                    //           offsetY: 16,
                    //           formatter: function (val) {
                    //             const updatedValue = val == 0.0001 ? 0 : val;
                    //             return (
                    //               "$" +
                    //               parseFloat(updatedValue).toLocaleString()
                    //             );
                    //           },
                    //         },
                    //         total: {
                    //           show: true,
                    //           label: "Total Aprobada",
                    //           fontSize: "0.8rem",
                    //           color: "#373d3f",
                    //           formatter: function (w) {
                    //             const total = w.globals.seriesTotals.reduce(
                    //               (a, b) => {
                    //                 return a + b;
                    //               },
                    //               0
                    //             );
                    //             return (
                    //               "$" +
                    //               (total === 0.0001
                    //                 ? 0
                    //                 : total
                    //               ).toLocaleString()
                    //             );
                    //           },
                    //         },
                    //       },
                    //     },
                    //   },
                    // },
                  },
                },
              ],
            }}
          />

          {/* <DonoutChart
            percentage={percent}
            colorOn="#3552CC"
            colorOff="#E6E6E6"
            labelOn="Crédito Disponible"
            labelOff="Monto Utilizado"
            circleColor="#FFFFFF"
            baseClass="customize"
            textStyle={{
              color: "#ff0000",
            }}
            labelStyle={{
              off: {
                fontSize: "16px",
              },
              on: {
                fontSize: "18px",
              },
            }}
          /> */}

          {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: 100,
              height: 100,
              position: "absolute",
              top: 0,
              zIndex: 10,
              left: 0,
            }}
          >
            <p
              style={{
                fontWeight: 800,
                fontSize: 12,
                color: "#3552CC",
                textAlign: "center",
                margin: 0,
              }}
            >
              ${currencyFormat(creditLimit)}
              {!creditLimit && "0.0"}
            </p>
            <p
              style={{
                fontWeight: 500,
                fontSize: 10,
                textAlign: "center",
                margin: 0,
              }}
            >
              Línea Total <br />
              Aprobada
            </p>
          </div> */}
        </div>
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            gap: "20px",
            fontFamily: "SFUIText-semibold",
            fontSize: "16px",
            lineHeight: "24px",
            marginTop: "30px",
            [theme.breakpoints.down("lg")]: {
              fontFamily: URBANIST_FONT.REGULAR,
            },
          })}
        >
          <div>
            <Box
              sx={(theme) => ({
                color: "#9E9E9E",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "14px",
                  color: "#000",
                },
                [theme.breakpoints.down("tablet")]: { fontSize: "12px" },
              })}
            >
              Crédito Disponible
            </Box>
            <Box
              sx={(theme) => ({
                color: "#000000",
                marginTop: "10px",
                [theme.breakpoints.down("lg")]: {
                  fontFamily: URBANIST_FONT.BOLD,
                  marginTop: "5px",
                },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "14px",
                  marginTop: "0px",
                },
              })}
            >
              {loading
                ? ""
                : open_to_buy
                ? `$ ${open_to_buy && open_to_buy.toLocaleString()}`
                : "$ 0.0"}
            </Box>
          </div>
          <div>
            <Box
              sx={(theme) => ({
                color: "#9E9E9E",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "14px",
                  color: "#000",
                },
                [theme.breakpoints.down("tablet")]: { fontSize: "12px" },
              })}
            >
              Monto Utilizado
            </Box>
            <Box
              sx={(theme) => ({
                color: "#000000",
                marginTop: "10px",
                [theme.breakpoints.down("lg")]: {
                  fontFamily: URBANIST_FONT.BOLD,
                  marginTop: "5px",
                },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "14px",
                  marginTop: "0px",
                },
              })}
            >
              {loading
                ? ""
                : outstandingAmount
                ? `$ ${outstandingAmount.toLocaleString()}`
                : "$ 0.0"}
            </Box>
          </div>
          <div>
            <Box
              sx={(theme) => ({
                color: "#9E9E9E",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "14px",
                  color: "#000",
                },
                [theme.breakpoints.down("tablet")]: { fontSize: "12px" },
              })}
            >
              Saldo por Pagar
            </Box>
            <Box
              sx={(theme) => ({
                color: "#000000",
                marginTop: "10px",
                [theme.breakpoints.down("lg")]: {
                  fontFamily: URBANIST_FONT.BOLD,
                  marginTop: "5px",
                },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "14px",
                  marginTop: "0px",
                },
              })}
            >
              {loading
                ? ""
                : dueAmount
                ? `$ ${dueAmount?.toLocaleString()}`
                : "$ 0.0"}
              <div style={{ position: "relative", display: "inline-block" }}>
                <span
                  onClick={handleInfoIcon}
                  style={{
                    color: "#3552CC",
                    position: "relative",
                    top: 6,
                    cursor: "pointer",
                  }}
                >
                  {/* <InfoIcon /> */}
                </span>
                {/* from here */}
                {open && (
                  <div
                    style={{
                      background: "#FFFFFF",
                      border: "1px solid #F5F5F5",
                      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                      borderRadius: 10,
                      width: 230,
                      position: "absolute",
                      left: 36,
                      top: 5,
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        left: -12,
                        top: 5,
                        display: "inlineBlock",
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "7.5px 12px 7.5px 0",
                        borderColor:
                          "transparent #F5F5F5 transparent transparent",
                      }}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: -11,
                        top: 5,
                        display: "inlineBlock",
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "7.5px 12px 7.5px 0",
                        borderColor:
                          "transparent #ffffff transparent transparent",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 12,
                        padding: "8px 12px",
                      }}
                    >
                      <div style={{ color: "#9E9E9E" }}>Principal Amount</div>
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        ${LmsBalanceSummary?.summary?.principal_total}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 12,
                        padding: "8px 12px",
                      }}
                    >
                      <div style={{ color: "#9E9E9E" }}>Interest charges</div>
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        ${LmsBalanceSummary?.summary?.interest_total}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 12,
                        padding: "8px 12px",
                      }}
                    >
                      <div style={{ color: "#9E9E9E" }}>Late fee</div>
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        ${LmsBalanceSummary?.fee_detail?.fee_sum}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 12,
                        padding: "8px 12px",
                      }}
                    >
                      <div style={{ color: "#9E9E9E" }}>Other Charges</div>
                      <div
                        style={{
                          textAlign: "right",
                        }}
                      >
                        $80.00
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        fontSize: 12,
                        padding: "12px",
                        borderTop: "1px solid #E6E6E6",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Total Due
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          fontWeight: "bold",
                        }}
                      >
                        ${LmsBalanceSummary?.repayment_amt}
                      </div>
                    </div>
                  </div>
                )}
                {/* to here */}
              </div>
            </Box>
          </div>

          {decisionStatus === "approved" && (
            <Box
              sx={(theme) => ({
                "& button": {
                  padding: "10px 30px",
                  background:
                    LmsBalanceSummary && dueAmount > 0 ? "#3552CC" : "#6B7779",
                  color: "#ffffff",
                  borderRadius: "8px",
                  marginTop: "10px",
                  [theme.breakpoints.down("lg")]: {
                    borderRadius: "30px",
                    fontFamily: URBANIST_FONT.SEMIBOLD,
                    fontSize: "14px",
                  },
                  [theme.breakpoints.down("tablet")]: { padding: "10px 1rem" },
                },
              })}
            >
              <button onClick={dueAmount > 0 && fetchPaymentHtml}>
                Pagar Ahora
              </button>
              <div
                dangerouslySetInnerHTML={{ __html: paymentHtml }}
                ref={paymentref}
                style={{ display: "none" }}
              ></div>
            </Box>
          )}
        </Box>
        {/* <p style={{ textAlign: 'center', color: '#9E9E9E', marginTop: 20 }}> */}
        <p className={css.paymentDate}>
          <span>
            {dueAmount === 0 ||
            decisionStatus === DECISION_STATUS.PENDING ||
            decisionStatus === DECISION_STATUS.REJECTED ? (
              <span>No hay pagos pendientes por ahora</span>
            ) : dueDate ? (
              <span>
                Tu próxima fecha de pago es:
                <span id="date" style={{ color: "#000000", padding: "0 4px" }}>
                  {moment(dueDate).format("MMMM DD, YYYY")}
                </span>
              </span>
            ) : (
              <></>
            )}
          </span>
        </p>
      </Box>

      {decisionStatus === "pending" && (
        <div
          style={{
            paddingTop: 18,
            paddingBottom: 19,
            // textAlign: "center",
            background: "#FDEAAF",
            borderRadius: "0px 0 20px 20px",
            color: "#F1A720",
            fontFamily: "SFUIText-bold",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <Typography
            mx={2}
            color="#F1A720"
            fontSize={14}
            sx={(theme) => ({
              fontFamily: "SFUIText-bold",
              [theme.breakpoints.down("lg")]: {
                fontFamily: "Urbanist-Bold",
              },
            })}
          >
            {canAddCosigner || storeCosignerStatus.has_cosigner
              ? "Te hemos incluido en nuestra Lista de Espera. Te notificaremos cuando tengamos alguna solución de crédito adecuada para ti."
              : "Su solicitud de crédito está bajo revisión!"}
          </Typography>
        </div>
      )}

      {decisionStatus === "rejected" && (
        <div
          style={{
            marginTop: 40,

            paddingTop: 18,
            paddingBottom: 19,
            textAlign: "center",
            background: "#FFB8B8",
            borderRadius: "0px 0 20px 20px",
            color: "#E92736",
            fontFamily: "SFUIText-bold",
          }}
        >
          Su solicitud de crédito es rechazada
        </div>
      )}
    </Box>
  );
};

export default CreditDetails;
