import { Grid, FormHelperText, Box } from "@mui/material";
import { useState, memo } from "react";

import InputNumber from "../../../../components/sharedComponents/InputNumber";
import { currencyFormat } from "../../../../utils";
import { getNumber } from "../../../common/ProcedureForm/helpers";
import { handleCreditLimit } from "../../helpers";
import styles from "../styles";

const RenderNumber = ({ formik, maxCreditAmt, minCreditAmount }) => {
  const css = styles();

  const [message, setMessage] = useState(false);

  const { values, touched, errors, handleBlur, setFieldValue } = formik;

  const renderNumberInput = ({ label, value, setField, message }) => {
    return (
      <>
        <InputNumber
          formControlStyles={css.formControl}
          textLabel={label}
          name={value}
          value={values[value]}
          prefix="$"
          errors={errors[value]}
          touched={touched[value]}
          setField={(_, amount) => {
            setField && setField(_, amount);
          }}
          field={value}
          handleBlur={handleBlur}
          labelStyle={{ color: "#000" }}
        />
        {message || null}
      </>
    );
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        {renderNumberInput({
          label: (
            <span style={{ whiteSpace: "nowrap" }}>
              Costo aproximado del procedimiento
            </span>
          ),
          value: "procedureCost",
          setField: (_, amount) => {
            setFieldValue("procedureCost", amount);
            handleCreditLimit({
              amount,
              formik,
              maxCreditAmt,
              setMessage,
              errors,
              minCreditAmount,
            });
          },
          message: message && (
            <Box className={css.límite}>
              {/* {`Por el momento, el monto máximo del crédito que podemos
                          otorgarte es de $${currencyFormat(maxCreditAmt)}`} */}
              {`Por el momento, el monto máximo del crédito que podemos
                          otorgarte es de $${currencyFormat(80000)}`}
            </Box>
          ),
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        {renderNumberInput({
          label: "Monto del crédito solicitado",
          value: "requestedAmount",
          setField: (_, amount) => {
            let amt = getNumber(amount);
            if (formik?.values?.clinicName) {
              amt = amt > maxCreditAmt ? maxCreditAmt : amt;
            } else {
              amt = amt > 80000 ? 80000 : amt;
            }
            setFieldValue("requestedAmount", amt);
          },
        })}
        <Box className={css.límite}>
          El límite de $80,000 aplica solamente para ciertos socios comerciales;
          otras clínicas y médicos pueden estar sujetas a un límite de crédito
          menor.
        </Box>
      </Grid>
    </>
  );
};

export default memo(RenderNumber);
