/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 *
 * Panel
 *
 */

import PropTypes from "prop-types";
import { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import Payment from "../../assets/payment.png";
import paymentOutlined from "../../assets/Icons/payment_outlined.png";
import { currencyFormat } from "../../utils/index";

const PanelPaymentSchedule = ({ title, body, open, status, each }) => {
  const [panelOpen, setPanelOpen] = useState(false);

  const [openVal, setOpenVal] = useState(open || panelOpen);

  const handlePanel = () => {
    setPanelOpen(!panelOpen);
    if (!open) {
      setOpenVal(!openVal);
    }
  };

  return (
    <div style={{ marginBottom: 10 }}>
      <div
        aria-controls="panel2a-content"
        id="panel2a-header"
        onClick={() => handlePanel()}
      >
        <Box
          sx={(theme) => ({
            background: "rgba(53, 82, 204, 0.05)",
            padding: `10px 14px`,
            marginBottom: "8px",
            cursor: "pointer",
            boxShadow: "0px 4px 4px rgba(53, 82, 204, 0.25)",
            display: "grid",
            gridTemplateColumns: "80px 1fr 60px",
            [theme.breakpoints.down("lg")]: {
              padding: "1rem",
              borderRadius: "2px",
              boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)",
              marginBottom: "1.5rem",
            },
            [theme.breakpoints.down("tablet")]: {
              position: "relative",
              gridTemplateColumns: "1fr",
              borderRadius: "20px",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: { alignItems: "start" },
              [theme.breakpoints.down("tablet")]: { display: "none" },
            })}
          >
            <Box
              sx={(theme) => ({
                width: "60px",
                height: "60px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                "& img": { height: "33px" },
                "& #payment_outlined": { display: "none" },
                [theme.breakpoints.down("lg")]: {
                  width: "40px",
                  height: "40px",
                  boxShadow: "unset",
                  "& img": { display: "none", height: "30px", width: "30px" },
                  "& #payment_outlined": { display: "block" },
                },
              })}
            >
              <img src={Payment} style={{ height: 33 }} alt="" id="payment" />
              <img alt="" src={paymentOutlined} id="payment_outlined" />
            </Box>

            {/* <div>
              <div
                style={{
                  color: '#9E9E9E',
                  fontSize: 18,
                }}
              >
                {title}
              </div>
              <div
                style={{
                  fontSize: 16,
                  color: '#000000',
                  marginTop: 10,
                  fontFamily: 'SFUIText-semibold',
                }}
              >
                {each.lp_id.split('-').pop()}
              </div>
            </div> */}
          </Box>

          <Box
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "repeat(5, auto)",
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "repeat(3, auto)",
                rowGap: "1rem",
              },
              [theme.breakpoints.down("tablet")]: {
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "1rem 10px",
              },
            })}
          >
            <div style={{ flex: 1, textAlign: "left" }}>
              <Box
                sx={(theme) => ({
                  color: "#9E9E9E",
                  fontSize: "18px",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Regular",
                    fontSize: "14px",
                  },
                })}
              >
                {title}
              </Box>
              <Box
                sx={(theme) => ({
                  fontSize: "16px",
                  color: "#000000",
                  marginTop: "10px",
                  fontFamily: "SFUIText-semibold",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Bold",
                  },
                })}
              >
                {each.lp_id.split("-").pop()}
              </Box>
            </div>

            <div style={{ flex: 1, textAlign: "left" }}>
              <Box
                sx={(theme) => ({
                  color: "#9E9E9E",
                  fontSize: "18px",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Regular",
                    fontSize: "14px",
                  },
                })}
              >
                Fecha de desembolso
              </Box>
              <Box
                sx={(theme) => ({
                  marginTop: "10px",
                  fontFamily: "SFUIText-semibold",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Bold",
                  },
                })}
              >
                {each.disbursed_date}
              </Box>
            </div>

            <div style={{ flex: 1, textAlign: "left" }}>
              <Box
                sx={(theme) => ({
                  color: "#9E9E9E",
                  fontSize: "18px",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Regular",
                    fontSize: "14px",
                  },
                })}
              >
                Monto del préstamo
              </Box>
              <Box
                sx={(theme) => ({
                  marginTop: "10px",
                  fontFamily: "SFUIText-semibold",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Bold",
                  },
                })}
              >
                {" "}
                ${currencyFormat(each.disbursed_amt)}
              </Box>
            </div>

            <div style={{ flex: 1, textAlign: "left" }}>
              <Box
                sx={(theme) => ({
                  color: "#9E9E9E",
                  fontSize: "18px",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Regular",
                    fontSize: "14px",
                  },
                })}
              >
                No de pagos
              </Box>
              <Box
                sx={(theme) => ({
                  marginTop: "10px",
                  fontFamily: "SFUIText-semibold",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Bold",
                  },
                })}
              >
                {each.tenure}
              </Box>
            </div>

            <div style={{ flex: 1, textAlign: "left" }}>
              <Box
                sx={(theme) => ({
                  color: "#9E9E9E",
                  fontSize: "18px",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Regular",
                    fontSize: "14px",
                  },
                })}
              >
                Cantidad debida
              </Box>
              <Box
                sx={(theme) => ({
                  marginTop: "10px",
                  fontFamily: "SFUIText-semibold",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Bold",
                  },
                })}
              >
                ${currencyFormat(each.loan_due_amount)}
              </Box>
            </div>
          </Box>
          {/* <span
            className={`transition-transform flex px-4 py-2 ${
              openVal ? "transform rotate-180" : ""
            }`}
          > */}
          <Box
            sx={(theme) => ({
              display: "inline-flex",
              color: "green",
              alignItems: "center",
              marginLeft: "24px",
              [theme.breakpoints.down("tablet")]: {
                position: "absolute",
                top: "10px",
                right: "4px",
              },
            })}
          >
            <ChevronRight
              style={{
                color: "black",
                transform: openVal ? "rotate(90deg)" : "",
                transition: "all 0.3s ease-in-out",
              }}
            />{" "}
          </Box>
          {/* <ExpandMoreIcon /> */}
          {/* </span> */}
        </Box>
      </div>
      <Box
        sx={(theme) => ({
          overflow: "auto",
          transition: "all 0.3s ease-in-out",
          maxHeight: openVal ? "100vh" : "0px",
          top: -9,
          position: "relative",
          zIndex: 20,
          [theme.breakpoints.down("lg")]: { top: -24 },
        })}
      >
        <Box
          sx={(theme) => ({
            padding: "50px 20px",
            border: "1px solid #E6E6E6",
            [theme.breakpoints.down("lg")]: {
              padding: "1.5rem",
              borderColor: "rgba(0, 0, 0, 0.5)",
            },
            [theme.breakpoints.down("tablet")]: {
              padding: "1.5rem 1rem 0px",
              border: "transparent",
            },
          })}
        >
          {body}
        </Box>
      </Box>
    </div>
  );
};

PanelPaymentSchedule.propTypes = {
  title: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
  open: PropTypes.bool,
};

export default PanelPaymentSchedule;
