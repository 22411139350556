import { makeStyles } from "@mui/styles";
const ProcessInformationStyles = makeStyles((theme) => ({
  backgroundContainer: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    height: 200,
    width: "100%",

    background: "#002539",

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(601)]: {
      height: 250,
    },
  },
  mettaLogo: {
    width: 90,
    [theme.breakpoints.down(601)]: {
      width: 60,
    },
  },
  headerTextContainer: {
    margin: "auto",
    marginTop: theme.spacing(2),
    width: "100%",
    [theme.breakpoints.down(1001)]: {
      width: "80%",
    },
    [theme.breakpoints.down(601)]: {
      width: "90%",
    },
  },
  headerText: {
    fontSize: "38px !important",
    color: theme.palette.primary.white,
    marginTop: "5px !important",
    fontWeight: "700 !important",
    "& > p": {
      fontSize: "28px !important",
    },
    [theme.breakpoints.down(900)]: {
      fontSize: "28px !important",
      "& > p": {
        fontSize: "22px !important",
      },
    },
    [theme.breakpoints.down(600)]: {
      fontSize: "26px !important",
      "& > p": {
        fontSize: "20px !important",
      },
    },
  },
  button: {
    marginTop: 20,
    margin: "auto",
    textAlign: "center",
    "&>button": {
      maxWidth: "max-content !important",
    },
  },
  formControl: {
    marginTop: "5px !important",
    marginBottom: "10px !important",
    width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   width: "25ch !important",
    // },
  },
  formControlInput: {
    marginTop: "0px !important",
    marginLeft: "-10px !important",
  },
  selectTime: {
    height: 40,
    width: 90,
    borderRadius: "10px !important",
    marginRight: 20,
  },
  formControlContainer: {
    marginTop: "0 !important",
    [theme.breakpoints.down("md")]: {
      width: "5ch",
    },
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  titleTime: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontWeight: "bold !important",
    marginBottom: "-10px !important",
  },
  errorTime: {
    color: "red !important",
    [theme.breakpoints.down("md")]: {
      width: "25ch",
    },
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
  },
  formView: {
    // width: "71.5ch",
    // [theme.breakpoints.down("md")]: {
    //   width: "80%",
    // },
    margin: "0 auto",
    maxWidth: 690,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    "& >.first": {},
    "& >.second": {
      marginLeft: theme.spacing(6),
    },
  },
  inputNumberView: {
    marginTop: "10px !important",
    "& input": {
      padding: "16.5px 14px 16.5px 58px !important",
    },
  },
  timeContainer: {
    marginTop: "5px !important",
  },
  selectCss: {
    backgroundColor: "rgba(53, 82, 204, 0.5) !important",
    color: "rgba(107, 119, 121, 1) !important",
  },
  listItemSelectedStyle: {
    backgroundColor: "rgba(53, 82, 204, 1) !important",
    color: "white !important",
  },
  skipProcedure: {
    color: "#3552CC",
    cursor: "pointer",
    fontWeight: 700,
    size: "18px",
    marginBottom: "40px",
  },
  límite: {
    fontSize: "12px",
    color: "#686872",
    lineHeight: "14.32px",
    marginTop: 1,
    // maxWidth: "40ch",
    // [theme.breakpoints.down("md")]: {
    //   maxWidth: "30ch",
    // },
  },
  submitButton: {
    background: "linear-gradient(270deg, #1B63DB 2.5%, #0ACC97 100%)",
  },
}));

export default ProcessInformationStyles;
