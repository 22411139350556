import { Box, Typography } from "@mui/material";
import { useState } from "react";

import { useSelector } from "react-redux";
import SplashValidation from "../../components/sharedComponents/SplashValidation";
import { INSTALLMENT_LOAN_ROUTES, ROUTES } from "../../constants/routes";
import IdentityInformationStyles from "./styles";

import Logo from "../../assets/Logos/logoBlue.svg";
import Mati from "../../components/Mati";
import configKeys from "../../config/key";
import useStore from "../../store/getStore";
import {
  selectApplicantInfo,
  selectIsCosigner,
} from "../../store/slices/cosigner";

function UnderReviewKYC() {
  const isUserCosigner = selectIsCosigner();
  const applicantInfo = useSelector(selectApplicantInfo);
  const [loader, setLoader] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const classes = IdentityInformationStyles();
  const { auth } = useStore();

  const handleOnComplete = () => {
    setRedirect(true);
    setLoader(true);
  };

  if (loader) {
    return (
      <SplashValidation
        image={4}
        redirectTo={`${INSTALLMENT_LOAN_ROUTES.MAIN}`}
        trueRedirect={redirect}
      />
    );
  } else {
    return (
      <div className={classes.pageContainer}>
        <Box className={classes.headerPageBackground} />
        <Box
          className={classes.formContainer}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <img src={Logo} alt="logo" className={classes.logo} />
          <Typography variant="h2" className={classes.textTitle}>
            Información General
          </Typography>
          <>
            <Mati
              onComplete={handleOnComplete}
              flowId={configKeys.ONBOARDING_USER_SIGNUP_FLOW_ID}
              userId={auth.userId}
              isCosigner={isUserCosigner}
              isAutoLoad
              referenceId={auth.coSignerReferenceNumber}
            />
          </>
        </Box>
      </div>
    );
  }
}

export default UnderReviewKYC;
