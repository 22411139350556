import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";

import FooterLogo from "../../../assets/Logos/FooterLogo.svg";

import useStyles from "./styles";

export const Footer = () => {
  const css = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { REACT_APP_METTA_WEB_NEW_URL } = process.env;

  return (
    <Box className={css.container}>
      <Grid container>
        <Grid item md={4} xs={6}>
          <Box className={css.footerHeadingText}>Medsi</Box>
          <Box mt={1}>
            <a
              href={`${REACT_APP_METTA_WEB_NEW_URL}/privacy-policy`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Aviso de Privacidad</li>
              </ul>
            </a>
          </Box>
          <Box mt={1}>
            <a
              href={`${REACT_APP_METTA_WEB_NEW_URL}/terms`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Términos y Condiciones</li>
              </ul>
            </a>
          </Box>
        </Grid>
        <Grid item md={4} xs={6}>
          <Box className={css.footerHeadingText}>Ayuda</Box>
          <Box mt={1}>
            <a
              href={`${REACT_APP_METTA_WEB_NEW_URL}/specialized-service-unit`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Atención a Usuarios</li>
              </ul>
            </a>
          </Box>
          <Box mt={1}>
            <a
              href={`${REACT_APP_METTA_WEB_NEW_URL}/contact`}
              target="_blank"
              className={css.footerAnchorTagStyle}
            >
              <ul className={css.footerUlTagStyle}>
                <li>Contacto</li>
              </ul>
            </a>
          </Box>
        </Grid>

        <Grid item md={4} xs={12} mt={isMobile && 2}>
          <Box className={css.line} />
          <Box className={css.logo} mt={2}>
            <img src={FooterLogo} />
          </Box>
          <Box mt={2}>Munbrunn S.A. de C.V.</Box>
          <Box mt={4} maxWidth={370}>
            Emerson 316, Oficina 201, Piso 2, Colonia Polanco V Sección,
            Alcaldía Miguel Hidalgo, Ciudad de México, México C.P. 11570
          </Box>
          <Box mt={4}>Medsi 2022, Todos los Derechos Reservador</Box>
        </Grid>
      </Grid>

      <Box my={2.5} className={css.line} />
      <Grid container>
        <Grid item md={6} xs={12}>
          <Box mt={2} mr={!isMobile && 2.5}>
            Medsi es una marca en proceso de registro. El uso de este sitio
            implia la aceptación de los{" "}
            <a
              href={`${REACT_APP_METTA_WEB_NEW_URL}/terms`}
              target="_blank"
              className={css.footerAnchorTagStyle}
              style={{ textDecoration: "underline" }}
            >
              Términos y Condiciones
            </a>
            , así como del{" "}
            <a
              href={`${REACT_APP_METTA_WEB_NEW_URL}/privacy-policy`}
              target="_blank"
              className={css.footerAnchorTagStyle}
              style={{ textDecoration: "underline" }}
            >
              Aviso de Privacidad
            </a>{" "}
            de Munbrunn Sociedad Anonima de Capital Variable.
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <Box mt={2} ml={!isMobile && 2.5}>
            Munbrunn S.A. de C.V. para su constitución y operación no requiere
            autorización de la Secretaría de Hacienda y Crédito Público ni está
            sujeta a la supervisión de la Comisión Nacional Bancaria y de
            Valores.
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
