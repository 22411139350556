import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translations from ".";
const i18nInit = () => {
  if (i18n.isInitialized) return;

  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: "es",
      interpolation: {
        escapeValue: false,
      },

      react: {
        wait: false,
        bindI18n: "languageChanged loaded",
        bindI18nStore: "added removed",
        nsMode: "default",
      },
      resources: translations,
    });
};

export default i18nInit;
