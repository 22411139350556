import { createStyles, makeStyles } from "@mui/styles";
import { URBANIST_FONT } from "../../../constants/common";
import { themePalette } from "../../../theme/themePalette";

const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      minHeight: "100vh",
      backgroundColor: themePalette.maastrichtBlue,
    },
    logo: {
      height: 33,
      // paddingBottom: 30,
      [theme.breakpoints.down(601)]: {
        height: 30,
        // paddingBottom: 25,
      },
    },
    textTitle: {
      "&.MuiTypography-root": {
        fontSize: "2.37rem",
        marginTop: "0.62rem",
        fontFamily: URBANIST_FONT.BOLD,
        color: themePalette.white,
      },
    },
    message: {
      "&.MuiTypography-root": {
        marginTop: "5.37rem",
        fontFamily: URBANIST_FONT.REGULAR,
        fontSize: "1.25rem",
        color: themePalette.white,
        textDecoration: "underline",
      },
    },
    contactInfo: {
      "&.MuiTypography-root": {
        marginTop: "2.25rem",
        fontFamily: URBANIST_FONT.REGULAR,
        fontSize: "1rem",
        color: themePalette.white,
        "& .contact-email": {
          fontFamily: URBANIST_FONT.BOLD,
          fontSize: "1.25rem",
          marginTop: "10px",
        },
      },
    },

    textBox: {
      background: "rgba(255, 255, 255, 0.2) !important",
      border: "1px solid rgba(255, 255, 255, 0.2) !important",
      color: themePalette.primary.white + "!important",
      padding: "0 !important",
      textAlign: "center",
      width: "50px !important",
      height: "60px !important",
      borderRadius: "4px !important",
      fontSize: "25px !important",
      fontWeight: "bold !important",
      textTransform: "uppercase",
      [theme.breakpoints.down(601)]: {
        width: "35px !important",
        height: "45px !important",
        fontSize: "20px !important",
      },
      [theme.breakpoints.down(421)]: {
        width: "25px !important",
        height: "30px !important",
        fontSize: "15px !important",
      },
    },
    circle: {
      color: themePalette.white,
      marginRight: "20px !important",
      height: "15px !important",
      [theme.breakpoints.down(421)]: {
        marginRight: "6px !important",
        height: "11px !important",
      },
    },
    textFieldStyles: {
      marginRight: "10px !important",

      [theme.breakpoints.down(421)]: {
        marginRight: "5px !important",
      },
    },
    gridStyle: {
      maxWidth: 514,
      marginTop: 10,
      marginBottom: 10,
      [theme.breakpoints.down(601)]: {
        maxWidth: 394,
      },
      [theme.breakpoints.down(421)]: {
        maxWidth: 265,
      },
    },
    button: {
      padding: "9px 78px !important",
      fontSize: "20px !important",
      textTransform: "none !important",
      marginTop: "40px !important",
      fontWeight: "bold !important",
      background: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
      "&:hover": {
        background: "#1976d2 !important",
      },
      "&.Mui-disabled": {
        background: themePalette.gray + " !important",
      },
      borderRadius: "100px !important",
      [theme.breakpoints.down(601)]: {
        height: 45,
        width: 130,
        fontSize: "17px !important",
      },
    },
    error: {
      color: "#db3333 !important",
      fontWeight: "bold !important",
      fontSize: "18px !important",
      textAlign: "center !important",
    },

    footerTextStyle: {
      "&.MuiTypography-root": {
        fontFamily: URBANIST_FONT.SEMIBOLD,
        color: "#fff",
        textTransform: "none !important",
        fontSize: "1rem",
        lineHeight: "19.2px",
      },
      "&.MuiButton-text": {
        fontFamily: URBANIST_FONT.SEMIBOLD,
        color: "#fff",
        textTransform: "none !important",
        fontSize: "1rem",
        lineHeight: "19.2px",
      },
    },
  })
);

export default useStyles;
