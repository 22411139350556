import React, { useEffect } from 'react';

import { Checkbox, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import MedsiTnc from '../../../../../components/ModalContents/CoSignerForm/MedsiTnc';
import StepperAcceptTermCondtion from '../../../../../components/ModalContents/StepperTermsandCondition';
import ModalComponent from '../../../../../components/sharedComponents/ModalComponent';
import { API_STATUS, URBANIST_FONT } from '../../../../../constants/common';
import {
  selectCreditDecision,
  selectStepperStatus,
} from '../../../../../store/slices/users';
import { getApplicationAgreement } from '../../../../../store/slices/users/actions';
import ClientSignature from '../SigningContract/ClientSignatureV2';

import { Box } from '@mui/material';
import useStyles from './styles';

const SigningContract = () => {
  const urlId = window.location.search.slice(1, window.location.search.length);
  const dispatch = useDispatch();
  const css = useStyles();

  const [isAccepted, setIsAccepted] = React.useState(false);
  const [isMedsiTncAccepted, setIsMedsiTncAccepted] = React.useState(false);
  const [openMedsiTncModal, setOpenMedsiTncModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [agreements, setAgreements] = React.useState('');
  const [medsiAgreements, setMedsiAgreements] = React.useState('');
  const [isLoadingTerms, setIsLoadingTerms] = React.useState(false);

  const stepperStatus = useSelector(selectStepperStatus);
  const creditDecision = useSelector(selectCreditDecision);

  useEffect(() => {
    setIsAccepted(stepperStatus?.signature_completed);
    setIsMedsiTncAccepted(stepperStatus?.signature_completed);
  }, [stepperStatus]);

  const loadAgreement = async () => {
    setIsLoadingTerms(true);
    const { status, data } = await dispatch(
      getApplicationAgreement({ app_id: urlId, agreement: 'loan' })
    ).unwrap();

    if (status === API_STATUS.SUCCESS) {
      setAgreements(data?.agreement);
    }
    setIsLoadingTerms(false);
  };

  const loadMedsiAgreement = async () => {
    setIsLoadingTerms(true);
    const { status, data } = await dispatch(
      getApplicationAgreement({ app_id: urlId, agreement: 'medsi' })
    ).unwrap();

    console.log({ loanData: data });

    if (status === API_STATUS.SUCCESS) {
      setMedsiAgreements(data?.agreement);
    }
    setIsLoadingTerms(false);
  };

  // useEffect(() => {
  //   loadAgreement();
  // }, []);

  const disableEdit =
    stepperStatus?.application_status === 'APPROVED' ||
    stepperStatus?.application_status === 'LEGAL_REVIEW' ||
    stepperStatus?.application_status === 'DISBURSED';

  const handleOpen = async (e) => {
    // const bool = e.target.checked;
    // console.log({ bool });
    // if (bool) {
    //   setIsAccepted(bool);
    // } else {
    //   setIsAccepted(!bool);
    // }
    if (!isAccepted) {
      setOpen(!open);
      await loadAgreement();
    } else {
      setIsAccepted(false);
    }
  };

  const handleOpenMedsiTnc = async (e) => {
    if (!isMedsiTncAccepted) {
      setOpenMedsiTncModal(!openMedsiTncModal);
      await loadMedsiAgreement();
    } else {
      setIsMedsiTncAccepted(false);
    }
  };
  return (
    <Box
      sx={(theme) => ({
        pointerEvents: disableEdit ? 'none' : 'auto',
        opacity: disableEdit ? 0.7 : 1,
        [theme.breakpoints.down('lg')]: {
          fontFamily: 'Urbanist-Regular',
        },
      })}
    >
      <h1 className={css.immediateLoanTxt}>
        Firma del contrato de préstamo inmediato
      </h1>

      <Box
        sx={(theme) => ({
          fontSize: '18px',
          color: '#6B7779',
          [theme.breakpoints.down('lg')]: {
            fontSize: '16px',
            mb: '1rem',
          },
        })}
      >
        Al firmar, aceptas las condiciones y los términos de este contrato, así
        como las condiciones de pago y comisiones e intereses establecidos.
        (Tenga en cuenta: el contrato está sujeto a cambios según la revisión y
        la aprobación final de Medsi y los detalles se le comunicarán para su
        confirmación antes del desembolso)
      </Box>

      <div
        style={{
          marginTop: 34,
        }}
      >
        {creditDecision?.agreement !== 'loan' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{ color: '#fff' }}>
              <Checkbox
                checked={isMedsiTncAccepted}
                onChange={handleOpenMedsiTnc}
                sx={{
                  '&.Mui-checked': {
                    // color: "#000",
                  },
                  '& .MuiSvgIcon-root': {
                    // color: "#000",
                  },
                }}
              />
            </span>
            <Typography
              style={{
                fontFamily: URBANIST_FONT.REGULAR,
                fontSize: '1rem',
              }}
            >
              {'     Acepto los '}
              <Typography
                style={{
                  color: '#3552CC',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  display: 'inline-block',
                  fontFamily: URBANIST_FONT.BOLD,
                  fontSize: '1rem',
                }}
                onClick={handleOpenMedsiTnc}
              >
                Términos y Condiciones de Medsi
              </Typography>
            </Typography>
          </div>
        )}

        <div
          style={{
            fontSize: 16,
            display: 'flex',
            // marginTop: 34,
            alignItems: 'center',
          }}
        >
          <span style={{ color: '#3552CC' }}>
            <Checkbox checked={isAccepted} onChange={handleOpen} />
          </span>

          <Typography
            style={{
              fontFamily: URBANIST_FONT.REGULAR,
              fontSize: '1rem',
            }}
          >
            {'     Acepto los '}
            <Typography
              style={{
                color: '#3552CC',
                cursor: 'pointer',
                textDecoration: 'underline',
                display: 'inline-block',
                fontFamily: URBANIST_FONT.BOLD,
                fontSize: '1rem',
              }}
              onClick={handleOpen}
            >
              Términos y Condiciones de la solicitud de Préstamo
            </Typography>
          </Typography>
        </div>
      </div>

      <Box
        sx={(theme) => ({
          boxShadow: '0px 4px 4px rgba(53, 82, 204, 0.25)',
          borderRadius: '10px',
          background: '#3552CC0D',
          [theme.breakpoints.down('lg')]: {
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          },
        })}
      >
        <ClientSignature
          isAccepted={
            medsiAgreements ? isMedsiTncAccepted && isAccepted : isAccepted
          }
        />
        <ModalComponent
          open={openMedsiTncModal}
          handleOpen={handleOpenMedsiTnc}
          body={
            <MedsiTnc
              setOpen={setOpenMedsiTncModal}
              setIsAccepted={setIsMedsiTncAccepted}
              tncUrl={medsiAgreements}
              isLoadingTerms={isLoadingTerms}
            />
          }
          hideBackdrop={false}
          onBackdropClick={() => setOpenMedsiTncModal(false)}
        />
        <ModalComponent
          open={open}
          handleOpen={handleOpen}
          body={
            <StepperAcceptTermCondtion
              setOpen={setOpen}
              setIsAccepted={setIsAccepted}
              tncUrl={agreements}
              isLoadingTerms={isLoadingTerms}
            />
          }
          hideBackdrop={false}
          onBackdropClick={() => setOpen(false)}
        />
      </Box>
    </Box>
  );
};

export default SigningContract;
