import moment from "moment";
import { keyBy } from "lodash";

import {
  currencyFormat,
  curpValidator,
  rfcValidator,
} from "../../../../../utils";
import { emailRegex } from "../../../../../utils/validations";
import { OTHER_OPTION } from "../../../../../constants/generals";
import { convertTime12to24 } from "../../../../../utils/formatter";
import {
  isOtherShow,
  isProcedureShow,
  isBranchSelectionAllowed,
  getNumber,
  isOtherShowClinic,
} from "../../../../common/ProcedureForm/helpers";

export const initialValues = {
  medicName: "",
  medicLastName: "",
  medicRelation: "",
  medicEmail: "",
  medicPhone: "",
  appointmentDate: "",
  appointmentTime: "",
  procType: "",
  procToDo: [],
  otherProc: "",
  procedureCost: "",
  requestedAmount: "",
  hours: "--",
  minutes: "--",
  time: "--",
  clinicName: "",
  clinicBranch: "",
  specialtyType: "",
  otherClinicName: "",
  clinicDr: "",
};

export const getMaxMinAmount = ({ productDetails, values, clinicList }) => {
  const clinicObj = keyBy(clinicList, "clinicName");
  let maxCreditAmt = productDetails?.maxCreditAmount || 0;
  const minCreditAmount = +(productDetails?.minCreditAmount || 0);

  if (
    clinicObj &&
    values.clinicName &&
    clinicObj?.[values.clinicName]?.maxCreditAmount
  ) {
    maxCreditAmt = +(clinicObj[values.clinicName]?.maxCreditAmount || 0);
  }

  return {
    maxCreditAmt,
    minCreditAmount,
  };
};

export const getPayload = ({
  values,
  hasNotDate,
  responseData = {},
  procedureToPerformList,
}) => {
  let payload = {
    ...responseData,
  };

  for (const key in payload) {
    if (payload[key] === null) {
      delete payload[key];
    }
  }

  const procedureCost = getNumber(values.procedureCost) || 0;
  const requestedAmount = getNumber(values.requestedAmount) || 0;

  if (requestedAmount) {
    payload.requested_loan_amount = requestedAmount;
  }

  if (values.appointmentDate) {
    let appointmentDate = values.appointmentDate
      ? moment(values.appointmentDate).format("yyyy-MM-DD")
      : "";
    payload.appointment_date = appointmentDate;
  }
  if (!hasNotDate) {
    const time = convertTime12to24(
      values.hours + ":" + values.minutes + " " + values.time
    );
    payload.appointment_time = time;
  }

  if (values.medicName) {
    payload.doc_name = values.medicName;
  }

  if (values.medicEmail) {
    payload.doc_email = values.medicEmail;
  }
  if (values.medicPhone) {
    payload.doc_phone = values.medicPhone;
  }
  if (values.clinicName) {
    if (!payload.clinic_info) {
      payload.clinic_info = {};
    }
    payload.clinic_info = {
      ...payload.clinic_info,
      clinic_name: values.clinicName,
    };
  }
  if (values.clinicBranch) {
    if (!payload.clinic_info) {
      payload.clinic_info = {};
    }
    payload.clinic_info = {
      ...payload.clinic_info,
      clinic_location: values.clinicBranch,
    };
    // payload.clinic_info.clinic_location = values.clinicBranch;
  }

  if (values.medicLastName) {
    payload.doc_last_name = values.medicLastName;
  }
  if (values.medicRelation) {
    payload.relation_with_doc = values.medicRelation;
  }

  if (procedureCost) {
    payload.procedure_cost = procedureCost;
  }

  if (values.specialtyType) {
    payload.speciality_type = values.specialtyType;
  }

  if (isProcedureShow({ values })) {
    payload.procedure_type = values.procType;
  }

  if (!isOtherShow({ values })) {
    payload.procedure_to_perform = values.procToDo;
  }

  if (procedureToPerformList?.length < 1 && !isOtherShow({ values })) {
    payload.procedure_to_perform = [values.procType];
  }
  if (values.otherProc) {
    payload.other_procedure = values.otherProc;
  }

  if (values.rfc) {
    payload.rfc = values.rfc;
  }

  if (values.curp) {
    payload.curp = values.curp;
  }
  if (values.otherProc) {
    payload.other_procedure = values.otherProc;
  } else {
    payload.other_procedure = "";
  }

  return payload;
};

// set value after fetching from BE
export const setValueFromResponse = ({ responseValue, values }) => {
  const {
    doc_name = "",
    doc_last_name = "",
    doc_phone = "",
    relation_with_doc = "",
    doc_email = "",
    procedure_type,
    clinic_info = {},
  } = responseValue;
  values.medicPhone = doc_phone;
  values.medicLastName = doc_last_name;
  values.medicRelation = relation_with_doc;
  values.medicEmail = doc_email;
  values.medicName = doc_name;
  values.procType = procedure_type || "";
  let { clinic_location, clinic_name, other_clinic_name } = clinic_info;

  if (clinic_name && doc_name && doc_last_name) {
    values.clinicDr = `${doc_name} ${doc_last_name}`;
  }

  values.clinicBranch = clinic_location || "";
  values.clinicName = clinic_name || "";
  values.otherClinicName = other_clinic_name || "";
  values.procToDo = responseValue.procedure_to_perform
    ? typeof responseValue.procedure_to_perform === "string"
      ? [responseValue.procedure_to_perform]
      : responseValue.procedure_to_perform
    : [];
  values.procedureCost = responseValue.procedure_cost || "";
  values.requestedAmount = responseValue.requested_loan_amount || "";

  values.appointmentDate = responseValue?.appointment_date || "";
  values.otherProc = responseValue?.other_procedure || "";
  values.specialtyType = responseValue?.speciality_type || "";
  values.rfc = responseValue?.rfc?.replace("No tengo RFC", "") || "";
  values.curp = responseValue?.curp || "";
  if (responseValue?.appointment_time) {
    // const timeObj = moment(responseValue.appointment_time)
    const timeObj = moment(
      new Date(
        `${responseValue.appointment_date} ${responseValue.appointment_time}`
      )
    )
      .format("h:mm:A")
      .toString()
      .split(":");
    values.minutes = timeObj[1];
    values.time = timeObj[2];
    values.hours = timeObj[0].startsWith("0") ? timeObj[0][1] : timeObj[0];
  } else {
    values.minutes = "";
    values.time = "";
    values.hours = "";
  }
  return values;
};

export const handleRequestAmount = ({
  errors,
  values,
  procedureList,
  clinicList,
}) => {
  const procedureCost = getNumber(values?.procedureCost) || 0;
  const requestedAmount = getNumber(values?.requestedAmount) || 0;

  const { maxCreditAmt, minCreditAmount } = getMaxMinAmount({
    values,
    procedureList,
    clinicList,
  });
  if (!procedureCost) {
    errors.procedureCost = "Ingresa el monto a solicitar";
  } else if (procedureCost && requestedAmount) {
    if (procedureCost < minCreditAmount) {
      errors.procedureCost = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    } else if (requestedAmount > procedureCost) {
      errors.procedureCost = `El costo del trámite debe ser mayor al monto solicitado`;
    } else {
      delete errors.procedureCost;
    }
  }

  if (!requestedAmount) {
    // errors.requestedAmount = "Ingresa el monto a solicitar";
  } else if (requestedAmount && procedureCost) {
    if (requestedAmount > procedureCost) {
      errors.requestedAmount = `El límite de $${currencyFormat(
        maxCreditAmt
      )} aplica solamente para ciertos socios comerciales; otras clínicas y médicos pueden estar sujetas a un límite de crédito menor.`;
    } else if (requestedAmount < minCreditAmount) {
      errors.requestedAmount = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    } else {
      delete errors.requestedAmount;
    }
  }
};

export const validateForm = ({
  values,
  hasNotDoctor,
  hasNotDate,
  touched,
  maxCreditAmt,
  procedureToPerformList,
  branchList,
  minCreditAmount,
  procedureList,
  clinicList,
}) => {
  let errors = {};
  //If the user has not a doc yet, we can skip this information
  const hasOtherProcess = isOtherShow({
    values,
  });

  const procedureCost = getNumber(values.procedureCost) || 0;
  const requestedAmount = getNumber(values.requestedAmount) || 0;
  const isOtherClinic = isOtherShowClinic({
    values,
  });
  const isBranchDrShow = branchList.length > 0 && values.clinicBranch;
  const isDrOtherFilled =
    values.medicName && values.medicLastName && !values.clinicName;

  if (!hasNotDoctor) {
    if (!isOtherClinic && !isBranchDrShow && !isDrOtherFilled) {
      !values.medicName
        ? (errors.medicName = "Este campo es requerido")
        : delete errors.medicName;

      !values.medicLastName
        ? (errors.medicLastName = "Este campo es requerido")
        : delete errors.medicLastName;
    }

    !values.medicRelation
      ? (errors.medicRelation = "Este campo es requerido")
      : delete errors.medicRelation;

    !values.medicEmail
      ? (errors.medicEmail = "Este campo es requerido")
      : !emailRegex.test(values.medicEmail)
      ? (errors.medicEmail = "ingresa un correo valido")
      : delete errors.medicEmail;

    !values.medicPhone
      ? (errors.medicPhone = "Este campo es requerido")
      : delete errors.medicPhone;
  }

  if (!hasNotDate) {
    //Validate date
    if (!values.appointmentDate) {
      errors.appointmentDate = `Selecciona una fecha, de lo contrario, selecciona la opcion 'no tengo cita programada aún'`;
    } else {
      //Verify if the date is a Valid format
      if (!moment(values.appointmentDate).isValid()) {
        errors.appointmentDate = "El formato de la fecha no es correcto";
        touched.appointmentDate = true;
      } else {
        delete errors.appointmentDate;
        delete touched.appointmentDate;
      }
    }
    //Validate appointment time

    values.hours === "--" || values.minutes === "--" || values.time === "--"
      ? (errors.appointmentTime = `Selecciona una hora, de lo contrario, selecciona la opcion 'no tengo cita programada aún'`)
      : delete errors.appointmentTime; /* minutesValidation(values.appointmentTime.target.value); */
  }

  //Validate specialtyType  types
  !values.specialtyType
    ? (errors.specialtyType = "Selecciona el especialidad médica")
    : delete errors.specialtyType;

  //Validate procedure  types

  !values.procType
    ? (errors.procType = "Selecciona el tipo de procedimiento")
    : delete errors.procType;

  const canShowOtherInput = isOtherShow({
    values,
  });

  if (
    !canShowOtherInput &&
    procedureToPerformList?.length > 0 &&
    values.procToDo.length === 0
  ) {
    errors.procToDo = "Selecciona al menos un proceso";
  } else {
    delete errors.procToDo;
  }
  // Remove error validation is specialty type selected others
  if (!isProcedureShow({ values })) {
    delete errors.procToDo;
    delete errors.procType;
  }

  if (
    OTHER_OPTION.toUpperCase() === values.procType.toUpperCase() ||
    procedureToPerformList?.length < 1
  ) {
    delete errors.procToDo;
  }

  if (!procedureCost) {
    errors.procedureCost = "Ingresa el costo del procedimiento";
  } else if (procedureCost && procedureCost) {
    if (procedureCost > maxCreditAmt) {
      errors.procedureCost = `El costo del trámite debe ser mayor a $${maxCreditAmt}`;
    } else {
      delete errors.procedureCost;
    }
  }

  if (hasOtherProcess) {
    !values.otherProc
      ? (errors.otherProc = "Ingresa el/los otros procedimiento(s)")
      : delete errors.otherProc;
  }
  if (!hasOtherProcess) {
    delete errors.otherProc;
  }

  // if (!requestedAmount) {
  //   errors.requestedAmount = "Ingresa el monto a solicitar";
  // } else if (requestedAmount && procedureCost) {
  //   if (requestedAmount > procedureCost) {
  //     errors.requestedAmount = `El importe del crédito no puede ser superior al costo del procedimiento o mayor de $${currencyFormat(
  //       maxCreditAmt
  //     )}. Favor de corregir`;
  //   } else {
  //     delete errors.requestedAmount;
  //   }
  // }

  handleRequestAmount({
    maxCreditAmt,
    values,
    errors,
    minCreditAmount,

    procedureList,
    clinicList,
  });

  if (!procedureCost) {
    errors.procedure = "Ingresa el monto a solicitar";
  } else if (requestedAmount && procedureCost) {
    // if (procedureCost > maxCreditAmt) {
    //   errors.procedureCost = `El límite de $${currencyFormat(
    //     maxCreditAmt
    //   )} aplica solamente para ciertos socios comerciales; otras clínicas y médicos pueden estar sujetas a un límite de crédito menor.`;
    // } else

    if (procedureCost < minCreditAmount) {
      errors.procedureCost = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    } else {
      delete errors.requestedAmount;
    }
  }

  if (!requestedAmount) {
    errors.requestedAmount = "Ingresa el monto a solicitar";
  } else if (requestedAmount && procedureCost) {
    if (requestedAmount > procedureCost) {
      errors.requestedAmount = `El límite de $${currencyFormat(
        maxCreditAmt
      )} aplica solamente para ciertos socios comerciales; otras clínicas y médicos pueden estar sujetas a un límite de crédito menor.`;
    } else if (requestedAmount < minCreditAmount) {
      errors.requestedAmount = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    } else {
      delete errors.requestedAmount;
    }
  }

  if (isBranchSelectionAllowed({ values })) {
    !values.clinicBranch
      ? (errors.clinicBranch = "Selecciona el Sucursal clínica")
      : delete errors.clinicBranch;
  }

  return errors;
};
