import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  container: {
    padding: "3% 6%",
    background: "#3552CC",
    color: "#fff",
    [theme.breakpoints.down(601)]: {
      padding: "5% 10%",
    },
  },
  footerHeadingText: {
    fontWeight: "bold",
    fontFamily: "SFUIText-Bold",
    fontSize: "22px",
    lineHeight: "1.2",
  },
  footerAnchorTagStyle:{
    color: "#fff",
    textDecoration: 'none',
  },
  footerUlTagStyle:{
    margin: '0px',
    padding: '0px',
    paddingLeft: '14px',
  },
  line: {
    height: 1,
    background: "#fff",
    width: "100%",
  },
  rightContent: {
    textAlign: 'right',
    [theme.breakpoints.down(601)]: {
      textAlign: 'left',
      marginTop: 20,
    },
  }
}));

export default styles;
