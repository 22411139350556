import { FC } from 'react';
import { IconProps, defaultIconColor } from '../types';

const LeftIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12ZM13.3912 17.7071C13.7818 18.0977 14.4149 18.0977 14.8055 17.7071C15.196 17.3166 15.196 16.6835 14.8055 16.2929L10.5698 12.0573L14.7985 7.91075C15.1928 7.52408 15.199 6.89094 14.8124 6.4966C14.4257 6.10227 13.7926 6.09605 13.3982 6.48273L8.44847 11.3363C8.25789 11.5232 8.14992 11.7785 8.14861 12.0454C8.14731 12.3123 8.25276 12.5687 8.4415 12.7574L13.3912 17.7071Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

LeftIcon.defaultProps = {
  fill: defaultIconColor,
};

export default LeftIcon;
