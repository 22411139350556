import React, { useState } from "react";
import QRCode from "../../../../components/ModalContents/QRCode";
import { useAppDispatch } from "../../../../store";
import useStyles from "./styles";

import { useSelector } from "react-redux";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import { selectStepperApplicationSummary } from "../../../../store/slices/users";
import { getStepperApplicationSummary } from "../../../../store/slices/users/actions";
import Box from "@mui/material/Box";
import { currencyFormat } from "../../../../utils/index";

const QRSummary = () => {
  const css = useStyles();
  const dispatch = useAppDispatch();
  const urlId = window.location.search.slice(1, window.location.search.length);

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  React.useEffect(() => {
    dispatch(getStepperApplicationSummary(urlId));
  }, []);

  const StepperApplicationSummary = useSelector(
    selectStepperApplicationSummary
  );

  return (
    <Box
      sx={(theme) => ({
        borderRadius: "0px 0px 10px 10px",
        border: "1px solid #E6E6E6",
        marginLeft: "44px",
        marginRight: "44px",
        marginTop: "-18px",
        [theme.breakpoints.down("lg")]: {
          border: "transparent",
          margin: "1rem 2rem",
          fontFamily: "Urbanist-Medium",
        },
        [theme.breakpoints.down("tablet")]: { ml: "1rem", mr: "1.5rem" },
      })}
    >
      <h1 className={css.summaryText}>Resumen</h1>
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "repeat(2,1fr)",
          padding: "20px",
          paddingLeft: "65px",
          [theme.breakpoints.down("lg")]: {
            padding: "1rem",
            pl: "2rem",
            gap: "1rem",
          },
          [theme.breakpoints.down("tablet")]: {
            gridTemplateColumns: "1fr",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: "20px",
            gap: "10px",
            padding: "20px",
            [theme.breakpoints.down("lg")]: {
              padding: "1.5rem 2rem",
              gap: "1rem",
            },
            [theme.breakpoints.down("tablet")]: { fontSize: "14px" },
          })}
        >
          <Box
            sx={(theme) => ({
              marginBottom: "20px",
              [theme.breakpoints.down("lg")]: { mb: "0px" },
            })}
          >
            <Box
              sx={(theme) => ({
                color: "#979797",
                fontWeight: 500,
                fontSize: "14px",
                [theme.breakpoints.down("lg")]: { mb: "5px" },
              })}
            >
              Nombre del procedimiento
            </Box>
            <div
              style={{
                color: "#000000",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {StepperApplicationSummary?.procedure_name}
            </div>
          </Box>

          <Box
            sx={(theme) => ({
              marginBottom: "20px",
              [theme.breakpoints.down("lg")]: { mb: "0px" },
            })}
          >
            <Box
              sx={(theme) => ({
                color: "#979797",
                fontWeight: 500,
                fontSize: "14px",
                [theme.breakpoints.down("lg")]: { mb: "5px" },
              })}
            >
              Nombre del médico
            </Box>
            <div
              style={{
                color: "#000000",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              {StepperApplicationSummary?.doc_name}
            </div>
          </Box>

          <Box
            sx={(theme) => ({
              marginBottom: "20px",
              [theme.breakpoints.down("lg")]: { mb: "0px" },
            })}
          >
            <Box
              sx={(theme) => ({
                color: "#979797",
                fontWeight: 500,
                fontSize: "14px",
                [theme.breakpoints.down("lg")]: { mb: "5px" },
              })}
            >
              Monto del préstamo
            </Box>
            <div
              style={{
                color: "#000000",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              ${currencyFormat(StepperApplicationSummary?.loan_amount)}
            </div>
          </Box>

          <Box
            sx={(theme) => ({
              marginBottom: "20px",
              [theme.breakpoints.down("lg")]: { mb: "0px" },
            })}
          >
            <Box
              sx={(theme) => ({
                color: "#979797",
                fontWeight: 500,
                fontSize: "14px",
                [theme.breakpoints.down("lg")]: { mb: "5px" },
              })}
            >
              <span>Cantidad debida</span>
            </Box>
            <div
              style={{
                color: "#000000",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              ${currencyFormat(StepperApplicationSummary?.due_amount)}
            </div>
          </Box>

          <Box
            sx={(theme) => ({
              marginBottom: "20px",
              [theme.breakpoints.down("lg")]: { mb: "0px" },
            })}
          >
            <Box
              sx={(theme) => ({
                color: "#979797",
                fontWeight: 500,
                fontSize: "14px",
                [theme.breakpoints.down("lg")]: { mb: "5px" },
              })}
            >
              Importe total a pagar
            </Box>
            <div
              style={{
                color: "#000000",
                fontWeight: 700,
                fontSize: 16,
              }}
            >
              ${currencyFormat(StepperApplicationSummary?.total_amount_payable)}
            </div>
          </Box>
        </Box>

        <Box
          sx={(theme) => ({
            padding: "20px",
            paddingLeft: "40px",
            [theme.breakpoints.down("lg")]: {
              padding: "1rem",
            },
          })}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              style={{ width: 50 }}
              src={StepperApplicationSummary?.qr}
              alt=""
            />
            <Box
              sx={(theme) => ({
                fontWeight: 600,
                fontSize: "18px",
                color: "#3552CC",
                textDecoration: "underline",
                paddingLeft: "10px",
                cursor: "pointer",
              })}
              onClick={handleOpen}
            >
              Ver mi QR
            </Box>
          </div>
          <h3
            style={{
              fontSize: 20,
              marginTop: 10,
              marginBottom: 10,
              listStylePosition: "inside",
            }}
          >
            Pasos para utilizar tu QR:
          </h3>
          <ul className={css.ulList}>
            <li> Ponte en contacto con tu médico y comparte este QR</li>
            <li>
              Pide al médico que escanee este QR usando la cámara de su teléfono
            </li>
            <li>
              Una vez que el Doctor escanee el QR, podrá confirmar tu cita y
              completar la información necesaria para que finalicemos tu
              solicitud{" "}
            </li>
          </ul>
        </Box>
      </Box>
      <ModalComponent
        open={open}
        handleOpen={handleOpen}
        body={<QRCode setOpen={setOpen} qr={StepperApplicationSummary?.qr} />}
        hideBackdrop={false}
        onBackdropClick={() => setOpen(false)}
      />
    </Box>
  );
};

export default QRSummary;
