import {
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { FormikErrors, useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STEPPER_NAME } from "..";
import ButtonComponent from "../../../../../components/sharedComponents/ButtonComponent";
import CenteredContent from "../../../../../components/sharedComponents/CenteredContent";
import DateinputComponent from "../../../../../components/sharedComponents/DateInputComponent";
import InputText from "../../../../../components/sharedComponents/InputText";
import { API_STATUS, URBANIST_FONT } from "../../../../../constants/common";
import { useAppDispatch } from "../../../../../store/index";
import { selectPatientGroup } from "../../../../../store/slices/users";
import {
  getPatientApplication,
  getPatientGroup,
  savePatientApplication,
  savePatientGroup,
} from "../../../../../store/slices/users/actions";
import { errorToast, successToast } from "../../../../../utils/toast";
import useStyles from "./index.styles";

interface PatientGroupDetails {
  date_of_birth: string;
  first_name: string;
  gender: string;
  last_name: string;
  maternal_name: string;
  upg_id: string;
}

interface FamilyFormValues {
  lastname: string;
  motherLastname: string;
  relation: string;
  name: string;
  gender: string;
  lengthPhone: string;
  email: string;
  date_of_birth: string;
}

const initialValues = {
  lastname: "",
  motherLastname: "",
  relation: "",
  name: "",
  gender: "",
  lengthPhone: "",
  email: "",
  date_of_birth: "",
};

const FamilyFormDetails = ({
  handleSubmitDetails,
  selectedFamily,
  setOpenAccordionTitle,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const patientDetails = useSelector(selectPatientGroup);
  let application_id = window.location.search.slice(
    1,
    window.location.search.length
  );
  const patient = patientDetails.find(
    (eachPatient) => eachPatient.upg_id === selectedFamily
  );

  let patientUpgId = patient?.upg_id;

  useEffect(() => {
    const fillPatientValue = async () => {
      await setFieldValue("name", patient?.first_name || "");
      await setFieldValue("lastname", patient?.last_name || "");
      await setFieldValue("motherLastname", patient?.maternal_name || "");
      await setFieldValue("relation", patient?.relation_with_user || "");
      await setFieldValue("gender", patient?.gender || "");
      await setFieldValue("date_of_birth", patient?.date_of_birth);
    };
    fillPatientValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFamily, patientDetails]);

  const validate = (values: FamilyFormValues) => {
    let errors: FormikErrors<FamilyFormValues> = {};
    !values.name
      ? (errors.name = "Este campo es requerido")
      : values.name.length < 3
      ? (errors.name = "mas de 2 caracters")
      : delete errors.name;
    !values.relation
      ? (errors.relation = "Este campo es requerido")
      : delete errors.relation;
    !values.motherLastname
      ? (errors.motherLastname = "Este campo es requerido")
      : values.motherLastname.length < 3
      ? (errors.motherLastname = "mas de 2 caracters")
      : delete errors.motherLastname;
    !values.lastname
      ? (errors.lastname = "Este campo es requerido")
      : values.lastname.length < 3
      ? (errors.lastname = "mas de 2 caracters")
      : delete errors.lastname;

    if (values.gender === "") errors.gender = "El campo es requerido";
    if (!values.date_of_birth) {
      errors.date_of_birth = "Selecciona una fecha";
    } else if (!moment(values.date_of_birth).isValid()) {
      errors.date_of_birth = "El formato de la fecha no es correcto";
      touched.date_of_birth = true;
    }
    return errors;
  };
  const formik = useFormik({
    initialValues,
    validate,
    // validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async (values) => {
      const isSamePatient =
        patient?.first_name === values?.name &&
        patient.last_name === values?.lastname;
      const familyDetails = {
        upg_id: patient?.upg_id,
        first_name: values?.name,
        last_name: values?.lastname,
        maternal_name: values?.motherLastname,
        gender: values?.gender,
        date_of_birth: values?.date_of_birth,
        relation_with_user: values?.relation,
      };

      if (!isSamePatient) {
        const { status: patientSaveStatus, data } = await dispatch(
          savePatientGroup({
            details: familyDetails,
            isEdit: !!patient?.upg_id,
          })
        ).unwrap();

        patientUpgId = data?.upg_id;

        if (patientSaveStatus === API_STATUS.SUCCESS) {
          setValues({ ...initialValues });
          setTouched({}, false);
          setOpenAccordionTitle(STEPPER_NAME.PROCEDURE_DETAILS);
          handleSubmitDetails(data?.upg_id || "");
        }
      }

      const { status: patientApplicationSaveStatus, meta } = await dispatch(
        savePatientApplication({
          details: {
            application_id,
            upg_id: patientUpgId,
          },
          isEdit: !!patient?.upg_id,
        })
      ).unwrap();

      if (patientApplicationSaveStatus === API_STATUS.SUCCESS) {
        setOpenAccordionTitle(STEPPER_NAME.PROCEDURE_DETAILS);
        successToast("Sus datos se guardaron correctamente");
      }
      if (patientApplicationSaveStatus === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    setValues,
    setTouched,
  } = formik;

  return (
    <Grid container marginTop={9} rowGap={4} columnGap={4} columnSpacing={8}>
      <Grid item xs={12} lg={4}>
        <InputText
          // id="Nombre"
          label="Nombre(s)"
          name="name"
          value={values.name}
          errors={errors.name}
          touched={touched.name}
          handleChange={handleChange}
          handleBlur={handleBlur}
          stylesInput={classes.input}
          centeredLabel={undefined}
          InputProps={undefined}
          fullWidth={undefined}
          insideLabel={undefined}
          disabled={undefined}
          placeholder={undefined}
          labelStyle={undefined}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InputText
          // id="Nombre"
          label="Apellido Paterno"
          name="lastname"
          value={values.lastname}
          errors={errors.lastname}
          touched={touched.lastname}
          handleChange={handleChange}
          handleBlur={handleBlur}
          stylesInput={classes.input}
          centeredLabel={undefined}
          InputProps={undefined}
          fullWidth={undefined}
          insideLabel={undefined}
          disabled={undefined}
          placeholder={undefined}
          labelStyle={undefined}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InputText
          // id="Nombre"
          label="Apellido Materno"
          name="motherLastname"
          value={values.motherLastname}
          errors={errors.motherLastname}
          touched={touched.motherLastname}
          handleChange={handleChange}
          handleBlur={handleBlur}
          stylesInput={classes.input}
          centeredLabel={undefined}
          InputProps={undefined}
          fullWidth={undefined}
          insideLabel={undefined}
          disabled={undefined}
          placeholder={undefined}
          labelStyle={undefined}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <InputText
          // id="Nombre"
          label="Relación con el codeudor"
          name="relation"
          value={values.relation}
          errors={errors.relation}
          touched={touched.relation}
          handleChange={handleChange}
          handleBlur={handleBlur}
          stylesInput={classes.input}
          centeredLabel={undefined}
          InputProps={undefined}
          fullWidth={undefined}
          insideLabel={undefined}
          disabled={undefined}
          placeholder={undefined}
          labelStyle={undefined}
        />
      </Grid>

      <Grid item xs={12} lg={4}>
        <div className="myinput labelMargin">
          <Typography
            color="#3552CC"
            variant="h5"
            fontSize="1rem !important"
            fontWeight="700 !important"
            marginBottom="2px !important"
            marginTop="2px !important"
            paddingLeft={4}
          >
            Género
          </Typography>
          <Box marginTop={2.5}>
            <RadioGroup
              aria-label="gender"
              defaultValue="female"
              name="gender"
              className={classes.radioGender}
              onChange={handleChange}
              row
            >
              <FormControlLabel
                value="Mujer"
                control={<Radio checked={values.gender === "Mujer"} />}
                // control={<Radio checked={true} />}
                label={<span className={classes.formControlRadio}>Mujer </span>}
              />
              <FormControlLabel
                value="Hombre"
                control={<Radio checked={values.gender === "Hombre"} />}
                // control={<Radio checked={false} />}
                label={<span className={classes.formControlRadio}>Hombre</span>}
              />
            </RadioGroup>
          </Box>
        </div>
      </Grid>

      <Grid item xs={12} lg={4}>
        <Typography
          variant="h5"
          color="#3552CC"
          fontSize="1rem !important"
          fontWeight="700 !important"
          marginBottom="2px !important"
          marginTop="2px !important"
          paddingLeft={4}
        >
          Fecha de nacimiento
        </Typography>
        <Box marginTop={2.5} className={classes.dateInput}>
          <DateinputComponent
            name="date_of_birth"
            maxDateValue={new Date()}
            dateValue={
              values.date_of_birth
                ? new Date(values.date_of_birth.replaceAll("-", "/"))
                : null
            }
            handleDate={(value) => {
              if (value) {
                setFieldValue(
                  "date_of_birth",
                  moment(new Date(value)).format("YYYY-MM-DD")
                );
              }
            }}
            errors={errors.date_of_birth}
            touched={touched.date_of_birth}
            onBlur={handleBlur}
          />
        </Box>
      </Grid>

      <Grid item lg={10} marginTop={8.5}>
        <CenteredContent className="" direction="">
          <ButtonComponent
            type="submit"
            text="Continuar"
            onClick={handleSubmit}
            disabled={Object.entries(formik.errors).length}
            style={{
              borderRadius: "8px !important",
            }}
            className={classes.submitButton}
          />
        </CenteredContent>
      </Grid>
    </Grid>
  );
};

const FamilyModal: React.FC<any> = ({ setOpenAccordionTitle }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [patientRelation, setPatientRelation] = useState<string>("myself");
  const [selectedFamily, setSelectedFamily] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const patientGroup: PatientGroupDetails[] = useSelector(selectPatientGroup);

  let application_id = window.location.search.slice(
    1,
    window.location.search.length
  );

  const loadPatients = async () => {
    const patient = await dispatch(getPatientGroup()).unwrap();
    return patient;
  };

  const loadPatientApplication = async () => {
    const { data, status } = await dispatch(
      getPatientApplication(application_id)
    ).unwrap();

    if (status === API_STATUS.SUCCESS && data?.upg_id !== "None") {
      setPatientRelation("family");
      setSelectedFamily(data?.upg_id || "");
    }
  };

  useEffect(() => {
    loadPatients();
    loadPatientApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitFamilyDetails = async (upg_id: string) => {
    setIsSubmitted(true);
    const { status } = await loadPatients();
    if (status === API_STATUS.SUCCESS) {
      await setSelectedFamily(upg_id);
    }
  };

  const handleUpdateApplication = async () => {
    const { status, meta } = await dispatch(
      savePatientApplication({
        details: {
          application_id,
          upg_id: null,
        },
        isEdit: true,
      })
    ).unwrap();
    if (status === API_STATUS.SUCCESS) {
      successToast("Sus datos se guardaron correctamente");
    }
    if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  return (
    <Box className={classes.container}>
      <Typography
        // fontSize="1.3rem"
        // fontFamily={URBANIST_FONT.BOLD}
        color="#6B7779"
        sx={(theme) => ({
          fontFamily: "SFUIText-bold",
          fontSize: "24px",
          [theme.breakpoints.down("lg")]: {
            fontFamily: URBANIST_FONT.BOLD,
            fontSize: "21px",
          },
          [theme.breakpoints.down("tablet")]: { fontSize: "18px" },
        })}
      >
        Detalles de el paciente
      </Typography>
      <Typography
        // fontSize="1rem"
        // fontFamily={URBANIST_FONT.SEMIBOLD}
        // color="#3552CC"
        variant="h5"
        color="#3552CC"
        // fontSize='1rem !important'
        // fontWeight='700 !important'
        sx={(theme) => ({
          marginTop: "1.7rem",
          marginBottom: "1.1rem",
          fontFamily: "SFUIText-bold",
          fontSize: "24px",
          [theme.breakpoints.down("lg")]: {
            fontFamily: URBANIST_FONT.BOLD,
            fontSize: "21px",
            margin: "1rem 0 1.1rem",
          },
          [theme.breakpoints.down("tablet")]: { fontSize: "18px" },
        })}
      >
        ¿Quién recibirá el tratamiento?
      </Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={patientRelation}
          onChange={(radioEvent) => {
            setPatientRelation(radioEvent?.target?.value);
          }}
          sx={(theme) => ({
            "& span": {
              [theme.breakpoints.down("lg")]: {
                fontFamily: URBANIST_FONT.REGULAR,
                fontSize: "16px",
              },
              [theme.breakpoints.down("tablet")]: {
                fontSize: "14px",
              },
            },
          })}
        >
          <FormControlLabel
            value="myself"
            control={<Radio />}
            label="Mí mismo"
          />
          <FormControlLabel
            value="family"
            control={<Radio />}
            label="Miembro de la familia"
          />
        </RadioGroup>
      </FormControl>
      {(selectedFamily || isSubmitted) && patientRelation === "myself" && (
        <Box marginTop={3.5}>
          <CenteredContent className="" direction="">
            <ButtonComponent
              type="submit"
              text="Continuar"
              onClick={handleUpdateApplication}
              // disabled={Object.entries(formik.errors).length}
              className={classes.submitButton}
            />
          </CenteredContent>
        </Box>
      )}
      {patientGroup?.length && patientRelation === "family" ? (
        <Box>
          <Typography
            fontSize="1rem"
            // fontFamily={URBANIST_FONT.SEMIBOLD}
            color="#3552CC"
            marginY="1rem"
            marginBottom={4}
            variant="h5"
            fontWeight="700 !important"
          >
            Elige el miembro de tu familia
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              rowGap: 10,
            }}
          >
            {patientGroup.map((patient) => {
              return (
                <div
                  style={{
                    padding: "9px 22px",
                    marginRight: 15,
                    borderRadius: 10,
                    cursor: "pointer",
                    background:
                      selectedFamily === patient.upg_id
                        ? "linear-gradient(0deg, #3552CC, #3552CC), rgba(53, 82, 204, 1)"
                        : "rgba(53, 82, 204, 0.1)",
                    color: selectedFamily === patient.upg_id ? "#fff" : "#000",
                  }}
                  onClick={(e) => {
                    setSelectedFamily(patient.upg_id);
                  }}
                >
                  {patient.first_name}
                </div>
              );
            })}

            <div
              style={{
                padding: "9px 22px",
                marginRight: 15,
                borderRadius: 10,
                cursor: "pointer",
                background: !selectedFamily
                  ? "linear-gradient(0deg, #3552CC, #3552CC), rgba(53, 82, 204, 1)"
                  : "rgba(53, 82, 204, 0.1)",
                color: !selectedFamily ? "#fff" : "#000",
              }}
              onClick={(e) => {
                setSelectedFamily("");
              }}
            >
              + Agregar un Nuevo Miembro
            </div>
          </div>
        </Box>
      ) : (
        <></>
      )}

      {patientRelation === "family" && (
        <>
          <Divider
            sx={{
              margin: "28px -20px 28px -35px",
              background: "rgba(0, 0, 0, 0.1)",
            }}
          />
          <FamilyFormDetails
            handleSubmitDetails={handleSubmitFamilyDetails}
            selectedFamily={selectedFamily}
            setOpenAccordionTitle={setOpenAccordionTitle}
          />
        </>
      )}
    </Box>
  );
};

export default FamilyModal;
