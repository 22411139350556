import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  paymentDate: {
    textAlign: 'center',
    color: '#9E9E9E',
    marginTop: '20px',
    [theme.breakpoints.down('lg')]: {
      color: '#000',
      marginBottom: '3rem',
      '& #date': { fontFamily: 'Urbanist-SemiBold' },
    },
    [theme.breakpoints.down('tablet')]: { marginBottom: 0 },
  },
}));

export default useStyles;
