import { useState } from "react";
import { Box, Grid } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import CircularDotIcon from "./CircularDot";
import "./carousel.css"; // requires a loader
import useStyles from "./styles";
import Arrow from "./arrow.svg";

export const CardItem = ({ label, img, title }) => {
  const css = useStyles();
  const [show, setShow] = useState(false);

  return (
    <Grid item xs={12} md={4} display="flex" justifyContent="center">
      <Box
        className={css.card}
        key={label}
        onMouseLeave={() => {
          setShow(false);
        }}
        onMouseOver={() => {
          title && setShow(true);
        }}
      >
        {show ? (
          title?.map((listItem) => {
            return (
              <Box px={4} mt={1} width="236px">
                {typeof listItem === "string" ? (
                    <li className={css.hoverListItemStyle}>{listItem}</li>
                  ) : (
                    <li className={css.hoverListItemStyle}>
                      {listItem.label}
                      {listItem?.items && (
                        <Box pl={3} mt={0.5} width="100%">
                          {listItem?.items?.map((itemText) => {
                            return (
                              <div> - {itemText}</div>
                            )
                          })}
                        </Box>
                      )}
                    </li>
                  )}
              </Box>
            );
          })
        ) : (
          <>
            <Box className={css.imgContainer}>
              <img src={img} />
            </Box>
            <Box className={css.cardTitle}>{label}</Box>
            {title && (
              <Box
                className={css.viewMore}
                onClick={() => {
                  setShow(true);
                }}
              >
                Ver más
                <Box className={css.arrowContainer}>
                  <img src={Arrow} />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Grid>
  );
};

const Banner = ({ item }) => {
  const css = useStyles();

  const renderCustomIndicator = (onClickHandler, isSelected, index, label) => {
    return (
      <span
        style={{ color: isSelected ? "#3552CC" : "#C4C4C4" }}
        className={css.customIndicatorStyle}
        key={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
        onClick={onClickHandler}
      >
        <CircularDotIcon />
      </span>
    );
  };

  return (
    <Box data-testid="balance-summary" width="100vw" py={2}>
      <Carousel
        autoPlay
        interval={5000}
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        renderIndicator={renderCustomIndicator}
      >
        {item?.map(({ label, img, title }) => {
          return (
            <Box
              key={title}
              justifyContent="center"
              alignContent="center"
              display="flex"
            >
              <CardItem label={label} img={img} title={title} key={label} />
            </Box>
          );
        })}
      </Carousel>
    </Box>
  );
};

export default Banner;
