import { Grid } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { STEPPER_NAME } from '..';
import { selectStepperStatus } from '../../../../../store/slices/users';
import CreditCalculator from './StepperCreditCalculator/index';
import CreditDetails from './StepperCreditDetails/index';
import Box from '@mui/material/Box/Box';

const LoanDetails = (props) => {
  const {
    selectLoanDetail,
    LmsBalanceSummary,
    loanDetailsStepper,
    procedureCost,
    promoCreditLimit,
    setOpenAccordionTitle = { setOpenAccordionTitle },
  } = props;
  const stepperStatus = useSelector(selectStepperStatus);

  const disableEdit =
    stepperStatus?.application_status === 'APPROVED' ||
    stepperStatus?.application_status === 'LEGAL_REVIEW' ||
    stepperStatus?.application_status === 'DISBURSED';

  const [requestedAmount, setRequestAmount] = React.useState();
  const [requestedTenure, setRequestedTenure] = React.useState();

  React.useEffect(() => {
    setRequestAmount(selectLoanDetail?.req_amt);
  }, [selectLoanDetail]);

  return (
    <div style={{ paddingBottom: 0 }}>
      <Box
        sx={(theme) => ({
          fontFamily: 'SFUIText-bold',
          fontSize: '24px',
          color: '#6B7779',
          lineHeight: 2.75,
          [theme.breakpoints.down('lg')]: {
            fontSize: '21px',
            lineHeight: 1.25,
            fontFamily: 'Urbanist-Bold',
          },
          [theme.breakpoints.down('tablet')]: { fontSize: '18px' },
        })}
      >
        Información del Crédito
      </Box>

      <Grid
        container
        sx={(theme) => ({
          borderRadius: '20px',
          padding: '25px 50px',
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          [theme.breakpoints.down('lg')]: {
            boxShadow: 'unset',
            padding: 0,
          },
        })}
        gap={2}
      >
        <Grid item xs={12} tablet={5.8}>
          <CreditCalculator
            promoCreditLimit={promoCreditLimit}
            procedureCost={procedureCost}
            requestedAmount={requestedAmount}
            setRequestedTenure={setRequestedTenure}
            setRequestAmount={setRequestAmount}
            selectLoanDetail={selectLoanDetail}
            loanDetailsStepper={loanDetailsStepper}
            LmsBalanceSummary={LmsBalanceSummary}
          />
        </Grid>
        <Grid item xs={12} tablet={5.8}>
          <CreditDetails
            requestedAmount={requestedAmount}
            requestedTenure={requestedTenure}
            selectLoanDetail={selectLoanDetail}
            loanDetailsStepper={loanDetailsStepper}
            LmsBalanceSummary={LmsBalanceSummary}
          />
        </Grid>
      </Grid>

      <div
        style={{
          textAlign: 'center',
          marginTop: 30,
        }}
      >
        <Box
          sx={(theme) => ({
            background: disableEdit ? '#999' : '#3552CC',
            display: 'inline',
            padding: '13px 112px',
            borderRadius: '8px',
            color: '#FFFFFF',
            cursor: 'pointer',
            [theme.breakpoints.down('lg')]: {
              borderRadius: '30px',
              fontFamily: 'Urbanist-SemiBold',
              fontSize: '14px',
              padding: '12px 2rem',
            },
          })}
          onClick={() => setOpenAccordionTitle(STEPPER_NAME.KYC)}
          disabled={disableEdit}
        >
          Continuar
        </Box>
      </div>
    </div>
  );
};

export default LoanDetails;
