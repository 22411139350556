import { ReactNode } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import useStyles from "./styles";

export interface GradientButtonProps {
  title?: string;
  iconComponent?: ReactNode;
  handleClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  customCss?: any;
  type?: "button" | "reset" | "submit" | undefined;
}

const GradientButton = ({
  iconComponent,
  handleClick,
  loading = false,
  title,
  disabled = false,
  customCss,
  type = "button",
}: GradientButtonProps) => {
  const css = useStyles();

  return (
    <button
      className={`${css.payNowGradientButtonStyle} ${customCss || ""}`}
      onClick={handleClick}
      disabled={disabled || loading}
      type={type}
    >
      {iconComponent}
      {title}
      {loading && <CircularProgress size={14} />}
    </button>
  );
};

export default GradientButton;
