import { FC } from "react";

import { IconProps, obsidian } from "./types";

const TransactionIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.9062 14.0036L0.474038 8.27532C0.177797 8.00937 0 7.56377 0 7.08687C0 6.60998 0.177791 6.16438 0.474038 5.89843L6.9062 0.170175C7.22278 -0.0567783 7.60375 -0.0567783 7.92033 0.170495C8.23692 0.397767 8.44726 0.821634 8.48027 1.29953C8.51354 1.77774 8.36541 2.246 8.08544 2.54715L4.60136 5.65489H23.5762C23.9593 5.65489 24.3132 5.92787 24.5047 6.37092C24.6961 6.81397 24.6961 7.35994 24.5047 7.80298C24.3132 8.24602 23.9593 8.51901 23.5762 8.51901H4.60136L8.08544 11.6268C8.34053 11.8556 8.50995 12.22 8.55039 12.6273C8.59059 13.0342 8.49822 13.445 8.2965 13.755C8.09477 14.0648 7.80332 14.2441 7.49585 14.2473C7.28503 14.2505 7.07876 14.1655 6.90623 14.0037L6.9062 14.0036ZM29.526 21.6507L23.0938 15.9225V15.9228C22.8588 15.6699 22.5551 15.5609 22.2572 15.6226C21.9591 15.6843 21.6942 15.9109 21.5277 16.2469C21.3611 16.5829 21.3082 16.9968 21.3817 17.3875C21.4554 17.7781 21.6487 18.1096 21.9146 18.2997L25.3987 21.4075H6.42381C6.0407 21.4075 5.68681 21.6805 5.49537 22.1235C5.30394 22.5666 5.30394 23.1125 5.49537 23.5556C5.68681 23.9986 6.04072 24.2716 6.42381 24.2716H25.3987L21.9146 27.3794C21.6595 27.6082 21.4901 27.9726 21.4496 28.3799C21.4094 28.7868 21.5018 29.1976 21.7035 29.5076C21.9053 29.8174 22.1967 29.9967 22.5042 29.9999C22.715 30.0031 22.9213 29.9181 23.0938 29.7563L29.526 24.0281C29.8222 23.7621 30 23.3165 30 22.8396C30 22.3627 29.8222 21.9171 29.526 21.6512L29.526 21.6507Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

TransactionIcon.defaultProps = {
  fill: obsidian,
};

export default TransactionIcon;
