import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  startHere: {
    color: '#9E9E9E',
    fontFamily: 'SFUIText-semibold',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.down('lg')]: {
      color: '#000',
      padding: '0px',
      margin: '0px 0px 1rem 0px',
      fontSize: '14px',
      fontFamily: 'Urbanist-Medium',
    },
  },
  availableCredit: {
    color: '#9E9E9E',
    [theme.breakpoints.down('lg')]: {
      color: '#000',
      fontFamily: 'Urbanist-Medium',
      fontSize: '14px',
    },
  },
  chooseTerm: {
    color: '#9E9E9E',
    fontFamily: 'SFUIText-semibold',
    lineHeight: '24px',
    marginTop: 20,
    [theme.breakpoints.down('lg')]: {
      color: '#000',
      fontFamily: 'Urbanist-Medium',
      fontSize: '14px',
    },
  },
  months: {
    padding: '5px 10px',
    marginRight: '10px',
    borderRadius: '10px',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Semibold',
      fontSize: '14px',
      borderRadius: '30px',
      padding: '8px 14px',
    },
  },
  // paymentPreference: {
  //   [theme.breakpoints.down('lg')]: {},
  // },
  currency: {
    fontFamily: 'SFUIText-bold',
    textDecoration: 'underline',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Bold',
      fontSize: '14px',
      marginTop: '2px',
      textDecoration: 'none',
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '12px' },
  },
  takeLoan: {
    padding: '10px 6px ',
    borderRadius: '8px',
    marginRight: '13px',
    fontFamily: 'SFUIText-bold',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Semibold',
      fontSize: '14px',
      borderRadius: '30px',
      marginRight: 0,
      padding: '10px 14px',
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: '12px',
      padding: '8px 10px',
    },
  },
}));

export default useStyles;
