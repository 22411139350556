import { Typography, Box } from "@mui/material";
import MailIcon from "../../../components/icons/Mail";
import ClockIcon from "../../../components/icons/Clock";
import LocationIcon from "../../../components/icons/Location";
import PhoneIcon from "../../../components/icons/Phone";
import InfoIcon from "../../../components/icons/Info";
import Header from "../../Home/Header";
import Footer from "../../Home/Footer";
import styles from "./styles";

const IconBoxContainer = ({ iconName, text }) => {
  const classes = styles();

  return (
    <Box className={classes.iconBoxContainerStyle}>
      <Box className={classes.iconBoxIconContainer}>{iconName}</Box>
      <Typography
        variant="subtitle1"
        className={`${classes.text12} ${classes.textJustify}`}
      >
        {text}
      </Typography>
    </Box>
  );
};

const SpecializedServiceUnit = () => {
  const classes = styles();

  return (
    <>
      <Header isBgBlue={false} />
      <Box className={classes.textWrapper}>
        <Typography
          variant="h3"
          className={`${classes.text12} ${classes.textCenter}`}
        >
          <strong>Unidad Especializada de Atención de Usuarios Medsi</strong>
        </Typography>
        <Box className={classes.line} mt={2} />
        <Box maxWidth='800px'>
          <Typography
            variant="subtitle1"
            lineHeight={1.25}
            className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
          >
            UNE. Unidad Especializada de Atención a Usuarios.
            <br />
            Munbrunn S.A. de C.V.
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
          >
            Titular: José Magdaleno Cabrera González
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
          >
            Datos del titular
          </Typography>
          <IconBoxContainer
            iconName={<MailIcon width={28} height={28} />}
            text="Correo electrónico: contacto@medsi.mx"
          />
          <IconBoxContainer
            iconName={<ClockIcon width={28} height={28} />}
            text="Horario de atención: lunes a viernes 9:30 a 15:00 y 17:00 a 19:00"
          />

          <IconBoxContainer
            iconName={<LocationIcon width={28} height={28} />}
            text="Domicilio: Emerson 316, Oficina 201, Piso 2, Colonia Polanco V Sección, Alcaldía Miguel Hidalgo, Ciudad de México, México C.P. 11570"
          />
          <IconBoxContainer
            iconName={<PhoneIcon width={28} height={28} />}
            text="Teléfono: +52 56 3930 6489"
          />
          <IconBoxContainer
            iconName={<InfoIcon width={28} height={28} />}
            text="Munbrunn, S.A. de C.V., únicamente cuenta con oficinas de atención al público en la Ciudad de México y no cuenta con sucursales"
          />
          <Typography
            variant="subtitle1"
            className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
            color="#656565"
          >
            En caso de alguna consulta, reclamación o aclaración podrá
            presentarla en la UNE.
            <br />
            Unidad Especializada de Atención a Usuarios, la cual dará respuesta
            <br />
            en un plazo no mayor a 30 días hábiles.
          </Typography>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default SpecializedServiceUnit;
