import { Typography } from "@mui/material";
import React from "react";
import TitleComponentStyles from "./TitleComponent.styles";

const TitleComponent = (props) => {
  const { text, center, disabled, titleStyle } = props;
  const classes = TitleComponentStyles();
  return (
    <Typography
      variant="h5"
      style={titleStyle}
      className={
        disabled ? `${classes.text} ${classes.disabled}` : classes.text
      }
      textAlign={center && "center"}
    >
      {text}
    </Typography>
  );
};

export default TitleComponent;
