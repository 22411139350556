import { Typography, Box } from "@mui/material";
import { debounce } from "lodash";
import { useState, useEffect } from "react";
import styles from "./styles";

const TermsAndConditions = ({ tncRef, canScrollToBottom }) => {
  const classes = styles();
  const [showAcepter, setShowAcceptar] = useState(true);

  const gotoBottom = () => {
    const containerRef = document.getElementById("scrollableDiv");
    if (containerRef) {
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  };

  useEffect(() => {
    if (canScrollToBottom) {
      // used to get the scrollable element which is out side of the component
      const containerRef = document.getElementById("scrollableDiv");
      if (containerRef) {
        containerRef.addEventListener("scroll", checkScrollPosition);
      }
      return () =>
        containerRef?.removeEventListener("scroll", checkScrollPosition);
    }
  }, [canScrollToBottom]);

  const checkScrollPosition = debounce(() => {
    const containerRef = document.getElementById("scrollableDiv");
    if (containerRef) {
      // logic to check if user scrolled near to the bottom of element
      if (containerRef.scrollTop + 1000 >= containerRef.scrollHeight) {
        setShowAcceptar(false);
      } else {
        setShowAcceptar(true);
      }
    }
  }, 200);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.textWrapper} id="scrollableDiv">
        <Typography
          variant="body1"
          textAlign="center"
          className={classes.textSize}
        >
          <strong>TERMINOS Y CONDICIONES DE USO</strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.textSize} ${classes.textTerm}`}
        >
          Munbrunn, S.A. de C.V., en lo sucesivo Medsi con domicilio en Calle
          Emerson # 316, segundo piso despacho 201, colonia Polanco V sección,
          alcaldía Miguel Hidalgo, C.P. 11560, Ciudad de México, da a conocer
          por este medio los Términos y Condiciones Generales de Uso de los
          Servicios Medsi a través del Sistema Medsi, en donde se puede
          encontrar información, servicios, consultas, contenidos y/o productos.
          <br />
          <br />
          Los presentes Términos y Condiciones Generales de Uso tienen un
          carácter obligatorio y vinculante; por lo que cualquier persona que
          acceda y haga uso del Sistema Medsi mediante su ingreso y registro en
          las Aplicaciones, Sitios Web y/o Plataformas de Medsi, será
          considerado como Usuario y consentirá estos Términos y Condiciones
          Generales. Asimismo, Medsi podrá establecer Términos y Condiciones
          específicos para Servicios Medsi cuando así se requiera. Cualquier
          persona que no acepte estos Términos y Condiciones Generales, así como
          el Aviso de Privacidad de Medsi, deberá abstenerse de utilizar los
          Servicios, sus Aplicaciones, Sitios Web y/o Plataformas o manifestar
          su deseo de terminar dicha relación, solicitando el cierre de su
          cuenta.
          <br />
        </Typography>

        <ol className={classes.textTitle}>
          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>DEFINICIONES.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              Para efectos de los presentes Términos y Condiciones Generales,
              los conceptos que a continuación se indican tendrán el significado
              que se les atribuye, ya sea en singular o en plural:
              <br />
              <br />
              <strong>Aplicaciones:</strong> Aquellas creadas o desarrolladas
              por Medsi, o de las cuales tiene los derechos para su uso y
              explotación, y a través de las cuales también proporciona los
              Servicios Medsi.
              <br />
              <br />
              <strong>Cuenta Medsi:</strong> Es la cuenta creada por el Usuario
              en Aplicaciones, Sitios Web y/o Plataformas de Medsi a través de
              la generación de un usuario y Contraseña, que le permite acceder a
              los Servicios Medsi con su Firma Electrónica.
              <br />
              <br />
              <strong>Datos de Registro:</strong> La información proporcionada
              por el Usuario en cualquiera de las Aplicaciones, Sitios Web y/o
              Plataformas de Medsi, y que es necesaria para la prestación de los
              Servicios Medsi, en el entendido de que en caso de no proporcionar
              los Datos de Registro el Usuario no podrá acceder al Sistema
              Medsi.
              <br />
              <br />
              <strong>Sistema Medsi:</strong> El conjunto de Aplicaciones,
              Sitios Web y/o Plataformas de Medsi relacionadas entre sí para la
              prestación de los Servicios Medsi en favor del Usuario.
              <br />
              <br />
              <strong>Firma Electrónica:</strong> Los datos en forma electrónica
              consignados en un mensaje de datos, o adjuntados o asociados a
              éste, que identifican al Usuario como firmante en relación con el
              mensaje de datos, incluyendo NIP, clave de acceso a las
              Plataformas y la firma digital del titular de la Cuenta Medsi.
              <br />
              <br />
              <strong>Medsi:</strong> De manera conjunta o separada: Munbrunn,
              S.A. de C.V., las filiales, subsidiarias y/o controladoras que
              prestan los Servicios Medsi. Las cuales tienen su domicilio en
              Calle Emerson # 316, segundo piso despacho 201, colonia Polanco V
              sección, alcaldía Miguel Hidalgo, C.P. 11560, Ciudad de México,
              México.
              <br />
              <br />
              <strong>Servicios Medsi:</strong> Los productos y servicios que
              son proporcionados por Medsi.
              <br />
              <br />
              <strong>Términos y Condiciones Generales:</strong> El presente
              documento en el que se establecen las reglas, condiciones y
              obligaciones de carácter general que aplican a los Servicios
              Medsi, así como sus actualizaciones, modificaciones,
              reexpresiones, de tiempo en tiempo.
              <br />
              <br />
              <strong>Usuario:</strong> Las personas físicas o morales que
              ingresen y se registren en las Aplicaciones, Sitios Web y/o
              Plataformas.
            </Typography>
          </li>
          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>USO Y RESTRICCIONES.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              Los Usuarios podrán contratar los Servicios Medsi, siempre que
              tengan capacidad legal para contratar y obligarse a lo establecido
              en los presentes Términos y Condiciones Generales. En el caso de
              Usuarios que sean personas morales, la persona física que realice
              el ingreso y registro en Medsi deberá tener la representación
              legal con facultades para actos de administración en términos del
              segundo párrafo del artículo 2554 del Código Civil Federal o sus
              correlativos para la República Mexicana, en relación con el
              artículo segundo del Código de Comercio, otorgado ante fedatario
              público.
              <br />
              <br />
              La persona que realice el registro y/o que haga uso de los
              Servicios Medsi en representación de una persona moral, está dando
              por entendido que cuenta con facultades suficientes para actuar en
              nombre y por cuenta de ella y que dicha persona moral acepta
              adherirse a los presentes Términos y Condiciones Generales.
              <br />
              <br />
              No podrán contratar los Servicios Medsi las personas menores de
              edad, o las personas que hayan sido suspendidos temporalmente o
              inhabilitados definitivamente por Medsi.
              <br />
              <br />
              Medsi, a su libre determinación, se reserva el derecho de rechazar
              cualquier solicitud de contratación de los Servicios Medsi,
              incluso de negar el ingreso o registro o de suspender o cancelar
              cualquier prestación de Servicios, sin que esté obligado a
              comunicar o exponer las razones de su decisión y sin que ello
              genere algún derecho a indemnización o resarcimiento en favor del
              Usuario.
              <br />
              <br />
              Las Partes acuerdan que el Usuario manifiesta su consentimiento
              respecto de la aceptación de estos Términos y Condiciones, así
              como tener acceso a los Servicios Medsi, mediante el uso de una
              Firma Electrónica, incluido el NIP, a través de Medios
              Electrónicos.
              <br />
              <br />
              Los Usuarios accederán a Medsi mediante el ingreso de su dirección
              de e-mail registrado en la Cuenta Medsi y su Firma Electrónica; en
              el caso de dispositivos móviles que lo permitan y si los Usuarios
              así lo deciden, con el reconocimiento de su huella digital o
              reconocimiento facial.
              <br />
              <br />
              Los Usuarios se obligan a mantener la confidencialidad de su NIP o
              clave de ingreso al Sistema Medsi, siendo los únicos y exclusivos
              responsables por todas las operaciones efectuadas desde su Cuenta
              Medsi, y obligándose a notificar a Medsi por correo electrónico a
              la dirección contacto@medsi.mx o a través de los medios que se
              pongan a su disposición en el Sistema Medsi, cualquier uso no
              autorizado de la Cuenta Medsi, así como del ingreso o de intentos
              de ingreso no autorizados a la misma.
              <br />
              <br />
              Los Usuarios reconocen y aceptan que su Cuenta Medsi es personal,
              única e intransferible, y será asignada a un solo titular; por lo
              que está prohibida su venta, cesión o transferencia bajo cualquier
              título, y no podrán permitir ni autorizar el uso de la misma por
              terceras personas.
              <br />
              <br />
              Cualquier referencia a dinero en las Plataformas se entenderá
              hecha en la moneda de curso legal en los Estados Unidos Mexicanos.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>REGISTRO EN MEDSI.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              Para utilizar los Servicios Medsi, el Usuario deberá crear una
              Cuenta Medsi, a través del llenado del formulario de registro en
              todos sus campos con información exacta, precisa y veraz, 3
              generando un usuario y contraseña secreta. El Usuario se obliga a
              actualizar la información proporcionada en los formularios de
              registro conforme resulte necesario. Los Usuarios garantizan y
              responden, en cualquier caso, de la legalidad, veracidad,
              exactitud, vigencia y autenticidad de la información proporcionada
              en los formularios de registro. La información del Usuario estará
              siempre disponible accediendo a su Cuenta Medsi mediante el
              ingreso del usuario y contraseña secreta registrados.
              <br />
              <br />
              Medsi se reserva el derecho de solicitar la verificación de la
              identidad de los Usuarios, por lo que el Usuario reconoce que
              Medsi, pueda solicitar cualquier comprobante y/o dato adicional a
              efecto de corroborar la información proporcionada en el formulario
              de registro o para asegurar su identificación. Asimismo, Medsi se
              reserva el derecho de suspender temporal o definitivamente a
              aquellos Usuarios cuya información no haya sido posible confirmar.
              <br />
              <br />
              Medsi enviará notificaciones y comunicados a los Usuarios a través
              de los canales de comunicación que considere apropiados, tales
              como correos electrónicos, mensajes de texto, mensajes emergentes,
              notificaciones automáticas y aplicaciones de mensajería. Al
              realizar el registro, el Usuario autoriza la conexión de la cuenta
              Medsi a una cuenta o plataforma de un tercero (por ejemplo, una
              cuenta de redes sociales o aplicaciones de mensajería), y es
              posible que pueda administrar sus preferencias de conexión desde
              su cuenta Medsi o desde la cuenta o la plataforma del tercero.
              Consulte el aviso de privacidad que rige la plataforma de dicho
              tercero para obtener más información sobre las opciones que tiene
              a su disposición.
            </Typography>
          </li>
          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>MEDSI PAY.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              <strong>
                <u>Definiciones.</u>
              </strong>{" "}
              Las partes acuerdan que, para efectos este apartado, los conceptos
              que a continuación se indican tendrán el siguiente significado, ya
              sea en singular o en plural:
              <br />
              <br />
              <strong>Clientes:</strong> Las persona(s) física(s) o moral(es)
              que adquieran alguno de los bienes o servicios ofrecidos por
              Medsi.
              <br />
              <br />
              <strong>Referencia:</strong> La referencia que se generará por el
              Participante del SPEI, ya sea para la cobranza de las Órdenes de
              Pago a nombre del Proveedor o para el pago del Crédito Medsi.
              <br />
              <br />
              <strong>Crédito Medsi: </strong> El crédito simple que, en su
              caso, Medsi otorga al Cliente, de conformidad con lo establecido
              en este Contrato y en las políticas de crédito internas de Medsi.
              <br />
              <br />
              <strong>Cuenta Medsi Pay: </strong> La cuenta creada en Medsi Pay,
              a través de la cual se podrán crear 2 (dos) tipos de perfiles:
              <br />
              <br />
              <Typography
                variant="subtitle1"
                className={`
                ${classes.textSize}
                  ${classes.mt16}
                    ${classes.alignJustify}
                `}
              >
                <ol className="lowerAlpha">
                  <li>
                    <u>Perfil de Proveedor.</u> El Proveedor creará las Órdenes
                    de Pago por el procedimiento realizado al cliente, y
                    recibirá el pago de éstas, a través de la Plataforma Medsi
                    Pay, hasta el crédito autorizado al Cliente. Asimismo,
                    proporcionará a Medsi, previo a la recepción del pago, y por
                    única ocasión, su contraseña clave CIEC emitida por el
                    Servicio de Administración Tributaria.
                  </li>
                  <li>
                    <u>Perfil de Cliente.</u> El Cliente solicitará un Crédito
                    Medsi para pagar el procedimiento realizado por el
                    proveedor; proporcionará información para que Medsi realice
                    un análisis financiero; y en caso de obtener dicho crédito,
                    pagará periódicamente a Medsi por el monto del Crédito
                    Medsi.
                  </li>
                </ol>
              </Typography>
              <br />
              <br />
              <strong>Día Hábil: </strong> Significa todos los días de lunes a
              viernes, excepto los sábados, domingos y aquellos dispuestos por
              la Procuraduría Federal del Consumidor para que las instituciones
              de crédito presten sus servicios al público en la República
              Mexicana.
              <br />
              <br />
              <strong>Destino del Crédito Medsi: </strong> El Crédito Medsi se
              aplicará para el pago del procedimiento realizado por el
              Proveedor, previamente autorizado por Medsi.
              <br />
              <br />
              <strong>Instrucción de Disposición:</strong> En su caso, la
              instrucción irrevocable de pago que el Cliente emite a través de
              su Cuenta Medsi Pay para pagar una Orden de Pago con los recursos
              del Crédito Medsi.
              <br />
              <br />
              <strong>Orden de Pago: </strong> El documento generado por el
              Proveedor respecto del procedimiento realizado al Cliente, con la
              finalidad de que el Cliente liquide la misma a través de un
              Crédito Medsi. Dicha Orden de Pago deberá contener al menos: (i)
              CLABE del Proveedor; (ii) nombre completo o razón o denominación
              social del Cliente, (iii) nombre completo o razón o denominación
              social del Proveedor; (iv) RFC del Cliente y del Proveedor; (v)
              monto a pagar, y (vi) descripción del procedimiento realizado, así
              como cualquier otro dato que de tiempo en tiempo Medsi determine.
              <br />
              <br />
              <strong>Participante del SPEI:</strong> Entidad Financiera que
              proporciona a Medsi el servicio para realizar operaciones a través
              del SPEI, por medio de las cuales se liquidará las órdenes de pago
              del proveedor y se realizará la cobranza del Crédito Medsi al
              Cliente. MexpagoMexpago (“Mexpago Transacciones”, S.A.P.I de
              C.V.en los sucesivo “MexpagoMexpago”, será la entidad designada
              para realizar dicho servicio.
              <br />
              <br />
              <strong>Proveedor de servicios:</strong> La persona o empresa que
              ofrece servicios al Cliente, y que utiliza Medsi Pay.
              <br />
              <br />
              <strong>Medsi Pay: </strong> El Servicio a través del cual el
              Cliente solicitará el crédito para financiar el procedimiento
              realizado por el proveedor.
              <br />
              <br />
              <strong>Seguimiento de Cobranza:</strong> Medsi recibirá el pago
              del Cliente respecto al crédito otorgado, de conformidad con los
              términos y condiciones del contrato firmado.
              <br />
              <br />
              <strong>SPEI</strong> Sistema de Pagos Electrónicos
              Interbancarios.
              <br />
              <br />
              <strong>
                <u>Contraprestación. </u>
              </strong>
              El uso Medsi Pay generará una contraprestación a cargo del
              proveedor. El monto y periodo de pago de la contraprestación por
              el uso de los Servicios Medsi Pay dependerá de las modalidades o
              plan disponibles al momento de su contratación. En cualquier caso,
              previo al uso de los Servicios Medsi Pay y/o en caso de
              actualización de la contraprestación, Medsi notificará el monto de
              la Contraprestación y el periodo a través de la Cuenta Medsi Pay.
              El titular de la Cuenta Medsi Pay que no desee realizar el pago de
              la contraprestación correspondiente, podrá dejar de utilizar los
              Servicios Medsi Pay en cualquier momento y/o solicitar la
              cancelación de la Cuenta Medsi de conformidad con los Términos y
              Condiciones Generales; en el entendido que, de no realizar el pago
              correspondiente, la Cuenta Medsi Pay será suspendida de forma
              inmediata.
              <br />
              <br />
              <strong>
                <u>Alcance de Medsi Pay. </u>
              </strong>
              El alcance del servicio proporcionado por Medsi Pay consistirá en:{" "}
              <br />
              <br />
              El Cliente que solicite el Crédito Medsi para realizar el pago del
              procedimiento realizado por el Proveedor, autorizará a Medsi a
              realizar un análisis financiero, y en caso de aprobarse dicho
              crédito, el Servicio de Seguimiento de Cobranza.
              <ul className="upperAlpha">
                <li>Para el Proveedor:</li>
                <ul className="lowerRoman">
                  <li>
                    Generar y enviar Órdenes de Pago a través de Medsi Pay.
                  </li>
                  <li>
                    Realizar la contraprestación a Medsi por el uso de Medsi
                    Pay.
                    <br />
                    <br />
                    El Proveedor al aceptar estos Términos y Condiciones
                    específicos, y cuando Medsi así lo determine, deberá llenar
                    y firmar el formato de solicitud y/o enviar cualquier otro
                    documento que el participante del SPEI requiera, conforme a
                    sus procesos de validación. Asimismo, el Proveedor: (i) en
                    caso de que Medsi lo requiera, realizará el pago de la
                    Contraprestación al Participante del SPEI, y (ii) otorgará
                    un mandato a Medsi desde la aceptación de los Términos y
                    Condiciones para proporcionar al Participante del SPEI la
                    documentación del Proveedor que sea cargada en la Plataforma
                    por éste y/o que Medsi tenga en su poder para procesar las
                    órdenes de pago.
                  </li>
                  <li>
                    El Proveedor acepta y reconoce que el pago de los servicios
                    otorgados al Cliente, serán liquidados mediante la
                    transferencia electrónica de fondos que se realice con los
                    recursos del Crédito Medsi.
                  </li>
                  <li>
                    El Proveedor acepta y reconoce es el único responsable del
                    cálculo y determinación del monto total de la Orden de Pago.
                    Es decir, Medsi no recibe recursos provenientes de las
                    Órdenes de Pago.
                  </li>
                  <li>
                    El Proveedor recibirá el pago por parte de Medsi, previa
                    Instrucción de Disposición emitida por el Cliente a Medsi.
                    El pago de la Orden de Pago se realizará hasta por el monto
                    del Crédito Medsi, a través del participante del SPEI a la
                    cuenta que indique el Cliente en la Instrucción de
                    Disposición, y que será la misma que el Proveedor indicó en
                    la Orden de Pago correspondiente.
                  </li>
                </ul>
                <li>
                  Para el Cliente:
                  <ul className="lowerRoman">
                    <li>
                      Posibilidad de acceder a un Crédito Medsi, mismo que en el
                      caso de ser otorgado, será destinado a la liquidación de
                      la Orden de Pago, mediante la Instrucción de Disposición
                      que realice el Cliente en la Cuenta Medsi Pay.
                    </li>
                    <li>
                      Proporcionar información a Medsi para que éste realice un
                      análisis financiero del Cliente, a efecto de que Medsi le
                      otorgue un crédito, siempre y cuando el Cliente acepte
                      estos Términos y Condiciones específicos, cree su Cuenta
                      Medsi Pay y haya otorgado su autorización y proporcionado
                      la información necesaria para el análisis financiero
                      correspondiente.
                      <br />
                      <br />
                      El Cliente acepta, reconoce y se obliga a: (i) pagar el
                      crédito con recursos propios y lícitos a la cuenta
                      bancaria de Medsi, a través del participante del SPEI,
                      desde una cuenta bancaria de la que sea titular, (ii) en
                      caso contrario, la persona que realice el pago del crédito
                      será obligado solidario y se deberá señalar desde su
                      registro en Medsi Pay; (iii) en caso de que Medsi otorgue
                      un Crédito a favor del Cliente, previamente, deberá
                      aprobar las políticas de crédito vigentes e internas de
                      Medsi, y cumplir el proceso y requisitos establecidos en
                      el sitio de internet Medsi.com.mx, y 6 (iv) una vez
                      aprobado el Crédito Medsi, realizará la generación de la
                      Instrucción de Disposición para el pago de los servicios
                      otorgados por el Proveedor.
                      <br />
                      <br />
                      El Cliente sabe y reconoce que Medsi no es responsable de
                      la calidad y/o funcionamiento de los bienes y/o servicios
                      ofrecidos por el Proveedor, por lo que cualquier situación
                      relativa a estos, entregas, devoluciones, garantías y/o
                      facturación, deberá atenderla directamente con el
                      Proveedor, en el entendido de que el Cliente estará
                      obligado a pagar el Crédito Medsi conforme a lo
                      establecido en el contrato y demás documentos suscritos
                      por el Cliente y relativos al Crédito Medsi.
                      <br />
                      <br />
                      Los pagos realizados de conformidad con las Órdenes de
                      Pago emitidas, se realizarán únicamente a través del
                      sistema del participante del SPEI a cada Proveedor.
                      <br />
                      <br />
                      Medsi en ningún momento recibirá cantidad alguna por los
                      servicios o procedimientos realizados al Cliente, en el
                      entendido de que Medsi únicamente otorga el crédito Medsi
                      a través del uso de Plataforma Medsi Pay.
                    </li>
                  </ul>
                </li>
              </ul>
              <strong>
                {" "}
                <u>Uso de la Plataforma Medsi Pay.</u>
              </strong>{" "}
              El uso de la Plataforma Medsi Pay estará sujeto a las siguientes
              características: (a) tiempo de respuesta, Medsi procesará los
              Servicios Medsi Pay en la Plataforma Medsi Pay, en el entendido de
              que Medsi sólo proporciona ésta para que el Cliente solicite el
              crédito Medsi, y con ello se realice el pago al Proveedor de los
              servicios o procedimientos, por lo que el tiempo en que se refleje
              en firme el depósito , dependerá del SPEI y no de Medsi; (b) envío
              de recibo a través de la Plataforma Medsi Pay, el Proveedor de los
              servicios o procedimientos podrá descargar el comprobante de pago
              de las Órdenes de Pago correspondientes ingresando a su Cuenta
              Medsi Pay. Medsi podrá notificar al Proveedor cuando se realice el
              pago de las Órdenes de Pago mediante correo electrónico y/o
              mensaje SMS a los correos y/o teléfonos registrados en la Cuenta
              Medsi Pay. El Cliente reconoce expresamente que Medsi no es
              responsable ni tendrá obligación alguna del funcionamiento de
              SPEI.
              <br />
              <strong>
                <u> Obligaciones del Proveedor y del Cliente</u>
              </strong>
              Al hacer uso de la Plataforma Medsi Pay y los Servicios Medsi Pay,
              el Proveedor y el Cliente se obligan, según corresponda, a lo
              siguiente:
              <br />
              <ul className="lowerRoman">
                <li>
                  Utilizar la Plataforma Medsi Pay y los Servicios Medsi Pay
                  para solicitar el crédito Medsi para el pago de los bienes y/o
                  servicios inherentes al objeto social del Proveedor. No
                  obstante, lo anterior, el Proveedor reconoce que no podrá
                  utilizar Medsi para la recibir pagos derivados de la
                  comercialización de bienes y/o servicios que de manera
                  enunciativa se enlistan a continuación:
                  <ul className="lowerAlpha">
                    <li>
                      Servicios que ofrecen
                      subvenciones/incentivos/gratificaciones monetarias de
                      gobierno.
                    </li>
                    <li>
                      Juegos de azar o competiciones de/con efectivo/dinero.{" "}
                    </li>
                    <li>Prostitución y/o pornografía.</li>
                    <li>
                      Actos que pudieran dañar la reputación de Medsi, los
                      Clientes o cualquiera de sus productos y/o servicios
                    </li>
                    <li>
                      Pagos a personas y/u organizaciones que apoyan al
                      terrorismo.
                    </li>
                    <li>Partidos Políticos.</li>
                    <li>
                      Donación de recursos monetarios bajo cualquier
                      circunstancia.
                    </li>
                    <li>
                      Casinos, casas de juego, apuestas, lotería, billetes de
                      lotería, juegos de azar, carreras de caballos.
                    </li>
                    <li>
                      Cualquier otro bien o servicio que sean constitutivos de
                      delitos o contrarios a la legislación de los Estados
                      Unidos Mexicanos, o leyes internacionales, o que vayan en
                      contra de las políticas internas de Medsi.
                    </li>
                  </ul>
                  <br />
                  <br />
                  El Proveedor y el Cliente autorizan a Medsi a que realice
                  actividades de monitoreo, validación y supervisión respecto de
                  los Servicios proporcionados por el proveedor, así como la
                  información y documentos registrados por el Proveedor y los
                  Clientes en la Plataforma Medsi Pay, así como de los conceptos
                  y montos de las Órdenes de Pago y/o las Instrucciones de
                  Disposición.
                  <br />
                  <br />
                  Cuando el Proveedor y/o el Cliente se hayan registrado en
                  Medsi Pay, Medsi podrá requerirles que aporten documentación y
                  otras pruebas con el ánimo de identificarlos, así como para
                  asegurar que el Proveedor y los Clientes cumplen con todos los
                  requisitos necesarios, de acuerdo con las leyes aplicables en
                  materia de prevención de lavado de dinero, de conformidad con
                  otras leyes aplicables y políticas de Medsi y con estos
                  Términos y Condiciones.
                  <br />
                  <br />
                  Medsi podrá realizar una revisión crediticia al Proveedor y a
                  los Clientes (cuando así lo autoricen y/o soliciten el
                  Servicio de Pago a Proveedor con Crédito Medsi), para la cual
                  se comprometen a proporcionar la información y autorizaciones
                  necesarias, en su caso. Medsi podrá, a su libre determinación:
                  (i) prestar los Servicios Medsi Pay al Proveedor y/o al
                  Cliente; y (ii) generar o negar el registro del Proveedor y/o
                  del Cliente.
                  <br />
                  <br />
                  Cuando el Proveedor y/o el Cliente generen su Cuenta Medsi
                  Pay, Medsi enviará una confirmación a través del correo
                  electrónico o un mensaje al número celular registrado en la
                  Cuenta Medsi Pay, o cualquier otro medio que a su entera
                  discreción determine Medsi, confirmando que el Proveedor y/o
                  el Cliente podrán empezar a utilizar los Servicios Medsi Pay.
                </li>
                <li>
                  Entregar los bienes y/o servicios al Cliente, una vez que se
                  acredite el pago correspondiente; salvo que el Proveedor a su
                  entera determinación decida entregarlos previo al pago
                  correspondiente.
                </li>
                <li>
                  El Proveedor en este acto acepta y reconoce que todos los
                  pagos que se reciban conforme a las Órdenes de Pago serán
                  válidos, en virtud de la aceptación de estos Términos y
                  Condiciones, por lo que se desiste de realizar cualquier
                  reclamación de pago a Medsi o al Participante del SPEI.
                </li>
                <li>
                  En caso de que el Proveedor proporcione a Medsi datos
                  personales de los Clientes, el Proveedor manifiesta contar con
                  las autorizaciones de los Clientes para compartir dichos datos
                  personales a Medsi, liberando a éste de cualquier
                  responsabilidad al respecto y obligándose a sacarlo en paz y a
                  salvo de cualquier responsabilidad al respecto.
                </li>
                <li>
                  En caso de que el Proveedor, utilice en la Plataforma Medsi
                  Pay cualquier marca o logo, manifiesta que cuenta con las
                  autorizaciones necesarias para el uso de los mismos, liberando
                  de cualquier responsabilidad a Medsi, obligándose a acreditar
                  a éste las autorizaciones correspondientes cuando le sea
                  requerido.
                </li>
              </ul>
              <strong>Obligaciones de Medsi. </strong>
              <ul className="lowerAlpha">
                <li>
                  Garantizar que las Órdenes de Pago, y en su caso, las
                  Instrucciones de Disposición, sean ejecutadas a la letra de la
                  información que para tales efectos proporcionó el Proveedor
                  y/o el Cliente a través de la Cuenta Medsi Pay.
                </li>
                <li>
                  Medsi no será responsable, entre otras cosas, por: (a) errores
                  u omisiones contenidas en las Instrucciones de Disposición que
                  pueda derivar en pagos equivocados o incompletos causados por
                  la introducción errónea por parte de los Clientes de
                  información relevante del Proveedor o la operación; (b)
                  accesos no autorizados a la Cuenta Medsi Pay del Proveedor y/o
                  el Cliente; (c) la calidad o funcionamiento de los bienes y/o
                  servicios ofrecidos por el Proveedor; (d) las fallas de la
                  conexión de una cuenta o plataforma de un tercero para las
                  comunicaciones, y (e) errores o fallas del SPEI.
                </li>
                <li>
                  Medsi protegerá los datos proporcionados por el Proveedor y/o
                  el Cliente y, a menos que sea requerido legalmente por las
                  autoridades competentes, no revelará, registrará o, de otra
                  forma, procesará la información que Medsi pueda recibir
                  durante la prestación de los Servicios Medsi Pay, salvo que se
                  realice de conformidad con estos Términos y Condiciones y el
                  Aviso de Privacidad.
                </li>
              </ul>
              <strong>Responsabilidades de las Partes y Limitaciones. </strong>{" "}
              Medsi no será responsable por pagos equivocados o incompletos
              causados por la introducción errónea del e-mail, número celular,
              cuenta de redes sociales o aplicación de mensajería, Cuenta
              Bancaria del Cliente y/o cualquier otra información por parte del
              Proveedor. Los titulares de la Cuenta Medsi Pay en este acto
              reconocen y aceptan su total responsabilidad por las pérdidas que
              se originen, en especial si a través de la Cuenta Medsi Pay, se
              impartieran Instrucciones de Pago u Órdenes de Pago incompletas,
              duplicadas, no autorizadas o meramente erróneas, así como por los
              eventuales perjuicios que pueda causar a Medsi.
              <br />
              <br />
              Medsi no será responsable ni garantizará el cumplimiento de las
              obligaciones que hubiesen asumido los titulares de la Cuenta Medsi
              Pay con terceros en relación con las Órdenes de Pago y/o las
              Instrucciones de Disposición. Los titulares de la Cuenta Medsi Pay
              reconocen y aceptan que al utilizar la Plataforma lo hacen por su
              propia voluntad, prestando su consentimiento libremente y bajo su
              propio riesgo y responsabilidad. En ningún caso Medsi será
              responsable por daño directo, indirecto, lucro cesante, o por
              cualquier otro daño y/o perjuicio que haya podido sufrir el
              Proveedor o el Cliente, debido a las transacciones realizadas o no
              realizadas a través de la Plataforma.
              <br />
              <br />
              En virtud que Medsi únicamente presta los Servicios Medsi Pay, y
              es ajeno a la obligación que dio origen a la prestación de los
              Servicios Medsi Pay, Medsi no será responsable ni tendrá
              obligación alguna de verificar las causas, importe o cualquier
              otra circunstancia relativa a las Órdenes de Pago y a las
              Instrucciones de Disposición, así como respecto de la existencia,
              calidad, cantidad, funcionamiento, estado, integridad o
              legitimidad de los bienes y/o servicios ofrecidos por el Proveedor
              y pagados por mediante el Crédito Medsi otorgado al Cliente, así 9
              como de la capacidad para contratar del Proveedor y del Cliente, o
              la veracidad de los datos por ellos proporcionados al momento de
              solicitar los bienes y/o servicios ofrecidos.
              <br />
              <br />
              El Usuario reconoce y acepta que el uso de los Servicios Medsi es
              responsabilidad exclusiva de éste, por lo que se obliga a sacar en
              paz y a salvo a Medsi derivado del uso negligente o inadecuado de
              las Aplicaciones, Sitios Web y/o Plataformas albergadas dentro del
              Sistema de Medsi.
              <br />
              <br />
              En caso de que algún tercero inicie cualquier tipo de reclamo o
              acciones legales contra el Proveedor o el Cliente, dichos reclamos
              o acciones eximen de toda responsabilidad a Medsi y a sus
              directores, gerentes, empleados, agentes, operarios,
              representantes y apoderados. El Proveedor y el Cliente declaran
              conocer y aceptar todos los riesgos de que terceros no autorizados
              accedan a la información correspondiente a su Cuenta Medsi Pay,
              por lo cual deja en paz y a salvo de todo tipo de responsabilidad
              a Medsi; en tal sentido, reconociendo que Medsi ha realizado sus
              mejores esfuerzos técnicos a los efectos de que la información
              incluida en la Plataforma mantenga su alto grado de
              confidencialidad posible.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>COSTO DE LOS SERVICIOS MEDSI.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              La navegación en los Sitios Web será a título gratuito para el
              Usuario. No obstante lo anterior, el Usuario acepta y reconoce que
              el acceso de algunos de los Servicios Medsi proporcionados a
              través del Sistema Medsi pueden generar a cargo del Usuario una
              contraprestación. La contraprestación dependerá del tipo de
              Servicio Medsi que el Usuario contrate de conformidad con los
              Términos y Condiciones que para cada Servicio Medsi apliquen.
              <br />
              <br />
              En el supuesto de que los Servicios Medsi tengan distintas
              modalidades o planes, la elección de la modalidad o del plan
              correspondiente será responsabilidad del Usuario. El Usuario podrá
              revisar la información relativa a la modalidad o plan elegidos,
              así como a la fecha de corte y el historial de pagos realizados.
              <br />
              <br />
              De tiempo en tiempo, Medsi podrá otorgar ofertas y/o promociones
              para contratar servicios y/o productos del Sistema Medsi, ya sea
              de manera gratuita o a un costo preferencial a favor de
              determinados Usuarios. Las ofertas y/o promociones serán
              informadas al momento de contratar los Servicios Medsi. El Usuario
              podrá consultar en cualquier momento, las condiciones aplicables
              en su Cuenta Medsi. En caso de que el Usuario incumpla con
              cualquier obligación de pago respecto de los Servicios Medsi que
              contrate, dejará sin efectos de manera inmediata y a total
              discrecionalidad de Medsi, cualquier oferta y/o promoción que se
              le hubiese otorgado a su favor.
              <br />
              <br />
              El Usuario acepta y reconoce que el monto cobrado como
              contraprestación por el uso de determinados Servicios Medsi podrá
              variar por razones que incluyen promociones, cambios en el plan o
              modalidad contratado, nuevos servicios, cambios en los impuestos
              aplicables, modificaciones inflacionarias anuales, entre otros,
              por lo que desde este momento autoriza a Medsi a cargar a la
              factura correspondiente por la prestación de los Servicios Medsi
              contratados dichos cambios variables. En cualquier caso, Medsi
              proporcionará al Usuario la información relativa a dichos ajustes
              vía correo electrónico al correo registrado en la Cuenta Medsi 10
              El Usuario reconoce que los Términos y Condiciones específicos
              pueden cambiar sin previo aviso; por lo que el Usuario se obliga a
              verificar las condiciones vigentes aplicables al momento de la
              contratación de los Servicios Medsi.
              <br />
              <br />
              El Usuario reconoce que los Términos y Condiciones específicos
              pueden cambiar sin previo aviso; por lo que el Usuario se obliga a
              verificar las condiciones vigentes aplicables al momento de la
              contratación de los Servicios Medsi.
            </Typography>
          </li>
          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>SEGURIDAD Y PRIVACIDAD DE LA INFORMACIÓN.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              La información proporcionada por los Usuarios en los formularios
              de registro para utilizar los Servicios Medsi, será procesada y
              almacenada en servidores o medios magnéticos que mantienen los más
              altos estándares de seguridad y protección, tanto física como
              tecnológica. Para más información sobre la privacidad de la
              información, Medsi pone a disposición del Usuario el Aviso de
              Privacidad correspondiente en la dirección de internet
              Medsi.com.mx.
              <br />
              <br />
              El acceso a la Cuenta Medsi, así como el bloqueo y
              restablecimiento de la contraseña de acceso, siempre estará en
              manos del Usuario. La Cuenta Medsi del Usuario, así como su
              contraseña, para acceder y utilizar a los Servicios Medsi, y en su
              caso, cualquier otra clave que sea necesaria para llevar a cabo
              operaciones dentro del Sistema Medsi, son personales,
              confidenciales e intransferibles por lo que, en todo momento, la
              custodia y el uso de dichas claves, serán de la exclusiva
              responsabilidad del Usuario. El Usuario reconoce que cualquier
              persona que tenga acceso a sus claves de acceso podrá tener acceso
              a su Cuenta Medsi; por lo que, podrá autorizar transacciones que
              con esta se realicen. El Usuario reconoce que será el responsable
              de todas las transacciones que sean procesadas a través de la
              plataforma de Medsi Pay, así como del pago de dichas
              transacciones.
            </Typography>
          </li>
          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>MODIFICACIONES.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              Medsi podrá realizar cambios y actualizaciones en los presentes
              Términos y Condiciones Generales en cualquier momento, haciendo
              públicos los términos modificados en el Sitio Web. Todos los
              términos modificados entrarán en vigor al siguiente día de su
              publicación. El Usuario dentro de los 5 (cinco) días naturales
              siguientes a la publicación de los cambios introducidos deberá
              comunicar por e-mail a Medsi la negativa de aceptación de los
              mismos; en este supuesto quedará cancelado el registro de Usuario
              y será inhabilitado de las Aplicaciones, Sitios Web y/o
              Plataformas de Medsi. Independientemente de lo anterior el Usuario
              deberá pagar aquellas cantidades que se encuentren a cargo por la
              contratación de cualquiera de los Servicios Medsi. Vencido el
              plazo anterior de 5 (cinco) días naturales, sin que el Usuario
              haya comunicado a Medsi su oposición, se considerará que el
              Usuario se encuentra conforme con los nuevos términos.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>SANCIONES Y RESPONSABILIDAD DEL USUARIO.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              El Usuario acepta y reconoce que no podrá hacer uso de ninguna
              acción o dispositivo software, u otro medio tendiente a interferir
              con el funcionamiento de las Aplicaciones, Sitios Web y/o
              Plataformas de Medsi. Cualquier intromisión, tentativa o actividad
              violatoria o contraria a las leyes sobre derecho de propiedad
              intelectual y/o a las prohibiciones estipuladas en estos Términos
              y Condiciones Generales por parte del Usuario o de cualquier otro
              tercero, lo hará responsable de las acciones legales pertinentes,
              y a las sanciones previstas por este acuerdo, obligándose a pagar
              una indemnización por los daños y perjuicios ocasionados.
              <br />
              <br />
              Sin perjuicio de cualquier otra medida, Medsi podrá suspender en
              forma temporal o inhabilitar de manera definitiva la cuenta que
              los Usuarios para la prestación de los Servicios Medsi ante
              cualquier intromisión, tentativa o actividad violatoria a las
              prohibiciones estipuladas en estos Términos y Condiciones
              Generales. Además Medsi podrá aplicar una sanción que impacte 11
              negativamente en la reputación de un Usuario, iniciar las acciones
              que estime pertinentes y/o suspender la prestación de los
              Servicios Medsi si: (a) se quebrantara alguna ley, o cualquiera de
              las estipulaciones de los Términos y Condiciones Generales,
              específicos y/o demás políticas de Medsi; (b) si incumpliera sus
              obligaciones como Usuario; (c) si se incurriera a criterio de
              Medsi en conductas o actos dolosos o fraudulentos; (d) no pudiera
              verificarse la identidad del Usuario o que cualquier información
              proporcionada por el mismo fuere errónea.
              <br />
              <br />
              El Usuario reconoce y acepta que la utilización de los Servicios
              Medsi es responsabilidad exclusiva de éste, por lo que se obliga a
              sacar en paz y a salvo a Medsi y a sus directores, gerentes,
              empleados, agentes, operarios, representantes y/o funcionarios
              respecto de cualquier queja, multa, denuncia, demanda y/o acción
              por parte de cualquier autoridad o tercero, derivado del uso
              negligente o inadecuado de las Aplicaciones, Sitios Web y/o
              Plataformas de Medsi.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>DISPONIBILIDAD DE LOS SERVICIOS MEDSI.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              Medsi no garantiza la disponibilidad y continuidad de la operación
              de los Servicios Medsi y/o su contenido en relación con alguna
              actividad específica. Por lo que desde este momento el Usuario
              libera de cualquier responsabilidad derivada de cualquier daño,
              perjuicio o pérdida causado al Usuario causados por fallas en
              aplicaciones, sitios web y/o plataformas de Medsi o de cualquier
              otra naturaleza.
              <br />
              <br />
              Medsi tampoco será responsable por cualquier software malicioso
              que pudiera infectar los dispositivos del Usuario como
              consecuencia del acceso, uso, o examen de las Aplicaciones, Sitios
              Web y/o Plataformas de Medsi, o bien, de la transferencia de
              datos, archivos, imágenes, textos, o audio, contenidos en el
              mismo, así como cualquier otra información enviada con el uso de
              Medios Electrónicos. Los Usuarios no podrán imputar
              responsabilidad alguna ni exigir pago por lucro cesante, en virtud
              de perjuicios resultantes de dificultades técnicas o fallas en las
              Aplicaciones, Sitios Web y/o Plataformas de Medsi. El sistema
              puede eventualmente no estar disponible debido a dificultades
              técnicas o fallas de Internet, o por cualquier otra circunstancia
              ajena a Medsi; en tales casos se procurará restablecerlo con la
              mayor celeridad posible sin que por ello pueda imputársele algún
              tipo de responsabilidad a Medsi. Medsi no será responsable por
              ningún error, omisión, perdidas de información o cualquier otro
              relacionado con las Aplicaciones, Sitios Web y/o Plataformas que
              se utilicen.
              <br />
              <br />
              Además de lo anterior, el Usuario acepta y reconoce que Medsi no
              será responsable por cualquier daño, perjuicio y/o pérdida que
              surja en relación con fallas en el rendimiento, error, omisión,
              interrupción, defecto, demora en la operación o transmisión del
              Sistema Medsi, o en la red de internet del Usuario, o falla de
              sistema o línea que pudiera infectar o afectar el equipo del
              Usuario como consecuencia del acceso o uso del Sistema Medsi; así
              mismo, Medsi tampoco será responsable de los daños y/o perjuicios
              que se ocasionen como consecuencia de deficiencias, desperfectos,
              fallas u otros problemas que se originen por la instalación,
              adecuación y conexión de los sistemas del Usuario o aplicaciones
              de mensajería, ni de los daños y/o perjuicios que se le pudieran
              causar si, por caso fortuito, fuerza mayor o por cualquier otro
              acontecimiento o circunstancia inevitable, más allá del control
              razonable de Medsi, que ocasione que el Usuario no pudiera hacer
              uso oportuno del Sistema Medsi.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>PROPIEDAD INTELECTUAL.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              Las Aplicaciones, Sitios Web y/o Plataformas de Medsi, los
              logotipos, así como todo el material que en ellos aparezca son
              marcas, avisos comerciales, dominios y obras, su derecho de uso y
              12 explotación de los mismos, incluyendo de manera enunciativa su
              divulgación, publicación, reproducción distribución y
              transformación son propiedad de Medsi o del legítimo propietario
              de los mismos y están protegidos por los tratados internacionales
              y las leyes aplicables en materia de propiedad intelectual y
              derechos de autor. Los derechos de autor sobre el contenido,
              organización, recopilación, compilación, información, logotipos,
              fotografías, imágenes, programas, aplicaciones, y en general
              cualquier información contenida o publicada en las aplicaciones,
              Sitios Web y/o Plataformas de Medsi se encuentran debidamente
              protegidos a favor de Medsi, sus socio comerciales, afiliados,
              proveedores y/o de sus respectivos propietarios, de conformidad
              con la legislación aplicable en materia de propiedad intelectual e
              industrial.
              <br />
              <br />
              El Usuario se obliga a no copiar, desensamblar, o descompilar, de
              forma alguna las Aplicaciones, Sitios Web y/o Plataformas de
              Medsi, así mismo el Usuario acepta y reconoce que no creará o
              intentará crear, mediante técnicas de ingeniería inversa o de
              cualquier otra manera, al código fuente de las Aplicaciones,
              sitios Web y/o Plataformas de Medsi, bajo pena de incurrir en las
              infracciones en materia de propiedad intelectual que correspondan.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>INDEMNIZACIÓN.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              El Usuario deberá indemnizar, y mantener en paz y a salvo a Medsi,
              sus representantes, apoderados, empleados y accionistas, de
              cualquier reclamación, demanda, pérdida, daño, perjuicio, multa,
              interés y/o gasto, que resulten de: (i) cualquier falsedad en la
              información proporcionada en los formularios de registro, falta de
              personalidad o capacidades suficientes para actuar en nombre de
              una persona moral; (ii) el incumplimiento a las obligaciones que
              se estipulan en los presentes Términos y Condiciones Generales,
              así como a los Términos y Condiciones específicos, que en su caso
              se establezcan para la prestación de determinados Servicios Medsi;
              incluyendo cualquier violación de las políticas de Medsi; (iii) el
              uso ilegal o inapropiado de los Servicios Medsi; (iv) la violación
              de cualquier derecho de propiedad intelectual; (v) la violación de
              cualquier ley, reglamento o circular vigente en México; (vi)
              cualquier uso de los Servicios Medsi que realicen terceros no
              autorizados, y/o (vii) cualquier incumplimiento o uso no
              autorizado, que a consideración de Medsi o de la legislación
              vigente aplique a los Servicios Medsi.
              <br />
              <br />
              En virtud de lo anterior, los Usuarios infractores pagarán los
              daños y/o perjuicios que se causen a Medsi, y/o a cualquier otro
              tercero, así como cualquier gasto en el que incurran incluyendo y
              sin estar limitado a, honorarios de abogados y/o cualquier
              profesionista. Asimismo, los Usuarios infractores, deberá sacar en
              paz y salvo a Medsi por cualquier reclamación presentada por un
              tercero, derivada de la utilización de cualquiera de los Servicios
              Medsi, así como de las Aplicaciones, Sitios Web y/o Plataformas de
              Medsi por parte del Usuario en contravención a las condiciones
              establecidas en los presentes Términos y Condiciones Generales, en
              su caso y cuando así aplique, a los Términos y Condiciones
              específicos y/o o a las leyes vigentes y aplicables.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>CANCELACIÓN DE LA CUENTA MEDSI.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              El Usuario podrá cancelar su Cuenta Medsi, siempre que no tenga
              contratado un Servicio Medsi vigente, en cuyo caso, la cancelación
              será efectiva inmediatamente. La cancelación podrá realizarse
              mediante la funcionalidad de “Cancelación de Cuenta Medsi” que se
              encontrará disponible en las Plataforma.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>TERCEROS Y ALIANZAS COMERCIALES.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
            >
              El Usuario es consciente de los terceros cuya intervención sea
              necesaria para la prestación de los Servicios Medsi, (los
              “Terceros”). Por lo tanto, el Usuario acepta y reconoce que el uso
              que haga de dichos servicios de Terceros, así como la aceptación
              de sus políticas de confidencialidad y términos de uso, son
              responsabilidad del Usuario. Como parte de una estrategia de
              negocios, Medsi establece alianzas comerciales con distintos
              socios, partners, entre otros, por lo que es posible que usted
              haya sido referido a Medsi por un tercero, quienes podrán ofrecer
              o promocionar alguno de los productos o Servicios Medsi. No
              obstante lo anterior, Medsi es el único autorizado para aprobar el
              otorgamiento de alguno de sus productos o la prestación de los
              Servicios Medsi, por lo que en caso de ser aprobado Usted debe
              asegurarse de entender los presentes Términos y Condiciones
              Generales, así como los que de manera específica apliquen a los
              Servicios Medsi, en su caso.
            </Typography>
          </li>

          <li>
            <Typography
              variant="body1"
              className={`${classes.textSize} ${classes.mt16}`}
            >
              <strong>JURISDICCIÓN Y LEY APLICABLE.</strong>
            </Typography>
            <Typography
              variant="subtitle1"
              className={`
                ${classes.textSize}
                ${classes.mt16}
                ${classes.alignJustify}
                ${classes.ml_40}
                `}
              ref={tncRef || null}
            >
              Para todo lo relativo a la interpretación, ejecución y
              cumplimiento de estos Términos y Condiciones Generales, el Usuario
              se somete a las leyes federales de los Estados Unidos Mexicanos
              que resulten aplicables, así como a los Tribunales competentes de
              la Ciudad de México, renunciando expresamente a cualquier otro
              fuero que pudiere corresponderles en razón de sus domicilios
              presentes o futuros, de la ubicación de sus bienes, o de cualquier
              otra causa.
              <br />
              <br />
              Última actualización el 24 de agosto de 2022.
            </Typography>
          </li>
        </ol>
      </Box>
      {canScrollToBottom && (
        <div className={classes.buttonWrapper}>
          <div
            onClick={gotoBottom}
            className={showAcepter ? classes.acceptar : classes.hide}
          >
            Desplazarse hacia abajo
          </div>
        </div>
      )}
    </Box>
  );
};

export default TermsAndConditions;
