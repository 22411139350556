import { useState } from "react";
import { Box, Grid, Typography, useTheme, useMediaQuery } from "@mui/material";

import { GTMClick } from "../../utils/gtm";
import { homePage } from "././../../constants/gtm";
import { emailRegex } from "../../utils/validations";

import useStyles from "./styles";
import Content from "./Content";
import Footer from "./Footer";
import Header from "./Header";
import image from "./images/image.svg";
import mobileImage from "./images/mobile-main.svg";
import Calculator from "./Calculator";
import { saveEmail } from "./helper";
import MobileContent from "./Content/Mobile";

const HomePage = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [showSetText, setShowSetText] = useState("");
  const [isError, setError] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleSubmit = async () => {
    GTMClick(homePage.loginWithUs);
    if (emailRegex.test(email)) {
      const res = await saveEmail({ email });
      if (res?.status === "success") {
        setError(false);
        setShowSetText(
          "Gracias por mostrar interés en Medsi. Su solicitud se ha agregado con éxito a nuestra lista de espera."
        );
      } else if (res?.status === "error") {
        setShowSetText(res?.meta?.errorMessage);
        setError(true);
      }
    } else {
      setShowSetText("ingresa un correo valido");
      setError(true);
    }
  };

  const handleTextChange = ({ target }) => {
    setEmail(target?.value);
  };

  return (
    <Grid
      container
      justifyContent="flex-start"
      flexDirection="column"
      className={classes.backgroundContainer}
    >
      <Header />

      <Box className={classes.mainAreaContainer}>
        {isMobile ? (
          <img
            src={mobileImage}
            alt="My Happy SVG"
            className={classes.mainImage}
          />
        ) : (
          <img src={image} alt="My Happy SVG" width="64%" />
        )}
        <Box className={classes.mainAreaCalculatorContainer}>
          <Calculator />
        </Box>
        <Box className={classes.mainAreaTextContainer}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            className={classes.mainAreaTextBox}
          >
            <Typography
              variant="h3"
              align="center"
              className={classes.mainAreaFirstTextStyle}
            >
              Logra tu meta
            </Typography>
            <Typography
              variant="h3"
              align="center"
              className={classes.mainAreaSecondTextStyle}
            >
              con nosotros
            </Typography>
            {isMobile ? (
              <Typography
                variant="h3"
                align="center"
                className={classes.mainAreaThirdTextStyle}
              >
                Sómos la mejor solución <br /> de crédito para procedimientos
                cosméticos,
                <br /> estéticosy electivos.
              </Typography>
            ) : (
              <Typography
                variant="h3"
                align="center"
                className={classes.mainAreaThirdTextStyle}
              >
                Sómos la mejor solución de crédito
                <br />
                para procedimientos cosméticos, estéticos <br />y electivos.
              </Typography>
            )}
          </Box>

          <Box className={classes.inputContainer}>
            <Box className={classes.inputInsideContainer}>
              <input
                placeholder="Anota tu correo"
                className={classes.emailInputStyle}
                onChange={handleTextChange}
              />

              <button
                className={classes.startGradientButtonStyle}
                onClick={handleSubmit}
                disabled={!emailRegex.test(email)}
              >
                Iniciar
              </button>
            </Box>
            {Boolean(showSetText) && (
              <Box className={classes.inputValidateTextStyle}>
                <span className={isError ? "error" : ""}>{showSetText}</span>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {isMobile && (
        <Box className={classes.mobileCalculator}>
          <Calculator />
        </Box>
      )}
      {isMobile ? <MobileContent /> : <Content />}
      <Footer />
    </Grid>
  );
};

export default HomePage;
