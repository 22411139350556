import * as yup from "yup";

export const validationSchema = yup.object({
  correo: yup
    .string("Ingresa tu correo electrónico")
    .email("Ingresa un correo válido")
    .required("El correo es requerido")
    .transform((value) => value.toLowerCase()),
  // confirmacionCorreo: yup
  //   .string("Confirma tu correo")
  //   .email("Ingresa un correo válido")
  //   .required("La confirmación es requerida")
  //   .transform((value) => value.toLowerCase())
  //   .oneOf([yup.ref("correo"), null], "Los correos deben coincidir"),
  contrasena: yup
    .string("Ingresa una contraseña")
    .required("La contraseña es requerida")
    .matches(
      /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\-+\*])(?=.{8,})/, //eslint-disable-line
      "La contraseña debe cumplir con los parámetros requeridos"
    ),

  promocode: yup.string().matches(
    /^[a-zA-Z0-9_ -]*$/, //eslint-disable-line
    "El código de promoción debe cumplir con los parámetros requeridos"
  ),

  // confirmacionContrasena: yup
  //   .string("Confirma tu contraseña")
  //   .required("Confirma tu contraseña")
  //   .oneOf([yup.ref("contrasena"), null], "Las contraseñas deben coincidir"),
  phoneNumber: yup
    .string()
    .required("Este campo es requerido")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, "Debe tener exactamente 10 dígitos"),
});
