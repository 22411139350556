import { FC } from "react";
import { Box } from "@mui/material";
import GradientCheckCircle from "../../../assets/common/GradientCheckMark.svg";

import Button from "../../../features/installmentLoan/components/Button";

import useStyles from "./styles";
import useCommonStyles from "../styles";

export interface ButtonClickProps {
  password: string;
}

interface SuccessPopupProps {
  onSubmit: () => void;
}

const SuccessPopup: FC<SuccessPopupProps> = ({ onSubmit }) => {
  const css = useStyles();
  const commonCss = useCommonStyles();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mt={4} textAlign="center" className={css.checkmarkWrapper}>
        <img src={GradientCheckCircle} width={118} alt="img blanco" />
      </Box>
      <Box className={commonCss.title} ml={3} mt={3}>
        Tu contraseña se ha cambiado
      </Box>
      <Box textAlign="center" mt={6}>
        <Button
          label="Continuar"
          onClick={() => onSubmit()}
          noCaps
          className={commonCss.loginButtonStyle}
        />
      </Box>
    </Box>
  );
};

export default SuccessPopup;
