import { FC, useEffect, useCallback, useState } from "react";

import SplashValidation from "../../components/sharedComponents/SplashValidation";
import getParams from "../../features/installmentLoan/utils/getParams";
import { useAppDispatch } from "../../store";
import {
  updateDoctorConfirmation,
  saveDoctorBankDetails,
} from "../../store/slices/auth/actions";
import { API_STATUS } from "../../constants/common";
import { errorToast } from "../../utils/toast";
import confirmationBg from "../../assets/Icons/GradientFilledTick.svg";
import FooterLine from "../../assets/header/footerLine.svg";
import ConfirmationView from "../../components/ConfirmationView";
import { DOCTOR_CONFIRMATION_STATUS } from "../../constants/common";

import Form from "./Form";
import useStyles from "./styles";

const DoctorConfirmation: FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [doctorDetails, setDetails] = useState<any>(null);
  const { token, app_id } = getParams(["token", "app_id"]);
  // const appId = localStorage.getItem("application_id") || "";
  const css = useStyles();

  const onLoading = useCallback(async () => {
    if (token) {
      const { data, status, meta } = await dispatch(
        updateDoctorConfirmation({ token, appId: app_id })
      ).unwrap();
      if (status === API_STATUS.SUCCESS) {
        if (
          data?.status &&
          data?.status !== DOCTOR_CONFIRMATION_STATUS.NOT_SAVED
        ) {
          setViewDetails(true);
        }
        setDetails(data);
        setLoading(false);
      } else if (status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
    }
  }, [token, dispatch]);

  const saveData = async (payload) => {
    const isEdit =
      doctorDetails?.bank_name &&
      doctorDetails.number &&
      doctorDetails.payment_method;
    const { status, meta } = await dispatch(
      saveDoctorBankDetails({ ...payload, isEdit, token, app_id })
    ).unwrap();
    if (status === API_STATUS.SUCCESS) {
      setViewDetails(true);
    } else {
      errorToast(meta?.errorMessage);
    }
  };

  useEffect(() => {
    onLoading();
  }, [onLoading]);

  return loading ? (
    <SplashValidation image={2} trueRedirect={false} />
  ) : viewDetails ? (
    <ConfirmationView
      title="¡Muchas gracias!"
      content={
        <span className={css.contentWrapper}>
          Has confirmado con éxito el nombramiento de{" "}
          <span className={css.link}>
            {doctorDetails?.first_name} {doctorDetails?.last_name}
          </span>{" "}
          para el próximo <br />
          <span className={css.link}>{doctorDetails?.appointment_date}</span> a
          las {"  "}
          <span className={css.link}>{doctorDetails?.appointment_time}</span>.
          <br />
          <br />
          Nos pondremos en contacto con la oficina nuevamente en la fecha de la
          cita. para confirmar que se realiza el trámite y tramitar el pago.
          <br />
          <br />
          Si la cita es cancelada o reprogramada, o si tiene alguna pregunta o
          comentario, favor escribir a{" "}
          <a href="mailto:contacto@medsi.mx" className={css.link}>
            contacto@medsi.mx
          </a>
        </span>
      }
      imgSrc={confirmationBg}
      footer={
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            maxWidth: 744,
            margin: "70px auto 0",
          }}
        >
          <img
            src={FooterLine}
            style={{
              height: 138,
            }}
            alt="img blanco"
          />
        </div>
      }
    />
  ) : (
    <Form saveData={saveData} doctorDetails={doctorDetails} />
  );
};

export default DoctorConfirmation;
