import { Grid, FormHelperText } from "@mui/material";
import TitleComponent from "../../../../../components/sharedComponents/TitleComponent";
import useStyles from "./styles";
import { RenderTimeInput } from "./RenderTimeInput";

const RenderTime = ({ formik, hasNotDate, disabled }) => {
  const css = useStyles();

  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    formik;

  const handleTimeChange = (e, ...rest) => {
    let hours = values.hours;
    let minutes = values.minutes;
    let time = values.time;
    const { name, value } = e.target;
    if (name === "hours") {
      hours = value;
    }
    if (name === "minutes") {
      minutes = value;
    }
    if (name === "time") {
      time = value;
    }
    setFieldValue("appointmentTime", `${hours}:${minutes} ${time}`);
    handleChange(e);
  };

  return (
    <Grid flexDirection="column" display="flex">
      <Grid item xs={12} className={css.titleLableTime}>
        <TitleComponent
          text={"Hora de la cita"}
          disabled={hasNotDate || disabled}
        />
      </Grid>
      <Grid item xs={12} display="flex">
        <RenderTimeInput
          values={values}
          handleChange={handleTimeChange}
          handleBlur={handleBlur}
          touched={touched}
          errors={errors}
          disabled={hasNotDate || disabled}
        />
      </Grid>
      {errors.appointmentTime &&
        (touched.hours || touched.minutes || touched.time) && (
          <FormHelperText className={css.errorTime}>
            {errors.appointmentTime}
          </FormHelperText>
        )}
    </Grid>
  );
};

export default RenderTime;
