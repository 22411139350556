export enum INSTALLMENT_LOAN_ROUTES {
  MAIN = "/dashboard",
  PAYMENT_SCHEDULE = "/installment-loan/payment-schedule",
  STATEMENT_DOWNLOAD = "/installment-loan/statement-download",
  FAQS = "/installment-loan/faqs",
  TRANSACTIONS = "/installment-loan/transactions",
  SIGN_UP = "/installment-loan/sign-up",
  PAYMENT_DETAILS = "/installment-loan/payment-details",
  CONTRACT_DETAILS = "/contract-details",
}

export enum AUTH_ROUTES {
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  EMAIL_VERIFICATION = "/email-verify",
}

export const ROUTES = {
  splashScreen: "/",
  register: "/registro",
  sigIn: "/sigIn",
  knowYouBetter: "/conocerteMejor",
  generalInformation: "/informacionGeneral",
  professionalInformation: "/DetallesProfesionales",
  creditHistory: "/historialCrediticio",
  generalData: "/datosGenerales",
  clientSignature: "/firmaCliente",
  personalInformation: "/informacionPersonal",
  processInformation: "/datosProcedimiento",
  identityInformation: "/informacionIdentidad",
  coSigneridentityInformation: "/cosigner-informacionIdentidad",
  underReviewKYC: "/under-review-kyc",
  creditConfirmation: "/confirmacionCredito",
  qrCode: "/codigoQr",
  thankYou: "/graciasPorElegirnos",
  CREDIT_REQUEST: "/solicitudDeCredito",
  sumaryCoSigner: "/co-aplicante",
  referenceNumber: "/numeroReferencia",
  report: "/reporte",
  logInReport: "/numeroReferencia",
  verifyEmail: "/verificacionDeEmail/:email",
  tnc: "/terms",
  publicPromocode: "/public-code",
  medsiOffer: "/medsi-offers",
  stepper: "/stepper",
  allLoan: "/all-loan",
  allStatement: "/all-statement",
  allPayment: "/all-payment",
  allTransactions: "/all-transactions",
  allApplication: "/all-application",
  privacyPolicy: "/privacy-policy",
  specializedServiceUnit: "/specialized-service-unit",
  contact: "/contact",
  pendingInformation: "/pending-information",
  doctorConfirmation: "/doctor-confirmation",
  aboutUs: "/about-us",
  faqs: "/faqs",
  procedureType: "/procedure-type",
  landingCreditRequest: "/landing-credit-request",
  medsiInTheNews: "/news",
  applicationConfirmation: "/application-confirmation",
  cosignerConsentReferenceNumber: "/cosigner-consent-reference-number",
  cosignerConsentSignature: "/cosigner-consent-signature",
  cosignerThankyYouPage: "/cosigner-muchas-gracias",
};
