import { FC, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom";

import Header from "../../components/header";
import SectionHeader from "../../components/sectionHeader";
import TwoColumns from "../../components/twoColumns";
import SideBar from "../../components/sidebar";
import {
  getBalanceSummary,
  getApplicationSummary,
  getApplicationBasicDetails,
} from "../../features/installmentLoan/store/installmentLoan";
import useStore from "../../store/getStore";
import { useAppDispatch } from "../../store";
import DownloadFile from "../../components/icons/DownloadPdfFile";
import isURL from "../../utils/isURL";

import ContractTable from "./Table";
import useStyles from "./styles";

export interface InstallmentLoanProps {
  isPaymentLink?: boolean;
  statementDownload?: boolean;
  contractDetails?: boolean;
  lateFeeDetails?: boolean;
}

export interface CheckoutRouteParams {
  uuid: string;
}

const InstallmentLoan: FC<InstallmentLoanProps> = () => {
  const dispatch = useAppDispatch();
  const { installmentLoan } = useStore();
  const css = useStyles();
  const history = useHistory();

  const { applicationSummary, balanceSummary } = installmentLoan;

  const handleClick = (link: string) => {
    if (isURL(link)) {
      window.open(link, "_blank");
    }
  };

  const loadData = async () => {
    await dispatch(getApplicationBasicDetails()).unwrap();
    await dispatch(getBalanceSummary()).unwrap();
    dispatch(getApplicationSummary());
  };

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={<SideBar />}
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header />
          </Box>
          <Box mt={-2} ml={-4}>
            <SectionHeader
              title="DETALLES DEL CONTRATO"
              actionMode="custom"
              backClick={onBack}
              customActionElement={
                balanceSummary?.contractPdf && (
                  <Button
                    className={css.button}
                    variant={"text"}
                    onClick={() =>
                      handleClick(balanceSummary?.contractPdf || "")
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box className={css.buttonIconContainer}>
                        <DownloadFile color="white" />
                      </Box>
                      <Typography className={css.buttonTextStyle}>
                        Descargar PDF
                      </Typography>
                    </Box>
                  </Button>
                )
              }
            />
          </Box>
          <Box mx={10} mt={4} width="65%">
            {applicationSummary && (
              <ContractTable applicationSummary={applicationSummary} />
            )}
          </Box>
        </>
      }
      rightBg
    />
  );
};

export default InstallmentLoan;
