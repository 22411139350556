import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

import creditCalender from "../../../assets/home/credit-calender-v2.svg";
import creditCalenderMobile from "../../../assets/home/credit-calender-mobile.svg";

// import InputContainer from "./InputContainer";
import Calculator from "./Calculator";
import useStyle from "./styles";

export const MainContent = () => {
  const css = useStyle();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box className={css.container}>
      {/* <InputContainer /> */}
      <Box className={css.content}>
        <Grid container>
          <Grid md={6} xs={12} item>
            <Box pr={10}>
              <Box className={css.heading}>
                Una línea de crédito familiar para procedimientos médicos y
                estéticos
              </Box>
              <Box mt={2} className={css.description}>
                Medsi pone a tu alcance una solución accesible, fácil de usar y
                flexible, con la que puedes obtener al instante hasta $80,000
                pesos de crédito para tu tratamiento o cirugía.
              </Box>
            </Box>
            <Box className={css.imgContainer}>
              <img
                src={isMobile ? creditCalenderMobile : creditCalender}
                width="100%"
                height={"100%"}
                alt="CreditCalendar"
              />
            </Box>
          </Grid>
          <Grid md={6} xs={12} item>
            <Calculator />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MainContent;
