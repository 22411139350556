import { FC, useState } from "react";
import Box from "@mui/material/Box";

import { useAppDispatch } from "../../store";
import { requestResetEmail, setLoadingValue } from "../../store/slices/auth";
import { errorToast } from "../../utils/toast";
import {
  API_STATUS,
  FORGET_PASSWORD_METHOD,
  VERIFICATION_METHOD,
} from "../../constants/common";
import AlertComponent from "../../components/sharedComponents/AlertComponent";

import useStyles from "./styles";
import ForgotPopup, { ButtonClickProps } from "./ForgotPopup";
import SuccessPopup from "./SuccessPopup";
import OTPPopup from "./OTPPopup";

const ForgotPassword: FC = () => {
  const css = useStyles();
  const dispatch = useAppDispatch();

  const [isSuccess, setSuccess] = useState(false);
  const [userEmail, setEmail] = useState("");
  const [preferredForgetPassword, setPrefferedForgetPassword] = useState(
    FORGET_PASSWORD_METHOD.EMAIL
  );

  const onPressButton = async (payload: ButtonClickProps) => {
    dispatch(setLoadingValue(true));
    if (payload) {
      setEmail(payload?.email);
      sendEmail(payload?.email);
    }
  };

  const sendEmail = async (email) => {
    const payload = {
      email,
    };
    const res = await dispatch(requestResetEmail(payload)).unwrap();
    if (res.status === API_STATUS.SUCCESS) {
      setSuccess(true);
    } else if (res.status === API_STATUS.ERROR) {
      errorToast(res.meta.errorMessage);
    } else {
      errorToast("Error");
    }
  };

  const resentEmail = async () => {
    sendEmail(userEmail);
  };

  return (
    <Box className={css.loginMainContainer}>
      <AlertComponent
        open
        handleOpen={() => null}
        handleClose={() => null}
        body={
          isSuccess ? (
            <SuccessPopup onSubmit={() => resentEmail()} />
          ) : (
            <>
              {preferredForgetPassword === FORGET_PASSWORD_METHOD.EMAIL && (
                <>
                  <ForgotPopup
                    onSubmit={(payload: ButtonClickProps) =>
                      onPressButton(payload)
                    }
                  />
                  <div
                    className={css.passwordAlternativeText}
                    onClick={() =>
                      setPrefferedForgetPassword(FORGET_PASSWORD_METHOD.OTP)
                    }
                  >
                    ¿Restablecer contraseña usando OTP en su lugar?
                  </div>
                </>
              )}
              {preferredForgetPassword === FORGET_PASSWORD_METHOD.OTP && (
                <OTPPopup
                  onSubmit={(payload: ButtonClickProps) =>
                    onPressButton(payload)
                  }
                />
              )}
            </>
          )
        }
      />
    </Box>
  );
};

export default ForgotPassword;
