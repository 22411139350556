import { useHistory } from "react-router-dom";

import { useAppDispatch } from "../store";
import {
  getOnboardingSteps,
  stepNavigatorMapping,
} from "../store/slices/auth";
import { API_STATUS } from "../constants/common";

const useNavigationSteps = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const userNavigation = async () => {
    try {
      const { data, status = "" } = await dispatch(
        getOnboardingSteps()
      ).unwrap();

      if (status === API_STATUS.SUCCESS) {
        const page = stepNavigatorMapping[data?.step_no + 1];
        history.push(page);
      }
    } finally {
      //   nothing
    }
  };

  return { userNavigation };
};

export default useNavigationSteps;
