import React, { useEffect, useState } from "react";
import Header from "../home-v2/Header";
import Footer from "../home-v2/Footer";
import NewsBanner from "../../assets/NewsMedia/newsBanner.svg";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import useStyles from "./styles";
import Typography from "@mui/material/Typography";
import NewsCardContainer from "./NewsCardContainer";
import { articles } from "./articles";
const MedsiInTheNewsBlog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down(900));
  const [sortedArticles, setSortedArticles] = useState([]);

  useEffect(() => {
    const sorted = articles.sort((a, b) => {
      const dateA = new Date(a.releaseDate.split(", ")[1]);
      const dateB = new Date(b.releaseDate.split(", ")[1]);
      return dateB - dateA;
    });
    setSortedArticles(sorted);
  }, []);

  return (
    <Box className={classes.container}>
      <Header />
      <Box className={classes.mainContentPost}>
        <Box className={classes.content}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.bannerContainer}
          >
            <Grid item xs={12} md={3}>
              <img
                src={NewsBanner}
                alt="medsi-news"
                className={classes.bannerImage}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={classes.textWrapperContainer}>
                <Typography
                  variant="subtitle2"
                  className={classes.medsiNewsTitle}
                >
                  Noticias de Medsi
                </Typography>
                <Typography className={classes.medsiNewsSubtitle}>
                  Soluciones digitales en el sector salud
                </Typography>
                <Typography className={classes.medsiNewsDescription}>
                  Inmediatez, parcialidades y el médico que tu elijas son
                  algunos de los beneficios que te ofrece Medsi.
                </Typography>
                {!isMdDown && (
                  <Typography
                    variant="subtitle2"
                    className={classes.fotterDescription}
                  >
                    -Julio 26, 2022
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Box>
      </Box>
      {/* <hr className={classes.divider} /> */}
      <Grid container className={classes.newsContainer}>
        {sortedArticles.map((art) => (
          <Grid item xs={12} sm={6} md={6} lg={4} key={art.id}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <NewsCardContainer
                key={art.id}
                blogMedia={art.media}
                blogType={art.type}
                blogPoster={art.poster}
                blogLink={art.links}
                blogTitle={art.title}
                blogReleaseDate={art.releaseDate}
                blogSubtitle={art.subtitle}
                blogDescription={art.description}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
      <Footer />
    </Box>
  );
};

export default MedsiInTheNewsBlog;
