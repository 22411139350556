import axios from "axios";
import { API_STATUS } from "../constants/common";
import { getAccessToken, logout } from "../utils/auth";
import { errorToast } from "../utils/toast";

/**
 * @typedef {{
 *  type: string;
 *  code: string;
 *  status: number;
 *  message: string;
 *  details: any;
 * }} ApiException
 */

const defaultTimeout = 40000;
const noAuthCheckUrls = [
  "v1/login/",
  "v1/register/",
  "v1/request-reset-email/",
  "/v1/password-reset-complete/",
  "v1/email_verify/",
  "/v1/cosigner/application-summary/",
  "/v1/cosigner/curp_registration/",
  "/v1/cosigner/additional_info/",
  "/v1/cosigner/final_info/",
  "/v1/application/save_cosigner_signature/",
  "v1/application/save_cosigner_signature/",
  "v1/application/get_co_signer_agreement",
  "v1/cosigner/phone_number/",
  "v1/qrurl/",
  "/v1/identify_cosigner_unboarding_steps/",
  "v1/user-feedback/",
  "v1/cosigner/get-info/",
  "v2/otp/send/",
  "v2/otp/verify/",
  "v2/otp/resend/",
];

const isNoAuth = (url) => {
  const refRegex = new RegExp(/\/v1\/check_reference_code\/\?code=([^&]*)/g);
  const refRegex2 = new RegExp(/\/v1\/reset-password\/([^&]*)/g);
  return refRegex.test(url) || refRegex2.test(url);
};

const handleRequest = (config) => {
  if (
    noAuthCheckUrls.indexOf(config?.url) < 0 &&
    !isNoAuth(config?.url) &&
    config?.url?.split("?")[0] !== "v1/qrurl/" &&
    config?.url?.split("?")[0] !== "v1/application/get_co_signer_agreement"
  ) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getAccessToken()}`,
      },
    };
  } else {
    return config;
  }
};

/**
 *
 * @param {ApiInterceptorToast} toast
 * @param {import('react-router-dom').RouterProps['history']} history
 * @returns {(err: import('axios').AxiosError<any>) => void}
 */
// eslint-disable-next-line no-unused-vars
const handleErrorResponse = async (error) => {
  const { config, response } = error;
  if (noAuthCheckUrls.indexOf(config?.url) > -1 || isNoAuth(config?.url)) {
    const { status = "", error, meta } = response?.data || {};
    if (status === API_STATUS.ERROR) {
      if (meta?.errorMessage) {
        errorToast(meta?.errorMessage);
      } else {
        errorToast(error?.detail);
      }
    }
  } else {
    if (response.status === 401) {
      const { meta } = response?.data || {};
      if (meta?.errorMessage) {
        errorToast(meta?.errorMessage);
      }
      logout();
    }
    errorToast(error?.detail);
    return;
  }
  return error;
};

/**
 * @param {string} baseURL
 * @param {string} [name]
 */
const createApiInstance = (baseURL, name = "") => {
  const api = axios.create({ baseURL, timeout: defaultTimeout });

  if (!baseURL) {
    throw new Error(
      `${name} baseURL not set during built. Please, set baseURL`
    );
  }

  api.interceptors.request.use(handleRequest);
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      handleErrorResponse(error);
      return error.response;
    }
  );

  return {
    /**
     *
     * @param {ApiInterceptorToast} toast
     * @param {import('react-router-dom').RouterProps['history']} history
     */
    // initializeInterceptors: (toast, history) => {
    //   if (!privateToast) {
    //     api.interceptors.request.use(handleRequest);
    //     api.interceptors.response.use(null, handleErrorResponse(toast, history));
    //     privateToast = toast;
    //   }
    // },
    instance: api,
  };
};

export default createApiInstance;
