import Header from "../../../components/header";
import TwoColumns from "../../../components/twoColumns";
import SideBar from "../../../components/sidebar";
import useStyles from "../ApplicationStatus/styles";

import React from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import {
  selectAllApplication,
  selectLmsBalanceSummary,
  selectLoanDetailStepper,
  selectStepperStatus,
  selectTakeLoanDetail,
  selectTransactionList,
} from "../../../store/slices/users";
import { useAppDispatch } from "../../../store";
import {
  getAllApplication,
  getApplicationStepper,
  getLoanDetails,
  getTransactionList,
} from "../../../store/slices/users/actions";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

import PanelStatement from "../../../components/PanelStatement";
import { getCreditStatement } from "../../../features/installmentLoan/store/installmentLoan";

const StatementAll = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [application_id, setAppId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const css = useStyles();
  const [loanType, setLoanType] = React.useState("active");
  const [statements, setStatements] = React.useState([]);
  const [showSidebar, setShowSideBar] = React.useState(false);
  const urlId = window.location.search.slice(1, window.location.search.length);

  const handleOpen = (id) => {
    setAppId(id);
    setOpen(!open);
  };

  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);
  React.useEffect(() => {
    dispatch(
      getTransactionList({
        cl_id: urlId || LmsBalanceSummary?.cl_id,
        totalShow: 6,
        loanType: loanType,
      })
    );
  }, [urlId, LmsBalanceSummary, loanType]);

  const loadStatement = async () => {
    const { data } = await dispatch(getCreditStatement()).unwrap();
    setStatements(data?.results || []);
    console.log({ data });
  };

  React.useEffect(() => {
    loadStatement();
  }, []);

  const handleActive = () => {
    setLoanType("active");
  };

  const handleClose = () => {
    setLoanType("close");
  };

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header isBorderBottom={false} setShowSideBar={setShowSideBar} />
          </Box>

          <Box
            sx={(theme) => ({
              color: "#6B7779",
              fontSize: "24px",
              fontFamily: "SFUIText-bold",
              marginTop: "19px",
              marginLeft: "44px",
              marginBottom: "33px",
              [theme.breakpoints.down("lg")]: {
                fontSize: "18px",
                fontFamily: "Urbanist-Bold",
                marginLeft: "95px",
                marginTop: "1.5rem",
              },
              [theme.breakpoints.down("tablet")]: { ml: "35px", mb: "1rem" },
            })}
          >
            Estados de Cuenta
          </Box>

          {statements?.length ? (
            statements?.map((statement) => (
              <Box
                sx={(theme) => ({
                  marginLeft: "44px",
                  marginTop: "20px",
                  marginRight: "44px",
                  [theme.breakpoints.down("lg")]: {
                    width: "90%",
                    margin: "1rem auto 0",
                  },
                  [theme.breakpoints.down("laptop")]: {
                    width: "85%",
                  },
                })}
                key={statement.filename}
              >
                <PanelStatement
                  title={statement?.filename || ""}
                  statement={statement}
                />
              </Box>
            ))
          ) : (
            <Box
              sx={(theme) => ({
                height: "100%",
                textAlign: "center",
                fontSize: "20px",
                [theme.breakpoints.down("lg")]: {
                  fontSize: "16px",
                  fontFamily: "Urbanist-Regular",
                },
              })}
            >
              Sin Declaraciones
            </Box>
          )}
        </>
      }
      rightBg
    />
  );
};

export default StatementAll;
