import { FC } from "react";
import { IconProps, accentBlue } from "../types";

const CaretDownIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="21"
      height="12"
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.3906 2.39063C20.6484 2.13281 20.7891 1.80469 20.7891 1.41797C20.7891 0.644531 20.1797 0.0234377 19.4063 0.0234377C19.0195 0.0234377 18.668 0.1875 18.3984 0.457031L10.3828 8.66016L2.39063 0.457031C2.12109 0.1875 1.75781 0.0234375 1.38281 0.0234375C0.609375 0.0234375 1.35413e-07 0.644531 1.26189e-07 1.41797C1.21578e-07 1.80469 0.140625 2.13281 0.398438 2.39063L9.30469 11.5078C9.60938 11.8359 9.98438 11.9883 10.3945 12C10.8047 12 11.1563 11.8359 11.4727 11.5078L20.3906 2.39063Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CaretDownIcon.defaultProps = {
  fill: accentBlue,
};

export default CaretDownIcon;
