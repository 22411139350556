import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import "simple-react-donut-chart/src/style.css";
import { selectUserPromoCode } from "../../../../../../store/slices/users";
import useStyles from "../../../../styles";
import { currencyFormat } from "../../../../../../utils/index";

const CreditDetails = (props) => {
  const {
    decisionStatus,
    creditLimit,
    dueAmount,
    outstandingAmount,
    dueDate,
    requestedAmount,
    requestedTenure,
    selectLoanDetail,
    LmsBalanceSummary,
    loanDetailsStepper,
  } = props;
  const css = useStyles();

  const [interest, setInterest] = React.useState();
  const [vat, setVat] = React.useState();
  const [dueAmt, setDueAmt] = React.useState();
  const [monthlyPrinciple, setMonthlyPrinciple] = React.useState();

  const [totalPayableAmount, setTotalPayableAmount] = React.useState();

  const userPromocode = useSelector(selectUserPromoCode);

  React.useEffect(() => {
    if (requestedTenure) {
      let interestRate = loanDetailsStepper?.pricing?.[requestedTenure];
      if (
        !userPromocode?.promo_code_used &&
        userPromocode?.promo_code_interest
      ) {
        interestRate = userPromocode?.promo_code_interest;
      }

      setMonthlyPrinciple(
        Math.round(
          (requestedAmount || loanDetailsStepper?.req_amt) / requestedTenure
        )
      );
      setInterest(
        ((interestRate / 100) *
          (requestedAmount || loanDetailsStepper?.req_amt)) /
          12
      );

      setVat((loanDetailsStepper?.vat_rate / 100) * interest);
    }
  }, [requestedAmount, requestedTenure]);

  const percent = Math.round(
    (outstandingAmount / (creditLimit + outstandingAmount)) * 100
  );

  useEffect(() => {
    const tenures = loanDetailsStepper?.tenure;
    let interestRate = loanDetailsStepper?.pricing?.[tenures];
    if (!userPromocode?.promo_code_used && userPromocode?.promo_code_interest) {
      interestRate = userPromocode?.promo_code_interest;
    }

    setMonthlyPrinciple(
      Math.round(loanDetailsStepper?.req_amt / loanDetailsStepper?.tenure)
    );

    setInterest(((interestRate / 100) * loanDetailsStepper?.req_amt) / 12);

    setVat((loanDetailsStepper?.vat_rate / 100) * interest);
  }, [loanDetailsStepper]);

  useEffect(() => {
    const dueAmount = monthlyPrinciple + vat + interest;
    setDueAmt(dueAmount);

    let totalAmount = 0.0;
    let promoCodeDiscount = userPromocode?.promo_code_rpy_dst;
    if (dueAmount && loanDetailsStepper) {
      totalAmount = dueAmount * (loanDetailsStepper?.tenure || requestedTenure);
      if (!userPromocode?.promo_code_used && promoCodeDiscount) {
        let discountAmount = (promoCodeDiscount / 100) * totalAmount;
        totalAmount -= discountAmount;
      }
    }
    setTotalPayableAmount(totalAmount);
  }, [vat, interest, monthlyPrinciple]);

  return (
    <>
      <div
        style={{
          background: "#FFFFFF",
          borderRadius: 20,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <Box
          sx={(theme) => ({
            padding: "20px 48px 16px",
            [theme.breakpoints.down("tablet")]: {
              padding: "1rem 1.5rem",
            },
          })}
        >
          <Box
            sx={(theme) => ({
              fontSize: "18px",
              color: "#979797",
              fontFamily: "SFUIText-semibold",
              textAlign: "center",
              lineHeight: "24px",
              [theme.breakpoints.down("lg")]: {
                fontFamily: "Urbanist-SemiBold",
                fontSize: "14px",
                textAlign: "start",
              },
            })}
          >
            Resumen
          </Box>
          <Box
            sx={(theme) => ({
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              gap: "20px",
              fontFamily: "SFUIText-semibold",
              lineHeight: "24px",
              marginTop: "20px",
              [theme.breakpoints.down("lg")]: {
                fontFamily: "Urbanist-Medium",
                fontSize: "14px",
              },
            })}
          >
            <div>
              <div style={{ color: "#9E9E9E" }}>Monto mensual a pagar</div>
              <div style={{ color: "#3552CC", marginTop: 10 }}>
                ${dueAmt ? currencyFormat(dueAmt) : 0.0}
                <span
                  style={{ color: "#3552CC", position: "relative", top: 6 }}
                ></span>
              </div>
            </div>
            <div>
              <div style={{ color: "#9E9E9E" }}>
                {" "}
                Amortización mensual de principal
              </div>
              <div style={{ color: "#000000", marginTop: 10 }}>
                ${monthlyPrinciple ? currencyFormat(monthlyPrinciple) : 0.0}
              </div>
            </div>
            <div>
              <div style={{ color: "#9E9E9E" }}>Intereses mensuales</div>
              <div style={{ color: "#000000", marginTop: 10 }}>
                {/* ${currencyFormat(outstandingAmount)}.00 */}$
                {interest ? interest?.toFixed(2) : 0.0}
              </div>
            </div>

            <div>
              <div style={{ color: "#9E9E9E" }}>IVA mensual</div>
              <div style={{ color: "#000000", marginTop: 10 }}>
                {/* ${currencyFormat(outstandingAmount)}.00 */}$
                {vat ? vat?.toFixed(2) : 0.0}
              </div>
            </div>

            <div>
              <div style={{ color: "#9E9E9E" }}>Importe total a pagar</div>
              <div style={{ color: "#000000", marginTop: 10 }}>
                {/* ${currencyFormat(outstandingAmount)}.00 */}$
                {/* {dueAmt && loanDetailsStepper
                  ? (
                      dueAmt * (loanDetailsStepper?.tenure || requestedTenure)
                    ).toLocaleString()
                  : 0.0} */}
                {currencyFormat(totalPayableAmount)}
              </div>
            </div>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            padding: "15px 20px 15px 40px",
            fontSize: "14px",
            color: "#979797",
            background: "rgba(53, 82, 204, 0.05)",
            [theme.breakpoints.down("lg")]: {
              fontFamily: "Urbanist-Medium",
              background: "transparent",
              textAlign: "center",
              mb: "1rem",
            },
          })}
        >
          CAT Promedio 78% sin IVA, tasa de interés fija anual del 40% sin IVA
        </Box>
      </div>
    </>
  );
};

export default CreditDetails;
