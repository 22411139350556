import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles } from '@mui/styles';
import { customTheme } from '../../features/installmentLoan/theme/index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpChaContainer: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      flexGrow: 1,
      overflow: 'auto',
      background:
        'linear-gradient(180deg, #3552CC 0%, rgba(196, 196, 196, 0) 100%)',
      '&.noMsgs': {
        [theme.breakpoints.up('md')]: {
          marginTop: 54,
        },
      },

      [theme.breakpoints.down('xl')]: {
        position: 'fixed',
        width: '60vw',
        maxWidth: 428,
        height: '100vh',
        left: 0,
        top: 0,
        zIndex: theme.zIndex.modal + 2,
        background: '#3552CC',
      },
    },
    topLogoContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    mainLogoContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      padding: '40px 22px 12px 20px',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        '& img': {
          width: '60%',
        },
      },
    },
    mainLogoTextStyle: {
      color: customTheme.cotton,
      fontSize: '40px !important',
      fontWeight: 'bold !important',
    },
    notificationContainer: {
      padding: '40px 22px',
    },
    middleNavContainer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: '15%',
      [theme.breakpoints.down('md')]: {},
    },
    bottomProfileContainer: {
      minHeight: '14%',
    },
  })
);

export default useStyles;
