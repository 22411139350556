import { forwardRef, ReactNode } from "react";

import MaterialButton, {
  ButtonClassKey,
  ButtonProps as MaterialButtonProps,
} from "@mui/material/Button";

import CircularProgress, {
  CircularProgressClassKey,
} from "@mui/material/CircularProgress";
import { ClassNameMap } from "@mui/styles";
import useStyles from "./styles";

type ButtonClasses = Partial<ClassNameMap<ButtonClassKey>>;

export interface ButtonProps extends MaterialButtonProps {
  isSubmitting?: boolean;
  label: string | ReactNode;
  loaderSize?: number;
  classes?: ButtonClasses;
  circularProgressClasses?: Partial<ClassNameMap<CircularProgressClassKey>>;
  noCaps?: boolean;
}

const ButtonComposed = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    const {
      label,
      variant = "contained",
      loaderSize,
      isSubmitting,
      classes,
      circularProgressClasses,
      noCaps = false,
      disabled = false,
      ...btnProps
    } = props;
    const customClasses = useStyles();

    const btnLabel =
      typeof label === "string"
        ? noCaps
          ? label
          : label.toUpperCase()
        : label;

    return (
      <MaterialButton
        ref={ref}
        variant={variant}
        className={`${customClasses} ${classes}`}
        disabled={isSubmitting || disabled}
        sx={{
          "&.Mui-disabled": {
            cursor: "not-allowed",
            pointerEvents: "all !important",
          },
        }}
        {...btnProps}
      >
        {isSubmitting ? (
          <>
            {btnLabel}&nbsp;
            <CircularProgress
              data-testid="button-circular-progress"
              color="inherit"
              size={loaderSize || 28}
              thickness={4}
              classes={circularProgressClasses}
            />
          </>
        ) : (
          btnLabel
        )}
      </MaterialButton>
    );
  }
);

ButtonComposed.displayName = "ButtonComposed";

export default ButtonComposed;
