const {
  REACT_APP_METTA_API_LOAN_URL,
  REACT_APP_METTA_WEB_MX_URL,
  REACT_APP_METTA_IS_MX_ALLOWED,
  REACT_APP_METTA_WEB_NEW_URL,
  REACT_APP_METTA_PROJECT_LMS_ENDPOINT_URL,
} = process.env;

export const urls = {
  baseUrl: REACT_APP_METTA_API_LOAN_URL,
  webMXUrl: REACT_APP_METTA_WEB_MX_URL,
  isMXSiteAllowed: REACT_APP_METTA_IS_MX_ALLOWED,
  newWebUrl: REACT_APP_METTA_WEB_NEW_URL,
  lmsUrl: REACT_APP_METTA_PROJECT_LMS_ENDPOINT_URL,
  appStoreUrl: "https://apps.apple.com/in/app/medsi-credit/id1620564135",
  playStoreUrl:
    "https://play.google.com/store/apps/details?id=com.medsi&hl=en&gl=US",
  s3Video: "https://metta-assets-template.s3.us-west-2.amazonaws.com/videos",
};

export default urls;
