import { createSlice, PayloadAction } from "@reduxjs/toolkit";


const initialState = {
  sidebarTabValue: "",
};

const slice = createSlice({
  name: "ui",
  initialState: {
    ...initialState,
  } as UiState,
  reducers: {
    setSidebarTabValue: (state, action: PayloadAction<string>) => {
      state.sidebarTabValue = action.payload;
    },
  },
});

export type UiState = {
  sidebarTabValue: string;
};

export const { setSidebarTabValue } = slice.actions

export default slice.reducer;

export const getUiState = (state: any) => state.ui;
