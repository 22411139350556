import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

import { customTheme } from "../../features/installmentLoan/theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      // borderBottom: `1px solid ${customTheme.obsidian}`,
      padding: theme.spacing(1),
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(3.25),
      width: "100%",
      position: "relative",
      "&.hideBorder": {
        borderBottom: "none",
      },
    },
    titleCont:{
      padding: `0px 6%`,
    },
    title: {
      textTransform: "uppercase",
      color: `${customTheme.shark} !important`,
      fontSize: '22px !important',
      lineHeight: "1.2 !important",
      fontFamily: "SFUIText-Bold !important",
    },
    actionCont: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: `0px 6%`,
    },
    actionBtn: {
      padding: 0,
      color: customTheme.accentBlue,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& small": {
        paddingLeft: theme.spacing(1),
        display: "inherit",
        justifyContent: "inherit",
        alignItems: "inherit",
      },
      "&.menu svg": {
        transform: "rotate(90deg)",
      },
      "&:disabled": {
        color: customTheme.dolphin,
      },
    },
    actionComponentTextStyle: {
      textTransform: "none !important" as 'none',
      fontStyle: 'normal !important',
      fontSize: '22px !important',
      fontWeight: 'bold !important',
      lineHeight: "1.2 !important",
      marginRight: '8px !important'
    },
    menu: {
      padding: 0,
      minWidth: 160,
      marginTop: theme.spacing(5),
      "& ul": {
        padding: "0 !important",
      },
    },
    menuItem: {
      color: customTheme.shark,
      borderBottom: `1px solid ${customTheme.stone}`,
      fontSize: 12,
      lineHeight: "14px",
      padding: theme.spacing(2),
      paddingTop: theme.spacing(1.5),
      paddingBottom: theme.spacing(1.5),
    },
  })
);

export default useStyles;
