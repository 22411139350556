import { makeStyles } from '@mui/styles';

const TitleComponentStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.primary.main,
    fontWeight: '700 !important',
    fontSize: '1rem !important',
    marginBottom: '2px !important',
    marginTop: '2px !important',
    paddingLeft: 26,
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Medium !important',
      fontWeight: '500 !important',
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '14px !important' },
  },
  disabled: {
    color: theme.palette.primary.gray + '!important',
  },
}));

export default TitleComponentStyles;
