// collection of endpoints goes here

const ILEndPoint = `v1/lms`;

export const medsiEndPoint = {
  getBalanceSummary: `${ILEndPoint}/balance-summary/`,
  getTxnList: `${ILEndPoint}/get-txn-list`,
  getBillStatement: `${ILEndPoint}/get-bill-statement/:clId`,

  getLatestTxn: `${ILEndPoint}/txn/list?txn_type=All`,
  getTxnDetails: `${ILEndPoint}/txn?txn_id=`,

  getRepaymentAmount: `${ILEndPoint}/repayment?date=`,
  postRepaymentAmount: `${ILEndPoint}/repayment`,
  repaymentDetails: `${ILEndPoint}/repayment-detail?txnId=`,

  creditRequestDetails: `${ILEndPoint}/credit-request-details`,
  // creditRequestDetails: `${ILEndPoint}/cred-req-det2`,
  creditMinMax: `${ILEndPoint}/lms-credit-min-max/`,
  basicDetails: `${ILEndPoint}/application/`,
  applicationSummary: `${ILEndPoint}/application-summary/`,
};

export default medsiEndPoint;
