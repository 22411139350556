import { URBANIST_FONT } from "../../../constants/common";
import { makeStyles } from "@mui/styles";

const SplashValidationStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    background: "#00263A",
  },
  textTitle: {
    marginTop: "31px !important",
    marginBottom: "10px !important",
    color: theme.palette.primary.white,
    fontSize: "20px !important",
    fontFamily: URBANIST_FONT.REGULAR,

    [theme.breakpoints.down(491)]: {
      fontSize: "30px !important",
    },

    [theme.breakpoints.down(371)]: {
      fontSize: "25px !important",
    },

    "@media (min-width: 491px) and (max-height: 800px)": {
      fontSize: "30px !important",
    },
    animation: `$movimiento 1.5s linear infinite`,
  },
  textSubtitle: {
    color: "#0ACC97",
    fontFamily: URBANIST_FONT.REGULAR,
    fontSize: "18px !important",
    [theme.breakpoints.down(491)]: {
      fontSize: "18px !important",
    },
    [theme.breakpoints.down(371)]: {
      fontSize: "15px !important",
    },
    "@media (min-width: 491px) and (max-height: 800px)": {
      fontSize: "18px !important",
    },
    animation: `$movimiento 1.5s linear infinite`,
  },
  img: {
    maxWidth: 265,
    [theme.breakpoints.down(681)]: {
      maxWidth: 450,
    },

    [theme.breakpoints.down(491)]: {
      maxWidth: 350,
    },

    [theme.breakpoints.down(371)]: {
      maxWidth: 270,
    },

    "@media (min-width: 491px) and (max-height: 800px)": {
      maxWidth: 410,
    },

    "@media (min-width: 491px) and (max-height: 600px)": {
      maxWidth: 320,
    },
    animation: `$movimiento 1.5s linear infinite`,
  },
  "@keyframes movimiento": {
    "0%": {
      opacity: "0.2",
    },
    "12.5%": {
      opacity: "0.4",
    },
    "25%": {
      opacity: "0.6",
    },
    "37.5%": {
      opacity: "0.8",
    },
    "50%": {
      opacity: "1",
    },
    "62.5%": {
      opacity: "0.8",
    },
    "75%": {
      opacity: "0.6",
    },
    "87.5%": {
      opacity: "0.4",
    },
    "100%": {
      opacity: "0.2",
    },
  },

  headerLineStyle: {
    width: 574,
    height: 330,
    display: "grid",
    justifySelf: "flex-end",
    animation: `$movimiento 1.5s linear infinite`,
  },
  footerLineStyle: {
    animation: `$movimiento 1.5s linear infinite`,
  },
}));

export default SplashValidationStyles;
