import {
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import TitleComponent from "../TitleComponent";
import MultiSelectStyles from "./MultiSelect.styles";

const MultiSelectComponent = (props) => {
  const {
    labelTitle,
    multiSelectValue,
    handleChangeMultiple,
    proc,
    options,
    disabled,
    errors,
    touched,
    onBlur,
    name,
    stylesInput,
  } = props;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const classes = MultiSelectStyles();
  return (
    <FormControl
      className={
        stylesInput
          ? `${stylesInput} ${classes.formContainer}`
          : classes.formContainer
      }
    >
      <TitleComponent text={labelTitle} disabled={disabled} />
      <Select
        name={name}
        multiple
        value={multiSelectValue}
        onChange={handleChangeMultiple}
        MenuProps={MenuProps}
        renderValue={(selected) => selected.join(", ")}
        size="small"
        disabled={disabled}
        onBlur={onBlur}
      >
        {proc !== null &&
          options?.map((proc) => (
            <MenuItem key={proc} value={proc}>
              <Checkbox checked={multiSelectValue.indexOf(proc) > -1} />
              <ListItemText primary={proc} />
            </MenuItem>
          ))}
      </Select>
      <FormHelperText>{errors && touched && errors}</FormHelperText>
    </FormControl>
  );
};

export default MultiSelectComponent;
