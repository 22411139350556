import { FC, useRef } from "react";
import { useHistory } from "react-router-dom";
import { exportComponentAsPDF } from "react-component-export-image";
import { Typography, Box } from "@mui/material";

import TwoColumns from "../../components/twoColumns";
import SideBar from "../../components/sidebar";
import SectionHeader from "../../components/sectionHeader";
import DownloadFile from "../../components/icons/DownloadPdfFile";
import LoanSummary from "../common/LoanSummary";
import Button from "../../features/installmentLoan/components/Button";
import useGetStore from "../../store/getStore";
import Header from "../../components/header";

import useStyles from "./styles";
import ScheduleTable from "./ScheduleTable";

const ILPaymentSchedule: FC = () => {
  const { installmentLoan } = useGetStore();
  const css = useStyles();
  const history = useHistory();
  const componentRef = useRef<HTMLDivElement | null>(null);

  const { paymentScheduleItems, loading } = installmentLoan;
  
  const onBack = () => {
    history.goBack();
  };

  if (loading) {
    return <div>Loader</div>;
  }

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftRowPadless={true}
      leftContentComp={<SideBar />}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header />
          </Box>
          <Box mt={-2} ml={-4}>
            <SectionHeader
              title="CALENDARIO DE PAGO"
              actionMode="custom"
              backClick={onBack}
              customActionElement={
                <Button
                  variant="text"
                  className={css.button}
                  onClick={() =>
                    exportComponentAsPDF(componentRef, {
                      pdfOptions: {
                        w: 150,
                        h: 50,
                        x: 0,
                        y: 0,
                      },
                    })
                  }
                  label={
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Box className={css.buttonIconContainer}>
                        <DownloadFile color="white" />
                      </Box>
                      <Typography className={css.buttonTextStyle}>
                        Descargar PDF
                      </Typography>
                    </Box>
                  }
                />
              }
            />
          </Box>
          <Box px={4}>
            <Box className={css.resumeTitle} mt={3}>
              resumen de tu crédito
            </Box>
            <Box mt={4}>
              <LoanSummary />
            </Box>
            <div ref={componentRef}>
              <Box mt={6} ml={1}>
                <ScheduleTable paymentScheduleItems={paymentScheduleItems} />
              </Box>
            </div>
          </Box>
        </>
      }
      rightBg
    />
  );
};

export default ILPaymentSchedule;
