import { Box, Grid, List, ListItem, Typography } from "@mui/material";

import useStyle from "./styles";
import Beneficios from "../../../assets/Icons/beneficiosParaTiIcon.svg";
import areasTerap from "../../../assets/Icons/areasTerapeuticasIcon.svg";
import soluciones from "../../../assets/Icons/SolucionesFinancierasIcon.svg";
import lineaCreditoMedsi from "../../../assets/Logos/lineaFamiliarLogo.svg";
import logoTandaAhorro from "../../../assets/Logos/TandaAhorroLogo.svg";

export const HomePage = () => {
  const css = useStyle();

  const WrapperInformationContainer = (props) => {
    const { children } = props;
    return (
      <Box
        sx={{
          background: `linear-gradient(180deg, rgba(136, 144, 173, 0.5) 0%, rgba(255, 255, 255, 0) 100%)`,
          borderRadius: 5,
          minHeight: 480,
          width: "90%",
          boxShadow: `0px 4px 4px 0px rgba(0, 0, 0, 0.25)`,
          marginTop: 10,
        }}
      >
        {children}
      </Box>
    );
  };

  return (
    <Box className={css.container} mt={4}>
      <Box className={css.msg}>
        Por fin en México una solución accesible y flexible para tu bienestar y
        salud
      </Box>
      <Box className={css.title}>
        Utiliza la Linea Familiar Medsi cuantas veces sea necesario para pagar
        tus tratamientos
      </Box>
      {/* Beneficios para ti */}
      <WrapperInformationContainer>
        {/* WrapperContainer */}
        <Box sx={{ marginLeft: { xs: 5 } }}>
          <Box
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              display: "flex",
              marginTop: 4,
              marginLeft: { xs: 1 },
              flexDirection: { xs: "column", lg: "row" },
            }}
          >
            <img
              src={Beneficios}
              alt="beneficios"
              style={{ width: 116, height: 116 }}
            />
            <Typography
              sx={{
                fontFamily: "Urbanist-Semibold",
                fontSize: 24,
                // marginLeft: 2,
                marginRight: { xs: 25, md: 75 },
                mt: { xs: 2 },
              }}
            >
              {" "}
              Beneficios para ti{" "}
            </Typography>
          </Box>
          {/* <Grid container sx={{ marginLeft: 30, marginTop: "-40px" }}> */}
          <Grid container>
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ maxWidth: 250 }}>
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": {
                      display: "list-item",
                      width: 350,
                    },
                  }}
                >
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Línea de crédito revolvente para proteger a toda tu
                      familia
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Utiliza tu Línea Familiar para el tratamiento de cualquier
                      miembro de tu familia
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Servicios de Asesor Médico para orientación y
                      asesoramiento
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Atención personalizada desde el primer síntoma{" "}
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={4} sx={{ marginLeft: "-140px" }}> */}
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ maxWidth: 300 }}>
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": {
                      display: "list-item",
                      width: 350,
                    },
                  }}
                >
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Te ayudamos a encontrar al médico ideal cerca de ti
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Recibe respuesta en tiempo real
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Completa la aplicación en línea en menos de 3 minutos y
                      comienza a usar tu línea de inmediato
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Con tu Línea Familiar, programa uno o múltiples pagos a
                      doctores, clínicas y hospitales
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
            {/* <Grid item xs={12} md={4} sx={{ marginLeft: "-100px" }}> */}
            <Grid item xs={12} md={6} lg={4}>
              <Box sx={{ maxWidth: 250 }}>
                <List
                  sx={{
                    listStyleType: "disc",
                    pl: 2,
                    "& .MuiListItem-root": {
                      display: "list-item",
                      width: 350,
                    },
                  }}
                >
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Atiéndete con el médico de tu preferencia{" "}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      No estás limitado a una red cerrada de doctores{" "}
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      Elige pagos quincenales o mensuales, y paga en hasta 12
                      meses
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                    >
                      No necesitas una tarjeta de crédito o comprobantes de
                      nómina
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography
                      sx={{
                        fontFamily: "Urbanist-Medium",
                        fontSize: 14,
                        marginBottom: 10,
                      }}
                    >
                      Conoce el precio total de tu tratamiento desde el inicio
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </WrapperInformationContainer>

      {/* Areas terapeuticas */}
      <WrapperInformationContainer>
        <Box
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            display: "flex",
            marginTop: 4,
            marginLeft: { xs: 1 },
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <img
            src={areasTerap}
            alt="beneficios"
            style={{ width: 116, height: 116 }}
          />
          <Typography
            sx={{
              fontFamily: "Urbanist-Semibold",
              fontSize: { xs: 18, md: 24 },
              marginLeft: 2,
            }}
          >
            Áreas terapéuticas y especialidades médicas
          </Typography>
        </Box>
        <Grid container sx={{ paddingLeft: { xs: 4 } }}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ maxWidth: 250 }}>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Maternidad y obstetricia{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Ortopedia
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Odontología, funcional y cosmética{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Ortodoncia, Endodoncia, Protesis, etc.
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Oftalmología
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ maxWidth: 300 }}>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Procedimientos cosméticos y estéticos{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Quirúrgicos y no quirúrgicos
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Dermatología
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Análisis de laboratorio y pruebas de diagnóstico
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Imagenología, radiología, ultrasonido, etc.
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            <Box sx={{ maxWidth: 250 }}>
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Otorrinolaringología{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Medicina deportiva{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Diabetología
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Nefrología, endocrinología, etc.{" "}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </WrapperInformationContainer>

      {/* Soluciones Financieras  */}
      <WrapperInformationContainer>
        <Box
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            display: "flex",
            marginTop: 4,
            marginLeft: { xs: 1 },
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          <img
            src={soluciones}
            alt="beneficios"
            style={{ width: 116, height: 116 }}
          />
          <Typography
            sx={{
              fontFamily: "Urbanist-Semibold",
              fontSize: 24,
              marginLeft: 2,
            }}
          >
            Soluciones financieras
          </Typography>
        </Box>
        <Grid container sx={{marginLeft:3, marginBottom:3}}>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: 400 }}>
              <img src={lineaCreditoMedsi} alt="lineaCredito" />
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Línea de crédito revolvente por hasta $80 mil con aprobación
                    en tiempo real{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Utiliza tu Línea Familiar para el tratamiento de cualquier
                    miembro de tu familia
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Dale a tu familia la protección de una línea asegurada en
                    caso de imprevistos{" "}
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Financiamiento complementario para cuotas de deducibles y
                    co-aseguros para pacientes asegurados{" "}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ maxWidth: 300, mt:{ xs:2} }}>
              <img src={logoTandaAhorro} alt="lineaCredito" />
              <List
                sx={{
                  listStyleType: "disc",
                  pl: 2,
                  "& .MuiListItem-root": {
                    display: "list-item",
                  },
                }}
              >
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Programas de ahorro para obtener un crédito, a través de
                    esquema de “Tandas”
                  </Typography>
                </ListItem>
                <ListItem>
                  <Typography
                    sx={{ fontFamily: "Urbanist-Medium", fontSize: 14 }}
                  >
                    Tú decides cuánto puedes guardar quincenalmente y cuando has
                    ahorrado lo suficiente, nosotros te prestamos{" "}
                  </Typography>
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </WrapperInformationContainer>
      {/* <Box className={css.cardListView}>
        <Cards />
      </Box> */}
    </Box>
  );
};

export default HomePage;
