import { SVGProps } from 'react';
import { customTheme } from '../../features/installmentLoan/theme';

export interface IconProps extends SVGProps<SVGSVGElement> {
  defaultColor?: boolean;
}

export const accentBlue = customTheme.accentBlue;
export const defaultIconColor = customTheme.dolphin;
export const alertRed = customTheme.ferrari;
export const pine = customTheme.pine;
export const oyster = customTheme.oyster;
export const obsidian = customTheme.obsidian;
export const opal = customTheme.opal;
export const pineapple = customTheme.pineapple;
export const water = customTheme.water;
export const peacock = customTheme.peacock;
export const cerulean = customTheme.cerulean;
export const brandGreen = customTheme.brandGreen;
export const navy = customTheme.navy;
export const salmon = customTheme.salmon;
export const mango = customTheme.mango;
export const charcoal = customTheme.charcoal;
export const cotton = customTheme.cotton;
export const dirtyAqua = customTheme.dirtyAqua;
