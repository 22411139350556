export default {
  productTitle: {
    instantCredit: 'TRANSACCIONES RECIENTES',
    header: 'Resumen de tu crédito',
  },
  userSummary: {
    disbursement: 'Crédito otorgado',
    outstandingBalance: 'Saldo pendiente',
    payment: {
      pendingPayment: 'Pago pendiente',
      payOn: 'A pagar al {{date}}',
      paymentOfTerm: 'Pago {{term}} de {{totalTerm}}',
    },
    DESC: {
      INSTALLMENT: 'Pagar',
      LATE_FEE: 'Pagos atrasados',
    },
    label: {
      itemDisbursement: 'Crédíto otorgado',
      itemNextPayment: 'Próximo pago',
      itemLateFee: 'Pagos atrasados',
      itemAddedToPayment: 'Agregado al pago',
    },
    TIMELINE_DETAILS: {
      DETAILS_STATUS: {
        LABEL: {
          SUCCESS: 'Pagado',
          COMPLETED: 'Completado',
          PENDING: 'Vencido',
          LATE: 'Cuota aplicada',
          DUE: 'Fecha de pago',
          NEXT_PAYMENT: 'Próximo pago',
          LATE_FEE_APPLIED: 'Cuota aplicada',
        },
      },
    },
  },
  latestTransactions: 'Últimas transacciones',
  emptyTransactions: 'Aún no tienes transacciones',
  tooltip: {
    contract: 'Detalles del contrato',
    accountStatement: 'Estados de cuenta',
    payment: 'Pagar crédito',
    latePaymentFee: 'Cuotas por pago atrasado',
    increaseLimit: 'Aumentar línea de crédito',
  },
  errorTitle: 'Hubo un error',
  error: 'Algo salió mal',
  continue: 'Continuar',
  transaction: {
    disbursement: {
      title: 'Depósito *{{lpId}}',
      desc: 'Transferencia',
    },
    disbursementReverse: {
      title: 'Reversión de depósito  *{{lpId}}',
      desc: 'Transferencia',
    },
    manualRepayment: {
      title: 'Pago de crédito',
      desc: 'Pago manual',
    },
    automaticRepayment: {
      title: 'Pago de crédito',
      desc: 'Pago automático',
    },
    repaymentReversal: {
      title: 'Reversión de pago',
      desc: 'Transferencia',
    },
    payment: 'Pagado',
    allTransaction: 'Ver todo',
    pending: 'Pendiente',
  },
};
