import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg_mb from "../../assets/NewsMedia/newsBanner_mb.svg";
import bg_tb from "../../assets/NewsMedia/newsBanner_tb.svg";
import { customColor } from "../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  bannerContainer: {
    [theme.breakpoints.down(1230)]: {
      marginLeft: "140px !important",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0 !important",
    },
    [theme.breakpoints.down(1400)]: {
      marginLeft: 200,
      width: "100% !important",
    },
    [theme.breakpoints.up("md")]: {
      width: "1500px !important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "1400px !important",
    },
    [theme.breakpoints.up("xl")]: {
      width: "1350px !important",
    },
  },
  mainContentPost: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: 519,
    width: "100%",
    [theme.breakpoints.down(900)]: {
      height: 280,
      width: "100%",
    },
  },
  newsContainer: {
    heigth: 400,
    marginBottom: 100,
    [theme.breakpoints.down(900)]: {
      marginTop: 120,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    justifyContent: "center",
    backgroundImage: `url(${bg_tb})`,
    width: "100%",
    height: 519,
    [theme.breakpoints.down(500)]: {
      marginTop:50,
      height: 350,
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      background: `url(${bg_mb})`,
    },
    [theme.breakpoints.between(500,900)]: {
      height: 280,
      width: "100%",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
  },
  bannerImage: {
    height: 350,
    width: 318,
    marginLeft: -50,
    [theme.breakpoints.down(500)]: {
      height: 190,
      width: 158,
      marginTop: 150,
      marginLeft: 20,
    },
    [theme.breakpoints.between(500,769)]: {
      height: 200,
      width: 168,
      marginTop: 140,
      marginLeft: 30,
    },
    [theme.breakpoints.between(769,900)]: {
      height: 230,
      width: 198,
      marginTop: 160,
      marginLeft: 70,
    },
    [theme.breakpoints.between(900,1000)]: {
      marginLeft: 270,
      height: 300,
      width: 268,
    },
    [theme.breakpoints.between(1000,1200)]: {
      marginLeft: 180,
      height: 300,
      width: 268,
    },
    [theme.breakpoints.between(1200, 1320)]: {
      height: 300,
      width: 268,
      marginLeft: 20,
    },
  },
  textWrapperContainer: {
    [theme.breakpoints.down(500)]: {
      marginLeft: 25
    },
    [theme.breakpoints.between(500,769)]: {
      marginLeft: 30,
    },
    [theme.breakpoints.between(769,900)]: {
      marginLeft: 70
    },
    
    [theme.breakpoints.between(900,1000)]: {
      marginLeft: 200
    },
    [theme.breakpoints.between(1000,1200)]: {
      marginLeft: "120px !important",
    },
    [theme.breakpoints.up(1400)]: {
      marginLeft: "-30px !important",
    },
  },
  medsiNewsTitle: {
    color: customColor.obsidian,
    fontSize: "24px !important",
    fontFamily: "SFUIText-Semibold !important",
    marginBottom: "20px !important",
    marginTop: "65px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: 32,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: "0px !important",
      fontSize: "18px !important",
    },
    [theme.breakpoints.up(1400)]: {
      marginTop: "70px !important",
     
    },
    
  },
  medsiNewsSubtitle: {
    color: customColor.obsidian,
    fontSize: "22px !important",
    fontFamily: "SFUIText-Bold !important",
    marginTop: "30px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: 32,
    },
    [theme.breakpoints.down(900)]: {
      marginTop: "0px !important",
      fontSize: "18px !important",
    },
  },
  medsiNewsDescription: {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "18px !important",
    fontFamily: "SFUIText-Semibold !important",
    paddingRight: 60,
    [theme.breakpoints.down(900)]: {
      fontSize: "15px !important",
    },
  },

  fotterDescription: {
    float: "right",
    marginRight: "70px !important",
    marginTop: "20px !important",
    fontSize: "12px !important",
    color: "rgba(0, 0, 0, 0.5)",
  },
  divider: {
    width: 1200,
    color: "rgba(0, 0, 0, 0.3) ",
    // marginTop: "-30px !important",
    [theme.breakpoints.down(900)]: {
      width: "80%",
    },
  },
}));

export default useStyles;
