import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customColor } from "../../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  bottomImageContainer: {
    // position: "relative",
     marginTop: -5,
    padding: "138px 0",
    display: "flex",
    width: "100%",
    flexDirection: "row-reverse",
    alignItems: "center",
    backgroundImage:
      "linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%)",
    "& img": {
      // width: "99vw",
      width: "50%",
    },
    "& .content": {
      // position: "absolute",
      top: 0,
      padding: "0 6%",
      display: "flex",
      flexDirection: "column",
      width: "50%",
      height: "90%",
      justifyContent: "center",
      "& > .title": {
        fontSize: 32,
        fontWeight: "700",
        fontFamily: "Nexa-Bold",
        lineHeight: 1.2,
        // color: customColor.accentBlue,
        color: customColor.black,
        "& > span": {
          color: customColor.accentBlueLight,
        },
      },
      "&> .description": {
        marginTop: theme.spacing(2),
        color: "rgba(0, 0, 0, 1)",
        fontSize: 20,
        [theme.breakpoints.down(601)]: {
          fontSize: 16,
        },
      },
      "& > .btnContainer": {
        marginTop: theme.spacing(4),
        width: "74%",
        justifyContent: "flex-end",
        display: "flex",
      },
    },
  },
  registerButton: {
    backgroundImage: "linear-gradient(180deg, #0ACC97 0%, #1B63DB 100%)",
    borderRadius: 60,
    padding: `${theme.spacing(1)} ${theme.spacing(4)}`,
  },
}));

export default useStyles;
