export const COLOR_CONSTANTS = {
  COLOR_WHITE: "#ffffff",
  COLOR_BLACK: "#000000",
  MAIN_BLUE: "#3552CC",
  SUCCESS: "#22bb33",
  GRAY_90: "#7e8083",
  DARK_BLUE: "#304777",
  BUTTON_UNSELECTED_GRADIENT: "linear-gradient(90deg,#ABCDE4,#4893CC)",
  LIGHT_BLUE: "#46acc4",
  MAASTRICHT_BLUE: "#002539",
  TEXT_GRAY:"#7B7E7E"
};

export const APPLICATION_COLOR_CONSTANTS = {
  APPROVED: "#128807",
  PAYMENT_COMPLETED: "#128807",
  DISBURSED: "#128807",
  SUBMITTED: "#128807",
  PENDING: "#CD9C00",
  COSIGNER_CONSENT: "#CD9C00",
  LEGAL_REVIEW: "#CD9C00",
  UNDER_REVIEW: "#CD9C00",
  REJECTED: "#E92736",
  Deleted: "#E92736",
};
