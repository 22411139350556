import { Box, Grid, Typography } from "@mui/material";

import FooterLogo from "../../../assets/Logos/gradient-logo.svg";
import list1 from "../../../assets/aboutUs/list1-v2.svg";
import list2 from "../../../assets/aboutUs/list2-v2.svg";
import list3 from "../../../assets/aboutUs/list3-v2.svg";

import useStyle from "./styles";

const list = [
  {
    title: "NUESTROS CLIENTES PRIMERO",
    desc: "Nuestros clientes son nuestra principal prioridad.",
    imgSrc: list1,
  },
  {
    title: "SERVICIOS DE SALUD ACCESIBLES",
    desc: "Ofrecer una alternativa flexible para el cuidado de la salud de todos.",
    imgSrc: list2,
  },
  {
    title: "INNOVACIÓN EN SOLUCIONES",
    desc: "Contribuir a superar los retos y obstáculos estructurales en materia de salud utilizando la tecnología.",
    imgSrc: list3,
  },
];

export const HomePage = () => {
  const css = useStyle();

  return (
    <Box className={css.container}>
      <Box mt={10} textAlign="center">
        <img src={FooterLogo} alt="footerIcn" />
        <Box className={css.heading} mt={2}>
          Nuestra Misión
        </Box>
      </Box>
      <Box className={css.content}>
        <Typography
          sx={{
            fontFamily: "Urbanist-Medium",
            fontSize: 20,
            marginBottom: 2,
          }}
        >
          Medsi está comprometido con hacer accesibles los servicios de salud a
          toda la población. Para ello buscamos conectar a los pacientes y
          médicos de una manera eficiente y transparente.
        </Typography>
        <Typography
          sx={{
            fontFamily: "Urbanist-Medium",
            fontSize: 20,
            marginBottom: 2,
          }}
        >
          Brindamos soluciones de financiamiento en línea a los consumidores
          para servicios médicos y procedimientos estéticos.
        </Typography>

        <Typography
          sx={{
            fontFamily: "Urbanist-Medium",
            fontSize: 20,
          }}
        >
          Ofrecemos una línea de crédito familiar que permite mantener protegida
          la salud de tu familia, pues permite diferir el costo de sus
          procedimientos médicos y estéticos a través del tiempo en pequeños
          pagos quincenales o mensuales.
        </Typography>
      </Box>
      <Box className={css.bottomContent}>
        <Box className={css.heading}>Nuestros Valores</Box>
        <Box className={css.text} mt={5}>
          <Typography
            align="center"
            sx={{
              fontFamily: "Urbanist-Medium",
              fontSize: 20,
            }}
          >
            Centramos nuestro servicio en los siguientes valores
          </Typography>
        </Box>
      </Box>
      <Box className={css.listContainer}>
        <Grid container className="grid" spacing={20}>
          {list?.map((item) => {
            return (
              <Grid item md={4} xs={12} className="gridItem">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  className="container"
                >
                  <Box className="title">{item.title}</Box>
                  <Box className={css.imageContainer} mt={2}>
                    <img src={item.imgSrc} width={"78"} height={"82"} alt="title" />
                  </Box>
                  <Box className="title desc" mt={2}>
                    {item.desc}
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default HomePage;
