import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Box } from "@mui/material";
import webBlog from "../../../assets/NewsMedia/socialIcons/web-blog.svg";
import facebook from "../../../assets/NewsMedia/socialIcons/facebook.svg";
import twitter from "../../../assets/NewsMedia/socialIcons/twitter.svg";
import newsCardContainerStyles from "./NewsCardContainerStyles";
const NewsCardContainer = (props) => {
  const {
    blogTitle,
    blogMedia,
    blogPoster,
    blogType,
    blogLink,
    blogReleaseDate,
    blogSubtitle,
    blogDescription,
  } = props;
  const classes = newsCardContainerStyles();

  const ICONS_VARIANTS = {
    web: webBlog,
    facebook: facebook,
    twitter: twitter,
  };
  return (
    <Card sx={{ width: 350, marginTop: 10 }}>
      <CardActionArea>
        {blogType === "video" ? (
          <CardMedia
            component="video"
            height="200"
            controls
            src={blogMedia}
            poster={blogPoster}
          />
        ) : (
          <CardMedia
            component="img"
            alt="green iguana"
            height="200"
            image={blogMedia}
          />
        )}

        <CardContent sx={{ height: 250 }} className={classes.cardContent}>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardNewTitle}
          >
            {blogTitle}
            <span className={classes.cardNewTitleSpan}>
              {" "}
              - {blogReleaseDate}
            </span>
          </Typography>
          <Typography
            gutterBottom
            component="div"
            className={classes.cardNewSubtitle}
          >
            {blogSubtitle}
          </Typography>
          <Typography
            className={classes.cardNewBlogDescription}
            color="text.secondary"
          >
            {blogDescription}
          </Typography>
          {blogLink && blogLink.length > 0 && (
            <Box flexDirection="column" style={{ marginBottom: 30 }}>
              <Typography className={classes.articleHeader}>
                Articulos
              </Typography>
              <Box>
                {blogLink.map((link, index) => (
                  <a
                    key={link.url}
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={ICONS_VARIANTS[link.type]}
                      alt="blog-icn"
                      style={{
                        height: 20,
                        width: 20,
                        marginTop: 15,
                        marginLeft: index !== 0 ? 10 : 0,
                      }}
                    />
                  </a>
                ))}
              </Box>
            </Box>

            // <CardActions >
            // <Typography variant="subtitle2">
            //   Articulos
            // </Typography>
            //   <Button size="small" onClick={() => handleClickArticle(blogLink)}>
            //     ir al articulo
            //   </Button>
            // </CardActions>
          )}
        </CardContent>
        {/* {blogLink && (
          <CardActions>
            <Button size="small" onClick={() => handleClickArticle(blogLink)}>
              ir al articulo
            </Button>
          </CardActions>
        )} */}
      </CardActionArea>
    </Card>
  );
};

export default NewsCardContainer;
