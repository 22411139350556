import installmentLoan from '../../features/installmentLoan/translations';
import common from './common';
import creditRequest from './screens/creditRequest';
import home from './screens/home';
import homeV2 from './screens/homeV2';


export const instantLineCreditTS = {
  hello: 'Hola mundo',
  back: 'Atrás',
  greeting: {
    night: 'Buenas noches',
    morning: 'Buenos días',
    afternoon: 'Buenas tardes',
  },
  installmentLoan,
  common,
  creditRequest,
  home,
  homeV2
};

export default {
  translation: {
    instantLineCreditTS,
  },
};
