import { Box } from "@mui/material";

import FooterLogo from "../../../assets/Logos/FooterLogo-v2.svg";

export const Footer = () => {
  return (
    <Box>
      <Box mt={2}>
        <img src={FooterLogo} />
      </Box>

      <Box mt={2}>Munbrunn S.A. de C.V.</Box>
      <Box mt={4} width="50%">
        Emerson 316, Oficina 201, Piso 2, Colonia Polanco V Sección, Alcaldía
        Miguel Hidalgo, CDMX, México C.P. 11570
      </Box>
    </Box>
  );
};

export default Footer;
