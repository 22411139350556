import ButtonComponent from "../../sharedComponents/ButtonComponent";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import Button from "../../../features/installmentLoan/components/Button";
import CheckIcon from "@mui/icons-material/Check";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CosignerOTPStyles from "./CosignerOTP.styles";
import AddCircle from "@mui/icons-material/AddCircle";
import { useAppDispatch } from "../../../store";
import {
  resendOTP,
  sendOTP,
  verifyOTP,
} from "../../../store/slices/auth/actions";
import {
  API_STATUS,
  FIELD_CHANNEL,
  REQUESTED_SOURCE,
} from "../../../constants/common";
import { resetUserRegisterInfo } from "../../../store/slices/users";
import Theme from "../../../theme";
import { successToast } from "../../../utils/toast";

const TIMEOUT_MINUTES = 2;
const TIMEOUT_SECONDS = 0;

const MAX_LENGTH = 6;

const CosignerOTP = (props) => {
  const {
    setLoader,
    userEmail,
    handleClose,
    onBackdropClick,
    phoneNumber = "",
    otp_res,
    setOpenTnc,
    email,
    semilla: registerSemilla,
    setValidPassword,
  } = props;
  const dispatch = useAppDispatch();
  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [otpId, setOtpId] = useState(otp_res?.data?.id || "");

  const [error, setError] = useState(otp_res?.message || "");
  const [verifyError, setVerifyError] = useState("");

  const classes = CosignerOTPStyles();

  const [semilla, setSemilla] = useState(registerSemilla);
  const [minutes, setMinutes] = useState(TIMEOUT_MINUTES);
  const [seconds, setSeconds] = useState(TIMEOUT_SECONDS);

  const sendCosignerOTP = async () => {
    const { data, status, message } = await dispatch(
      sendOTP({
        req_source: REQUESTED_SOURCE.COSIGNER_CONSENT,
        channel: FIELD_CHANNEL.SMS,
        platform: 1,
        send_to: phoneNumber?.replace("+", "") || "",
      })
    ).unwrap();

    if (status === API_STATUS.SUCCESS) {
      setOtpId(data?.id || "");
      setSemilla(data?.semilla || "");
    }

    if (status === API_STATUS.ERROR) {
      setError(message);
    }
  };

  useEffect(() => {
    sendCosignerOTP();
  }, []);

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setIsResendDisabled(false);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const [otp, setOtp] = useState("");

  const onOtpChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setOtp(e.target.value);
    }
    if (verifyError) {
      setVerifyError("");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const {
        payload: { status, data, message, error },
      } = await dispatch(
        verifyOTP({
          id: otpId,
          otp_value: otp,
          sent_to: phoneNumber,
          req_source: REQUESTED_SOURCE.COSIGNER_CONSENT,
          semilla,
        })
      );

      if (status === API_STATUS.SUCCESS) {
        handleClose();
        setValidPassword(true);
        successToast("OTP verificado con éxito");
      }
      if (status === API_STATUS.ERROR && error?.twilio_error) {
        setVerifyError(message);
        setValidPassword(false);
      }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleResendOtp = async () => {
    setError("");
    setVerifyError("");

    const {
      payload: { data, meta, status, message },
    } = await dispatch(
      resendOTP({
        req_source: REQUESTED_SOURCE.COSIGNER_CONSENT,
        channel: FIELD_CHANNEL.SMS,
        platform: 1,
        send_to: phoneNumber,
        id: otpId,
        semilla,
      })
    );

    setMinutes(TIMEOUT_MINUTES);
    setSeconds(TIMEOUT_SECONDS);
    setIsResendDisabled(true);

    if (status === API_STATUS.SUCCESS) {
      setSemilla(data?.semilla || "");
      setOtpId(data?.id);
    }
    if (status === API_STATUS.ERROR) {
      setError(message);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <AddCircle
        style={{
          color: "#3552CC",
          transform: "rotate(45deg)",
          position: "absolute",
          right: 20,
          top: 20,
          zIndex: 20,
          cursor: "pointer",
        }}
        onClick={handleClose}
      />
      <Grid item xs={12} className={classes.modalTitleContainer}>
        <Typography
          variant="subtitle2"
          align="center"
          className={classes.modalTitle}
        >
          Le hemos enviado un código de verificación en Whatsapp a su número de
          teléfono registrado +{phoneNumber}
        </Typography>
      </Grid>

      <Grid item xs={12} mt={6}>
        <TextField
          // inputRef={textRef}
          className="myinput"
          id="otp"
          name="otp"
          size="normal"
          autoComplete="off"
          value={otp}
          onChange={onOtpChange}
          inputProps={{ maxLength: MAX_LENGTH }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {otp.length === MAX_LENGTH && (
                  <div className={classes.checkIconWrapper}>
                    <CheckIcon style={{ fontSize: 16, color: "#fff" }} />
                  </div>
                )}
              </InputAdornment>
            ),
          }}
          error={!!error || !!verifyError}
          helperText={error || verifyError}
        />
      </Grid>

      {(minutes !== 0 || seconds !== 0) && !error ? (
        <Grid
          item
          xs={12}
          className={classes.modalTitleContainer}
          mt={1}
          display="flex"
          justifyContent="center"
        >
          <Typography
            variant="subtitle2"
            align="center"
            className={classes.timerText}
          >
            El código de verificación caduca en
            {` 0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`} segundos
          </Typography>
        </Grid>
      ) : (
        <></>
      )}

      <Grid item xs={12} className={classes.buttonContainer} mt={7}>
        <Button
          label="Reenviar codigo"
          noCaps
          variant="text"
          className="resendCodeStyle"
          style={{
            background:
              isResendDisabled || !!error
                ? Theme.palette.primary.gray
                : Theme.palette.primary.main,
          }}
          onClick={handleResendOtp}
          disabled={isResendDisabled || !!error}
        />
        <Button
          label="Verificar"
          noCaps
          variant="text"
          className="VerifyButton"
          style={{
            background:
              otp.length === MAX_LENGTH && !error
                ? Theme.palette.primary.main
                : Theme.palette.primary.gray,
          }}
          disabled={otp.length < MAX_LENGTH}
          onClick={handleVerifyOtp || !!error}
        />
      </Grid>
    </Grid>
  );
};

export default CosignerOTP;
