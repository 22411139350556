/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 *
 * Panel
 *
 */

import { ChevronRight } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsApplicationSummaryLoading } from "../../store/slices/users";

const Panel = ({
  title,
  body,
  open,
  status,
  detailStatus,
  firstThreeStepperStatus,
  openPanel,
  onAccordionClick,
}) => {
  const isApplicationSummaryLoading = useSelector(
    selectIsApplicationSummaryLoading
  );

  const [panelOpen, setPanelOpen] = useState(openPanel || false);

  const [openVal, setOpenVal] = useState(open || panelOpen);

  const handlePanel = () => {
    setPanelOpen(!panelOpen);
    // if (openVal) {
    // }
    setOpenVal(!openVal);
    if (onAccordionClick) {
      onAccordionClick(title);
    }
  };

  useEffect(() => {
    if (isApplicationSummaryLoading) {
      setPanelOpen(false);
      setOpenVal(false);
    }
  }, [isApplicationSummaryLoading]);

  useEffect(() => {
    setOpenVal(open);
  }, [open]);

  return (
    <div style={{ marginBottom: 10 }}>
      <div
        aria-controls="panel2a-content"
        id="panel2a-header"
        onClick={() => {
          title === "Firmar el Contrato" && !firstThreeStepperStatus ? (
            <></>
          ) : (
            handlePanel()
          );
        }}
      >
        <Box
          sx={(theme) => ({
            background: "rgba(53, 82, 204, 0.05)",
            padding: `17px 14px`,
            marginBottom: "8px",
            cursor: "pointer",
            boxShadow: "0px 4px 4px rgba(53, 82, 204, 0.25)",
            textAlign: "center",
            display: "grid",
            gridTemplateColumns: "40px 1fr 50px",
            gap: "1rem",
            alignItems: "center",
            // justifyContent: 'space-between',
            [theme.breakpoints.down("lg")]: {
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px",
            },
          })}
        >
          <div>
            {status ? (
              <CheckCircleIcon
                style={{
                  // marginRight: 37,
                  marginTop: 4,
                  height: 20,
                  width: 20,
                  color:
                    title === "Firmar el Contrato" && !firstThreeStepperStatus
                      ? "#979797"
                      : "#3552CC",
                  // color: "#979797",
                }}
              />
            ) : (
              <HelpOutlineIcon
                style={{
                  // marginRight: 37,
                  marginTop: 4,
                  height: 20,
                  width: 20,
                  color:
                    title === "Firmar el Contrato" && !firstThreeStepperStatus
                      ? "#979797"
                      : "#F8C83C",
                  // color: "#979797",
                }}
              />
            )}
          </div>

          {/* <span
            className={`transition-transform flex px-4 py-2 ${
              openVal ? "transform rotate-180" : ""
            }`}
          > */}
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              [theme.breakpoints.down("lg")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                fontFamily: "SFUIText-bold",
                color:
                  title === "Firmar el Contrato" && !firstThreeStepperStatus
                    ? "#979797"
                    : "#000000",
                [theme.breakpoints.down("lg")]: {
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                },
              })}
            >
              {title}
            </Box>
            <Box
              sx={(theme) => ({
                display: "inline-flex",
                color: "green",
                alignItems: "center",
                // marginLeft: 24,
                [theme.breakpoints.down("lg")]: {
                  fontFamily: "Urbanist-Regular",
                  fontSize: "14px",
                  mt: "5px",
                },
              })}
            >
              {detailStatus === "En Proceso de Revisión" ||
              detailStatus === "En Proceso de Revisión Manual" ||
              detailStatus === "Pendiente" ? (
                <span style={{ color: "#F1A720" }}>
                  {detailStatus && detailStatus}
                </span>
              ) : (
                <></>
              )}
              {(detailStatus === "Aprobada" || detailStatus === "Aceptada") && (
                <span>{detailStatus && detailStatus}</span>
              )}
              {detailStatus === "Presentada" && (
                <span style={{ color: "#34A853" }}>
                  {detailStatus && detailStatus}
                </span>
              )}
              {detailStatus === "Rechazada" && (
                <span style={{ color: "#E92736" }}>
                  {detailStatus && detailStatus}
                </span>
              )}
            </Box>
          </Box>

          <div>
            <ChevronRight
              style={{
                color:
                  title === "Firmar el Contrato" && !firstThreeStepperStatus
                    ? "#979797"
                    : "black",
                transform: openVal ? "rotate(90deg)" : "",
                transition: "all 0.3s ease-in-out",
              }}
            />
          </div>
          {/* <ExpandMoreIcon /> */}
          {/* </span> */}
        </Box>
      </div>
      <div
        style={{
          overflow: "auto",
          transition: "all 0.3s ease-in-out",
          maxHeight: openVal ? "100vh" : "0",
          top: -9,
          position: "relative",
          zIndex: 20,
        }}
      >
        <Box
          sx={(theme) => ({
            padding: "50px 20px",
            border: "1px solid #E6E6E6",
            [theme.breakpoints.down("md")]: {
              border: "transparent",
              fontFamily: "Urbanist-Regular",
              padding: "3rem 1rem",
            },
          })}
        >
          {body}
        </Box>
      </div>
    </div>
  );
};

Panel.propTypes = {
  title: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
  open: PropTypes.bool,
};

export default Panel;
