import { makeStyles } from "@mui/styles";

const hairRestaurationStyles = makeStyles((theme) => ({
    hairReparationIcon:{
        marginLeft:20,
        paddingRight:10
    }
}));

export default hairRestaurationStyles;
