import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { instalmentLoanEndpoints } from "../../../features/installmentLoan/config/endpoints";

import authApi from "../../../apis/auth";
import authEndPoint from "../../../apis/auth/config";
import authEndPointV2 from "../../../apis/auth/configV2";

export const convertURIToImageData = (imgUrl) => {
  return new Promise(function (resolve, reject) {
    if (imgUrl == null) return reject();
    var canvas = document.createElement("canvas"),
      context = canvas.getContext("2d"),
      image = new Image();
    image.addEventListener(
      "load",
      function () {
        canvas.width = image.width;
        canvas.height = image.height;
        context?.drawImage(image, 0, 0, canvas.width, canvas.height);
        resolve(context?.getImageData(0, 0, canvas.width, canvas.height));
      },
      false
    );
    image.src = imgUrl;
  });
};

export const saveUserRegisterInfo = createAsyncThunk(
  authEndPointV2.register,
  async (payload) => {
    console.log({ payload });
    const { data } = await authApi.register(payload);
    return data;
  }
);

export const addCoSigner = createAsyncThunk(
  authEndPointV2.addCosigner,
  async (payload) => {
    const { data } = await authApi.addCoSigner(payload);
    return data;
  }
);

export const getCoSigner = createAsyncThunk(
  authEndPointV2.addCosigner,
  async (payload) => {
    const { data } = await authApi.getCoSigner(payload);
    return data;
  }
);

export const saveTakeLoan = createAsyncThunk(
  authEndPointV2.saveLoanDetail,
  async (payload) => {
    console.log({ payload });
    const { data } = await authApi.saveTakeLoan(payload);
    return data;
  }
);

export const deleteApplicationId = createAsyncThunk(
  authEndPoint.applicationDelete,
  async (payload) => {
    const { data } = await authApi.deleteApplicationId(payload);
    return data;
  }
);

export const verifyPromocode = createAsyncThunk(
  authEndPointV2.verifyPromoCode,
  async (payload) => {
    const { data } = await authApi.verifyPromoCode(payload);
    return data;
  }
);

export const subscribe = createAsyncThunk(
  authEndPointV2.subscribe,
  async () => {
    const { data } = await authApi.subscribe();
    return data;
  }
);

export const getUserGeneralInformation = createAsyncThunk(
  `${authEndPoint.generalInfo}Get`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.generalInfoGet();
    data.payload = payload;
    return data;
  }
);

export const getUserGeneralInformationV2 = createAsyncThunk(
  `${authEndPointV2.generalInfo}Get`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.generalInfoGetV2();
    data.payload = payload;
    return data;
  }
);

export const getDashboardDetails = createAsyncThunk(
  `${authEndPointV2.dashboardDetail}Get`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getDashboardInfo();
    data.payload = payload;
    return data;
  }
);

export const getApplicationStepper = createAsyncThunk(
  `${authEndPoint.applicationStepper}`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getApplicationStepper(payload);
    data.payload = payload;
    return data;
  }
);

export const getStepperApplicationSummary = createAsyncThunk(
  `${authEndPoint.applicationSummary}`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getStepperApplicationSummary(payload);
    data.payload = payload;
    return data;
  }
);

export const getAllApplication = createAsyncThunk(
  `${authEndPoint.applicationAll}`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getApplicationAll(payload);
    data.payload = payload;
    return data;
  }
);

export const getLoanDetails = createAsyncThunk(
  `${authEndPoint.applicationLoanDetail}`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getApplicationLoanDetail(payload);
    data.payload = payload;
    return data;
  }
);

export const saveApplicationLoanDetail = createAsyncThunk(
  authEndPoint.applicationLoanDetail,
  async (payload) => {
    const { data } = await authApi.saveApplicationLoanDetail(payload);
    return data;
  }
);

export const getDownloadContractLink = createAsyncThunk(
  authEndPoint.contractLink,
  async (payload) => {
    const { data } = await authApi.getDownloadContractLink(payload);
    return data;
  }
);

export const submitPromocode = createAsyncThunk(
  authEndPointV2.submitPromocode,
  async (payload) => {
    const { data } = await authApi.submitPromocode(payload);
    return data;
  }
);

export const getUserAccessToken = createAsyncThunk(
  `${instalmentLoanEndpoints.getAccessToken}Get`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getAccessToken();
    data.payload = payload;
    return data;
  }
);

export const getLmsBalanceSummary = createAsyncThunk(
  `${instalmentLoanEndpoints.getLmsBalanceSumamry}Get`,

  async (payload) => {
    const { data } = await authApi.getLmsBalanceSummary(payload);
    return data;
  }
);

export const getTransactionList = createAsyncThunk(
  `${instalmentLoanEndpoints.getTransactionList}Get`,

  async (payload) => {
    const { data } = await authApi.getTransactionList(payload);
    return data;
  }
);

export const getUserPromoCode = createAsyncThunk(
  `${authEndPointV2.userPromoCode}Get`,

  async (payload) => {
    const { data } = await authApi.getUserPromoCode();
    return data;
  }
);

export const getCoSignerStatus = createAsyncThunk(
  `${authEndPointV2.addCosigner}Get`,

  async (payload) => {
    const { data } = await authApi.getCoSignerStatus(payload);
    return data;
  }
);

export const getLoanDetailList = createAsyncThunk(
  `${instalmentLoanEndpoints.getLoanList}Get`,

  async (payload) => {
    const { data } = await authApi.getLoanList(payload);
    return data;
  }
);

export const getCreditDecision = createAsyncThunk(
  `${authEndPointV2.creditDecision}Get`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.getCreditDecisionInfo();
    data.payload = payload;
    return data;
  }
);

export const checkEmailValidity = createAsyncThunk(
  authEndPoint.checkEmail,
  async (payload) => {
    const { data } = await authApi.checkEmail(payload);
    data.payload = payload;
    return data;
  }
);

export const saveUserGeneralInformation = createAsyncThunk(
  authEndPoint.generalInfo,
  async (payload: any) => {
    // mapping payload based on api
    const requestPayload = {
      first_name: payload.first_name,
      last_name: payload.last_name,
      maternal_name: payload.maternal_name,
      gender: payload.gender,
      date_of_birth: payload.date_of_birth,
      curp: payload.curp,
      rfc: payload.rfc,
    };
    const args = {
      gotDataFromGetApiValue: payload.gotDataFromGetApiValue,
      params: requestPayload,
    };

    const { data } = await authApi.generalInfo(args);
    data.payload = payload;

    return data;
  }
);

export const saveUserProfessionalDetails = createAsyncThunk(
  authEndPoint.generalInfo,
  async (payload: any) => {
    // mapping payload based on api
    let requestPayload = {
      occupation: payload.occupation,
      monthly_income_range: payload.monthly_income_range,
      address: {
        street: payload.address.street,
        outside_no: payload.address.outside_no,
        inside_no: payload.address.inside_no,
        colony: payload.address.colony,
        city: payload.address.city,
        municipality: payload.address.municipality,
        cp: payload.address.cp,
        state: payload.address.state,
      },
    };
    if (payload.companyName) {
      requestPayload["company_name"] = payload.companyName;
    } else {
      if (payload.otherOcuppation) {
        requestPayload["occupation_others"] = payload.otherOcuppation;
      }
    }
    const args = {
      gotDataFromGetApiValue: payload.gotDataFromGetApiValue,
      params: requestPayload,
    };
    const { data } = await authApi.generalInfo(args);
    data.payload = payload;

    return data;
  }
);

export const saveUserRfcAndCurp = createAsyncThunk(
  authEndPoint.curpRegistration,
  async (payload: any) => {
    // mapping payload based on api
    const requestPayload = {
      curp: payload.curp,
      rfc: payload.rfc,
    };
    const { data } = await authApi.curpRegistration(requestPayload);
    data.payload = payload;

    return data;
  }
);

export const saveUserProcedureInformation = createAsyncThunk(
  authEndPointV2.procedureInfo,
  async (payload) => {
    const { data } = await authApi.medicalProcedure(payload);
    data.payload = payload;

    return data;
  }
);

export const saveUserPlaceOfBirth = createAsyncThunk(
  `${authEndPoint.additionalInfo}/post`,
  async (payload: any) => {
    // mapping payload based on api
    const address = {
      street: payload.street,
      outside_no: payload.externalNumber,
      inside_no: payload.internalNumber,
      colony: payload.suburb,
      city: payload.city,
      state: payload.state,
      municipality: payload.municipality,
      cp: payload.zip,
    };
    const date_of_birth = moment(payload.dateOfBirth).format("yyyy-MM-DD");
    const requestPayload = {
      address,
      date_of_birth,
      gender: payload.gender,
    };

    const { data } = await authApi.postUserPlaceOfBirth(requestPayload);
    data.payload = payload;
    return data;
  }
);

export const saveUserKnowYouBetter = createAsyncThunk(
  `${authEndPoint.furtherInfo}/post`,
  async (payload: any) => {
    // mapping payload based on api
    const cover_cost = payload.payer;

    const requestPayload = {
      cover_cost,
      occupation: payload.occupation,
      monthly_income_range: payload.monthlyIncome,
    };
    // blank case
    if (payload.companyName) {
      requestPayload["company_name"] = payload.companyName;
    }

    if (payload.otherOcuppation) {
      requestPayload["occupation_others"] = payload.otherOcuppation;
    }

    const { data } = await authApi.postFurtherInfo(requestPayload);
    data.payload = payload;

    return data;
  }
);

export const saveUserIdentificationInformation = createAsyncThunk(
  `${authEndPoint.identityInfo}/post`,
  async (payload: any) => {
    // mapping payload based on api
    const requestPayload = new FormData();
    requestPayload.append("id_type", payload.typeId);
    requestPayload.append("photo_front", payload.front);
    requestPayload.append("photo_back", payload.back);
    const { data } = await authApi.postIdentityInfo(requestPayload);
    data.payload = payload;
    return data;
  }
);

export const saveUserFinalProcessInformation = createAsyncThunk(
  `${authEndPoint.finalInfo}/post`,
  async (payload: any) => {
    // mapping payload based on api
    const requestPayload = new FormData();
    requestPayload.append("tnc", payload.tnc);
    requestPayload.append("signature", payload.signature);
    requestPayload.append("app_id", payload.application_id);
    const { data } = await authApi.postFinalInfo(requestPayload);
    data.payload = payload;
    return data;
  }
);

export const getSignature = createAsyncThunk(
  `${authEndPoint.finalInfo}/get`,
  async (payload) => {
    const { data } = await authApi.getSignature(payload);
    return data;
  }
);

export const saveUseRequestedCreditInformation = createAsyncThunk(
  `${authEndPoint.saveCreditRequest}/post`,
  async (payload) => {
    // mapping payload based on api
    const { data } = await authApi.saveCreditRequest(payload);
    data.payload = payload;
    return data;
  }
);

export const savePatientGroup = createAsyncThunk(
  `${authEndPointV2.savePatientGroup}`,
  async (payload: any) => {
    // mapping payload based on api
    const { data } = await authApi.savePatientGroup(payload);
    data.payload = payload;
    return data;
  }
);

export const savePatientApplication = createAsyncThunk(
  `${authEndPoint.saveApplication}`,
  async (payload: any) => {
    // mapping payload based on api
    const { data } = await authApi.savePatientApplication(payload);
    data.payload = payload;
    return data;
  }
);

export const getPatientApplication = createAsyncThunk(
  `${authEndPoint.saveApplication}/GET`,
  async (payload: any) => {
    // mapping payload based on api
    const { data } = await authApi.getPatientApplication(payload);
    data.payload = payload;
    return data;
  }
);

export const getPatientGroup = createAsyncThunk(
  `${authEndPointV2.savePatientGroup}/GET`,
  async () => {
    // mapping payload based on api
    const { data } = await authApi.getPatientGroup();
    return data;
  }
);

export const getApplicationAgreement = createAsyncThunk(
  `${authEndPoint.applicationAgreement}Get`,

  async (payload) => {
    const { data } = await authApi.getApplicationAgreement(payload);
    return data;
  }
);
