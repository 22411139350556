/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 *
 * Panel
 *
 */

import PropTypes from 'prop-types';
import { useState } from 'react';
import { Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { ChevronRight } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import pdf from '../../assets/pdf.png';
import file from '../../assets/file.svg';
import moment from 'moment';
import DownloadIcon from '@mui/icons-material/Download';

const PanelStatement = ({
  title,
  body,
  open,
  status,
  amount,
  date,
  statement,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);

  const [openVal, setOpenVal] = useState(open || panelOpen);

  const handlePanel = () => {
    setPanelOpen(!panelOpen);
    if (!open) {
      setOpenVal(!openVal);
    }
  };

  return (
    <div
      style={{ marginBottom: 10 }}
      onClick={() => window.open(statement.url, '_blank')}
    >
      <div aria-controls='panel2a-content' id='panel2a-header'>
        <Box
          sx={(theme) => ({
            background: 'rgba(53, 82, 204, 0.05)',
            padding: `10px 14px`,
            marginBottom: '8px',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(53, 82, 204, 0.25)',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            [theme.breakpoints.down('lg')]: {
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '2px',
              mb: '1.5rem',
            },
            [theme.breakpoints.down('tablet')]: {
              borderRadius: '20px',
              p: '1rem',
            },
          })}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Box
              sx={(theme) => ({
                padding: '14px 25px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                borderRadius: '10px',
                marginRight: '20px',
                '& #file': { display: 'none' },
                [theme.breakpoints.down('lg')]: {
                  '& img': { display: 'none' },
                  '& #file': { display: 'block' },
                  boxShadow: 'unset',
                  marginTop: '6px',
                },
                [theme.breakpoints.down('tablet')]: {
                  p: '8px 14px',
                  mr: 0,
                },
              })}
            >
              <img src={pdf} height='100%' alt='' />
              <img alt='' src={file} id='file' />
            </Box>

            <div>
              <Box
                sx={(theme) => ({
                  fontFamily: 'SFUIText-bold',
                  textAlign: 'left',
                  color: '#3F3D56',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: 'Urbanist-Semibold',
                    fontSize: '14px',
                  },
                  [theme.breakpoints.down('tablet')]: { fontSize: '12px' },
                })}
              >
                Nombre del archivo: {title}
              </Box>
              <Box
                sx={(theme) => ({
                  fontSize: '16px',
                  color: '#7F8192',
                  textAlign: 'left',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: 'Urbanist-Regular',
                    fontSize: '14px',
                  },
                  [theme.breakpoints.down('tablet')]: {
                    fontSize: '12px',
                    mt: '5px',
                  },
                })}
              >
                Fecha:{statement?.statement_date || ''}
                {/* Tamaño: 2MB */}
              </Box>
            </div>
          </div>

          {/* <span
            className={`transition-transform flex px-4 py-2 ${
              openVal ? "transform rotate-180" : ""
            }`}
          > */}
          <div
            style={{
              display: 'inline-flex',
              color: 'green',
              alignItems: 'center',
              marginLeft: 24,
            }}
          >
            <DownloadIcon
              sx={(theme) => ({
                color: '#3552CC',
                fontSize: '35px',
                [theme.breakpoints.down('lg')]: {
                  fontSize: '30px',
                },
              })}
            />{' '}
          </div>
          {/* <ExpandMoreIcon /> */}
          {/* </span> */}
        </Box>
      </div>
    </div>
  );
};

PanelStatement.propTypes = {
  title: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
  open: PropTypes.bool,
};

export default PanelStatement;
