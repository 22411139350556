export default {
  common: {
    loanDetails: "LOAN DETAILS",
  },
  locReceipt: {
    listTitle: "Pagos Y Retiros",
    creditPayment: "Pago de crédito",
    payDetail: "Detalle del pago",
    disbursement: "Desembolso *{{code}}",
    deposit: "Depósito *{{code}}",
    disbursementTitle: "Detalles del contrato",
    reqAmt: "Monto solicitado",
    amtOutstanding: "Saldo pendiente",
    contractId: "Número de contrato",
    date: "Fecha",
    transactionId: "ID de transacción",
    startDate: "Fecha de inicio",
    endDate: "Fecha final",
    payments: "Pagos",
    frequency: "Frecuencia de pago",
    lateFee: "Cargos por pagos atrasados",
    convenienceCost: "Costos de conveniencia",
    annualCost: "Tasa anual",
    lateFeeDetail: "Ver detalles",
    options: "Opciones",
    fileName: "contract-details.pdf",
    paymentFileName: "payment-details.pdf",
    downloadDesc: "Descargar PDF",
    downloadTitle: "Descargar detalles del contrato",
    downloadPaymentTitle: "Descargar Recibo de pago",
    emptyTitleDisbursement: "Aún no tienes transacciones",
    emptyTitlePayment: "Aún no tienes pagos",
  },
  signUp: {
    content:
      "Elige la cantidad que necesitas, elige tu plan de pago y recibirás los fondos en tu cuenta en cuestión de minutos *.",
    listMenu: {
      opt1: "Contratación de crédito",
    },
    btnLabel: "Comenzar",
    heading: "PRESTAMO A PLAZOS",
    cards1: {
      title: "Credit to boost your business",
      label1: "Big limit -> Borrow upto to $ 1Million pesos",
      label2: "Flexible/Convenient  Repayment Terms -> 12, 24 and  36 months",
      label3:
        "Collateral -> Absolutely No, we run our business on Trusting you!  You can avail the loan without pledging any valuable assets or security or collateral",
    },
    cards2: {
      title: "Digital application and approval in minutes",
      label1:
        "The fastest registration. Process 100% online, enter your information and get an answer*",
      label2:
        "There are minimum documentation and loan gets approved in minutes",
    },
    cards3: {
      title: "Clear and flexible payment terms",
      label1: "Fee -> No hidden fees. 100% transparency!",
      label2: "Fixed payment amount -> Clear and transparent payment schedule.",
      label3: "Tip -> Pay interest only on the borrowed amount",
    },
  },
  resume: {
    eligibleMsg: "You are eligible to borrow upto {{amount}} pesos.",
    list: {
      amountRequired: "AMOUNT REQUIRED",
      purpose: "PURPOSE OF THE LOAN",
      tenure: "Loan Tenure",
      interest: "Loan Interest",
    },
    getMonth: "{{month}} meses",
    pa: "{{rate}}% p.a",
    staticDetails: {
      pFee: "Processing fee - {{rate}}% of loan amount",
      fcFee: "Foreclosure fee - Nil",
      pp: "Part payment - Nil",
    },
    quickView: {
      memi: "Monthly EMI",
      pa: "Principal Amount",
      ira: "Interest Amount",
      ta: "Total Paybale Amount",
      catInfo:
        "CAT {{rate}}% sin IVA. Tasa de interés promedio ponderada por saldo en términos anuales 16% Comisión por disposición $0.00 sin IVA informativo.",
    },
    increaseLimitMsg:
      "Your current limit is {{limit}}, give bank details to avail  more limit",
  },
  dashboard: {
    paidTillDateTop: "pagado",
    paidTillDateBottom: "a la fecha",
    totalAmount: "Cantidad total del préstamo",
    nextPayment: "A pagar al",
    memi: "Cuota mensual",
    outstandingAmount: "Saldo pendiente",
    tenure: "Tenure",
    interest: "Interest",
    disbursementDate: "Fecha de inicio",
    nextPaymentAmt: "Monto del próximo pago",
    greeting: {
      night: "Buenas noches",
      morning: "Buenos días",
      afternoon: "Buenas tardes",
    },
  },
  paymentSchedule: {
    title: "Payment schedule",
  },
  transactions: {
    title: "Pagos Y Retiros",
  },
  quickLinks: {
    contract: "Detalles del contrato",
    paymentSchedule: "Calendario de pago",
    invoice: "Descargar factura",
    support: "Atención al cliente",
  },
};
