import { useDispatch } from "react-redux";
import SyncWidget from "@paybook/sync-widget";
import "@paybook/sync-widget/dist/widget.css";
import { useEffect } from "react";

import { getToken, saveData } from "../installmentLoan/store/payBook";
import useGetStore from "../../store/getStore";

export const PayBook = ({
  userId
}) => {
  const dispatch = useDispatch();
  const {
    payBook: { widgetToken, payBookUserId },
  } = useGetStore();

  const handleWidget = () => {
    var params = {
      token: widgetToken,
      config: {
        locale: "es",
        navigation: {
          displayStatusInToast: true,
        },
      },
    };
    var syncWidget = new SyncWidget(params);
    syncWidget.open();

    syncWidget.$on("opened", () => {
    });

    syncWidget.$on("closed", () => {
    });

    syncWidget.$on("success", (data) => {
      if (data) {
        dispatch(saveData(userId));
      }
    });
    syncWidget.$on("error", (data) => {
    });
  };

  useEffect(() => {
    if (widgetToken && payBookUserId) {
      handleWidget();
    }
  }, [payBookUserId, widgetToken]);

  const loadData = async () => {
    dispatch(getToken(userId));
  };

  useEffect(() => {
    loadData();
  }, []);

  return <div id="widget" />;
};

export default PayBook;
