import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import AddCircle from "@mui/icons-material/AddCircle";
import moment from "moment";
import TermConditionTextStyles from "./TermsAndConditions.styles";

const PaymentHistory = (props) => {
  const dispatch = useDispatch();
  const { setOpen, schedule, applicationId } = props;
  const classes = TermConditionTextStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.mainContainer}>
      <AddCircle
        style={{
          color: "#3552CC",
          transform: "rotate(45deg)",
          position: "absolute",
          right: 20,
          cursor: "pointer",
          top: 20,
        }}
        onClick={handleClose}
      />
      {/* <div>
        <h1>Payment Schedule</h1>
      </div> */}
      <div>
        <div
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: 10,
            overflow: "auto",
            maxHeight: "630px",
            marginBottom: 15,
            // textAlign: "center",
          }}
        >
          <Typography
            textAlign="left"
            fontWeight={600}
            fontSize="1rem"
            ml={4}
            py={2}
          >
            Horario para la identificación del préstamo:{applicationId}{" "}
          </Typography>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead
              style={{
                backgroundColor: "#3552CC",
                color: "#fff",
                fontSize: 18,
                height: 60,
              }}
            >
              <tr>
                <td style={{ paddingLeft: 40 }}>Plazo</td>
                <td style={{ paddingLeft: 40 }}>Fecha</td>{" "}
                <td style={{ paddingLeft: 40 }}>Amortización de principal</td>
                <td style={{ paddingLeft: 40 }}>Intereses</td>
                <td style={{ paddingLeft: 40 }}>IVA</td>
                <td style={{ paddingLeft: 40 }}>Saldo por pagar</td>
                <td style={{ paddingLeft: 40, paddingRight: 20 }}>Estatus</td>
              </tr>
            </thead>
            <tbody>
              {schedule?.length > 0 ? (
                schedule.map((each) => (
                  <tr style={{ height: 60, borderBottom: "1px solid #E6E7E8" }}>
                    <td style={{ paddingLeft: 40 }}>{each.term}</td>
                    <td style={{ paddingLeft: 40 }}>
                      {moment(each.due_date).format("yyyy-MM-DD hh:mm")}
                    </td>
                    <td style={{ paddingLeft: 40 }}>${each.principal_amt}</td>
                    <td style={{ paddingLeft: 40 }}>${each.interest_amt}</td>
                    <td style={{ paddingLeft: 40 }}>${each.vat_amt}</td>
                    <td style={{ paddingLeft: 40 }}>${each.term_due_amt}</td>
                    <td style={{ paddingLeft: 40, paddingRight: 20 }}>
                      {each.term_due_amt === 0 ? (
                        <span style={{ color: "#75A06E" }}>Pagado</span>
                      ) : (
                        <span style={{ color: "#D4A429" }}>Programado</span>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <div style={{ textAlign: "center", margin: "0px auto" }}>
                  Datos no disponibles
                </div>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
