import installmentLoan from "../../features/installmentLoan/translations";
import common from "../es/common";

export const instantLineCreditTS = {
  hello: "Hola mundo",
  back: "Atrás",
  greeting: {
    night: "Buenas noches",
    morning: "Buenos días",
    afternoon: "Buenas tardes",
  },
  installmentLoan,
  common,
};

export default {
  translation: {
    instantLineCreditTS,
  },
};
