import { useState } from "react";

import { Box, Grid } from "@mui/material";

import { CONTACT_EMAIL } from "../../config/key";
import Header from "../home-v2/Header";
import Footer from "../home-v2/Footer";
import useStyle from "./styles";
import BodyContent from "./BodyContent";
import MainContent from "./MainContent";

export const FaqsPage = () => {
  const css = useStyle();
  const [value, setValue] = useState("");

  const handleClick = (value) => {
    setValue(value || "");
  };

  const handleWrite = () => {
    window.open(`mailto:${CONTACT_EMAIL}`);
  };

  return (
    <Box className={css.container}>
      <Box className={css.videoContainer}>
        <MainContent handleClick={handleClick} />
        <Header />
      </Box>
      <BodyContent filterString={value} />
      <Box className={css.bottomContainer}>
        <Grid container>
          <Grid item md={7}>
            <Box className="content">
              Si tienes alguna otra consulta, envíanos un correo electrónico
            </Box>
          </Grid>
          <Grid item md={2} />
          <Grid item md={3}>
            <button onClick={handleWrite}>Escríbenos</button>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
};

export default FaqsPage;
