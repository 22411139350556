import { Route, Redirect } from "react-router-dom";
import { AUTH_ROUTES } from "../../constants/routes";
import { getAccessToken, getCoSignerToken } from "../../utils/auth";


const PrivateRoute = ({ component: Component, ...rest }) => {
  
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        Boolean(getAccessToken() || getCoSignerToken())? <Component {...props} /> : <Redirect to={AUTH_ROUTES.LOGIN} />
      }
    />
  );
};
export default PrivateRoute;
