import { makeStyles } from "@mui/styles";
import desktopBg from "../../assets/CreditHistory/backgroundDesktop.svg";
import tabletBg from "../../assets/CreditHistory/backgroundTablet.svg";
import mobileBg from "../../assets/CreditHistory/backgroundMobile.svg";
import tabletHeader from "../../assets/CreditHistory/headerTablet.svg";
import mobileHeader from "../../assets/CreditHistory/headerMobile.svg";
import desktopHeader from "../../assets/CreditHistory/headerDesktop.svg";

const styles = makeStyles((theme) => ({
  pageContainer: {
    backgroundImage: `url(${mobileBg})`,
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ` url(${mobileBg})`,
    },
    [theme.breakpoints.between("sm", "lg")]: {
      backgroundImage: ` url(${tabletBg})`,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: ` url(${desktopBg})`,
    },
  },
  headerPageBackground: {
    height: 280,
    backgroundImage: ` url(${desktopHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: ` url(${tabletHeader})`,
      height: 230,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: ` url(${mobileHeader})`,
      height: 168,
    },
  },
  logo: {
    width: 90,
    marginTop: -260,
    [theme.breakpoints.down(1001)]: {
      marginTop: -200,
    },
    [theme.breakpoints.down(751)]: {
      marginTop: -185,
    },
    [theme.breakpoints.down(601)]: {
      width: 60,
      marginTop: -140,
    },
  },
  textTitle: {
    color: theme.palette.primary.white,
    marginTop: "5px !important",
    marginBottom: "140px !important",
    [theme.breakpoints.down(1001)]: {
      marginBottom: "120px !important",
    },
    [theme.breakpoints.down(751)]: {
      marginBottom: "110px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "22px !important",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelForm: {
    color: theme.palette.primary.main,
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 20,
    },
  },
  radioButton: {
    maxWidth: 425,
    marginBottom: 30,
    marginLeft: 20,
    marginRight: 20,
    [theme.breakpoints.down(901)]: {
      maxWidth: 345,
    },
  },
  inputSize: {
    marginBottom: "20px !important",
  },
  disabled: {
    color: theme.palette.primary.gray + "!important",
  },
}));

export default styles;
