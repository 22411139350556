import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const successToast = (message = "Done!", options = {}) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    closeOnClick: true,
    ...options,
  });
};

export const errorToast = (message = "Algo salió mal", options = {}) => {
  toast.error(message, {
    position: "top-right",
    closeOnClick: true,
    autoClose: 3000,
    ...options,
  });
};

export const infoToast = (message, options = {}) => {
  toast.info(message, {
    position: "top-right",
    ...options,
  });
};
