import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles: any = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '20px',
    fontFamily: 'SFUIText-bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
      fontFamily: 'Urbanist-Bold',
    },
  },
  value: {
    fontSize: '16px',
    fontFamily: 'SFUIText-bold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
  buttonStyle: {
    borderRadius: '8px',
    background: '#3552CC',
    padding: '13px 136px',
    color: '#FFFFFF',
    fontSize: '20px',
    fontFamily: 'SFUIText-semibold',
    [theme.breakpoints.down('lg')]: {
      fontSize: '17px',
      fontFamily: 'Urbanist-SemiBold',
      borderRadius: '30px',
      padding: '12px 2rem',
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
}));

export default useStyles;
