

const CircularDotIcon= ({ defaultColor, ...props }) => {
  return (
    <svg
      {...props}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="6" cy="5.5" rx="6" ry="5.5"  fill={defaultColor ? props.fill : "currentColor"} />
    </svg>
  );
};

CircularDotIcon.defaultProps = {
  fill: '000000',
};

export default CircularDotIcon;
