import { makeStyles } from "@mui/styles";

const styles = makeStyles(() => ({
  basicGradientButtonStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    minWidth: "200px",
    minHeight: "48px",
    border: "none",
    fontFamily: "SFUIText-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "1.2",
    color: "#fff",
    backgroundImage: "linear-gradient(94deg, #ABCED4,#4893CC)",
    // margin: "0px 0px 0px 22px",
    cursor: 'pointer',
  },
}));

export default styles;
