import { FC } from "react";
import { Box } from "@mui/system";

import { getProcedureName } from "../../../store/helpers";
import { convertDateTimeToLocal } from "../../../utils/dateHelper";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { URBANIST_FONT } from "../../../constants/common";
import customColor from "../../../theme/customColor";

interface DoctorDetailsProps {
  doctorDetails?: any;
}

const DoctorDetails: FC<DoctorDetailsProps> = ({ doctorDetails }) => {
  const css = useStyles();
  if (doctorDetails) {
    const listItem: any = [];
    const { doc_name, doc_last_name, doc_email } = doctorDetails;

    if (doc_name && doc_last_name) {
      listItem.push({
        label: "Nombre del doctor",
        value: `${doc_name} ${doc_last_name}`,
      });
    }

    if (doc_email) {
      listItem.push({
        label: "Correo electrónico",
        value: doc_email,
      });
    }

    return (
      <Box className={css.container}>
        <Typography
          textAlign="left"
          fontFamily={URBANIST_FONT.BOLD}
          fontSize={28}
          color={customColor.cotton}
          marginBottom={2}
        >
          Información del Doctor
        </Typography>
        {listItem?.map((item) => {
          return (
            <Box className="row" key={item.label}>
              <Box className="title">{item.label}:</Box>
              <Box className="desc">{item.value}</Box>
            </Box>
          );
        })}
      </Box>
    );
  }
  return null;
};

export default DoctorDetails;
