import { Check } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../../constants/routes";
import SubmissionModalStyles from "./SubmissionModal.styles";
import { useDispatch } from "react-redux";
import {
  resetBooleanValuesCosigner,
  resetCosingerInfo,
} from "../../../store/slices/cosigner";
import {
  resetBooleanValuesUser,
  resetUserRegisterInfo,
} from "../../../store/slices/users";
const SubmissionModal = (props) => {
  const { handleClose } = props;
  const dispatch = useDispatch();
  const sendConfirmation = () => {
    dispatch(resetBooleanValuesCosigner());
    dispatch(resetBooleanValuesUser());
    dispatch(resetCosingerInfo());
    dispatch(resetUserRegisterInfo());
    handleClose()

  };
  const history = useHistory();
  const classes = SubmissionModalStyles();
  return (
    <Fragment>
      <Grid container className={classes.modalContainer}>
        <Grid container className={classes.modalTitleContainer}>
          <Grid item xs={10}>
            <Typography variant="h3" className={classes.title}>
              Confirmación
            </Typography>
          </Grid>
          {/* <Grid item xs={2}>
            <Close onClick={handleClose} className={classes.icon} />
          </Grid> */}
        </Grid>
        <Grid container className={classes.modalBodyContainer}>
          <Grid item>
            <Typography variant="subtitle1" className={classes.firstInfo}>
              Hemos detectado que hay una sesión activa, si regresas a esta
              página todo el proceso se perderá.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={classes.secondInfo}>
              ¿Quieres comenzar un proceso nuevo?
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.modalFooterContainer}>
          <Button
            className={classes.dismissBtn}
            onClick={() => {
              handleClose();
              history.push(ROUTES.generalInformation);
            }}
          >
            Cancelar
          </Button>
          <Button
            id="confirmDialog"
            color="primary"
            variant="contained"
            endIcon={<Check fontSize="small" />}
            onClick={ sendConfirmation}
          >
            CONFIRMAR
          </Button>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default SubmissionModal;
