import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  container: {},
  card: {
    width: "300px",
    maxWidth: "300px",
    minWidth: "300px",
    height: "320px",
    background: "#FFF",
    boxShadow: "0px 10px 8px rgba(0, 0, 0, 0.25)",
    borderRadius: 40,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    "&:hover": { transition: "all .5s ease-in-out" },
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down(601)]: {
      marginLeft: 30,
    },
  },
  imgContainer: {
    height: "50%",
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    paddingBottom: 16,
  },
  cardTitle: {
    fontFamily: "Nexa-Bold",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "25px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#28447C",
    width: "60%",
    [theme.breakpoints.down(601)]: {
      whiteSpace: "normal",
    },
    height: "50%",
  },
  hoverListItemStyle: {
    fontSize: "16px !important",
    fontWeight: "300 !important",
    color: "#54595f",
    fontFamily: "SFUIText-Regular !important",
    textAlign: "left",
    [theme.breakpoints.down(601)]: {
      whiteSpace: "break-spaces",
    },
  },
  cardContainer: {
    [theme.breakpoints.down(601)]: {
      flexWrap: "unset",
      overflow: "auto",
      whiteSpace: "nowrap",
      width: "100vw",
      height: "max-content",
      display: "flex",
      padding: "20px 0",
    },
  },
  cardMainContainer: {
    [theme.breakpoints.down(601)]: {
      background:
        "linear-gradient(90deg, #C1DCF9 0%, #D0E5FA 20.43%, #F2F8FE 100%)",
      padding: "30px 0",
    },
  },
  btn: {
    [theme.breakpoints.down(601)]: {
      marginBottom: 30,
      marginTop: 28,
      "& > button": {
        padding: "14px 26px",
        width: "max-content !important",
        minWidth: "fit-content",
        fontSize: "16px",
        height: "max-content !important",
      },
    },
  },
  listContainer: {
    "&::-webkit-scrollbar": { display: "none" },
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    padding: "10px",
  },
  childList:{
    listStyleType: 'circle',
    margin: 0,
    padding: 0,
  }
}));

export default styles;
