import { createAsyncThunk } from "@reduxjs/toolkit";

import coSignerAPI from "../../../apis/coSigner";
import coSignerEndPoint from "../../../apis/coSigner/config";

export const checkReferenceCode = createAsyncThunk(
  coSignerEndPoint.checkReferenceCode,
  async (arg: any) => {
    const { data } = await coSignerAPI.checkReferenceCode(arg);
    return data;
  }
);

export const applicationSummary = createAsyncThunk(
  coSignerEndPoint.applicationSummary,
  async (arg: any) => {
    const { token } = arg;
    const { data } = await coSignerAPI.applicationSummary({
      token,
    });
    return data;
  }
);

export const curpRegistration = createAsyncThunk(
  coSignerEndPoint.curpRegistration,
  async (payload) => {
    const { data } = await coSignerAPI.curpRegistration(payload);
    data.payload = payload;
    return data;
  }
);

export const getCosignerPersonalInformation = createAsyncThunk(
  `${coSignerEndPoint.additionalInfo}/GET`,
  async () => {
    // mapping payload based on api
    const { data } = await coSignerAPI.getCosignerPersonalInformation();
    return data;
  }
);

export const additionalInfo = createAsyncThunk(
  coSignerEndPoint.additionalInfo,
  async (payload) => {
    // mapping payload based on api
    const { data } = await coSignerAPI.additionalInfo(payload);
    data.payload = payload;
    return data;
  }
);
export const finalInfo = createAsyncThunk(
  coSignerEndPoint.finalInfo,
  async (payload: any) => {
    const requestPayload = new FormData();
    requestPayload.append("tnc", payload.tnc);
    requestPayload.append("signature", payload.signature);
    const arg = { finalInfo: requestPayload, token: payload.token };
    const { data } = await coSignerAPI.finalInfo(arg);
    data.payload = payload;
    return data;
  }
);

export const saveCosignerSignatures = createAsyncThunk(
  coSignerEndPoint.saveCosignerSignatures,
  async (payload: any) => {
    const requestPayload = new FormData();
    requestPayload.append("tnc", payload.tnc);
    requestPayload.append("signature", payload.signature);
    requestPayload.append("app_id", payload.app_id);
    const arg = { finalInfo: requestPayload, token: payload.token };
    const { data } = await coSignerAPI.saveCosignerSignatures(arg);
    data.payload = payload;
    return data;
  }
);

export const identifyCosignerUnboardingSteps = createAsyncThunk(
  coSignerEndPoint.identifyCosignerUnboardingSteps,
  async (payload) => {
    const arg = { token: payload };
    const { data } = await coSignerAPI.identifyCosignerUnboardingSteps(arg);
    data.payload = payload;
    return data;
  }
);

export const getZipCodeInfoCosigner = createAsyncThunk(
  `${coSignerEndPoint.getZipCodeInfo}/?zipcode=zipCode`,
  async (zipCode: any) => {
    const { data } = await coSignerAPI.getZipCodeInfo(zipCode);
    return data;
  }
);

export const updateCosignerPhonenumber = createAsyncThunk(
  `${coSignerEndPoint.updatePhonenumber}/patch`,
  async (payload: any) => {
    const { data } = await coSignerAPI.updateCosignerPhonenumber(payload);
    return data;
  }
);

export const getCosignerAgreement = createAsyncThunk(
  `${coSignerEndPoint.getCosignerAgreement}/GET`,
  async (app_id: string) => {
    const { data } = await coSignerAPI.getCosignerAgreement(app_id);
    return data;
  }
);
