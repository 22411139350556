import { Box } from "@mui/material";
import React from "react";
import GradientLogo from "../../../../assets/Logos/gradient-logo.svg";

const PocedureIconAndTitle = () => {
  return (
    <Box textAlign="center">
      <img src={GradientLogo} alt="gradientLogo" style={{marginTop:40}} />
      <Box
        sx={{
          color: "rgba(0, 0, 0, 0.5)",
          fontSize: 30,
          fontFamily: "Urbanist-SemiBold",
          textAlign: "center",
        }}
      >
        Procedimientos
      </Box>
    </Box>
  );
};

export default PocedureIconAndTitle;
