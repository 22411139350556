import Cookies from "js-cookie";
// import { cookieName, cookieDomain } from '../config';

const cookieDomain = "http://localhost:3000/";
const cookieName = "mettaUserData";
export const CoSignerAuth = "mettaCoSignerAuth";
export const CoSignerApplicationId = "mettaCoSignerApplicationId";
export const onBoardingUserType = "mettaonBoardingUserType";

export const isAuthenticated = () => {
  const token = getAccessToken();
  return Boolean(token);
};

/**
 *
 * @param {{
 *  access: string;
 *  tags: string[];
 *  username: string;
 *  fullName: string;
 * }} param0
 */
export const setUserCookie = (userData, name = cookieName) => {
  if (userData?.access_token) {
    Cookies.set(name, JSON.stringify(userData), {
      // domain: cookieDomain,
      // 24 hours
      expires: 1,
    });
  } else {
    Cookies.remove(name);
    Cookies.remove(name, {
      domain: cookieDomain,
    });
  }
};

export const setCookie = (data, name) => {
  if (data) {
    Cookies.set(name, JSON.stringify(data), {
      // domain: cookieDomain,
      // 24 hours
      expires: 1,
    });
  } else {
    Cookies.remove(name);
    Cookies.remove(name, {
      domain: cookieDomain,
    });
  }
};

/**
 *
 * @returns {string}
 */
export const getAccessToken = () => {
  try {
    const cookie = Cookies.get(cookieName);
    const parsedData = JSON.parse(cookie) || {};
    return parsedData?.access_token;
  } catch (err) {
    return false;
  }
};

export const getLmsAccessToken = () => {
  try {
    const cookie = Cookies.get(cookieName);
    const parsedData = JSON.parse(cookie) || {};
    return parsedData?.access_token;
  } catch (err) {
    return false;
  }
};

export const getCoSignerToken = () => {
  try {
    const cookie = Cookies.get(CoSignerAuth);
    const parsedData = JSON.parse(cookie) || {};
    return parsedData;
  } catch (err) {
    return false;
  }
};
export const getCosignerAppId = () => {
  try {
    const cookie = Cookies.get(CoSignerApplicationId);
    const parsedData = JSON.parse(cookie) || {};
    return parsedData;
  } catch (err) {
    return false;
  }
};

/**
 *
 * @returns {string}
 */
export const getCookie = (name = cookieName) => {
  try {
    const cookie = Cookies.get(name);
    const parsedData = JSON.parse(cookie) || {};
    return parsedData;
  } catch (err) {
    return "";
  }
};

/**
 *
 * @param {import('react-router-dom').RouterProps['history']} history
 */
export const logout = () => {
  Cookies.remove(cookieName);
  Cookies.remove(cookieName, {
    domain: cookieDomain,
  });
  if (window.location.pathname !== "/login") {
    window.location.replace("/login");
  }
};
