import { FC } from "react";
import { Box } from "@mui/material";

import Button from "../../../features/installmentLoan/components/Button";
import EmailCircleIcon from "../../../components/icons/EmailCircle";
import useCommonStyles from "../styles";

export interface ButtonClickProps {
  password: string;
}

interface SuccessPopupProps {
  onSubmit: () => void;
}

const SuccessPopup: FC<SuccessPopupProps> = ({ onSubmit }) => {
  const commonCss = useCommonStyles();

  return (
    <Box className={commonCss.content}>
      <Box mt={4} textAlign="center">
        <EmailCircleIcon />
      </Box>
      <Box className={commonCss.title} ml={3} mt={3}>
        Te hemos enviado un correo electrónico
      </Box>
      <Box mt={2}>
        Revisa todas las bandejas de tu correo, incluyendo la de SPAM. Si no
        recibes el correo, aún después de solicitar nuevamente, revísa la
        dirección de email que ingresaste en la pantalla anterior.
      </Box>
      <Box textAlign="center" mt={6}>
        <Button
          label="Enviar correo nuevamente"
          onClick={() => onSubmit()}
          noCaps
          className={commonCss.loginButtonStyle}
        />
      </Box>
    </Box>
  );
};

export default SuccessPopup;
