import { Button, Box, Typography } from "@mui/material";
import React from "react";
import { useInView } from "react-intersection-observer";
import GeolocationStyles from "./Geolocation.styles";

const Geolocation = (props) => {
  const { setClose } = props;
  const classes = GeolocationStyles();
  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  });
  let disabled = inView ? false : true;

  const handleClose = () => {
    setClose(false);
  };
  return (
    <div>
      <Box className={classes.mainContainer}>
        <Box className={classes.textWrapper}>
          <Typography variant="body1" className={classes.text}>
            <strong> Aviso de Geolocalización</strong>{" "}
          </Typography>
          <Typography
            variant="body1"
            className={`${classes.text} ${classes.mt16}`}
          >
            <strong> LEE ATENTAMENTE</strong>
          </Typography>
          <Typography
            variant="subtitle1"
            className={`${classes.text} ${classes.mt16}`}
            ref={ref}
          >
            Para los efectos de este aviso de privacidad, Información de
            Geolocalización significa la información que identifica, con una
            especificidad razonable, su ubicación (mediante el uso de
            coordenadas de longitud y latitud obtenidas a través de GPS, Wi-Fi o
            triangulación de ubicación de celulares y otros similares). Si no
            está de acuerdo en compartir su Información de Geolocalización,
            considere que es posible que nuestros servicios no se presten de
            forma correcta.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        className={classes.buttonSection}
      >
        <Button
          disabled={disabled}
          variant="contained"
          className={classes.button}
          onClick={handleClose}
        >
          Aceptar
        </Button>
      </Box>
    </div>
  );
};

export default Geolocation;
