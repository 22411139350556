import { makeStyles } from "@mui/styles";

import selectedNotImg from "../../assets/images/selectedNot.png";

const CreditConfirmationStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    backgroundImage: `url("data:image/svg+xml,%3Csvg id='Back' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1920 1080'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%233552cc;%7D.cls-2%7Bfill:%23abced4;opacity:0.24;%7D.cls-3%7Bfill:url(%23Degradado_sin_nombre_33);%7D%3C/style%3E%3ClinearGradient id='Degradado_sin_nombre_33' x1='414.3369' y1='-418.13' x2='1690.0923' y2='857.6254' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23839dbe'/%3E%3Cstop offset='0.1262' stop-color='%236f8acd'/%3E%3Cstop offset='0.3409' stop-color='%235470e2'/%3E%3Cstop offset='0.5299' stop-color='%234361ef'/%3E%3Cstop offset='0.672' stop-color='%233d5bf4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EConfirmacion back Desktop%3C/title%3E%3Crect class='cls-1' x='-2.4812' y='-1.1564' width='1920.1086' height='1078.7425'/%3E%3Cpath class='cls-2' d='M1502.0482,1076.4176S1730.0957,901.24,1923.4228,839.69v237.8958Z'/%3E%3Cpath class='cls-3' d='M-2.7888,253.3191S796.3453,857.7322,1919.8853,627.8325V-1.1564H-2.6368Z'/%3E%3C/svg%3E")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Back' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 768 1024'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%233552cc;%7D.cls-2%7Bfill:%23abced4;opacity:0.24;%7D.cls-3%7Bfill:url(%23Degradado_sin_nombre_33);%7D%3C/style%3E%3ClinearGradient id='Degradado_sin_nombre_33' x1='73.7829' y1='-70.769' x2='770.0168' y2='625.4649' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23839dbe'/%3E%3Cstop offset='0.1262' stop-color='%236f8acd'/%3E%3Cstop offset='0.3409' stop-color='%235470e2'/%3E%3Cstop offset='0.5299' stop-color='%234361ef'/%3E%3Cstop offset='0.672' stop-color='%233d5bf4'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EConfirmacion elementos tablet%3C/title%3E%3Crect class='cls-1' y='-0.1758' width='767.9775' height='1024.3672'/%3E%3Cpath class='cls-2' d='M465.096,1023.2046S628.8153,897.4412,767.6082,853.2539v170.79Z'/%3E%3Cpath class='cls-3' d='M1.9192,479.0036S319.577,719.8327,767.2528,628.2289V1.0342H1.98S-2.5133,479.0036,1.9192,479.0036Z'/%3E%3C/svg%3E")`,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 414 736' style='enable-background:new 0 0 414 736;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%233552CC;%7D .st1%7Bopacity:0.24;fill:%23ABCED4;enable-background:new ;%7D .st2%7Bfill:url(%23SVGID_1_);%7D%0A%3C/style%3E%3Ctitle%3EConfirmacion elementos mobile%3C/title%3E%3Cg id='Back'%3E%3Crect x='1' y='2.9' class='st0' width='412.9' height='731.9'/%3E%3Cpath class='st1' d='M272,735.6c0,0,76.6-58.9,141.6-79.6V736L272,735.6z'/%3E%3ClinearGradient id='SVGID_1_' gradientUnits='userSpaceOnUse' x1='-15.0685' y1='718.6998' x2='471.5184' y2='232.113' gradientTransform='matrix(1 0 0 -1 0 735.8898)'%3E%3Cstop offset='0' style='stop-color:%23839DBE'/%3E%3Cstop offset='0.1262' style='stop-color:%236F8ACD'/%3E%3Cstop offset='0.3409' style='stop-color:%235470E2'/%3E%3Cstop offset='0.5299' style='stop-color:%234361EF'/%3E%3Cstop offset='0.672' style='stop-color:%233D5BF4'/%3E%3C/linearGradient%3E%3Cpath class='st2' d='M2.1,480.8c0,0,171,129.7,412.1,80.3V0H2.1C2.1,0-0.3,480.8,2.1,480.8z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  logo: {
    height: 53,
    marginTop: 10,
    [theme.breakpoints.down(601)]: {
      height: 30,
    },
  },
  textTitle: {
    marginTop: "24px !important",
    color: "white",
    textAlign: "center",
    [theme.breakpoints.down(601)]: {
      fontSize: "24px !important",
    },
  },
  text24Hours: {
    marginTop: "24px !important",
    color: theme.palette.primary.white,
    textAlign: "center",
    paddingLeft: "40px !important",
    paddingRight: "40px !important",
    [theme.breakpoints.down(701)]: {
      fontSize: "23px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "19px !important",
    },
  },
  textAmountView: {
    textAlign: "center",
    width: 200,
    marginTop: "16px !important",
    color: theme.palette.primary.white,
    borderBottom: "2px solid white",
    fontWeight: "bold",
    letterSpacing: 1.5,
    [theme.breakpoints.down(701)]: {
      fontSize: "28px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "25px !important",
    },
  },
  boxMonthly: {
    background: `url(${selectedNotImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: 150,
    width: 150,
    borderRadius: "50%",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    border: "0.5px solid #FFFFFF",
    marginTop: 30,
    marginBottom: 30,
  },
  textMonthly: {
    textAlign: "center",
    color: theme.palette.primary.white,
    fontSize: "4.75rem !important",
  },
  textMonth: {
    textAlign: "center",
    color: theme.palette.primary.white,
    fontWeight: "600 !important",
    marginTop: "-15px !important",
  },
  textPayment: {
    color: theme.palette.primary.white,
    fontWeight: "700 !important",
    marginTop: "-30px !important",
  },
  textAmount: {
    color: theme.palette.primary.white,
    fontWeight: "700 !important",
    marginBottom: "20px !important",
  },
  textPassed: {
    color: theme.palette.primary.white,
    textAlign: "center",
    lineHeight: "1.2 !important",
    paddingLeft: 32,
    paddingRight: 32,
    marginBottom: "24px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "15px !important",
    },
  },
  button: {
    height: 45,
    width: 220,
    textTransform: "none !important",
    marginTop: "16px !important",
    marginBottom: "16px !important",
    background: theme.palette.primary.buttonUnselected,
    "&:hover": {
      background: "#1976d2 !important",
    },
    "&.Mui-disabled": {
      background: theme.palette.primary.gray + " !important",
    },
    borderRadius: "10px !important",
  },
  textEmail: {
    textAlign: "center",
    color: theme.palette.primary.white,
    marginBottom: 3,
    paddingLeft: 4,
    paddingRight: 4,
    [theme.breakpoints.down(601)]: {
      fontSize: "15px !important",
    },
  },
  email: {
    color: theme.palette.primary.white + " !important",
    padding: "0 !important",
    fontWeight: "bold !important",
    fontSize: "26px !important",
    textTransform: "initial !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "20px !important",
    },
  },
  gridStyle: {
    marginTop: 32,
    marginBottom: 30,
  },
  gridText: {
    color: theme.palette.primary.white,
    textAlign: "center",
    lineHeight: "1.75 !important",
    "& > a": {
      textDecoration: "none",
      color: theme.palette.primary.white,
    },
  },
  lightBlueColor: {
    color: theme.palette.primary.lightBlue,
  },
  payNowGradientButtonStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 8,
    minWidth: "112px",
    minHeight: "40px",
    padding: "4px 30px",
    border: "none",
    fontFamily: "SFUIText-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "1.2",
    color: theme.palette.primary.white,
    backgroundImage: "linear-gradient(94deg, #ABCED4,#4893CC)",
    margin: "0px 0px 0px 15px",
    cursor: "pointer",
  },
}));

export default CreditConfirmationStyles;
