import { FC, useRef } from "react";
import { Box } from "@mui/material";
// import PauseCircleRoundedIcon from "@mui/icons-material/PauseCircleRounded";
import PlayCircleRoundedIcon from "@mui/icons-material/PlayCircleRounded";

import video2 from "../../../assets/home/video2.svg";
import { urls } from "../../../config/url";

import useStyle from "./styles";

export interface CenterVideoProps {
  handleClick?: any;
}
export const CenterVideo: FC<CenterVideoProps> = ({ handleClick }) => {
  const css = useStyle();
  const vidRef = useRef<any>(null);

  return (
    <Box className={css.video2} position="relative">
      <video
        width="100%"
        height="100%"
        poster={video2}
        ref={vidRef}
        muted
        autoPlay
        loop
      >
        <source
          src={`${urls.s3Video}/Loop+player+Medsi+new+web.mp4`}
          type="video/mp4"
        />
      </video>
      <Box className={css.contentVideo2}>
        <Box className="first">
          Un giro completo para tu salud y la de tu familia
        </Box>
        <Box mt={2}>
          <PlayCircleRoundedIcon
            onClick={handleClick}
            className={css.pauseIcon}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CenterVideo;
