import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import buttonLoader from "../../../../../assets/loader.svg";
import Loading from "../../../../../components/Loading";
import ButtonComponent from "../../../../../components/sharedComponents/ButtonComponent";
import DateInputComponent from "../../../../../components/sharedComponents/DateInputComponent";
import DropdownMenu from "../../../../../components/sharedComponents/DropdownMenu";
import InputNumber from "../../../../../components/sharedComponents/InputNumber";
import InputText from "../../../../../components/sharedComponents/InputText";
import MultiSelectComponent from "../../../../../components/sharedComponents/MultiSelectComponent";
import {
  getCreditMinMax,
  getStepperProcedureInfo,
  stepperPendingProcedureInfo,
} from "../../../../../features/installmentLoan/store/installmentLoan";
import { useAppDispatch } from "../../../../../store";
import useStore from "../../../../../store/getStore";
import {
  getProcedureToPerform,
  getProcedureType,
  getSpecialtyType,
} from "../../../../../store/slices/onboarding/actions";
import { currencyFormat } from "../../../../../utils";
import { errorToast, successToast } from "../../../../../utils/toast";

import "../../../../../../src/input.css";
import { getPayload, setValueFromResponse, validateForm } from "./helpers";
import useStyles from "./styles";

import { STEPPER_NAME } from "..";
import { API_STATUS, URBANIST_FONT } from "../../../../../constants/common";
import useConditionCheck from "../../../../../hooks/useConditionCheck";
import {
  getApplicationStepper,
  selectDashboardDetails,
  selectStepperProcedureInfo,
  selectStepperStatus,
} from "../../../../../store/slices/users";
import {
  getMaxMinAmount,
  isOtherShow,
  isProcedureShow,
  removeDateError,
  removeDrError,
} from "../../../../common/ProcedureForm/helpers";
import RenderClinicDetails from "./RenderClinicDetails";
import RenderDoctorDetails from "./RenderDoctorDetails";
import RenderTime from "./RenderTime";

const FormComponent = ({
  loanDetailsStepper,
  setProcedureCost,
  setOpenAccordionTitle,
}) => {
  const [showLoader, setLoader] = useState(false);
  const css = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [application_id, setAppId] = useState();

  const [isData, setisData] = useState(false);
  const { onboarding, installmentLoan } = useStore();
  const {
    specialtyList,
    procedureList,
    procedureToPerformList,
    clinicList,
    branchList,
    doctorList,
  } = onboarding;

  const dashboardDetails = useSelector(selectDashboardDetails);

  const stepperStatus = useSelector(selectStepperStatus);

  const disableEdit =
    stepperStatus?.application_status === "APPROVED" ||
    stepperStatus?.application_status === "LEGAL_REVIEW" ||
    stepperStatus?.application_status === "DISBURSED";

  const isFormEditable =
    dashboardDetails?.procedure_status === "Aprobado" ? false : true;

  const { productDetails = {} } = installmentLoan;

  const [validResponse, setValidResponse] = useState(true);
  const [hasNotDate, setHasNotDate] = useState(false);
  const [wasInitialized, setWasInitialized] = useState(false);
  const [hasOtherProcess, setHasOtherProcess] = useState(false);
  const [hasNotDoctor, setHasNotDoctor] = useState(false);
  const [message, setMessage] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isFirstProcedureType, setIsFirstProcedureType] = useState(true);
  const [isFirstProcedureList, setIsFirstProcedureList] = useState(true);
  const [isFirstLoanAmount, setIsFirstLoanAmount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableDoctorInfo, setDisableDoctorInfo] = useState(false);

  console.log({ responseData });

  const validate = (values) => {
    setWasInitialized(true);
    return validateForm({
      values,
      hasNotDoctor,
      touched,
      hasNotDate,
      maxCreditAmt,
      minCreditAmount,
      hasOtherProcess,
      setHasOtherProcess,
      procedureToPerformList,
      branchList,
      procedureList,
      clinicList,
    });
  };

  const formik = useFormik({
    initialValues: {
      medicName: "",
      medicLastName: "",
      medicRelation: "",
      medicEmail: "",
      medicPhone: "",
      appointmentDate: "",
      appointmentTime: "",
      procType: "",
      procToDo: [],
      otherProc: "",
      procedureCost: "",
      requestedAmount: "",
      hours: "--",
      minutes: "--",
      time: "--",
      clinicName: "",
      clinicBranch: "",
      specialtyType: "",
      otherClinicName: "",
      clinicDr: "",
    },
    validate,
    onSubmit: async (values) => {
      //   GTMClick(dataProcedurePage.continue);
      const payload = getPayload({
        values,
        hasNotDate,
        responseData,
        procedureToPerformList,
      });
      setLoader(true);
      const { status, meta } = await dispatch(
        stepperPendingProcedureInfo({
          ...payload,
          application_id: application_id,
          isEdit: !!responseData?.proc_id,
        })
      ).unwrap();

      if (status === API_STATUS.ERROR) {
        setLoader(false);
      }
      if (status === API_STATUS.SUCCESS) {
        successToast("Sus datos se guardaron correctamente");
        setLoader(false);
        dispatch(getApplicationStepper(application_id));
        dispatch(getStepperProcedureInfo(application_id));
        setOpenAccordionTitle(STEPPER_NAME.LOAN_DETAILS);
      } else {
        errorToast(meta?.errorMessage);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

  useEffect(() => {
    formik.validateForm();
  }, [
    values.specialtyType,
    values.procType,
    procedureToPerformList,
    hasNotDoctor,
    hasNotDate,
  ]);
  const urlId = window.location.search.slice(1, window.location.search.length);

  useEffect(() => {
    if (!dashboardDetails) {
      // dispatch(getDashboardDetails());
    }
    if (loanDetailsStepper !== null || urlId) {
      setAppId(urlId || loanDetailsStepper?.application_id);
      // formik.setFieldValue("requestedAmount", loanDetailsStepper?.req_amt);
    } else {
      setAppId(localStorage.getItem("application_id"));
    }
  }, []);
  useEffect(() => {
    if (loanDetailsStepper?.req_amt) {
      formik.setFieldValue("requestedAmount", loanDetailsStepper?.req_amt);
    }
  }, [loanDetailsStepper]);

  useEffect(() => {
    if (dashboardDetails?.procedure_status === "Aprobado") {
      setValidResponse(false);
    } else {
      setValidResponse(true);
    }
  }, [dashboardDetails]);

  const { maxCreditAmt, minCreditAmount } = getMaxMinAmount({
    productDetails,
    values,
    clinicList,
  });

  let isValidForm = Object.entries(errors).length === 0;

  const getData = async () => {
    const { data, status } = await dispatch(
      getStepperProcedureInfo(application_id)
    ).unwrap();
    if (status === API_STATUS.SUCCESS) {
      const updatedValues = setValueFromResponse({
        responseValue: data,
        values,
      });
      setValues({ ...values, ...updatedValues });

      setTimeout(() => {
        setFieldValue("medicEmail", updatedValues.medicEmail);
        setFieldValue("medicPhone", updatedValues.medicPhone);
      }, 500);
      setResponseData(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (application_id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [application_id]);

  useEffect(() => {
    dispatch(getSpecialtyType());
    dispatch(getCreditMinMax());
  }, []);

  const stepperProcedureInfo = useSelector(selectStepperProcedureInfo);

  useEffect(() => {
    if (
      stepperProcedureInfo &&
      Object.keys(stepperProcedureInfo).length !== 0
    ) {
      setisData(true);
    } else {
      setisData(false);
    }
  }, [stepperProcedureInfo]);

  useEffect(() => {
    if (values?.specialtyType) {
      if (!isFirstProcedureType) {
        values.procToDo = [];
      } else {
        setIsFirstProcedureType(false);
      }
      dispatch(getProcedureType(values?.specialtyType));
    }
  }, [values?.specialtyType]);

  useEffect(() => {
    if (values?.procType) {
      if (!isFirstProcedureList) {
        values.procToDo = [];
      } else {
        setIsFirstProcedureList(false);
      }
      dispatch(getProcedureToPerform(values?.procType));
    }
  }, [values?.procType]);

  useEffect(() => {
    if (values?.clinicName) {
      if (+responseData?.requested_loan_amount !== +values.requestedAmount) {
        if (!isFirstLoanAmount) {
          // formik.setFieldValue("requestedAmount", "");
        } else {
          setIsFirstLoanAmount(false);
        }
      }
    }
  }, [values?.clinicName, responseData]);

  const canShowOtherInput = useConditionCheck(
    isOtherShow({
      values,
    })
  );

  const isProdShow =
    useConditionCheck(
      isProcedureShow({
        values,
      })
    ) && procedureList?.length > 0;

  const setCheckValue = (e, check) => {
    switch (check) {
      case "hasNotDoctor":
        setHasNotDoctor(e.target.checked);
        removeDrError({ values, errors });
        break;
      case "hasNotDate":
        setHasNotDate(e.target.checked);
        removeDateError({ values, errors });
        break;
      default:
        throw new Error("Something happened");
    }
  };

  const handleChangeMultiple = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      "procToDo",
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCreditLimit = (amount) => {
    setProcedureCost(amount);
    // if (parseInt(amount) > maxCreditAmt) {
    //   formik.setFieldValue("requestedAmount", maxCreditAmt);
    //   setMessage(true);
    // } else {
    //   formik.setFieldValue("requestedAmount", amount);
    //   setMessage(false);
    //   delete errors.procedureCost;
    // }
  };

  const renderInput = ({ label, value, disabled = false }) => {
    return (
      <InputText
        stylesInput={css.formControl}
        label={label}
        value={values[value]}
        name={value}
        handleChange={handleChange}
        errors={errors[value]}
        touched={touched[value]}
        handleBlur={handleBlur}
        disabled={disabled}
      />
    );
  };

  const renderHasCheck = ({ label, value, validResponse }) => {
    return (
      <FormControl className={`${css.checkBoxContainer} ${css.formControl}`}>
        <FormControlLabel
          control={
            <Checkbox
              name={`exist${value}`}
              size="small"
              onChange={(e) => setCheckValue(e, value)}
              disabled={!validResponse}
            />
          }
          label={
            <Typography
              className={`${css.checkboxLabel} ${
                validResponse ? "" : css.labelColor
              } `}
              variant="h5"
            >
              {label}
            </Typography>
          }
        />
      </FormControl>
    );
  };

  const renderDropDown = ({ label, value, dataObj, disabled = false }) => {
    return (
      <DropdownMenu
        label={label}
        optionsObject={dataObj}
        name={value}
        handleChange={handleChange}
        value={values[value]}
        onBlur={handleBlur}
        errors={errors[value]}
        touched={touched[value]}
        disabled={disabled}
      />
    );
  };

  const renderNumberInput = ({ label, value, setField, message, ...rest }) => {
    return (
      <Grid item xs={12} md={6}>
        <InputNumber
          formControlStyles={css.formControl}
          textLabel={label}
          name={value}
          value={values[value]}
          prefix="$"
          errors={errors[value]}
          touched={touched[value]}
          setField={(_, amount) => {
            setField && setField(_, amount);
          }}
          field={value}
          handleBlur={handleBlur}
          {...rest}
        />
        {message || null}
      </Grid>
    );
  };

  if (!loading) {
    return (
      <Box>
        <Box
          sx={(theme) => ({
            color: "#6B7779",
            fontFamily: "SFUIText-bold",
            fontSize: "24px",
            marginBottom: "35px",
            [theme.breakpoints.down("lg")]: {
              fontFamily: URBANIST_FONT.BOLD,
              fontSize: "21px",
              mb: "1.8rem",
            },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "18px",
              mb: "1em",
            },
          })}
        >
          Información del Procedimiento
        </Box>
        <form onSubmit={handleSubmit} className="formWrapper">
          <Box className={css.formBox}>
            <Grid container columnSpacing={6.25} rowSpacing={2}>
              <Grid item xs={12} md={6}>
                {renderNumberInput({
                  label: "Costo del procedimiento",
                  value: "procedureCost",
                  // disabled: !validResponse,

                  setField: (_, amount) => {
                    setFieldValue("procedureCost", amount);
                    handleCreditLimit(amount);
                  },
                  message: message && (
                    <FormHelperText style={{ width: "320px", marginTop: 0 }}>
                      {`Por el momento, el monto máximo del crédito que podemos
                          otorgarte es de $${currencyFormat(maxCreditAmt)}`}
                    </FormHelperText>
                  ),
                })}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderNumberInput({
                  label: "Monto del crédito solicitado",
                  value: "requestedAmount",
                  disabled: true,

                  setField: (_, amount) => {
                    if (Number(amount) > maxCreditAmt) {
                      formik.setFieldValue("requestedAmount", maxCreditAmt);
                    } else {
                      formik.setFieldValue("requestedAmount", amount);
                    }
                  },
                })}

                <Box className={css.límite}>
                  <div style={{ marginBottom: 10 }}>
                    Para editar el monto del crédito solicitado, vaya a la
                    sección Detalles del Préstamo
                  </div>
                  <div>
                    El límite de $80,000 aplica solamente para ciertos socios
                    comerciales; otras clínicas y médicos pueden estar sujetas a
                    un límite de crédito menor.
                  </div>
                </Box>
              </Grid>

              {/* Here */}
              <RenderClinicDetails
                formik={formik}
                responseData={responseData}
                // disabled={!validResponse}
              />
              <Grid item xs={12} md={12}>
                {renderHasCheck({
                  label: " No he elegido a un médico aún",
                  value: "hasNotDoctor",
                  validResponse: validResponse,
                  doctorDisable: disableDoctorInfo,
                })}
              </Grid>

              <RenderDoctorDetails
                formik={formik}
                responseData={responseData}
                // disabled={!validResponse}
                hasNotDoctor={hasNotDoctor}
              />

              <Grid item xs={12} md={12}>
                {renderHasCheck({
                  label: " No tengo cita programada aún",
                  value: "hasNotDate",
                  validResponse: validResponse,
                })}
              </Grid>

              <Grid item xs={12} md={6}>
                <DateInputComponent
                  formStyles={css.formControl}
                  labelTitle="Fecha de la cita"
                  name="appointmentDate"
                  dateValue={values.appointmentDate}
                  handleDate={(value) => {
                    setFieldValue("appointmentDate", value);
                  }}
                  hasDate={hasNotDate}
                  errors={errors.appointmentDate}
                  touched={touched.appointmentDate}
                  onBlur={handleBlur}
                  // disabled={!validResponse || hasNotDate}
                  disabled={hasNotDate}
                  disablePast
                  minDate={new Date()}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RenderTime
                  formik={formik}
                  hasNotDate={hasNotDate}
                  // disabled={!validResponse}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="dropdownFix">
                  <DropdownMenu
                    label={"Especialidad médica"}
                    optionsObject={specialtyList || []}
                    name={"specialtyType"}
                    handleChange={(...rest) => {
                      handleChange(...rest);
                      formik.setFieldValue("procType", "");
                      formik.setFieldValue("procToDo", []);
                      formik.setFieldValue("otherProc", "");
                    }}
                    value={values.specialtyType}
                    onBlur={handleBlur}
                    errors={errors.specialtyType}
                    touched={touched.specialtyType}
                    // disabled={!validResponse}
                  />
                </div>
              </Grid>

              {isProdShow && (
                <Grid item md={6} xs={12} className={css.titleLableTime}>
                  {renderDropDown({
                    label: "Tipo de procedimiento",
                    value: "procType",
                    dataObj: procedureList,
                    // disabled: !validResponse,
                  })}
                </Grid>
              )}
              {!canShowOtherInput && procedureToPerformList?.length > 0 && (
                <Grid item md={6} xs={12}>
                  <MultiSelectComponent
                    labelTitle="Procedimiento(s) a realizar"
                    multiSelectValue={values.procToDo}
                    handleChangeMultiple={handleChangeMultiple}
                    options={procedureToPerformList || []}
                    errors={errors.procToDo}
                    touched={touched.procToDo}
                    onBlur={handleBlur}
                    name="procToDo"
                    // disabled={!validResponse}
                  />
                </Grid>
              )}
              {canShowOtherInput && (
                <Grid item md={6} xs={12}>
                  {renderInput({
                    label: "Indicar otro(s) procedimiento(s)",
                    value: "otherProc",
                    // disabled: !validResponse,
                  })}
                </Grid>
              )}
            </Grid>

            <Box justifyContent="center" display="flex" mt={4}>
              <ButtonComponent
                type="submit"
                text="Enviar"
                disabled={!isValidForm || disableEdit}
              />
              {showLoader && (
                <div style={{ marginTop: 4 }}>
                  <img src={buttonLoader} alt="" />
                </div>
              )}
            </Box>
          </Box>
        </form>
      </Box>
    );
  }

  return <Loading />;
};

export default FormComponent;
