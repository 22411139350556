import { Box, Checkbox, Grid, Typography } from "@mui/material";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";

import moment from "moment";
import { useState } from "react";
import FooterLine from "../../../assets/header/footerLine.svg";
import Logo from "../../../assets/Logos/gradient-logo.svg";
import StepperAcceptTermCondtion from "../../../components/ModalContents/StepperTermsandCondition";
import { URBANIST_FONT } from "../../../constants/common";
import { ROUTES } from "../../../constants/routes";
import Button from "../../../features/installmentLoan/components/Button";
import useStyles from "./styles";

const ThankYouPage = () => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const handleOpen = (e) => {
    setOpen(!open);
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      textAlign="center"
      className={classes.content}
    >
      <img src={Logo} className={classes.logo} alt="logo" />

      <Typography className={classes.textTitle}>Muchas gracias</Typography>
      <Typography className={classes.message} noWrap>
        Su consentimiento ha sido enviado con éxito en nuestro sistema
      </Typography>
      <Typography className={classes.contactInfo}>
        Si tienes alguna duda, escribenos a
        <Typography className="contact-email">contacto@medsi.mx</Typography>
      </Typography>

      <div
        style={{
          overflow: "hidden",
          width: "100%",
          maxWidth: "100vw",
          margin: "39px auto 0",
        }}
      >
        <img
          src={FooterLine}
          style={{
            height: 138,
          }}
          alt="img blanco"
        />
      </div>

      <Grid container maxWidth="923px">
        <Grid
          item
          // md={2.5}
          md={4}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          mt={15}
        >
          <Typography className={classes.footerTextStyle} translate="no">
            CENTRO DE AYUDA
          </Typography>
        </Grid>
        <Grid
          item
          // md={2.5}
          md={4}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          mt={2}
        >
          <Button
            label="Términos y Condiciones"
            variant="text"
            className={classes.footerTextStyle}
            onClick={() => {
              window.open(ROUTES.tnc, "_blank");
            }}
          />
        </Grid>
        <Grid
          item
          // md={2.5}
          md={4}
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          mt={2}
        >
          <Button
            label="Aviso de Privacidad"
            variant="text"
            className={classes.footerTextStyle}
            onClick={() => {
              window.open(ROUTES.privacyPolicy, "_blank");
            }}
          />
        </Grid>
      </Grid>
      <Typography
        className={classes.footerTextStyle}
        translate="no"
        mt={5}
        mb={2}
      >
        © medsi 2023 Todos los derechos reservados
      </Typography>
    </Box>
  );
};

export default ThankYouPage;
