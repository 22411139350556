import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  content: {
    "& .description": {
      padding: `0 ${theme.spacing(5)} 0 0 !important`,
    },
    "& div": {
      fontSize: "14px !important",
      fontFamily: "SFUIText-Regular !important",
      fontWeight: "400 !important",
    },
    "& .title": {
      fontWeight: "600 !important",
    },
    "& a": {
      textDecoration: "none",
    },
  },
}));

export default useStyles;
