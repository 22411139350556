import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory, Link } from "react-router-dom";

import { currencyFormat } from "../../../../utils";
import { PREFERENCE_TYPE } from "../../../../constants/generals";
import InputNumber from "../../../../components/sharedComponents/InputNumber";
import GradientButton from "../../../../components/GradientButton";
import { GTMClick } from "../../../../utils/gtm";
import { homePage } from "../../../../constants/gtm";
import { i18nDate } from "../../../../utils/formatter";
import { ROUTES } from "../../../../constants/routes";
import useStyles from "./styles";

const termList = [3, 6, 12];

const Row = ({ title, rightContent }) => {
  return (
    <Box>
      <Box className={"leftContent"}>{title}</Box>
      <Box className={"rightContent"}>{rightContent}</Box>
    </Box>
  );
};

export const Calculator: FC = () => {
  const css = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const langPrefix = "instantLineCreditTS.homeV2.calculator";

  const [procedureType, setProcedureType] = useState("0");
  const [selectedTerm, setSelectedTerm] = useState(3);
  const [amount, setAmount] = useState(10000);
  const [preferenceType, setPreferenceType] = useState(PREFERENCE_TYPE.MONTHLY);

  const handleAmount = (value) => {
    if (!isNaN(value)) {
      setAmount(value);
    } else {
      setAmount(0);
    }
  };

  const handlePreferenceType = ({ target }) => {
    setPreferenceType(target?.value);
  };

  const handleProcedureType = ({ target }) => {
    setProcedureType(target?.value);
  };

  const handleRegisterClick = () => {
    GTMClick(homePage.signUpHeader);
    history.push("/registro");
  };

  const calculateAmount = (p, term) => {
    const ir = 40;
    if (p > 0 && term > 0 && !isNaN(p) && !isNaN(term)) {
      const pa = +p;
      const a = pa + ((pa * ir) / (100 * 12)) * term;
      const vat = (a - pa) * 0.16;
      const totalAmt = vat + a;
      const emi =
        totalAmt / term / (PREFERENCE_TYPE.MONTHLY === preferenceType ? 1 : 2);

      return {
        totalAmt,
        emi,
      };
    }
    return {};
  };

  const { emi = 0, totalAmt = 0 } = calculateAmount(amount, selectedTerm);

  const renderTerm = () => {
    return termList?.map((item) => (
      <div
        key={`term_${item}`}
        className={item === selectedTerm ? "selected" : ""}
        onClick={() => setSelectedTerm(item)}
      >
        {t(`${langPrefix}.months`, { term: item })}
      </div>
    ));
  };

  const renderPreference = () => {
    return (
      <select
        name={"preferenceType"}
        onChange={handlePreferenceType}
        value={preferenceType}
        className={css.select}
      >
        <option value={PREFERENCE_TYPE.MONTHLY}>Mensual</option>
        <option value={PREFERENCE_TYPE.BI_WEEKLY}>Quincenal</option>
      </select>
    );
  };

  const renderProcedureType = () => {
    return (
      <select
        name={"procedureType"}
        onChange={handleProcedureType}
        value={procedureType}
        className={css.select}
      >
        <option value="0">No quirúrgico / En consultorio</option>
        <option value="1">Quirúrgico / En consultorio</option>
        <option value="2">Quirúrgico / Quirófano</option>
      </select>
    );
  };

  const term =
    selectedTerm * (PREFERENCE_TYPE.MONTHLY === preferenceType ? 1 : 2);
  const currDate = moment().format("yyyy-MM-DD hh:mm:s");
  const dateCalc = i18nDate(currDate, "es").format("D [de] MMMM [del] yyyy");
  return (
    <Box className={css.container}>
      <Box className={css.title} pl={4}>
        Simula tu crédito
      </Box>
      <Box className={css.calculator}>
        <Row
          title={t(`${langPrefix}.list.amountTitle`)}
          rightContent={
            <InputNumber
              formControlStyles={css.formControl}
              name={"number"}
              value={amount}
              prefix="$"
              setField={(_, amount) => {
                handleAmount && handleAmount(amount);
              }}
              infoText={
                amount > 80000 ? (
                  <div className={css.infoText}>
                    Por el momento, el monto máximo que podemos financiar es de
                    $80,000. Incrementaremos estos montos en el futuro cercano.
                  </div>
                ) : null
              }
            />
          }
        />

        <Row
          title={t(`${langPrefix}.list.paymentFrequency`)}
          rightContent={<Box className={css.termView}>{renderTerm()}</Box>}
        />

        <Row
          title={t(`${langPrefix}.list.paymentPreference`)}
          rightContent={renderPreference()}
        />

        <Row
          title={t(`${langPrefix}.list.procedureType`)}
          rightContent={renderProcedureType()}
        />

        <Row
          title={
            <Box>
              <span>{term}</span>
              {t(`${langPrefix}.paymentOf`)}
              <Box> ${currencyFormat(emi)}</Box>
            </Box>
          }
          rightContent={
            <Box width="90%" display="flex" justifyContent="flex-end">
              <GradientButton
                customCss={css.gradientButton}
                title="Solicitar crédito"
                handleClick={handleRegisterClick}
              />
            </Box>
          }
        />
      </Box>
      <Box className={css.infoText2}>
        <Typography
          sx={{
            fontFamily: "Urbanist-Medium",
            fontSize: 16,
          }}
        >
          El límite de $80,000 aplica solamente para ciertos socios comerciales;
          otras clínicas y médicos pueden estar sujetas a un límite de crédito
          menor.
        </Typography>
      </Box>
      <Box className={css.infoText3} mt={1}>
        <span
          style={{
            fontFamily: "Urbanist-Medium",
            fontSize: 16,
          }}
        >
          {t(`${langPrefix}.info`, {
            currDate: dateCalc,
            month: selectedTerm,
            plan:
              PREFERENCE_TYPE.MONTHLY === preferenceType
                ? "mensual"
                : "quincenal",
            amount: `$${currencyFormat(amount)}`,
            totalAmt: `$${currencyFormat(totalAmt)}`,
          })}
        </span>
        <Box mt={1}>
          <Link to={ROUTES.tnc} target="_blank" style={{ textDecoration: 'none' }} >
            <Typography
              sx={{
                fontFamily: "Urbanist-Medium",
                fontSize: 16,
                color: "#0ACC97",
              }}
            >
              Consulta Términos y Condiciones.
            </Typography>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Calculator;
