import React from "react";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import quirofanoIcon from "../../../../assets/procedureType/Quirofano.svg";
import consultorioIcon from "../../../../assets/procedureType/Consultorio.svg";
import PlasticSurgeryStyles from "./PlasticSurgery.styles";
import HairRestauration from "../HairRestauration";
const PlasticSurgery = () => {
  const classes = PlasticSurgeryStyles();

  const CustomListItem = ({ children, style }) => (
    <ListItem style={style}>
      <Typography
        sx={{
          fontFamily: "Urbanist-Medium",
          fontSize: 14,
          color: `rgba(0, 0, 0, 0.5)`,
        }}
      >
        {children}
      </Typography>
    </ListItem>
  );

  const listItemsQuirurgicoQuiroFirstSection = [
    "Abdominoplastia (Cirugía de Abdomen)",
    "Aumento y/o Modelado de Gluteos",
    "Braquioplastia",
    "Ginecomastia e Implante de pectoral (En hombres, aumento o reducción de pectorales)",
    "Levantamiento de senos",
  ];
  const listItemsQuirurgicoQuiroSecondSection = [
    "Abdominoplastia (Cirugía de Abdomen)",
    "Aumento y/o Modelado de Gluteos",
    "Braquioplastia",
    "Ginecomastia e Implante de pectoral (En hombres, aumento o reducción de pectorales)",
    "Levantamiento de senos",
  ];

  const listItemsQuirurgicoConsulFirstSection = [
    "Bichectomia (Cirugía de Mejillas)",
    "Blefaroplastia (Cirugía de Párpados)",
    "Cervicoplastia (Lifting de Cuello)",
    "Contorno Facial",
    "Frontoplastia (Reducción de Frente Prominente y/o Elevación de Cejas)",
  ];
  const listItemsQuirurgicoConsulSecondSection = [
    "Otoplastia (Cirugía de Orejas)",
    "Ritidoplastia (Estiramiento Facial)",
    "Mentoplastia (Cirugía de Mentón)",
  ];

  const HeroSection = (props) => {
    const {
      mainTitle,
      heroIcon,
      heroTitle,
      heroListItemsFirst,
      heroListItemsSecond,
    } = props;

    return (
      <Box
        sx={{
          paddingLeft: 15,
          paddingRight: 15,
          marginTop: 10,
          
        }}
      >
        {mainTitle && (
          <Typography
            sx={{
              fontFamily: "Urbanist-SemiBold",
              fontSize: 36,
              color: "#000000",
              opacity: "0.5",
              marginBottom: 6,
            }}
          >
            {mainTitle}
          </Typography>
        )}
        <Grid container className={classes.optionsContainer}>
          <Grid item xs={12} md={4}>
            <img src={heroIcon} alt="heroIcn" />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              sx={{
                fontFamily: "Urbanist-SemiBold",
                fontSize: 22,
                marginBottom: 2,
              }}
            >
              {heroTitle}
            </Typography>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontSize: 10,
                },
              }}
            >
              {heroListItemsFirst.map((item) => (
                <CustomListItem>{item}</CustomListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={4}>
            <List
              sx={{
                listStyleType: "disc",
                pl: 2,
                "& .MuiListItem-root": {
                  display: "list-item",
                  color: "rgba(0, 0, 0, 0.5)",
                  fontSize: 10,
                },
              }}
            >
              {heroListItemsSecond.map((item, index) =>
                index === 0 ? (
                  <CustomListItem style={{ marginTop: 45 }}>
                    {item}
                  </CustomListItem>
                ) : (
                  <CustomListItem>{item}</CustomListItem>
                )
              )}
            </List>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <Box className={classes.mainContainer}>
      <HeroSection
        mainTitle="Cirugía Plástica"
        heroIcon={quirofanoIcon}
        heroTitle="Quirúrgico / En Quirófano"
        heroListItemsFirst={listItemsQuirurgicoQuiroFirstSection}
        heroListItemsSecond={listItemsQuirurgicoQuiroSecondSection}
      />
      <HeroSection
        heroIcon={consultorioIcon}
        heroTitle="Quirúrgico / En consultorio"
        heroListItemsFirst={listItemsQuirurgicoConsulFirstSection}
        heroListItemsSecond={listItemsQuirurgicoConsulSecondSection}
      />
      <HairRestauration/>
    </Box>
  );
};

export default PlasticSurgery;
