import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddCircle from '@mui/icons-material/AddCircle';
import { useHistory } from 'react-router-dom';
import {
  API_STATUS,
  API_STATUS_REQUEST,
  URBANIST_FONT,
} from '../../../constants/common';
import { INSTALLMENT_LOAN_ROUTES, ROUTES } from '../../../constants/routes';
import { getBalanceSummary } from '../../../features/installmentLoan/store/installmentLoan';
import {
  deleteApplicationId,
  getAllApplication,
  getCreditDecision,
} from '../../../store/slices/users/actions';
import { errorToast, successToast } from '../../../utils/toast';
import TermConditionTextStyles from './TermsAndConditions.styles';

const DeleteConfirmation = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { setOpen, application_id, redirect, fetchLmsBalanceSummary } = props;
  const classes = TermConditionTextStyles();

  const handleDelete = async () => {
    const res = await dispatch(deleteApplicationId(application_id));
    if (res?.payload?.status === API_STATUS.SUCCESS) {
      successToast(API_STATUS_REQUEST[res?.payload?.status]);
      setOpen(false);
      fetchLmsBalanceSummary();
      dispatch(getCreditDecision());
      dispatch(getAllApplication());
      if (!redirect) {
        return;
      } else {
        history.push(`${INSTALLMENT_LOAN_ROUTES.MAIN}`);
      }
    } else {
      errorToast(res?.payload?.message);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.mainContainer}>
      <AddCircle
        sx={(theme) => ({
          color: '#3552CC',
          transform: 'rotate(45deg)',
          position: 'absolute',
          right: 20,
          top: 20,
          zIndex: 20,
          cursor: 'pointer',
          [theme.breakpoints.down('lg')]: {
            right: '30px',
            top: '25px',
          },
        })}
        onClick={handleClose}
      />
      <Box
        sx={(theme) => ({
          color: '#3552CC',
          fontFamily: 'SFUIText-bold',
          fontSize: '36px',
          marginTop: '79px',
          [theme.breakpoints.down('lg')]: {
            fontSize: '24px',
            fontFamily: URBANIST_FONT.BOLD,
            mt: '3rem',
          },
        })}
      >
        ¿Estás seguro de que deseas eliminar esta aplicación?
      </Box>
      <Box
        sx={(theme) => ({
          alignItems: 'center',
          margin: '29px 0',
          [theme.breakpoints.down('tablet')]: { fontSize: '14px' },
        })}
      >
        Tenga en cuenta: una vez que confirme, esto se eliminará de forma
        permanente
      </Box>

      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '50px',
          marginTop: '20px',
          gap: '1rem',
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            mb: '2rem',
          },
        })}
      >
        <div>
          <button className={classes.cancel} onClick={handleClose}>
            Cancelar
          </button>
        </div>
        <div>
          <button className={classes.confirm} onClick={handleDelete}>
            Si, lo confirmo
          </button>
        </div>
      </Box>
    </div>
  );
};

export default DeleteConfirmation;
