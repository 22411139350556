import CloseIcon from '@mui/icons-material/Close';
import { Button, Modal, Typography } from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import { dataURLtoFile } from './helpers';
import SignaturePadStyles from './SignaturePad.styles';

import './ClientSignature.css';

const CustomSignaturePad = (props) => {
  const { setValidateImage, setImageURL, imageURL, imageObj, setImageObj } =
    props;

  const classes = SignaturePadStyles();

  const [open, setOpen] = useState(false);

  const sigCanvas = useRef({});

  const openModal = () => {
    setOpen(!open);
  };

  const clean = () => sigCanvas.current.clear();

  const save = () => {
    let emptyImage =
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC';
    let image = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    let image_obj = dataURLtoFile(image, 'signature.png');

    if (image === emptyImage) {
      setValidateImage(false);
    } else {
      setValidateImage(true);
    }
    setImageURL(image);
    setImageObj(image_obj);
  };

  console.log({ imageURL });

  return (
    <Fragment>
      <div className={classes.boxSignature} onClick={openModal}>
        {imageURL ? (
          <img src={imageURL} className={classes.imageSignature} alt='firma' />
        ) : (
          <Typography variant='h6' className={classes.textSignature}>
            Presiona para firmar
          </Typography>
        )}
      </div>
      <Modal
        open={open}
        sx={(theme) => ({
          [theme.breakpoints.down('lg')]: {
            width: '90vw',
            height: '90vh',
            margin: 'auto',
          },
        })}
      >
        <Fragment>
          <Button
            variant='contained'
            color='error'
            className={classes.buttonClose}
            onClick={openModal}
          >
            <CloseIcon />
          </Button>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: classes.signature,
            }}
          />
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonClean}
            onClick={clean}
          >
            Limpiar
          </Button>
          <Button
            variant='contained'
            color='primary'
            className={classes.buttonSave}
            onClick={async () => {
              await save();
              setOpen(false);
            }}
          >
            Aceptar
          </Button>
        </Fragment>
      </Modal>
    </Fragment>
  );
};

export default CustomSignaturePad;
