import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../../../../constants/routes";
import { selectCreditDecision } from "../../../../../store/slices/users";
import useStyles from "./style";

const KYCStepper = ({ status }) => {
  const css = useStyles();
  const history = useHistory();
  const handleKyc = () => {
    history.push(ROUTES.identityInformation);
  };

  const creditDecision = useSelector(selectCreditDecision);

  const canEditKyc = creditDecision?.user_details?.kyc_status !== "accepted";

  return (
    <div>
      <Box
        sx={(theme) => ({
          fontSize: "24px",
          fontFamily: "SFUIText-bold",
          color: "#6B7779",
          marginBottom: "1rem",
          [theme.breakpoints.down("lg")]: {
            fontFamily: "Urbanist-Bold",
            fontSize: "21px",
            lineHeight: "22px",
          },
          [theme.breakpoints.down("tablet")]: { fontSize: "18px" },
        })}
      >
        Documentos requeridos
      </Box>

      <div style={{ marginLeft: 85 }}>
        1. <span className={css.title}>Identificación-</span>{" "}
        <span className={css.value}>Eg:</span> Credencial Para Votar, Credencial
        Para Votar Desde El Extranjero, Visa De Residencia Permanente & <br />
        &nbsp; &nbsp; Licencia de conducir
        <br /> 2. <span className={css.title}>Video de verificación</span>
      </div>

      <div style={{ textAlign: "center", marginTop: 40 }}>
        {status ? (
          <button
            style={{
              opacity: canEditKyc ? 1 : 0.5,
            }}
            className={css.buttonStyle}
            onClick={() => {
              handleKyc();
            }}
            disabled={!canEditKyc}
          >
            Realizar Validación de Identidad
          </button>
        ) : (
          <button
            className={css.buttonStyle}
            onClick={() => {
              handleKyc();
            }}
          >
            Comenzar
          </button>
        )}
      </div>
    </div>
  );
};

export default KYCStepper;
