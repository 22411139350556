import { makeStyles } from "@mui/styles";

const GeolocationStyles = makeStyles((theme) => ({
  divContainer: {
    backgroundColor: theme.palette.primary.white,
  },
  mainContainer: {
    width: "80vw",
    height: "60vh",
    background: theme.palette.primary.white,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "scroll",
  },
  textWrapper: {
    padding: 20,
  },
  buttonSection: {
    backgroundColor: theme.palette.primary.white,
    height: 80,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  text: {
    [theme.breakpoints.down(1001)]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "11px !important",
    },
  },
  mt16: {
    marginTop: "16px !important",
  },
  button: {
    height: 45,
    width: 180,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
    "&.Mui-disabled": {
      height: 45,
      width: 180,
      color: theme.palette.primary.white + "!important",
      backgroundColor: theme.palette.primary.gray + " !important",
    },
  },
}));

export default GeolocationStyles;
