import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ROUTES } from "../../constants/routes";
import CreditRequestStyles from "./styles";
import { Box, Typography, Grid, Container } from "@mui/material";

import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import CircleButton from "../../components/CircleButton";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import { PREFERENCE_TYPE } from "../../constants/generals";
import { saveUseRequestedCreditInformation } from "../../store/slices/users";
import { LOAN_TNC } from "../../constants/generals";
import DropdownMenu from "../../components/sharedComponents/DropdownMenu";
import {
  creditRequestDetails,
  setSelectedTerm,
  setCreditLoading,
} from "../../features/installmentLoan/store/installmentLoan";
import { useAppDispatch } from "../../store";
import useStore from "../../store/getStore";
import { GTMClick } from "../../utils/gtm";
import { creditRequestPage } from "../../constants/gtm";
import CircularLoader from "../../components/CircularLoader";
import { API_STATUS } from "../../constants/common";
import { errorToast } from "../../utils/toast";

const MONTHLY = "Mensual";
const BI_WEEKLY = "Quincenal";
const CreditRequest = () => {
  const history = useHistory();
  const classes = CreditRequestStyles();

  const [preferencePayment, setPreferencePayment] = useState(MONTHLY);

  const dispatch = useAppDispatch();
  const { installmentLoan } = useStore();
  const {
    termDetails,
    termSelected,
    productDetails,
    requestedAmount,
    termDetailsLoading,
  } = installmentLoan;
  const prodCode = productDetails?.prodCode || "ffp";

  const handlePayment = (event) => {
    setPreferencePayment(event?.target?.value);
  };

  const handleClick = async () => {
    GTMClick(creditRequestPage.continue);
    let prefPay =
      (preferencePayment === MONTHLY && PREFERENCE_TYPE.MONTHLY) ||
      (preferencePayment === BI_WEEKLY && PREFERENCE_TYPE.BI_WEEKLY);

    const res = await dispatch(
      saveUseRequestedCreditInformation({
        req_amt: requestedAmount,
        term: termSelected?.term,
        tnc_codes: LOAN_TNC,
        repayment_duration: prefPay,
        prod_code: prodCode,
      })
    ).unwrap();

    if (res?.status === API_STATUS.SUCCESS) {
      history.push(ROUTES.clientSignature);
    } else if (res?.status === API_STATUS.ERROR) {
      errorToast(res?.meta?.errorMessage);
    }
  };

  useEffect(() => {
    dispatch(setCreditLoading(true));
    prodCode && dispatch(creditRequestDetails(prodCode));
  }, [prodCode]);

  const handleTermClick = (item) => {
    dispatch(setSelectedTerm(item));
  };

  const currencyFormat = (num) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  const validation = () => {
    if (termSelected?.term) {
      return true;
    }

    return false;
  };

  let isValid = validation();
  const isWeeklySelected = preferencePayment === BI_WEEKLY;

  return (
    <div className={classes.content}>
      <Box className={classes.headerContainer}></Box>
      <Container component="main">
        <Grid display="flex" flexDirection="column" alignItems="center">
          <img src={Logo} alt="logo" className={classes.logo} />
          <Typography variant="h2" className={classes.title}>
            Nuestras opciones para ti
          </Typography>

          <Typography variant="h3" className={classes.textCreditRequest}>
            Crédito a solicitar
          </Typography>
          <Typography variant="h2" className={classes.textAmount}>
            {requestedAmount && `$ ${currencyFormat(requestedAmount)} MXN`}
            <div className="line" />
          </Typography>
          <Typography
            variant="h2"
            className={classes.textFinancingalternatives}
          >
            Alternativas de financiamiento disponibles para ti
          </Typography>
          <Typography variant="h3" className={classes.textOption}>
            Elige una opción:
          </Typography>

          {0 ? (
            <CircularLoader />
          ) : (
            <>
              <Box mt={5}>
                <DropdownMenu
                  stylesInput={classes.formControl}
                  optionsObject={[MONTHLY, BI_WEEKLY]}
                  name={"preferenceType"}
                  handleChange={handlePayment}
                  value={preferencePayment}
                  label=" "
                  listItemStyle={classes.customCss}
                  listItemSelectedStyle={classes.listItemSelectedStyle}
                />
              </Box>
              <Box mt={5}>
                <Grid container className={classes.gridCircle}>
                  {termDetails?.map((item) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={4}
                        onClick={() => handleTermClick(item)}
                        key={item.term}
                      >
                        <CircleButton
                          monthlyInstallments={item.term}
                          amount={
                            isWeeklySelected
                              ? item["bi-weekly-amount"]
                              : item.amount
                          }
                          isWeeklySelected={isWeeklySelected}
                          selected={termSelected?.term === item?.term}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
              <Box className={classes.buttonContainer}>
                <ButtonComponent
                  onClick={handleClick}
                  text="Continuar"
                  disabled={!isValid}
                />
              </Box>
            </>
          )}
        </Grid>
      </Container>
    </div>
  );
};

export default CreditRequest;
