import { makeStyles } from '@mui/styles';

const TermsAndConditionsStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '800px',
    background: theme.palette.primary.white,
    borderRadius: '5px',
    overflow: 'hidden',
    position: 'relative',
    maxHeight: '95vh',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      maxWidth: '50vw',
      padding: '1rem 2rem',
      fontFamily: 'Urbanist-Regular',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '70vw',
    },
    [theme.breakpoints.down('tablet')]: {
      maxWidth: '90vw',
    },
  },
  buttonSection: {
    backgroundColor: theme.palette.primary.white,
    height: 80,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  button: {
    height: 45,
    width: 180,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
    '&.Mui-disabled': {
      height: 45,
      width: 180,
      color: theme.palette.primary.white + '!important',
      backgroundColor: theme.palette.primary.gray + ' !important',
    },
  },
  cancel: {
    padding: '16px 66px',
    background: '#979797',
    color: '#FFFFFF',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '100px',
      padding: '1rem 3rem',
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '14px' },
  },
  confirm: {
    padding: '16px 33px',
    background: '#3552CC',
    color: '#FFFFFF',
    borderRadius: '8px',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '100px',
      background: 'linear-gradient(90deg, #0ACC97 12.31%, #1B63DB 107.39%)',
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '14px' },
  },
}));

export default TermsAndConditionsStyles;
