import { makeStyles } from "@mui/styles";

const requestCreditLineStyles = makeStyles((theme) => ({
  doctorImage: {
    [theme.breakpoints.down("lg")]: {
      height: 280,
    },
    [theme.breakpoints.down(900)]: {
      marginLeft: -100,
    },
    [theme.breakpoints.up("lg")]: {
      height: 570,
      marginLeft: -70,
    },
  },
}));

export default requestCreditLineStyles;
