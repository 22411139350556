import { useEffect, useState } from "react";
import { Grid, Typography, Box, Button, Hidden } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import {
  selectApplicantInfo,
  selectCoSignerToken,
  applicationSummary,
  resetCosingerInfo,
  identifyCosignerUnboardingSteps,
  coSignerStepNavigatorMapping,
} from "../../store/slices/cosigner";
import { errorToast } from "../../utils/toast";
import SplashValidation from "../../components/sharedComponents/SplashValidation";
import { ROUTES } from "../../constants/routes";
import Logo from "../../assets/Logos/logoCoSigner.svg";
import { i18nDate } from "../../utils/formatter";
import { selectAuthUserName, setIsAuthorized } from "../../store/slices/users";
import { GTMClick } from "../../utils/gtm";
import { coSignerSummaryPage } from "../../constants/gtm";
import { PREFERENCE_TYPE, STR_NA } from "../../constants/generals";

import SumaryCoSignerStyles from "./SumaryCoSigner.styles";

const SumaryCoSigner = () => {
  const classes = SumaryCoSignerStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const coSignerToken = useSelector(selectCoSignerToken);
  const applicantInfo = useSelector(selectApplicantInfo);
  const userName = useSelector(selectAuthUserName);
  const [loading, setLoading] = useState(false);

  const {
    applicantsName,
    coSignerName,
    applicationDate,
    medicName,
    procType,
    procediments,
    procedureCost,
    creditRequestedAmount,
    monthlyInstallments,
    monthlyPayment,
    paymentFrequency,
    referenceNumber,
    specialtyType,
  } = applicantInfo;

  const handleClick = async () => {
    GTMClick(coSignerSummaryPage.continue);
    if (coSignerToken) {
      dispatch(setIsAuthorized(true));

      const { status, data } = await dispatch(
        identifyCosignerUnboardingSteps(coSignerToken)
      ).unwrap();
      if (status === "success") {
        if (!isNaN(data.step_no)) {
          const page = coSignerStepNavigatorMapping[data?.step_no + 1];
          history.push(page);
        }
      } else {
        errorToast("Ocurrió un error inesperado, por favor recarga la página.");
      }
    }
  };

  const callCosignerApplicationSummary = async () => {
    await dispatch(resetCosingerInfo());
    await dispatch(applicationSummary({ token: coSignerToken })).unwrap();
  };

  useEffect(() => {
    callCosignerApplicationSummary();
  }, []);

  const currencyFormat = (num) => {
    let val = parseFloat(num);
    return val.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  if (loading) {
    return <SplashValidation image={4} trueRedirect={false} />;
  } else {
    return (
      <Box
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
        textAlign="center"
        className={classes.content}
      >
        <img src={Logo} className={classes.logo} alt="logo" />
        <Typography variant="h2" className={classes.textCoSigner}>
          {/* Hola, {`${name} ${lastName}`}coSignerName */}
          Hola, {`${coSignerName}`}
        </Typography>

        <Typography variant="h2" className={classes.textWelcome}>
          Bienvenido a Medsi
        </Typography>

        <Typography variant="h6" className={classes.textUser}>
          <label className={classes.lightBlueColor}>{userName}</label> te ha
          incluido como correponsable en su solicitud de crédito con Medsi para
          pagar el procedimiento/tratamiento
        </Typography>

        <Typography variant="h6" className={classes.textInformation}>
          Para seguir adelante con su solicitud, necesitamos tu ayuda para
          obtener algo de información adicional. Este proceso no debería tomarte
          más de 5 minutos. completar.
        </Typography>

        <Typography variant="h6" className={classes.textPressButton}>
          Si reconoces al aplicante antes mencionado y quieres ir adelante con
          <Hidden mdDown>
            <br />
          </Hidden>{" "}
          esta solicitud, presiona el botón de Continuar.
        </Typography>

        <Button
          variant="contained"
          className={classes.button}
          onClick={handleClick}
        >
          Continuar
        </Button>

        <Grid container className={classes.gridFooterStyle}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" className={classes.gridText}>
              <Link to={ROUTES.contact} target="_blank">
                CENTRO DE AYUDA
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" className={classes.gridText}>
              <Link to={ROUTES.tnc} target="_blank">
                TÉRMINOS Y CONDICIONES
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1" className={classes.gridText}>
              <Link to={ROUTES.privacyPolicy} target="_blank">
                AVISO DE PRIVACIDAD
              </Link>
            </Typography>
          </Grid>
        </Grid>

        <Typography variant="body2" className={classes.textRightsReserved}>
          ©medsi 2022 Todos los derechos reservados.
        </Typography>
      </Box>
    );
  }
};

export default SumaryCoSigner;

// <Grid container className={classes.gridStyle}>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Fecha de aplicación:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {i18nDate(applicationDate, "es").format("D [de] MMMM [del] yyyy")}
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Número de aplicacion:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {referenceNumber}
//   </Typography>
// </Grid>

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Nombre del médico tratante:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {medicName || STR_NA}
//   </Typography>
// </Grid>

// {specialtyType && (
//   <>
//     <Grid item xs={6}>
//       <Typography variant="h6" className={classes.textGridLeft}>
//         Especialidad médica:
//       </Typography>
//     </Grid>
//     <Grid item xs={6}>
//       <Typography variant="h6" className={classes.textGridRight}>
//         {specialtyType}
//       </Typography>
//     </Grid>
//   </>
// )}

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Tipo de procedimientoa:
//   </Typography>
// </Grid>

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {procType || STR_NA}
//   </Typography>
// </Grid>

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Procedimiento:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {procediments || STR_NA}
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Costo del procedimiento:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {`$ ${currencyFormat(procedureCost)} MXN`}
//   </Typography>
// </Grid>

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Monto del crédito:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {`$ ${currencyFormat(creditRequestedAmount)} MXN`}
//   </Typography>
// </Grid>

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Plazo del crédito:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {monthlyInstallments} meses
//   </Typography>
// </Grid>

// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Pago
//     {/* {paymentFrequency?.toLowerCase() === '1-month' ? 'Mensual' : 'Quincenal'}  */}
//     {/* pago a plazo */}
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     ${currencyFormat(monthlyPayment)}
//     {/* {monthlyPayment === "Mensual"
//     ?
//     // : currencyFormat(monthlyPayment / 2)}*/}
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridLeft}>
//     Frecuencia de pago:
//   </Typography>
// </Grid>
// <Grid item xs={6}>
//   <Typography variant="h6" className={classes.textGridRight}>
//     {paymentFrequency === PREFERENCE_TYPE.MONTHLY
//       ? "Mensual"
//       : "Quincenal"}
//   </Typography>
// </Grid>
// </Grid>
