export const mapBalanceSummary = (res) => {
  const { data, status } = res;
  const {
    cl_id: clId,
    req_amt: loanAmt,
    amt_outstanding: outstandingAmt,
    tenure,
    interestRate = 0,
    term_amt: emiAmt,
    next_due_date: nextDueDate,
    term_amt: nextDueAmt,
    percentage_paid: percentPaid,
    disbursed_date: disbursementDate,
    contract_pdf: contractPdf,
    payment_frequency: paymentFrequency,
    email_verified: emailVerified,
    schedule,
  } = data;

  const paymentSchedule = schedule?.map((item) => {
    const {
      is_id: isId,
      term,
      due_date: date,
      principal_amt: principalAmt,
      interest_amt: interestAmt,
      total_due_amt: totalAmt,
      principal_remain: principalRemain,
      extra_paid: extraAmt,
      status = "",
      vat_amt: vatAmt,
    } = item;
    return {
      isId,
      term,
      date,
      principalAmt,
      interestAmt,
      totalAmt,
      principalRemain,
      status,
      extraAmt,
      vatAmt,
    };
  });

  const balanceSummary = {
    clId,
    loanAmt,
    interestRate,
    tenure,
    emiAmt,
    disbursementDate,
    nextDueDate,
    nextDueAmt,
    status: data?.status?.[1],
    percentPaid,
    outstandingAmt,
    contractPdf,
    paymentFrequency,
    emailVerified,
  };

  return {
    data: {
      balanceSummary,
      paymentSchedule,
    },
    meta: {
      status,
    },
  };
};

export const mapLatestTransaction = (res) => {
  const { data, status } = res;
  const { txnList } = data;

  const latestTransaction = txnList?.map((item) => {
    const {
      txn_id: id,
      amt,
      created_at: date,
      txn_type: txnType,
      txn_status: txnStatus,
    } = item;
    return {
      id,
      amt,
      date,
      txnType,
      txnStatus,
    };
  });

  return {
    data: {
      latestTransaction,
    },
    meta: {
      status,
    },
  };
};

export const mapTransactionDetail = (res) => {
  const { data, status } = res;
  const { txnDetail } = data;

  const {
    extra_amt_paid: extraAmtPaid,
    created_at: date,
    interest_paid: interestPaid,
    principal_paid: principalPaid,
    total_paid: totalAmtPaid,
    txn_id: id,
    txn_status: txnStatus,
    txn_type: txnType,
    vat_paid: vatAmtPaid,
  } = txnDetail?.[0];

  const txnDetails = {
    date,
    extraAmtPaid,
    interestPaid,
    principalPaid,
    totalAmtPaid,
    id,
    txnStatus,
    txnType,
    vatAmtPaid,
  };

  return {
    data: {
      txnDetails,
    },
    meta: {
      status,
    },
  };
};
