// import saludEconomia from "../../assets/NewsMedia/salud_economia.jpg";
// import medic from "../../assets/NewsMedia/doctor.jpg";
import mexicoSalud from "../../assets/NewsMedia/mexico_salud.jpg";
import FinanciamientoMexico from "../../assets/NewsMedia/MexicoSaludFinanciamiento.jpg";
// import Kaloni from "../../assets/NewsMedia/kaloni.svg";
import MedsiCat from "../../assets/NewsMedia/MedsiEventosCatastroficos.svg";
import HeraldoPoster from "../../assets/NewsMedia/heraldoPoster.png";
import MilenioPoster from "../../assets/NewsMedia/milenioPoster.png";
import FinancieroPoster from "../../assets/NewsMedia/posterFinanciero.png";
import eduardoPoster from "../../assets/NewsMedia/eduardoTorreBlanca_poster.png";
import stevenLoeb from "../../assets/NewsMedia/stevenLoeb.jpg";
import peterpage from "../../assets/NewsMedia/peterpage.jpg";
import sebastianEstrada from "../../assets/NewsMedia/sebastianEstrada.png";
import bulfragBlog from "../../assets/NewsMedia/bulfragBlog.jpg";
import rodrigoRojas from "../../assets/NewsMedia/rodrigoRojas.jpg";
import NilsaHernandez from "../../assets/NewsMedia/NilsaHernandez.jpg";
import axis from "../../assets/NewsMedia/axis.png";
import linoSolis from "../../assets/NewsMedia/linoSolis.jpg";
import erika from "../../assets/NewsMedia/erika.jpg";
import health from "../../assets/NewsMedia/health.jpg";
import yanin from "../../assets/NewsMedia/yaninAlfaro.jpg";
import finovate from "../../assets/NewsMedia/finovate.jpg";
import stripe from "../../assets/NewsMedia/stripe.png";
import tekios from "../../assets/NewsMedia/tekios.jpg";
import medsi from "../../assets/NewsMedia/medsi.jpg";
import insurance from "../../assets/NewsMedia/insurance.png";
import gritDaily from "../../assets/NewsMedia/gritdaily.jpg";
import itToday from "../../assets/NewsMedia/itToday.jpg";
import dineroImagen from "../../assets/NewsMedia/dineroImagen.png";
import pagar from "../../assets/NewsMedia/pagar.jpg";
const awsUrl = `https://medsi-news-media.s3.amazonaws.com/videos/`;

export const articles = [
  {
    id: 1,
    media: `${awsUrl}%C2%BFChat+GPT+es+confiable_%2C+los+50+an%CC%83os+de+la+primer+llamada+telefo%CC%81nica+y+ma%CC%81s+_+Milenio+Tech.mp4`,
    type: "video",
    poster: MilenioPoster,
    title: "Milenio, App para cuidar tu salud",
    releaseDate: "Abril 7, 2023",
    subtitle:
      "Milenio habla sobre la primera super app Mexicana que está revolucionando la era técnologica",
    description: `Medsi es una super App Mexicana, que brinda la posibilidad de obtener créditos para cualquier cuestión médica.`,
  },
  {
    id: 2,
    media: pagar,
    type: "article",
    links: [
      {
        url: `https://es.wired.com/articulos/la-healthtech-que-quiere-pagar-tu-parto-tu-dentista-y-hasta-tu-cirugia-plastica`,
        type: "web",
      },
    ],
    title: "WIRED en Español",
    releaseDate: "Marzo 06, 2023",
    subtitle: `La healthtech que quiere pagar tu parto, tu dentista y hasta tu cirugía plástica.`,
    description: `El problema del acceso a la salud no es solo la falta de médicos u hospitales, sino la falta de dinero para poder pagar los servicios. La startup Medsi atiende esta problemática. Su meta es clara: que ninguna persona deje de lado su salud por falta de recursos económicos.`,
  },
  {
    id: 3,
    media: dineroImagen,
    type: "article",
    links: [
      {
        url: `https://www.dineroenimagen.com/el-contador/el-contador/150750`,
        type: "web",
      },
    ],
    title: "Dinero en Imagen, Staff",
    releaseDate: "Febrero 06, 2023",
    subtitle: `Medsi, cuyo fundador y CEO es Manuel Villalvazo, cerró una ronda de financiamiento por 10 millones de dólares para impulsar la incorporación de cerca de 30 mil clientes.`,
    description: `Esta empresa, fundada en 2022, tiene el objetivo de “democratizar” el acceso a la salud y para lograrlo creó un marketplace de servicios médicos en línea, accesible a través de una aplicación móvil.`,
  },
  {
    id: 4,
    media: itToday,
    type: "article",
    links: [
      {
        url: `https://www.healthcareittoday.com/2023/02/03/medsi-raises-usd-10m-in-debt-financing-round-to-onboard-first-30000-mexican-customers-currently-on-the-waitlist-for-its-health-assurance-super-app/`,
        type: "web",
      },
    ],
    title: "Healthcare IT TODAY",
    releaseDate: "Febrero 03, 2023",
    subtitle: `Medsi, tiene como objetivo resolver el gran desafío de la brecha latinoamericana en el acceso a la atención asequible`,
    description: `Con solo una pequeña fracción de mexicanos que tiene seguro médico o acceso al crédito o ahorros necesarios, una emergencia médica puede ser un evento catastrófico como lo demuestra la reciente pandemia de COVID-19 que golpeó a México con más fuerza que al resto de América del Norte.`,
  },
  {
    id: 5,
    media: gritDaily,
    type: "article",
    links: [
      {
        url: `https://gritdaily.com/medsi-mexicos-first-health-assurance-super-app/`,
        type: "web",
      },
    ],
    title: "GritDaily, Peter Page",
    releaseDate: "Febrero 02, 2023",
    subtitle: `Medsi Impulsa el Crecimiento de la Primera Súper App “Garantía de Salud”`,
    description: `La pandemia de covid golpeó a México con más fuerza que al resto de América del Norte, dejando al descubierto el lamentablemente inadecuado sistema de salud de la nación.`,
  },
  {
    id: 6,
    media: insurance,
    type: "article",
    links: [
      {
        url: `https://insurancenewsnet.com/oarticle/medsi-raises-usd-10m-in-debt-financing-round-to-onboard-first-30000-mexican-customers-currently-on-the-waitlist-for-its-health-assurance-super-app`,
        type: "web",
      },
    ],
    title: "Insurance NewsNet, PRWeb",
    releaseDate: "Enero 31, 2023",
    subtitle: `Medsi recauda USD $ 10 millones en una ronda de financiamiento de deuda para incorporar a los primeros 30,000 clientes mexicanos`,
    description: `Con solo una pequeña fracción de mexicanos que tiene seguro médico o acceso al crédito o ahorros necesarios, una emergencia médica puede ser un evento catastrófico como lo demuestra la reciente pandemia de COVID-19 que azotóMéxicomás duro que el resto deAmérica del norte.`,
  },
  {
    id: 7,
    media: medsi,
    type: "article",
    links: [
      {
        url: `https://pitchbook.com/profiles/company/519068-71#overview`,
        type: "web",
      },
    ],
    title: "Pitchbook",
    releaseDate: "Enero 30, 2023",
    subtitle: `Conoce todo sobre Medsi`,
    description: `Encuentra toda la información pública acerca de Medsi, las rondas de financiamiento que hemos obtenido y quienes fueron sus cofundadores.`,
  },
  {
    id: 8,
    media: tekios,
    type: "article",
    links: [
      {
        url: `https://tekiosmag.com/2023/01/30/healthtech-mexicana-medsi-recauda-us10-millones-para-potenciar-linea-de-creditos-para-procedimientos-de-salud/`,
        type: "web",
      },
    ],
    title: "Tekios, Lino Solis de Ovando",
    releaseDate: "Enero 30, 2023",
    subtitle: `Healthtech mexicana Medsi recauda US$10 millones para potenciar línea de créditos para procedimientos de salud`,
    description: `La startup, con sede en Ciudad de México y presencia en Miami, Florida, lanzó en septiembre del año pasado su primer producto, una línea de crédito que permite financiar servicios y procedimientos de salud esenciales o electivos.`,
  },
  {
    id: 9,
    media: stripe,
    type: "article",
    links: [
      {
        url: `https://techcrunch.com/2023/01/29/fintech-whats-stripes-deal/`,
        type: "web",
      },
    ],
    title: "TechCrunch, Mary Ann Azevedo",
    releaseDate: "Enero 29, 2023",
    subtitle: `¿Cuál es el problema de Stripe?`,
    description: `Stripe se había fijado un plazo de 12 meses para salir a bolsa, ya sea a través de una cotización directa o buscando una transacción en el mercado privado`,
  },
  {
    id: 10,
    media: finovate,
    type: "article",
    links: [
      {
        url: `https://finovate.com/finovate-global-africa-revolutionizing-payments-and-promoting-inclusion-with-pagas-tayo-oviosu/`,
        type: "web",
      },
    ],
    title: "Finovate blog, David Penn",
    releaseDate: "Enero 27, 2023",
    subtitle: `Finovate Global Africa: revolucionando los pagos y promoviendo la inclusión con Tayo Oviosu de Paga`,
    description: `Paga fue reconocida recientemente con su ubicación en la lista CB Insights 250, una de las siete nuevas empresas africanas presentadas. ¿Qué está yendo bien con fintech en el África subsahariana en estos días?`,
  },
  {
    id: 11,
    media: yanin,
    type: "article",
    links: [
      {
        url: `https://www.bloomberglinea.com/2023/01/27/healthtech-medsi-levanta-deuda-por-us10-millones-de-capem-mexico/`,
        type: "web",
      },
    ],
    title: "Bloomberg Línea, Yanin Alfaro",
    releaseDate: "Enero 27, 2023",
    subtitle: `La startup mexicana que brinda financiamiento para servicios de salud, además está esperando el cierre de una ronda semilla.`,
    description: `Fundada en enero de 2022, Medsi comenzó en septiembre de 2022 a dar financiamiento para el cuidado de la salud, una línea de crédito revolvente que puede ser utilizada por toda la familia para servicios y procedimientos de salud. `,
  },
  {
    id: 12,
    media: linoSolis,
    type: "article",
    links: [
      {
        url: `https://global-it.mx/2023/01/26/medsi-levanta-10-mdd-para-su-app-de-credito-a-servicios-de-salud/`,
        type: "web",
      },
    ],
    title: "Global IT, Lino Solis",
    releaseDate: "Enero 26, 2023",
    subtitle: `La startup Medsi, un marketplace en el que convergen “fintech” y “healthcare” anunció que cerró una ronda de financiamiento de deuda de $10 millones de dólares.`,
    description: `Fundada en enero de 2022, Medsi realizó el lanzamiento comercial de su primer producto, una línea de crédito revolvente para uso familiar que permite financiar servicios y procedimientos de salud esenciales o electivos, en septiembre de 2022.`,
  },
  {
    id: 13,
    media: health,
    type: "article",
    links: [
      {
        url: `https://www.eleconomista.com.mx/opinion/De-donde-sea-20230126-0104.html`,
        type: "web",
      },
      {
        url: `https://www.pressreader.com/article/281775633292503`,
        type: "web",
      },
    ],
    title: "El Economista, Staff",
    releaseDate: "Enero 26, 2023",
    subtitle: `Ante el escenario, la tecnológica Medsi buscará que México sea punto de partida para la operación de su plataforma fintech y healthcare`,
    description: `Aunque el 49% de la población mexicana tiene acceso a algún tipo de cobertura de salud, solo el 5% tiene acceso a un seguro médico privado, de acuerdo con la Organización para la Cooperación y el Desarrollo Económicos (OCDE).`,
  },
  {
    id: 14,
    media: erika,
    type: "article",
    links: [
      {
        url: `https://cobertura360.mx/2023/01/26/negocios/medsi-levanta-10-mdd-para-incorporar-a-largo-plazo-a-clientes-que-operan-en-la-informalidad/`,
        type: "web",
      },
    ],
    title: "Cobertura 360, Erika Gonzalez",
    releaseDate: "Enero 26, 2023",
    subtitle: `Medsi levanta 10 mdd para incorporar a largo plazo a clientes que operan en la informalidad`,
    description: `A fin de resolver el desafío del acceso a servicios médicos a una atención asequible y transparente en Latinoamérica, Medsi, plataforma digital para automatizar los servicios de salud, cerró una ronda de financiamiento de deuda de 10 millones de dólares para incorporar a los primeros 30 mil clientes en lista de espera para su nueva aplicación.`,
  },
  {
    id: 15,
    media: axis,
    type: "article",
    links: [
      {
        url: `https://www.axisnegocios.com/breves.phtml?id=116187`,
        type: "web",
      },
    ],
    title: "Axis Negocios,Staff",
    releaseDate: "Enero 26, 2023",
    subtitle: `Medsi, startup de salud, recaba 10 millones de dólares en ronda de deuda`,
    description: `Medsi, una startup que busca democratizar el acceso a los servicios de salud a través de la combinación de tecnología y atención humana, dijo que recaudó 10 millones de dólares en una ronda de financiamiento de deuda dirigida por el fondo CAPEM México.`,
  },
  {
    id: 16,
    media: NilsaHernandez,
    type: "article",
    links: [
      {
        url: `https://www.milenio.com/negocios/inversion-10-mdd-llega-medsi-mexico-atender-necesidades`,
        type: "web",
      },
    ],
    title: "Milenio, Nilsa Hernandez",
    releaseDate: "Enero 26, 2023",
    subtitle: `Medsi es una aplicación que en menos de tres minutos da préstamos a los mexicanos`,
    description: `Medsi, proveedor de soluciones de financiamiento dedicados al segmento médico, llega a México para atender las necesidades que tienen las personas de atención a la salud, pero que no pueden acceder a ello por falta de dinero.`,
  },
  {
    id: 17,
    media: rodrigoRojas,
    type: "article",
    links: [
      {
        url: `https://www.saludiario.com/primera-super-app-de-salud/`,
        type: "web",
      },
    ],
    title: "Saludiario, Rodrigo Rojas",
    releaseDate: "Enero 26, 2023",
    subtitle: `La primera “súper app de salud” en Latinoamérica es Mexicana`,
    description: `Fundado en enero de 2022, Medsi realizó el lanzamiento comercial de su primer producto. Consistió en una línea de crédito revolvente para uso familiar que permite financiar servicios y procedimientos de salud esenciales.`,
  },
  {
    id: 18,
    media: bulfragBlog,
    type: "article",
    links: [
      {
        url: `https://www.bullfrag.com/this-is-the-first-super-health-app-in-latin-america/`,
        type: "web",
      },
    ],
    title: "Bullfrag Blog, Staff",
    releaseDate: "Enero 26, 2023",
    subtitle: `Así es la primera “súper app de salud” de América Latina`,
    description: `La tecnología ha transformado por completo todas las actividades de la vida. Desde mantenerse informado de lo que sucede en el mundo hasta enviar correos electrónicos se puede hacer fácilmente con un teléfono inteligente.`,
  },
  {
    id: 19,
    media: sebastianEstrada,
    type: "article",
    links: [
      {
        url: `https://www.eleconomista.com.mx/sectorfinanciero/Medsi-levanta-10-millones-de-dolares-para-impulsar-el-sector-Insurtech-en-Mexico-20230126-0024.html`,
        type: "web",
      },
    ],
    title: "El Economista, Sebastian Estrada",
    releaseDate: "Enero 26, 2023",
    subtitle: `Medsi levanta 10 millones de dólares para impulsar el sector Insurtech en México`,
    description: `Las insurtech o tecnología de seguros, están llegando con fuerza a México, debido al aumento de las fintech, estas firmas buscan cerrar la brecha del acceso de seguros y servicios médicos, ya que visualizan oportunidad en la demanda del mercado mexicano.`,
  },
  {
    id: 20,
    media: peterpage,
    type: "article",
    links: [
      {
        url: `https://gritdaily.com/medsi-raises-10m/`,
        type: "web",
      },
    ],
    title: "GritDaily, Peter Page",
    releaseDate: "Enero 26, 2023",
    subtitle: `Medsi recauda $ 10 millones para incorporar a 30,000 clientes mexicanos que esperan su súper aplicación de "health guarantee"`,
    description: `Hoy, Medsi, un mercado de "fintech se encuentra con healthtech" que hace que los servicios de atención médica, la información y los precios sean más accesibles para todos, anunció que ha recaudado una ronda de financiamiento de deuda de USD $ 10 millones de CAPEM.`,
  },
  {
    id: 21,
    media: stevenLoeb,
    type: "article",
    links: [
      {
        url: `https://vator.tv/news/2023-01-26-mexican-healthcare-marketplace-medsi-raises-10m`,
        type: "web",
      },
    ],
    title: "Vator, Steven Loeb",
    releaseDate: "Enero 26, 2023",
    subtitle: "El mercado mexicano de la salud Medsi recauda $10 millones",
    description: `La empresa ofrece productos financieros, incluida una línea de crédito, para ayudar a los miembros a acceder a la atención.`,
  },
  {
    id: 22,
    media: `${awsUrl}Entrevista_ide_tv.mp4`,
    type: "video",
    poster: eduardoPoster,
    title: "Ide TV- Universo PYME",
    releaseDate: "Agosto 15, 2022",
    subtitle:
      "Eduardo Torreblanca entrevista al equipo de Medsi, para saber que es lo que Medsi ofrece a los Mexicanos.",
    description: `Medsi es una empresa Mexicana que ofrece un producto innovador, el cual hace uso de inteligencia artificial, medicina y financiamiento para cuestiones médicas, Manuel, Pablo y José nos explican como funciona su plataforma, los beneficios que obtenemos y cuantos rubros médicos estan siendo cubiertos por está hasta el momento.`,
  },
  {
    id: 23,
    media: MedsiCat,
    type: "article",
    links: [
      {
        url: `https://www.forbes.com.mx/esta-fintech-ofrece-creditos-ante-eventos-catastroficos-medicos/`,
        type: "web",
      },
      {
        url: `https://twitter.com/Forbes_Mexico/status/1554815706985893890?t=KK7PYyiWnxWP-7ie6G2xrQ&s=08`,
        type: "twitter",
      },
    ],
    title: "Forbes",
    releaseDate: "Agosto 3, 2022",
    subtitle: "Medsi ofrece créditos ante eventos catastróficos médicos.",
    description: `Medsi es una empresa de tecnología financiera fundada en México, busca salvar a sus clientes de cualquier evento catastrófico médico.`,
  },
  {
    id: 24,
    media: FinanciamientoMexico,
    type: "article",
    links: [
      {
        url: `https://heraldodemexico.com.mx/opinion/2022/8/1/financiamiento-medico-426699.html`,
        type: "web",
      },
    ],
    title: "El Heraldo",
    releaseDate: "Agosto 1, 2022",
    subtitle:
      "Financiamiento médico sustentado por el bolsillo de los Mexicanos",
    description: `Se prevee que por varios años los Mexicanos sigamos gastando más dinero de nuestro bolsillo para atender temas de Salud, esto debido a temas referentes a la crisis que el día de hoy el sector salud está viviendo, dando como resultado que la mayoría de las familias Mexicanas queden al margen de lo que los servicios del IMSS e ISSSTE ofrecen actualmente.`,
  },
  {
    id: 25,
    media: mexicoSalud,
    type: "article",
    links: [
      {
        url: `https://www.heraldobinario.com.mx/empresas/2022/7/12/por-que-el-acceso-la-salud-es-discriminatorio-solo-49-de-la-poblacion-paga-servicios-sanitarios-26918.html`,
        type: "web",
      },
      {
        url: `https://heraldodemexico.com.mx/opinion/2022/8/1/financiamiento-medico-426699.html`,
        type: "web",
      },
    ],
    title: "El Heraldo",
    releaseDate: "Julio 12, 2022",
    subtitle:
      "¿Por qué el acceso a la salud es discriminatorio? Solo 49% de la población paga servicios sanitarios",
    description: `En México una de las principales limitantes para que las personas puedan acceder a servicios médicos es el tema del presupuesto`,
  },

  {
    id: 26,
    media: `${awsUrl}Manuel+Villalvazo.mp4`,
    type: "video",
    poster: HeraldoPoster,
    title: "Dario Celis, Tiempo de Negocios",
    releaseDate: "Julio 12, 2022",
    subtitle:
      "Dario Celis entrevista a Manuel Villalvazo, cofundador y CEO de Medsi, para hablar de la industria de la salud en México.",
    description: `Cuáles son algunas de las raices estructurales detrás del problema de salud en México, y como las soluciones de Medsi permiten ampliar el accesso a servicios médicos adecuados y de manera oportuna.`,
  },
  {
    id: 27,
    media: `${awsUrl}TESTIGO+PABLO+MUNOZ+COTE+Y+MANUEL+VILLALVAZOcompleto.mp4`,
    type: "video",
    poster: FinancieroPoster,
    title: "El Financiero Bloomberg",
    links: [
      {
        url: `https://twitter.com/elfinancierotv/status/1542288922415452160?s=24&t=xU1vdVKjKdiwr4xVd-5Qtg`,
        type: "twitter",
      },
    ],
    releaseDate: "Junio 29, 2022",
    subtitle:
      "¿Cuales son las ventajas de usar la plataforma Medsi? Los cofundadores nos explican.",
    description: `Medsi ofrece una gran cantidad de beneficios para la obtención 
    de créditos de manera rápida, segura y confiable.`,
  },

  // {
  //   id: 1,
  //   media: saludEconomia,
  //   type: "article",
  //   title: "Noticias Medsi",
  //   releaseDate: "Junio 2, 2022",
  //   subtitle:
  //     "Invertir en salud se ha convertido en un punto relevante en la economía.",
  //   description: `De acuerdo con la publicación “Macroeconomía y salud. Invertir
  //   en salud para el desarrollo económico” , la salud contribuye al
  //   crecimiento económico de largo plazo a través de los siguientes
  //   mecanismos: incrementa la productividad laboral del adulto,
  //   reduce las pérdidas de producción de los trabajadores`,
  // },
  // {
  //   id: 2,
  //   media: medic,
  //   type: "article",
  //   title: "Noticias Medsi",
  //   releaseDate: "Junio 2, 2022",
  //   subtitle:
  //     "Retos , tendencias actuales y futuras, la salud en los medios digitales.",
  //   description: `El sector salud, tanto a nivel país como a nivel mundial, no es inmune
  //   del todo a los cambios en los ciclos económicos, pero una mayor
  //   demanda por una mejor calidad de vida en economías emergentes,
  //   así como una población envejecida en economías desarrolladas
  //   asegura  que.`,
  // },
  // {
  //   id: 3,
  //   media: mexicoSalud,
  //   type: "article",
  //   title: "Noticias Medsi",
  //   releaseDate: "Junio 2, 2022",
  //   subtitle: "La industria de la salud en México y el mundo.",
  //   description: `El sector salud, tanto a nivel país como a nivel mundial, no es inmune
  //   del todo a los cambios en los ciclos económicos, pero una mayor
  //   demanda por una mejor calidad de vida en economías emergentes,
  //   así como una población envejecida en economías desarrolladas
  //   asegura  que la industria crezca a un ritmo más acelerado que el de
  //   la economía en general.`,
  // },
  // {
  //   id: 4,
  //   media: MedsiCat,
  //   type: "article",
  //   link: `https://www.forbes.com.mx/esta-fintech-ofrece-creditos-ante-eventos-catastroficos-medicos/`,
  //   title: "Forbes",
  //   releaseDate: "Agosto 3, 2022",
  //   subtitle: "Medsi ofrece créditos ante eventos catastróficos médicos.",
  //   description: `Medsi es una empresa de tecnología financiera fundada en México, busca salvar a sus clientes de cualquier evento catastrófico médico.`,
  // },

  // {
  //   id: 8,
  //   media: Kaloni,
  //   type: "article",
  //   title: "Noticias Medsi",
  //   releaseDate: "Junio 2, 2022",
  //   subtitle:
  //     "Kaloni y Medsi, una asociación exclusiva para tu transformación.",
  //   description: `Descubre cómo puedes afiliarte a Medsi y obtener una grán
  //   variedad de procedimientos estéticos y electivos.`,
  // }, Waiting for content
];
