import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import { INSTALLMENT_LOAN_ROUTES, ROUTES } from "../../constants/routes";
import { mailOptions } from "../../utils/index";
import CreditConfirmationStyles from "./styles";

import { API_STATUS } from "../../constants/common";
import { getApplicationBasicDetails } from "../../features/installmentLoan/store/installmentLoan";
import { useAppDispatch } from "../../store";
import useStore from "../../store/getStore";
import {
  applicationSummary,
  selectApplicantInfo,
  selectCosignerInfo,
  selectCoSignerToken,
  selectIsCosigner,
} from "../../store/slices/cosigner";
import {
  selectFolioNumber,
  selectUserCoSigner,
} from "../../store/slices/users";
import { currencyFormat } from "../../utils/index";
import { errorToast } from "../../utils/toast";

const CreditConfirmation = () => {
  const cosignerInfo = useSelector(selectCosignerInfo);
  const cosignerMainInfo = useSelector(selectApplicantInfo);
  const coSignerToken = useSelector(selectCoSignerToken);

  const isUserCosigner = selectIsCosigner();
  const { name, lastName, mothersLastName } = cosignerInfo;
  let coapplicant = `${name} ${lastName} ${mothersLastName}`;
  //Generate new folio
  const { creditRequestedAmount, applicantsName } =
    useSelector(selectApplicantInfo);
  //saving co applicant data
  let cosignerInformation = useSelector(selectApplicantInfo);

  const dispatch = useAppDispatch();
  const {
    installmentLoan: { basicApplicationSummary },
  } = useStore();
  const { reqAmt, termLength, termDuration, termAmt, refCode } =
    basicApplicationSummary || {};

  let amount = isUserCosigner ? creditRequestedAmount : reqAmt || 0;
  let monthlyIn = isUserCosigner
    ? cosignerInformation.monthlyInstallments
    : termLength;
  let paymenthPref = isUserCosigner
    ? cosignerInformation.paymentFrequency
    : termDuration;
  let monthPay = isUserCosigner ? cosignerInformation.monthlyPayment : termAmt;

  const classes = CreditConfirmationStyles();
  const hasCoSigner = useSelector(selectUserCoSigner);
  const history = useHistory();
  const folio = useSelector(selectFolioNumber);
  mailOptions();

  const getData = async () => {
    dispatch(
      applicationSummary({
        token: coSignerToken,
      })
    );
  };
  useEffect(() => {
    getData();
  }, []);

  useLayoutEffect(() => {
    if (folio) {
      /* dispatch(resetBooleanValuesCosigner());
      dispatch(resetBooleanValuesUser());
      dispatch(resetCosingerInfo());
      dispatch(resetUserRegisterInfo());
      dispatch(setIsProcessComplete(true)); */
    }
  }, [folio, dispatch]);

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      className={classes.content}
    >
      <img src={Logo} className={classes.logo} alt="logo" />

      <Typography variant="h1" className={classes.textTitle}>
        {isUserCosigner ? "Muchas gracias" : "Confirmación de Aplicación"}
      </Typography>
      {/* <Typography variant="h3" className={classes.textTitle}>
        Número de aplicación: {"  "}
        <strong>
          {isUserCosigner
            ? cosignerMainInfo.referenceNumber &&
              cosignerMainInfo.referenceNumber
            : refCode}
        </strong>
      </Typography> */}
      {isUserCosigner ? (
        <>
          <Typography variant="h3" className={classes.text24Hours} mb={7}>
            Tus datos han sido enviados con éxito. Revisaremos la solicitud y
            nos pondremos en contacto contigo en breve.
          </Typography>
        </>
      ) : hasCoSigner ? (
        <>
          <Typography variant="h3" className={classes.text24Hours}>
            Se ha enviado un correo a
            <span className={classes.lightBlueColor}>{coapplicant}</span> para
            finalizar tu proceso de solicitud de crédito.
          </Typography>
          <Typography variant="h3" className={classes.text24Hours}>
            Una vez que
            <span className={classes.lightBlueColor}>{coapplicant}</span> nos
            proporcione la información adicional, quedará completado tu proceso
            de solicitud y te notificaremos de inmediato por email
          </Typography>
        </>
      ) : (
        <Typography variant="h3" className={classes.text24Hours}>
          Te hemos enviado a tu correo la confirmación
          <Hidden smDown>
            <br />
          </Hidden>
          de tu aplicación de crédito. Regresaremos contigo
          <Hidden smDown>
            <br />
          </Hidden>
          en las próximas 24 horas
        </Typography>
      )}
      {!isUserCosigner && (
        <>
          {amount ? (
            <Typography variant="h3" className={classes.textAmountView}>
              ${`${currencyFormat(amount)} MXN`}
            </Typography>
          ) : null}

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={classes.boxMonthly}
          >
            <Typography variant="h1" className={classes.textMonthly}>
              {monthlyIn || null}
            </Typography>
            <Typography variant="subtitle1" className={classes.textMonth}>
              meses
            </Typography>
          </Box>

          {paymenthPref ? (
            <Typography variant="subtitle1" className={classes.textPayment}>
              Pago {paymenthPref} de
            </Typography>
          ) : null}
          {monthPay ? (
            <Typography variant="h4" className={classes.textAmount}>
              ${currencyFormat(monthPay)}
              MXN
            </Typography>
          ) : null}

          <Typography variant="h6" className={classes.textPassed}>
            De ser aprobado tu crédito, el médico que seleccionaste
            <Hidden smDown>
              <br />
            </Hidden>
            previamente será notificado de inmediato.
            <br />
            <br />
            Nosotros haremos el pago directamente a tu médico, <br />
            en la fecha y hora de tu cita, una vez que hayas <br /> recibido el
            tratamiento o procedimiento que vas a realizarte.
            {/* Al momento de aprobación podrás generar tu Código QR,
            <Hidden smDown>
              <br />
            </Hidden>
            con el cual podrás hacer válido tu crédito con tu médico */}
          </Typography>

          {/* <Button
            disabled={true}
            size="large"
            variant="contained"
            className={classes.button}
            onClick={() => history.push(ROUTES.qrCode)}
          >
            Generar Código QR
          </Button> */}
          <button
            className={classes.payNowGradientButtonStyle}
            onClick={() => history.push(INSTALLMENT_LOAN_ROUTES.MAIN)}
          >
            Finalizar
          </button>
        </>
      )}

      <Typography variant="h6" className={classes.textEmail}>
        Si tienes alguna duda, escríbenos a<br />
        <Button href="mailto:contacto@medsi.mx" className={classes.email}>
          contacto@medsi.mx
        </Button>
      </Typography>

      <Grid container className={classes.gridStyle}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" className={classes.gridText}>
            <Link to={ROUTES.contact} target="_blank">
              CENTRO DE AYUDA
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" className={classes.gridText}>
            <Link to={ROUTES.tnc} target="_blank">
              TÉRMINOS Y CONDICIONES
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" className={classes.gridText}>
            <Link to={ROUTES.privacyPolicy} target="_blank">
              AVISO DE PRIVACIDAD
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreditConfirmation;
