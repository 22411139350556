import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const styles = makeStyles((theme) => ({
  container: {
    padding: "3% 6%",
    // background: "#3552CC",
    background: "#00263A",
    color: "#fff",
    [theme.breakpoints.down(601)]: {
      padding: "5% 10%",
    },
    width: "100%",
  },
  footerHeadingText: {
    fontWeight: "bold",
    fontFamily: "SFUIText-Bold",
    fontSize: 22,
    lineHeight: "1.2",
  },
  footerAnchorTagStyle: {
    color: "#fff",
    textDecoration: "none",
  },
  footerUlTagStyle: {
    margin: "0px",
    padding: "0px",
    paddingLeft: "14px",
  },
  line: {
    height: 1,
    background: "#fff",
    width: "100%",
  },
  rightContent: {
    textAlign: "right",
    [theme.breakpoints.down(601)]: {
      textAlign: "left",
      marginTop: 20,
    },
  },
  title: {
    fontSize: 32,
    color: customColor.cotton,
    fontWeight: "700",
    [theme.breakpoints.down(601)]: {
      fontSize: 20,
    },
  },
  input: {
    width: "70%",
    marginTop: "5px !important",
    marginBottom: "20px !important",
    background: "transparent",
    "& fieldset": {
      display: "none",
    },
    "& input": {
      background: "rgba(255, 255, 255, 0.5)",
      height: 36,
      padding: "0px 16px",
      fontSize: 18,
      lineHeight: "1.2",
      fontFamily: "SFUIText-Bold",
      fontWeight: "700",
      borderRadius: "8px",
      color: customColor.cotton,
      border: `1px solid ${customColor.cotton}`,
      "&::placeholder": {
        color: customColor.cotton,
      },
      [theme.breakpoints.down(601)]: {
        width: "100%",
        height: "34px",
        fontSize: 14,
      },
    },
    [theme.breakpoints.down(601)]: {
      width: "100%",
    },
  },
  textArea: {
    width: "70%",
    marginTop: "5px !important",
    background: "rgba(255, 255, 255, 0.5)",
    padding: "0px 16px",
    fontSize: 18,
    lineHeight: "1.2",
    fontFamily: "SFUIText-Bold",
    fontWeight: "700",
    borderRadius: "8px",
    color: customColor.cotton,
    border: `1px solid ${customColor.cotton}`,
    "&::placeholder": {
      color: customColor.cotton,
    },
    [theme.breakpoints.down(601)]: {
      width: "100%",
      height: "34px",
      fontSize: 14,
    },
    height: 136,
    resize: "none",
  },
  button: {
    marginTop: "16px !important",
    backgroundColor: "rgba(255, 255, 255, 0.5) !important",
  },
  content: {
    width: "75%",
    [theme.breakpoints.down(601)]: {
      width: "100%",
    },
  },
  formContainer: {
    width: "50%",
    [theme.breakpoints.down(601)]: {
      width: "100%",
    },
  },
  medsiLogo: {
    marginTop: 16,
  },
  medsiText: {
    fontFamily: "Urbanist-Bold",
  },
}));

export default styles;
