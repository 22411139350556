export const PAYMENT_LINK_MIN_AMOUNT = 20;
export const MANUAL_MIN_AMOUNT = 10;

export const API_STATUS = {
  SUCCESS: "success",
  ERROR: "error",
};

export const DECISION_STATUS = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  APPROVED: "approved",
  REJECTED: "rejected",
};

export const ERRORS = {
  SERVICE_ERROR: "SERVICE_ERROR",
};

export enum LMS_TXN_TYPE {
  TYPE_UNKNOWN = "TYPE_UNKNOWN",
  DISBURSEMENT = "DISBURSEMENT",
  DISBURSEMENT_REVERSAL = "DISBURSEMENT_REVERSAL",
  MANUAL_REPAYMENT = "MANUAL_REPAYMENT",
  AUTOMATIC_REPAYMENT = "AUTOMATIC_REPAYMENT",
  REPAYMENT_REVERSAL = "REPAYMENT_REVERSAL",
  PAYMENTLINK_REPAYMENT = "PAYMENTLINK_REPAYMENT",
}

export enum EnumTxnStatus {
  UNKNOWN_STATUS = "UNKNOWN_STATUS",
  APPROVED = "APPROVED",
  POSTED = "POSTED",
  DECLINED = "DECLINED",
  VOIDED = "VOIDED",
  REFUNDED = "REFUNDED",
  REJECTED = "REJECTED",
  REVERSED = "REVERSED",
  CAPTURED = "CAPTURED",
  RELEASED = "RELEASED",
}

export enum EnumCreditTxnStatus {
  STATUS_UNKNOWN = 0,
  PENDING = 1,
  POSTED = 2,
  SUCCESSFUL = 3,
  FAILURE = 4,
  PROCESSING = 5,
}

export enum EnumCreditTxnStatusString {
  STATUS_UNKNOWN = "STATUS_UNKNOWN",
  PENDING = "PENDING",
  POSTED = "POSTED",
  SUCCESSFUL = "SUCCESSFUL",
  FAILURE = "FAILURE",
  PROCESSING = "PROCESSING",
}

export enum EnumSubscriptionStatus {
  UNKNOWN = "UNKNOWN",
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
  DELINQUENT = "DELINQUENT",
  COLLECTIONS = "COLLECTIONS",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
}

export enum APPLICATION_STATUS {
  PROCESSING = "Application processing",
  DISBURSED = "Loan Disbursed",
  RECEIVED = "Application Received",
  APPROVED = "Credit Approved",
  PRE_APPROVED = "Pre Approved",
  LEGAL_REVIEW = "LEGAL_REVIEW",
  PENDING = "PENDING",
  UNDER_REVIEW = "Bajo Revisión",
  REJECTED = "REJECTED",
  PAYMENT_COMPLETED = "PAYMENT_COMPLETED",
}

export enum EMAIL_STATUS_VIEW {
  VERIFIED = "verified",
  ALREADY_VERIFIED = "already-verified",
  FAILURE = "failure",
}

export enum DOCTOR_CONFIRMATION_STATUS {
  SAVED = "saved",
  ALREADY_VERIFIED = "already_exist",
  UNAVAILABLE = "doctor_unavailable",
  NOT_SAVED = "not_exist",
}

export const LOAN_HISTORY_STATUS = {
  "Loan Disbursed": "Préstamo desembolsado",
  "Disbursement Failed": "Desembolso No completado",
  "Payment Completed": "Pago completado",
  Cancelled: "Cancelado",
  Pending: "Pendiente",
  "Disbursal started": "Comenzó el desembolso",
  Fraud: "Fraude",
};

export const ALL_APPLCIATION = {
  Deleted: "Elliminada",
  SUBMITTED: "Solicitud Finalizada",
  DISBURSED: "Préstamo desembolsado",
  PENDING: "Pendiente",
  APPROVED: "Aprobada",
  UNDER_REVIEW: "Bajo Revisión",
  LEGAL_REVIEW: "Bajo Revisión",
  COSIGNER_CONSENT: "Consentimiento del Codeudor",
  REJECTED: "Rechazada",
  PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
};

export const TRANSACTION_STATUS = {
  Posted: "Al corriente",
  Success: "Éxito",
  Failure: "Falla",
  Fraud: "Fraude",
  Disbursement: "Desembolso",
};

export const SIGNATURE_STATUS = {
  success: "Éxito",
  error: "error",
};

export const PROMOCODE_STATUS = {
  success: "Código de promoción añadido con éxito",
};

export const PROMOCODE_DELETE_STATUS = {
  success: "Código de promoción eliminado con éxito",
};

export const API_STATUS_REQUEST = {
  error: "Ingrese una cantidad menor que su Crédito Disponible",
  success: "Solicitud de préstamo eliminada con éxito",
};

export enum URBANIST_FONT {
  REGULAR = "Urbanist-Regular",
  MEDIUM = "Urbanist-Medium",
  SEMIBOLD = "Urbanist-SemiBold",
  BOLD = "Urbanist-Bold",
}

export enum VERIFICATION_METHOD {
  OTP = "OTP",
  PASSWORD = "PASSWORD",
}

export enum FORGET_PASSWORD_METHOD {
  OTP = "OTP",
  EMAIL = "EMAIL",
}

export enum REQUESTED_SOURCE {
  UNKNOWN = 0,
  REGISTRATION = 1,
  LOGIN = 2,
  RESET_PASSWORD = 3,
  BUREAU_CONSENT_SAVE = 4,
  FORGOT_PASSWORD = 5,
  COSIGNER_CONSENT = 6,
}

export enum FIELD_CHANNEL {
  UNKNOWN = 0,
  EMAIL = 1,
  SMS = 2,
  IVR = 3,
  WHATSAPP = 4,
}
