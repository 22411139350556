import { Box, Grid } from "@mui/material";

import { Link } from "react-router-dom";
import ButtonComponent from "../../../../components/sharedComponents/ButtonComponent";

import { useEffect } from "react";
import { INSTALLMENT_LOAN_ROUTES } from "../../../../constants/routes";
import { isKnowsBetter, KNOW_BETTER_NO, KNOW_BETTER_YES } from "../../helpers";
import styles from "../styles";
import { RenderDropDown } from "./HelperComponent";
import RenderClinicForm from "./RenderClinicForm";
import RenderDoctorForm from "./RenderDoctorForm";
import RenderNumber from "./RenderNumber";
import RenderProcedureForm from "./RenderProcedureForm";

const FormContainer = ({
  isValidForm,
  maxCreditAmt,
  minCreditAmount,
  procedureInfo,
  isSubmitting,
  ...formik
}) => {
  const css = styles();

  useEffect(() => {
    if (formik?.values.isKnowDoctor === "Aún no") {
      formik.setFieldValue("medicName", "");
      formik.setFieldValue("medicLastName", "");
      formik.setFieldValue("medicRelation", "");
      formik.setFieldValue("medicPhone", "");
      formik.setFieldValue("appointmentDate", "");
      formik.setFieldValue("appointmentTime", "");
      formik.setFieldValue("procType", "");
      formik.setFieldValue("procToDo", "");
      formik.setFieldValue("otherProc", "");
      formik.setFieldValue("hours", "");
      formik.setFieldValue("minutes", "");
      formik.setFieldValue("clinicName", "");
      formik.setFieldValue("clinicBranch", "");
      formik.setFieldValue("specialtyType", "");
      formik.setFieldValue("otherClinicName", "");
      formik.setFieldValue("clinicDr", "");
      formik.setFieldValue("medicEmail", "");
    }
  }, [formik?.values.isKnowDoctor]);

  return (
    <Box className={css.formView}>
      <Grid container columnSpacing={{ md: 10 }} justifyContent="center">
        <RenderNumber
          formik={formik}
          maxCreditAmt={maxCreditAmt}
          minCreditAmount={minCreditAmount}
        />
        {/* <Grid item md={12}>
          <RenderDropDown
            label="Conozco mi clínica, médico y hora de la cita."
            options={[KNOW_BETTER_YES, KNOW_BETTER_NO]}
            value="isKnowDoctor"
            formik={formik}
            customCss={css.selectCss}
            listItemSelectedStyle={css.listItemSelectedStyle}
          />
        </Grid> */}
        {/* {isKnowsBetter(formik?.values) ? (
          <>
            <RenderClinicForm formik={formik} />
            <RenderDoctorForm formik={formik} />
            <RenderProcedureForm formik={formik} />
          </>
        ) : null} */}
        <Grid item md={12} textAlign="center">
          {/* <div style={{ textAlign: "center", fontFamily: "Roboto-Bold" }}>
            Con esta información, ¡estamos listos para darte tu presupuesto
            personalizado!
          </div> */}
          <Box className={css.button}>
            <ButtonComponent
              type="submit"
              text="Mirar tu oferta personalizada"
              className={css.submitButton}
              disabled={!isValidForm}
              loading={isSubmitting}
              style={{
                width: "auto",
                padding: "0px 50px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <div style={{ textAlign: "center" }}>
        <Link className={css.skipProcedure} to={INSTALLMENT_LOAN_ROUTES.MAIN}>
          lo haré después
        </Link>
      </div>
    </Box>
  );
};

export default FormContainer;
