import React from 'react';
import Grid, { GridSize } from '@mui/material/Grid';
import Box from '@mui/material/Box';
import useStyles from './flatList.styles';

interface IFlatList {
  title?: string;
}

export interface IFlatListItem {
  title?: string;
  subtitle?: string;
  value?: JSX.Element | string;
  gridSize?: number;
  noPadding?: boolean;
  customComponent?: React.ReactElement;
}

export const FlatListItem: React.FC<IFlatListItem> = ({
  title,
  value,
  subtitle,
  gridSize = 6,
  noPadding,
  customComponent,
}) => {
  const css = useStyles({ noPadding });

  return (
    <Grid item container xs={12} component={Box} className={css.compWrapper}>
      {customComponent ? (
        customComponent
      ) : (
        <>
          <Grid item xs={gridSize as GridSize}>
            {title && <div>{title}</div>}
            {subtitle && <div className={css.light}>{subtitle}</div>}
          </Grid>
          {!!value && (
            <Grid item xs={(12 - gridSize) as GridSize} className={`${css.light} ${css.right}`}>
              {value}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const FlatList: React.FC<IFlatList> = ({ title, children }) => {
  const css = useStyles({});
  return (
    <Grid className={css.wrapper}>
      {!!title && (
        <Grid item xs={12} className={css.titleWrapper}>
          {title}
        </Grid>
      )}
      <Grid item xs={12} className={css.flatListWrapper}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FlatList;
