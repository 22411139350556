import { Box } from "@mui/system";
import LogInIcon from "./LogInIcon.svg";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import useStyles from "./styles";
import { ROUTES } from "../../../constants/routes";

import MettaLogoHorizontal from "../../../assets/Logos/MettaLogoHorizontal.svg";

const Header = ({ isBgBlue = true }) => {
  const css = useStyles();
  const history = useHistory();

  const onPressSignup = () => {
    history.push(ROUTES.register);
  };

  return (
    <Box
      className={css.headerMainContainer}
      style={{
        backgroundImage:
          !isBgBlue &&
          "linear-gradient(to right, rgba(53, 82, 204, 0.7), rgba(53, 82, 204, 0.1))",
      }}
    >
      <img src={MettaLogoHorizontal} />
      <Box className={css.headerButtonsContainer}>
        <button
          className={css.headerLoginButtonStyle}
          onClick={() => {
            history.push("/login");
          }}
        >
          <Typography className={css.headerLoginButtonTextStyle}>
            Log in
          </Typography>
          <img src={LogInIcon} />
        </button>
        <button
          className={css.headerApplyCreditButtonStyle}
          onClick={onPressSignup}
        >
          Solicitar crédito
        </button>
      </Box>
    </Box>
  );
};

export default Header;
