import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import image from "../../../../assets/procedureType/bottom-v2.svg";
import GradientButton from "../../../../components/GradientButton";
import useStyle from "./styles";
// import customColor from "../../../../theme/customColor";

export const HomePage = () => {
  const history = useHistory();
  const css = useStyle();

  const handleRegisterClick = () => {
    history.push("/registro");
  };

  return (
    <Box className={css.bottomImageContainer}>
      <img src={image} alt="hexag-icn" />
      <Box className="content">
        <Box className="title">Tu decides el médico</Box>
        <Box className="description">
          Puedes recibir el tratamiento con el médico que tu prefieras. No estás
          limitado a una red cerrada de doctores; si tu médico no está aún
          afiliado a Medsi, ¡intégralo fácilmente!
        </Box>
        <Box className="btnContainer">
          <GradientButton
            customCss={css.registerButton}
            title="Regístrate"
            handleClick={handleRegisterClick}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
