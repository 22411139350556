import { FC, useRef } from "react";
import { Box } from "@mui/material";

import check from "../../../assets/faqs/check.svg";
import useStyle from "./styles";

interface MainContentProps {
  handleClick: any;
}

export const MainContent: FC<MainContentProps> = ({ handleClick }) => {
  const css = useStyle();
  const inputRef = useRef<any>(null);

  return (
    <Box className={css.container}>
      <Box className={css.title}>Preguntas Frecuentes</Box>
      <Box className={css.desc}>
        Aquí encontraras respuestas a la mayoría de las preguntas que pueden
        surgirte con relación a la solución.{" "}
      </Box>
      <Box className={css.btnContainer}>
        <Box width="80%">
          <input
            className="content"
            type={"text"}
            ref={inputRef}
            placeholder={
              "Ej: ¿Qué pasa si no conozco aún el costo del procedimiento?"
            }
          />
        </Box>

        <button onClick={() => handleClick(inputRef?.current?.value)}>
          Buscar
        </button>
      </Box>
      <Box className={css.checkContainer}>
        <img src={check} className="icon" />
        <Box className="content">
          Es 100% fácil y disponible 24/7 para resolver tus consultas
          instantáneas.
        </Box>
      </Box>
    </Box>
  );
};

export default MainContent;
