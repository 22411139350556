import Header from "../../../components/header";
import TwoColumns from "../../../components/twoColumns";
import SideBar from "../../../components/sidebar";
import useStyles from "../ApplicationStatus/styles";

import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import {
  selectAllApplication,
  selectLmsBalanceSummary,
  selectLmsUserAccessToken,
  selectLoanDetailStepper,
  selectStepperStatus,
  selectTakeLoanDetail,
  selectTransactionList,
} from "../../../store/slices/users";
import { useAppDispatch } from "../../../store";
import {
  getAllApplication,
  getApplicationStepper,
  getLmsBalanceSummary,
  getLoanDetails,
  getTransactionList,
  getUserAccessToken,
} from "../../../store/slices/users/actions";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import DeleteConfirmation from "../../../components/ModalContents/DeleteConfirmation";
import Panel from "../../../components/Panel";
import Disbursements from "./Disbursement";
import PanelLoan from "../../../components/PanelLoan";
import { lmsApi } from "../../../apis/api";
import { TRANSACTION_STATUS, URBANIST_FONT } from "../../../constants/common";
import { currencyFormat } from "../../../utils/index";

const TransactionAll = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [application_id, setAppId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const css = useStyles();
  const urlId = window.location.search.slice(1, window.location.search.length);
  const [txnType, setTxnType] = React.useState("disbursement");
  const [showSidebar, setShowSideBar] = React.useState(false);

  const lmsUserAccessToken = useSelector(selectLmsUserAccessToken);

  useEffect(() => {
    try {
      dispatch(getUserAccessToken());
    } catch (err) {}
  }, []);
  useEffect(() => {
    if (lmsUserAccessToken) {
      lmsApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${lmsUserAccessToken}`;
      setTimeout(() => {
        dispatch(getLmsBalanceSummary());
      }, 200);
    }
  }, [lmsUserAccessToken]);
  const handleOpen = (id) => {
    setAppId(id);
    setOpen(!open);
  };

  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);
  useEffect(() => {
    if (lmsUserAccessToken) {
      setTimeout(() => {
        dispatch(
          getTransactionList({
            cl_id:
              urlId ||
              LmsBalanceSummary?.cl_id ||
              localStorage.getItem("cl_id"),
            totalShow: 6,
            txnType: txnType,
          })
        );
      }, 200);
    }
  }, [urlId, LmsBalanceSummary, txnType, lmsUserAccessToken]);

  const transactionList = useSelector(selectTransactionList);

  const handleDisbursement = () => {
    setTxnType("disbursement");
  };

  const handleRepayment = () => {
    setTxnType("repayment");
  };

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header isBorderBottom={false} setShowSideBar={setShowSideBar} />
          </Box>

          <Box
            sx={(theme) => ({
              color: "#6B7779",
              fontSize: "24px",
              fontFamily: "SFUIText-bold",
              marginTop: "19px",
              marginLeft: "44px",
              marginBottom: "33px",
              [theme.breakpoints.down("lg")]: {
                fontSize: "18px",
                fontFamily: URBANIST_FONT.BOLD,
                marginLeft: "95px",
                marginTop: "1.5rem",
              },
              [theme.breakpoints.down("tablet")]: { marginLeft: "35px" },
            })}
          >
            Resumen de Movimientos
          </Box>

          <Box
            sx={(theme) => ({
              // marginLeft: '44px',
              // display: 'flex',
              // textAlign: 'center',
              // marginRight: '44px',
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              width: "93%",
              height: "50px",
              margin: "0 auto",
              [theme.breakpoints.down("lg")]: {
                fontFamily: URBANIST_FONT.SEMIBOLD,
                marginTop: "1.5rem",
                width: "100%",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                flex: 1,
                background: txnType === "disbursement" ? "#3552CC" : "#E6E6E6",
                color: txnType === "disbursement" ? "#FFFFFF" : "#3552CC",
                borderRadius: "5px 0px 0px 5px",
                // padding: '16px 166px',
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
                [theme.breakpoints.down("lg")]: { borderRadius: 0 },
                [theme.breakpoints.down("tablet")]: { fontSize: "14px" },
              })}
              onClick={handleDisbursement}
            >
              Desembolsos
            </Box>
            <Box
              sx={(theme) => ({
                flex: 1,
                background: txnType === "repayment" ? "#3552CC" : "#E6E6E6",
                color: txnType === "repayment" ? "#FFFFFF" : "#3552CC",
                borderRadius: "5px 0px 0px 5px",
                // padding: '16px 166px',
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
                [theme.breakpoints.down("tablet")]: { fontSize: "14px" },
              })}
              onClick={handleRepayment}
            >
              Repagos
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              margin: "10px 44px 0",
              [theme.breakpoints.down("tablet")]: {
                margin: "1rem 1rem 0",
              },
            })}
          >
            {transactionList?.map((each) => (
              <PanelLoan
                title={`Pago #${each.txn_id.split("-").pop()}`}
                body={<Disbursements each={each} />}
                txn_timeStamp={each.txn_timestamp}
                amount={currencyFormat(each.amt)}
                status={TRANSACTION_STATUS[each.status]}
              />
            ))}
          </Box>
        </>
      }
      rightBg
    />
  );
};

export default TransactionAll;
