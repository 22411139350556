import { createSlice } from "@reduxjs/toolkit";

import { API_STATUS } from "../../../constants/common";
import { ONBOARDING_TYPE } from "../../../constants/generals";
import { ROUTES } from "../../../constants/routes";
import {
  CoSignerApplicationId,
  CoSignerAuth,
  getCookie,
  onBoardingUserType,
  setCookie,
} from "../../../utils/auth";
import { getProcedureName } from "../../helpers";
import {
  additionalInfo,
  applicationSummary,
  checkReferenceCode,
  curpRegistration,
  finalInfo,
  getZipCodeInfoCosigner,
  identifyCosignerUnboardingSteps,
} from "./actions";

const initialState = {
  cosignerGeneralInformation: {
    id: "",
    cellphoneNumber: "",
    email: "",
    lastName: "",
    monthlyIncome: "",
    mothersLastName: " ",
    name: "",
    folio: "",
  },
  cosignerMainUserInformation: {
    id: "",
    applicantsName: "",
    coSignerName: "",
    applicationDate: "",
    medicName: "",
    procediments: "",
    procedureCost: "",
    creditRequestedAmount: "",
    monthlyInstallments: "",
    paymentFrequency: "",
    referenceNumber: "",
    specialtyType: "",
  },
  cosignerRfcAndCurp: {
    rfc: "",
    curp: "",
    createOn: "",
  },
  cosignerPlaceofBirth: {
    stateofBirth: "",
    gender: "",
    dateOfBirth: "",
    street: "",
    externalNumber: "",
    internalNumber: "",
    suburb: "",
    state: "",
    municipality: "",
    zip: "",
  },
  cosignerCreditHistoryInformation: {
    bankName: "",
    accountType: "",
    user: "",
    password: "",
    verifyCreditHistoryAuth: false,
    accessToBankAccountAuth: false,
    acceptedNoticeOfPrivacy: false,
  },
  cosignerIdentificationInformation: {
    typeId: "",
    frontFileName: "",
    rearFileName: "",
  },
  cosignerFinalProcessInformation: {
    signature: "",
    directDebitAuth: false,
    paymenthPreference: "",
  },
  isUserCosigner: false,
  isAuthorized: false,
  coSignerToken: "",
  coSignerApplicationId: "",
  currentCoSignerStep: 1,
  cosignerPhoneNumber: "",
  cosignerDetails: {},
  cosignerUserDetails: {},
};

export const coSignerStepNavigatorMapping = {
  //General Data(CURP/RFC)
  1: ROUTES.generalData,
  //Personal Information
  2: ROUTES.personalInformation,
  //Identity Information
  3: ROUTES.coSigneridentityInformation,
  //Final Info(Signature)
  4: ROUTES.clientSignature,
  // Credit confirmation
  5: ROUTES.creditConfirmation,
};

export const cosignerInformation = createSlice({
  name: "cosigner",
  initialState,
  reducers: {
    setIsAuthorized: (state, { payload }) => {
      state.isAuthorized = payload;
    },
    setIsUserCosigner: (state, { payload }) => {
      state.isUserCosigner = payload;
    },
    setCosignerFolioNumber: (state, { payload }) => {
      state.cosignerGeneralInformation.folio = payload;
    },
    setCosignerGeneralInformation: (state, { payload }) => {
      state.cosignerGeneralInformation = payload;
    },
    setCosignerMainUserInformation: (state, { payload }) => {
      state.cosignerMainUserInformation = payload;
    },
    setCosignerRfcAndCurp(state, { payload }) {
      state.cosignerRfcAndCurp = payload;
    },
    setCosignerPlaceofBirth(state, { payload }) {
      state.cosignerPlaceofBirth = payload;
    },
    setCosignerCreditHistoryInformation(state, { payload }) {
      state.cosignerCreditHistoryInformation = payload;
    },
    setCosignerIdentificationInformation(state, { payload }) {
      state.cosignerIdentificationInformation = payload;
    },
    setCosignerFinalProcessInformation(state, { payload }) {
      state.cosignerFinalProcessInformation = payload;
    },
    resetBooleanValuesCosigner(state) {
      state.isAuthorized = false;
      state.isUserCosigner = false;
    },
    resetCosingerInfo(state) {
      state.cosignerGeneralInformation.id = "";
      state.cosignerGeneralInformation.cellphoneNumber = "";
      state.cosignerGeneralInformation.email = "";
      state.cosignerGeneralInformation.lastName = "";
      state.cosignerGeneralInformation.monthlyIncome = "";
      state.cosignerGeneralInformation.mothersLastName = " ";
      state.cosignerGeneralInformation.name = "";
      state.cosignerGeneralInformation.folio = "";
    },
    setCoSignerToken(state, { payload }) {
      if (payload?.token) {
        state.coSignerToken = payload?.token;
        setCookie(payload?.token, CoSignerAuth);
      }
      if (payload?.app_id) {
        state.coSignerApplicationId = payload?.app_id;
        setCookie(payload?.app_id, CoSignerApplicationId);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkReferenceCode.fulfilled, (state, { payload }) => {
      if (API_STATUS?.SUCCESS === payload?.status) {
        setCookie(ONBOARDING_TYPE.CO_SIGNER, onBoardingUserType);
      }
    });
    builder.addCase(applicationSummary.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.cosignerMainUserInformation.applicantsName = data.user_name;
        state.cosignerMainUserInformation.coSignerName = data["co-signer_name"];
        state.cosignerMainUserInformation.applicationDate =
          data.application_date;
        state.cosignerMainUserInformation.medicName = data.doctor_name;
        state.cosignerMainUserInformation.procType = data.procedure_type;
        state.cosignerMainUserInformation.procediments = getProcedureName(
          data.procedure_name,
          data.other_procedure
        );
        state.cosignerMainUserInformation.procedureCost =
          data.cost_of_procedure;
        state.cosignerMainUserInformation.creditRequestedAmount =
          data.loan_amount;
        state.cosignerMainUserInformation.monthlyInstallments =
          data.loan_tenure;
        state.cosignerMainUserInformation.monthlyPayment = data.term_amount;
        state.cosignerMainUserInformation.paymentFrequency =
          data.payment_frequency;
        state.cosignerMainUserInformation.referenceNumber = data.reference_code;
        state.cosignerMainUserInformation.specialtyType = data.speciality_type;
      }
    });
    // general data: rfc & curp
    builder.addCase(curpRegistration.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.cosignerRfcAndCurp = data;
      }
    });
    builder.addCase(additionalInfo.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.cosignerPlaceofBirth = data;
      }
    });
    builder.addCase(finalInfo.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.cosignerFinalProcessInformation = data;
      }
    });
    builder.addCase(
      identifyCosignerUnboardingSteps.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.cosignerPhoneNumber = data?.phone_number || "";
          state.cosignerDetails = data?.co_signer_details || {};
          state.cosignerUserDetails = data?.user_details || {};
          state.currentCoSignerStep = Number(data?.step_no);
        }
      }
    );
  },
});

export const {
  setIsUserCosigner,
  setCosignerFolioNumber,
  setCosignerGeneralInformation,
  setCosignerRfcAndCurp,
  setCosignerMainUserInformation,
  setCosignerPlaceofBirth,
  setCosignerCreditHistoryInformation,
  setCosignerIdentificationInformation,
  setCosignerFinalProcessInformation,
  resetBooleanValuesCosigner,
  resetCosingerInfo,
  setCoSignerToken,
} = cosignerInformation.actions;

export const selectIsCosigner = () => {
  const cookie = getCookie(onBoardingUserType);
  return cookie === ONBOARDING_TYPE.CO_SIGNER;
};
export const selectCosignerInfo = (state) =>
  state.cosigner ? state.cosigner.cosignerGeneralInformation : null;
export const selectApplicantInfo = (state) =>
  state.cosigner ? state.cosigner.cosignerMainUserInformation : null;
export const selectId = (state) =>
  state.cosigner.cosignerGeneralInformation
    ? state.cosigner.cosignerGeneralInformation.id
    : null;
export const selectCoSignerToken = () => {
  const cookie = getCookie(CoSignerAuth);
  return cookie;
};
export const selectCurrentCoSignerStep = (state) =>
  state.cosigner ? state.cosigner.currentCoSignerStep : null;
export const selectCurrentCoSignerPhoneNumber = (state) =>
  state.cosigner ? state.cosigner.cosignerPhoneNumber : null;
export const selectCurrentCoSignerDetails = (state) =>
  state.cosigner ? state.cosigner.cosignerDetails : null;
export const selectCurrentCoSignerUserDetails = (state) =>
  state.cosigner ? state.cosigner.cosignerUserDetails : null;

export default cosignerInformation.reducer;

export {
  checkReferenceCode,
  applicationSummary,
  curpRegistration,
  additionalInfo,
  finalInfo,
  identifyCosignerUnboardingSteps,
  getZipCodeInfoCosigner,
};
