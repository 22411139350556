import { makeStyles } from "@mui/styles";
import desktopBg from "../../assets/ConocerteMejor/desktopBg.svg";
import tabletBg from "../../assets/ConocerteMejor/mobileBg.svg";
import mobileBg from "../../assets/ConocerteMejor/backgroundMobile.svg";
import tabletHeader from "../../assets/ConocerteMejor/tabletHeader.svg";
import mobileHeader from "../../assets/ConocerteMejor/headerMobile.svg";
import desktopHeader from "../../assets/ConocerteMejor/desktopHeader.svg";

const ConocerteMejorStyles = makeStyles((theme) => ({
  headerPageBackground: {
    height: 280,
    backgroundImage: ` url(${desktopHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: ` url(${tabletHeader})`,
      height: 230,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: ` url(${mobileHeader})`,
      height: 168,
    },
  },
  pageContainer: {
    backgroundImage: `url(${mobileBg})`,
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ` url(${mobileBg})`,
    },
    [theme.breakpoints.between("sm", "lg")]: {
      backgroundImage: ` url(${tabletBg})`,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: ` url(${desktopBg})`,
    },
  },
  logo: {
    width: 90,
    marginTop: -265,
    [theme.breakpoints.down(1001)]: {
      marginTop: -200,
    },
    [theme.breakpoints.down(601)]: {
      width: 60,
      marginTop: -140,
    },
  },
  textTitle: {
    color: theme.palette.primary.white,
    marginTop: "5px !important",
    marginBottom: "140px !important",
    [theme.breakpoints.down(1001)]: {
      marginBottom: "120px !important",
    },
    [theme.breakpoints.down(601)]: {
      marginBottom: "100px !important",
      fontSize: "22px !important",
    },
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
  },
  labelForm: {
    color: "#3552CC",
    fontWeight: 400,
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 25,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 20,
    },
  },
  formControlSize: {
    width: "35ch",
    marginTop: "5px !important",
    marginBottom: "20px !important",
  },
  formHelperText: {
    color: "red",
  },
  formControl: {
    marginTop: "5px !important",
    marginBottom: "10px !important",
  },
  cosignerInfo: {
    marginTop: "20px !important",
    marginBottom: "20px !important",
  },
  formControlText: {
    maxWidth: 450,
    marginTop: "5px !important",
    marginBottom: "10px !important",
  },
  text: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontWeight: "700 !important",
    fontSize: "1rem !important",
    marginBottom: "2px !important",
    marginTop: "2px !important",
    marginLeft: "53px !important",
    marginRight: "45px !important",
    [theme.breakpoints.down(900)]: {
      width: "260px !important",
    },
  },
}));

export default ConocerteMejorStyles;
