const v2 = "v2";
const medsiLoan = "/v2/lms";
export const medsiV2Auth = {
  register: `${v2}/register/`,
  generalInfo: `${v2}/user_info/`,
  dashboardDetail: `${v2}/get_dashboard_details/`,
  creditDecision: `${v2}/lms/credit-decision/`,
  procedureInfo: `${v2}/procedure_info/`,
  verifyPromoCode: `${v2}/validate_promocode/`,
  identifyUnboarding: `${v2}/get_latest_step/`,
  creditDetails: `${v2}/get_latest_step/`,
  saveLoanDetail: `${medsiLoan}/loan/create/`,
  subscribe: `${medsiLoan}/subscribe/create/`,
  addCosigner: `${v2}/add_cosigner_details/`,
  userPromoCode: `${v2}/get_user_promo_code/`,
  submitPromocode: `${v2}/promocode/`,
  sendOtp: `${v2}/otp/send/`,
  verifyOtp: `${v2}/otp/verify/`,
  resendOtp: `${v2}/otp/resend/`,
  sendOtpWithAuth: `${v2}/otp/token-attachment/send/`,
  verifyOtpWithAuth: `${v2}/otp/token-attachment/verify/`,
  resendOtpWithAuth: `${v2}/otp/token-attachment/resend/`,

  savePatientGroup: `${v2}/patient_group/`,
};

export default medsiV2Auth;
