import { Grid } from "@mui/material";

import CenteredContent from "../../../../components/sharedComponents/CenteredContent";
import InputText from "../../../../components/sharedComponents/InputText";
import DropdownMenu from "../../../../components/sharedComponents/DropdownMenu";

import styles from "../styles";

export const RenderTextInput = ({ label, value, formik, disabled = false }) => {
  const css = styles();
  const { values, touched, errors, handleBlur, handleChange } = formik;

  return (
    <InputText
      stylesInput={css.formControl}
      label={label}
      value={values[value]}
      name={value}
      handleChange={handleChange}
      errors={errors[value]}
      touched={touched[value]}
      onBlur={handleBlur}
      disabled={disabled}
    />
  );
};

export const RenderDropDown = ({
  label,
  value,
  formik,
  options,
  disabled = false,
  customCss,
  listItemSelectedStyle,
}) => {
  const { values, touched, errors, handleBlur, handleChange } = formik;
  const css = styles();

  return (
    <DropdownMenu
      stylesInput={css.formControl}
      label={label}
      optionsObject={options || []}
      name={value}
      handleChange={handleChange}
      value={values[value]}
      onBlur={handleBlur}
      errors={errors[value]}
      touched={touched[value]}
      disabled={disabled}
      listItemStyle={customCss}
      listItemSelectedStyle={listItemSelectedStyle}
    />
  );
};
