import { makeStyles } from "@mui/styles";
const ProcessInformationStyles = makeStyles((theme) => ({
  backgroundContainer: {
    minHeight: "100vh",
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1920 1080'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23linear-gradient);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;isolation:isolate;%7D%3C/style%3E%3ClinearGradient id='linear-gradient' y1='540.4419' x2='1920.7266' y2='540.4419' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatos Procedimiento Back Desktop%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' width='1920.7266' height='1080.8838'/%3E%3C/g%3E%3Cg id='Onda'%3E%3Cpath class='cls-2' d='M-.137,944.9973s357.4594-12.7285,735.0723,76.3712,1183.7529-8.4857,1183.7529-57.2784v117.4048H0Z'/%3E%3C/g%3E%3C/svg%3E")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Capa_3' data-name='Capa 3' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 768 1024'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23Degradado_sin_nombre_3);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;isolation:isolate;%7D%3C/style%3E%3ClinearGradient id='Degradado_sin_nombre_3' y1='511.9922' x2='767.9775' y2='511.9922' gradientTransform='matrix(1, 0, 0, -1, 0, 1024)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatos del procedimiento back tablet%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' y='-0.1758' width='767.9775' height='1024.3672'/%3E%3C/g%3E%3Cpath class='cls-2' d='M5.1562,902.3828s65.9805-12.8935,140.0958-12.8935c74.1171,0,155.46,12.8935,229.5751,46.3261,74.1172,33.4317,166.3067,53.97,247.6543,53.97S768,975.4551,768,975.4551V1024H5.1562Z'/%3E%3C/svg%3E")`,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Capa_1' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 414 736'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23Degradado_sin_nombre);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;isolation:isolate;%7D%3C/style%3E%3ClinearGradient id='Degradado_sin_nombre' x1='-0.8374' y1='368.1108' x2='413.3828' y2='368.1108' gradientTransform='matrix(1, 0, 0, -1, 0, 736)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatos del procedimiento back elementos mobile-01%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' x='-0.8374' y='0.4834' width='414.2202' height='734.8115'/%3E%3C/g%3E%3Cg id='Onda'%3E%3Cpath class='cls-2' d='M0,669.5439a449.585,449.585,0,0,1,76.5532-7.0449c40.5005,0,84.9488,7.0449,125.4473,25.3145,40.5005,18.2676,90.8755,29.49,135.3266,29.49a405.4534,405.4534,0,0,0,79.5167-7.83V736H0Z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
  },
  headerContainer: {
    height: 350,
    width: "100%",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='1920' height='344' viewBox='0 0 1920 344' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M234.277 235.356C234.277 235.356 526.409 291.887 800.29 176.731C800.29 176.731 620.309 109.731 234.277 235.356Z' fill='%23ABCED4'/%3E%3Cpath d='M0 330C0 330 393 158 627 158C861 158 1113 310 1403 310C1693 310 1920 166 1920 166V1H0V330Z' fill='%233552CC'/%3E%3Cpath d='M1490.98 0H1920V165.38C1920 165.38 1764.16 274.613 1531.89 299C1531.89 299 1459.37 168.52 1490.98 0Z' fill='%234568D8'/%3E%3Cpath d='M0 344V330V316.499C13.4194 309.8 339.948 148.751 621.099 148.751C760.545 148.751 879.404 186.523 994.335 223.062C1114.55 261.303 1238.87 300.799 1393.24 300.799C1690 300.799 1917.72 152.82 1919.92 151.392V177.5C1910.41 183.807 1709.55 319.313 1393.24 319.313C1228.65 319.313 1099.44 278.223 974.445 238.499C859.209 201.862 750.377 167.264 621.099 167.264C355.266 167.265 3.1636 342.428 0 344Z' fill='url(%23paint0_linear_612_299)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_612_299' x1='0' y1='246.268' x2='1919.92' y2='246.268' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%233552CC'/%3E%3Cstop offset='1' stop-color='%2395D0DD'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 416.4 168.48'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23abced4;%7D.cls-2%7Bfill:%233552cc;%7D.cls-3%7Bfill:%234568d8;%7D.cls-4%7Bfill:url(%23linear-gradient);%7D%3C/style%3E%3ClinearGradient id='linear-gradient' x1='0.66' y1='251.09' x2='416.4' y2='251.09' gradientTransform='translate(0 -105.89)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233552cc'/%3E%3Cstop offset='1' stop-color='%2395d0dd'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatosProcedimientoHeader%3C/title%3E%3Cg id='Layer_2' data-name='Layer 2'%3E%3Cg id='Capa_2' data-name='Capa 2'%3E%3Cg id='Header'%3E%3Cpath class='cls-1' d='M26.41,147.53s84.22,16.3,163.18-16.9C189.59,130.63,137.7,111.32,26.41,147.53Z'/%3E%3Cpath class='cls-2' d='M0,.11V161s80.87-35.71,134.43-35.71,107.67,32.47,169.36,32.47,112-30.3,112-30.3V0Z'/%3E%3Cpath class='cls-3' d='M275.62.27H415.5V122.7S368.78,158.94,289,155.52C289,155.52,265.31,91.49,275.62.27Z'/%3E%3Cpath class='cls-4' d='M.66,168.48v-9.87c2.9-1.45,74.35-36.69,135.11-36.69,30.14,0,55.82,8.17,80.66,16.06,26,8.27,52.85,16.8,86.2,16.8,64.13,0,113.74-32.18,113.74-32.18l0,9.47c-2.06,1.36-45.41,30.63-113.77,30.63-35.56,0-63.49-8.87-90.5-17.46-24.9-7.92-48.42-15.39-76.36-15.39C78.32,129.85.66,168.48.66,168.48Z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
      height: 290,
    },
    [theme.breakpoints.down(601)]: {
      height: 250,
    },
  },
  mettaLogo: {
    width: 90,
    [theme.breakpoints.down(601)]: {
      width: 60,
    },
  },
  headerTextContainer: {
    margin: "auto",
    marginTop: -325,
    width: "60%",
    [theme.breakpoints.down(1001)]: {
      marginTop: -270,
      width: "80%",
    },
    [theme.breakpoints.down(601)]: {
      marginTop: -235,
      width: "90%",
    },
  },
  formContainer: {
    marginTop: 140,
    [theme.breakpoints.down(1001)]: {
      marginTop: 110,
    },
    [theme.breakpoints.down(601)]: {
      marginTop: 80,
    },
  },
  headerText: {
    fontSize: "37px !important",
    color: theme.palette.primary.white,
    marginTop: "5px !important",
    fontWeight: "700 !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "25px !important",
    },
  },
  checkBoxContainer: {
    [theme.breakpoints.down("md")]: {
      width: 260,
    },
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  button: {
    marginTop: 30,
    marginBottom: 30,
  },
  formControl: {
    marginTop: "5px !important",
    marginBottom: "10px !important",
  },
  selectTime: {
    height: 37,
    width: 90,
    borderRadius: "10px !important",
    marginRight: 20,
  },
  formControlContainer: {
    [theme.breakpoints.down("md")]: {
      width: 260,
    },
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
  titleTime: {
    textAlign: "left",
    color: theme.palette.primary.main,
    fontWeight: "bold !important",
    marginBottom: "-10px !important",
  },
  errorTime: {
    color: "red !important",
    [theme.breakpoints.down("md")]: {
      width: 260,
    },
    [theme.breakpoints.up("md")]: {
      width: "50ch",
    },
  },
}));

export default ProcessInformationStyles;
