import { Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

import { customColor } from "../../theme/customColor";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    payNowGradientButtonStyle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      borderRadius: 6,
      minWidth: 112,
      minHeight: 40,
      padding: "0px 15px",
      border: "none",
      fontFamily: "SFUIText-Bold",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "18px",
      lineHeight: "1.2",
      color: customColor.cotton,
      backgroundImage: "linear-gradient(94deg, #ABCED4,#4893CC)",
      margin: "0px 0px 0px 15px",
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      [theme.breakpoints.down(601)]: {
        fontSize: "14px",
      },
    },
  })
);

export default useStyles;
