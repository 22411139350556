import useStyles from "./styles";

import DropdownMenu from "../../../components/sharedComponents/DropdownMenu";
import { time, hours, minutes } from "../../../constants";

export const RenderTimeInput = ({
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  disabled = false,
}) => {
  const css = useStyles();

  return (
    <>
      <DropdownMenu
        classSelect={css.selectTime}
        optionsObject={hours}
        name="hours"
        value={values.hours}
        handleChange={handleChange}
        onBlur={handleBlur}
        errors={errors.hours}
        touched={touched.hours}
        disabled={disabled}
      />
      <DropdownMenu
        classSelect={css.selectTime}
        optionsObject={minutes}
        name="minutes"
        value={values.minutes}
        handleChange={handleChange}
        onBlur={handleBlur}
        errors={errors.minutes}
        touched={touched.minutes}
        disabled={disabled}
      />
      <DropdownMenu
        classSelect={css.selectTime}
        optionsObject={time}
        name="time"
        value={values.time}
        handleChange={handleChange}
        onBlur={handleBlur}
        errors={errors.time}
        touched={touched.time}
        disabled={disabled}
      />
    </>
  );
};

export default RenderTimeInput;
