import { URBANIST_FONT } from "../../../constants/common";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles(() => ({
  textInputIconStyle: {
    color: customColor.cotton,
  },
  input: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    "& input": {
      width: 260,
      minWidth: 260,
    },
  },
  passwordItemsContainer: {
    marginLeft: 20,
    marginBottom: 20,
  },
  textClass: {
    color: customColor.cotton,
  },
  signupTextStyle: {
    color: `${customColor.lightAccentBlue} !important`,
    textTransform: "none !important" as "none",
    padding: "0px 0px 4px !important",
    fontWeight: "500 !important",
    fontSize: "18px !important",
  },

  title: {
    fontFamily: URBANIST_FONT.REGULAR,
    fontSize: 16,
    lineHeight: "19px",
    textAlign: "center",

    color: "#000000",
  },

  otpTitle: {
    fontFamily: URBANIST_FONT.MEDIUM,
    fontSize: 14,
    lineHeight: "17px",
    textAlign: "center",
    color: "#000000",
  },

  loginButtonStyle: {
    backgroundImage: "linear-gradient(94deg, #ABCED4,#4893CC)",
    marginTop: "125px !important",
    width: "180px",
    height: "50px",
    fontSize: "18px !important",
    fontWeight: "bold !important",
    borderRadius: "14px !important",
  },

  logoStyle: {
    height: 40,
    width: 94,
    margin: "0px auto",
  },
  resendButton: {
    fontFamily: URBANIST_FONT.BOLD,
    fontSize: 12,
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    letterSpacing: "0.2px",
    color: "#CC3535",
    textShadow: "4px 8px 24px rgba(36, 107, 253, 0.25)",

    cursor: "pointer",
  },

  timer: {
    fontFamily: URBANIST_FONT.BOLD,
    fontSize: 12,
    lineHeight: "140%",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    letterSpacing: "0.2px",
    color: "#CC3535",
    textShadow: "4px 8px 24px rgba(36, 107, 253, 0.25)",
  },
}));

export default useStyles;
