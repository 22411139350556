import { makeStyles } from "@mui/styles";
import { customColor } from "../../../../theme/customColor";

const OdontologyStyles = makeStyles((theme) => ({
  heading: {
    color: customColor.black,
    fontSize: 32,
    fontFamily: "Nexa-Bold",
    fontWeight: "700",
    lineHeight: 1.2,
    marginTop: 5,
  },
  title: {
    fontFamily: "Urbanist-SemiBold",
    fontSize: 28,
    color: "rgba(0, 0, 0, 0.5)",
    marginLeft: 22,
  },
  tableView: {
    marginTop: `${theme.spacing(4)} !important`,
    padding: "0 12% !important",
    "&> div": {
      color: "rgba(0, 0, 0, 0.5)",
      fontFamily: "SFUIText-Regular",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 18,
      lineHeight: 1.2,
      "&>div:first-child": {
        fontWeight: "700",
        color: customColor.black,
      },
      "&>div:last-child": {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export default OdontologyStyles;
