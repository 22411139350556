import { Theme } from '@mui/material/styles';
import { createStyles, makeStyles, withStyles } from '@mui/styles';

import { customColor } from '../../../../theme/customColor';

export const useTabContainerStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    indicator: {
      width: 0,
      backgroundColor: customColor.oyster,
    },
  })
);

export const useTabStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none !important' as 'none',
      flexDirection: 'row !important' as 'row',
      minWidth: '100%',
      maxWidth: '100% !important',
      justifyContent: 'flex-start !important',
      width: '100%',
      // paddingRight: `${theme.spacing(3)} !important`,
      paddingRight: '10% !important',
      // paddingLeft: `${theme.spacing(6)} !important`,
      paddingLeft: '12% !important',
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontSize: '20px !important',
      fontWeight: '700 !important',
      color: `${customColor.accentBlue} !important`,
      textAlign: 'left !important' as 'left',
      opacity: 1,

      '& svg': {
        fontSize: '24px !important',
        marginRight: theme.spacing(2),
        fill: customColor.accentBlue,
        color: 'inherit',
        [theme.breakpoints.down('tablet')]: {
          fontSize: '20px !important',
          width: '24px !important',
        },
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(1.5),
        paddingBottom: theme.spacing(1.5),
        minWidth: 50,
        width: 'auto',
      },
      [theme.breakpoints.down('xl')]: {
        color: `${customColor.white} !important`,
        fill: `${customColor.white} !important`,
      },
      // [theme.breakpoints.down('tablet')]: {
      //   '& .MuiTab-root.Mui-selected': {
      //     padding: '10px !important',
      //   },
      // },
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
      },
    },
    selected: {
      fontWeight: '600 !important',
      color: `${customColor.accentBlue} !important`,
      fill: `${customColor.accentBlue} !important`,
      borderLeft: `6px solid ${customColor.accentBlue} !important`,
      background: `${customColor.cotton} !important`,
      '&::before': {
        content: '♥',
        height: '10px',
        width: '10px',
        position: 'absolute',
        top: -10,
        backgroundColor: 'red',
      },
    },
    labelIcon: {
      [theme.breakpoints.down('sm')]: {
        minHeight: 50,
      },
    },
    indicator: {
      backgroundColor: `${customColor.cotton} !important`,
    },
    chip: {
      fontSize: 10,
      border: '1px solid',
      padding: '3px 7px',
      borderRadius: 10,
      width: 'max-content',
      color: customColor.mango,
      fontFamily: 'SFUIText-Bold',
    },
    head: {
      height: 16,
      [theme.breakpoints.down('tablet')]: { height: 0 },
    },
    bottom: {
      height: 16,
      [theme.breakpoints.down('tablet')]: { height: 0 },
    },
    title: {
      [theme.breakpoints.down('xl')]: {
        color: '#fff',
      },
    },
    titleStyle: {
      [theme.breakpoints.down('lg')]: {
        fontFamily: 'Urbanist-Regular !important',
      },
      [theme.breakpoints.down('tablet')]: {
        fontSize: '16px !important',
      },
    },
  })
);

export const useTooltipStyles = withStyles((theme: Theme) =>
  createStyles({
    arrow: {
      '&:before': {
        border: `1px solid ${customColor.cotton}`,
        boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%), 
          0px 8px 10px 1px rgb(0 0 0 / 14%),
          0px 3px 14px 2px rgb(0 0 0 / 12%)`,
      },
      color: customColor.cotton,
    },
    tooltip: {
      boxShadow: `0px 5px 5px -3px rgb(0 0 0 / 20%),
        0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%)`,
      backgroundColor: customColor.cotton,
      borderRadius: 8,
      padding: 10,
      fontWeight: 400,
      color: customColor.charcoal,
      fontSize: theme.typography.pxToRem(12),
    },
  })
);
