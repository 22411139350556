import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import GradientButton from "../../components/GradientButton";
import { GTMClick } from "../../utils/gtm";
import { homePage } from "../../constants/gtm";
import useStyle from "./styles";
import { URBANIST } from "../../constants/fontsConst";

export const HomePage = () => {
  const css = useStyle();
  const history = useHistory();

  const handleRegisterClick = () => {
    GTMClick(homePage.signUpHeader);
    history.push("/registro");
  };

  return (
    <Box className={css.video3} mt={4} flexDirection="column">
      <Box position="relative">
        <Typography
          align="center"
          sx={{
            fontFamily: URBANIST.SEMIBOLD,
            fontSize: 22,
          }}
        >
          ¡En Medsi te decimos que sí!
        </Typography>
      </Box>
      <Box mt={4}>
        <GradientButton
          title="Quiero solicitar mi línea familiar"
          handleClick={handleRegisterClick}
          customCss={css.btnCredit}
        />
      </Box>
    </Box>
  );
};

export default HomePage;
