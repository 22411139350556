import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";

import ClientSignature from "./pages/ClientSignatureV2";
import CreditConfirmation from "./pages/CreditConfirmation";
import GeneralData from "./pages/GeneralData";
import PersonalInformation from "./pages/PersonalInformation";
import QrCode from "./pages/QrCode";
import ReferenceNumber from "./pages/ReferenceNumber";
import SumaryCoSigner from "./pages/SumaryCoSigner";
import ThankYou from "./pages/ThankYou";

import ContractDetails from "./containers/ContractDetails";
import EmailVerification from "./containers/emailVerification";
import ForgotPassword from "./containers/forgotPassword";
import IdentityInformation from "./containers/IdentityInformation";
import Login from "./containers/login";
import NotFound from "./containers/NotFound";
import PaymentSchedule from "./containers/paymentSchedule";
import ResetPassword from "./containers/resetPassword";
import DashboardLoan from "./containers/userflowDashboard";
import Stepper from "./containers/userflowDashboard/StepperPage";
import CreditHistory from "./features/CreditHistory";
import PaymentDetails from "./features/installmentLoan/containers/paymentDetails";
import GeneralInformation from "./pages/GeneralInformation";
import KnowYouBetter from "./pages/KnowYouBetter";
import ProfessionalDetails from "./pages/ProfessionalDetails";
// import ProcessInformation from "./containers/ProcessInformation";
import AboutUs from "./containers/AboutUs";
import Faqs from "./containers/Faqs";
import HomeScreen2 from "./containers/home-v2";
import LandingCreditRequest from "./containers/home-v2/CreditRequest";
import ProcedureType from "./containers/ProcedureType";
import ProcessInformation from "./containers/ProcessInformation/v2";
import HomeScreen from "./pages/Home";

import {
  AUTH_ROUTES,
  INSTALLMENT_LOAN_ROUTES,
  ROUTES,
} from "./constants/routes";
import Register from "./containers/auth/Register";
import CosignerConsentReferenceNumber from "./containers/Cosigner/ReferenceNumber";
import CosignerConsentSignature from "./containers/Cosigner/Signature";
import CosignerThankYouPage from "./containers/Cosigner/ThankYouPage";
import PendingInformation from "./containers/PendingInformationV2";
import Contact from "./containers/static/Contact";
import PrivacyPolicy from "./containers/static/PrivacyPolicy";
import SpecializedServiceUnit from "./containers/static/SpecializedServiceUnit";
import TnC from "./containers/static/TnC";
import CreditRequest from "./pages/CreditRequest";
import LogInReport from "./pages/LogInReport";
import Report from "./pages/Report";

import ApplicationDetailPage from "./containers/ApplicationDetailPage";
import CoSignerIdentityInformation from "./containers/CoSignerIdentityInformation";
import DoctorBankPage from "./containers/DoctorBankPage";
import MedsiInTheNewsBlog from "./containers/MedsiInTheNewsBlog";
import PublicPromocode from "./containers/PublicPromocode";
import UnderReviewKYC from "./containers/underReviewKYC";
import AllApplication from "./containers/userflowDashboard/AllApplication";
import LoanAll from "./containers/userflowDashboard/LoanHistory";
import MedsiOffer from "./containers/userflowDashboard/MedsiOffer";
import PaymentAll from "./containers/userflowDashboard/PaymentSchedule";
import StatementAll from "./containers/userflowDashboard/Statement";
import TransactionAll from "./containers/userflowDashboard/TransactionAll";

const reload = () => window.location.reload();

const AppRouter = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact component={Login} path={AUTH_ROUTES.LOGIN} />
        <PublicRoute
          restricted={false}
          component={HomeScreen2}
          path={ROUTES.splashScreen}
          exact
        />
        <PublicRoute
          restricted={false}
          component={HomeScreen}
          path={"/home2"}
          exact
        />
        <PublicRoute
          restricted={false}
          component={MedsiInTheNewsBlog}
          path={"/news"}
          exact
        />
        <PublicRoute
          restricted={false}
          component={Register}
          path={ROUTES.register}
          exact
        />
        <PublicRoute
          restricted={false}
          component={LandingCreditRequest}
          path={ROUTES.landingCreditRequest}
          exact
        />

        <PublicRoute
          restricted={false}
          component={PublicPromocode}
          path={ROUTES.publicPromocode}
          exact
        />

        <PublicRoute
          restricted={false}
          component={CosignerConsentReferenceNumber}
          path={ROUTES.cosignerConsentReferenceNumber}
          exact
        />

        <PublicRoute
          restricted={false}
          component={CosignerConsentSignature}
          path={ROUTES.cosignerConsentSignature}
          exact
        />

        <PublicRoute
          restricted={false}
          component={CosignerThankYouPage}
          path={ROUTES.cosignerThankyYouPage}
          exact
        />

        <PrivateRoute
          component={GeneralInformation}
          path={ROUTES.generalInformation}
          exact
        />
        <PrivateRoute
          component={ProfessionalDetails}
          path={ROUTES.professionalInformation}
          exact
        />
        <PrivateRoute
          component={ProcessInformation}
          path={ROUTES.processInformation}
          exact
        />

        {/* <PrivateRoute
          component={ProcessInformation}
          path={ROUTES.processInformation + '2'}
          exact
        /> */}
        <PrivateRoute
          component={CreditRequest}
          path={ROUTES.CREDIT_REQUEST}
          exact
        />
        <PrivateRoute
          component={KnowYouBetter}
          path={ROUTES.knowYouBetter}
          exact
        />
        <PrivateRoute
          component={CreditHistory}
          path={ROUTES.creditHistory}
          exact
        />
        <PrivateRoute component={GeneralData} path={ROUTES.generalData} exact />
        <PrivateRoute
          component={ClientSignature}
          path={ROUTES.clientSignature}
          exact
        />
        <PrivateRoute
          component={PersonalInformation}
          path={ROUTES.personalInformation}
          exact
        />

        <PrivateRoute
          component={CreditConfirmation}
          path={ROUTES.creditConfirmation}
          exact
        />
        <PrivateRoute component={QrCode} path={ROUTES.qrCode} exact />
        <PrivateRoute
          component={IdentityInformation}
          path={ROUTES.identityInformation}
          exact
        />

        <PrivateRoute
          component={UnderReviewKYC}
          path={ROUTES.underReviewKYC}
          exact
        />

        <PrivateRoute
          component={CoSignerIdentityInformation}
          path={ROUTES.coSigneridentityInformation}
          exact
        />

        <PrivateRoute component={MedsiOffer} path={ROUTES.medsiOffer} exact />
        <PrivateRoute
          restricted={false}
          component={ThankYou}
          path={ROUTES.thankYou}
        />
        <PublicRoute
          restricted={false}
          component={SumaryCoSigner}
          path={ROUTES.sumaryCoSigner}
        />
        <PublicRoute
          restricted={false}
          component={ReferenceNumber}
          path={ROUTES.referenceNumber}
        />
        <PublicRoute
          restricted={false}
          component={Report}
          path={ROUTES.report}
        />
        <PublicRoute
          restricted={false}
          component={LogInReport}
          path={ROUTES.sigIn}
        />
        <PublicRoute
          restricted={false}
          exact
          component={DashboardLoan}
          path={INSTALLMENT_LOAN_ROUTES.MAIN}
        />

        <PrivateRoute
          exact
          restricted={false}
          component={Stepper}
          path={ROUTES.stepper}
        />

        <PrivateRoute
          exact
          restricted={false}
          component={AllApplication}
          path={ROUTES.allApplication}
        />

        <PrivateRoute
          exact
          restricted={false}
          component={StatementAll}
          path={ROUTES.allStatement}
        />

        <PrivateRoute
          exact
          restricted={false}
          component={TransactionAll}
          path={ROUTES.allTransactions}
        />

        <PrivateRoute
          exact
          restricted={false}
          component={LoanAll}
          path={ROUTES.allLoan}
        />

        <PrivateRoute
          exact
          restricted={false}
          component={PaymentAll}
          path={ROUTES.allPayment}
        />

        <PublicRoute
          exact
          restricted={false}
          component={PaymentSchedule}
          path={INSTALLMENT_LOAN_ROUTES.PAYMENT_SCHEDULE}
        />

        <PublicRoute
          exact
          restricted={false}
          component={ForgotPassword}
          path={AUTH_ROUTES.FORGOT_PASSWORD}
        />
        <PublicRoute
          exact
          restricted={false}
          component={ResetPassword}
          path={AUTH_ROUTES.RESET_PASSWORD}
        />
        <PublicRoute
          exact
          restricted={false}
          component={EmailVerification}
          path={AUTH_ROUTES.EMAIL_VERIFICATION}
        />
        <PublicRoute
          exact
          restricted={false}
          component={PaymentDetails}
          path={INSTALLMENT_LOAN_ROUTES.PAYMENT_DETAILS}
        />
        <PrivateRoute
          exact
          restricted={false}
          component={ContractDetails}
          path={INSTALLMENT_LOAN_ROUTES.CONTRACT_DETAILS}
        />
        <PrivateRoute
          exact
          restricted={false}
          component={PendingInformation}
          path={ROUTES.pendingInformation}
        />
        <PublicRoute
          exact
          restricted={false}
          component={DoctorBankPage}
          path={ROUTES.doctorConfirmation}
        />

        <PublicRoute
          exact
          restricted={false}
          component={ApplicationDetailPage}
          path={ROUTES.applicationConfirmation}
        />

        <PublicRoute component={TnC} path={ROUTES.tnc} exact />
        <PublicRoute
          component={PrivacyPolicy}
          path={ROUTES.privacyPolicy}
          exact
        />
        <PublicRoute
          component={SpecializedServiceUnit}
          path={ROUTES.specializedServiceUnit}
          exact
        />
        <PublicRoute component={Contact} path={ROUTES.contact} exact />
        <PublicRoute component={AboutUs} path={ROUTES.aboutUs} exact />
        <PublicRoute component={Faqs} path={ROUTES.faqs} exact />
        <PublicRoute
          component={ProcedureType}
          path={ROUTES.procedureType}
          exact
        />

        <Route path="/apple-app-site-association" onEnter={reload} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default AppRouter;
