import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  container: {
    paddingTop: 200,
    background: '#fff',
  },
  body: {
    background: 'linear-gradient(90deg, #C1DCF9 0%, #D0E5FA 20.43%, #F2F8FE 100%)',
    position: 'relative',
    height: '62vw',
  },
  content: {
    color: '#6B7779',
    fontSize: 20,
    fontFamily: "SFUIText-Regular",
  },
  title: {
    color:'#3552CC',
    fontSize: 35,
    fontFamily: "SFUIText-Bold",
  },
  imageContainer: {
    // position: 'relative',
    // marginBottom: 170,
    // width: '20%'
  },
  image: {
    // position: 'absolute',
    // top: '-170px',
    width: '80%',
  },
  cardContainer: {
    position: 'relative',
    background: 'linear-gradient(90deg, #C1DCF9 0%, #D0E5FA 20.43%, #F2F8FE 100%)',
    height: '42vw',
  },
  cardList: {
    display: 'flex',
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  }
}));

export default styles;
