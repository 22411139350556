import { makeStyles } from "@mui/styles";

const OTPVerificationTextStyles = makeStyles((theme) => ({
  mainContainer: {
    background: theme.palette.primary.white,
    borderRadius: 5,
    padding: "88px 30px 92px 45px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "360px !important",
      height: 380,
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: "max-content",
    },
  },
  modalTitleContainer: {
    marginTop: 80,
    // padding: "0 12px",
  },
  modalTitle: {
    fontSize: 16,
    fontFamily: "Urbanist-Regular",

    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
      marginTop: "10px !important",
    },
  },
  email: {
    color: theme.palette.primary.main,
    fontSize: "22px !important",
    fontWeight: "900 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
      marginTop: "-20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px !important",
      marginTop: "10px !important",
    },
  },
  modalDescription: {
    marginLeft: "35px !important",
    marginRight: "35px !important",
    color: theme.palette.primary.darkBlue,
    fontWeight: "800 !important",
    [theme.breakpoints.up("sm")]: {
      marginTop: "40px !important",
      marginBottom: "50px !important",
    },
  },
  small: {
    marginLeft: "40px !important",
    marginRight: "40px !important",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: 300,
    },
  },
  smallError: {
    marginLeft: "40px !important",
    marginRight: "40px !important",
    color: theme.palette.error.main,
    fontWeight: "800 !important",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: 300,
    },
  },

  timerText: {
    /* Verification code expires in 00:10 secs */

    fontFamily: "Urbanist-Regular",
    fontSize: 16,
    lineHeight: "140%",
    /* or 22px */
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "#CC3535",
    /* Button/Shadow 1 */
    textShadow: "4px 8px 24px rgba(36, 107, 253, 0.25)",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",

    "& .resendCodeStyle": {
      background: theme.palette.primary.gray,
      padding: "13px 20px",
      borderRadius: 5,
      color: theme.palette.primary.white,
      fontSize: "16px",
      fontFamily: "Urbanist-Regular",
    },
    "& .VerifyButton": {
      background: theme.palette.primary.main,
      padding: "13px 36px",
      borderRadius: 5,
      color: theme.palette.primary.white,

      fontSize: 16,
      fontFamily: "Urbanist-Regular",
    },
  },
  checkIconWrapper: {
    height: 20,
    width: 20,
    borderRadius: 10,
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default OTPVerificationTextStyles;
