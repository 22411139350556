import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../theme/customColor";

import bg from "../../assets/aboutUs/bg.svg";
import bgMobile from "../../assets/aboutUs/bg-mobile.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    background: "linear-gradient(0deg, #EDEDF6 0%, #DAE2EE 100%)",
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    height: 540,
    [theme.breakpoints.down(601)]: {
      height: 270,
      backgroundSize: "cover",
    },
  },
  bottomContainer: {
    width: "80%",
    backgroundColor: customColor.cotton,
    padding: `${theme.spacing(2)} ${theme.spacing(10)}`,
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(16),
    "& button": {
      border: "none",
      fontFamily: "SFUIText-Regular",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 20,
      color: customColor.cotton,
      // backgroundColor: customColor.accentBlue,
      backgroundImage: "linear-gradient(180deg, #0ACC97 0%, #1B63DB 100%)",
      borderRadius: 60,
      padding: `${theme.spacing(1)} ${theme.spacing(6)}`,
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      [theme.breakpoints.down(601)]: {
        fontSize: 14,
      },
    },
    "& .content": {
      // color: customColor.accentBlue,
      color: customColor.black,
      fontSize: 24,
      fontFamily: "SFUIText-Regular",
      fontWeight: "500",
      marginLeft: theme.spacing(3),
      display: "flex",
      height: "100%",
      alignItems: "center",
    },
  },
}));

export default useStyles;
