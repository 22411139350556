import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  selectTime: {
    height: 40,
    width: 90,
    borderRadius: "10px !important",
    marginRight: 20,
  },
}));

export default useStyles;
