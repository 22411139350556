import { instalmentLoanEndpoints } from "../../features/installmentLoan/config/endpoints";
import api, { lmsApi } from "../api";
import medsiAuth from "./config";
import medsiV2Auth from "./configV2";

const authApi = {
  async login(data) {
    return api.post(medsiAuth.login, data);
  },

  async sendOTP(data) {
    const response = await api.post(medsiV2Auth.sendOtp, data);
    return response;
  },

  async resendOTP(data) {
    const response = await api.patch(medsiV2Auth.resendOtp, data);
    return response;
  },

  async verifyOTP(data) {
    return api.post(medsiV2Auth.verifyOtp, data);
  },

  async sendOTPWithAuth(data) {
    return api.post(medsiV2Auth.sendOtpWithAuth, data);
  },

  async resendOTPWithAuth(data) {
    const response = await api.patch(medsiV2Auth.resendOtpWithAuth, data);
    return response;
  },

  async verifyOTPWithAuth(data) {
    return api.post(medsiV2Auth.verifyOtpWithAuth, data);
  },

  async requestResetEmail(data) {
    const response = await api.post(medsiAuth.requestResetEmail, data);
    return response;
  },

  async passwordReset(data) {
    const url = `${medsiAuth.passwordReset}${data.uuid}/${data.token}/`;
    const response = await api.get(url);
    return response;
  },

  async passwordResetComplete(data) {
    const response = await api.patch(medsiAuth.passwordResetComplete, data);
    return response;
  },

  async emailVerify(data) {
    const url = `${medsiAuth.emailVerify}?token=${data.token}`;
    const response = await api.get(url);
    return response;
  },

  async getOnboardingSteps() {
    return api.get(medsiAuth.getOnboardingSteps);
  },

  async register(data) {
    return api.post(medsiV2Auth.register, data);
  },

  async addCoSigner(data) {
    return api.post(medsiV2Auth.addCosigner, data);
  },

  async getCoSigner(data) {
    return api.get(medsiV2Auth.addCosigner);
  },

  async saveTakeLoan(data) {
    return api.post(medsiV2Auth.saveLoanDetail, data);
  },

  async verifyPromoCode(data) {
    return api.post(medsiV2Auth.verifyPromoCode, data);
  },

  async subscribe(data) {
    return api.post(medsiV2Auth.subscribe, data);
  },

  async generalInfoGet() {
    return api.get(medsiAuth.generalInfo);
  },

  async savePatientGroup(data) {
    if (data?.isEdit) {
      return api.patch(`${medsiV2Auth.savePatientGroup}`, data.details);
    }
    return api.post(`${medsiV2Auth.savePatientGroup}`, data.details);
  },

  async savePatientApplication(data) {
    if (data?.isEdit) {
      return api.patch(`${medsiAuth.saveApplication}`, data.details);
    }
    return api.post(`${medsiAuth.saveApplication}`, data.details);
  },

  async getPatientApplication(data) {
    return api.get(`${medsiAuth.saveApplication}?application_id=${data}`);
  },

  async getPatientGroup() {
    return api.get(`${medsiV2Auth.savePatientGroup}`);
  },

  async getApplicationStepper(data) {
    return api.get(`${medsiAuth.applicationStepper}?application_id=${data}`);
  },

  async getStepperApplicationSummary(data) {
    return api.get(`${medsiAuth.applicationSummary}?application_id=${data}`);
  },

  async getApplicationAll(data) {
    return api.get(`${medsiAuth.applicationAll}`);
  },

  async deleteApplicationId(data) {
    return api.delete(`${medsiAuth.applicationDelete}?application_id=${data}`);
  },

  async getTransactionList(data) {
    return lmsApi.get(
      `${instalmentLoanEndpoints.getTransactionList}${data.cl_id}/?limit=&txn_type=${data.txnType}&offset=0`
    );
  },

  async getApplicationLoanDetail(data) {
    return api.get(`${medsiAuth.applicationLoanDetail}?application_id=${data}`);
  },

  async getDownloadContractLink(data) {
    return api.get(`${medsiAuth.contractLink}?application_id=${data}`);
  },

  async saveApplicationLoanDetail(data) {
    return api.post(`${medsiAuth.applicationLoanDetail}`, data);
  },

  async generalInfoGetV2() {
    return api.get(medsiV2Auth.generalInfo);
  },

  async getDashboardInfo() {
    return api.get(medsiV2Auth.dashboardDetail);
  },

  async getAccessToken() {
    return api.get(instalmentLoanEndpoints.getAccessToken);
  },
  async getLmsBalanceSummary(data) {
    return lmsApi.get(instalmentLoanEndpoints.getLmsBalanceSumamry);
  },

  async getUserPromoCode() {
    return api.get(medsiV2Auth.userPromoCode);
  },

  async deleteUserPromoCode(data) {
    return api.delete(`${medsiV2Auth.submitPromocode}?promo_code=${data}`);
  },

  async getCoSignerStatus(data) {
    return api.get(medsiV2Auth.addCosigner);
  },

  async getLoanList(data) {
    return lmsApi.get(
      `${instalmentLoanEndpoints.getLoanList}${data.cl_id}/?limit=${data.totalShow}&loan_status=${data.loanType}&offset=0`
    );
  },

  async getCreditDecisionInfo() {
    return api.get(medsiV2Auth.creditDecision);
  },

  async generalInfo(data) {
    return api.post(medsiV2Auth.generalInfo, data.params);
  },

  async professionalData(data) {
    if (data?.gotDataFromGetApiValue) {
      return api.patch(medsiAuth.professionalDetail, data.params);
    } else {
      return api.post(medsiAuth.professionalDetail, data.params);
    }
  },
  async medicalProcedure(data) {
    return api.post(medsiV2Auth.procedureInfo, data);
  },

  async checkEmail(data) {
    return api.post(medsiAuth.checkEmail, data);
  },
  async logout(data) {
    return api.post(medsiAuth.logout, data);
  },
  async curpRegistration(data) {
    return api.post(medsiAuth.curpRegistration, data);
  },
  async postUserPlaceOfBirth(data) {
    return api.post(medsiAuth.additionalInfo, data);
  },
  async postFurtherInfo(data) {
    return api.post(medsiAuth.furtherInfo, data);
  },
  async postIdentityInfo(data) {
    return api.post(medsiAuth.identityInfo, data);
  },
  async postFinalInfo(data) {
    return api.post(medsiAuth.finalInfo, data);
    // return api.patch(medsiAuth.finalInfo, data);
  },

  async submitPromocode(data) {
    const response = await api.post(medsiV2Auth.submitPromocode, data);
    return response;
  },

  async getSignature(data) {
    return api.get(`${medsiAuth.finalInfo}?application_id=${data}`);
  },

  async saveCreditRequest(data) {
    return api.post(medsiAuth.saveCreditRequest, data);
  },

  async getUnboardinglatestStep() {
    return api.get(medsiV2Auth.identifyUnboarding);
  },

  async updatedOnboardingSteps(data) {
    return api.patch(medsiAuth.getOnboardingSteps, data);
  },
  async resendEmailConfirmation(data) {
    return api.post(medsiAuth.resendConfirmationMail, data);
  },
  async updateDoctorConfirmation(data) {
    const url = `${medsiAuth.doctorConfirmation}?token=${data.token}${
      data.appId && "&app_id=" + data.appId
    }`;
    const response = await api.get(url);
    return response;
  },
  async applicationDetailsThroughQR(data) {
    const url = `${medsiAuth.qrApplicationDetais}?app_id=${data.appId}&user_id=${data.user_id}`;
    const response = await api.get(url);
    return response;
  },

  async saveUserFeedBack(data) {
    const response = await api.post(medsiAuth.userFeedBack, data);
    return response;
  },

  async saveDoctorBankDetails(data) {
    const response = await api.post(
      `${medsiAuth.doctorBankDetails}?token=${data?.token}&app_id=${data?.app_id}`,
      data.accountDetails
    );
    return response;
  },

  async verifyPassword(data) {
    const response = await api.post(medsiAuth.verifyPassword, data);
    return response;
  },
  async getApplicationAgreement(payload) {
    return api.get(
      `${medsiAuth.applicationAgreement}?app_id=${payload.app_id}&agreement=${payload.agreement}`
    );
  },
};

export default authApi;
