import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";
import CreditRequestBg from "../../../assets/CreditRequest/credir-request-bg.svg";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    // alignItems: "center",
    height: "max-content",
    width: "100%",
    // padding: `0 106px`,
    marginTop: theme.spacing(8),
    backgroundImage: `url(${CreditRequestBg})`,
    backgroundRepeat: "no-repeat",

    // paddingBottom: theme.spacing(14),
    [theme.breakpoints.down(601)]: {
      marginTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    fontSize: 50,
    width: "50%",
    // color: customColor.accentBlue,
    fontFamily: "SFUIText-Medium",
    color: "#fff",
    textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

    [theme.breakpoints.down(601)]: {
      fontSize: 22,
      padding: `0 ${theme.spacing(3)}`,
    },
  },
  desc: {
    fontSize: 22,
    fontFamily: "Urbanist-Regular",
    width: "50%",
  },
  link: {
    // color: customColor.accentBlue,
    color: customColor.black,
    fontSize: 18,
    fontFamily: "SFUIText-Regular",
    fontWeight: "700",
    textDecoration: "none",
  },

  content: {
    // marginLeft: theme.spacing(4),
    // paddingRight: theme.spacing(14),
    padding: `0 106px`,

    // minHeight: "100vh",
    [theme.breakpoints.down(601)]: {
      paddingRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  },
  question: {
    height: "85%",
    width: "85%",
    [theme.breakpoints.down(601)]: {
      height: "100%",
      width: "100%",
    },
  },
  circle: {
    width: 35,
    height: 35,
    background: " linear-gradient(77.99deg, #0ACC97 11.28%, #196FD4 127.02%)",
    borderRadius: 100,
    marginRight: 16,
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },

  subTitleWrapper: {
    marginLeft: theme.spacing(10),
    maxWidth: 668,
  },

  subTitle: {
    fontFamily: "Urbanist-Regular",
    fontWeight: 500,
    fontSize: 36,
  },
  monthsDesc: {
    fontFamily: "SFUIText-Regular",
    marginTop: 16,
    fontSize: 22,
  },
  registerButtonContainer: {
    height: 84,
    width: 185,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",

    fontSize: 22,
    fontFamily: "Urbanist-Regular",
    fontWeight: 500,
  },
  curpContainer: {
    width: "100%",
    backgroundImage: "linear-gradient(180deg, #29CEA7 0%, #1D87C8 100%)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    display: "flex",
    height: 357,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > .title": {
      color: "#fff",
      fontFamily: "Urbanist-Semibold",
      fontSize: 36,
    },
    "& > .buttonContainer": {
      maxWidth: 822,
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
      marginTop: 40,
    },
    "& > div > .itemsWrapper": {
      borderWidth: 2,
      borderColor: "#fff",
      borderRadius: 30,
      height: 102,
      width: 102,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderStyle: "solid",

      fontSize: 24,
      color: "#fff",
      fontFamily: "Urbanist-Semibold",
      textAlign: "center",
    },
  },

  processContainer: {
    margin: "150px 356px",
    "& .title": {
      fontFamily: "Urbanist-Bold",
      fontSize: 36,
      paddingLeft: 80,
    },
    "& .subtitle": {
      fontFamily: "Urbanist-Semibold",
      fontSize: 24,
      marginTop: 30,
      marginBottom: 62,
      paddingLeft: 80,
    },
    "& .processRow": {
      display: "flex",
      alignItems: "center",
      marginTop: 70,
    },
    "& .bullet": {
      width: 41,
      height: 41,
      borderRadius: 100,
      backgroundImage:
        "linear-gradient(212.06deg, #0ACC97 19.26%, #196FD3 104.11%)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      color: "#fff",
      fontSize: 20,
      fontFamily: "Urbanist-Bold",
    },
    "& .processContent": {
      paddingLeft: 40,
    },
  },

  contactusContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "118px 0",
    backgroundImage:
      "linear-gradient(180deg, #D9D9D9 0%, rgba(217, 217, 217, 0) 100%)",
    "& .desc1": {
      fontFamily: "Urbanist-Semibold",
      fontSize: 36,
    },
    "& .desc2": {
      fontFamily: "Urbanist-Regular",
      fontWeight: 500,
      fontSize: 25,
    },
  },
  btnCredit: {
    fontFamily: "Nexa-Bold",
    backgroundImage: "linear-gradient(90deg, #0ACC97 12.31%, #1B63DB 107.39%)",
    padding: "18px 38px",
    borderRadius: 20,
  },
}));

export default useStyles;
