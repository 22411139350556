import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  headerMainContainer: {
    padding: "12px 11% 15px 5%",
    background: "rgba(53, 82, 204, 0.3)",
    color: "#fff",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  headerButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down(601)]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  headerLoginButtonStyle: {
    background: "transparent",
    outline: "none",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "38px",
    padding: "0px 10px",
    marginRight: "22px",
    borderRadius: "6px",
    "&:active": {
      background: "rgba(53, 82, 204, 0.1)",
    },
  },
  headerLoginButtonTextStyle: {
    // fontFamily: 'SF UI Text',
    fontSize: "16px !important",
    fontWeight: "bold !important",
    lineHeight: "19px !important",
    color: "#6B7779 !important",
    marginRight: "9px !important",
    [theme.breakpoints.down(601)]: {
      color: "#FFF !important",
    },
  },
  headerApplyCreditButtonStyle: {
    background: "transparent",
    outline: "none",
    border: "1px solid #3552CC",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "38px",
    padding: "0px 22px",

    // fontFamily: 'SF UI Text';
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3552CC",
    "&:active": {
      background: "rgba(53,82,204, 0.1)",
    },
  },
  mobileMenu: {
    [theme.breakpoints.up(601)]: {
      display: "none",
    },
    [theme.breakpoints.down(601)]: {
      width: "100%",
      background: "#3552CC",
      paddingBottom: 30,
    },
  },
  btnContainer: {
    [theme.breakpoints.down(601)]: {
      width: "100%",
      "& > button": {
        padding: "20px 0",
        background: "transparent",
        border: "none",
        alignItems: "center",
        display: "flex",
        fontWeight: '600',
        fontFamily: 'SFUIText-Regular',
        "& > span": {
          color: "#FFF",
          fontSize: 18,
          lineHeight: '21px',
          marginLeft: 10,
        },
        "& > img": {
          width: 25,
          height: 25,
        },
      },
    },
  },
  headerMainContainerMobile: {
    [theme.breakpoints.down(601)]: {
      background: "#3552CC",
      zIndex: 1,
      flex: 1,
      padding: "0 22px",
      '& > .header-row': {
        height: 65,
      }
    },
  }
}));

export default styles;
