import { Fragment } from "react";
import { FormControl, FormHelperText, Stack, TextField } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TitleComponent from "../TitleComponent";
import DateinputStyles from "./Dateinput.styles";

const DateinputComponent = (props) => {
  const {
    onBlur,
    labelTitle,
    dateValue,
    handleDate,
    hasDate,
    formStyles,
    errors,
    disabled,
    touched,
    maxDateValue,
    minDate,
  } = props;
  const classes = DateinputStyles();
  //console.log("dateTime: ", errors, touched);

  return (
    <div>
      {labelTitle && (
        <FormControl
          className={
            formStyles
              ? `${formStyles} ${classes.formContainer}`
              : classes.formContainer
          }
        >
          <TitleComponent text={labelTitle} disabled={disabled} />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                inputProps={{ "aria-label": "Without label", readOnly: true }}
                inputFormat="dd/MM/yyyy"
                value={dateValue}
                onChange={handleDate}
                disabled={hasDate || disabled}
                onBlur={onBlur}
                minDate={minDate || undefined}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color={errors ? "error" : "success"}
                    size="small"
                    error={touched && Boolean(errors)}
                  />
                )}
              />
            </Stack>
          </LocalizationProvider>
          <FormHelperText>{errors && touched && errors}</FormHelperText>
        </FormControl>
      )}
      {maxDateValue && (
        <Fragment>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3}>
              <DesktopDatePicker
                inputProps={{ "aria-label": "Without label", readOnly: true }}
                inputFormat="dd/MM/yyyy"
                value={dateValue}
                onChange={handleDate}
                disabled={hasDate || disabled}
                onBlur={onBlur}
                minDate={minDate || undefined}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    color={errors ? "error" : "success"}
                    size="small"
                    error={touched && Boolean(errors)}
                  />
                )}
                openTo="day"
                views={["year", "month", "day"]}
                maxDate={maxDateValue}
              />
            </Stack>
          </LocalizationProvider>
          <FormHelperText>{errors && touched && errors}</FormHelperText>
        </Fragment>
      )}
    </div>
  );
};

export default DateinputComponent;
