import React from "react";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import cosmeticTratmentStyles from "./cosmeticTratment.styles";
import hairRestaurationIcon from "../../../../assets/procedureType/hairRestaurationIcon.svg";
const CosmeticTratments = (props) => {
  const classes = cosmeticTratmentStyles();
  //CustomListItemTypography
  const ListGrayItem = ({ children }) => (
    <Typography
      sx={{
        fontFamily: "Urbanist-Medium",
        color: "rgba(0, 0, 0, 0.5)",
        fontSize: 20,
      }}
    >
      {children}
    </Typography>
  );

  const ListBlackItem = ({ children }) => (
    <Typography sx={{ fontFamily: "Urbanist-SemiBold", fontSize: 20 }}>
      {children}
    </Typography>
  );

  const ListItemsAndTitle = (props) => {
    const { title, items, headerImage, marginLeftToList } = props;
    return (
      <Box>
        {headerImage ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={headerImage}
              alt="hair"
              className={classes.hairReparationIcon}
            />
            <ListBlackItem>{title}</ListBlackItem>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" mt={"28px"}>
            <ListBlackItem>{title}</ListBlackItem>
          </Box>
        )}

        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: 10,
            },
            marginLeft: marginLeftToList,
            marginTop: headerImage && "-20px",
          }}
        >
          {items.map((item) => (
            <ListItem>
              <ListGrayItem>{item}</ListGrayItem>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box className={classes.mainContainer} >
      <Typography
        sx={{
          fontFamily: "Urbanist-SemiBold",
          fontSize: 36,
          color: "#000000",
          opacity: "0.5",
          marginLeft: 15,
          marginBottom: 5,
        }}
        className={classes.sectionTitle}
      >
        Tratamientos Cosméticos y Dermatología
      </Typography>
      <Grid container>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Rejuvenecimiento y armonización facial"
            items={[
              "Toxina botulínica",
              "Acido Hialurónico",
              "Fillers dermales ",
            ]}
            headerImage={hairRestaurationIcon}
            marginLeftToList={15}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Grid item xs={12}>
            <ListItemsAndTitle
              title="Laser / Fotorejuvenecimiento"
              items={["Dermoabrasión / Peeling Químico "]}
              marginLeftToList={9}
            />
          </Grid>
          <Grid item xs={12}>
            <ListItemsAndTitle
              title="Depilación"
              items={["Depilación general "]}
              marginLeftToList={9}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Lifting facial sin cirugía"
            items={[
              "Ultrasonido focalizado de alta intensidad (HIFU / Ultherapy)",
              "Radiofrecuencia monopolar",
            ]}
            marginLeftToList={0}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Contorno corporal sin cirugía"
            items={[
              "Moldeado corporal en frio (Criolipólisis / Coolsculpting)",
              "Radiofrequencia selectiva (Lipolaser / Vanquish)",
            ]}
            marginLeftToList={9}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CosmeticTratments;
