import medsiEndPoint from "./config";
import api from "../api";
import medsiV2Auth from "../auth/configV2";
import medsiEndPointV2 from "./configV2";
import medsiAuth from "../auth/config";

const installmentLoanApi = {
  async getCreditStatement() {
    const response = await api.get(medsiEndPointV2.creditStatement);
    return response;
  },

  async getBalanceSummary() {
    const response = await api.get(medsiEndPoint.getBalanceSummary);
    return response;
  },

  async getLatestTransaction() {
    const response = await api.get(medsiEndPoint.getLatestTxn);
    return response;
  },

  async getTransactionDetail(data) {
    const url = `${medsiEndPoint.getTxnDetails}${data.id}`;
    const response = await api.get(url);
    return response;
  },

  async getRepaymentAmount(data) {
    const url = `${medsiEndPoint.getRepaymentAmount}${data.date}`;
    // const { tnc, signature, repayment_options } = data;
    // const payload = { tnc, signature, repayment_options };
    const response = await api.get(url);
    return response;
  },

  async postRepaymentAmount(data) {
    const { date, amt } = data;
    const payload = { date, amt };
    const response = await api.post(medsiEndPoint.postRepaymentAmount, payload);
    return response;
  },
  async creditRequestDetails(prodCode) {
    const response = await api.get(
      `${medsiEndPoint.creditRequestDetails}/?prodCode=${prodCode}`
    );
    return response;
  },

  async getCreditMinMax() {
    const response = await api.get(medsiEndPoint.creditMinMax);
    return response;
  },
  async getBasicDetails() {
    const response = await api.get(medsiEndPoint.basicDetails);
    return response;
  },
  async getApplicationSummary() {
    const response = await api.get(medsiEndPoint.applicationSummary);
    return response;
  },

  async getApplicationSummary() {
    const response = await api.get(medsiEndPoint.applicationSummary);
    return response;
  },

  async getProcedureInfo() {
    const response = await api.get(medsiEndPointV2.getProcedureInfo);
    return response;
  },

  async updatedApplicationSummary(data) {
    const response = await api.patch(medsiEndPoint.applicationSummary, data);
    return response;
  },

  async updatedApplicationSummaryV2(data) {
    const response = await api.patch(medsiV2Auth.procedureInfo, data);
    return response;
  },

  async stepperPendingProcedureInfo(data) {
    const { isEdit, cost, ...restData } = data;
    let response;
    if (isEdit) {
      response = await api.patch(`${medsiAuth.applicationProcedure}`, restData);
    } else {
      response = await api.post(`${medsiAuth.applicationProcedure}`, restData);
    }
    return response;
  },

  async getStepperPendingProcedureInfo(data) {
    const response = await api.get(
      `${medsiAuth.applicationProcedure}?application_id=${data}`
    );
    return response;
  },

  async postPaymentInitiate(data) {
    const response = await api.post(medsiEndPointV2.paymentInitiate, data);
    return response;
  },
};

export default installmentLoanApi;
