import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useAppDispatch } from "../../../../store";
import { useTranslation } from "react-i18next";
import CancelIcon from '@mui/icons-material/Cancel';
// import Download from "../../../../components/icons/Download";
// import RequestMoney from "../../../../components/icons/RequestMoney";

import FlatList from "../../components/FlatList";
import { FlatListItem } from "../../components/FlatList/flatList";
import { amountFormatterMethod } from "../../utils/amountFormatter";

import useStyles from "./styles";
import items from "./listItem";
import Box from "@mui/material/Box";
import {
  setLoadingTxnDetails,
  getTxnDetails,
  ILTxnDetails,
} from "../../store/installmentLoan";

import Button from "../../components/Button";
import useGetStore from "../../../../store/getStore";
import CircularLoader from "../../../../components/CircularLoader";

export interface PaymentProps {
  onBack?: () => void;
  transactionsId?: string;
}

export interface TermProps {
  id: string;
  title: string;
  subTitle: string;
  status?: string;
}

const PaymentDetails = ({ onBack, transactionsId }: PaymentProps) => {
  const { t } = useTranslation();

  const { installmentLoan } = useGetStore();
  const { txnDetails } = installmentLoan;
  const dispatch = useAppDispatch();
  const css = useStyles();

  const [itemValue, setItemValue] = useState<ILTxnDetails | null>();

  useEffect(() => {
    if (txnDetails) {
      setItemValue(txnDetails);
    }
  }, [installmentLoan.loadedTxnDetails]);

  const loadData = async () => {
    dispatch(setLoadingTxnDetails(true));
    if (transactionsId) {
      await dispatch(getTxnDetails(transactionsId));
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  // const download = () => {
  //   if (invoiceLink) {
  //     const link = document.createElement("a");
  //     link.href = invoiceLink;
  //     link.download = t(
  //       "instantLineCreditTS.installmentLoan.locReceipt.paymentFileName"
  //     );
  //     link.dispatchEvent(new MouseEvent("click"));
  //   }
  // };


  return (
    // <Layout
    //   noScroll
    //   headerComponent={
    //     <div className={css.header}>
    //       <Button onClick={onBack} label='back' />
    //     </div>
    //   }
    // >
    <>
      <div className={css.header} onClick={onBack}>
        <CancelIcon  className={css.backIcon} />
      </div>
      <Grid item xs={12} className={css.receiptWrapper}>
        {/* <div className={css.iconWrapper}>
          <RequestMoney />
        </div> */}
        <Grid item xs={12} className={css.gridWrapper}>
          {!installmentLoan.loadedTxnDetails && (
            <>
              <div className={css.amount}>
                $
                <strong>
                  {itemValue?.totalAmtPaid &&
                    amountFormatterMethod(itemValue?.totalAmtPaid)}
                </strong>
              </div>
            </>
          )}
          <p className={css.creditTitle}>
            {t("instantLineCreditTS.installmentLoan.locReceipt.creditPayment")}
          </p>
        </Grid>

        {installmentLoan.loadedTxnDetails ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight={500}
          >
            <CircularLoader />
          </Box>
        ) : (
          <>
            <FlatList
              title={t(
                "instantLineCreditTS.installmentLoan.locReceipt.payDetail"
              )}
            >
              {/* <FlatListItem
            noPadding
            customComponent={
              <>
                {termArray?.map((item: TermProps) => (
                  <Milestone
                    key={item.id}
                    size="small"
                    rightTitle={item.title}
                    subtitle={item.subTitle}
                    leftTitle={t(
                      "instantLineCreditTS.installmentLoan.locReceipt.disbursement",
                      { code: item?.id.slice(item.id.length - 4) }
                    )}
                    // leftTitle={item?.id.slice(item.id.length - 4)}
                    active
                  />
                ))}
              </>
            }
          /> */}
              {items({ t, itemValue }).map((item) => (
                <FlatListItem key={item.title} {...item} />
              ))}
            </FlatList>

            {/* <FlatList
              title={t(
                "instantLineCreditTS.installmentLoan.locReceipt.options"
              )}
            >
              <FlatListItem
                title={t(
                  "instantLineCreditTS.installmentLoan.locReceipt.downloadPaymentTitle"
                )}
                subtitle={t(
                  "instantLineCreditTS.installmentLoan.locReceipt.downloadDesc"
                )}
                // value={
                //   <Download
                //     // onClick={download}
                //     color={customTheme.oyster}
                //     fontSize={24}
                //   />
                // }
                gridSize={10}
              />
            </FlatList> */}
          </>
        )}
      </Grid>
    </>
  );
};

export default PaymentDetails;
