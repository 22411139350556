import React from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SplashSqueezeStyle from "./SplashSqueeze.styles";

const SplashSqueeze = (props) => {
  const { redirectTo, setLoaderSplash } = props;
  let timeout = 5000;

  if (setLoaderSplash) {
    timeout = 500;
  }

  const classes = SplashSqueezeStyle();
  const history = useHistory();

  setTimeout(() => {
    if (setLoaderSplash) {
      setLoaderSplash(false);
    } else {
      history.push(redirectTo);
    }
    return () => {
      clearTimeout();
    };
  }, timeout);

  return <Box className={classes.mainContainer}></Box>;
};

export default SplashSqueeze;
