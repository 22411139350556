import Promocode from '@components/icons/publicpromocode';
import AddCircle from '@mui/icons-material/AddCircle';
import MenuIcon from '@mui/icons-material/Menu';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiMenuFill } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MedsiGradientLogo from '../../assets/Logos/DashboardGradientLogo.png';
import PriceTag from '../../assets/priceTag.svg';
import PromoCode from '../../components/ModalContents/PromoCode';
import ModalComponent from '../../components/sharedComponents/ModalComponent';
import {
  API_STATUS,
  PROMOCODE_DELETE_STATUS,
  PROMOCODE_STATUS,
  SIGNATURE_STATUS,
  URBANIST_FONT,
} from '../../constants/common';
import { AUTH_ROUTES } from '../../constants/routes';
import { deleteUserPromoCode } from '../../features/installmentLoan/store/installmentLoan';
import useTimeOfDay from '../../hooks/useTimeOfDay';
import { useAppDispatch } from '../../store';
import useStore from '../../store/getStore';
import { userLogout } from '../../store/slices/auth';
import {
  selectAuthUserName,
  selectUserPromoCode,
} from '../../store/slices/users';
import { getUserPromoCode } from '../../store/slices/users/actions';
import { errorToast, successToast } from '../../utils/toast';
import NotificationIcon from '../icons/Notification';
import useStyles from './styles';

interface HeaderProps {
  greeting?: string;
  name?: string;
  isBorderBottom?: boolean;
  setPromoCreditLimit?: any;
  setShowSideBar?: any;
}

const Header: FC<HeaderProps> = ({
  greeting,
  name,
  isBorderBottom = true,
  setPromoCreditLimit,
  setShowSideBar,
}) => {
  const css = useStyles({
    isBorderBottom,
  });
  const { timeOfDay } = useTimeOfDay();
  const { t } = useTranslation();
  const { auth } = useStore();

  const userName = useSelector(selectAuthUserName);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [promoPopUp, setPromoPopUp] = useState(false);
  const [updateData, setupdateData] = useState(false);
  const [userPromocode, setUserPromoCode] = useState('');

  const history = useHistory();

  const defaultMsg = t(
    `instantLineCreditTS.installmentLoan.dashboard.greeting.${timeOfDay}`
  );

  useEffect(() => {
    dispatch(getUserPromoCode());
  }, []);

  useEffect(() => {
    if (updateData) {
      dispatch(getUserPromoCode());
    }
  }, [updateData]);

  useEffect(() => {
    setShowSideBar(false);
  }, [history]);

  const userPromoCode = useSelector(selectUserPromoCode);

  useEffect(() => {
    setUserPromoCode(userPromoCode.promo_code);
    if (setPromoCreditLimit) {
      setPromoCreditLimit(userPromoCode.promo_code_amt_limit);
    }
  }, [userPromoCode]);

  const logoutUser = async () => {
    setLoading(true);
    await dispatch(userLogout(auth.refreshToken)).unwrap();
    setLoading(false);
    history.push(AUTH_ROUTES.LOGIN);
  };
  const handlePromoPopUp = (name) => {
    setPromoPopUp(true);
    setUserPromoCode(name);
  };

  const handleErase = async () => {
    let value = '';
    let promo_code = userPromoCode;
    console.log({ userPromoCode });
    const res = await dispatch(deleteUserPromoCode(userPromoCode.promo_code));
    if (res?.payload?.status === API_STATUS.SUCCESS) {
      setUserPromoCode(value);
      successToast(PROMOCODE_DELETE_STATUS[res.payload.status]);
      setPromoCreditLimit();
    } else {
      errorToast(SIGNATURE_STATUS[res.payload.status]);
    }
  };

  return (
    <Box>
      <Box className={css.box}>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            flex: 1,
            [theme.breakpoints.down('lg')]: { marginLeft: '65px' },
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column',
              alignItems: 'flex-start',
              rowGap: 3,
            },
            [theme.breakpoints.down('tablet')]: { marginLeft: '0px' },
          })}
        >
          <Box
            sx={(theme) => ({
              flex: 1,
              // [theme.breakpoints.down('xl')]: {
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
                alignItems: 'center',
              },
            })}
          >
            <Typography variant='h6' className={css.h6}>
              {greeting || defaultMsg},
            </Typography>
            <Typography ml='8px' variant='h4' className={css.h4}>
              {name || userName}
            </Typography>
          </Box>
          <Box className={css.notificationContainer}>
            {!userPromocode ? (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px 12px',
                  borderRadius: '20px',
                  cursor: 'pointer',
                  background:
                    'linear-gradient(90deg, #0ACC97 12.31%, #1B63DB 107.39%)',
                  [theme.breakpoints.down('lg')]: {
                    borderRadius: '30px',
                    padding: '5px 5rem 5px 5px',
                  },
                  [theme.breakpoints.down('tablet')]: {
                    padding: '5px 1.5rem 5px 5px',
                  },
                })}
                onClick={() => handlePromoPopUp(userPromocode)}
              >
                <AddCircle
                  sx={(theme) => ({
                    color: '#FFFFFF',
                    cursor: 'pointer',
                    fontSize: '1rem',
                    [theme.breakpoints.down('lg')]: { fontSize: '2.3rem' },
                    [theme.breakpoints.down('tablet')]: { fontSize: '1.8rem' },
                  })}
                />
                <Box
                  sx={(theme) => ({
                    marginLeft: '10px',
                    fontSize: '18px',
                    fontFamily: 'SFUIText-bold',
                    color: '#FFFFFF',
                    [theme.breakpoints.down('lg')]: {
                      fontFamily: URBANIST_FONT.SEMIBOLD,
                      fontSize: '16px',
                    },
                    [theme.breakpoints.down('tablet')]: { fontSize: '14px' },
                  })}
                >
                  Añadir código de promoción
                </Box>
              </Box>
            ) : (
              <Box
                sx={(theme) => ({
                  fontFamily: 'SFUIText-bold',
                  cursor: 'pointer',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: URBANIST_FONT.BOLD,
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    color: '#3552CC',
                    fontFamily: 'SFUIText-Regular',
                    [theme.breakpoints.down('lg')]: {
                      fontFamily: URBANIST_FONT.REGULAR,
                    },
                  })}
                >
                  Su código de promoción es
                </Box>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: 'rgba(53, 82, 204, 0.05)',
                    borderRadius: 10,
                    padding: '12px 10px',
                    marginTop: 4,
                  }}
                >
                  <span
                    onClick={() =>
                      userPromoCode?.promo_code_used
                        ? null
                        : handlePromoPopUp(userPromocode)
                    }
                    style={{
                      cursor: userPromoCode?.promo_code_used
                        ? 'default'
                        : 'cursor',
                    }}
                  >
                    <img
                      style={{
                        color: userPromoCode?.promo_code_used
                          ? '#6B7779'
                          : '#3552CC',
                        width: 25,
                      }}
                      src={PriceTag}
                      alt=''
                    />
                  </span>
                  <span
                    style={{
                      color: userPromoCode?.promo_code_used
                        ? '#6B7779'
                        : '#3552CC',
                      marginLeft: 10,
                      marginRight: 16,

                      cursor: userPromoCode?.promo_code_used
                        ? 'default'
                        : 'cursor',
                    }}
                    onClick={() =>
                      userPromoCode?.promo_code_used
                        ? null
                        : handlePromoPopUp(userPromocode)
                    }
                  >
                    {userPromocode}
                  </span>
                  {userPromoCode?.promo_code_used ? (
                    <></>
                  ) : (
                    <span
                      onClick={() =>
                        userPromoCode?.promo_code_used ? null : handleErase()
                      }
                    >
                      <AddCircle
                        style={{
                          color: '#3552CC',
                          transform: 'rotate(45deg)',
                          height: 14,
                          width: 14,
                          cursor: 'pointer',
                          fontSize: '2rem',
                        }}
                      />
                    </span>
                  )}
                </div>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          flexDirection='row'
          display='flex'
          alignItems='center'
          // columnGap={1}
        >
          <div style={{ flex: 1 }} />

          <Box
            sx={(theme) => ({
              flex: 1,
              textAlign: 'center',
              [theme.breakpoints.up('xl')]: {
                display: 'none',
              },
            })}
          >
            <img src={MedsiGradientLogo} style={{ height: 40 }} alt='' />
          </Box>

          <Box
            flex={1}
            display='flex'
            justifyContent='flex-end'
            alignItems='center'
            columnGap={'8px'}
          >
            <Box className={css.notificationContainer}>
              <NotificationIcon className={css.notification} />
            </Box>
            <Tooltip title='Cerrar sesión' className={css.tooltipContainer}>
              <button onClick={logoutUser} disabled={loading}>
                <PowerSettingsNewIcon className={css.logout} />
                {loading && <CircularProgress size={14} />}
              </button>
            </Tooltip>

            <MenuIcon
              sx={(theme) => ({
                width: 24,
                height: 24,
                [theme.breakpoints.up('xl')]: {
                  width: 40,
                  height: 40,
                  display: 'none',
                },
              })}
              onClick={() => {
                setShowSideBar((state: boolean) => !state);
              }}
            />
          </Box>
        </Box>
      </Box>
      <ModalComponent
        open={promoPopUp}
        handlePromoPopUp={handlePromoPopUp}
        body={
          <PromoCode
            setPromoPopUp={setPromoPopUp}
            setShowPromoCode={setShowPromoCode}
            setUserPromoCode={setUserPromoCode}
            userPromocode={userPromocode}
            setupdateData={setupdateData}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => {
          setPromoPopUp(false);
        }}
      />
    </Box>
  );
};

export default Header;
