import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { currencyFormat } from "../../../utils";
import { PREFERENCE_TYPE } from "../../../constants/generals";
import InputNumber from "../../../components/sharedComponents/InputNumber";

import useStyles from "./styles";
import useCommonStyles from "../commonStyles";

export const Calculator = () => {
  const css = useStyles();
  const commonCss = useCommonStyles();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const langPrefix = "instantLineCreditTS.home.calculator";

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [procedureType, setProcedureType] = useState("0");
  const [preferenceType, setPreferenceType] = useState(PREFERENCE_TYPE.MONTHLY);
  const [amount, setAmount] = useState(10000);
  const [tenure, setTenure] = useState(3);

  const handleAmount = (value) => {
    setAmount(value);
  };

  const handleTenure = ({ target }) => {
    setTenure(target?.value);
  };

  const calculateAmount = (p, term) => {
    const ir = 40;
    if (p > 0 && term > 0 && !isNaN(p) && !isNaN(term)) {
      const pa = +p;
      const a = pa + ((pa * ir) / (100 * 12)) * term;
      const vat = (a - pa) * 0.16;
      const totalAmt = vat + a;
      const emi =
        totalAmt / term / (PREFERENCE_TYPE.MONTHLY === preferenceType ? 1 : 2);
      if (!isMobile) {
        // if (document.getElementById("totalAmtDocId")?.innerHTML) {
        //   document.getElementById(
        //     "totalAmtDocId"
        //   ).innerHTML = `$${currencyFormat(totalAmt)}`;
        // }

        if (document.getElementById("emiAmtDocId")?.innerHTML) {
          document.getElementById("emiAmtDocId").innerHTML = `$${currencyFormat(
            emi
          )}`;
        }
      }
      return {
        totalAmt,
        emi,
        term,
      };
    } else {
      if (!isMobile) {
        // if (document.getElementById("totalAmtDocId")?.innerHTML) {
        //   document.getElementById("totalAmtDocId").innerHTML = "$0";
        // }
        if (document.getElementById("emiAmtDocId")?.innerHTML) {
          document.getElementById("emiAmtDocId").innerHTML = "$0";
        }
      }
    }
    return {};
  };

  useEffect(() => {
    if (!isMobile) {
      calculateAmount(amount, tenure);
    }
  }, [amount, tenure, procedureType]);

  const { emi = 0, term = 0 } = calculateAmount(amount, tenure);

  const handleProcedureType = ({ target }) => {
    setProcedureType(target?.value);
  };

  const handlePreferenceType = ({ target }) => {
    setPreferenceType(target?.value);
  };

  return (
    <Box className={css.container} py={6}>
      <Box px={6}>
        <Box className={css.title}>Simula tu crédito</Box>
        <Box className={css.inputContainer}>
          <Box className={css.label}>Monto a financiar</Box>
          <Box position="relative">
            <InputNumber
              formControlStyles={css.formControl}
              name={"number"}
              value={amount}
              prefix="$"
              setField={(_, amount) => {
                handleAmount && handleAmount(amount);
              }}
            />
          </Box>
        </Box>
        <Box className={css.inputContainer}>
          <Box className={css.label}>Plazo del crédito</Box>
          <input
            name={"term"}
            type="number"
            onChange={handleTenure}
            value={tenure}
            className={css.input}
          />
        </Box>
        <Box className={css.inputContainer}>
          <Box className={css.label}>Preferencia de pago</Box>
          <select
            name={"preferenceType"}
            onChange={handlePreferenceType}
            selected={preferenceType}
            className={css.select}
          >
            <option value={PREFERENCE_TYPE.MONTHLY}>Mensual</option>
            <option value={PREFERENCE_TYPE.BI_WEEKLY}>Quincenal</option>
          </select>
        </Box>
        <Box className={css.inputContainer}>
          <Box className={css.label}>
            ¿Que tipo de procedimiento deseas realizar?
          </Box>
          <select
            name={"procedureType"}
            onChange={handleProcedureType}
            selected={procedureType}
            className={css.select}
          >
            <option value="0">No quirúrgico / En consultorio</option>
            <option value="1">Quirúrgico / En consultorio</option>
            <option value="2">Quirúrgico / Quirófano</option>
          </select>
        </Box>
      </Box>

      <Box className={css.valueContainer} px={6} mt={2} pt={4}>
        <Box>
          <Box className={css.calcTitle}>
            {t(`${langPrefix}.paymentOf`, {
              term: term * (PREFERENCE_TYPE.MONTHLY === preferenceType ? 1 : 2),
            })}
          </Box>
          <Box className={css.calcValue} id="emiAmtDocId">
            ${currencyFormat(emi)}
          </Box>
        </Box>
        {/* <Box mt={2}>
          <Box className={css.calcTitle}>Total a pagar</Box>
          <Box className={css.calcValue} id="totalAmtDocId">
            ${currencyFormat(totalAmt)}
          </Box>
        </Box> */}
        <Box mt={2} display="flex" justifyContent="center">
          <button
            className={commonCss.basicGradientButtonStyle}
            onClick={() => history.push("/registro")}
          >
            Solicitar crédito
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default Calculator;
