import { FC, useState } from "react";
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

import useStyle from "./styles";

export interface AccordionComponentItem {
  title: string;
  description: string;
}
export interface AccordionComponentProps {
  list: AccordionComponentItem[];
}

export const AccordionComponent: FC<AccordionComponentProps> = ({ list }) => {
  const css = useStyle();

  return (
    <Box className={css.container}>
      {list?.map((item) => {
        return (
          <Box mt={2} key={item.title} className={css.content}>
            <AccordionComponentItem {...item} />
          </Box>
        );
      })}
    </Box>
  );
};

export const AccordionComponentItem: FC<AccordionComponentItem> = ({
  title,
  description,
}) => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary
        expandIcon={expanded === "panel1" ? <CloseSharpIcon /> : <AddIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="title">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography className="description" dangerouslySetInnerHTML={{__html: description}} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionComponent;
