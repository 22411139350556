import { makeStyles } from "@mui/styles";
import desktopBg from "../../assets/IdentityInformation/backgroundDesktop.svg";
import tabletBg from "../../assets/IdentityInformation/backgroundTablet.svg";
import mobileBg from "../../assets/IdentityInformation/backgroundMobile.svg";

const IdentityInformationStyles = makeStyles((theme) => ({
  headerPageBackground: {
    height: 115,
    backgroundImage: ` url("data:image/svg+xml,%3Csvg width='1925' height='120' viewBox='0 0 1925 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M0 84.0384C0 84.0384 87.5086 18.1684 292.226 18.1684C408.904 18.1684 498.534 41.6138 536.719 55.0111C574.905 68.4084 696.887 119.765 941.911 119.765C1186.93 119.765 1295.66 78.54 1321.65 71.3853C1347.63 64.2306 1461.13 26.3553 1671.15 26.3553C1846.17 26.3553 1924.19 73.0938 1924.19 73.0938V0H0V84.0384Z' fill='%234D8DC6'/%3E%3C/svg%3E%0A")`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: ` url("data:image/svg+xml,%3Csvg width='768' height='182' viewBox='0 0 768 182' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M768 168.608C768 168.608 701.332 181.637 626.444 181.637C551.554 181.637 469.363 168.608 394.474 134.827C319.585 101.046 226.434 61.5172 144.239 61.5172C62.0437 61.5172 0 94.7744 0 94.7744V0H768V168.608Z' fill='%234D8DC6'/%3E%3C/svg%3E%0A")`,
      height: 200,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: ` url("data:image/svg+xml,%3Csvg width='416' height='99' viewBox='0 0 416 99' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M416 91.33C390.69 95.8675 365.037 98.2285 339.324 98.3871C298.758 98.3871 254.238 91.33 213.674 73.0321C173.11 54.7342 122.651 33.3221 78.1294 33.3221C33.6074 33.3221 0 51.3359 0 51.3359V0H416V91.33Z' fill='%234D8DC6'/%3E%3C/svg%3E%0A")`,
      height: 168,
    },
  },
  pageContainer: {
    backgroundImage: `url(${mobileBg})`,
    minHeight: "100vh",
    backgroundSize: "cover",
    backgroundPosition: "top",
    [theme.breakpoints.down("sm")]: {
      backgroundImage: ` url(${mobileBg})`,
    },
    [theme.breakpoints.between("sm", "lg")]: {
      backgroundImage: ` url(${tabletBg})`,
    },
    [theme.breakpoints.up("lg")]: {
      backgroundImage: ` url(${desktopBg})`,
    },
  },
  logo: {
    width: 90,
    marginTop: -100,
    [theme.breakpoints.down(1001)]: {
      marginTop: -197,
    },
    [theme.breakpoints.down(601)]: {
      width: 60,
      marginTop: -150,
    },
  },
  textTitle: {
    color: theme.palette.primary.main,
    marginTop: "5px !important",
    marginBottom: "100px !important",
    "@media (max-height: 700px)": {
      marginBottom: "50px !important",
    },
    [theme.breakpoints.down(1001)]: {
      marginBottom: "110px !important",
    },
    [theme.breakpoints.down(601)]: {
      marginBottom: "80px !important",
      fontSize: "22px !important",
    },
  },
  error: {
    color: "#db3333 !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
    textAlign: "center !important",
  },
  /* formContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "10%",
    },
  }, */
}));

export default IdentityInformationStyles;
