import { makeStyles } from "@mui/styles";

const ReferenceNumberStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundImage: `url("data:image/svg+xml,%3Csvg id='Back' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1440 1024'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%233552cc;%7D.cls-2,.cls-3%7Bopacity:0.8;%7D.cls-2%7Bfill:url(%23linear-gradient);%7D.cls-3%7Bfill:url(%23linear-gradient-2);%7D%3C/style%3E%3ClinearGradient id='linear-gradient' x1='1096.0547' y1='960.6353' x2='1440' y2='960.6353' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233552cc'/%3E%3Cstop offset='0.2121' stop-color='%233855cc'/%3E%3Cstop offset='0.3824' stop-color='%23415fcb'/%3E%3Cstop offset='0.5382' stop-color='%235070c9'/%3E%3Cstop offset='0.6854' stop-color='%236688c6'/%3E%3Cstop offset='0.8265' stop-color='%2381a7c3'/%3E%3Cstop offset='0.9613' stop-color='%23a3ccbf'/%3E%3Cstop offset='1' stop-color='%23aed8be'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' x1='1529.2861' y1='1030.3688' x2='717.0273' y2='-376.5047' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Ctitle%3ECodigo de referencia Sign in Medsi desktop%3C/title%3E%3Crect class='cls-1' x='1.6436' width='1438.3564' height='1024.709'/%3E%3Cpath class='cls-2' d='M1440,1024v-126.73A586.6086,586.6086,0,0,0,1096.0547,1024Z'/%3E%3Cpath class='cls-3' d='M1131.1387,627.9648c113.4375,0,219.3056-31.8281,308.8613-86.8906V0H550.4365q-2.5928,26.95-2.6084,54.5981C547.8281,371.2529,808.9854,627.9648,1131.1387,627.9648Z'/%3E%3C/svg%3E")`,
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Back' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 390 844'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%233552cc;%7D.cls-2,.cls-3%7Bopacity:0.6;%7D.cls-2%7Bfill:url(%23linear-gradient);%7D.cls-3%7Bfill:url(%23linear-gradient-2);%7D%3C/style%3E%3ClinearGradient id='linear-gradient' x1='1.3535' y1='269.3627' x2='390' y2='269.3627' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%233552cc'/%3E%3Cstop offset='0.2101' stop-color='%233855cc'/%3E%3Cstop offset='0.3805' stop-color='%23415fcb'/%3E%3Cstop offset='0.537' stop-color='%235070c9'/%3E%3Cstop offset='0.6851' stop-color='%236688c6'/%3E%3Cstop offset='0.8274' stop-color='%2382a7c3'/%3E%3Cstop offset='0.9635' stop-color='%23a4cdbf'/%3E%3Cstop offset='1' stop-color='%23aed8be'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' x1='164.0674' y1='780.335' y2='780.335' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Ctitle%3EBlue back CR mobile%3C/title%3E%3Crect class='cls-1' x='-0.2935' width='390.5318' height='844.5195'/%3E%3Cpath class='cls-2' d='M390,527.543V0H1.3535V451.4492S176.54,575.9658,390,527.543Z'/%3E%3Cpath class='cls-3' d='M164.0674,844H390V716.67S263.8438,761.7588,164.0674,844Z'/%3E%3C/svg%3E")`,
    },
  },
  logo: {
    height: 65,
    marginTop: -50,
    paddingBottom: 30,
    [theme.breakpoints.down(601)]: {
      height: 30,
      paddingBottom: 25,
    },
  },
  textTitle: {
    color: theme.palette.primary.white,
    marginBottom: "40px !important",
    [theme.breakpoints.down(801)]: {
      fontSize: "25px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "18px !important",
      paddingLeft: "20px !important",
      paddingRight: "20px !important",
      marginBottom: "30px !important",
    },
  },
  textReference: {
    color: theme.palette.primary.white,
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  textBox: {
    background: theme.palette.primary.white + " !important",
    padding: "0 !important",
    textAlign: "center",
    width: "50px !important",
    height: "60px !important",
    borderRadius: "4px !important",
    fontSize: "25px !important",
    fontWeight: "bold !important",
    textTransform: "uppercase",
    [theme.breakpoints.down(601)]: {
      width: "35px !important",
      height: "45px !important",
      fontSize: "20px !important",
    },
    [theme.breakpoints.down(421)]: {
      width: "25px !important",
      height: "30px !important",
      fontSize: "15px !important",
    },
  },
  circle: {
    color: theme.palette.primary.white,
    marginRight: "20px !important",
    height: "15px !important",
    [theme.breakpoints.down(421)]: {
      marginRight: "6px !important",
      height: "11px !important",
    },
  },
  textFieldStyles: {
    marginRight: "10px !important",
    [theme.breakpoints.down(421)]: {
      marginRight: "5px !important",
    },
  },
  gridStyle: {
    maxWidth: 514,
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down(601)]: {
      maxWidth: 394,
    },
    [theme.breakpoints.down(421)]: {
      maxWidth: 265,
    },
  },
  button: {
    height: 55,
    width: 160,
    fontSize: "20px !important",
    textTransform: "none !important",
    marginTop: "30px !important",
    fontWeight: "bold !important",
    background: theme.palette.primary.buttonUnselected,
    "&:hover": {
      background: "#1976d2 !important",
    },
    "&.Mui-disabled": {
      background: theme.palette.primary.gray + " !important",
    },
    borderRadius: "10px !important",
    [theme.breakpoints.down(601)]: {
      height: 45,
      width: 130,
      fontSize: "17px !important",
    },
  },
  error: {
    color: "#db3333 !important",
    fontWeight: "bold !important",
    fontSize: "18px !important",
    textAlign: "center !important"
  },
}));

export default ReferenceNumberStyles;
