import { Backdrop, Box, Grid } from '@mui/material';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Header from '../../components/header';
import SideBar from '../../components/sidebar';
import TwoColumns from '../../components/twoColumns';
import {
  API_STATUS,
  APPLICATION_STATUS,
  URBANIST_FONT,
} from '../../constants/common';
import { getRepaymentAmount } from '../../features/installmentLoan/store/repayment';
import { useAppDispatch } from '../../store';
import useStore from '../../store/getStore';
// import OfferBanner from "../../components/OfferBanner";

import useStyles from './styles';

import { lmsApi } from '../../apis/api';
import Loader from '../../components/Loader';
import Congratulation from '../../components/ModalContents/Congratulation';
import ModalComponent from '../../components/sharedComponents/ModalComponent';
import SplashValidation from '../../components/sharedComponents/SplashValidation';
import '../../global.css';
import {
  getCreditDecision,
  getDashboardDetails,
  selectCreditDecision,
  selectDashboardDetails,
  selectLmsBalanceSummary,
  selectLmsUserAccessToken,
} from '../../store/slices/users';
import {
  getLmsBalanceSummary,
  getUserAccessToken,
  subscribe,
} from '../../store/slices/users/actions';
import { delay } from '../../utils/delay';
import { useDidMountEffect } from '../../utils/hooks';
import { errorToast } from '../../utils/toast';
import ApplicationStatus from './ApplicationStatus';
import CreditCalculator from './CreditCalculator';
import CreditDetails from './CreditDetails';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

const InstallmentLoan = () => {
  const dispatch = useAppDispatch();
  const { installmentLoan } = useStore();
  const [splashLoader, setSplashLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const css = useStyles();
  // const dashboardDetail = useSelector(selectDashboardDetails);
  const [decisionStatus, setDecisionStatus] = React.useState();
  const [creditLimit, setCreditLimit] = React.useState();
  const [offerDetails, setOfferDetails] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [promoCreditLimit, setPromoCreditLimit] = React.useState();

  const [showSidebar, setShowSideBar] = React.useState(false);

  const refetchApi = async () => {
    setSplashLoader(true);
    setSplashLoader(false);
    dispatch(getDashboardDetails());
  };

  // const loadData = async () => {
  //   dispatch(getDashboardDetails());
  // };

  const loadData = async () => {
    const { data } = await dispatch(getCreditDecision()).unwrap();
    if (data?.creditEligibilityData?.[0]?.decision_status === 'approved') {
      setLoading(true);
      try {
        const { payload } = await dispatch(getUserAccessToken());
        if (payload?.status === API_STATUS.ERROR) {
          setLoading(false);
          errorToast(payload?.meta?.errorMessage);
        }
      } catch (error) {
        console.log('Error', error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // dispatch(subscribe());
    loadData();
    setOpen(true);
  }, []);

  const creditDecision = useSelector(selectCreditDecision);
  const LmsUserAccessToken = useSelector(selectLmsUserAccessToken);
  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);

  const fetchLmsBalanceSummary = async () => {
    try {
      if (LmsUserAccessToken) {
        setLoading(true);
        lmsApi.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${LmsUserAccessToken}`;
        await dispatch(getLmsBalanceSummary());
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log('Error', error);
      setLoading(false);
    }
  };

  useDidMountEffect(() => {
    fetchLmsBalanceSummary();
  }, [LmsUserAccessToken]);

  useEffect(() => {
    setCreditLimit(LmsBalanceSummary?.credit_limit);
  }, [LmsBalanceSummary]);

  useEffect(() => {
    setDecisionStatus(
      creditDecision?.creditEligibilityData[0]?.decision_status
    );

    setOfferDetails(
      creditDecision?.creditEligibilityData[0]?.ffp?.offer_details
    );
  }, [creditDecision]);

  if (splashLoader) {
    return <SplashValidation image={2} />;
  }

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Loader loading={loading} />
          <Helmet>
            <title>Inicio</title>
          </Helmet>
          <Box>
            <Header
              isBorderBottom={false}
              setPromoCreditLimit={setPromoCreditLimit}
              setShowSideBar={setShowSideBar}
            />
          </Box>
          <Box className={css.creditContent}>
            <Grid container rowSpacing={4} columnSpacing={{ lg: 4 }}>
              <Grid
                item
                xs={12}
                // lg={6}
                md={6}
                sx={(theme) => ({
                  '& h2': {
                    [theme.breakpoints.down('lg')]: {
                      fontSize: '22px',
                      fontFamily: `${URBANIST_FONT.BOLD} !important`,
                      marginLeft: '55px',
                      marginTop: '1.5rem',
                    },
                    [theme.breakpoints.down('md')]: { marginLeft: '85px' },
                    [theme.breakpoints.down('tablet')]: {
                      marginLeft: '25px',
                      fontSize: '18px !important',
                    },
                  },
                })}
              >
                <h2
                  style={{
                    color: '#3552CC',
                    fontFamily: 'SFUIText-semibold',
                    fontSize: 22,
                    lineHeight: '24px',
                  }}
                >
                  Detalles de tu línea de crédito
                </h2>

                <CreditDetails
                  loading={loading}
                  LmsBalanceSummary={LmsBalanceSummary}
                  decisionStatus={decisionStatus}
                  creditLimit={creditLimit}
                  dueAmount={LmsBalanceSummary?.repayment_amt}
                  dueDate={LmsBalanceSummary?.next_due_date}
                  open_to_buy={LmsBalanceSummary?.open_to_buy}
                  outstandingAmount={
                    creditLimit - LmsBalanceSummary?.open_to_buy
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                // lg={6}
                md={6}
                sx={(theme) => ({
                  '& h2': {
                    [theme.breakpoints.down('lg')]: {
                      fontSize: '22px',
                      fontFamily: `${URBANIST_FONT.BOLD} !important`,
                      marginLeft: '55px',
                      marginTop: '1.5rem',
                    },
                    [theme.breakpoints.down('md')]: { marginLeft: '85px' },
                    [theme.breakpoints.down('tablet')]: { marginLeft: '25px' },
                  },
                })}
              >
                <h2
                  style={{
                    color: '#3552CC',
                    fontFamily: 'SFUIText-semibold',
                    fontSize: 22,
                    lineHeight: '24px',
                  }}
                >
                  Programa un nuevo desembolso
                </h2>
                <CreditCalculator
                  decisionStatus={decisionStatus}
                  // creditLimit={promoCreditLimit || creditLimit}
                  creditLimit={promoCreditLimit}
                  months={LmsBalanceSummary?.pricing}
                  repayment_option_lock={
                    LmsBalanceSummary?.repayment_option_lock
                  }
                  repayment_option={LmsBalanceSummary?.repayment_option}
                  open_to_buy={LmsBalanceSummary?.open_to_buy}
                />
              </Grid>
            </Grid>

            <ApplicationStatus
              fetchLmsBalanceSummary={fetchLmsBalanceSummary}
              decisionStatus={decisionStatus}
              LmsBalanceSummary={LmsBalanceSummary}
              creditDecision={creditDecision}
            />
          </Box>

          {/* <ModalComponent
            open={open}
            body={
              <Congratulation setOpen={setOpen} creditLimit={creditLimit} />
            }
            hideBackdrop={false}
            onBackdropClick={() => setOpen(false)}
          /> */}
        </>
      }
      rightBg
    />
  );
};

export default InstallmentLoan;
