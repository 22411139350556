import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { customColor } from '../../../../../theme/customColor';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: '5px !important',
    marginBottom: '10px !important',
  },
  container: {
    background: 'rgba(53, 82, 204, 0.05)',
    boxShadow: '0px 4px 4px rgba(53, 82, 204, 0.25)',
    borderRadius: 10,
  },
  formBox: { maxWidth: 770 },

  checkBoxContainer: {
    [theme.breakpoints.down('md')]: {
      width: 260,
    },
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
  rightContainer: {
    padding: '14px 0',
  },
  titleTime: {
    textAlign: 'left',
    color: '##1976d2',
    fontWeight: 'bold !important',
    marginBottom: '-10px !important',
  },
  errorTime: {
    color: 'red !important',
    [theme.breakpoints.down('md')]: {
      width: 260,
    },
    [theme.breakpoints.up('md')]: {
      width: '50ch',
    },
  },
  hr: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
  },
  title: {
    color: customColor.shark,
    fontSize: 22,
    fontFamily: 'SFUIText-Bold',
  },
  selectTime: {
    height: 40,
    width: 90,
    borderRadius: '10px !important',
    marginRight: 20,
  },
  link: {
    color: '#1976d2',
    fontWeight: 'bold',
  },
  textCurpQuery: {
    fontFamily: 'Roboto-Regular',
    color: customColor.shark,
    fontSize: 14,
    [theme.breakpoints.down(601)]: {
      fontSize: 13,
      fontWeight: 700,
      fontFamily: 'Roboto-Bold',
    },
  },
  límite: {
    color: '#686872',
    fontSize: 14,
  },
  labelColor: {
    color: '#7e8083 !important',
  },
  titleLableTime: {
    marginTop: '4px !important',
  },
  checkboxLabel: {
    fontSize: '1rem !important',
    color: '#1976d2',
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem !important',
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: '14px !important',
    },
  },
}));

export default useStyles;
