export const screenHome = "homePage";
export const screenLogin = "loginPage";
export const screenSignUp = "signupPage";
export const screenGeneralInfo = "generaleInfoPage";
export const screenProfessionalDetail = "professionalDetailPage";

export const screenGeneralData = "generalDataPage";
export const screenDataProcedure = "dataProcedurePage";
export const screenCreditRequest = "creditRequestPage";
export const screenInformationPersonal = "informationPersonalPage";
export const screenKnowYouBetter = "knowYouBetterPage";
export const screenClientSignature = "clientSignaturePage";
export const screenReferenceNumber = "referenceNumberPage";
export const screenSummary = "summaryPage";

export const homePage = {
  signUpHeader: {
    label: "header",
    action: "sign-up",
    screen: screenHome,
  },
  newsHeader: {
    label: "header",
    action: "news",
    screen: screenHome,
  },
  loginHeader: {
    label: "header",
    action: "login",
    screen: screenHome,
  },
  applyForCredit: {
    label: "content",
    action: "Apply for credit",
    screen: screenHome,
  },
  loginWithUs: {
    label: "content",
    action: "Login with us",
    screen: screenHome,
  },
  applyForCreditBottom: {
    label: "content",
    action: "Apply for credit",
    screen: screenHome,
    value: "bottom",
  },
};

export const loginPage = {
  login: {
    label: "loginForm",
    action: "Login",
    screen: screenLogin,
  },
};

export const signupPage = {
  signUp: {
    label: "SignUpForm",
    action: "Create Account",
    screen: screenSignUp,
  },
};

export const generalDataPage = {
  continue: {
    label: "GeneralDataForm",
    action: "Continue",
    screen: screenGeneralData,
  },
};

export const dataProcedurePage = {
  continue: {
    label: "DataProcedureForm",
    action: "Continue",
    screen: screenDataProcedure,
  },
};

export const creditRequestPage = {
  continue: {
    label: "CreditRequestInfo",
    action: "Continue",
    screen: screenCreditRequest,
  },
};

export const generaleInfoPage = {
  continue: {
    label: "GeneralInfoForm",
    action: "Continue",
    screen: screenGeneralInfo,
  },
};

export const professionalDetailPage = {
  continue: {
    label: "PersonalDetailPage",
    action: "Continue",
    screen: screenProfessionalDetail,
  },
};

export const informationPersonalPage = {
  continue: {
    label: "InformationPersonalForm",
    action: "Continue",
    screen: screenInformationPersonal,
  },
};

export const knowYouBetterPage = {
  continue: {
    label: "KnowYouBetterForm",
    action: "Continue",
    screen: screenKnowYouBetter,
  },
};

export const clientSignaturePage = {
  continue: {
    label: "ClientSignatureForm",
    action: "Continue",
    screen: screenClientSignature,
  },
};

// CoSigner flow Event
export const coSignerClientSignaturePage = {
  continue: {
    label: "CoSignerClientSignatureForm",
    action: "Continue",
    screen: screenClientSignature,
  },
};

export const coSignerInformationPersonalPage = {
  continue: {
    label: "CoSignerInformationPersonalForm",
    action: "Continue",
    screen: screenInformationPersonal,
  },
};

export const coSignerGeneraleInfoPage = {
  continue: {
    label: "CoSignerGeneralInfoForm",
    action: "Continue",
    screen: screenGeneralInfo,
  },
};

export const coSignerProfessionalDetailPage = {
  continue: {
    label: "CoSignerProfessionalDetailPage",
    action: "Continue",
    screen: screenProfessionalDetail,
  },
};

export const coSignerGeneralDataPage = {
  continue: {
    label: "CoSignerGeneralDataForm",
    action: "Continue",
    screen: screenGeneralData,
  },
};

export const coSignerReferenceNumberPage = {
  continue: {
    label: "CoSignerReferenceInput",
    action: "Continue",
    screen: screenReferenceNumber,
  },
};

export const coSignerSummaryPage = {
  continue: {
    label: "CoSignerSummary",
    action: "Continue",
    screen: screenSummary,
  },
};
