const v1 = "v1";

export const medsiAuth = {
  requestResetEmail: "v1/request-reset-email/",
  passwordReset: "/v1/reset-password/",
  passwordResetComplete: "/v1/password-reset-complete/",
  emailVerify: `${v1}/email_verify/`,
  login: `${v1}/login/`,
  register: `${v1}/register/`,
  medicalProcedure: `${v1}/medical_procedure/`,
  identityInfo: `${v1}/identity_info/`,
  generalInfo: `${v1}/general_info/`,
  checkEmail: `${v1}/resend_email/`,
  applicationDelete: `${v1}/application/delete/`,

  applicationSummary: `${v1}/application/summary/`,
  contractLink: `${v1}/application/download/loan-agreement/`,

  applicationAll: `${v1}/application/all/`,
  applicationLoanDetail: `${v1}/application/loan-details/`,
  applicationProcedure: `${v1}/application/procedure-details/`,
  applicationStepper: `${v1}/application/stepper/`,
  logout: `${v1}/logout/`,
  getOnboardingSteps: `${v1}/identify_unboarding_steps/`,
  curpRegistration: `${v1}/curp_registration/`,
  additionalInfo: `${v1}/additional_info/`,
  furtherInfo: `${v1}/further_info/`,
  finalInfo: `${v1}/application/signature/`,
  finalInfoOld: `${v1}/final_info/`,
  saveCreditRequest: `${v1}/tnc/save-tnc/`,
  resendConfirmationMail: `${v1}/resend_email/`,
  doctorConfirmation: `${v1}/doctor_confirm/`,
  userFeedBack: `${v1}/user-feedback/`,
  doctorBankDetails: `${v1}/doctor_confirmation_process/`,
  verifyPassword: `${v1}/check-password/`,

  saveApplication: `${v1}/application/application-member`,
  qrApplicationDetais: `${v1}/qrurl/`,
  applicationAgreement: `${v1}/application/get-agreement/`,
};

export default medsiAuth;
