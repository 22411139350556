import { Box } from "@mui/material";

// import useStyle from "./styles";
// import TopContent from "./TopContent"; THIS CODE NEED TO BE REMOVED, it was refactored.
// import TableData from "./TableData";
// import TableData2 from "./TableData2";
// import TableData3 from "./TableData3";
// import TableData4 from "./TableData4";
import BottomContainer from "./BottomContainer";
import MedicSpecializations from "./MedicSpecializations";
import Odontology from "./Odontology";
import PlasticSurgery from "./PlasticSurgery";
import CosmeticTratments from "./CosmeticTratments";

export const HomePage = () => {

  return (
    <Box sx={{ width: "100%", marginTop: "-4px", backgroundColor: "#fff" }}>
      <MedicSpecializations />
      <Odontology />
      <PlasticSurgery />
      <CosmeticTratments/>
      <BottomContainer />
    </Box>
  );
};

export default HomePage;
