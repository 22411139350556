export const themeBreakpoints = {
  values: {
    xs: 300,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    mobile: 0,
    tablet: 640,
    laptop: 1024,
    desktop: 1200,
    xxs: 280,
  },
};
