import { Grid } from "@mui/material";
import { currencyFormat } from "../../../../utils/index";
import React from "react";
import PaymentHistory from "../../../../components/ModalContents/paymentHistory";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import { API_STATUS, URBANIST_FONT } from "../../../../constants/common";
import { useAppDispatch } from "../../../../store";
import { getDownloadContractLink } from "../../../../store/slices/users/actions";
import { errorToast } from "../../../../utils/toast";

const Loans = (props) => {
  const dispatch = useAppDispatch();
  const { each, LmsBalanceSummary, applicationId } = props;
  const [open, setOpen] = React.useState(false);
  const [schedule, setSchedule] = React.useState(false);

  const handleOpen = (schedule) => {
    setOpen(!open);
    if (schedule?.length > 0) {
      setSchedule(schedule);
    }
  };

  const handleDownloadContract = async (application_id) => {
    const res = await dispatch(getDownloadContractLink(application_id));
    if (res?.payload?.status === API_STATUS.SUCCESS) {
      if (res?.payload?.data?.downloadable_url !== "") {
        window.open(
          res?.payload?.data?.downloadable_url,
          "_blank",
          "noopener,noreferrer"
        );
      } else {
        errorToast(res?.payload?.message);
      }
    } else {
      errorToast(res?.payload?.data?.message);
    }
  };

  const tenure =
    each.tenure && each.term_duration
      ? each.tenure / each.term_duration
      : each.tenure;

  return (
    <div>
      <Grid
        container
        rowSpacing="28px"
        style={{
          padding: 32,
          background: "#FFFFFF",
          filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          borderRadius: 20,
          fontFamily: "SFUIText-semibold",
        }}
      >
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Monto del préstamo
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {currencyFormat(each.req_amt)}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Tasa de interés
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {each.interest_rate ? `${each.interest_rate}%` : "--"}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Monto mensual a pagar
          </label>
          <div
            style={{
              fontFamily: URBANIST_FONT.BOLD,
              marginTop: 10,
              color: "#00B63E",
            }}
          >
            {each.term_amt ? currencyFormat(each.term_amt) : "--"}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Fecha del próximo vencimiento
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {LmsBalanceSummary?.next_due_date
              ? LmsBalanceSummary?.next_due_date
              : "--"}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Fecha de Solicitud
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {each.created_at ? each.created_at : "--"}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            {" "}
            Fecha de desembolso
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {each.disbursed_date ? each.disbursed_date : "--"}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Monto total de reembolso
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {currencyFormat(each.total_repayment)}
          </div>
        </Grid>
        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Plazo
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {tenure ? `${tenure} meses` : "--"}
          </div>
        </Grid>

        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Preferencia de pago
          </label>
          <div style={{ fontFamily: URBANIST_FONT.BOLD, marginTop: 10 }}>
            {each.term_duration ? `Quincenal` : "Mensual"}
          </div>
        </Grid>

        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Calendario de pago del préstamo
          </label>
          <div
            style={{
              fontFamily: URBANIST_FONT.BOLD,
              marginTop: 10,
              textDecoration: "underline",
              color: "#3552CC",
              cursor: "pointer",
            }}
            onClick={() => handleOpen(each.schedule)}
          >
            Mostrar
          </div>
        </Grid>

        <Grid item xxs={12} xs={6} tablet={4} laptop={3}>
          <label style={{ color: "#787C7C", fontFamily: URBANIST_FONT.BOLD }}>
            Contrato
          </label>
          <div
            style={{
              fontFamily: URBANIST_FONT.BOLD,
              marginTop: 10,
              textDecoration: "underline",
              color: "#3552CC",
              cursor: "pointer",
            }}
            onClick={() => handleDownloadContract(each.application_id)}
          >
            Mostrar
          </div>
        </Grid>
      </Grid>
      <ModalComponent
        open={open}
        handleOpen={handleOpen}
        body={
          <PaymentHistory
            setOpen={setOpen}
            schedule={schedule}
            applicationId={applicationId}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpen(false)}
      />
    </div>
  );
};

export default Loans;
