const data = {
  calculator: {
    months: "{{term}} meses",
    list: {
      amountTitle: "Monto a financiar",
      paymentFrequency: "Plazo",
      paymentPreference: "Frecuencia de pago",
      procedureType: "Tipo de procedimiento",
    },
    paymentOf: " Parcialidades de:",
    info: "CAT Promedio 78.0% sin IVA. Para fines informativos y de comparación exclusivamente. Fecha de cálculo {{currDate}} con una tasa de interés fija anual del 40% sin IVA, un plazo de {{month}} meses, pagos {{plan}} fijos, un monto de crédito de {{amount}} y monto total a pagar de {{totalAmt}}.",
  },
  header: {
    menuList: {
      menu1: "Nosotros",
      menu2: "Procedimientos",
      menu3: "Solicitud de Crédito",
      menu4: "Preguntas Frecuentes",
      menu5: "Iniciar Sesión",
      menu6: "Regístrate",
      menu7: "Sala de Prensa",
    },
  },
};

export default data;
