import { useEffect, useState } from "react";

const useConditionCheck = (data: boolean): boolean => {
  const [isValid, setIsValid] = useState(false);
  useEffect(() => {
    setIsValid(data);
  }, [data]);

  return isValid;
};

export default useConditionCheck;
