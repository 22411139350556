import { FC } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Box,
} from "@mui/material";

import { ApplicationSummary } from "../../../features/installmentLoan/store/installmentLoan";
import { i18nDate, formatMoney } from "../../../utils/formatter";
import useStyles from "../styles";

export interface ScheduleTableProps {
  applicationSummary: ApplicationSummary;
}

const ScheduleTable: FC<ScheduleTableProps> = ({ applicationSummary }) => {
  const css = useStyles();

  const {
    contractId,
    repaymentStart,
    repaymentEnd,
    loanTenure,
    paymentFrequency,
    latPaymentRate,
    annualRate,
    loanAmount,
    totalPayments,
  } = applicationSummary;

  const list = [
    {
      label: "Número de contrato",
      value: contractId || "",
    },
    {
      label: "Monto del préstamo",
      value: formatMoney(loanAmount) || "",
    },
    {
      label: "Fecha de inicio",
      value:
        repaymentStart &&
        i18nDate(repaymentStart.toString(), "es").format(
          "D [de] MMMM [del] yyyy"
        ),
    },
    {
      label: "Fecha final",
      value:
        repaymentEnd &&
        i18nDate(repaymentEnd.toString(), "es").format(
          "D [de] MMMM [del] yyyy"
        ),
    },
    {
      label: "Pagos totales",
      value: totalPayments || "",
    },
    {
      label: "Plazo del préstamo",
      value: loanTenure ? `${loanTenure} meses` : "",
    },
    {
      label: "Frecuencia de pagos",
      value: paymentFrequency || "",
    },
    {
      label: "Cuota por pago atrasado",
      value: latPaymentRate ? `${latPaymentRate}% + VAT` : "",
    },
    {
      label: "Tasa anual",
      value: annualRate ? `${annualRate}% + VAT` : "",
    },
  ];

  return (
    <Table stickyHeader aria-label="simple table">
      <TableHead className={css.headerRow}>
        <TableRow>
          <TableCell className={`${css.tableCellStyle} ${css.leftBorderTop}`}>
            <Box ml={6}>
              <b className={css.headerRowText}>{"Especificaciones"}</b>
            </Box>
          </TableCell>
          <TableCell className={`${css.tableCellStyle} ${css.rightBorderTop}`}>
            <b className={css.headerRowText}>{"Detalles"}</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {list?.map(({ label, value }, index) => {
          return (
            <TableRow hover className={css.root} key={`${label}_${index}`}>
              <TableCell
                className={`${css.tableCellStyle} ${
                  index + 1 === list.length ? css.leftBorderBottom : ""
                }`}
              >
                <Box ml={6} className={css.label}>
                  {label}
                </Box>
              </TableCell>
              <TableCell
                className={`${css.tableCellStyle} ${
                  index + 1 === list.length ? css.rightBorderBottom : ""
                }`}
              >
                {value}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ScheduleTable;
