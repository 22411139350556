import { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Box,
  FormControlLabel,
  Typography,
  RadioGroup,
  Radio,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { startCase, toLower } from "lodash";

import useStyle from "./styles";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import ManIcon from "../../assets/PersonalInformation/manIcon.svg";
import WomanIcon from "../../assets/PersonalInformation/womanIcon.svg";
import DateinputComponent from "../../components/sharedComponents/DateInputComponent";
import CustomInputText from "../../components/sharedComponents/InputText";

import { selectUserId, saveUserPlaceOfBirth } from "../../store/slices/users";
import { ROUTES } from "../../constants/routes";
import SplashValidation from "../../components/sharedComponents/SplashValidation";
import {
  selectId,
  selectIsCosigner,
  additionalInfo,
  selectCoSignerToken,
  getZipCodeInfoCosigner,
} from "../../store/slices/cosigner";
import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import { useAppDispatch } from "../../store";
import { GTMClick } from "../../utils/gtm";
import { getZipCodeInfo } from "../../store/slices/onboarding/actions";

import {
  informationPersonalPage,
  coSignerInformationPersonalPage,
} from "../../constants/gtm";
import { API_STATUS } from "../../constants/common";
import { errorToast } from "../../utils/toast";
import { getCosignerPersonalInformation } from "../../store/slices/cosigner/actions";

const PersonalInformation = () => {
  const classes = useStyle();
  //Redux
  const id_client = useSelector(selectUserId);
  const id_cosigner = useSelector(selectId);
  const isUserCosigner = selectIsCosigner();
  const coSignerToken = useSelector(selectCoSignerToken);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [hasErrors, setHasErrors] = useState(false);
  const [loader, setLoader] = useState(false);

  const nowDate = new Date();

  const initialValues = {
    stateAddress: "",
    municipality: "",
    cp: "",
    street: "",
    gender: "",
    suburb: "",
    outdoorNumber: "",
    insideNumber: "",
    date: null,
    city: "",
  };

  const validate = (values) => {
    let errors = {};

    if (values.gender === "") errors.gender = "El campo es requerido";

    if (!values.date) {
      errors.date = "Selecciona una fecha";
    } else if (!moment(values.date).isValid()) {
      errors.date = "El formato de la fecha no es correcto";
      touched.date = true;
    }

    if (values.street === "") errors.street = "El campo es requerido";

    if (values.outdoorNumber === "")
      errors.outdoorNumber = "El campo es requerido";

    if (values.suburb === "") errors.suburb = "El campo es requerido";

    if (values.city === "") errors.city = "El campo es requerido";

    if (!values.stateAddress) errors.stateAddress = "El campo es requerido";

    if (!values.municipality) errors.municipality = "El campo es requerido";

    if (values.cp.length !== 5) {
      errors.cp = "Valor incorrecto";
    }

    setHasErrors(true);

    return errors;
  };

  const onSubmit = async (values) => {
    let payload = {
      id: isUserCosigner ? id_cosigner : id_client,
      data: {
        personalInformation: {
          gender: values.gender,
          dateOfBirth: values.date,
          street: values.street,
          externalNumber: values.outdoorNumber,
          internalNumber: values.insideNumber,
          suburb: values.suburb,
          state: values.stateAddress,
          municipality: values.municipality,
          zip: values.cp,
          city: values.city,
          createOn: new Date(),
        },
      },
    };
    if (isUserCosigner) {
      //Post to db
      GTMClick(coSignerInformationPersonalPage.continue);
      const arg = {
        token: coSignerToken,
        personalInformation: {
          gender: values.gender,
          date_of_birth: moment(values.date).format("YYYY-MM-DD"),
          address: {
            street: values.street,
            outside_no: values.outdoorNumber,
            inside_no: values.insideNumber,
            colony: values.suburb,
            city: values.city,
            state: values.stateAddress,
            municipality: values.municipality,
            cp: values.cp,
          },
        },
      };
      const res = await dispatch(additionalInfo(arg)).unwrap();

      if (res?.status === API_STATUS.SUCCESS) {
        // history.push(ROUTES.knowYouBetter);
        history.push(ROUTES.coSigneridentityInformation);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      } else {
        history.push(ROUTES.coSigneridentityInformation);
      }
    } else {
      GTMClick(informationPersonalPage.continue);
      const res = await dispatch(
        saveUserPlaceOfBirth(payload.data.personalInformation)
      ).unwrap();

      if (res?.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.knowYouBetter);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
      setLoader(true);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  const getTitle = (str) => {
    return startCase(toLower(str));
  };

  const fetchData = async () => {
    if (values?.cp?.length > 4) {
      const getData = () => {
        if (isUserCosigner) {
          return dispatch(getZipCodeInfoCosigner(values?.cp)).unwrap();
        } else {
          return dispatch(getZipCodeInfo(values?.cp)).unwrap();
        }
      };
      const { status, meta, data } = await getData();
      if (status === API_STATUS.SUCCESS) {
        formik.setFieldValue(
          "municipality",
          getTitle(data?.municipality || "")
        );
        formik.setFieldValue("city", getTitle(data?.city || ""));
        formik.setFieldValue("stateAddress", getTitle(data?.state || ""));
      } else if (status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
    }
  };

  const fetchPersonalInformation = async () => {
    const { status, data } = await dispatch(
      getCosignerPersonalInformation()
    ).unwrap();

    if (status === API_STATUS.SUCCESS) {
      setFieldValue("gender", data?.gender);
      setFieldValue("date", data?.date);
      setFieldValue("stateAddress", data?.address?.state || "");
      setFieldValue("municipality", data?.address?.municipality || "");
      setFieldValue("cp", data?.address?.cp || "");
      setFieldValue("street", data?.address?.street || "");

      setFieldValue("outdoorNumber", data?.address?.outside_no || "");
      setFieldValue("insideNumber", data?.address?.inside_no || "");
      setFieldValue("city", data?.address?.city || "");
      setFieldValue("suburb", data?.address?.colony || "");
    }
  };

  useEffect(() => {
    fetchPersonalInformation();
  }, []);

  useEffect(() => {
    fetchData();
  }, [values?.cp]);

  let isValidForm =
    Object.entries(formik.errors).length === 0 && hasErrors ? true : false;

  if (loader) {
    return <SplashValidation image={2} />;
  } else {
    return (
      <div className={classes.content}>
        <Box className={classes.headerContainer}></Box>
        <Container component="main" maxWidth="xs">
          <Grid
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <img src={Logo} alt="logo" className={classes.logo} />
            <Typography variant="h2" className={classes.textTitle}>
              Datos Personales
            </Typography>
            {/* <Typography variant="h3" className={classes.textBirth}>
              Datos Personales
            </Typography> */}
            <Box mt={10}>
              <Typography variant="h3" className={classes.textGender}>
                Género
              </Typography>
            </Box>
            <RadioGroup
              aria-label="gender"
              defaultValue="female"
              name="gender"
              className={classes.radioGender}
              onChange={handleChange}
              value={values?.gender}
            >
              <FormControlLabel
                value="Hombre"
                control={<Radio />}
                label={
                  <span className={classes.formControlRadio}>
                    <img
                      src={ManIcon}
                      className={classes.iconRadio}
                      alt="man"
                    />{" "}
                    Hombre
                  </span>
                }
              />
              <FormControlLabel
                value="Mujer"
                control={<Radio />}
                label={
                  <span className={classes.formControlRadio}>
                    <img
                      src={WomanIcon}
                      className={classes.iconRadio}
                      alt="woman"
                    />{" "}
                    Mujer{" "}
                  </span>
                }
              />
            </RadioGroup>

            <Typography variant="h3" className={classes.dateBirth}>
              Fecha de nacimiento
            </Typography>
            <DateinputComponent
              name="date"
              maxDateValue={new Date()}
              dateValue={values.date}
              handleDate={(value) => {
                setFieldValue("date", value);
              }}
              errors={errors.date}
              touched
              onBlur={handleBlur}
              hasDate={false}
              minDate={nowDate.setFullYear(nowDate.getFullYear() - 80)}
            />

            <Typography variant="h3" className={classes.dateBirth}>
              Dirección
            </Typography>
            <Grid container spacing={1} className={classes.gridStyle}>
              <Grid item xs={12}>
                <CustomInputText
                  fullWidth={true}
                  name="street"
                  insideLabel="Calle"
                  value={values.street}
                  handleChange={handleChange}
                  errors={errors.street}
                  touched={touched.street}
                  handleBlur={handleBlur}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputText
                  fullWidth={true}
                  stylesInput={classes.mtInputAddress}
                  name="outdoorNumber"
                  insideLabel="No. exterior"
                  value={values.outdoorNumber}
                  handleChange={handleChange}
                  errors={errors.outdoorNumber}
                  touched={touched.outdoorNumber}
                  handleBlur={handleBlur}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={6}>
                <CustomInputText
                  fullWidth={true}
                  stylesInput={classes.mtInputAddress}
                  name="insideNumber"
                  insideLabel="No. interior"
                  value={values.insideNumber}
                  handleChange={handleChange}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomInputText
                  fullWidth={true}
                  stylesInput={classes.mtInputAddress}
                  name="suburb"
                  insideLabel="Colonia"
                  value={values.suburb}
                  handleChange={handleChange}
                  errors={errors.suburb}
                  touched={touched.suburb}
                  handleBlur={handleBlur}
                  disabled={false}
                />
              </Grid>
              <Grid item xs={4}>
                <Box mt={2}>
                  <NumberFormat
                    className={classes.selectAddress}
                    customInput={TextField}
                    size="small"
                    name="cp"
                    label="C.P."
                    color={!errors.cp && touched.cp ? "success" : "secondary"}
                    value={values.cp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.cp && touched.cp ? true : false}
                    helperText={errors.cp && touched.cp ? errors.cp : ""}
                    inputProps={{
                      maxLength: 5,
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box mt={2}>
                  <CustomInputText
                    stylesInput={classes.selectAddress}
                    insideLabel="Alcaldía o municipio"
                    value={values.municipality}
                    name={"municipality"}
                    handleChange={handleChange}
                    errors={errors.municipality}
                    touched={touched.municipality}
                    handleBlur={handleBlur}
                    placeholder="Alcaldía o municipio"
                  />
                </Box>
              </Grid>

              <Grid item xs={12} lg={12} my={2}>
                <Box>
                  <div className="myinput labelFix">
                    <CustomInputText
                      stylesInput={classes.selectAddress}
                      insideLabel="Ciudad"
                      value={values.city}
                      name="city"
                      handleChange={handleChange}
                      errors={errors.city}
                      touched={touched.city}
                      handleBlur={handleBlur}
                      placeholder="Ciudad"
                    />
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box mt={2}>
                  <CustomInputText
                    stylesInput={classes.selectAddress}
                    insideLabel="Estado"
                    value={values.stateAddress}
                    name={"stateAddress"}
                    handleChange={handleChange}
                    errors={errors.stateAddress}
                    touched={touched.stateAddress}
                    handleBlur={handleBlur}
                    placeholder="Estado"
                  />
                </Box>
              </Grid>
            </Grid>

            <ButtonComponent
              onClick={handleSubmit}
              text="Continuar"
              disabled={!isValidForm}
            />
          </Grid>
        </Container>
      </div>
    );
  }
};

export default PersonalInformation;
