import Box from '@mui/material/Box';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { INSTALLMENT_LOAN_ROUTES, ROUTES } from '../../constants/routes';

import HomeIcon from '../icons/Home';
import LoanIcon from '../icons/loan';
import StatementIcon from '../icons/Statement';
// import RequestNewCreditIcon from "../icons/RequestNewCredit";
import GradientMettaLogoWithName from '../../assets/Logos/FooterLogo-v2.svg';
import MettaLogoWithName from '../../assets/Logos/MedsiGradientLogo.png';
// import MettaLogoWithName from "../../assets/Logos/SideBarLogo.svg";
import CalenderMoneyIcon from '../icons/CalenderMoney';
import HandMoneyIcon from '../icons/HandMoney';
import SupportIcon from '../icons/Support';

import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { urls } from '../../config/url';
import { APPLICATION_STATUS } from '../../constants/common';
import SidebarNavigation from '../../features/installmentLoan/components/SidebarNavigation';
import { setSidebarTabValue } from '../../features/installmentLoan/store/ui';
import { useAppDispatch } from '../../store';
import useStore from '../../store/getStore';
import { userLogout } from '../../store/slices/auth';
import isURL from '../../utils/isURL';
import useStyles from './styles';

import { Backdrop } from '@mui/material';
import PaymentScheduleIcon from '../../components/icons/paymentSchedule';
import TransactionIcon from '../../components/icons/transaction';
import { selectLmsBalanceSummary } from '../../store/slices/users';

interface ISidebareProps {
  showSidebar?: boolean;
  setShowSideBar?: any;
}

const LandingSidebar: React.FC<ISidebareProps> = ({
  showSidebar = false,
  setShowSideBar,
}) => {
  const { ui, installmentLoan } = useStore();
  const dispatch = useAppDispatch();
  const { balanceSummary, basicApplicationSummary } = installmentLoan;
  const isDisbursed =
    APPLICATION_STATUS.DISBURSED === basicApplicationSummary?.status;

  const css = useStyles();
  const { auth } = useStore();
  const history = useHistory();
  const { t } = useTranslation();

  const langPrefix = 'instantLineCreditTS.installmentLoan.quickLinks';

  const logoutUser = () => {
    dispatch(userLogout(auth.refreshToken));
  };

  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);

  const sidebarItems = [
    {
      title: 'Inicio',
      disabled: false,
      icon: HomeIcon,
      link: INSTALLMENT_LOAN_ROUTES.MAIN,
      display: true,
    },
    {
      title: t(`${langPrefix}.contract`),
      disabled: false,
      icon: StatementIcon,
      display: Boolean(balanceSummary?.contractPdf),
      link: INSTALLMENT_LOAN_ROUTES.CONTRACT_DETAILS,
    },
    {
      title: t(`${langPrefix}.paymentSchedule`),
      disabled: false,
      icon: CalenderMoneyIcon,
      link: INSTALLMENT_LOAN_ROUTES.PAYMENT_SCHEDULE,
      display:
        basicApplicationSummary?.isApplicationSummaryLoaded && isDisbursed,
    },
    {
      title: 'Historial de Préstamos',
      disabled: false,
      icon: LoanIcon,
      link: `${ROUTES.allLoan}/?${
        LmsBalanceSummary?.cl_id || localStorage.getItem('cl_id')
      }`,
      display: true,
    },

    {
      title: 'Movimientos',
      disabled: false,
      icon: TransactionIcon,
      link: `${ROUTES.allTransactions}`,
      display: true,
    },

    {
      title: 'Calendario de Próximos Pagos',
      disabled: false,
      icon: PaymentScheduleIcon,
      link: `${ROUTES.allPayment}`,
      display: true,
    },

    {
      title: 'Estado de Cuenta',
      disabled: false,
      icon: StatementIcon,
      link: `${ROUTES.allStatement}`,
      display: true,
    },
    // {
    //   title: "Ofertas Medsi",
    //   disabled: false,
    //   icon: Promocode,
    //   link: `${ROUTES.medsiOffer}`,
    //   display: true,
    // },

    {
      title: t(`${langPrefix}.support`),
      disabled: false,
      icon: SupportIcon,
      display: true,
      link: `${urls.newWebUrl}${ROUTES.contact}`,
    },
  ];

  const onSidebarClick = (link: string) => {
    if (!isURL(link)) {
      history.push(link);
    } else {
      window.open(link, '_');
      // dispatch(setSidebarTabValue(link));
    }
    dispatch(setSidebarTabValue(link));
  };

  const options = [
    {
      label: 'Préstamos a plazos',
      icon: HandMoneyIcon,
      // amount: 100,
      value: 0,
      link: '',
      display: true,
      menuItemId: 'btn_debit_switcher',
    },
    // {
    //   label: "label2",
    //   icon: StatementIcon,
    //   amount: 100,
    //   value: 1,
    //   link: "",
    //   display: true,
    //   menuItemId: "btn_debit_switcher",
    // },
  ];

  return (
    <>
      <Backdrop
        sx={(theme) => ({
          color: '#fff',
          zIndex: theme.zIndex.drawer + 1,
          [theme.breakpoints.up('xl')]: { display: 'none' },
        })}
        open={showSidebar}
        onClick={() => setShowSideBar(false)}
      />
      <Box
        className={css.helpChaContainer}
        sx={(theme) => ({
          [theme.breakpoints.down('xl')]: {
            display: showSidebar ? 'block' : 'none',
          },
        })}
      >
        <Box className={css.topLogoContainer}>
          <Box className={css.mainLogoContainer}>
            <img
              src={GradientMettaLogoWithName}
              style={{ maxHeight: 240 }}
              alt=''
            />
          </Box>
        </Box>
        {/* <Box mt={2}>
          <SidebarAccount
            options={options}
            value={0}
            // onChange={changeValue}
            // displayExtraButton={displayExtraButton}
            // extraButton={extraButtonOptions}
            // disabled={options.length > 0}
          />
        </Box> */}

        <Box className={css.middleNavContainer}>
          <SidebarNavigation
            items={sidebarItems.filter((item) => item.display)}
            selected={ui.sidebarTabValue}
            onChange={onSidebarClick}
          />
        </Box>

        {/* <Box className={css.bottomProfileContainer}>
          <SidebarUserFooter
            logout={logoutUser}
            goToProfile={() => {
              console.log("profile");
            }}
            logoutStr="Cerrar Sesión"
            fullName={auth?.userName}
            // profilePicUrl={
            //   user.profilePicUrl ? `${variables.s3Assets}${user.profilePicUrl}` : undefined
            // }
          />
        </Box> */}
      </Box>
    </>
  );
};

export default LandingSidebar;
