import { FC, useState, useEffect } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import SplashValidation from "../../components/sharedComponents/SplashValidation";
import { passwordReset, passwordResetComplete } from "../../store/slices/auth";
import getParams from "../../features/installmentLoan/utils/getParams";
import AlertComponent from "../../components/sharedComponents/AlertComponent";
import { errorToast } from "../../utils/toast";
import { API_STATUS } from "../../constants/common";
import { useAppDispatch } from "../../store";

import ResetPopup, { ButtonClickProps } from "./ResetPopup";
import SuccessPopup from "./SuccessPopup";
import useStyles from "./styles";

const ResetPassword: FC = () => {
  const css = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);
  const [params, setParams] = useState<{ [x: string]: string }>({});
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    setParams(getParams(["uuid", "token"]));
  }, []);

  const checkParams = async () => {
    if (params.uuid && params.token) {
      const param = { uuid: params?.uuid, token: params?.token };
      const res = await dispatch(passwordReset(param)).unwrap();
      if (res.status === API_STATUS.SUCCESS) {
        setLoading(false);
      } else if (res.status === API_STATUS.ERROR) {
        errorToast(res.meta.errorMessage);
      } else {
        errorToast("Error");
      }
    }
  };

  const onPressButton = async (payload: ButtonClickProps) => {
    if (payload && params?.uuid && params?.token) {
      const param = {
        uuid: params?.uuid,
        token: params?.token,
        ...payload,
      };
      const res = await dispatch(passwordResetComplete(param)).unwrap();
      if (res.status === API_STATUS.SUCCESS) {
        setSuccess(true)
      } else if (res.status === API_STATUS.ERROR) {
        errorToast(res.meta.errorMessage);
      } else {
        errorToast("Error");
      }
    }
  };

  useEffect(() => {
    checkParams();
  }, [params]);

  return loading ? (
    <SplashValidation image={1} trueRedirect={false} />
  ) : (
    <Box className={css.loginMainContainer}>
      <AlertComponent
        open
        handleOpen={() => null}
        handleClose={() => null}
        body={
          isSuccess ? (
            <SuccessPopup onSubmit={() => history.push("/login")} />
          ) : (
            <ResetPopup
              onSubmit={(payload: ButtonClickProps) => onPressButton(payload)}
            />
          )
        }
      />
    </Box>
  );
};

export default ResetPassword;
