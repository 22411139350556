import { makeStyles } from "@mui/styles";

const DateinputStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.down("md")]: {
      width: 260,
    },
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

export default DateinputStyles;
