import { createAsyncThunk } from "@reduxjs/toolkit";

import onboardingApi from "../../../apis/onboarding";
import onboardingEndPoint from "../../../apis/onboarding/config";

export const getSpecialtyType = createAsyncThunk(
  onboardingEndPoint.getSpecialty,
  async () => {
    const { data } = await onboardingApi.getSpecialtyType();
    return data;
  }
);

export const getProcedureType = createAsyncThunk(
  `${onboardingEndPoint.getSpecialty}/procedureType`,
  async (procedureType: string) => {
    const { data } = await onboardingApi.getProcedureType(procedureType);
    return data;
  }
);

export const getProcedureToPerform = createAsyncThunk(
  `${onboardingEndPoint.getSpecialty}/procedureToPerform`,
  async (toPerform: string) => {
    const { data } = await onboardingApi.getProcedureToPerform(toPerform);
    return data;
  }
);

export const getClinicList = createAsyncThunk(
  `${onboardingEndPoint.getDoctorClinic}/clinic`,
  async () => {
    const { data } = await onboardingApi.getClinicListForDoctor();
    return data;
  }
);

export const getDoctorList = createAsyncThunk(
  `${onboardingEndPoint.getDoctorClinic}/doc`,
  async () => {
    const { data } = await onboardingApi.getDoctorListForClinic();
    return data;
  }
);

export const getClinicListForDoctor = createAsyncThunk(
  `${onboardingEndPoint.getDoctorClinic}/?clinic=clinicName`,
  async (clinic: string) => {
    const { data } = await onboardingApi.getClinicListForDoctor(clinic);
    return data;
  }
);

export const getDoctorListForClinic = createAsyncThunk(
  `${onboardingEndPoint.getDoctorClinic}/?doc=docName`,
  async (doc: string) => {
    const { data } = await onboardingApi.getDoctorListForClinic(doc);
    return data;
  }
);

export const getBranchForClinic = createAsyncThunk(
  `${onboardingEndPoint.getClinicBranch}/?clinic=clinicName`,
  async (clinic: string) => {
    const { data } = await onboardingApi.getBranchForClinic(clinic);
    return data;
  }
);

export const getZipCodeInfo = createAsyncThunk(
  `${onboardingEndPoint.getZipCodeInfo}/?zipcode=zipCode`,
  async (zipCode: string) => {
    const { data } = await onboardingApi.getZipCodeInfo(zipCode);
    return data;
  }
);

export const getCurpInfo = createAsyncThunk(
  `${onboardingEndPoint.getCurpInfo}`,
  async (curp: string) => {
    const { data } = await onboardingApi.getCurpInfo(curp);
    return data;
  }
);
