import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { customTheme } from "../../features/installmentLoan/theme";

interface IUseStyles {
  rightRowPadless?: boolean;
  rightBg?: false | any;
  leftRowPadless?: boolean;
  showMiddleSeparator?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    twoColumnsContainer: {
      height: "100%",
      [theme.breakpoints.up("xl")]: {
        minHeight: "100vh",
      },
      display: "flex",
      "& ::-webkit-scrollbar": {
        width: 4,
      },
      "& ::-webkit-scrollbar-track": {
        background: customTheme.stone,
      },
      "& ::-webkit-scrollbar-thumb": {
        background: "#bdbdbd60",
      },
      "& ::-webkit-scrollbar-thumb:hover": {
        background: "#bdbdbd60",
      },
    },
    leftRow: (props: IUseStyles) => ({
      padding: !props.leftRowPadless ? theme.spacing(3) : 0,
      borderRight: props.showMiddleSeparator
        ? `1px solid ${customTheme.stone}`
        : "none",
      maxHeight: "100vh",
      overflow: "hidden",
      overflowY: "auto",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        borderRight: "none",
        overflow: "auto",
        height: "auto",
        maxHeight: "none",
      },
    }),
    rightRow: (props: IUseStyles) => ({
      padding: !props.rightRowPadless ? theme.spacing(3) : 0,
      backgroundColor: props.rightBg
        ? customTheme[props.rightBg as any]
        : "transparent",
      maxHeight: "100vh",
      overflow: "hidden",
      overflowY: "auto",
      [theme.breakpoints.down("sm")]: {
        borderRight: "none",
        overflow: "auto",
        height: "auto",
        maxHeight: "none",
      },
    }),
  })
);

export default useStyles;
