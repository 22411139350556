import { FC } from "react";
import { Modal, Theme, Fade } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";

import { customColor } from "../../../theme/customColor";
import { urls } from "../../../config/url";

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  modalMainContainer: {
    display: "flex",
    width: "80%",
    height: "70%",
    "&:focus": {
      outline: "none"
    },
  },
  videoContainer: {
    backgroundColor: customColor.cotton,
    height: "100%",
    borderRadius: theme.spacing(1),
    "&> video": {
      padding: theme.spacing(1),
    },
    flex: 1,
  },
  close: {
    fontSize: "40px !important",
    color: customColor.cotton,
    margin: `0 ${theme.spacing(1)}`
  },
}));

export interface ModalVideoProps {
  open: boolean;
  onClose?: any;
}
const ModalVideo: FC<ModalVideoProps> = ({ open = false, onClose }) => {
  const css = useStyles();
  return (
    <Modal
      open={open}
      onClose={onClose}
      className={css.modal}
      aria-labelledby="title-modal"
      aria-describedby="simple-modal-description"
    //   className={classes.modal}
      closeAfterTransition
    >
      <Fade in={open}>
        <div className={css.modalMainContainer}>
          <div>
            <CloseSharpIcon className={css.close} onClick={onClose} />
          </div>
          <div className={css.videoContainer}>
            <video width="100%" height="100%" autoPlay>
              <source
                src={`${urls.s3Video}/modal-video.mp4`}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalVideo;
