import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { URBANIST_FONT } from "../../constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  loginMainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#002539",
  },
  title: {
    fontSize: 18,
    fontFamily: URBANIST_FONT.MEDIUM,
  },
  loginButtonStyle: {
    marginTop: "28px !important",
    width: "auto",
    height: "max-content",
    fontSize: 16,
    fontWeight: "bold !important",
    borderRadius: "100px !important",
    padding: "12px 34px !important",

    background: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
    fontFamily: URBANIST_FONT.BOLD,

    "&:disabled": {
      opacity: 0.5,
    },
  },
  iconWrapperStyle: {
    background: "linear-gradient(180deg, #1B63DB 0%, #0ACC97 100%)",
  },
}));

export default useStyles;
