import { makeStyles } from "@mui/styles";

const SubmissionModalStyles = makeStyles((theme) => ({
  modalContainer: {
    borderRadius: 10,
    padding: 25,
    [theme.breakpoints.down("sm")]: {
      maxWidth: 320,
      maxHeight: 350,
      mainWidth: 320,
      minHeight: 350,
      left: 15,
      top: 1701,
      margin: 15,
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: 350,
      maxHeight: 380,
      mainWidth: 350,
      minHeight: 380,
      left: 384,
      top: 1249,
    },

    backgroundColor: theme.palette.primary.white,
    boxShadow: theme.shadows[5],
  },
  modalTitleContainer: {
    height: `20%`,
  },
  title: {
    fontSize: `24px !important`,
    color: theme.palette.primary.link,
    lineHeight: `28px`,
  },
  icon: {
    paddingLeft: 30,
    cursor: `pointer`,
    color: theme.palette.secondary.gray_button,

  },
  modalBodyContainer: {
    height: `60%`,
  },
  modalFooterContainer: {
    height: `20%`,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  dismissBtn: {
    color: theme.palette.primary.logout,
    marginRight:'20px !important'
  },
  firstInfo: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    color: theme.palette.primary.font_blue,
  },
  secondInfo: {
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    color: theme.palette.secondary.gray_button,
  },
}));

export default SubmissionModalStyles;
