export const pushDataLayer = (event, rest) => {
  if (window) {
    window?.dataLayer?.push({
      event,
      ...rest,
    });
  }
};

export const GTMClick = ({ screen = "", label = "", action = "" }) => {
  if (window) {
    pushDataLayer("click", {
      screen,
      label,
      action,
    });
  }
};
