import { FC } from "react";

import { IconProps, obsidian } from "./types";

const SupportIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="29"
      height="39"
      viewBox="0 0 29 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29 39H0V38.4478C0 28.79 6.50469 20.934 14.5 20.934C22.4953 20.934 29 28.7898 29 38.4478V39ZM1.1816 37.8938H27.8156C27.5825 29.1001 21.6985 22.0386 14.5 22.0386C7.30149 22.0386 1.42014 29.1001 1.1816 37.8938V37.8938Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M14.5 6.22864e-05C11.393 0.00361486 8.41439 1.16443 6.21743 3.2279C4.02046 5.29138 2.78454 8.08903 2.78076 11.0072C2.78616 11.9033 2.90578 12.7955 3.1371 13.6649L3.24687 14.0861H7.30969C7.72017 15.5841 8.64928 16.9114 9.95085 17.859C11.2524 18.8067 12.8525 19.321 14.4994 19.321C16.1462 19.321 17.7464 18.8067 19.0479 17.859C20.3495 16.9114 21.2786 15.5841 21.6891 14.0861H25.7532L25.8615 13.6649C26.2991 12.0429 26.3359 10.3485 25.969 8.71115C25.6022 7.07381 24.8414 5.53697 23.745 4.21824C22.6485 2.89951 21.2454 1.83382 19.643 1.10271C18.0407 0.371588 16.2815 -0.00557555 14.5 6.22864e-05ZM14.5 18.2151C10.8764 18.2151 7.92885 14.9835 7.92885 11.0076C7.92431 9.71278 8.25006 8.43575 8.87884 7.28342H20.1238C20.7535 8.43539 21.0788 9.71273 21.0724 11.0076C21.0724 14.9835 18.125 18.2151 14.5 18.2151ZM24.8243 12.9814H22.0156C22.1668 12.3329 22.2436 11.6711 22.2447 11.0072C22.2528 9.41279 21.818 7.84396 20.9831 6.45567L20.8773 6.28519V6.17949H8.18903L8.01977 6.45698C7.18349 7.84444 6.74721 9.41287 6.75406 11.0072C6.75718 11.671 6.83391 12.3326 6.98302 12.9814H4.17995C4.03326 12.3323 3.95787 11.6708 3.95499 11.0072C3.94714 9.61829 4.25037 8.24334 4.84501 6.97163C5.43964 5.69991 6.31237 4.55987 7.40654 3.62552C8.50072 2.69116 9.79191 1.98336 11.1962 1.54811C12.6005 1.11285 14.0866 0.959859 15.558 1.09905C17.0293 1.23823 18.4531 1.6665 19.7368 2.35604C21.0205 3.04557 22.1354 3.98096 23.0093 5.10149C23.8831 6.22201 24.4962 7.50262 24.8089 8.86017C25.1216 10.2177 25.1268 11.6218 24.8243 12.9814Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

SupportIcon.defaultProps = {
  fill: obsidian,
};

export default SupportIcon;
