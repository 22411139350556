import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Logo from "../../assets/SplashScreen/logo.svg";
import { useState } from "react";
import { Alert } from "@mui/material";
import LogInReportStyles from "./LogInReport.styles";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";



export default function LogInReport() {
  const classes = LogInReportStyles();
  const [error, setError] = useState(false);
  const history = useHistory();

  if(localStorage.getItem("loginReport")) {
    history.push(ROUTES.report);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let user = data.get("usuario");
    let contrasena = data.get("contrasena");
    const usuarios = ["Manuel", "Pablo"];
    const contrasenas = ["ManuelMetta!@."];
    if (usuarios.includes(user) && contrasenas.includes(contrasena)) {
      setError(false);
      localStorage.setItem("loginReport", true);
      setTimeout(() => {
        history.push(ROUTES.report);
      }, 2000);
    } else {
      setError(true);
    }
  };

  return (
    <Grid
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      className={classes.content}
    >
      <Container component="main" maxWidth="xs">
        <Box className={classes.boxStyle}>
          <img alt="metta-logo" src={Logo} className={classes.logo} />
          <Typography component="h1" variant="h5" className={classes.title}>
            Iniciar Sesión
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              color="security"
              id="usuario"
              label="Usuario"
              InputLabelProps={{
                classes: {
                  root: classes.input,
                },
              }}
              inputProps={{
                className: classes.input,
              }}
              name="usuario"
              autoComplete="usuario"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              color="security"
              name="contrasena"
              label="Contraseña"
              type="password"
              id="contrasena"
              autoComplete="current-password"
              InputLabelProps={{
                classes: {
                  root: classes.input,
                },
              }}
              inputProps={{
                className: classes.input,
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              className={classes.button}
            >
              Iniciar Sesión
            </Button>
            {error && (
              <Alert severity="error">
                El usuario o la contraseña son incorrectos{" "}
              </Alert>
            )}
          </Box>
        </Box>
        <Typography
          variant="body2"
          align="center"
          className={classes.textCopyright}
        >
          {"Copyright © "}
          <Link color="inherit" href="https://medsi.mx/">
            medsi.mx
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Grid>
  );
}
