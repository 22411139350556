import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API_STATUS } from "../../../constants/common";

import {
  getSpecialtyType,
  getProcedureToPerform,
  getProcedureType,
  getDoctorList,
  getClinicList,
  getDoctorListForClinic,
  getClinicListForDoctor,
  getBranchForClinic,
  getZipCodeInfo,
} from "./actions";
import { OnboardingState } from "./types";

const initialState = {
  loading: false,
  specialtyList: [],
  procedureToPerformList: [],
  procedureList: [],
  clinicList: [],
  doctorList: [],
  branchList: [],
  hasNotDoctor: false,
  hasNotDate: false,
  formValueData: {},
};

const slice = createSlice({
  name: "onboarding",
  initialState: {
    ...initialState,
  } as OnboardingState,
  reducers: {
    setLoadingValue: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    resetProcedureApplication: (state) => {
      state = initialState;
    },
    resetBranchList: (state) => {
      state.branchList = [];
    },
    setHasNotDoctor: (state, action: PayloadAction<boolean>) => {
      state.hasNotDoctor = action.payload;
    },
    setHasNotDate: (state, action: PayloadAction<boolean>) => {
      state.hasNotDate = action.payload;
    },
    updateFormValues: (state, action: any) => {
      state.formValueData = { ...state.formValueData, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSpecialtyType.fulfilled, (state, { payload = {} }) => {
      const { data, status } = payload as any;
      if (API_STATUS?.SUCCESS === status) {
        state.specialtyList = data?.values || [];
        state.procedureToPerformList = [];
        state.procedureList = [];
      }
    });
    builder.addCase(
      getProcedureToPerform.fulfilled,
      (state, { payload = {} }) => {
        const { data, status } = payload as any;
        if (API_STATUS?.SUCCESS === status) {
          state.procedureToPerformList = data?.values || [];
        }
      }
    );
    builder.addCase(getProcedureType.fulfilled, (state, { payload = {} }) => {
      const { data, status } = payload as any;
      if (API_STATUS?.SUCCESS === status) {
        state.procedureList = data?.values || [];
        // state.procedureToPerformList = [];
      }
    });
    builder.addCase(getDoctorList.fulfilled, (state, { payload = {} }) => {
      const { data, status } = payload as any;
      if (API_STATUS?.SUCCESS === status) {
        if (data?.values?.length > 0) {
          state.doctorList = data?.values?.map((item, i) => {
            return {
              drEmail: item.email,
              drPhone: item.phone_number,
              drFirstName: item.first_name,
              drLastName: item.last_name,
              clinicName: item.clinic_name,
              clinicEmail: item.clinic_email,
              clinicPhone: item.clinic_phone,
              maxCreditAmount: item.max_credit_range,
            };
          });
        }
      }
    });
    builder.addCase(getClinicList.fulfilled, (state, { payload = {} }) => {
      const { data, status } = payload as any;
      if (API_STATUS?.SUCCESS === status) {
        if (data?.values?.length > 0) {
          state.clinicList = data?.values?.map((item) => {
            return {
              drEmail: item.doctor_email,
              drPhone: item.doctor_phone_number,
              drFirstName: item.doctor_first_name,
              drLastName: item.doctor_last_name,
              clinicName: item.name,
              clinicEmail: item.email,
              clinicPhone: item.phone_number,
              maxCreditAmount: item.max_credit_range,
            };
          });
        }
      }
    });
    builder.addCase(getBranchForClinic.fulfilled, (state, { payload = {} }) => {
      const { data, status } = payload as any;
      if (API_STATUS?.SUCCESS === status) {
        if (data?.values?.length > 0) {
          state.branchList = data?.values?.map((item, i) => {
            return {
              drEmail: item.doctor_email,
              drPhone: item.doctor_phone_number,
              drFirstName: item.doctor_first_name,
              drLastName: item.doctor_last_name,
              branchName: item.branch_name,
            };
          });
        }
      }
    });
  },
});

export default slice.reducer;

export const {
  setLoadingValue,
  resetBranchList,
  setHasNotDate,
  setHasNotDoctor,
  updateFormValues,
  resetProcedureApplication,
} = slice.actions;
export {
  getSpecialtyType,
  getProcedureToPerform,
  getProcedureType,
  getDoctorList,
  getClinicList,
  getDoctorListForClinic,
  getClinicListForDoctor,
  getBranchForClinic,
  getZipCodeInfo,
};

export const getOnboardingState = (state: any) => state.onboarding;
