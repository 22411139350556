import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import contentBg from "../../../assets/home/content-bg-v2.svg";
import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundImage: `url("${contentBg}")`,
    padding: "0 6%",
    backgroundColor: "#ededf7",
    backgroundSize: "cover",
  },
  heading: {
    color: customColor.black,
    fontSize: 32,
    fontFamily: "Nexa-Bold",
    fontWeight: "700",
    lineHeight: 1.2,
    [theme.breakpoints.down(601)]: {
      fontSize: 24,
    },
  },
  headingLight: {
    color: customColor.black,
  },
  description: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: 20,
    [theme.breakpoints.down(601)]: {
      fontSize: 16,
    },
  },
  content: {
    marginTop: theme.spacing(34),
    [theme.breakpoints.down(601)]: {
      marginTop: theme.spacing(4),
    },
  },
  imgContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "90%",
    "&> img": {
      width: "90%",
    },
  },
}));

export default useStyles;
