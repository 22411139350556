import { useState } from "react";
import { Box, Grid, useTheme, useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

import Banner from "../Banner";
import { GTMClick } from "../../../../utils/gtm";
import { homePage } from "../../././../../constants/gtm";

import useStyle from "./styles";
import useCommonStyles from "../../commonStyles";
import profit from "../../images/profit.svg";
import medical from "../../images/medical.svg";
import solution from "../../images/solution.svg";

export const CardItem = ({ label, img, title }) => {
  const css = useStyle();
  const [show, setShow] = useState(false);

  return (
    <Grid item xs={12} md={4} display="flex" justifyContent="center">
      <Box
        className={css.card}
        key={label}
        onMouseLeave={() => {
          setShow(false);
        }}
        onMouseOver={() => {
          title && setShow(true);
        }}
      >
        {show ? (
          <Box className={css.listContainer}>
            {title?.map((listItem) => {
              return (
                <Box px={4} mt={1} width="100%">
                  {typeof listItem === "string" ? (
                    <li className={css.hoverListItemStyle}>{listItem}</li>
                  ) : (
                    <li className={css.hoverListItemStyle}>
                      {listItem.label}
                      {listItem?.items && (
                        <Box pl={4} mt={0.5} width="100%">
                          <ul className={css.childList}>
                            {listItem?.items?.map((itemText) => {
                              return <li>{itemText}</li>;
                            })}
                          </ul>
                        </Box>
                      )}
                    </li>
                  )}
                </Box>
              );
            })}
          </Box>
        ) : (
          <>
            <Box className={css.imgContainer}>
              <img src={img} />
            </Box>
            <Box className={css.cardTitle}>{label}</Box>
          </>
        )}
      </Box>
    </Grid>
  );
};

export const Cards = () => {
  const css = useStyle();
  const commonCss = useCommonStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cards = [
    {
      label: "Beneficios para tí",
      img: profit,
      title: [
        "Completa la aplicación en línea fácil y rápidamente",
        "Elige pagos quincenales o mensuales, y paga en hasta 9 meses",
        "No necesitas una tarjeta de crédito o comprobantes de nómina",
        "Recibe una respuesta en menos de 24 horas",
        "Puedes hacer tus pagos por transferencia, con tarjeta o en efectivo en tiendas de autoservicio",
        "Tasas de interés menores a los bancos y tarjetas de crédito",
        {
          label: "Atiéndete con el médico de tu preferencia: ",
          items: ["no estas limitado a una red cerrada de doctores"],
        },
      ],
    },
    {
      label: "Áreas terapéuticas \ny Especialidades médicas",
      title: [
        {
          label: "Procedimientos cosméticos no quirúrgicos",
          items: [
            "Botox, fillers, rejuvenecimiento con laser o fototerapia, depilación, radio-frecuencia, criolipólisis, etc.",
          ],
        },
        {
          label: "Procedimientos estéticos quirúrgicos",
          items: ["en consultorio y en quirófano, invasivos y no-invasivos"],
        },
        {
          label: "Odontología cosmética y funcional",
          items: [
            "Ortodoncia, prótesis y estética dental, cirugía bucal y maxilofacial",
          ],
        },
        "Oftalmología",
        "Restauración capilar y transplante de cabello",
        "Procedimientos quirúrgicos electivos en general",
      ],
      img: medical,
    },
    {
      label: "Soluciones financieras",
      img: solution,
      title: [
        "Préstamos en línea para tratamientos médicos y cosméticos, con aprobación en tiempo real para procedimientos en consultorio (quirúrgicos y no quirúrgicos)",
        "Financiamiento de procedimientos quirúrgicos y tratamientos médicos pre-programados",
        "Financiamiento complementario para cuotas de deducibles y co-pago para pacientes asegurados",
        'Programas de ahorro y/o prepago para procedimientos, a través de un esquema de "tandas" (próximamente)',
      ],
    },
  ];

  if (isMobile) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="max-content"
        className={css.cardMainContainer}
      >
        <Banner item={cards} />
        <Box paddingTop="6%" className={css.btn}>
          <button
            className={commonCss.basicGradientButtonStyle}
            style={{ width: "264px", height: "60px" }}
            onClick={() => {
              GTMClick(homePage.applyForCredit);
              history.push("/registro");
            }}
          >
            Solicitar crédito
          </button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      paddingTop="2%"
    >
      <Box width="80%">
        <Grid container>
          {cards?.map(({ label, img, title }) => {
            return (
              <CardItem label={label} img={img} title={title} key={label} />
            );
          })}
        </Grid>
      </Box>
      <Box paddingTop="6%">
        <button
          className={commonCss.basicGradientButtonStyle}
          style={{ width: "264px", height: "60px" }}
          onClick={() => {
            GTMClick(homePage.applyForCredit);
            history.push("/registro");
          }}
        >
          Solicitar crédito
        </button>
      </Box>
    </Box>
  );
};

export default Cards;
