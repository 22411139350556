import { Box, Grid } from "@mui/material";

import image1 from "../images/image1.svg";
import image2 from "../images/image2.svg";
import image3 from "../images/image3.svg";
import cardBg from "../images/card-bg.svg";

import useStyle from "./styles";
import Cards from "./Cards";

export const Content = () => {
  const css = useStyle();

  return (
    <Box className={css.container}>
      <Box className={css.body}>
        <Grid container px={8} style={{ position: "absolute", top: "-10%" }}>
          <Grid
            container
            alignItems="center"
            // style={{ position: "absolute", top: "-12%" }}
          >
            <Grid item md={5.5} xs={12}>
              <Box
                className={css.imageContainer}
                display="flex"
                justifyContent="end"
                mr={6}
              >
                <img src={image1} className={css.image} />
              </Box>
            </Grid>
            <Grid item md={6.5} xs={12}>
              <Box className={css.title}>Tú decides</Box>
              <Box className={css.content}>
                Tú eliges el procedimiento y el médico
                <br /> que tú quieras. No tienes que elegir entre una <br /> red
                limitada de doctores.
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            // style={{ position: "absolute", top: "16%" }}
            style={{ marginTop: "-10%" }}
          >
            <Grid item md={6.5} xs={12}>
              <Box textAlign="right">
                <Box className={css.title}>En tus tiempos</Box>
                <Box className={css.content}>
                  En medsi tienes la flexibilidad de financiar tu procedimiento
                  <br /> a 3, 6, 9 meses, en pagos quincenales o mensuales{" "}
                  <br />a tu preferencia.
                </Box>
              </Box>
            </Grid>
            <Grid item md={5.5} xs={12}>
              <Box display="flex"
                justifyContent="start" className={css.imageContainer} ml={6}>
                <img src={image2} className={css.image} />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            // style={{ position: "absolute", top: "26%" }}
            style={{ marginTop: "-10%" }}
          >
            <Grid item md={5.5} xs={12}>
              <Box display="flex"
                justifyContent="end" className={css.imageContainer} mr={6}>
                <img src={image3} className={css.image} />
              </Box>
            </Grid>
            <Grid item md={6.5} xs={12}>
              <Box className={css.title}>Es muy fácil</Box>
              <Box className={css.content}>
                El proceso de aplicación en línea toma menos de 10 minutos{" "}
                <br />y recibes tu aprobación en menos de 24 horas.
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box className={css.cardContainer}>
        <Box textAlign="center">
          <img src={cardBg} />
        </Box>
        <Box className={css.cardList}>
          <Cards />
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
