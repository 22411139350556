import { FC, useEffect, useCallback } from "react";
import { Box, Grid } from "@mui/material";

import AccordionComponent from "../../../components/AccordionComponent";

import useStyle from "./styles";
import { faqsList } from "./helper";
import Button from "./Button";
import { useState } from "react";

interface BodyContentProps {
  filterString?: string;
}

export const BodyContent: FC<BodyContentProps> = ({ filterString }) => {
  const css = useStyle();
  const [selectedMenu, setSelectedMenu] = useState("GET_CREDIT");

  const getItem = useCallback(() => {
    console.log(filterString, "filterString", faqsList);
  }, [filterString]);

  useEffect(() => {
    getItem();
  }, [getItem]);

  const leftMenu = [
    {
      label: "Obtener un crédito",
      key: "GET_CREDIT",
    },
    {
      label: "Mi crédito Medsi",
      key: "MY_CREDIT",
    },
    {
      label: "Doctores, tipos de procedimientos y especialidades médicas",
      key: "DR_PROCEDURE",
    },
  ];

  const list = faqsList?.[selectedMenu].filter((item) => {
    return (
      item?.title?.includes(filterString) ||
      item?.description?.includes(filterString)
    );
  });

  return (
    <Box className={css.container}>
      <Grid container>
        <Grid item md={3.5}>
          <Box mt={4}>
            {leftMenu?.map(({ label, key }) => {
              return (
                <Button
                  isSelected={key === selectedMenu}
                  title={label}
                  key={key}
                  onClick={() => setSelectedMenu(key)}
                />
              );
            })}
          </Box>
        </Grid>
        <Grid item md={0.5} />
        <Grid item md={8}>
          <Box className={css.faqContainer}>
            <AccordionComponent list={list} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BodyContent;
