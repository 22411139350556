import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import closeImage from "../../assets/Vector.png";
import {
  selectUserEmail,
  selectUserId,
  setIsAuthorized,
  setVerifyEmail,
} from "../../store/slices/users";
import { updateData, verifyEmailValidation } from "../../utils/fetchData";

import ButtonComponent from "../sharedComponents/ButtonComponent";
// import ButtonComponent from "../ButtonComponent";
import AddCircle from "@mui/icons-material/AddCircle";
import TermConditionTextStyles from "./AcceptTermCondition.styles";

const AcceptTermCondtion = (props) => {
  const { setOpen, setShow, userEmail, setTncAccepted } = props;
  const [errorValidate, setErrorValidate] = useState(false);
  const dispatch = useDispatch();
  const classes = TermConditionTextStyles();
  const id = useSelector(selectUserId);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onClick = async () => {
    setIsSubmitting(true);
    setOpen(false);
    setShow(true);
    setTncAccepted(true);
    setIsSubmitting(false);
  };

  const handleClose = () => {
    setOpen(false);
    setTncAccepted(false);
  };

  return (
    <div style={{ position: "relative", background: "#fff", borderRadius: 6 }}>
      <AddCircle
        style={{
          color: "#3552CC",
          transform: "rotate(45deg)",
          position: "absolute",
          right: 20,
          top: 20,
          zIndex: 900,
        }}
        onClick={handleClose}
      />
      <div className={classes.mainContainer}>
        <Typography
          variant="subtitle2"
          align="center"
          className={classes.modalDescription}
        >
          Por este conducto autorizo expresamente a Munbrunn S.A. de C.V., para
          que por conducto de sus funcionarios facultados lleve a cabo consultas
          sobre mi comportamiento crediticio en las Sociedades de Información
          Crediticia que estime conveniente.
          <br />
          <br />
          Así mismo, declaro que conozco la naturaleza y alcance de la
          información que se solicitará, del uso que Munbrunn S.A. de C.V hará
          de tal información y de que ésta podrá realizar consultas periódicas
          sobre mi historial, consintiendo que esta autorización se encuentre
          vigente por un período de 3 años contados a partir de su expedición y
          en todo caso durante el tiempo que se mantenga la relación jurídica.
          <br />
          <br />
          Para autorizar ingrese nuevamente su contraseña.
        </Typography>
      </div>
      <Box display="flex" justifyContent="center" alignItems="center">
        <ButtonComponent
          type="submit"
          text="Acepto"
          disabled={false}
          onClick={onClick}
          className={classes.submitBtn}
          loading={isSubmitting}
          style={{
            width: "auto",
            padding: "0px 92px",
          }}
        />
      </Box>
    </div>
  );
};

export default AcceptTermCondtion;
