import { Backdrop, CircularProgress } from "@mui/material";

const Loader = ({ loading }: { loading: boolean }) => {
  const backdropStyles = {
    zIndex: 10000,
    color: "#fff",
  };
  return (
    <Backdrop style={backdropStyles} open={loading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
