import { makeStyles } from "@mui/styles";
import customColor from "../../../../theme/customColor";
import ProcedureBanner from "../../../../assets/procedureType/procedure-banner.svg";

const MedicSpecializationStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    backgroundImage: `url(${ProcedureBanner})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center -80px",
    paddingBottom: 50,
  },
  image: {
    width: 248,
    height: 260,
    marginLeft: 480,
  },
  tableView: {
    marginTop: `${theme.spacing(4)} !important`,
    padding: "0 12% !important",
    "&> div": {
      color: "rgba(0, 0, 0, 0.5)",
      fontFamily: "SFUIText-Regular",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: 18,
      lineHeight: 1.2,
      "&>div:first-child": {
        fontWeight: "700",
        color: customColor.black,
      },
      "&>div:last-child": {
        marginTop: theme.spacing(3),
      },
    },
  },
}));

export default MedicSpecializationStyles;
