import { Box, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import {
  API_STATUS,
  API_STATUS_REQUEST,
  DECISION_STATUS,
  SIGNATURE_STATUS,
  URBANIST_FONT,
} from "../../../constants/common";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch } from "../../../store";
import { resetProcedureApplication } from "../../../store/slices/onboarding";
import { saveTakeLoan } from "../../../store/slices/users/actions";
import { currencyFormat } from "../../../utils";
import { errorToast, successToast } from "../../../utils/toast";
import useStyles from "./styles";

const CreditCalculator = (props) => {
  const history = useHistory();
  const {
    decisionStatus,
    creditLimit,
    months,
    open_to_buy,
    repayment_option_lock,
    repayment_option,
  } = props;
  let isQuinceal = true;
  let isMensual = true;

  const initialMonth = months && Object.keys(months);
  const css = useStyles();
  const dispatch = useAppDispatch();
  const [creditAmount, setCreditAmount] = React.useState();
  const [tenure, setTenure] = React.useState();

  const [isBiweeklyPayment, setIsBiweeklyPayment] = React.useState(
    repayment_option === "2-week"
  );
  const [paymentDuration, setPaymentDuration] = React.useState(
    repayment_option || "1-month"
  );

  if (!repayment_option_lock) {
    isQuinceal = true;
    isMensual = true;
  } else {
    if (repayment_option === "2-week") {
      isQuinceal = true;
      isMensual = false;
    } else {
      isQuinceal = false;
      isMensual = true;
    }
  }

  const handleAmount = (e) => {
    const value = Number(e.target.value.replaceAll(",", ""));
    if (!isNaN(value)) {
      console.log("1");
      if (creditLimit) {
        console.log("2");
        if (value > creditLimit) {
          setCreditAmount(creditLimit);
        } else {
          setCreditAmount(value);
        }
      } else {
        console.log("open", open_to_buy);
        console.log("value", value);
        if (value > open_to_buy) {
          setCreditAmount(open_to_buy);
        } else {
          setCreditAmount(value);
        }
      }
    } else {
      // setCreditAmount(0);
    }
  };

  React.useEffect(() => {
    if (initialMonth && initialMonth[0]) {
      setTenure(parseInt(initialMonth && initialMonth[0]));
    }
    if (creditLimit || open_to_buy) {
      setCreditAmount(creditLimit || open_to_buy);
    }
  }, [open_to_buy, months, creditLimit]);

  React.useEffect(() => {
    setCreditAmount(30000);
    setTenure(6);
    // return async () => {
    //   localStorage.removeItem("application_id");
    // };
  }, []);

  React.useEffect(() => {
    // if (repayment_option_lock) {
    if (repayment_option === "2-week") {
      setIsBiweeklyPayment(true);
      setPaymentDuration("2-week");
    }
    if (repayment_option === "1-month") {
      setIsBiweeklyPayment(false);
      setPaymentDuration("1-month");
    }
    // }
  }, [repayment_option, repayment_option_lock]);

  const handleTenure = (e, month) => {
    if (month) {
      setTenure(month);
    }
  };

  const calculateAmount = (p, term) => {
    let ir;
    {
      months &&
        Object.keys(months)?.map((each, index) => {
          if (tenure === parseInt(each)) {
            ir = months[each];
          }
        });
    }

    if (!months) {
      ir = 40;
    }

    if (p > 0 && term > 0 && !isNaN(p) && !isNaN(term)) {
      const pa = +p;
      const a = pa + ((pa * ir) / (100 * 12)) * term;
      const vat = (a - pa) * 0.16;
      const totalAmt = vat + a;
      let emi = totalAmt / term;
      if (isBiweeklyPayment) {
        emi = emi / 2;
      }

      return {
        totalAmt,
        emi,
      };
    }
    return {};
  };

  const { emi = 0, totalAmt = 0 } = useMemo(
    () => calculateAmount(creditAmount, tenure),
    [creditAmount, tenure, isBiweeklyPayment]
  );

  const handleTakeLoan = async () => {
    if (Number(creditAmount) < 1000) {
      return errorToast(
        "Ingrese el monto del préstamo con un mínimo de $ 1,000"
      );
    }
    const res = await dispatch(
      saveTakeLoan({
        req_amt: creditAmount,
        tenure: tenure,
        repayment_option: paymentDuration,
      })
    );

    if (res?.payload?.status === API_STATUS.SUCCESS) {
      window.localStorage.application_id = res?.payload?.data?.application_id;
      successToast(SIGNATURE_STATUS[res?.payload?.status]);
      dispatch(resetProcedureApplication());
      history.push(`${ROUTES.stepper}/?${res?.payload?.data?.application_id}`);
    } else {
      errorToast(API_STATUS_REQUEST[res?.payload?.status]);
    }
  };

  const isTakeLoanDisabled =
    open_to_buy === 0 ||
    (decisionStatus !== DECISION_STATUS.ACCEPTED &&
      decisionStatus !== DECISION_STATUS.APPROVED);

  return (
    <div>
      <Box
        sx={(theme) => ({
          background: "#FFFFFF",
          borderRadius: "20px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          // [theme.breakpoints.down("md")]: {
          //   margin: "0px 10px",
          // },
          [theme.breakpoints.down("lg")]: {
            width: "80%",
            margin: "1rem 0px 0px 58px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontFamily: `${URBANIST_FONT.REGULAR} !important`,
          },
          [theme.breakpoints.down("md")]: {
            width: "60%",
            margin: "1rem 0px 1rem 85px",
          },
          [theme.breakpoints.down("tablet")]: {
            width: "90%",
            margin: "1rem auto",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            padding: "30px 40px 15px 40px",
            [theme.breakpoints.down("tablet")]: {
              padding: "30px 20px 15px 20px",
            },
          })}
        >
          <p className={css.startHere}>
            {/* <span style={{ fontWeight: 700 }}>¡Empieza aquí! </span> */}
            ¡Empieza aquí! Ingresa el monto solicitado para tu tratamiento
          </p>

          <div
            style={{
              border: "1px solid #000",
              borderRadius: 8,
              position: "relative",
              overflow: "hidden",
              padding: "0px 10px",
            }}
          >
            <input
              style={{
                height: 40,
                maxWidth: 320,
                width: "100%",
                fontWeight: 600,
                fontSize: 18,
                color: "#000000",
                border: "none",
                outline: "none",
              }}
              onChange={(e) => {
                handleAmount(e);
              }}
              type="text"
              value={
                creditAmount?.toLocaleString()
                // creditAmount > open_to_buy
                //   ? open_to_buy?.toLocaleString()
                //   : creditAmount?.toLocaleString()
              }
            />
            <button
              style={{
                background: "#E6E6E6",
                position: "absolute",
                right: 0,
                top: 0,
                width: 50,
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              $
            </button>
          </div>
          <p className={css.availableCredit}>
            Tu crédito disponible es de{" "}
            <span style={{ color: "#000000" }}>
              {!creditLimit ||
                (open_to_buy === "" && "30000.0".toLocaleString())}
              $
              {creditLimit?.toLocaleString() ||
                (open_to_buy && open_to_buy.toLocaleString())}{" "}
            </span>
          </p>

          <p className={css.chooseTerm}>Elegir plazo:</p>

          <div style={{ display: "flex" }}>
            {months &&
              Object.keys(months)?.map((each, index) => (
                <span
                  key={index}
                  style={{
                    background:
                      tenure === parseInt(each) ? "#3552CC" : "#EBEEFA",
                    color: tenure === parseInt(each) ? "white" : "black",
                  }}
                  className={css.months}
                  onClick={(e) => {
                    handleTenure(e, parseInt(each));
                  }}
                >
                  {each} meses
                </span>
              ))}
            {!months && (
              <>
                <span
                  style={{
                    background: tenure === 3 ? "#3552CC" : "#EBEEFA",
                    color: tenure === 3 ? "white" : "black",
                  }}
                  className={css.months}
                  onClick={(e) => {
                    handleTenure(e, 3);
                  }}
                >
                  3 meses
                </span>
                <span
                  style={{
                    background: tenure === 6 ? "#3552CC" : "#EBEEFA",
                    color: tenure === 6 ? "white" : "black",
                  }}
                  className={css.months}
                  onClick={(e) => {
                    handleTenure(e, 6);
                  }}
                >
                  6 meses
                </span>
                <span
                  style={{
                    background: tenure === 9 ? "#3552CC" : "#EBEEFA",
                    color: tenure === 9 ? "white" : "black",
                  }}
                  className={css.months}
                  onClick={(e) => {
                    handleTenure(e, 9);
                  }}
                >
                  12 meses
                </span>
              </>
            )}
          </div>

          <p className={css.chooseTerm}>Preferencia de pago</p>
          {(isQuinceal || isMensual) && (
            <div style={{ display: "flex" }}>
              <>
                {isMensual && (
                  <span
                    style={{
                      background: !isBiweeklyPayment ? "#3552CC" : "#EBEEFA",
                      color: !isBiweeklyPayment ? "white" : "black",
                    }}
                    className={css.months}
                    onClick={(e) => {
                      // handleTenure(e, 3);
                      setPaymentDuration("1-month");

                      setIsBiweeklyPayment(false);
                    }}
                  >
                    Mensual
                  </span>
                )}
                {isQuinceal && (
                  <span
                    style={{
                      background: isBiweeklyPayment ? "#3552CC" : "#EBEEFA",
                      color: isBiweeklyPayment ? "white" : "black",
                    }}
                    className={css.months}
                    onClick={(e) => {
                      setIsBiweeklyPayment(true);
                      setPaymentDuration("2-week");
                      // handleTenure(e, 6);
                    }}
                  >
                    Quincenal
                  </span>
                )}
              </>
            </div>
          )}

          <Box
            sx={(theme) => ({
              background: " #F5F6FA",
              borderRadius: "18px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "18px",
              marginTop: "40px",
              [theme.breakpoints.down("lg")]: {
                background: " #fff",
                padding: "0px",
                fontSize: "14px",
              },
              [theme.breakpoints.down("tablet")]: {
                fontSize: "12px",
                marginTop: "1rem",
              },
            })}
          >
            <p>
              Tu pago {isBiweeklyPayment ? "quincenal" : "mensual"} sería de{" "}
              <br />{" "}
              <span className={css.currency}>${currencyFormat(emi)}</span>
            </p>

            {/* <Link to={`${ROUTES.stepper}`}> */}
            <button
              onClick={() => {
                handleTakeLoan();
              }}
              style={{
                color: isTakeLoanDisabled ? "#6B7779" : "#FFFFFF",
                border: isTakeLoanDisabled ? `1px solid #6B7779` : "",
                background: isTakeLoanDisabled ? `` : " #4CA55A",
              }}
              className={css.takeLoan}
              disabled={isTakeLoanDisabled}
            >
              Programar este Desembolso
            </button>
            {/* </Link> */}
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            padding: "15px 20px 15px 40px",
            fontSize: "14px",
            color: "#979797",
            background: "rgba(53, 82, 204, 0.05)",
            borderRadius: "0 0 20px 20px",
            [theme.breakpoints.down("lg")]: {
              background: "transparent",
              color: "#000",
              fontFamily: "Urbanist-Medium",
              fontSize: "12px",
              padding: "0px 0px 1rem",
              textAlign: "center",
            },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "10px",
              padding: "0px auto 1rem",
            },
          })}
        >
          CAT Promedio 78% sin IVA, tasa de interés fija anual del 40% sin IVA
        </Box>
      </Box>
    </div>
  );
};

export default CreditCalculator;
