import { makeStyles } from "@mui/styles";

const PlasticSurgeryStyles = makeStyles((theme) => ({
  optionsContainer: {
    maxWidth: 1300,
  },
  mainContainer:{
    marginBottom:10,
    background: "#FFFFFF",
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
    borderBottomLeftRadius: `20px`,
    borderBottomRightRadius: `20px`,
    paddingBottom:10,
  }
  
}));

export default PlasticSurgeryStyles;
