import { keyBy } from "lodash";

import { OTHER_OPTION } from "../../../constants/generals";

export const isOtherShow = ({ values }) => {
  return (
    OTHER_OPTION.toUpperCase() === values.specialtyType.toUpperCase() ||
    OTHER_OPTION.toUpperCase() === values.procType.toUpperCase()
  );
};

export const isProcedureShow = ({ values }) => {
  return OTHER_OPTION.toUpperCase() !== values.specialtyType.toUpperCase();
};

export const removeDrErrorInfo = ({ errors, values }) => {
  errors.medicName && delete errors.medicName;
  errors.medicLastName && delete errors.medicLastName;
  errors.medicEmail && delete errors.medicEmail;
  errors.medicPhone && delete errors.medicPhone;
  errors.clinicDr && delete errors.clinicDr;
  values.medicName = "";
  values.medicLastName = "";
  values.medicEmail = "";
  values.medicPhone = "";
  values.clinicDr = "";
};

export const removeDrError = ({ errors, values }) => {
  removeDrErrorInfo({
    errors,
    values,
  });
  errors.medicRelation && delete errors.medicRelation;
  values.medicRelation = "";
};

export const removeDateError = ({ errors, values }) => {
  errors.appointmentDate && delete errors.appointmentDate;
  errors.appointmentTime && delete errors.appointmentTime;
  values.appointmentDate = "";
  values.appointmentDate = null;
  values.hours = "--";
  values.minutes = "--";
  values.time = "--";
};

export const isOtherShowClinic = ({ values }) => {
  return (
    values?.clinicName &&
    OTHER_OPTION.toUpperCase() === values.clinicName?.toUpperCase()
  );
};

export const getMaxMinAmount = ({ productDetails, values, clinicList }) => {
  const clinicObj = keyBy(clinicList, "clinicName");
  let maxCreditAmt = productDetails?.maxCreditAmount || 0;
  const minCreditAmount = +(productDetails?.minCreditAmount || 0);

  if (
    clinicObj &&
    values.clinicName &&
    clinicObj?.[values.clinicName]?.maxCreditAmount
  ) {
    maxCreditAmt = +(clinicObj[values.clinicName]?.maxCreditAmount || 0);
  }

  return {
    maxCreditAmt,
    minCreditAmount,
  };
};

export const fillDoctorValues = ({
  values,
  clinicList,
  errors,
  responseData = {},
  doctorList,
}) => {
  const {
    doc_phone = "",
    doc_last_name = "",
    doc_email = "",
    doc_name = "",
  } = responseData;
  if (
    isOtherShowClinic({
      values,
    })
  ) {
    const medicDetails = doctorList.find(
      (eachDr) =>
        `${eachDr?.drFirstName} ${eachDr?.drLastName}` === values.clinicDr
    );
    if (medicDetails && medicDetails !== -1) {
      values.medicFirstName = medicDetails?.drFirstName;
      values.medicLastName = medicDetails?.drLastName;
      values.medicEmail = medicDetails?.drEmail;
      values.medicPhone = medicDetails?.drPhone;
    } else {
      values.medicFirstName = doc_name;
      values.medicLastName = doc_last_name;
      values.medicEmail = doc_email;
      values.medicPhone = doc_phone;
    }
  } else if (clinicList?.length > 0) {
    const clinicObj = keyBy(clinicList, "clinicName");
    if (clinicObj && values.clinicName && clinicObj?.[values.clinicName]) {
      const item = clinicObj?.[values.clinicName];
      if (item.drFirstName) {
        values.medicName = item.drFirstName;
      }
      if (item.drLastName) {
        values.medicLastName = item.drLastName;
      }
      if (item.drEmail) {
        values.medicEmail = item.drEmail;
      }
      if (item.drPhone) {
        values.medicPhone = item.drPhone;
      }
      if (item.drFirstName && item.drLastName) {
        values.clinicDr = `${item.drFirstName} ${item.drLastName}`;
      }
    } else {
      removeDrErrorInfo({
        values,
        errors,
      });
    }
  }
};

export const fillDoctorValuesForBranch = ({
  values,
  branchList,
  errors,
  responseData = {},
}) => {
  const {
    doc_phone = "",
    doc_last_name = "",
    doc_email = "",
    doc_name = "",
  } = responseData;
  if (branchList?.length > 0) {
    const clinicObj = keyBy(branchList, "branchName");

    if (clinicObj && values.clinicBranch && clinicObj?.[values.clinicBranch]) {
      const item = clinicObj?.[values.clinicBranch];
      values.medicName = item.drFirstName;
      values.medicLastName = item.drLastName;
      values.medicEmail = item.drEmail;
      values.medicPhone = item.drPhone;
      values.clinicDr = `${item.drFirstName} ${item.drLastName}`;
    } else {
      removeDrErrorInfo({
        values,
        errors,
      });
    }
  }
};

export const fillClinicValues = ({ values, doctorList, responseData }) => {
  const { doc_email = "", doc_phone = "" } = responseData || {};
  if (isOtherShowDoctor({ values })) {
    values.medicName = "";
    values.medicLastName = "";
    values.medicEmail = "";
    values.medicPhone = "";
  } else {
    const [doctorObj] = doctorList?.filter((item) => {
      return `${item.drFirstName} ${item.drLastName}` === values.clinicDr;
    });

    if (doctorObj && values.clinicDr) {
      values.clinicName = doctorObj.clinicName || "Otro";
      values.medicName = doctorObj.drFirstName;
      values.medicLastName = doctorObj.drLastName;
      values.medicEmail = doctorObj.drEmail;
      values.medicPhone = doctorObj.drPhone;
    }
  }
};

export const doctorListOption = (doctorList) => {
  return (
    doctorList?.map((item) => `${item.drFirstName} ${item.drLastName}`) || []
  );
};

export const isOtherShowDoctor = ({ values }) => {
  return (
    values.clinicDr &&
    OTHER_OPTION.toUpperCase() === values.clinicDr?.trim().toUpperCase()
  );
};

export const isBranchSelectionAllowed = ({ values }) => {
  const clinicList = ["kaloni"];

  return (
    values?.clinicName &&
    clinicList.indexOf(values?.clinicName.toLowerCase()) > -1
  );
};

export const branchListOption = (doctorList) => {
  return doctorList?.map((item) => `${item.branchName}`) || [];
};

export const getNumber = (amount) => {
  if (typeof amount === "string") {
    return Number(amount.replace(/[^0-9\.-]+/g, ""));
  }
  return amount;
};
