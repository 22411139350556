import { COLOR_CONSTANTS } from "../../constants/styleConstants";
import customColor from "../customColor";

export const themePalette = {
  primary: {
    main: COLOR_CONSTANTS.MAIN_BLUE,
    gray: COLOR_CONSTANTS.GRAY_90,
    white: COLOR_CONSTANTS.COLOR_WHITE,
    darkBlue: COLOR_CONSTANTS.DARK_BLUE,
    buttonUnselected: COLOR_CONSTANTS.BUTTON_UNSELECTED_GRADIENT,
    lightBlue: COLOR_CONSTANTS.LIGHT_BLUE,
    black: COLOR_CONSTANTS.COLOR_BLACK,
    maastrichtBlue: COLOR_CONSTANTS.MAASTRICHT_BLUE,
  },
  secondary: {
    main: COLOR_CONSTANTS.MAIN_BLUE,
    // main: COLOR_CONSTANTS.GENOMIC_LIFE_SECONDARY, // This is the Genomic Life Blue Gray color
  },
  success: {
    main: COLOR_CONSTANTS.SUCCESS,
  },

  // info: {
  //   // main: COLOR_CONSTANTS.COLOR_INFO,
  //   // light: COLOR_CONSTANTS.COLOR_INFO_10,
  //   // dark: COLOR_CONSTANTS.COLOR_INFO_MEDIUM
  // },
  // warning: {
  //   // main: COLOR_CONSTANTS.COLOR_WARNING,
  //   // light: COLOR_CONSTANTS.COLOR_WARNING_LIGHT,
  //   // dark: COLOR_CONSTANTS.COLOR_WARNING_DARK,
  // },
  // error: {
  //   // main: COLOR_CONSTANTS.COLOR_ERROR,
  //   // light: COLOR_CONSTANTS.COLOR_ERROR_10,
  // },
  security: {
    main: COLOR_CONSTANTS.COLOR_WHITE,
    // main: COLOR_CONSTANTS.COLOR_SECURITY,
    // light: COLOR_CONSTANTS.COLOR_SECURITY_10,
  },
  ...customColor,
};
