import React from "react";
import { Grid, Typography, Box, Button } from "@mui/material";
import QrCodeStyles from "./QrCode.styles";
import { useHistory, Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import QR from "../../assets/QrCode/qr.png";

const QrCode = () => {
  const classes = QrCodeStyles();

  const history = useHistory();

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      className={classes.content}
    >
      <Typography variant="h1" className={classes.textCode}>
        Código QR
      </Typography>

      <img src={QR} className={classes.imgQr} alt="img qr" />
      <Typography variant="h6" className={classes.textEmail}>
        Si tienes alguna duda, escríbenos a<br /> contacto@medsi.mx
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        onClick={() => history.push(ROUTES.thankYou)}
      >
        Finalizar
      </Button>

      <Typography variant="h3" className={classes.textGenerateQr}>
        Generar Código QR
      </Typography>

      <Grid container className={classes.gridStyle}>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" className={classes.gridText}>
            <Link to={ROUTES.contact} target="_blank">
              CENTRO DE AYUDA
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" className={classes.gridText}>
            <Link to={ROUTES.tnc} target="_blank">
              TÉRMINOS Y CONDICIONES
            </Link>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" className={classes.gridText}>
            <Link to={ROUTES.privacyPolicy} target="_blank">
              AVISO DE PRIVACIDAD
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QrCode;
