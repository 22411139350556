import { Box, Button, Checkbox, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import FooterLine from "../../../assets/header/footerLine.svg";
import ApplicationTnc from "../../../components/ModalContents/CoSignerForm/ApplicationTnc";
import MedsiTnc from "../../../components/ModalContents/CoSignerForm/MedsiTnc";
import StepperAcceptTermCondtion from "../../../components/ModalContents/StepperTermsandCondition";
import ButtonComponent from "../../../components/sharedComponents/ButtonComponent";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import { API_STATUS, URBANIST_FONT } from "../../../constants/common";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch } from "../../../store/index";
import { selectCoSignerToken } from "../../../store/slices/cosigner";
import {
  finalInfo,
  getCosignerAgreement,
  saveCosignerSignatures,
} from "../../../store/slices/cosigner/actions";
import { getCosignerAppId } from "../../../utils/auth";
import { errorToast } from "../../../utils/toast";
import ClientSignature from "./ClientSignatureV2";
import CosginerSignatureStyles from "./Signature.styles";

const CosignerConsentSignature = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = CosginerSignatureStyles({});

  const coSignerToken = useSelector(selectCoSignerToken);

  const [applicationId, setApplicationId] = useState<string>("");
  const [openMedsiTncModal, setOpenMedsiTncModal] = useState(false);
  const [openApplicationTncModal, setOpenApplicationTncModal] = useState(false);
  const [isMedsiTncAccepted, setIsMedsiTncAccepted] = useState(false);
  const [signature, setSignature] = useState(false);
  const [isApplicationTncAccepted, setIsApplicationTncAccepted] =
    useState(false);
  const [agreements, setAgreements] = useState({}) as any;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getCosignerApplicationId = async () => {
    const application = await getCosignerAppId();
    setApplicationId(application?.app_id);
    await fetchAgreements(application);
  };

  const fetchAgreements = async (application) => {
    const { payload } = await dispatch(
      getCosignerAgreement(application?.app_id || "")
    );
    if (payload.status === API_STATUS.SUCCESS) {
      setAgreements(payload.data);
    }
  };

  useEffect(() => {
    getCosignerApplicationId();
  }, [dispatch]);

  const handleOpenMedsiTnc = (e) => {
    if (!isMedsiTncAccepted) {
      setOpenMedsiTncModal(!openMedsiTncModal);
    } else {
      setIsMedsiTncAccepted(false);
    }
  };

  const handleOpenApplicationTnc = (e) => {
    if (!isApplicationTncAccepted) {
      setOpenApplicationTncModal(!openApplicationTncModal);
    } else {
      setIsApplicationTncAccepted(false);
    }
  };

  const handleSignatureSubmission = async () => {
    setIsSubmitting(true);
    try {
      const { payload, meta }: { meta: any; payload: any } = await dispatch(
        saveCosignerSignatures({
          token: coSignerToken,
          tnc: "accepted", //have to get from BE
          signature,
          app_id: applicationId,
        })
      );

      if (payload.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.cosignerThankyYouPage);
      }
      if (payload.status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
    } catch (error) {
      console.log("Error", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      justifyContent="center"
      alignItems="center"
      display="flex"
      flexDirection="column"
      textAlign="center"
      className={classes.content}
    >
      <Typography variant="h2" className={classes.textTitle}>
        ¡Felicidades!
      </Typography>

      <div style={{ marginTop: 52 }}>
        {agreements?.medsi_contract && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span style={{ color: "#fff" }}>
              <Checkbox
                checked={isMedsiTncAccepted}
                onChange={handleOpenMedsiTnc}
                sx={{
                  "&.Mui-checked": {
                    color: "#fff",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#fff",
                  },
                }}
              />
            </span>
            <Typography
              style={{
                color: "#fff",
                fontFamily: URBANIST_FONT.REGULAR,
                fontSize: "1rem",
              }}
            >
              {"     Acepto los "}
              <Typography
                onClick={handleOpenMedsiTnc}
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  textDecoration: "underline",
                  display: "inline-block",
                  fontFamily: URBANIST_FONT.BOLD,
                  fontSize: "1rem",
                }}
              >
                Términos y Condiciones de Medsi
              </Typography>
            </Typography>
          </div>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ color: "#3552CC" }}>
            <Checkbox
              checked={isApplicationTncAccepted}
              onChange={handleOpenApplicationTnc}
              sx={{
                "&.Mui-checked": {
                  color: "#fff",
                },
                "& .MuiSvgIcon-root": {
                  color: "#fff",
                },
              }}
            />
          </span>
          <Typography
            style={{
              color: "#fff",
              fontFamily: URBANIST_FONT.REGULAR,
              fontSize: "1rem",
            }}
          >
            {"     Acepto los "}
            <Typography
              onClick={handleOpenApplicationTnc}
              style={{
                color: "#fff",
                cursor: "pointer",
                textDecoration: "underline",
                display: "inline-block",
                fontFamily: URBANIST_FONT.BOLD,
                fontSize: "1rem",
              }}
            >
              Términos y Condiciones de la solicitud de Préstamo
            </Typography>
          </Typography>
        </div>
      </div>

      <div>
        <ClientSignature
          isAccepted={
            agreements?.medsi_contract
              ? isMedsiTncAccepted && isApplicationTncAccepted
              : isApplicationTncAccepted
          }
          setSignature={setSignature}
        />
        <ModalComponent
          open={openMedsiTncModal}
          handleOpen={handleOpenMedsiTnc}
          body={
            <MedsiTnc
              setOpen={setOpenMedsiTncModal}
              setIsAccepted={setIsMedsiTncAccepted}
              tncUrl={agreements?.medsi_contract}
            />
          }
          hideBackdrop={false}
          onBackdropClick={() => setOpenMedsiTncModal(false)}
        />
        <ModalComponent
          open={openApplicationTncModal}
          handleOpen={handleOpenApplicationTnc}
          body={
            <ApplicationTnc
              setOpen={setOpenApplicationTncModal}
              setIsAccepted={setIsApplicationTncAccepted}
              tncUrl={agreements?.loan_contract_link}
            />
          }
          hideBackdrop={false}
          onBackdropClick={() => setOpenApplicationTncModal(false)}
        />
      </div>
      <Typography className={classes.textTitle} mt={2}>
        Borrar y firmar de nuevo
      </Typography>
      <Typography className={classes.textDescription} mt={5}>
        Al firmar, aceptas las Condiciones y los Términos de este contrato, así
        como las Condiciones de Pago y Comisiones e Intereses Establecidos.
        (Tenga en cuenta: el contrato está sujeto a cambios según la revisión y
        la aprobación final de Medsi y los detalles se le comunicarán para su
        confirmación antes del desembolso).
      </Typography>

      <ButtonComponent
        className={classes.submitButton}
        onClick={handleSignatureSubmission}
        disabled={
          agreements?.medsi_contract
            ? !isMedsiTncAccepted
            : false || !isApplicationTncAccepted || !signature
        }
        text="Continuar"
        loading={isSubmitting}
      />

      <div
        style={{
          overflow: "hidden",
          width: "100%",
          maxWidth: "100vw",
          margin: "70px auto 0",
        }}
      >
        <img
          src={FooterLine}
          style={{
            height: 138,
          }}
          alt="img blanco"
        />
      </div>
    </Box>
  );
};

export default CosignerConsentSignature;
