import { FC } from "react";
import { IconProps, defaultIconColor } from "./types";

const LockIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="21"
      height="25"
      viewBox="0 0 21 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.5745 8.66519L16.3617 9.11767C17.3223 9.56929 18.1348 10.2843 18.7049 11.1797C19.2749 12.0748 19.579 13.1133 19.582 14.1744C19.582 14.1747 19.582 14.1749 19.582 14.1751L19.582 18.1977C19.5807 19.6854 18.9892 21.1117 17.9375 22.1638C16.8858 23.2159 15.4596 23.8078 13.972 23.8096H6.11378C4.62564 23.8082 3.19881 23.2166 2.14638 22.1645C1.09401 21.1124 0.501928 19.6859 0.5 18.1979V14.1745C0.50298 13.113 0.807377 12.0742 1.37776 11.179C1.94827 10.2836 2.76141 9.56874 3.72248 9.11759L4.01001 8.98261V8.66498V6.11278C4.01142 4.62475 4.6031 3.19806 5.65522 2.14579C6.70727 1.09359 8.13375 0.501699 9.62168 0.5H10.4647C11.9522 0.502408 13.3781 1.09456 14.4297 2.14665C15.4814 3.19884 16.0729 4.62517 16.0745 6.11284V8.66519H16.5745ZM4.11911 6.11169V6.11238L4.11942 8.26765L4.11951 8.91965L4.74917 8.75045C5.19529 8.63057 5.65469 8.5671 6.11662 8.56149H13.9699C14.4303 8.56726 14.8883 8.63071 15.3329 8.7504L15.9629 8.91995V8.26758V6.1123V6.1119C15.9617 4.65397 15.3823 3.25604 14.3516 2.22483C13.321 1.19362 11.9234 0.613337 10.4655 0.611298H10.4648L9.62109 0.611297L9.62057 0.611298C8.16215 0.612811 6.76388 1.19276 5.73251 2.22393C4.70114 3.25509 4.12092 4.65326 4.11911 6.11169ZM19.4726 18.1988V18.1982V14.1738V14.1732C19.4709 12.7151 18.8911 11.3172 17.8601 10.286C16.8292 9.2549 15.4314 8.67474 13.9733 8.67279H13.9726H6.11331H6.11292C4.65434 8.67393 3.25581 9.25379 2.22433 10.2851C1.19286 11.3163 0.612725 12.7147 0.611298 14.1733V14.1738L0.611297 18.1982L0.611298 18.1987C0.612958 19.6572 1.19322 21.0553 2.22467 22.0864C3.25613 23.1174 4.65453 23.6971 6.11294 23.6982H6.11331H13.9726H13.9732C15.4312 23.6963 16.8289 23.1163 17.8598 22.0854C18.8907 21.0545 19.4707 19.6568 19.4726 18.1988Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M16.5745 8.66519L16.3617 9.11767C17.3223 9.56929 18.1348 10.2843 18.7049 11.1797C19.2749 12.0748 19.579 13.1133 19.582 14.1744C19.582 14.1747 19.582 14.1749 19.582 14.1751L19.582 18.1977C19.5807 19.6854 18.9892 21.1117 17.9375 22.1638C16.8858 23.2159 15.4596 23.8078 13.972 23.8096H6.11378C4.62564 23.8082 3.19881 23.2166 2.14638 22.1645C1.09401 21.1124 0.501928 19.6859 0.5 18.1979V14.1745C0.50298 13.113 0.807377 12.0742 1.37776 11.179C1.94827 10.2836 2.76141 9.56874 3.72248 9.11759L4.01001 8.98261V8.66498V6.11278C4.01142 4.62475 4.6031 3.19806 5.65522 2.14579C6.70727 1.09359 8.13375 0.501699 9.62168 0.5H10.4647C11.9522 0.502408 13.3781 1.09456 14.4297 2.14665C15.4814 3.19884 16.0729 4.62517 16.0745 6.11284V8.66519H16.5745ZM4.11911 6.11169V6.11238L4.11942 8.26765L4.11951 8.91965L4.74917 8.75045C5.19529 8.63057 5.65469 8.5671 6.11662 8.56149H13.9699C14.4303 8.56726 14.8883 8.63071 15.3329 8.7504L15.9629 8.91995V8.26758V6.1123V6.1119C15.9617 4.65397 15.3823 3.25604 14.3516 2.22483C13.321 1.19362 11.9234 0.613337 10.4655 0.611298H10.4648L9.62109 0.611297L9.62057 0.611298C8.16215 0.612811 6.76388 1.19276 5.73251 2.22393C4.70114 3.25509 4.12092 4.65326 4.11911 6.11169ZM19.4726 18.1988V18.1982V14.1738V14.1732C19.4709 12.7151 18.8911 11.3172 17.8601 10.286C16.8292 9.2549 15.4314 8.67474 13.9733 8.67279H13.9726H6.11331H6.11292C4.65434 8.67393 3.25581 9.25379 2.22433 10.2851C1.19286 11.3163 0.612725 12.7147 0.611298 14.1733V14.1738L0.611297 18.1982L0.611298 18.1987C0.612958 19.6572 1.19322 21.0553 2.22467 22.0864C3.25613 23.1174 4.65453 23.6971 6.11294 23.6982H6.11331H13.9726H13.9732C15.4312 23.6963 16.8289 23.1163 17.8598 22.0854C18.8907 21.0545 19.4707 19.6568 19.4726 18.1988Z"
        stroke={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M16.5745 8.66519L16.3617 9.11767C17.3223 9.56929 18.1348 10.2843 18.7049 11.1797C19.2749 12.0748 19.579 13.1133 19.582 14.1744C19.582 14.1747 19.582 14.1749 19.582 14.1751L19.582 18.1977C19.5807 19.6854 18.9892 21.1117 17.9375 22.1638C16.8858 23.2159 15.4596 23.8078 13.972 23.8096H6.11378C4.62564 23.8082 3.19881 23.2166 2.14638 22.1645C1.09401 21.1124 0.501928 19.6859 0.5 18.1979V14.1745C0.50298 13.113 0.807377 12.0742 1.37776 11.179C1.94827 10.2836 2.76141 9.56874 3.72248 9.11759L4.01001 8.98261V8.66498V6.11278C4.01142 4.62475 4.6031 3.19806 5.65522 2.14579C6.70727 1.09359 8.13375 0.501699 9.62168 0.5H10.4647C11.9522 0.502408 13.3781 1.09456 14.4297 2.14665C15.4814 3.19884 16.0729 4.62517 16.0745 6.11284V8.66519H16.5745ZM4.11911 6.11169V6.11238L4.11942 8.26765L4.11951 8.91965L4.74917 8.75045C5.19529 8.63057 5.65469 8.5671 6.11662 8.56149H13.9699C14.4303 8.56726 14.8883 8.63071 15.3329 8.7504L15.9629 8.91995V8.26758V6.1123V6.1119C15.9617 4.65397 15.3823 3.25604 14.3516 2.22483C13.321 1.19362 11.9234 0.613337 10.4655 0.611298H10.4648L9.62109 0.611297L9.62057 0.611298C8.16215 0.612811 6.76388 1.19276 5.73251 2.22393C4.70114 3.25509 4.12092 4.65326 4.11911 6.11169ZM19.4726 18.1988V18.1982V14.1738V14.1732C19.4709 12.7151 18.8911 11.3172 17.8601 10.286C16.8292 9.2549 15.4314 8.67474 13.9733 8.67279H13.9726H6.11331H6.11292C4.65434 8.67393 3.25581 9.25379 2.22433 10.2851C1.19286 11.3163 0.612725 12.7147 0.611298 14.1733V14.1738L0.611297 18.1982L0.611298 18.1987C0.612958 19.6572 1.19322 21.0553 2.22467 22.0864C3.25613 23.1174 4.65453 23.6971 6.11294 23.6982H6.11331H13.9726H13.9732C15.4312 23.6963 16.8289 23.1163 17.8598 22.0854C18.8907 21.0545 19.4707 19.6568 19.4726 18.1988Z"
        stroke={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M9.69787 16.0954L9.78924 15.7112L9.43665 15.5333C9.22169 15.4249 9.0396 15.261 8.90922 15.0586C8.77884 14.8562 8.70488 14.6227 8.69499 14.3821C8.6851 14.1416 8.73964 13.9027 8.85298 13.6903C8.96631 13.4779 9.13432 13.2996 9.33964 13.1739C9.54495 13.0482 9.78012 12.9796 10.0208 12.9752C10.2615 12.9708 10.499 13.0308 10.7088 13.149C10.9186 13.2671 11.0929 13.4392 11.2139 13.6473C11.3349 13.8554 11.3981 14.0921 11.397 14.3329L11.897 14.3352L11.397 14.3333C11.3961 14.5826 11.326 14.8266 11.1946 15.0383C11.0631 15.25 10.8754 15.4211 10.6525 15.5324L10.2978 15.7096L10.3895 16.0953L11.2232 19.6027H8.86378L9.69787 16.0954Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M9.69787 16.0954L9.78924 15.7112L9.43665 15.5333C9.22169 15.4249 9.0396 15.261 8.90922 15.0586C8.77884 14.8562 8.70488 14.6227 8.69499 14.3821C8.6851 14.1416 8.73964 13.9027 8.85298 13.6903C8.96631 13.4779 9.13432 13.2996 9.33964 13.1739C9.54495 13.0482 9.78012 12.9796 10.0208 12.9752C10.2615 12.9708 10.499 13.0308 10.7088 13.149C10.9186 13.2671 11.0929 13.4392 11.2139 13.6473C11.3349 13.8554 11.3981 14.0921 11.397 14.3329L11.897 14.3352L11.397 14.3333C11.3961 14.5826 11.326 14.8266 11.1946 15.0383C11.0631 15.25 10.8754 15.4211 10.6525 15.5324L10.2978 15.7096L10.3895 16.0953L11.2232 19.6027H8.86378L9.69787 16.0954Z"
        stroke={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M9.69787 16.0954L9.78924 15.7112L9.43665 15.5333C9.22169 15.4249 9.0396 15.261 8.90922 15.0586C8.77884 14.8562 8.70488 14.6227 8.69499 14.3821C8.6851 14.1416 8.73964 13.9027 8.85298 13.6903C8.96631 13.4779 9.13432 13.2996 9.33964 13.1739C9.54495 13.0482 9.78012 12.9796 10.0208 12.9752C10.2615 12.9708 10.499 13.0308 10.7088 13.149C10.9186 13.2671 11.0929 13.4392 11.2139 13.6473C11.3349 13.8554 11.3981 14.0921 11.397 14.3329L11.897 14.3352L11.397 14.3333C11.3961 14.5826 11.326 14.8266 11.1946 15.0383C11.0631 15.25 10.8754 15.4211 10.6525 15.5324L10.2978 15.7096L10.3895 16.0953L11.2232 19.6027H8.86378L9.69787 16.0954Z"
        stroke={defaultColor ? props.fill : "currentColor"}
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

LockIcon.defaultProps = {
  fill: defaultIconColor,
};

export default LockIcon;
