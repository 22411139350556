import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  container: {
    paddingTop: 50,
    background: '#fff',
  },
  body: {
    width: '100vw'
  },
  content: {
    color: '#6B7779',
    fontSize: 15,
    fontFamily: "SFUIText-Regular",
  },
  title: {
    color:'#3552CC',
    fontSize: 22,
    fontFamily: "SFUIText-Bold",
  },
  imageContainer: {
    // position: 'relative',
    // marginBottom: 170,
    // width: '20%'
  },
  line: {
    border: '0.5px solid rgba(0, 0, 0, 0.2)'
  },
  image: {
    // position: 'absolute',
    // top: '-170px',
    width: '100%',
  },
  cardContainer: {
    // position: 'relative',
    // height: '42vw',
  },
  cardList: {
    // display: 'flex',
    // position: 'absolute',
    // top: 0,
    // bottom: 0,
    // right: 0,
    // left: 0,
  }
}));

export default styles;
