import { FC } from "react";

import { IconProps, obsidian } from "./types";

const ClockIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M49.9999 8.33331C27.0374 8.33331 8.33325 27.0375 8.33325 50C8.33325 72.9625 27.0374 91.6666 49.9999 91.6666C72.9624 91.6666 91.6666 72.9625 91.6666 50C91.6666 27.0375 72.9624 8.33331 49.9999 8.33331ZM49.9999 16.6666C68.4588 16.6666 83.3332 31.5411 83.3332 50C83.3332 68.4588 68.4588 83.3333 49.9999 83.3333C31.5411 83.3333 16.6666 68.4588 16.6666 50C16.6666 31.5411 31.5411 16.6666 49.9999 16.6666ZM45.8333 25V51.7252L63.7206 69.6126L69.6125 63.7207L54.1666 48.2747V25H45.8333Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

ClockIcon.defaultProps = {
  fill: obsidian,
};

export default ClockIcon;
