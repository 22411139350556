import { FC } from "react";
import CircularProgress from "@mui/material/CircularProgress";

import useStyles from './styles';

export interface CircularLoaderProps {}

const CircularLoader: FC<CircularLoaderProps> = ({}) => {
  return <CircularProgress size='4em'value={90}/>;
};

export default CircularLoader;
