import { makeStyles } from "@mui/styles";

const BienvenidaTextStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
    background: theme.palette.primary.white,
    borderRadius: 20,

    [theme.breakpoints.down("sm")]: {
      width: "360px !important",
      height: 380,
    },
    [theme.breakpoints.up("sm")]: {
      width: "900px !important",
      height: "max-content",
    },
  },
  modalTitleContainer: {
    marginTop: 80,
    padding: 10,
    color: theme.palette.primary.darkBlue,
  },
  modalTitle: {
    fontWeight: "800 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
      marginTop: "10px !important",
    },
    marginLeft: "20px !important",
    marginRight: "20px !important",
  },
  email: {
    color: theme.palette.primary.main,
    fontSize: "22px !important",
    fontWeight: "900 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
      marginTop: "-20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px !important",
      marginTop: "10px !important",
    },
  },
  modalDescription: {
    fontSize: "54px !important",
    color: "#3552CC",
    fontFamily: "SFUIText-bold !important",
    textAlign: "center !important",
  },

  eligibilityMsg: {
    textAlign: "center !important",
    fontSize: "24px !important",
    fontFamily: "SFUIText-semibold !important",
  },

  small: {
    marginLeft: "40px !important",
    marginRight: "40px !important",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: 300,
    },
  },
  smallError: {
    marginLeft: "40px !important",
    marginRight: "40px !important",
    color: theme.palette.error.main,
    fontWeight: "800 !important",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: 300,
    },
  },
}));

export default BienvenidaTextStyles;
