import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "55%",
    margin: "auto",
    marginTop: theme.spacing(20),
  },
  title: {
    fontSize: 58,
    fontWeight: "700",
    fontFamily: "Nexa-Bold",
    // color: customColor.accentBlue,
    color: customColor.black,
    textAlign: "center",
  },
  desc: {
    marginTop: theme.spacing(8),
    fontSize: 22,
    fontFamily: "SFUIText-Regular",
    // color: customColor.accentBlue,
    color: customColor.black,
    fontWeight: "500",
    textAlign: "center",
    width: "100%",
    padding: `0 ${theme.spacing(8)}`,
  },
  btnContainer: {
    marginTop: theme.spacing(8),
    textAlign: "center",
    display: "flex",
    // backgroundColor: customColor.accentBlue,
    backgroundImage: "linear-gradient(180deg, #29D1A5 0%, #1973D1 100%)",
    padding: `${theme.spacing(2.5)} ${theme.spacing(5)}`,
    width: "100%",
    borderRadius: theme.spacing(10),
    justifyContent: "space-between",
    "& .content": {
      color: customColor.cotton,
      fontSize: 18,
      fontFamily: "SFUIText-Regular",
      fontWeight: "500",
      background: "transparent",
      border: "none",
      outline: 0,
      width: "100%",
      "&::placeholder": {
        color: customColor.cotton,
      },
    },
    "&> button": {
      borderRadius: theme.spacing(4),
      padding: "4px 15px",
      border: "none",
      fontFamily: "SFUIText-Regular",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 16,
      // color: customColor.accentBlue,
      color: customColor.black,
      backgroundColor: customColor.cotton,
      cursor: "pointer",
      "&:disabled": {
        opacity: 0.5,
        cursor: "not-allowed",
      },
      [theme.breakpoints.down(601)]: {
        fontSize: 12,
      },
    },
  },
  checkContainer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    display: "flex",
    padding: `${theme.spacing(2.5)} ${theme.spacing(5)}`,
    width: "100%",
    borderRadius: theme.spacing(10),
    justifyContent: "center",
    alignItems: "center",
    "&> .icon": {
      width: 30,
    },
    "& > .content": {
      // color: customColor.accentBlue,
      color: customColor.black,
      fontSize: 18,
      fontFamily: "SFUIText-Regular",
      fontWeight: "500",
      marginLeft: theme.spacing(3),
    },
  },
}));

export default useStyles;
