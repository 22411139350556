import { makeStyles } from '@mui/styles';

const InputNumberStyles = makeStyles((theme) => ({
  formContainer: {
    [theme.breakpoints.down('md')]: {
      width: 260,
    },
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
  },

  helper: {
    fontSize: '12px !important',
    color: '#686872 !important',
    lineHeight: '14.32px !important',
    marginTop: '2px !important',
    marginLeft: '0 !important',
    marginRight: '0 !important',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Regular !important',
    },
  },
}));

export default InputNumberStyles;
