import { FC } from "react";

import { IconProps, obsidian } from "./types";

const PaymentScheduleIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8836 18.3208C10.8836 17.9385 10.5705 17.6289 10.1843 17.6289H7.74387C7.35804 17.6289 7.04492 17.9385 7.04492 18.3208V20.7371C7.04492 21.1198 7.35804 21.4296 7.74387 21.4296H10.1843C10.5705 21.4296 10.8836 21.1198 10.8836 20.7371V18.3208Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M16.9828 18.3208C16.9828 17.9385 16.6697 17.6289 16.2842 17.6289H13.8435C13.4577 17.6289 13.1445 17.9385 13.1445 18.3208V20.7371C13.1445 21.1198 13.4577 21.4296 13.8435 21.4296H16.2842C16.6697 21.4296 16.9828 21.1198 16.9828 20.7371V18.3208Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M23.0828 18.3208C23.0828 17.9385 22.7697 17.6289 22.3838 17.6289H19.9435C19.5572 17.6289 19.2441 17.9385 19.2441 18.3208V20.7371C19.2441 21.1198 19.5572 21.4296 19.9435 21.4296H22.3838C22.7697 21.4296 23.0828 21.1198 23.0828 20.7371V18.3208Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M10.8836 24.3661C10.8836 23.9832 10.5705 23.6738 10.1843 23.6738H7.74387C7.35804 23.6738 7.04492 23.9832 7.04492 24.3661V26.782C7.04492 27.1645 7.35804 27.4742 7.74387 27.4742H10.1843C10.5705 27.4742 10.8836 27.1644 10.8836 26.782V24.3661Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M16.9828 24.3661C16.9828 23.9832 16.6697 23.6738 16.2842 23.6738H13.8435C13.4577 23.6738 13.1445 23.9832 13.1445 24.3661V26.782C13.1445 27.1645 13.4577 27.4742 13.8435 27.4742H16.2842C16.6697 27.4742 16.9828 27.1644 16.9828 26.782V24.3661Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M23.0828 24.3661C23.0828 23.9832 22.7697 23.6738 22.3842 23.6738H19.9435C19.5572 23.6738 19.2441 23.9832 19.2441 24.3661V26.782C19.2441 27.1645 19.5572 27.4742 19.9435 27.4742H22.3842C22.7697 27.4742 23.0828 27.1644 23.0828 26.782V24.3661Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M27.4456 8.57712V4.8863C28.9176 4.93024 30.1272 6.13649 30.1272 7.61913V29.2491C30.1272 30.7571 28.8886 32.0002 27.3655 32.0002H2.76164C1.23632 32.0002 0 30.7596 0 29.2491V7.61913C0 6.13649 1.20962 4.93024 2.68167 4.8863V8.57712C2.68167 10.2453 4.0482 10.3782 5.73245 10.3782H7.65743C9.34162 10.3782 10.7263 10.2453 10.7263 8.57712V4.87305H19.4009V8.57712C19.4009 10.2453 20.7856 10.3782 22.4701 10.3782H24.3947C26.079 10.3782 27.4456 10.2453 27.4456 8.57712ZM25.3582 29.0847C26.0177 29.0847 26.5521 28.5015 26.5521 27.7826L26.5521 15.4779C26.5521 14.7587 26.0176 14.1754 25.3582 14.1754H4.71734C4.05784 14.1754 3.52344 14.7587 3.52344 15.4779V27.7826C3.52344 28.5015 4.0579 29.0847 4.71734 29.0847H25.3582Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M5.72603 8.122H7.62973C8.20756 8.122 8.67607 7.65872 8.67607 7.0865V1.03587C8.67607 0.463587 8.20756 0 7.62973 0H5.72603C5.14813 0 4.67969 0.463587 4.67969 1.03587V7.0865C4.67969 7.65872 5.14813 8.122 5.72603 8.122Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M22.4447 8.122H24.3484C24.9259 8.122 25.3944 7.65872 25.3944 7.0865V1.03587C25.3944 0.463587 24.9259 0 24.3484 0H22.4447C21.8669 0 21.3984 0.463587 21.3984 1.03587V7.0865C21.3984 7.65872 21.8669 8.122 22.4447 8.122Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

PaymentScheduleIcon.defaultProps = {
  fill: obsidian,
};

export default PaymentScheduleIcon;
