import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUserEmail,
  selectUserId,
  setIsAuthorized,
  setVerifyEmail,
} from "../../store/slices/users";
import { updateData, verifyEmailValidation } from "../../utils/fetchData";

import ButtonComponent from "../sharedComponents/ButtonComponent";
// import ButtonComponent from "../ButtonComponent";
import BienvenidaTextStyles from "./BienvenidaText.styles";

const BienvenidaMessage = (props) => {
  const { setLoader, userEmail } = props;
  const [errorValidate, setErrorValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const classes = BienvenidaTextStyles();
  const id = useSelector(selectUserId);
  const onClick = async () => {
    setIsLoading(true);
    await setLoader(true);
    setIsLoading(false);
    // will replace this logic with email verification
    // let res = await verifyEmailValidation(userEmail).catch((err) => {
    //   setErrorValidate(true);
    // });
    // if (res.data.statusCode === 200) {
    //   //If the email was verified, we need to send this information
    //   //to the database
    //   setErrorValidate(false);
    //   const payload_verifiedEmail = {
    //     id,
    //     data: {
    //       verifiedEmail: {
    //         verified: true,
    //       },
    //     },
    //   };
    //   let resSave = await updateData(payload_verifiedEmail);
    //   if (resSave.data.statusCode === 200) {
    //     dispatch(setVerifyEmail(true));
    //     setLoader(true);
    //     dispatch(setIsAuthorized(true));
    //   }
    // }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="space-evenly"
      className={classes.mainContainer}
    >
      <Grid item xs={12} className={classes.modalTitleContainer}>
        <Typography
          variant="subtitle2"
          align="center"
          className={classes.modalTitle}
        >
          Enviaremos un link de confirmación al siguiente correo:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="h3"
          align="center"
          className={classes.email}
          sx={{ overflowWrap: "break-word" }}
        >
          {userEmail ? userEmail : null}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          align="center"
          className={classes.modalDescription}
        >
          Tan pronto tengas oportunidad, revisa tu bandeja de entrada y verifica
          tu dirección de correo. Asegúrate también de checar la bandeja de
          correo no deseado.
          <br />
          <br />
          La verificación de tu email es necesaria para la aprobación de tu
          crédito.
          <br />
          <br />
          Presiona CONTINUAR para seguir adelante con el proceso de aplicación.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="center" alignItems="center">
          <ButtonComponent
            type="submit"
            text="Continuar"
            disabled={false}
            onClick={onClick}
            className={classes.submitBtn}
            loading={isLoading}
            style={{
              width: "auto",
              padding: "0px 92px",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default BienvenidaMessage;
