import React, { Fragment } from 'react';
import {
  Select,
  MenuItem,
  FormHelperText,
  TextField,
  FormControl,
} from '@mui/material';
import DropdownMenuStyles from './DropdownMenu.styles';
import TitleComponent from '../TitleComponent';
import CaretDownIcon from '../../icons/Arrows/CaretDown';

const placeholderValue = 'none';

function DropdownMenu({
  optionsObject,
  label,
  handleChange,
  name,
  value,
  centeredLabel,
  onBlur,
  errors,
  touched,
  stylesInput,
  disabled,
  classSelect,
  optionsObjectValue,
  placeholder,
  defaultValue = '',
  placeHolderStyle = '',
  listItemStyle = '',
  listItemSelectedStyle = '',
  labelStyle,
}) {
  const classes = DropdownMenuStyles();
  let textCss = '';
  if (value === placeholderValue) {
    textCss += `${placeHolderStyle} `;
  }
  return (
    <Fragment>
      {label && (
        <FormControl
          className={
            stylesInput
              ? `${classes.formContainer} ${stylesInput}`
              : classes.formContainer
          }
          error={touched && Boolean(errors)}
        >
          <TitleComponent
            text={label}
            center={centeredLabel ? centeredLabel : false}
            disabled={disabled}
            titleStyle={labelStyle}
          />
          <TextField
            select
            size='small'
            name={name}
            onChange={handleChange}
            onBlur={onBlur}
            color={value === '' ? 'error' : 'primary'}
            defaultValue={''}
            value={value}
            disabled={disabled}
            placeholder={placeholder}
            className={`${textCss} ${stylesInput || ''}`}
          >
            {placeholder && (
              <MenuItem
                className='placeholderItem'
                disabled
                value={placeholderValue}
              >
                {placeholder}
              </MenuItem>
            )}
            {optionsObject?.map((rel, indice) => {
              let className = `${listItemStyle} ${
                value === rel ? listItemSelectedStyle : ''
              }`;

              if (indice === 0) {
                className += ` ${classes.isFirstItem}`;
              }

              if (indice === optionsObject.length - 1) {
                className += ` ${classes.isLastItem}`;
              }

              return (
                <MenuItem value={rel} key={indice} className={className}>
                  {rel}
                </MenuItem>
              );
            })}
          </TextField>
          <FormHelperText className={classes.formHelperText}>
            {errors && touched && errors}
          </FormHelperText>
        </FormControl>
      )}
      {!label && (
        <Fragment>
          <Select
            className={classSelect}
            name={name}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            onBlur={onBlur}
            placeholder={placeholder}
            IconComponent={CaretDownIcon}
            defaultValue={defaultValue}
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                '& .MuiSelect-icon': {
                  width: '15px !important',
                  top: 'calc(50% - 0.4em)',
                  right: '10px',
                },
              },
            })}
          >
            {placeholder && (
              <MenuItem value={placeholderValue}>{placeholder}</MenuItem>
            )}
            {optionsObjectValue
              ? optionsObject[optionsObjectValue].map((rel, indice) => (
                  <MenuItem key={indice} value={rel}>
                    {rel}
                  </MenuItem>
                ))
              : optionsObject.map((rel, indice) => (
                  <MenuItem key={indice} value={rel}>
                    {rel}
                  </MenuItem>
                ))}
          </Select>
          <FormHelperText
            className={classes.formHelperText}
            sx={(theme) => ({
              [theme.breakpoints.down('lg')]: {
                fontFamily: 'Urbanist-Regular !important',
              },
            })}
          >
            {errors && touched && errors}
          </FormHelperText>
        </Fragment>
      )}
    </Fragment>
  );
}

export default DropdownMenu;
