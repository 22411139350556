import { FC } from "react";

import { IconProps, accentBlue } from "./types";

const StatementIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="30"
      height="39"
      viewBox="0 0 30 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_891_333)">
        <path
          d="M29.3999 6.4976L22.9018 0H6.19229V6.19141H0V38.9824H23.2046V32.7867H29.3999V6.4976ZM23.228 1.897L27.5034 6.16991H23.228V1.897ZM1.11079 37.8721V7.3037H15.9223V13.4756H22.0932V37.8721H1.11079ZM17.0346 8.0889L21.31 12.3633H17.0346V8.0889ZM23.2046 31.6772V12.6904L16.7085 6.19141H7.3058V1.1123H22.1144V7.2798H28.2873V31.6772H23.2046Z"
          fill={defaultColor ? props.fill : "currentColor"}
        />
        <path
          d="M18.7358 16.7822H4.46771V17.8921H18.7358V16.7822Z"
          fill={defaultColor ? props.fill : "currentColor"}
        />
        <path
          d="M18.7358 20.9512H4.46771V22.0605H18.7358V20.9512Z"
          fill={defaultColor ? props.fill : "currentColor"}
        />
        <path
          d="M18.7358 25.1167H4.46771V26.2275H18.7358V25.1167Z"
          fill={defaultColor ? props.fill : "currentColor"}
        />
        <path
          d="M18.7358 29.2852H4.46771V30.396H18.7358V29.2852Z"
          fill={defaultColor ? props.fill : "currentColor"}
        />
        <path
          d="M18.7358 33.4521H4.46771V34.563H18.7358V33.4521Z"
          fill={defaultColor ? props.fill : "currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_891_333">
          <rect width="29.3999" height="38.9824" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

StatementIcon.defaultProps = {
  fill: accentBlue,
};

export default StatementIcon;
