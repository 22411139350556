import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import TwoColumns from "../../components/twoColumns";
import SideBar from "../../components/sidebar";
import Header from "../../components/header";
import SectionHeader from "../../components/sectionHeader";

import useStyles from "./styles";
import FormComponent from "./FormComponent";

const PendingInformation = () => {
  const css = useStyles();
  const history = useHistory();

  const onBack = () => {
    history.goBack();
  };

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={<SideBar />}
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header isBorderBottom={false} />
          </Box>
          <Box mt={-2} ml={-4}>
            <SectionHeader
              title="DETALLES DEL PROCEDIMIENTO"
              backClick={onBack}
            />
          </Box>
          <FormComponent />
        </>
      }
      rightBg
    />
  );
};

export default PendingInformation;
