import { Box, Fade, Modal } from "@mui/material";
import React from "react";
import ModalComponentStyles from "./ModalComponent.styles";

const ModalComponent = (props) => {
  const {
    handleClose,
    open,
    body,
    hideBackdrop = true,
    onBackdropClick,
  } = props;
  const classes = ModalComponentStyles();

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="title-modal"
        aria-describedby="simple-modal-description"
        className={classes.modal}
        closeAfterTransition
        hideBackdrop={hideBackdrop}
        onBackdropClick={onBackdropClick || null}
      >
        <Fade in={open}>
          <Box
            sx={(theme) => ({
              overflow: "auto",
              background: "#fff",
              [theme.breakpoints.down("lg")]: {
                borderRadius: "50px",
              },
              [theme.breakpoints.down("md")]: {
                mx: "30px",
              },
            })}
          >
            {body}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalComponent;
