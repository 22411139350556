import { FormControl, FormHelperText, TextField } from "@mui/material";
import NumberFormat from "react-number-format";

import TitleComponent from "../TitleComponent";
import InputNumberStyles from "./InputNumber.styles";
const InputNumber = (props) => {
  const {
    textLabel,
    name,
    value,
    prefix,
    errors,
    touched,
    setField,
    field,
    handleBlur,
    formControlStyles,
    onChange,
    disabled = false,
    infoText,
    labelStyle,
  } = props;
  const classes = InputNumberStyles();
  return (
    <FormControl
      className={
        formControlStyles
          ? `${formControlStyles} ${classes.formContainer}`
          : classes.formContainer
      }
    >
      <TitleComponent
        text={textLabel}
        disabled={disabled}
        titleStyle={labelStyle}
      />
      <NumberFormat
        error={touched && Boolean(errors)}
        autoComplete="off"
        customInput={TextField}
        name={name}
        value={value}
        thousandSeparator={true}
        allowEmptyFormatting
        prefix={prefix}
        fullWidth={true}
        onBlur={handleBlur && handleBlur}
        size="small"
        onChange={onChange && onChange}
        color={value !== "" ? "success" : "secondary"}
        onValueChange={(cantidad) => {
          const { value } = cantidad;
          setField && setField(field, parseInt(value));
        }}
        allowNegative={false}
        disabled={disabled}
      />
      {infoText ? (
        <div>{infoText}</div>
      ) : (
        <FormHelperText className={classes.helper}>
          {errors && touched && errors}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default InputNumber;
