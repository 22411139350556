import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles(() => ({
  checkMark: {
    fontSize: "120px !important",
    color: customColor.accentBlue,
  },

  checkmarkWrapper: {
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg, #EFEFEF 0%, rgba(253, 253, 253, 0) 100%)",
    borderRadius: 200,
    filter: "drop-shadow(1px 4px 4px rgba(0, 0, 0, 0.25))",
    width: 156,
    height: 156,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
