import { makeStyles } from "@mui/styles";

const BienvenidaTextStyles = makeStyles((theme) => ({
  mainContainer: {
    position: "relative",
    background: theme.palette.primary.white,
    borderRadius: 5,
    overflow: "auto",
    width: 450,
    top: 50,
    paddingBottom: 70,
    [theme.breakpoints.down("sm")]: {
      width: "90vw",
      height: "65vh",
    },
  },
  modalTitleContainer: {
    marginTop: 80,
    padding: 10,
    color: theme.palette.primary.darkBlue,
  },
  modalTitle: {
    fontWeight: "800 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px !important",
      marginTop: "10px !important",
    },
    marginLeft: "20px !important",
    marginRight: "20px !important",
  },
  email: {
    color: theme.palette.primary.main,
    fontSize: "22px !important",
    fontWeight: "900 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px !important",
      marginTop: "-20px !important",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "22px !important",
      marginTop: "10px !important",
    },
  },
  modalDescription: {
    marginLeft: "35px !important",
    marginRight: "35px !important",
    color: theme.palette.primary.darkBlue,
    fontWeight: "800 !important",
    [theme.breakpoints.up("sm")]: {
      paddingTop: "54px !important",
      marginTop: "10px !important",
      marginBottom: "50px !important",
    },
  },
  small: {
    marginLeft: "40px !important",
    marginRight: "40px !important",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: 300,
    },
  },
  smallError: {
    marginLeft: "40px !important",
    marginRight: "40px !important",
    color: theme.palette.error.main,
    fontWeight: "800 !important",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-60px !important",
    },
    [theme.breakpoints.up("sm")]: {
      width: "400px !important",
      height: 300,
    },
  },
  submitBtn: {
    background: "linear-gradient(270deg, #1B63DB 0%, #0ACC97 100%)",
  },
}));

export default BienvenidaTextStyles;
