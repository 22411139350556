export const mapRepaymentAmount = (res) => {
  const { data, status } = res;

  const {
    principalAmt,
    interestAmt,
    vatAmt,
    totalAmt,
    fullClosureAmt,
    nextDueDate,
    ids_list,
    paymentLink,
  } = data;

  const idsList = ids_list?.map((item) => {
    const {
      no,
      date,
      beginningBalance,
      paymentAmount,
      interestAmt,
      principalAmt,
      vatAmt,
      endingBalance,
    } = item;
    return {
      no,
      date,
      beginningBalance,
      paymentAmount,
      interestAmt,
      principalAmt,
      vatAmt,
      endingBalance,
    };
  });

  return {
    data: {
      principalAmt,
      interestAmt,
      vatAmt,
      totalAmt,
      fullClosureAmt,
      nextDueDate,
      idsList,
      paymentLink,
    },
    meta: {
      status,
    },
  };
};
