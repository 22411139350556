import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { lmsApi } from '../../../apis/api';
import buttonLoader from '../../../assets/loader.svg';
import { useAppDispatch } from '../../../store';
import Header from '../../../components/header';
import TwoColumns from '../../../components/twoColumns';
import SideBar from '../../../components/sidebar';
import useStyles from '../styles';
import qr from '../../../assets/qr.svg';
import { Box } from '@mui/system';
import Step from './Steps';
import ProcedureCard from './StepperCard';
import {
  selectIsApplicationSummaryLoading,
  selectLmsBalanceSummary,
  selectLmsUserAccessToken,
  selectLoanDetailStepper,
  selectStepperStatus,
  selectTakeLoanDetail,
} from '../../../store/slices/users';
import {
  getApplicationStepper,
  getCreditDecision,
  getLmsBalanceSummary,
  getLoanDetails,
  getUserAccessToken,
} from '../../../store/slices/users/actions';
import QRSummary from './QRSummary';

const Stepper = () => {
  const urlId = window.location.search.slice(1, window.location.search.length);

  const dispatch = useAppDispatch();
  const selectLoanDetail = useSelector(selectTakeLoanDetail);
  const [application_id, setAppId] = React.useState();
  const [promoCreditLimit, setPromoCreditLimit] = React.useState();
  const [showSidebar, setShowSideBar] = React.useState(false);
  const isApplicationSummaryLoading = useSelector(
    selectIsApplicationSummaryLoading
  );

  console.log({ showSidebar });

  const lmsUserAccessToken = useSelector(selectLmsUserAccessToken);

  useEffect(() => {
    dispatch(getCreditDecision());
  }, [urlId, dispatch]);

  React.useEffect(() => {
    try {
      dispatch(getUserAccessToken());
    } catch (err) {}
  }, []);
  React.useEffect(() => {
    if (lmsUserAccessToken) {
      lmsApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${lmsUserAccessToken}`;
      setTimeout(() => {
        dispatch(getLmsBalanceSummary());
      }, 200);
    }
  }, [lmsUserAccessToken]);

  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);

  React.useEffect(() => {
    if (selectLoanDetail !== null || urlId) {
      setAppId(urlId || selectLoanDetail?.application_id);
    } else {
      setAppId(localStorage.getItem('application_id'));
    }
  }, []);

  React.useEffect(() => {
    if (application_id) {
      dispatch(getLoanDetails(application_id));
    }
  }, [application_id]);

  const loanDetailsStepper = useSelector(selectLoanDetailStepper);
  React.useEffect(() => {
    if (typeof application_id !== 'undefined') {
      dispatch(getApplicationStepper(application_id));
    }
  }, [application_id]);

  const StepperStatus = useSelector(selectStepperStatus);
  const css = useStyles();

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Helmet>
            <title>Inicio</title>
            {/* <meta name='viewport' content='width=100px, initial-scale=0.39' /> */}
          </Helmet>
          <Box>
            <Header
              isBorderBottom={false}
              setPromoCreditLimit={setPromoCreditLimit}
              setShowSideBar={setShowSideBar}
            />
          </Box>
          <Step StepperStatus={StepperStatus} />
          <ProcedureCard
            promoCreditLimit={promoCreditLimit}
            StepperStatus={StepperStatus}
            selectLoanDetail={selectLoanDetail}
            LmsBalanceSummary={LmsBalanceSummary}
            loanDetailsStepper={loanDetailsStepper}
          />
          {isApplicationSummaryLoading && (
            <Box
              display='flex'
              flexDirection={'row'}
              justifyContent='center'
              marginY={'40px'}
            >
              <Typography textAlign={'center'} fontSize={20}>
                Cargando Aplicación{' '}
              </Typography>
              <div style={{ margin: '0 10px' }}>
                <img src={buttonLoader} alt='Spinner' />
              </div>
            </Box>
          )}
          {StepperStatus?.contract_completed && <QRSummary />}
        </>
      }
      rightBg
    />
  );
};

export default Stepper;
