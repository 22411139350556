export const Headers = [
  "Completado",
  "Nombre",
  "Apellido Paterno",
  "Apellido Materno",
  "Celular",
  "Fecha",
  "Correo",
  /* "Contraseña", */
  "Fecha",
  "Nombre(s) del Doctor",
  "Apellido(s) del doctor",
  "Relación con el Médico",
  "Fecha de la cita",
  "Horario de cita",
  "Tipo de procedimiento",
  "Procedimiento a realizar",
  "Otro procedimiento",
  "Costo del procedimiento",
  "Monto del crédito",
  "Fecha",
  "Aviso Privacidad",
  "Geolocalización",
  "Terminos y Condiciones",
  "Meses elegidos",
  "Cantidad Mensual",
  "Fecha",
  "CURP",
  "RFC",
  "Fecha",
  /* "Lugar Nacimiento", */
  "Género",
  "Fecha de Nacimiento",
  "Calle",
  "Número interior",
  "Número exteror",
  "Alcaldia/Municipio",
  "Colonia",
  "Código postal",
  "Estado",
  "Fecha",
  "Ocupación",
  "Otra Ocupacion",
  "Nombre de la empresa",
  "Quién cubre el costo",
  "Nombre",
  "Apellido Paterno",
  "Apellido Materno",
  "Celular",
  "Correo",
  "Ingreso Mensual",
  /* "Fecha", */
  "Nombre del banco",
  "Tipo de cuenta",
  "Usuario",
  "Autorizo Historial",
  "Autorizo informacion Bancaria",
  /* "Fecha", */
  "Tipo de Identificacion",
  "Foto Delantera",
  "Foto trasera",
  /* "Fecha", */
  "Autorizo domicialización bancaria",
  "Preferencia de pago",
  "Firma",
  /* "Fecha", */
]