import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  video2: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 546,
    [theme.breakpoints.down(601)]: {
      height: 236,
    },
  },
  contentVideo2: {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > .first": {
      fontSize: 36,
      [theme.breakpoints.down(601)]: {
        fontSize: 22,
      },
      color: customColor.cotton,
      fontFamily: "Nexa-Bold",
      "&> span": {
        fontSize: 34,
        [theme.breakpoints.down(601)]: {
          fontSize: 16,
        },
      },
    },
  },
  pauseIcon: {
    fontSize: "142px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "96px !important",
    },
    color: customColor.cotton,
  },
}));

export default useStyles;
