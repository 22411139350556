import { makeStyles } from "@mui/styles";
import BackgroundDesktop from "../../assets/DatosGenerales/backgroundDesktop.svg";
import BackgroundTablet from "../../assets/DatosGenerales/backgroundTablet.svg";
import BackgroundMobile from "../../assets/DatosGenerales/backgroundMobile.svg";
import HeaderDesktopBackground from "../../assets/DatosGenerales/headerDesktopBackground.svg";
import HeaderTabletBackground from "../../assets/DatosGenerales/headerTabletBackground.svg";
import HeaderMobileBackground from "../../assets/DatosGenerales/headerMobileBackground.svg";
import customColor from "../../theme/customColor";

const GeneralDataStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    // backgroundImage: `url(${BackgroundDesktop})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url(${BackgroundTablet})`,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url(${BackgroundMobile})`,
    },
  },
  headerContainer: {
    height: 280,
    width: "100%",
    backgroundImage: `url(${HeaderDesktopBackground})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url(${HeaderTabletBackground})`,
      height: 250,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url(${HeaderMobileBackground})`,
      height: 180,
    },
  },
  logo: {
    width: 90,
    marginTop: -270,
    [theme.breakpoints.down(1001)]: {
      marginTop: -225,
    },
    [theme.breakpoints.down(601)]: {
      width: 60,
      marginTop: -150,
    },
  },
  title: {
    color: theme.palette.primary.white,
    textAlign: "center",
    marginTop: "5px !important",
    marginBottom: "140px !important",
    [theme.breakpoints.down(1001)]: {
      marginBottom: "100px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "20px !important",
      marginBottom: "80px !important",
    },
  },
  generalData: {
    fontSize: "30px !important",
    color: theme.palette.primary.main,
    [theme.breakpoints.down(601)]: {
      fontSize: "21px !important",
    },
  },
  enterCurp: {
    fontSize: "28px !important",
    marginBottom: "20px !important",
    marginTop: "5px !important",
    color: theme.palette.primary.gray,
    [theme.breakpoints.down(601)]: {
      fontSize: "15px !important",
      marginBottom: "20px !important",
      fontWeight: "bold !important",
    },
  },
  input: {
    width: 436,
    borderRadius: 10,
    [theme.breakpoints.down(601)]: {
      maxWidth: 350,
    },
    [theme.breakpoints.down(401)]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down(321)]: {
      maxWidth: 220,
    },
  },
  inputProps: {
    textTransform: "uppercase",
    textAlign: "center",
  },

  checkValidate: {
    marginTop: 20,
    marginBottom: 15,
  },
  icon: {
    color: theme.palette.primary.gray,
  },
  checkedIcon: {
    color: theme.palette.primary.main,
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  textCurpQuery: {
    // marginTop: "15px !important",
    marginTop: "4px !important",
    marginBottom: "22px !important",
    // color: theme.palette.primary.gray,
    fontSize: "14px",
    lineHeight: "16px",
    width: 436,

    [theme.breakpoints.down(601)]: {
      fontSize: "15px !important",
      fontWeight: "700 !important",
    },
  },
  isCosigner: {
    // marginTop: "10px !important",
  },
  column: {
    flexDirection: "column",
    // alignItems: "flex-start !important",
    textAlign: "center !important",
    marginTop: 8,
  },

  listItemContainer: {
    margin: "20px 0px",
  },

  listItemBank: {
    padding: "0 !important",
    "& .label": {
      color: customColor.accentBlue,
      paddingLeft: 10,
    },
  },

  textChecks: {
    color: theme.palette.primary.gray,
    [theme.breakpoints.down(601)]: {
      fontSize: "14px !important",
    },
  },
  grayColor: {
    color: theme.palette.primary.gray,
  },
  phoneNumberContainer: {
    // paddingTop: "32px",
  },
  phoneNumberInputBox: {
    width: "100%",
  },
  phoneNumberDisclaimer: {
    position: "absolute",
  },
}));

export default GeneralDataStyles;
