import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles(() => ({
  textInputIconStyle: {
    color: customColor.cotton,
  },
  input: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    "& input": {
      width: 260,
      minWidth: 260,
    }
  },
  passwordItemsContainer: {
    marginLeft: 20,
    marginBottom: 20,
  },
  textClass: {
    color: customColor.cotton,
  },
  errorText: {
    fontSize: '0.75rem',
    fontFamily: "SFUIText-Regular",
    lineHeight: 1.6,
    color: customColor.errorTextRed,
    fontWeight: 400,
  }
}));

export default useStyles;
