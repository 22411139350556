import { Box } from "@mui/material";
import { FC } from "react";

import useStyles from "./styles";

export interface MenuProps {
  menuList: any;
}

const Menu: FC<MenuProps> = ({ menuList }) => {
  const css = useStyles();

  return (
    <Box className={css.headerButtonsContainer}>
      <Box flexDirection="row" display="flex" className={css.listContainer}>
        {menuList?.map(({ label, icon, onClick, menuCss, selected }) => {
          const css = selected ? "selected" : "";

          return (
            <div
              onClick={() => onClick && onClick()}
              className={menuCss || css}
              key={label}
            >
              {label}
              {icon && <img src={icon} />}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default Menu;
