import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';

const ButtonComponent = (props) => {
  const useStyles = makeStyles((theme) => ({
    button: {
      height: 45,
      width: 180,
      marginTop: 10,
      marginBottom: '30px !important',
      backgroundColor: '#3552CC',
      borderRadius: '100px !important',
    },
    buttonDisabled: {},
  }));
  const {
    text,
    type,
    disabled,
    onClick,
    loading = false,
    className: buttonClassName,
    ...restButtonProps
  } = props;
  const classes = useStyles();

  return (
    <Button
      type={type && type}
      onClick={onClick}
      disabled={disabled || loading}
      className={`${classes.button} ${buttonClassName || ''}`}
      variant='contained'
      color='primary'
      sx={(theme) => ({
        textTransform: 'capitalize',
        fontWeight: 'bold',
        '&.Mui-disabled': {
          height: 45,
          width: 180,
          background: '#979797',
          color: '#fff',
        },
        [theme.breakpoints.down('lg')]: {
          fontFamily: 'Urbanist-SemiBold !important',
        },
        [theme.breakpoints.down('tablet')]: { fontSize: '14px' },
      })}
      {...restButtonProps}
    >
      {text}
      {loading && <CircularProgress size={14} sx={{ ml: 2 }} />}
    </Button>
  );
};

export default ButtonComponent;
