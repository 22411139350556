import React from "react";
import { Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PasswordItemStyles from "./PasswordItems.styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const PasswordItem = (props) => {
  const { text, success, textClass = '' } = props;
  const classes = PasswordItemStyles();
  return (
    <Box display="flex" justifyContent="left" className={classes.itemContainer}>
      {success ? (
        <CheckCircleIcon
          className={`${classes.succesIcon} ${classes.icon}`}
          fontSize="small"
        />
      ) : (
        <CheckCircleOutlineIcon
          className={`${classes.unSuccessIcon} ${classes.icon}`}
          fontSize="small"
        />
      )}
      <Typography variant="body2" className={`${classes.passwordItemText} ${textClass}`}>
        {text}
      </Typography>
    </Box>
  );
};

export default PasswordItem;
