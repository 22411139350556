import "moment-timezone";
import moment, { Moment } from "moment";

const currencyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

export const formatMoney = (amount: number): string => {
  return currencyFormatter.format(amount);
};

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const i18nDate = (UTCTimeStamp: string, locale: string): Moment => {
  const iDate = UTCTimeStamp
    ? moment.utc(UTCTimeStamp).tz(timeZone)
    : moment().tz(timeZone);
  return iDate.locale(locale ? locale : moment.locale());
};

export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(" ") as string[];

  let [hours, minutes] = time.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12).toString();
  }

  return `${hours}:${minutes}`;
};
