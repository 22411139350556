import { getCoSignerToken } from "../../utils/auth";
import api from "../api";
import coSigner from "./config";

const addCoSignerHeader = () => {
  const token = getCoSignerToken();
  console.log("addCoSignerHeaderToken", token);
  return { headers: { Authorization: `Api-Key ${token}` } };
};

const coSignerAPI = {
  async checkReferenceCode(data) {
    let url = `${coSigner.checkReferenceCode}${data.code}`;
    if (data.isBureauPull) {
      url += `&bureau-pull=${data.isBureauPull}`;
    }
    return api.get(url, {
      ...addCoSignerHeader(),
    });
  },
  async applicationSummary(data) {
    return api.get(coSigner.applicationSummary, {
      ...addCoSignerHeader(),
    });
  },
  async curpRegistration(data) {
    return api.post(coSigner.curpRegistration, data.rfcAndCurp, {
      ...addCoSignerHeader(),
    });
  },
  async getCosignerPersonalInformation() {
    return api.get(coSigner.additionalInfo, {
      ...addCoSignerHeader(),
    });
  },
  async additionalInfo(data) {
    return api.post(coSigner.additionalInfo, data.personalInformation, {
      ...addCoSignerHeader(),
    });
  },
  async finalInfo(data) {
    return api.post(coSigner.finalInfo, data.finalInfo, {
      ...addCoSignerHeader(),
    });
  },
  async saveCosignerSignatures(data) {
    return api.post(coSigner.saveCosignerSignatures, data.finalInfo, {
      ...addCoSignerHeader(),
    });
  },
  async identifyCosignerUnboardingSteps(data) {
    return api.get(coSigner.identifyCosignerUnboardingSteps, {
      ...addCoSignerHeader(),
    });
  },
  async getZipCodeInfo(zipcode = "") {
    return api.post(
      coSigner.getZipCodeInfo,
      { zipcode },
      {
        ...addCoSignerHeader(),
      }
    );
  },

  async updateCosignerPhonenumber(payload = {}) {
    return api.patch(coSigner.updatePhonenumber, payload, {
      ...addCoSignerHeader(),
    });
  },

  async getCosignerAgreement(app_id = "") {
    return api.get(`${coSigner.agreement}?app_id=${app_id}`, {
      ...addCoSignerHeader(),
    });
  },
};

export default coSignerAPI;
