import { makeStyles } from "@mui/styles";

import { customTheme } from "../../../features/installmentLoan/theme";

const useStyles = makeStyles(() => ({
  tableCellStyle: {
    "&.MuiTableCell-root": {
      backgroundColor: "transparent",
      padding: "10px 16px",
      height: 54,
      fontSize: 16,
      textAlign: 'center',
    },
    "&.MuiTableCell-head": {
      height: 60
    }
  },
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: customTheme.cotton,
    },
    "&:nth-of-type(even)": {
      backgroundColor: customTheme.stone,
    },
  },
  headerRow: {
    backgroundColor: customTheme.accentBlue,
  },
  headerRowText: {
    color: customTheme.cotton,
    fontSize: 19,
    fontFamily: "SFUIText-Bold",
  },
  leftBorderTop: {
    borderTopLeftRadius: 10,
  },
  rightBorderTop: {
    borderTopRightRadius: 10,
  },
  leftBorderBottom: {
    borderBottomLeftRadius: 10,
  },
  rightBorderBottom: {
    borderBottomRightRadius: 10,
  },
}));

export default useStyles;
