export const amountFormatterMethod = (amount: number): string => {
  return Intl.NumberFormat('en-US', {
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);
};

export const bankersRoundFormatted = (n: number, d = 2): string => {
  n = n * Math.pow(10, d);
  const r = Math.round(n);
  const val = Math.abs(n) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return amountFormatterMethod(val / Math.pow(10, d));
};

export const bankersRound = (n: number, d = 2): number => {
  n = n * Math.pow(10, d);
  const r = Math.round(n);
  const val = Math.abs(n) % 1 === 0.5 ? (r % 2 === 0 ? r : r - 1) : r;
  return val / Math.pow(10, d);
};
