import { makeStyles } from "@mui/styles";
import { customColor } from "../../../../../../../theme/customColor";

const styles = makeStyles((theme) => ({
  container: {
    width: "90%",
    maxWidth: 560,
    backgroundColor: customColor.cotton,
    padding: theme.spacing(4),
    borderRadius: theme.spacing(2),
  },
}));

export default styles;
