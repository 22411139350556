import { FormControl, FormHelperText } from "@mui/material";
import TitleComponent from "../TitleComponent";
import PhoneInput from "react-phone-input-2";
import "./style.css";
import "./general.css";
import InputPhoneStyles from "./InputPhone.styles";

function InputPhone({
  label,
  name,
  handleChange,
  value,
  errors,
  handleBlur,
  touched,
  centeredLabel,
  stylesInput,
  disabled = false,
  placeholder = "+52 551 111 1111",
}) {
  const error = Boolean(errors);
  const classes = InputPhoneStyles();
  return (
    <FormControl
      className={
        stylesInput ? `${stylesInput} ${classes.inputSize}` : classes.inputSize
      }
    >
      <TitleComponent
        text={label}
        center={centeredLabel ? centeredLabel : false}
        disabled={disabled}
      />
      <PhoneInput
        country="mx"
        name={name}
        onChange={handleChange}
        value={value}
        onBlur={handleBlur}
        inputStyle={{
          "&:focus": {
            borderColor: "red",
          },
        }}
        rules={{ required: true }}
        disabled={disabled}
        placeholder={placeholder}
        dropdownClass="testdrop"
      />
      {error && (
        <FormHelperText align="center" className={classes.formHelperText}>
          {errors}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export default InputPhone;
