// collection of endpoints goes here

const ILEndPoint = `v2`;

export const medsiEndPointV2 = {
  getProcedureInfo: `${ILEndPoint}/procedure_info/`,
  paymentInitiate: `${ILEndPoint}/mexpago/payment/initiate/`,
  creditStatement: `${ILEndPoint}/lms/download/credit-statement/`,
};

export default medsiEndPointV2;
