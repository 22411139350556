import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(15),
    // backgroundColor: "#cfdbf7",
    backgroundImage:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.02) 100%)",
    width: "90%",
    borderRadius: theme.spacing(4),
    marginTop: theme.spacing(8),
  },
  faqContainer: {
    padding: `${theme.spacing(8)} ${theme.spacing(12)}`,
    backgroundColor: customColor.cotton,
    boxShadow: " 0px 5px 16px rgba(8, 15, 52, 0.06)",
    borderRadius: theme.spacing(4),
  },
  select: {
    marginTop: theme.spacing(4),
    borderRadius: theme.spacing(3),
    padding: `${theme.spacing(3)} ${theme.spacing(2)} ${theme.spacing(
      3
    )} ${theme.spacing(3)}`,
    border: "none",
    fontFamily: "SFUIText-Regular",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    cursor: "pointer",
    lineHeight: 1.4,
    color: customColor.obsidian,
    backgroundColor: customColor.cotton,
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: 12,
    },
    textAlign: "left",
  },
  buttonSelected: {
    color: customColor.cotton,
    // backgroundColor: customColor.accentBlue,
    backgroundColor: "#00263A",
  },
}));

export default useStyles;
