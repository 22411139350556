import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { customTheme } from "../../theme";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 8,
    },
    containedPrimary: {
      background: customTheme.oyster,
    },
    containedSecondary: {
      background: customTheme.stone,
      color: customTheme.pine,
    },
  })
);

export default useStyles;
