import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const ModalComponentStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  body: {
    backgroundColor: customColor.cotton,
    boxShadow: "0px 8px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 20,
    padding: "50px 40px",
    width: "90vw",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
}));

export default ModalComponentStyles;
