import moment from "moment";
import "moment/locale/es.js";
moment.locale("es");

export const formattedLocalDate = (UTCTimeStamp: string | undefined) => {
  const formattedDate = moment(UTCTimeStamp).format("D [de] MMMM [de] YYYY");
  return formattedDate;
};

export const formattedLocalDateWithDay = (UTCTimeStamp: string | undefined) => {
  const date = moment.utc(UTCTimeStamp).format("YYYY-MM-DD HH:mm:ss");
  const localTime = moment.utc(date).toDate();
  const formattedDate = moment(localTime)
    .locale("es")
    .format("D [de] MMMM [de] YYYY HH:mm:ss");
  return formattedDate;
};

export const getDay = (UTCTimeStamp: string | undefined) => {
  const formattedDate = moment(UTCTimeStamp).locale("es").format("dddd");
  return formattedDate;
};

export const getDate = (UTCTimeStamp: string | undefined) => {
  const formattedDate = moment(UTCTimeStamp).locale("es").format("DD");
  return formattedDate;
};

export const formatPaymentDates = (UTCTimeStamp: string | undefined) =>
  moment(UTCTimeStamp).locale("es").format("LL");

export const addRealMonth = (d: string | undefined, month: number) => {
  const date = moment(d);
  const fm = moment(date).add(month, "M");
  const fmEnd = moment(fm).endOf("month");
  return date.date() !== fm.date() && fm.isSame(fmEnd.format("YYYY-MM-DD"))
    ? fm.add(1, "d")
    : fm;
};

export const getFullDate = (UTCTimeStamp: string | undefined) => {
  const formattedDate = moment(UTCTimeStamp).format("YYYY-MM-DD");
  return formattedDate;
};

export const convertDateTimeToLocal = (date, time) => {
  return `${date}; ${time}`;
  // return formattedLocalDateWithDay(`${date} ${time}: 00`);
};
