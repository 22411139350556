import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import DropdownMenu from "../../../../../../components/sharedComponents/DropdownMenu";
import InputText from "../../../../../../components/sharedComponents/InputText";
import { useAppDispatch } from "../../../../../../store";
import useStore from "../../../../../../store/getStore";
import {
  getBranchForClinic,
  getClinicList,
  resetBranchList,
} from "../../../../../../store/slices/onboarding";
import {
  branchListOption,
  fillDoctorValues,
  fillDoctorValuesForBranch,
  isBranchSelectionAllowed,
  isOtherShowClinic,
} from "../../../../../common/ProcedureForm/helpers";

import "../../../../../../../src/input.css";
import useStyles from "../styles";

const RenderClinicDetails = ({ formik, responseData, disabled }) => {
  const css = useStyles();
  const { onboarding } = useStore();
  const dispatch = useAppDispatch();
  const isDr = Boolean(responseData?.doc_email || responseData?.doc_phone);
  const [isFirstDoctorDetails, setIsFirstDoctorDetails] = useState(isDr);

  const { clinicList, branchList, doctorList } = onboarding;

  const { values, touched, errors, handleChange, handleBlur } = formik;

  const clinicListOption = clinicList?.map((item) => item.clinicName) || [];

  const isOtherClinic = isOtherShowClinic({
    values,
  });

  const isBranchSelect = isBranchSelectionAllowed({ values });
  const branchListOpt = branchListOption(branchList);

  useEffect(() => {
    dispatch(getClinicList());
  }, []);

  // get Doctor's list based on clinic selection
  useEffect(() => {
    if (values?.clinicName) {
      if (!isFirstDoctorDetails) {
        fillDoctorValues({
          values,
          clinicList,
          errors,
          responseData,
          doctorList,
        });
      } else {
        setIsFirstDoctorDetails(false);
      }

      if (isBranchSelect) {
        dispatch(getBranchForClinic(values?.clinicName));
      } else {
        values.clinicBranch = responseData?.clinic_info?.clinic_location || "";
        dispatch(resetBranchList());
      }
    }
  }, [values?.clinicName]);

  // get doctor details based on branch selection
  useEffect(() => {
    if (values?.clinicBranch) {
      fillDoctorValuesForBranch({
        values,
        branchList,
        errors,
      });
    }
  }, [values?.clinicBranch]);

  const renderInput = ({ label, value, disabled = false }) => {
    return (
      <InputText
        stylesInput={css.formControl}
        label={label}
        value={values[value]}
        name={value}
        handleChange={handleChange}
        errors={errors[value]}
        touched={touched[value]}
        handleBlur={handleBlur}
        disabled={disabled}
      />
    );
  };

  const renderDropDown = ({ label, value, dataObj, disabled = false }) => {
    return (
      <DropdownMenu
        label={label}
        optionsObject={dataObj}
        name={value}
        handleChange={handleChange}
        value={values[value]}
        onBlur={handleBlur}
        errors={errors[value]}
        touched={touched[value]}
        disabled={disabled}
      />
    );
  };
  return (
    <>
      <Grid item xs={12} md={6}>
        <div className="dropdownFix">
          <DropdownMenu
            label={"Nombre de la clínica"}
            optionsObject={clinicListOption || []}
            name={"clinicName"}
            handleChange={async (e) => {
              const value = e.target.value;
              await handleChange(e);
              if (value === "Otro") {
                formik.setFieldValue("medicName", "");
                formik.setFieldValue("medicLastName", "");
                formik.setFieldValue("clinicDr", "");
                formik.setFieldValue("medicEmail", "");
              }
            }}
            value={values.clinicName}
            onBlur={handleBlur}
            errors={errors.clinicName}
            touched={touched.clinicName}
            disabled={disabled}
          />
        </div>
      </Grid>
      {/* {isOtherClinic && (
        <Grid xs={12} md={12}>
          {renderInput({
            label: "Otro nombre de la clínica",
            value: "otherClinicName",
          })}
        </Grid>
      )} */}
      <Grid item xs={12} md={6}>
        {isBranchSelect && branchListOpt?.length > 0
          ? renderDropDown({
              label: "Sucursal de la clínica",
              value: "clinicBranch",
              dataObj: branchListOpt || [],
              disabled: disabled,
            })
          : renderInput({
              label: "Sucursal de la clínica",
              value: "clinicBranch",
              disabled: disabled,
            })}
      </Grid>
    </>
  );
};

export default RenderClinicDetails;
