import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import PayBook from "../payBook";
import useStore from "../../store/getStore";
import styles from "./styles";
import { updateOnboardingSteps } from "../../store/slices/auth";
import { ROUTES } from "../../constants/routes";
import { useAppDispatch } from "../../store";

function CreditHistory() {
  const classes = styles();
  const { auth } = useStore();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const step = 7; // bank step

  const handleSkip = async () => {
    await dispatch(
      updateOnboardingSteps({
        data: {
          step,
          status: "Skipped",
        },
        latest_step: step,
      })
    ).unwrap();
    history.push(ROUTES.identityInformation);
  };

  return (
    <div className={classes.pageContainer}>
      <Box className={classes.headerPageBackground}></Box>
      <Box
        className={classes.formContainer}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <img src={Logo} alt="logo" className={classes.logo} />
        <Typography variant="h2" className={classes.textTitle}>
          Historial Crediticio
        </Typography>
      </Box>
      <Box textAlign="center">
        <ButtonComponent text="Skip" type="button" onClick={handleSkip} />
      </Box>
      <PayBook userId={auth.userId} />
    </div>
  );
}

export default CreditHistory;
