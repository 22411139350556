import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import confirmationBg from "../../assets/backgroundImages/confirmation.svg";
import { customColor } from "../../theme/customColor";
import { URBANIST_FONT } from "../../constants/common";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100wh",
    background: customColor.maastrichtBlue,
    padding: "0 25%",
    [theme.breakpoints.down("md")]: {
      padding: "5% 5% 0",
    },
  },
  heading: {
    fontFamily: URBANIST_FONT.BOLD,
    fontSize: 38,
    color: customColor.cotton,
    width: "100%",
    textAlign: "center",
  },
  content: {
    fontFamily: URBANIST_FONT.REGULAR,
    fontSize: 20,
    color: customColor.cotton,
    textAlign: "center",
  },
  btn: {
    backgroundColor: customColor.lightGreen,
    color: customColor.cotton,
    borderRadius: 4,
    padding: "3px 24px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    minWidth: 80,
    minHeight: 36,
    border: "none",
    fontFamily: "SFUIText-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 16,
    cursor: "pointer",
    marginTop: 32,
  },
}));

export default useStyles;
