import { FC, ReactNode, MouseEvent } from "react";
import { Box } from "@mui/material";

import useStyles from "./styles";

export interface ButtonProps {
  label?: string | ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

export interface ConfirmationViewProps {
  title?: string | ReactNode;
  content?: string | ReactNode;
  imgSrc?: string;
  btn?: ButtonProps;
  footer?: string | ReactNode;
}
const ConfirmationView: FC<ConfirmationViewProps> = ({
  title = null,
  content = null,
  imgSrc,
  btn,
  footer = null,
}) => {
  const css = useStyles();

  return (
    <Box className={css.container}>
      {imgSrc && <img src={imgSrc} alt="imgSrc" />}
      {title && (
        <Box className={css.heading} mt={1}>
          {title}
        </Box>
      )}
      {content && (
        <Box mt={2} className={css.content}>
          {content}
        </Box>
      )}
      {btn?.label && (
        <button className={css.btn} onClick={btn?.onClick}>
          {btn?.label}
        </button>
      )}
      {footer && <>{footer}</>}
    </Box>
  );
};

export default ConfirmationView;
