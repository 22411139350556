import { Box } from "@mui/material";
import { useFormik } from "formik";
import { memo, useEffect, useState } from "react";

import SplashValidation from "../../../components/sharedComponents/SplashValidation";
import { API_STATUS } from "../../../constants/common";
import { dataProcedurePage } from "../../../constants/gtm";
import { INSTALLMENT_LOAN_ROUTES, ROUTES } from "../../../constants/routes";
import {
  getCreditMinMax,
  getProcedureInfoDetails,
} from "../../../features/installmentLoan/store/installmentLoan";
import { useAppDispatch } from "../../../store";
import useStore from "../../../store/getStore";
import {
  getClinicList,
  getDoctorList,
  getSpecialtyType,
} from "../../../store/slices/onboarding";
import { saveUserProcedureInformation } from "../../../store/slices/users";
import { GTMClick } from "../../../utils/gtm";
import { errorToast } from "../../../utils/toast";
import { getMaxMinAmount } from "../../common/ProcedureForm/helpers";
import { getPayload, initialValues, validateForm } from "../helpers";
import FormContainer from "./FormContainer";
import Header from "./Header";
import styles from "./styles";

const ProcessInformation = () => {
  const css = styles();

  const [loader, setLoader] = useState(false);
  const dispatch = useAppDispatch();
  const [wasInitialized, setWasInitialized] = useState(false);
  const { onboarding, installmentLoan } = useStore();

  const { productDetails } = installmentLoan;
  const { procedureToPerformList, clinicList, hasNotDate, hasNotDoctor } =
    onboarding;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitData = async (payload) => {
    const res = await dispatch(saveUserProcedureInformation(payload)).unwrap();
    if (res?.status === API_STATUS.SUCCESS) {
      setLoader(true);
    } else if (res?.status === API_STATUS.ERROR) {
      errorToast(res?.meta?.errorMessage);
    }
  };

  const getData = async () => {
    const { data, status } = await dispatch(getProcedureInfoDetails()).unwrap();
    if (status === "success") {
      formik.setFieldValue("procedureCost", data?.cost);
      formik.setFieldValue("requestedAmount", data?.requested_loan_amount);
    }
  };

  useEffect(() => {
    dispatch(getCreditMinMax());
    dispatch(getSpecialtyType());
    dispatch(getClinicList());
    dispatch(getDoctorList());
    getData();
  }, []);

  const validate = () => {
    if (!wasInitialized) {
      setWasInitialized(true);
    }
    return validateForm({
      values,
      hasNotDoctor,
      errors: {},
      hasNotDate,
      maxCreditAmt,
      procedureToPerformList,
      minCreditAmount,
    });
  };

  const formik = useFormik({
    initialValues,
    // validate,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      GTMClick(dataProcedurePage.continue);
      const payload = getPayload({
        hasNotDoctor,
        values,
        hasNotDate,
        procedureToPerformList,
      });
      await submitData(payload);
      setIsSubmitting(false);
    },
  });

  const { values, handleSubmit } = formik;

  const { maxCreditAmt, minCreditAmount } = getMaxMinAmount({
    productDetails,
    values,
    clinicList,
  });
  const errorData = validate();
  formik.errors = errorData;
  let isValidForm =
    Object.entries(formik.errors).length === 0 && wasInitialized;

  if (loader) {
    return (
      <SplashValidation
        image={1}
        // trueRedirect={false}
        redirectTo={INSTALLMENT_LOAN_ROUTES.MAIN}
      />
    );
  } else {
    return (
      <Box className={css.backgroundContainer}>
        <Box className={css.headerContainer}>
          <Header />
        </Box>
        <div
          style={{
            height: 9,
            background: "linear-gradient(270deg, #1B63DB 19.79%, #0ACC97 100%)",
          }}
        />
        <Box my={12} paddingX={4}>
          <form onSubmit={handleSubmit}>
            <FormContainer
              {...formik}
              isValidForm={isValidForm}
              maxCreditAmt={maxCreditAmt}
              minCreditAmount={minCreditAmount}
              isSubmitting={isSubmitting}
            />
          </form>
        </Box>
      </Box>
    );
  }
};

export default memo(ProcessInformation);
