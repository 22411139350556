import { makeStyles } from "@mui/styles";
import customColor from "../../../theme/customColor";

const useStyles = makeStyles(() => {
  return {
    tableCellStyle: {
      "&.MuiTableCell-root": {
        backgroundColor: "transparent",
        padding: "10px 16px",
        height: 54,
        fontSize: 18,
        textAlign: 'left',
        '& b': {
          color: customColor.cotton,
          fontSize: 19,
          fontFamily: "SFUIText-Bold",
        }
      },
      "&.MuiTableCell-head": {
        height: 60
      }
    },
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: customColor.cotton,
      },
      "&:nth-of-type(even)": {
        backgroundColor: customColor.stone,
      },
    },
    headerRow: {
      backgroundColor: customColor.accentBlue,
    },
    label: {
      fontFamily: "SFUIText-Bold",
    },
    leftBorderTop: {
      borderTopLeftRadius: 10,
    },
    rightBorderTop: {
      borderTopRightRadius: 10,
    },
    leftBorderBottom: {
      borderBottomLeftRadius: 10,
    },
    rightBorderBottom: {
      borderBottomRightRadius: 10,
    },
  };
});

export default useStyles;
