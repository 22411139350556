import { Box } from "@mui/material";
import React from "react";

const Header = (props) => {
  const { children, className } = props;
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={className}
    >
      {children}
    </Box>
  );
};

export default Header;
