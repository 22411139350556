import { Typography, Box } from "@mui/material";
import MailIcon from "../../../components/icons/Mail";
import LocationIcon from "../../../components/icons/Location";
import Header from "../../Home/Header";
import Footer from "../../Home/Footer";
import styles from "./styles";

const Contact = () => {
  const classes = styles();

  return (
    <>
      <Header isBgBlue={false} />
      <Box className={classes.textWrapper}>
        <Typography
          variant="h6"
          lineHeight={1.25}
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          <strong>Contacta a Medsi</strong>
        </Typography>
        <Box className={classes.line} mt={2} />
        <Typography
          variant="h6"
          lineHeight={1.25}
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Puedes contactarnos de las siguiente maneras:
        </Typography>
        <Box className={classes.contactIconMainContainer} mt={2}>
          <Box className={classes.contactItemContainer}>
            <Box className={classes.contactIconContainer}>
              <MailIcon width={28} height={28} />
            </Box>
            <Typography
              variant="subtitle1"
              className={`${classes.text12} ${classes.textJustify}`}
              // style={{ lineHeight: 1.70; }}
            >
              <a
                href="mailto:contacto@medsi.mx"
                style={{ textDecoration: "none" }}
              >
                contacto@medsi.mx
              </a>
            </Typography>
          </Box>
          <Box
            className={classes.contactItemContainer}
            // style={{ borderTop: "1px solid #dedede", alignItems: "flex-start" }}
          >
            <Box className={classes.contactIconContainer}>
              <LocationIcon width={28} height={28} />
            </Box>
            <Typography
              variant="subtitle1"
              className={`${classes.text12} ${classes.textJustify}`}
              // lineHeight={1.7}
            >
              Medsi, Emerson 316, Oficina 201, Piso 2,
              <strong>
                 {' '}Colonia Polanco V Sección, Alcaldía Miguel Hidalgo, Ciudad de
                México, México
              </strong>
              <br />
              C.P. 11570
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Contact;
