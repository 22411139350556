import { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";

import DropdownMenu from "../../components/sharedComponents/DropdownMenu";
import GradientButton from "../../components/GradientButton";
import InputText from "../../components/sharedComponents/InputText";

import {
  initialValues,
  validateForm,
  paymentMethodsType,
  bankList,
  getPayload,
  getNumberLabel,
  getNumberPlaceHolder,
  getMaxLength,
  // validationSchema,
} from "./helper";
import useStyles from "./styles";
import Details from "./Details";
import DoctorDetails from "./Details/doctor";
import CreditDetails from "./Details/credit";
import { URBANIST_FONT } from "../../constants/common";
import customColor from "../../theme/customColor";
import InputPhone from "../../components/sharedComponents/InputPhone";
import { rfcValidator } from "../../utils";
import TitleComponent from "../../components/sharedComponents/TitleComponent";

const Form = ({ saveData, doctorDetails }) => {
  const css = useStyles();
  const [wasInitialized, setWasInitialized] = useState(false);

  const validate = (values) => {
    let errors = {};
    !wasInitialized && setWasInitialized(true);

    // Validate bank method type
    !values.paymentMethodsType
      ? (errors.paymentMethodsType = "Selecciona el método de pago")
      : delete errors.paymentMethodsType;

    // Validate bank
    !values.bankName
      ? (errors.bankName = "Selecciona el Banco")
      : delete errors.bankName;

    // Validate business name
    !values.businessName
      ? (errors.businessName = "Este campo es requerido")
      : delete errors.businessName;

    // Validate id
    !values.id ? (errors.id = "Este campo es requerido") : delete errors.id;

    // Validate fullName
    // !values.fullName
    //   ? (errors.fullName = "Este campo es requerido")
    //   : delete errors.fullName;

    if (!values.cardNumber) {
      errors.cardNumber = "Este campo es requerido";
    } else if (
      (values.paymentMethodsType === paymentMethodsType[0] &&
        values.cardNumber.length !== 18) ||
      (values.paymentMethodsType === paymentMethodsType[1] &&
        values.cardNumber.length !== 16)
    ) {
      errors.cardNumber = "Introduce un número de tarjeta válido";
    } else {
      delete errors.cardNumber;
    }

    //validate rfc
    if (rfcValidator(values.rfc) || !values.rfc) {
      // setValidateRfc(true);
      delete errors.rfc;
    } else {
      // setValidateRfc(false);
      errors.rfc = "El RFC no es válido";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    validate,
    onSubmit: async (values) => {
      const payload = getPayload({
        values,
        doctorDetails,
      });
      saveData(payload);
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

  useEffect(() => {
    const paymentMethod = paymentMethodsType.filter(
      (paymentType) =>
        paymentType?.split(" (")[0] === doctorDetails?.payment_method
    )[0];

    const updatedValues = {
      email: doctorDetails?.doc_email || "",
      phoneNumber: doctorDetails?.doc_phone || "",
      paymentMethodsType: paymentMethod || "",
      bankName: doctorDetails?.bank_name || "",
      cardNumber: doctorDetails?.number || "",
      businessName: doctorDetails?.business_name || "",
      fullName:
        doctorDetails?.full_name_physician ||
        `${doctorDetails?.doc_name} ${doctorDetails?.doc_last_name}` ||
        "",
      id: doctorDetails?.professional_id || "",
      rfc: doctorDetails?.rfc || "",
    };
    setValues({ ...values, ...updatedValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorDetails]);

  const isButtonEnable = wasInitialized && Object.entries(errors).length === 0;

  const renderDropDown = ({
    label,
    value,
    dataObj,
    disabled = false,
    placeholder,
  }) => {
    return (
      <DropdownMenu
        label={label}
        optionsObject={dataObj}
        name={value}
        handleChange={handleChange}
        value={values[value] || "none"}
        onBlur={handleBlur}
        errors={errors[value]}
        touched={touched[value]}
        disabled={disabled}
        stylesInput={css.select}
        placeholder={placeholder}
        placeHolderStyle={css.selectPlaceHolderColor}
      />
    );
  };

  const renderInput = ({
    label,
    value,
    disabled = false,
    setField,
    placeholder,
  }) => {
    return (
      <InputText
        stylesInput={css.formControl}
        label={label}
        value={values[value]}
        name={value}
        handleChange={setField ? (ev) => setField(ev) : handleChange}
        errors={errors[value]}
        touched={touched[value]}
        handleBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
    );
  };

  return (
    <Box className={css.container}>
      <Box className={css.title}>
        Muchas gracias por confirmar el procedimiento de su paciente.
      </Box>
      <Box className={css.desc}>
        A continuación, indique la cuenta a la que debemos enviar el pago a
        través del SPEI.
      </Box>
      <Grid
        className={css.details}
        container
        justifyContent="center"
        columnSpacing={2}
        rowSpacing={2}
        maxWidth="1270px"
        sx={(theme) => ({
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1270px",
          },
          [theme.breakpoints.up("md")]: {
            maxWidth: "760px",
          },
          [theme.breakpoints.up("sm")]: {
            maxWidth: "670px",
          },
        })}
      >
        <Grid item xs={12} lg={6}>
          <Details doctorDetails={doctorDetails} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DoctorDetails doctorDetails={doctorDetails} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CreditDetails doctorDetails={doctorDetails} />
        </Grid>
      </Grid>
      <Typography
        maxWidth={"620px"}
        marginTop={6}
        fontFamily={URBANIST_FONT.REGULAR}
        fontSize="1rem"
        color={customColor.cotton}
        textAlign="center"
      >
        *Estos montos están sujetos a cambios si el paciente actualiza su
        procedimiento y los detalles de la solicitud de préstamo durante el
        proceso de revisión.
      </Typography>
      <form onSubmit={handleSubmit} className={css.form}>
        <Grid
          className={css.formContainer}
          container
          rowGap={4}
          columnSpacing={12}
        >
          <Grid item xs={12} md={6}>
            {renderDropDown({
              label: "Indica el método de pago",
              value: "paymentMethodsType",
              dataObj: paymentMethodsType || [],
              placeholder: "Seleccione una opción",
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderDropDown({
              label: "Banco",
              value: "bankName",
              dataObj: bankList || [],
              placeholder: "Seleccione una opción",
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderInput({
              label: getNumberLabel({
                values,
              }),
              value: "cardNumber",
              placeholder: getNumberPlaceHolder({ values }),
              setField: ({ target: { value = "" } }) => {
                const re = /^[0-9]\d*(\d+)?$/i;
                if (
                  value === "" ||
                  (re.test(value) && value.length <= getMaxLength({ values }))
                ) {
                  formik.setFieldValue("cardNumber", value);
                }
              },
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderInput({
              label: "Nombre o Razón Social de la Cuenta",
              value: "businessName",
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderInput({
              label: "Nombre completo del médico tratante",
              value: "fullName",
              disabled: true,
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            {renderInput({
              label: "Cédula profesional del Médico",
              value: "id",
            })}
          </Grid>
          <Grid item xs={12} md={12}>
            <Box width={{ md: "calc(50% - 50px)" }}>
              {renderInput({
                label: "RFC (opcional)",
                value: "rfc",
              })}
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            {renderInput({
              label: "Correo electrónico del Médico",
              value: "email",
            })}
          </Grid>
          <Grid item xs={12} md={6}>
            <TitleComponent
              text={"Número de teléfono"}
              titleStyle={{
                color: customColor.cotton,
                textAlign: "left",
                fontFamily: URBANIST_FONT.BOLD,
              }}
            />
            <div className="telInput bgDark" style={{ width: "100%" }}>
              <InputPhone
                country="mx"
                name="phoneNumber"
                id="phoneNumber"
                stylesInput={css.phoneInput}
                handleChange={(e, ...props) => {
                  // setCountryCodeLength(e.slice(props[0].dialCode.length));
                  // setFieldValue("lengthPhone", countryCodeLength);
                  setFieldValue("phoneNumber", e);
                }}
                value={values.phoneNumber}
                errors={errors.phoneNumber}
                touched={touched.phoneNumber}
                handleBlur={handleBlur}
              />
            </div>
            <div className={css.phoneNumberDisclaimer}>
              Te enviaremos un mensaje en Whatsapp para validar tu cuenta
            </div>
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="center" mt={5} mb={5}>
          <GradientButton
            title="Confirmar"
            handleClick={handleSubmit}
            type="submit"
            disabled={!isButtonEnable}
            customCss={css.submitButtonStyle}
          />
        </Box>
        <Box display={"flex"} justifyContent={"center"}>
          <Typography
            maxWidth="780px"
            marginBottom={6.5}
            marginTop={1.5}
            fontFamily={URBANIST_FONT.REGULAR}
            fontSize="1.1rem"
            color={customColor.cotton}
            textAlign="center"
          >
            El uso de los datos en esta plataforma se realizan con fines de
            comunicación, si alguno de tus datos son incorrectos por favor
            envíanos un correo a contacto@medsi.mx
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default Form;
