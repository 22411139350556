import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "max-content",
    width: "100%",
    marginTop: theme.spacing(10),
    paddingBottom: theme.spacing(14),
    [theme.breakpoints.down(601)]: {
      marginTop: theme.spacing(6),
      paddingBottom: theme.spacing(4),
    },
  },
  title: {
    fontSize: 32,
    // color: customColor.accentBlue,
    color: customColor.black,
    fontFamily: "Nexa-Bold",
    fontWeight: "700",
    [theme.breakpoints.down(601)]: {
      fontSize: 22,
      padding: `0 ${theme.spacing(3)}`,
    },
  },
  link: {
    // color: customColor.accentBlue,
    color: customColor.black,
    fontSize: 18,
    fontFamily: "SFUIText-Regular",
    fontWeight: "700",
    textDecoration: "none",
  },
  content: {
    marginLeft: theme.spacing(5),
    paddingRight: theme.spacing(14),
    [theme.breakpoints.down(601)]: {
      paddingRight: theme.spacing(4),
      marginLeft: theme.spacing(4),
      marginTop: theme.spacing(4),
    },
  },
  question: {
    height: "284",
    width: "206",
    [theme.breakpoints.down(601)]: {
      height: "100%",
      width: "100%",
    },
  },
  circle: {
    width: 15,
    height: 15,
    background: " linear-gradient(77.99deg, #0ACC97 11.28%, #196FD4 127.02%)",
    borderRadius: 100,
    marginRight: 16,
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default useStyles;
