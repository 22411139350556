const data = {
    medics: [
      "ARROYO FONSECA, Paola Arline",
      "GONGORA SADA, Marisol",
      "PEREZ CHAVEZ, Francisco",
    ],
    medicRelation: ["Cita de Primera Vez", "Paciente recurrente"],
    procType: [
      "No quirúrgico / En consultorio",
      "Quirúrgico / En consultorio",
      "Quirúrgico / Quirófano",
    ],
    "No quirúrgico / En consultorio": [
      "Depilación",
      "Fillers Dermales (Ácido Hyalurónico / Hidroxiapatita de Calcio)",
      "Laser / Fotorejuvenecedor",
      "Peeling Químico",
      "Radiofrecuencia",
      "Toxina Botulínica",
      "Otro",
    ],
    "Quirúrgico / En consultorio": [
      "Bichectomía",
      "Blefaroplastía",
      "Cervicoplastía",
      "Contorno Facial",
      "Frontoplastía",
      "Otoplastía",
      "Ritidoplastía",
      "Otro",
    ],
    "Quirúrgico / Quirófano": [
      "Abdominoplastía",
      "Aumento de Gluteos",
      "Braquioplastía",
      "Levantamiento de Senos",
      "Liposucción/Lipoescultura",
      "Mamoplastía de Aumento",
      "Mamoplastía de Reducción",
      "Reducción de Muslos",
      "Revitalización Cutánea",
      "Otro",
    ],
  };

  export default data