import { FC, MouseEvent } from "react";

import confirmationBg from "../../assets/Icons/success-email.svg";
import ConfirmationView from "../../components/ConfirmationView";

export interface AlreadyVerifiedProps {
  handleClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const AlreadyVerified: FC<AlreadyVerifiedProps> = ({ handleClick }) => {
  return (
    <ConfirmationView
      title="Exito de Verificación"
      content={
        <span>
          Has verificado con éxito tu correo electronico.
          <br />
          Ahora puedes cerrar esta pantalla y continuar con el proceso de
          solicitud de crédito.
        </span>
      }
      imgSrc={confirmationBg}
      btn={{
        label: "Cerrar",
        onClick: handleClick,
      }}
    />
  );
};

export default AlreadyVerified;
