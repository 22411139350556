import { makeStyles } from "@mui/styles";
import { customColor } from "../../theme/customColor";


const styles = makeStyles((theme) => ({
  divContainer: {
    backgroundColor: theme.palette.primary.white,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: 100,
    position: 'relative'
  },
  textWrapper: {
    padding: 20,
    overflowY: "auto",
    flex: 1,
    height: '100%',
    paddingBottom: 80,
  },
  text12: {
    [theme.breakpoints.down(601)]: {
      fontSize: "12px !important",
    },
  },
  mt24: {
    marginTop: "24px !important",
  },
  textCenter: {
    textAlign: "center !important",
  },
  textJustify: {
    textAlign: "justify !important",
  },
  olStyles: {
    listStyleType: "lower-alpha",
    marginLeft: "0in",
  },
  table: {
    maxWidth: "500pt",
    borderCollapse: "collapse",
    border: "none",
    marginTop: "16px",
  },
  tdHead: {
    border: "1pt solid windowtext",
    padding: "0.75pt",
    verticalAlign: "top",
  },
  tdBody: {
    borderRight: "1pt solid windowtext",
    borderBottom: "1pt solid windowtext",
    borderLeft: "1pt solid windowtext",
    borderImage: "initial",
    borderTop: "none",
    padding: "0.75pt",
    verticalAlign: "top",
  },
  buttonWrapper: {
    position: "absolute",
    fontWeight: 600,
    letterSpacing: 0.4,
    color: customColor.accentBlue,
    padding: "12px 24px",
    width: "100%",
    textAlign: "center",
    background:
      "linear-gradient(to top, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    height: 60,
    bottom: 0,
  },
  hide: {
    visibility: 'hidden',
  },
  acceptar: { cursor: 'pointer' },
}));

export default styles;
