import { Theme } from "@mui/material/styles";
import { makeStyles, createStyles } from "@mui/styles";
import { customTheme } from "../../features/installmentLoan/theme/index";

export interface IUseStyles {
  // variant: IOysterTheme;
  opacity: number;
  fontSize: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backBar: {
      width: "100%",
      display: "flex",
      alignSelf: "flex-start",
      flexDirection: "row",
      alignItems: "center",
      zIndex: 100,
      "& p": {
        fontFamily: "SFUIText-Bold",
      },
    },
    btn: (props: IUseStyles) => ({
      cursor: "pointer",
      fontSize: props.fontSize,
      lineHeight: "1em",
      fontWeight: 700,
      color: customTheme.accentBlue,
      opacity: props.opacity,
      margin: 0,
      padding: 0,
      backgroundColor: "transparent",
      fontFamily: "Montserrat",
      border: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "& p": {
        margin: 0,
        padding: 0,
      },
      "& button": {
        fontSize: "1em",
        lineHeight: "1.1428571428571428em",
        marginLeft: -(props.fontSize - 2),
        marginRight: "0.2em",
      },
      "& svg": {
        color: customTheme.accentBlue,
        fontSize: "1.1428571428571428em",
      },
    }),
    rightComponent: {
      flexGrow: 1,
      textAlign: "right",
    },
  })
);

export default useStyles;
