import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import { rfcValidator, curpValidator } from "../../utils";
import { selectUserId, saveUserRfcAndCurp } from "../../store/slices/users";
import { ROUTES } from "../../constants/routes";
import {
  selectId,
  selectIsCosigner,
  curpRegistration,
  selectCoSignerToken,
  checkReferenceCode,
  selectCurrentCoSignerPhoneNumber,
  selectCurrentCoSignerDetails,
  selectCurrentCoSignerUserDetails,
} from "../../store/slices/cosigner";
import { GTMClick } from "../../utils/gtm";
import { generalDataPage, coSignerGeneralDataPage } from "../../constants/gtm";
import { API_STATUS } from "../../constants/common";
import { errorToast, successToast } from "../../utils/toast";

import GeneralDataStyles from "./GeneralData.styles";
import TextInput from "../../features/installmentLoan/components/TextInput";
import { verifyPassword } from "../../store/slices/auth/actions";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import AcceptTermCondtion from "../../components/ModalContents/AcceptTermCondition";
import InputPhone from "../../components/sharedComponents/InputPhone";
import CosignerOTP from "../../components/ModalContents/CosignerOTP";
import { updateCosignerPhonenumber } from "../../store/slices/cosigner/actions";

const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /^[0-9]+$/;

const GeneralData = () => {
  const classes = GeneralDataStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const coSignerToken = useSelector(selectCoSignerToken);
  const isUserCosigner = selectIsCosigner();
  const id_client = useSelector(selectUserId);
  const id_cosigner = useSelector(selectId);
  const cosignerPhoneNumber = useSelector(selectCurrentCoSignerPhoneNumber);
  const cosignerDetails = useSelector(selectCurrentCoSignerDetails);
  const userDetails = useSelector(selectCurrentCoSignerUserDetails);

  const [curp, setCurp] = useState("");
  const [validateCurp, setValidateCurp] = useState(false);
  const [isValidPassword, setValidPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [bureauValidate, setBureauValidate] = useState(false);

  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [openCosignerOtp, setOpenCosignerOtp] = useState(false);

  const [isTncApproved, setIsTncApproved] = useState(false);

  const [rfc, setRfc] = useState("");
  const [validateRfc, setValidateRfc] = useState(false);

  const [checkRfc, setCheckRfc] = useState(false);

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [maternal_name, setMaternalName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setPhoneNumberValue(cosignerPhoneNumber);
  }, [cosignerPhoneNumber]);

  useEffect(() => {
    setEmail(cosignerDetails?.email || "");
    setFirstName(cosignerDetails.first_name || "");
    setLastName(cosignerDetails?.last_name || "");
    setMaternalName(cosignerDetails?.maternal_name || "");
  }, [cosignerDetails]);

  const handleClick = async () => {
    let payload = {
      id: isUserCosigner ? id_cosigner : id_client,
      data: {
        rfcAndCurp: {
          rfc: rfc ? rfc : "",
          curp,
          createOn: new Date(),
        },
      },
    };
    //Co signer dispatch and post
    if (isUserCosigner) {
      GTMClick(coSignerGeneralDataPage.continue);
      const agr = { token: coSignerToken, rfcAndCurp: payload.data.rfcAndCurp };

      await dispatch(
        updateCosignerPhonenumber({
          phone_number: phoneNumberValue,
          first_name,
          last_name,
          maternal_name,
          email,
        })
      );

      const res = await dispatch(curpRegistration(agr)).unwrap();
      if (res?.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.personalInformation);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
    } else {
      GTMClick(generalDataPage.continue);
      const res = await dispatch(
        saveUserRfcAndCurp(payload.data.rfcAndCurp)
      ).unwrap();
      if (res?.status === API_STATUS.SUCCESS) {
        history.push(ROUTES.personalInformation);
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
    }
  };

  const handleCurp = (event) => {
    let value = event.target.value.toUpperCase();
    curpValidator(value) ? setValidateCurp(true) : setValidateCurp(false);
    setCurp(value);
  };

  const handleRfc = (event) => {
    let value = event.target.value.toUpperCase();

    rfcValidator(value) ? setValidateRfc(true) : setValidateRfc(false);

    setRfc(value);
  };

  const handleCheck = (event) => {
    let value = event.target.checked;

    if (value) {
      setRfc("");
      setValidateRfc(true);
    } else {
      setValidateRfc(false);
    }
    setCheckRfc(value);
  };

  const checkPassword = async (text) => {
    setLoading(true);
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      verifyPassword({
        password: text,
      })
    ).unwrap();
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Password verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const checkReferenceCodeData = async (code) => {
    setLoading(true);
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      checkReferenceCode({
        code,
        isBureauPull: true,
      })
    ).unwrap();
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Number verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (isUserCosigner) {
        checkReferenceCodeData(referenceNumber);
      }
    } else {
      // checkPassword(confirmationPassword);
    }
  };

  const handleBureauCheckBox = (e) => {
    setIsTncApproved(e.target.checked);
    if (e.target.checked) {
      setOpen(true);
    } else {
      setBureauValidate(false);
      setValidPassword(false);
      setOpen(false);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setOpenCosignerOtp(false);
    setBureauValidate(true);
    setValidPassword(false);
  };

  const onEmailErrors = () => {
    if (!email) {
      return "El correo es requerido";
    }
    if (!EMAIL_REGEX.test(email)) {
      return "Ingresa un correo válido";
    }

    if (userDetails?.email === email) {
      return "El corresponsable de tu crédito debe ser una persona distinta al solicitante. Has ingresado la misma dirección de correo electrónico para ambos; por favor ingresa una dirección de email válida.";
    }
    return;
  };

  const onPhoneErrors = () => {
    if (!phoneNumberValue) {
      return "Este campo es requerido";
    }
    if (!PHONE_REGEX.test(phoneNumberValue)) {
      return "Must be only digits";
    }
    if (phoneNumberValue.length < 10) {
      return "Debe tener exactamente 10 dígitos";
    }
    if ("+" + phoneNumberValue === userDetails?.phone_number) {
      return "El corresponsable de tu crédito debe ser una persona distinta al solicitante. Has ingresado el mismo número de teléfono para ambos; por favor ingresa número telefónico válido.";
    }
    return;
  };

  return (
    <div className={classes.content}>
      <Box className={classes.headerContainer}></Box>
      <Container component="main">
        <Grid
          justifyContent="center"
          alignItems="center"
          display="flex"
          flexDirection="column"
        >
          <img src={Logo} alt="logo" className={classes.logo} />
          <Typography variant="h2" className={classes.title}>
            Estamos listos para comenzar <br /> con tu registro
          </Typography>
          <Typography variant="textSubtitle" className={classes.generalData}>
            Datos generales
          </Typography>
          <Typography variant="h3" className={classes.enterCurp}>
            Ingresa tu CURP para registrarte
          </Typography>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
              columnGap: 103,
              rowGap: 36,
            }}
          >
            <div>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 12,
                  // textAlign: "center",
                }}
                className={classes.headerInput}
              >
                CURP
              </Typography>
              <TextField
                className={classes.input}
                id="curp"
                // label="CURP"
                value={curp.toUpperCase()}
                placeholder="Ingresa tu CURP"
                //size="small"
                error={curp.length === 18 && !validateCurp ? true : false}
                color={
                  curp.length === 18 && validateCurp ? "success" : "primary"
                }
                helperText={
                  curp.length === 18 && !validateCurp
                    ? "La CURP no es válida"
                    : ""
                }
                inputProps={{
                  maxLength: 18,
                  className: classes.inputProps,
                }}
                onChange={handleCurp}
              />
              <Typography className={classes.textCurpQuery}>
                Si no conoces tu CURP{" "}
                <a
                  href="https://www.gob.mx/curp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  consúltalo aquí
                </a>
              </Typography>
            </div>

            <div>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 12,
                }}
                className={classes.headerInput}
              >
                RFC
              </Typography>
              {!isUserCosigner && (
                <FormControlLabel
                  className={classes.checkValidate}
                  label="No tengo un RFC, u otra persona estará cubriendo el costo de mi procedimiento"
                  control={
                    <Checkbox
                      id="checkRfc"
                      size="small"
                      onChange={handleCheck}
                      checkedIcon={
                        <CheckCircleIcon className={classes.checkedIcon} />
                      }
                      icon={<CheckCircleOutlineIcon className={classes.icon} />}
                    />
                  }
                />
              )}

              <TextField
                className={
                  isUserCosigner
                    ? `${classes.input} ${classes.isCosigner}`
                    : classes.input
                }
                id="rfc"
                // label="RFC"
                value={rfc.toUpperCase()}
                placeholder="Ingresa tu RFC"
                disabled={checkRfc}
                error={rfc.length >= 12 && !validateRfc ? true : false}
                color={rfc.length >= 12 && validateRfc ? "success" : "primary"}
                helperText={
                  rfc.length >= 12 && !validateRfc ? "El RFC no es válido" : ""
                }
                inputProps={{
                  maxLength: 13,
                  className: classes.inputProps,
                }}
                onChange={handleRfc}
              />
            </div>

            <div>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 12,
                }}
                className={classes.headerInput}
              >
                Nombre(s)
              </Typography>
              <TextField
                className={classes.input}
                id="first_name"
                label=""
                value={first_name}
                placeholder=""
                onChange={(e) => setFirstName(e?.target?.value)}
              />
            </div>
            <div>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 12,
                }}
                className={classes.headerInput}
              >
                Apellido Paterno
              </Typography>
              <TextField
                className={classes.input}
                id="last_name"
                label=""
                value={last_name}
                placeholder=""
                onChange={(e) => setLastName(e?.target?.value)}
              />
            </div>
            <div>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 12,
                }}
                className={classes.headerInput}
              >
                Apellido Materno
              </Typography>
              <TextField
                className={classes.input}
                id="maternal_name"
                label=""
                value={maternal_name}
                placeholder=""
                onChange={(e) => setMaternalName(e?.target?.value)}
              />
            </div>
            <div className={`${classes.input} ${classes.phoneNumberContainer}`}>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: 12,
                }}
                className={classes.headerInput}
              >
                Número de celular (10 dígitos)
              </Typography>
              <div className={`telInput ${classes.phoneNumberInputBox}`}>
                <InputPhone
                  country="mx"
                  name="phoneNumber"
                  id="phoneNumber"
                  inputStyle={{ width: "376px" }}
                  handleChange={(e, ...props) => {
                    setPhoneNumberValue(e);
                    if (isTncApproved) {
                      setIsTncApproved(false);
                    }
                  }}
                  value={phoneNumberValue}
                  errors={onPhoneErrors()}
                />
              </div>
              <span className={classes.phoneNumberDisclaimer}>
                Te enviaremos un mensaje en Whatsapp para validar tu cuenta
              </span>
            </div>
            <div>
              <Typography
                variant="h5"
                style={{
                  fontSize: 18,
                  marginBottom: 4,
                  marginTop: -20,
                }}
                className={classes.headerInput}
              >
                Correo Electrónico
              </Typography>
              <TextField
                className={classes.input}
                id="email"
                label=""
                value={email}
                placeholder=""
                error={!!onEmailErrors()}
                helperText={onEmailErrors()}
                onChange={(e) => setEmail(e?.target?.value)}
              />
            </div>
          </div>

          <div className={classes.listItemContainer}>
            <ListItem className={classes.listItemBank}>
              <Checkbox
                edge="start"
                name="bank"
                onChange={handleBureauCheckBox}
                checked={isTncApproved}
                checkedIcon={<CheckCircleIcon color="primary" />}
                icon={<CheckCircleOutlineIcon className={classes.grayColor} />}
                disabled={!!onPhoneErrors()}
              />
              <ListItemText
                primary={
                  <Typography
                    variant="subtitle1"
                    className={classes.textChecks}
                  >
                    Autorizo ​​a verificar Mi Historial Crediticio a través de
                    OTP
                  </Typography>
                }
              />
            </ListItem>
          </div>

          {bureauValidate && (
            // <ListItem className={`${classes.listItemBank} ${classes.column}`}>
            //   {isUserCosigner ? (
            //     <>
            //       {/* <Box className="label" style={{ marginBottom: 20 }}>
            //       Numero de referencia
            //     </Box> */}
            //       <Box className={classes.passwordView}>
            //         <TextField
            //           className={
            //             isUserCosigner
            //               ? `${classes.input} ${classes.isCosigner}`
            //               : classes.input
            //           }
            //           size="small"
            //           label="Numero de referencia"
            //           id="referenceNumber"
            //           placeholder="referencia"
            //           value={referenceNumber}
            //           onChange={(e) => {
            //             setReferenceNumber(e.target.value?.toUpperCase());
            //           }}
            //           onKeyDown={handleKeyDown}
            //         />
            //         <div style={{ marginTop: 20 }}>
            //           <ButtonComponent
            //             onClick={() => checkReferenceCodeData(referenceNumber)}
            //             text="Verify"
            //             className="verifyButton"
            //             disabled={!referenceNumber}
            //             loading={loading}
            //           />
            //         </div>
            //       </Box>
            //     </>
            //   ) : (
            //     <>
            //       {/* <Box className="label" style={{ marginBottom: 10 }}>
            //       Ingrese una credencial válida para verificar
            //     </Box> */}
            //       <Box className={classes.passwordView}>
            //         <TextField
            //           className={
            //             isUserCosigner
            //               ? `${classes.input} ${classes.isCosigner}`
            //               : classes.input
            //           }
            //           size="small"
            //           id="password"
            //           label=" Ingrese una credencial válida para verificar"
            //           type="password"
            //           placeholder="Confirma tu contraseña"
            //           // className={classes.input}
            //           value={confirmationPassword}
            //           onChange={(e) => {
            //             setConfirmationPassword(e.target.value);
            //           }}
            //           onKeyDown={handleKeyDown}
            //           // onBlur={() => setCheckError(true)}
            //         />
            //         <div style={{ marginTop: 10 }}>
            //           <ButtonComponent
            //             onClick={() => checkPassword(confirmationPassword)}
            //             text="Verify"
            //             className="verifyButton"
            //             disabled={!confirmationPassword}
            //             loading={loading}
            //           />
            //         </div>
            //       </Box>
            //     </>
            //   )}
            // </ListItem>
            <></>
          )}

          <ButtonComponent
            onClick={handleClick}
            text="Continuar"
            disabled={
              validateCurp && validateRfc && isTncApproved && isValidPassword
                ? false
                : true
            }
          />
        </Grid>
      </Container>

      <ModalComponent
        open={open}
        body={
          <AcceptTermCondtion
            setOpen={setOpen}
            setShow={handleCloseModal}
            setOpenCosignerOtp={setOpenCosignerOtp}
            setTncAccepted={setOpenCosignerOtp}
          />
        }
        hideBackdrop={false}
        onBackdropClick={handleCloseModal}
      />
      <ModalComponent
        open={openCosignerOtp}
        setOpenOtpVerification={setOpenCosignerOtp}
        body={
          <CosignerOTP
            phoneNumber={phoneNumberValue}
            handleClose={() => setOpenCosignerOtp(false)}
            setValidPassword={setValidPassword}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpenCosignerOtp(false)}
      />
    </div>
  );
};

export default GeneralData;
