import api from "../api";
import medsiOnboarding from "./config";

const onboardingApi = {
  async getSpecialtyType() {
    return api.get(medsiOnboarding.getSpecialty);
  },
  async getProcedureType(procedureType) {
    return api.get(`${medsiOnboarding.getSpecialty}?type=${procedureType}`);
  },
  async getProcedureToPerform(toPerform) {
    return api.get(`${medsiOnboarding.getSpecialty}?level1=${toPerform}`);
  },
  async getClinicListForDoctor(clinicName = "True") {
    return api.get(`${medsiOnboarding.getDoctorClinic}?clinic=${clinicName}`);
  },
  async getDoctorListForClinic(drName = "True") {
    return api.get(`${medsiOnboarding.getDoctorClinic}?doc=${drName}`);
  },
  async getBranchForClinic(clinicName = "True") {
    return api.get(
      `${medsiOnboarding.getClinicBranch}?clinic=${clinicName?.toLowerCase()}`
    );
  },
  async getZipCodeInfo(zipcode = "") {
    return api.post(medsiOnboarding.getZipCodeInfo, { zipcode });
  },

  async getCurpInfo(curp = "") {
    return api.get(`${medsiOnboarding.getCurpInfo}?curp=${curp}`);
  },
};

export default onboardingApi;
