import { makeStyles, createStyles } from '@mui/styles';

const useStyles = () =>
  createStyles({
    wrapper: {
      margin: 0,
      padding: 0,
      '& mati-button': {
        height: 0,
        width: 0,
      },
    },
  });

export default makeStyles(useStyles);
