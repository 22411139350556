import React, { useState } from "react";
import { useAppDispatch } from "../../../../store";
import useStyles from "../../styles";

import CheckIcon from "@mui/icons-material/Check";
import Box from "@mui/material/Box/Box";
import { useSelector } from "react-redux";
import DeleteConfirmation from "../../../../components/ModalContents/DeleteConfirmation";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import { API_STATUS, URBANIST_FONT } from "../../../../constants/common";
import { selectTakeLoanDetail } from "../../../../store/slices/users";
import { deleteApplicationId } from "../../../../store/slices/users/actions";
import { errorToast, successToast } from "../../../../utils/toast";

const Step = (props) => {
  const urlId = window.location.search.slice(1, window.location.search.length);

  const dispatch = useAppDispatch();
  const { StepperStatus } = props;
  const loanDetail = useSelector(selectTakeLoanDetail);
  const [application_id, setAppId] = useState();
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    if (loanDetail !== null || urlId) {
      setAppId(urlId || loanDetail?.application_id);
    } else {
      setAppId(localStorage.getItem("application_id"));
    }
  }, []);

  const css = useStyles();

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box
        sx={(theme) => ({
          width: "550px",
          margin: "0 auto 40px",
          paddingLeft: "12px",
          [theme.breakpoints.down("lg")]: {
            width: "70%",
            p: 0,
            fontFamily: URBANIST_FONT.MEDIUM,
          },
        })}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <div className={css.stepperCircle}>
            {StepperStatus?.patientDetails || true ? (
              <div className={css.stepperCircleActive}>
                <CheckIcon
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <div className={css.stepperCircleInside}>.</div>
            )}
          </div>
          <div style={{ flex: 1, height: 5, background: "#D9D9D9" }} />

          <div className={css.stepperCircle}>
            {StepperStatus?.procedure_completed ? (
              <div className={css.stepperCircleActive}>
                <CheckIcon
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <div className={css.stepperCircleInside}>.</div>
            )}
          </div>
          <div style={{ flex: 1, height: 5, background: "#D9D9D9" }} />

          <div className={css.stepperCircle}>
            {StepperStatus?.loan_completed ? (
              <div className={css.stepperCircleActive}>
                <CheckIcon
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <div className={css.stepperCircleInside}>.</div>
            )}
          </div>
          <div style={{ flex: 1, height: 5, background: "#D9D9D9" }} />

          <div className={css.stepperCircle}>
            {StepperStatus?.kyc_completed ? (
              <div className={css.stepperCircleActive}>
                <CheckIcon
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <div className={css.stepperCircleInside}>.</div>
            )}
          </div>
          <div style={{ flex: 1, height: 5, background: "#D9D9D9" }} />

          <div className={css.stepperCircle}>
            {StepperStatus?.signature_completed ? (
              <div className={css.stepperCircleActive}>
                <CheckIcon
                  sx={{
                    width: "100%",
                  }}
                />
              </div>
            ) : (
              <div className={css.stepperCircleInside}>.</div>
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            margin: "5px -30px",
            fontSize: 12,
          }}
        >
          <div style={{ maxWidth: 100, color: "#3552CC", textAlign: "center" }}>
            Detalles del Paciente
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{
              maxWidth: 100,
              color: "#3552CC",
              textAlign: "center",
            }}
          >
            Información del Procedimiento
          </div>
          <div style={{ flex: 1 }} />
          <div style={{ maxWidth: 100, color: "#3552CC", textAlign: "center" }}>
            Detalles del Préstamo
          </div>

          <div style={{ flex: 1 }} />
          <div style={{ maxWidth: 100, color: "#3552CC", textAlign: "center" }}>
            Validación de Indentidad
          </div>
          <div style={{ flex: 1 }} />
          <div style={{ maxWidth: 100, color: "#3552CC", textAlign: "center" }}>
            Firma del Contrato
          </div>
        </div>
      </Box>

      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          margin: "0px 44px",
          fontFamily: "SFUIText-bold",
          fontSize: "16px",
          textDecoration: "underline",
          color: "#3552CC",
          [theme.breakpoints.down("lg")]: {
            fontFamily: URBANIST_FONT.BOLD,
            fontSize: "18px",
            mb: "2rem",
          },
          [theme.breakpoints.down("tablet")]: {
            flexDirection: "column",
            gap: "1rem",
            m: "0 32px 2rem",
          },
        })}
      >
        <div style={{ display: "flex" }}>
          ID de la Solicitud: {application_id}
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleOpen();
          }}
        >
          Eliminar Solicitud
        </div>
      </Box>

      <ModalComponent
        open={open}
        handleOpen={handleOpen}
        body={
          <DeleteConfirmation
            setOpen={setOpen}
            application_id={application_id}
            redirect={true}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpen(false)}
      />
    </>
  );
};

export default Step;
