import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

import { customColor } from "../../theme/customColor";
import video3 from "../../assets/home/video3.svg";
import { URBANIST } from "../../constants/fontsConst";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    height: 615,
    [theme.breakpoints.down(601)]: {
      height: 270,
    },
  },
  video: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "column",
  },
  video3: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "500px",
    backgroundImage: `url("${video3}")`,
    [theme.breakpoints.down(601)]: {
      padding: "0 20px",
      textAlign: "center",
      height: "360px",
    },
  },
  title: {
    fontSize: 32,
    color: customColor.black,
    fontFamily: URBANIST.SEMIBOLD,
    fontWeight: "700",
    [theme.breakpoints.down(601)]: {
      fontSize: 22,
    },
  },
  content: {
    position: "absolute",
    width: "80%",
    "& > div": {
      width: "46%",
      [theme.breakpoints.down(601)]: {
        width: "80%",
      },
    },
    "& > .first": {
      color: "#7B7E7E",
      fontSize: 40,
      fontFamily: URBANIST.BOLD,
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down(601)]: {
        fontSize: 20,
      },
    },
    "& > .second": {
      color: customColor.cotton,
      fontFamily: URBANIST.REGULAR,
      fontWeight: 500,
      fontSize: 45,
      textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      [theme.breakpoints.down(601)]: {
        fontSize: 26,
      },
    },
  },
 
  btnCredit: {
    fontFamily: URBANIST.BOLD,
    backgroundImage: "linear-gradient(90deg, #0ACC97 12.31%, #1B63DB 107.39%)",
    borderRadius: 20,
    padding: "18px 38px",
    fontSize: 20,
  },
  containerMedium: {
    background: `linear-gradient(180deg, #012758 0%, #2476BC 100%)`,
    height: 373,
    width: "100%",
    paddingBottom: 0,
    marginBottom: 0,
    borderBottomLeftRadius: 50,
    borderBottomRightRadius: 50,
    [theme.breakpoints.down("sm")]: {
      height: 624,
    },
  },
  imageMediumContainer: {
    marginTop: 34,
    marginRight: 100,
    [theme.breakpoints.down(850)]: {
      height: 300,
      marginTop: 73,
      marginLeft: 50,
    },
    [theme.breakpoints.down(750)]: {
      height: 250,
      marginTop: 123,
      marginLeft: 50,
    },
    [theme.breakpoints.down("sm")]: {
      height: 362,
      marginTop: 44,
      marginLeft: -10,
    },
    [theme.breakpoints.down(530)]: {
      height: 320,
      marginTop: 86,
      marginLeft: 1,
    },
    [theme.breakpoints.down(490)]: {
      height: 300,
      marginTop: -84,
      marginLeft: 13,
    },
    [theme.breakpoints.down(470)]: {
      height: 290,
      marginTop: -74,
      marginLeft: 7,
    },
    
  },
  welcomeMessageContainer: {
    marginTop: "120px !important",
    paddingLeft: "30px !important",
    [theme.breakpoints.only("sm")]: {
      marginTop: "110px !important",
      paddingLeft: "60px !important",
    },
    [theme.breakpoints.down(440)]: {
      marginTop: "120px !important",
      paddingLeft: "30px !important",
    },
  },
  headerTitleDescription: {
    marginTop: 2,
    [theme.breakpoints.only("sm")]:{
      width:200
    },
    [theme.breakpoints.down(490)]: {
      width: 400,
      marginTop: 56,
      marginLeft: 0,
    },
    [theme.breakpoints.down(490)]: {
      height: 250,
      marginTop: 76,
      marginLeft: 13,
    },

  },
}));

export default useStyles;
