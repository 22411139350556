import { makeStyles } from "@mui/styles";

const TermsAndConditionsStyles = makeStyles((theme) => ({
  mainContainer: {
    width: "1000px",
    background: theme.palette.primary.white,
    borderRadius: "5px",
    overflow: "hidden",
    position: "relative",
    maxHeight: "95vh",
    textAlign: "center",
    padding: 30,
    paddingTop: 50,
  },
  buttonSection: {
    backgroundColor: theme.palette.primary.white,
    height: 80,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  button: {
    height: 45,
    width: 180,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 1,
    "&.Mui-disabled": {
      height: 45,
      width: 180,
      color: theme.palette.primary.white + "!important",
      backgroundColor: theme.palette.primary.gray + " !important",
    },
  },
}));

export default TermsAndConditionsStyles;
