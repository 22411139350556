import { FC, MouseEvent, ReactNode } from 'react';
import IconButton from '@mui/material/IconButton';

import BackIcon from '../icons/Arrows/Left';
// import CloseIcon from '@icons/Close';
// import CloseTransparentIcon from '@icons/CloseTransparent';

import useStyles, { IUseStyles } from './styles';

export enum IconVariants {
  BACK = 'back',
  CLOSE = 'close',
  CLOSE_TRANSPARENT = 'close-transparent',
  BACK_TRANSPARENT = 'back-transparent',
}

export interface IBackPageButton extends Partial<IUseStyles> {
  onClick: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  buttonText?: string;
  icon?: IconVariants;
  rightComponent?: ReactNode;
  className?: string;
}

const BackPageButton: FC<IBackPageButton> = ({
  buttonText,
  onClick,
  rightComponent,
  className,
  icon = 'back',
  // variant = 'oyster',
  opacity = 1,
  fontSize = 14,
  ...restProps
}) => {
  const css = useStyles({ opacity, fontSize });
  let Icon = BackIcon;

  // let Icon = icon === IconVariants.BACK ? BackIcon : CloseIcon;
  // if (icon === IconVariants.CLOSE_TRANSPARENT) Icon = CloseTransparentIcon;
  // if (icon === IconVariants.BACK_TRANSPARENT) Icon = BackTransparentIcon;

  return (
    <nav className={`${css.backBar} ${className}`} {...restProps}>
      <section role="button" className={css.btn} onClick={onClick}>
        <IconButton type="button">
          <Icon/>
        </IconButton>
        {buttonText && <p>{buttonText}</p>}
      </section>
      {rightComponent && (
        <div className={css.rightComponent}>
          {rightComponent}
        </div>
      )}
    </nav>
  );
};

export default BackPageButton;
