import { rfcValidator } from "../../utils";
import * as yup from "yup";

type PayloadParams = {
  values: any;
  doctorDetails: any;
};

export const paymentMethodsType = [
  "Cuenta CLABE (18 dígitos)",
  "Tarjeta de débito (16 dígitos)",
];

export const bankList = [
  "ABC Capital",
  "Actinver",
  "Afirme",
  "American Express",
  "Autofin",
  "Azteca",
  "Bajío",
  "BanCoppel",
  "Bancrea",
  "Banorte",
  "Bansi",
  "BBVA",
  "Base",
  "CitiBanamex",
  "CIBanco",
  "Compartamos",
  "Consubanco",
  "Finterra",
  "Inbursa",
  "Invex",
  "Monex",
  "Multiva",
  "Pagatodo",
  "Sabadeli",
  "Santander",
  "Scotiabank",
];

export const initialValues = {
  paymentMethodsType: "",
  bankName: "",
  cardNumber: "",
  businessName: "",
  fullName: "",
  id: "",
  rfc: "",
  email: "",
  phoneNumber: "",
};

export const validateForm = ({ values, errors }: any) => {
  // Validate bank method type
  !values.paymentMethodsType
    ? (errors.paymentMethodsType = "Selecciona el método de pago")
    : delete errors.paymentMethodsType;

  // Validate bank
  !values.bankName
    ? (errors.bankName = "Selecciona el Banco")
    : delete errors.bankName;

  // Validate business name
  !values.businessName
    ? (errors.businessName = "Este campo es requerido")
    : delete errors.businessName;

  // Validate id
  !values.id ? (errors.id = "Este campo es requerido") : delete errors.id;

  // Validate fullName
  !values.fullName
    ? (errors.fullName = "Este campo es requerido")
    : delete errors.fullName;

  if (!values.cardNumber) {
    errors.cardNumber = "Este campo es requerido";
  } else if (
    (values.paymentMethodsType === paymentMethodsType[0] &&
      values.cardNumber.length !== 18) ||
    (values.paymentMethodsType === paymentMethodsType[1] &&
      values.cardNumber.length !== 16)
  ) {
    errors.cardNumber = "Introduce un número de tarjeta válido";
  } else {
    delete errors.cardNumber;
  }

  //validate rfc
  if (rfcValidator(values.rfc)) {
    // setValidateRfc(true);
    delete errors.rfc;
  } else {
    // setValidateRfc(false);
    errors.rfc = "El RFC no es válido";
  }
};

export const getPayload = ({ values, doctorDetails = {} }: PayloadParams) => {
  const typeArr = values?.paymentMethodsType?.split(" (");
  const {
    doc_name = "",
    doc_last_name = "",
    user_email = "",
    application_id = "",
  } = doctorDetails;

  const payload = {
    accountDetails: {
      doc_name,
      doc_last_name,
      doc_email: values.email,
      doc_phone: values.phoneNumber,
      payment_method: typeArr?.[0],
      bank_name: values.bankName,
      number: values.cardNumber,
      business_name: values.businessName,
      full_name_physician: values.fullName,
      professional_id: values.id,
      user_email,
      application_id,
      rfc: values.rfc,
    },
    doctorDetails: {
      doc_name,
      doc_last_name,
      doc_email: values.email,
      doc_phone: values.phoneNumber,
    },
  };

  return payload;
};

export const getNumberLabel = ({ values }) => {
  if (values?.paymentMethodsType) {
    return `${
      values?.paymentMethodsType === paymentMethodsType[0]
        ? "Clabe"
        : "Número de tarjeta"
    } `;
  }
  return "Clabe / Número de tarjeta";
};

export const getNumberPlaceHolder = ({ values }) => {
  if (values?.paymentMethodsType) {
    return `Escriba los ${
      values?.paymentMethodsType === paymentMethodsType[0] ? "18" : "16"
    } dígitos`;
  }
  return `Escriba los  16 / 18 dígitos`;
};

export const getMaxLength = ({ values }) => {
  if (values?.paymentMethodsType) {
    return `${values?.paymentMethodsType === paymentMethodsType[0] ? 18 : 16} `;
  }
  return 18;
};

// export const validationSchema = yup.object({
//   email: yup
//     .string()
//     .email("Ingresa un correo válido")
//     // .required("El correo es requerido")
//     .transform((value) => value.toLowerCase()),
// });
