import { Action, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import { useDispatch } from "react-redux";

//Slices
import userReducer from "./slices/users";
import installmentLoan from "../features/installmentLoan/store/installmentLoan";
import auth from "./slices/auth";
import ui from "../features/installmentLoan/store/ui";
import repayment from "../features/installmentLoan/store/repayment";
import payBook from "../features/installmentLoan/store/payBook";
import cosignerReducer from "./slices/cosigner";
import onboarding from "./slices/onboarding";

const persistConfig = {
  key: "medsi",
  storage,
  whitelist: ["auth"],
};

const reducers = combineReducers({
  user: userReducer,
  installmentLoan,
  payBook,
  auth,
  repayment,
  ui,
  cosigner: cosignerReducer,
  onboarding,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const persist = persistedReducer;

const store = configureStore({
  reducer: persist,
  middleware: [thunk],
});
export default store;

export type RootState = ReturnType<typeof store.getState>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useAppDispatch = () => useDispatch<ThunkAppDispatch>();

