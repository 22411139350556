// collection of endpoints goes here


export const medsiPaybook = {
  getToken: 'v1/get_token/',
  saveTxnPayBook: 'v1/save_transactions/',
  saveMatiResponse: '/v1/kyc/save-mati-response/',
};

export default medsiPaybook;
