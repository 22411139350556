import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@mui/material";

import { formatMoney, i18nDate } from "../../../utils/formatter";

import useStore from "../../../store/getStore";
import { getBalanceSummary } from "../../../features/installmentLoan/store/installmentLoan";

import useStyles from "./styles";

export interface LoanSummaryProps {
  showInterest?: boolean;
}

const LoanSummary: FC<LoanSummaryProps> = () => {
  const { installmentLoan } = useStore();
  const css = useStyles();
  const dispatch = useDispatch();
  const { loadingBalanceSummary, balanceSummary, isBalanceSummaryLoaded } =
    installmentLoan;

  const { loanAmt, emiAmt, tenure, disbursementDate, paymentFrequency } =
    balanceSummary || {};

  const loadData = async () => {
    if (!isBalanceSummaryLoaded) {
      await dispatch(getBalanceSummary());
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  if (loadingBalanceSummary) {
    return <div>Loader</div>;
  }

  const list = [
    {
      label: "Monto del préstamo",
      value: loanAmt ? formatMoney(loanAmt) : "",
    },
    {
      label: "Monto del término",
      value: emiAmt ? formatMoney(emiAmt) : "",
    },
    {
      label: "Plazo",
      value: tenure ? `${tenure} Meses` : "",
    },
    {
      label: "Frecuencia de pagos",
      value: paymentFrequency || "",
    },
    {
      label: "Fecha de inicio",
      value: disbursementDate
        ? i18nDate(disbursementDate, "es").format("D [de] MMMM [del] yyyy")
        : "",
    },
  ];

  return (
    <Table aria-label="simple table">
      <TableHead className={css.headerRow}>
        <TableRow>
          {list?.map(({ label }, index) => {
            return (
              <TableCell
                className={`${css.tableCellStyle} ${
                  index === 0
                    ? css.leftBorderTop
                    : index === list.length - 1
                    ? css.rightBorderTop
                    : ""
                }`}
                key={`${label}_${index}_header`}
              >
                <b>{label}</b>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow hover className={css.root}>
          {list?.map(({ label, value }, index) => {
            return (
              <TableCell
                key={`${label}_${index}column`}
                className={`${css.tableCellStyle} ${
                  index === 0
                    ? css.leftBorderBottom
                    : index === list.length - 1
                    ? css.rightBorderBottom
                    : ""
                }`}
              >
                {value}
              </TableCell>
            );
          })}
        </TableRow>
      </TableBody>
    </Table>
  );
  // return (
  //   <Box mt={2}>
  //     <Grid container>
  //       <Grid item xs={12} md={12} xl={12}>
  //         <Box display="flex" ml={2}>
  //           {loanAmt && (
  //             <CustomRow
  //               primaryTextComponent={
  //                 <span className={css.primaryText}>
  //                   {formatMoney(loanAmt)}
  //                 </span>
  //               }
  //               secondaryTextComponent={
  //                 <span className={css.secondaryText}>
  //                   {t(`${loanPrefix}.totalAmount`)}
  //                 </span>
  //               }
  //               variant="bold"
  //               className={css.borderBottom}
  //             />
  //           )}
  //           {emiAmt && (
  //             <CustomRow
  //               primaryTextComponent={
  //                 <span className={css.primaryText}>
  //                   {formatMoney(emiAmt)}
  //                 </span>
  //               }
  //               secondaryTextComponent={
  //                 <span className={css.secondaryText}>
  //                   {t(`${loanPrefix}.memi`)}
  //                 </span>
  //               }
  //               variant="bold"
  //               className={css.borderBottom}
  //             />
  //           )}
  //           {tenure && (
  //             <CustomRow
  //               primaryTextComponent={
  //                 <span className={css.primaryText}>{`${tenure} meses`}</span>
  //               }
  //               secondaryTextComponent={
  //                 <span className={css.secondaryText}>
  //                   {t(`${loanPrefix}.tenure`)}
  //                 </span>
  //               }
  //               variant="bold"
  //               className={css.borderBottom}
  //             />
  //           )}
  //           {/* {showInterest && (
  //               <CustomRow
  //                 primaryText={`${interestRate}%`}
  //                 secondaryText={t(`${loanPrefix}.interest`)}
  //                 variant="bold"
  //                 className={css.borderBottom}
  //               />
  //             )} */}
  //           {disbursementDate && (
  //             <CustomRow
  //               primaryTextComponent={
  //                 <span className={css.primaryText}>
  //                   {i18nDate(disbursementDate, "es").format("D [de] MMMM [del] yyyy")}
  //                 </span>
  //               }
  //               secondaryTextComponent={
  //                 <span className={css.secondaryText}>
  //                   {t(`${loanPrefix}.disbursementDate`)}
  //                 </span>
  //               }
  //               variant="bold"
  //               className={css.borderBottom}
  //             />
  //           )}
  //         </Box>
  //       </Grid>
  //     </Grid>
  //   </Box>
  // );
};

export default LoanSummary;
