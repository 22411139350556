import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

import { API_STATUS } from "../../../../constants/common";
import { instalmentLoanEndpoints } from "../../config/endpoints";
import instalmentLoanApi from "../../../../apis/instalmentLoan";
import { mapRepaymentAmount } from "./helpers";

export const paymentOptions = ["Pago mensual", "Otra cantidad"];

const initialState = {
  isLoading: false,
  terms: [],
  txnAmt: 0,
  amtPaid: 0,
  contractId: "",
  txnDate: "",
  txnId: "",
  invoiceLink: "",
  selectedTxnId: "",
  payAmountDetails: null,
  selectedPaymentOption: paymentOptions[0],
};

const repaymentAmountSampleData = {
  status: "success",
  data: {
    ids_list: [
      {
        no: 6,
        date: "2022-09-14 05:12:15",
        beginningBalance: 5.99,
        paymentAmount: 5.99,
        interestAmt: 1.0,
        principalAmt: 4.83,
        vatAmt: 0.16,
        endingBalance: 0.0,
      },
    ],
    principalAmt: 4.83,
    interestAmt: 1.0,
    vatAmt: 0.16,
    totalAmt: 5.99,
    fullClosureAmt: 5.83,
    nextDueDate: "2022-09-14 05:12:15",
  },
};

const repaymentDetailsSampleData = {
  data: {
    term: [
      {
        id: "797bda4e864142f1bd04bbbb460619c8",
        date: "2022-01-13 12:33:15",
        principalPaid: 2387.48,
        interestPaid: 250,
        extraAmtPaid: 0,
        totalAmtPaid: 2637.48,
      },
      {
        id: "797bda4e864142f1bd04bbbb460619c9",
        date: "2022-01-13 12:33:15",
        principalPaid: 2387.48,
        interestPaid: 250,
        extraAmtPaid: 0,
        totalAmtPaid: 2637.48,
      },
    ],
    txnAmt: 2637.48,
    amtPaid: 2637.48,
    contractId: "contractId",
    txnDate: "txnDate",
    txnId: "txn1",
    invoiceLink: "invoiceLink",
  },
  meta: {
    status: "SUCCESS",
    message: "success",
    filterParam: "null",
    error: "false",
    success: "false",
    errorCode: "UNKNOWN_ERROR",
    debug: "null",
    pagination: "null",
  },
};

export const getRepaymentDetails = createAsyncThunk(
  instalmentLoanEndpoints.repaymentDetails,
  async () => {
    return repaymentDetailsSampleData;
  }
);

export const getRepaymentAmount = createAsyncThunk(
  instalmentLoanEndpoints.getRepaymentAmount,
  async (date: string) => {
    const { data } = await instalmentLoanApi.getRepaymentAmount({
      date,
      // tnc: 'asdf',signature: '', repayment_options: 'bi-weekly'
    });

    const res = mapRepaymentAmount(data);
    // const res = mapRepaymentAmount(repaymentAmountSampleData);
    return res;
  }
);

export type IPostRepaymentAmountArgs = {
  date: string;
  amt: number;
};

export const postRepaymentAmount = createAsyncThunk(
  instalmentLoanEndpoints.postRepaymentAmount,
  async (arg: IPostRepaymentAmountArgs) => {
    const { date, amt } = arg;
    const { data } = await instalmentLoanApi.postRepaymentAmount({
      date,
      amt,
    });
    return data;
    // return repaymentAmountSampleData;
  }
);

const slice = createSlice({
  name: "repayment",
  initialState: {
    ...initialState,
  } as RepaymentState,
  reducers: {
    setLoadingValue: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSelectedOption: (state, { payload }) => {
      state.selectedPaymentOption = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRepaymentDetails.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;
      if ("SUCCESS" === meta?.status) {
        state.contractId = data.contractId;
        state.txnDate = data.txnDate;
        state.invoiceLink = data.invoiceLink;
        state.txnId = data.txnId;
        state.terms = data.term;
        state.amtPaid = data.amtPaid;
      }
      state.isLoading = false;
    });
    // get repayment amount
    builder.addCase(getRepaymentAmount.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;

      if (API_STATUS?.SUCCESS === meta?.status) {
        state.payAmountDetails = data;
      }
    });
    builder.addCase(postRepaymentAmount.fulfilled, (state, { payload }) => {
      const { meta, data } = payload as any;

      if (API_STATUS?.SUCCESS === meta?.status) {
        console.log(payload, "payload");
        // state.payAmountDetails = data;
      }
    });
  },
});

export type TermDetails = {
  id: string;
  date: string;
  principalPaid: number;
  interestPaid: number;
  extraAmtPaid: number;
  totalAmtPaid: number;
};
export type RepaymentIntentIds = {
  no: string; //
  date: string; //
  beginningBalance: number; //
  paymentAmount: number; //
  principalAmt: number; //
  interestAmt: number; //
  endingBalance: number; //
  vatAmt: number; //
};

export type RepaymentIntent = {
  resDate: string;
  principalAmt: number; //
  interestAmt: number; //
  totalAmt: number; //
  fullClosureAmt: number; //
  vatAmt: number; //
  nextDueDate: string; //
  quickTerminationDate: string;
  idsList: RepaymentIntentIds[]; //
};

export type RepaymentState = {
  isLoading: boolean;
  terms: TermDetails[];
  txnAmt: number;
  amtPaid: number;
  contractId: string;
  txnDate: string;
  txnId: string;
  invoiceLink: string;
  selectedTxnId: string;
  payAmountDetails?: RepaymentIntent | null;
  selectedPaymentOption?: string;
};

export default slice.reducer;

export const { setLoadingValue, setSelectedOption } = slice.actions;

export const getRepaymentState = (state: any) => state.repayment;

export const getRepaymentAmountValue = (state: any) => {
  const { selectedPaymentOption, payAmountDetails } = state.repayment;
  if (payAmountDetails) {
    if (selectedPaymentOption === paymentOptions[0]) {
      return payAmountDetails.totalAmt;
    } else if (selectedPaymentOption === paymentOptions[1]) {
      return payAmountDetails.fullClosureAmt;
    }
  }

  return 0;
};
