import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ManIcon from "../../assets/PersonalInformation/manIcon.svg";
import WomanIcon from "../../assets/PersonalInformation/womanIcon.svg";
import DateinputComponent from "../../components/sharedComponents/DateInputComponent";
import CustomInputText from "../../components/sharedComponents/InputText";
import TextInput from "../../features/installmentLoan/components/TextInput";
import { verifyPassword } from "../../store/slices/auth/actions";
import { getZipCodeInfo } from "../../store/slices/onboarding/actions";
import { curpValidator, rfcValidator } from "../../utils";

import DropdownMenu from "../../components/sharedComponents/DropdownMenu";
import {
  getUserGeneralInformation,
  getUserGeneralInformationV2,
  saveUserGeneralInformation,
  saveUserProfessionalDetails,
  selectUserEmail,
  selectUserGeneralInformation,
  selectUserGeneralInformationV2,
  selectUserId,
} from "../../store/slices/users";
import useStyle from "./styles";

import { CheckCircle, CheckRounded, Circle } from "@mui/icons-material";
import { startCase, toLower } from "lodash";
import "../../../src/input.css";
import Logo from "../../assets/Logos/gradient-logo.svg";
import AcceptTermCondtion from "../../components/ModalContents/AcceptTermCondition";
import RegistrationVerifyPassword from "../../components/ModalContents/RegistrationVerifyPassword";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import InputPhone from "../../components/sharedComponents/InputPhone";
import InputText from "../../components/sharedComponents/InputText";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import SplashValidation from "../../components/sharedComponents/SplashValidation";
import { conocerteMejor } from "../../constants";
import { API_STATUS } from "../../constants/common";
import {
  coSignerGeneraleInfoPage,
  coSignerProfessionalDetailPage,
  generaleInfoPage,
  professionalDetailPage,
} from "../../constants/gtm";
import { ROUTES } from "../../constants/routes";
import { setEmailAndName } from "../../store/slices/auth";
import {
  getZipCodeInfoCosigner,
  selectId,
  selectIsCosigner,
  setCosignerGeneralInformation,
} from "../../store/slices/cosigner";
import customColor from "../../theme/customColor";
import { GTMClick } from "../../utils/gtm";
import { errorToast, successToast } from "../../utils/toast";

const { ocupacion, costo, ingreso } = conocerteMejor;

function GeneralInformation() {
  const [terms, setTerms] = useState(false);
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);

  const nowDate = new Date();
  const [checkErrorPassword, setCheckError] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [isValidPassword, setValidPassword] = useState(false);

  const classes = useStyle();
  const history = useHistory();
  const [wasInitialized, setWasInitialized] = React.useState(false);
  const dispatch = useDispatch();
  const id_client = useSelector(selectUserId);
  const id_cosigner = useSelector(selectId);
  const userGeneralInformationV2 = useSelector(selectUserGeneralInformationV2);

  const isUserCosigner = selectIsCosigner();

  const [gotDataFromGetApi, setGotDataFromGetApi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isTncAccepted, setTncAccepted] = useState(false);

  const getData = async () => {
    await dispatch(getUserGeneralInformationV2()).unwrap();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const getTitle = (str) => {
    return startCase(toLower(str));
  };

  useEffect(() => {
    if (
      userGeneralInformationV2.occupation ||
      userGeneralInformationV2.occupation_others ||
      userGeneralInformationV2.company_name ||
      userGeneralInformationV2.monthly_income_range ||
      userGeneralInformationV2.address?.street ||
      userGeneralInformationV2.address?.city ||
      userGeneralInformationV2.address?.colony ||
      userGeneralInformationV2.address?.cp ||
      userGeneralInformationV2.address?.inside_no ||
      userGeneralInformationV2.address?.municipality ||
      userGeneralInformationV2.address?.outside_no ||
      userGeneralInformationV2.address?.state
    ) {
      userGeneralInformationV2.occupation &&
        setFieldValue("occupation", userGeneralInformationV2.occupation);
      userGeneralInformationV2.occupation_others &&
        setFieldValue(
          "otherOcuppation",
          userGeneralInformationV2.occupation_others
        );
      userGeneralInformationV2.company_name &&
        setFieldValue("companyName", userGeneralInformationV2.company_name);
      userGeneralInformationV2.monthly_income_range &&
        setFieldValue(
          "monthlyIncome",
          userGeneralInformationV2.monthly_income_range
        );

      userGeneralInformationV2.address.street &&
        setFieldValue("street", userGeneralInformationV2.address.street);

      userGeneralInformationV2.address.city &&
        setFieldValue("city", userGeneralInformationV2.address.city);

      userGeneralInformationV2.address.colony &&
        setFieldValue("colony", userGeneralInformationV2.address.colony);

      userGeneralInformationV2.address.cp &&
        setFieldValue("cp", userGeneralInformationV2.address.cp);

      userGeneralInformationV2.address.inside_no &&
        setFieldValue(
          "insideNumber",
          userGeneralInformationV2.address.inside_no
        );

      userGeneralInformationV2.address.outside_no &&
        setFieldValue(
          "outdoorNumber",
          userGeneralInformationV2.address.outside_no
        );

      userGeneralInformationV2.address.municipality &&
        setFieldValue(
          "municipality",
          userGeneralInformationV2.address.municipality
        );

      userGeneralInformationV2.address.state &&
        setFieldValue("state", userGeneralInformationV2.address.state);

      setGotDataFromGetApi(true);
    }
  }, [userGeneralInformationV2]);

  const validate = (values) => {
    let errors = {};
    setWasInitialized(true);

    values.occupation === ""
      ? (errors.occupation = "Este campo es requerido")
      : delete errors.occupation;
    if (values.occupation === "Empleado") {
      values.companyName === ""
        ? (errors.companyName = "Este campo es requerido")
        : delete errors.companyName;
      values.otherOcuppation = "";
    } else {
      delete errors.companyName;
    }

    if (values.occupation === "Otro") {
      values.otherOcuppation === ""
        ? (errors.otherOcuppation = "Este campo es requerido")
        : delete errors.otherOcuppation;
      values.companyName = "";
    } else {
      delete errors.otherOcuppation;
    }

    !values.monthlyIncome
      ? (errors.monthlyIncome = "Este campo es requerido")
      : delete errors.monthlyIncome;

    if (values.street === "") errors.street = "El campo es requerido";

    if (values.outdoorNumber === "")
      errors.outdoorNumber = "El campo es requerido";

    if (values.colony === "") errors.colony = "El campo es requerido";

    if (values?.cp) {
      if (values.city === "") errors.city = "El campo es requerido";
      if (values.municipality === "")
        errors.municipality = "El campo es requerido";
      if (values.state === "") errors.state = "El campo es requerido";
    }

    if (values.cp.length !== 5) {
      errors.cp = "Valor incorrecto";

      if (values.municipality === "Alcaldía o municipio")
        errors.municipality = "El campo es requerido";

      if (values.state === "Estado") errors.state = "El campo es requerido";

      !values.phoneNumber
        ? (errors.phoneNumber = "Este campo es requerido")
        : values.phoneNumber.slice(-10).length < 10
        ? (errors.phoneNumber = "numeros minios requeridos 10")
        : delete errors.phoneNumber;
    }
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      monthlyIncome: "",
      occupation: "",
      otherOcuppation: "",
      companyName: "",
      city: "",
      municipality: "",
      cp: "",
      state: "",
      colony: "",
      outdoorNumber: "",
      insideNumber: "",
      street: "",
    },
    validate,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      let payload = {
        id: isUserCosigner ? id_cosigner : id_client,
        data: {
          corresponsibleData: {
            occupation: values.occupation,
            companyName: values.companyName,
            otherOcuppation: values.otherOcuppation,
            monthly_income_range: values.monthlyIncome,

            address: {
              street: values.street,
              outside_no: values.outdoorNumber,
              inside_no: values.insideNumber,
              colony: values.colony,
              city: values.city,
              municipality: values.municipality,
              cp: values.cp,
              state: values.state,
            },
            createOn: new Date(),
            gotDataFromGetApiValue: gotDataFromGetApi,
          },
        },
      };
      if (isUserCosigner) {
        GTMClick(coSignerProfessionalDetailPage.continue);
        const data = setCosignerGeneralInformation(
          payload.data.corresponsibleData
        ).unwrap();

        if (data?.status === API_STATUS.SUCCESS) {
          // dispatch(
          //   setEmailAndName({
          //     first_name: values.occupation,
          //     last_name: values.paternalSurname,
          //   })
          // );
          history.push(ROUTES.processInformation);
        } else if (data?.status === API_STATUS.ERROR) {
          errorToast(data?.meta?.errorMessage);
        }
      } else {
        GTMClick(professionalDetailPage.continue);
        const data = await dispatch(
          saveUserProfessionalDetails(payload.data.corresponsibleData)
        ).unwrap();
        if (data?.status === API_STATUS.SUCCESS) {
          // dispatch(
          //   setEmailAndName({
          //     first_name: values.name,
          //     last_name: values.paternalSurname,
          //   })
          // );
          history.push(ROUTES.processInformation);
        } else if (data?.status === API_STATUS.ERROR) {
          errorToast(data?.meta?.errorMessage);
        }
      }
      setIsSubmitting(false);
    },
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  const handleOpen = () => {
    setOpen(true);
  };

  const checkPassword = async (text) => {
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      verifyPassword({
        password: text,
      })
    ).unwrap();

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Password verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  let isValidForm =
    Object.entries(errors).length === 0 && wasInitialized ? true : false;

  useEffect(() => {
    fetchData();
  }, [values?.cp]);

  const fetchData = async () => {
    if (values?.cp?.length > 4) {
      const getData = () => {
        if (isUserCosigner) {
          return dispatch(getZipCodeInfoCosigner(values?.cp)).unwrap();
        } else {
          return dispatch(getZipCodeInfo(values?.cp)).unwrap();
        }
      };
      const { status, meta, data } = await getData();
      if (status === API_STATUS.SUCCESS) {
        formik.setFieldValue("city", getTitle(data?.city || ""));
        formik.setFieldValue("state", getTitle(data?.state || ""));
        formik.setFieldValue(
          "municipality",
          getTitle(data?.municipality || "")
        );
      } else if (status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
    }
  };

  return loading ? (
    <SplashValidation image={1} trueRedirect={false} />
  ) : (
    <div className={classes.pageContainer}>
      <Box className={classes.headerPageBackground}>
        <img src={Logo} alt="logo" className={classes.logo} />
        <Typography variant="h2" className={classes.textTitle}>
          Sólo un poco más de información...
        </Typography>
      </Box>
      <div
        style={{
          height: 9,
          background: "linear-gradient(270deg, #1B63DB 19.79%, #0ACC97 100%)",
        }}
      />
      <form onSubmit={handleSubmit}>
        <Box
          className={classes.formContainer}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid
            container
            width={{ lg: 780 }}
            rowSpacing={1.75}
            columnSpacing={10}
            style={{ marginTop: "40px", paddingLeft: 10, paddingRight: 10 }}
          >
            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput paddingFix">
                <DropdownMenu
                  label="Ocupación"
                  optionsObject={ocupacion}
                  name="occupation"
                  handleChange={handleChange}
                  onBlur={handleBlur}
                  value={values.occupation}
                  errors={errors.occupation}
                  touched={touched.occupation}
                  labelStyle={{ color: customColor.black }}
                />
              </div>
            </Grid>

            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput paddingFix">
                <DropdownMenu
                  label="Ingreso Mensual"
                  optionsObject={ingreso}
                  name="monthlyIncome"
                  handleChange={handleChange}
                  value={values.monthlyIncome}
                  onBlur={handleBlur}
                  touched={touched.monthlyIncome}
                  errors={errors.monthlyIncome}
                  labelStyle={{ color: customColor.black }}
                />
              </div>
            </Grid>

            <Grid item xs={12} lg={6} sm={6} mt={0.5}>
              {values.occupation === "Empleado" && (
                <div className="myinput labelMargin">
                  <InputText
                    stylesInput={classes.formControl}
                    label="Nombre de la Empresa"
                    name="companyName"
                    handleChange={handleChange}
                    value={values.companyName}
                    errors={errors.companyName}
                    touched={touched.companyName}
                    handleBlur={handleBlur}
                    labelStyle={{ color: customColor.black }}
                  />
                </div>
              )}
              {values.occupation === "Otro" && (
                <div className="myinput labelMargin">
                  <InputText
                    stylesInput={classes.formControl}
                    label="Especifica"
                    name="otherOcuppation"
                    handleChange={handleChange}
                    value={values.otherOcuppation}
                    errors={errors.otherOcuppation}
                    touched={touched.otherOcuppation}
                    handleBlur={handleBlur}
                    labelStyle={{ color: customColor.black }}
                  />
                </div>
              )}
            </Grid>

            <Grid item xs={12} lg={6} sm={6}></Grid>

            <Grid item xs={12} lg={6} sm={6} display="flex"></Grid>
          </Grid>
          <div className="">
            <Grid
              container
              width={{ lg: 780 }}
              rowSpacing={2.5}
              columnSpacing={10}
              style={{ paddingLeft: 10, paddingRight: 10 }}
            >
              <Grid item xs={12} textAlign="center">
                <Typography variant="h3" className={classes.textGender}>
                  Dirección
                </Typography>
              </Grid>

              <Grid item xs={12} lg={6} sm={6}>
                <div className="myinput labelFix">
                  <CustomInputText
                    stylesInput={classes.mtInputAddress}
                    fullWidth={true}
                    name="street"
                    insideLabel="Calle"
                    value={values.street}
                    handleChange={handleChange}
                    errors={errors.street}
                    touched={touched.street}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <div className="myinput labelFix">
                  <CustomInputText
                    fullWidth={true}
                    stylesInput={classes.mtInputAddress}
                    name="outdoorNumber"
                    insideLabel="No. exterior"
                    value={values.outdoorNumber}
                    handleChange={handleChange}
                    errors={errors.outdoorNumber}
                    touched={touched.outdoorNumber}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <div className="myinput labelFix">
                  <CustomInputText
                    fullWidth={true}
                    stylesInput={classes.mtInputAddress}
                    name="insideNumber"
                    insideLabel="No. interior"
                    value={values.insideNumber}
                    handleChange={handleChange}
                    disabled={false}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <div className="myinput labelFix">
                  <CustomInputText
                    fullWidth={true}
                    stylesInput={classes.mtInputAddress}
                    name="colony"
                    insideLabel="Colonia"
                    value={values.colony}
                    handleChange={handleChange}
                    errors={errors.colony}
                    touched={touched.colony}
                    handleBlur={handleBlur}
                    disabled={false}
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <Box>
                  <div className=" labelFix">
                    <NumberFormat
                      className="myinput"
                      customInput={TextField}
                      size="small"
                      name="cp"
                      label="C.P."
                      color={!errors.cp && touched.cp ? "success" : "secondary"}
                      value={values.cp}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.cp && touched.cp ? true : false}
                      helperText={errors.cp && touched.cp ? errors.cp : ""}
                      inputProps={{
                        maxLength: 5,
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.placeholder,
                        },
                      }}
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}>
                <Box>
                  <div className="myinput labelFix">
                    <CustomInputText
                      stylesInput={classes.selectAddress}
                      insideLabel="Alcaldía o municipio"
                      value={values.municipality}
                      name={"municipality"}
                      handleChange={handleChange}
                      errors={errors.municipality}
                      touched={touched.municipality}
                      handleBlur={handleBlur}
                      placeholder="Alcaldía o municipio"
                    />
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} lg={6} sm={6}>
                <Box>
                  <div className="myinput labelFix">
                    <CustomInputText
                      stylesInput={classes.selectAddress}
                      insideLabel="Ciudad"
                      value={values.city}
                      name={"city"}
                      handleChange={handleChange}
                      errors={errors.city}
                      touched={touched.city}
                      handleBlur={handleBlur}
                      placeholder="Ciudad"
                    />
                  </div>
                </Box>
              </Grid>

              <Grid item xs={12} lg={6} sm={6}>
                <Box>
                  <div className="myinput labelFix">
                    <CustomInputText
                      stylesInput={classes.selectAddress}
                      insideLabel="Estado"
                      value={values.state}
                      name={"state"}
                      handleChange={handleChange}
                      errors={errors.state}
                      touched={touched.state}
                      handleBlur={handleBlur}
                      placeholder="Estado"
                    />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} lg={6} sm={6}></Grid>

              <Grid item xs={12} lg={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="existeDoctor"
                      size="small"
                      // checked={show}
                      checked={isTncAccepted}
                      icon={<Circle />}
                      checkedIcon={<CheckCircle />}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        if (show) {
                          setShow(false);
                          setOpen(false);
                        } else {
                          setOpen(true);
                        }
                      }}
                    />
                  }
                  label={
                    <Typography variant="h5">
                      Autorizo ​​a verificar Mi Historial Crediticio a través de
                      OTP
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
          </div>

          <ModalComponent
            open={show}
            // open={true}
            setOpenCongratsPromo={setShow}
            body={
              <RegistrationVerifyPassword
                phoneNumber={values?.phoneNumber}
                handleClose={() => setShow(false)}
                otp_res={{ id: "" }}
                setOpenTnc={setOpen}
                setValidPassword={setValidPassword}
              />
            }
            hideBackdrop={false}
            // onBackdropClick={() => setOpenCongratsPromo(false)}
          />

          {/* {show && (
            <Grid
              container
              direction="row"
              width={{ lg: 900 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="row"
              className={classes.gridContainer}
            >
              <Grid
                item
                xs={12}
                // textAlign="center"
                justifyContent={"center"}
                alignItems="center"
                display={"grid"}
              >
                <Typography variant="body1" className={classes.textGender}>
                  Confirma ingresando tu contraseña
                </Typography>
                <Box
                  display="flex"
                  className={classes.passwordView}
                  mt={{ lg: 1 }}
                >
                  <TextInput
                    id="password"
                    type="password"
                    placeholder="Confirma tu contraseña"
                    className={classes.input}
                    value={confirmationPassword}
                    onChange={(e) => {
                      setConfirmationPassword(e.target.value);
                    }}
                    onBlur={() => setCheckError(true)}
                  />

                  <ButtonComponent
                    onClick={() => checkPassword(confirmationPassword)}
                    text="Verificar"
                    className="verifyButton"
                    loading={loading}
                  />
                </Box>
              </Grid>
            </Grid>
          )} */}
          <Box mt={1}>
            <ButtonComponent
              type="submit"
              text="Continuar"
              disabled={!isValidPassword || !isValidForm}
              loading={isSubmitting}
              className={classes.buttonStyle}
              style={{
                width: "auto",
                padding: "0px 92px",
              }}
            />
          </Box>
        </Box>
      </form>
      <ModalComponent
        open={open}
        handleOpen={handleOpen}
        body={
          <AcceptTermCondtion
            setOpen={setOpen}
            setShow={setShow}
            setTncAccepted={setTncAccepted}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpen(false)}
      />
    </div>
  );
}

export default GeneralInformation;
