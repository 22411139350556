import { Box, Grid } from "@mui/material";

import bg from "../../assets/aboutUs/bg-v2.svg";

import Header from "../home-v2/Header";
import MainContent from "./MainContent";
import Footer from "../home-v2/Footer";
import useStyle from "./styles";

export const AboutUsPage = () => {
  const css = useStyle();

  return (
    <Box className={css.container}>
      <Box className={css.videoContainer}>
        <img src={bg} />
        {/* <Box className={css.main}>
          <Grid container>
            <Grid item xs={12} md={7}></Grid>
            <Grid item xs={12} md={5} className="content">
              En Medsi te acompañamos en tu transformación
            </Grid>
          </Grid>
        </Box> */}
        <Header />
      </Box>
      <MainContent />
      <Footer />
    </Box>
  );
};

export default AboutUsPage;
