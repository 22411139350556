import { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  FormHelperText,
} from '@mui/material';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../store';
import InputText from '../../components/sharedComponents/InputText';
import DropdownMenu from '../../components/sharedComponents/DropdownMenu';
import InputNumber from '../../components/sharedComponents/InputNumber';
import InputPhone from '../../components/sharedComponents/InputPhone';
import data from '../../utils/static/data';
import DateInputComponent from '../../components/sharedComponents/DateInputComponent';
import ButtonComponent from '../../components/sharedComponents/ButtonComponent';
import MultiSelectComponent from '../../components/sharedComponents/MultiSelectComponent';
import { currencyFormat } from '../../utils';
import {
  getApplicationSummary,
  getCreditMinMax,
  getProcedureInfoDetails,
  updatePendingProcedureDetails,
  updatePendingProcedureDetailsV2,
} from '../../features/installmentLoan/store/installmentLoan';
import { INSTALLMENT_LOAN_ROUTES } from '../../constants/routes';
import { errorToast, successToast } from '../../utils/toast';
import {
  getSpecialtyType,
  getProcedureToPerform,
  getProcedureType,
} from '../../store/slices/onboarding/actions';
import useStore from '../../store/getStore';
import Loading from '../../components/Loading';
import buttonLoader from '../../assets/loader.svg';

import {
  getPayload,
  initialValues,
  setValueFromResponse,
  validateForm,
} from './helpers';
import useStyles from './styles';
import '../../../src/input.css';

import { API_STATUS } from '../../constants/common';
import {
  isOtherShow,
  isProcedureShow,
  removeDrError,
  removeDateError,
  getMaxMinAmount,
} from '../common/ProcedureForm/helpers';
import KycForm from './KycForm';
import RenderClinicDetails from './RenderClinicDetails';
import RenderDoctorDetails from './RenderDoctorDetails';
import RenderTime from './RenderTime';
import {
  getNumber,
  isOtherShowClinic,
  isBranchSelectionAllowed,
  branchListOption,
  doctorListOption,
} from '../common/ProcedureForm/helpers';
import {
  getDashboardDetails,
  selectDashboardDetails,
} from '../../store/slices/users';

const FormComponent = () => {
  const [showLoader, setLoader] = useState(false);
  const css = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { onboarding, installmentLoan } = useStore();
  const {
    specialtyList,
    procedureList,
    procedureToPerformList,
    clinicList,
    branchList,
    doctorList,
  } = onboarding;

  const dashboardDetails = useSelector(selectDashboardDetails);

  const isFormEditable =
    dashboardDetails?.procedure_status === 'Aprobado' ? false : true;

  const { productDetails = {} } = installmentLoan;

  const [validResponse, setValidResponse] = useState(true);
  const [hasNotDate, setHasNotDate] = useState(false);
  const [wasInitialized, setWasInitialized] = useState(false);
  const [hasOtherProcess, setHasOtherProcess] = useState(false);
  const [hasNotDoctor, setHasNotDoctor] = useState(false);
  const [message, setMessage] = useState(false);
  const [responseData, setResponseData] = useState({});
  const [isFirstProcedureType, setIsFirstProcedureType] = useState(true);
  const [isFirstProcedureList, setIsFirstProcedureList] = useState(true);
  const [isFirstLoanAmount, setIsFirstLoanAmount] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableDoctorInfo, setDisableDoctorInfo] = useState(false);

  const validate = (values) => {
    setWasInitialized(true);
    return validateForm({
      values,
      hasNotDoctor,
      touched,
      hasNotDate,
      maxCreditAmt,
      minCreditAmount,
      hasOtherProcess,
      setHasOtherProcess,
      procedureToPerformList,
      branchList,
      procedureList,
      clinicList,
    });
  };

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: async (values) => {
      //   GTMClick(dataProcedurePage.continue);
      const payload = getPayload({
        values,
        hasNotDate,
        responseData,
        procedureToPerformList,
      });
      setLoader(true);
      const { status, meta } = await dispatch(
        updatePendingProcedureDetailsV2(payload)
      ).unwrap();

      if (status === API_STATUS.ERROR) {
        setLoader(false);
      }
      if (status === API_STATUS.SUCCESS) {
        setLoader(false);
        successToast('Sus datos se guardaron correctamente');
        history.push(INSTALLMENT_LOAN_ROUTES.MAIN);
      } else {
        errorToast(meta?.errorMessage);
      }
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formik;

  useEffect(() => {
    formik.validateForm();
  }, [
    values.specialtyType,
    values.procType,
    procedureToPerformList,
    hasNotDoctor,
    hasNotDate,
    values.procedureCost,
  ]);

  useEffect(() => {
    if (!dashboardDetails) {
      dispatch(getDashboardDetails());
    }
  }, []);

  useEffect(() => {
    if (dashboardDetails?.procedure_status === 'Aprobado') {
      setValidResponse(false);
    } else {
      setValidResponse(true);
    }
  }, [dashboardDetails]);

  const { maxCreditAmt, minCreditAmount } = getMaxMinAmount({
    productDetails,
    values,
    clinicList,
  });

  let isValidForm = Object.entries(errors).length === 0;

  const getData = async () => {
    const { data, status } = await dispatch(getProcedureInfoDetails()).unwrap();
    if (status === API_STATUS.SUCCESS) {
      setValueFromResponse({ responseValue: data, values });
      setResponseData(data);

      setLoading(false);
    }
  };

  const onBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getSpecialtyType());
    dispatch(getCreditMinMax());
    getData();
  }, []);

  useEffect(() => {
    if (values?.specialtyType) {
      if (!isFirstProcedureType) {
        values.procToDo = [];
      } else {
        setIsFirstProcedureType(false);
      }
      dispatch(getProcedureType(values?.specialtyType));
    }
  }, [values?.specialtyType]);

  useEffect(() => {
    if (values?.procType) {
      if (!isFirstProcedureList) {
        values.procToDo = [];
      } else {
        setIsFirstProcedureList(false);
      }
      dispatch(getProcedureToPerform(values?.procType));
    }
  }, [values?.procType]);

  // useEffect(() => {
  //   if (values?.clinicName) {
  //     if (+responseData?.requested_loan_amount !== +values.requestedAmount) {
  //       if (!isFirstLoanAmount) {
  //         formik.setFieldValue("requestedAmount", "");
  //       } else {
  //         setIsFirstLoanAmount(false);
  //       }
  //     }
  //   }
  // }, [values?.clinicName, responseData]);

  const canShowOtherInput = isOtherShow({
    values,
  });
  const isProdShow =
    isProcedureShow({
      values,
    }) && procedureList?.length > 0;

  const setCheckValue = (e, check) => {
    switch (check) {
      case 'hasNotDoctor':
        setHasNotDoctor(e.target.checked);
        removeDrError({ values, errors });
        break;
      case 'hasNotDate':
        setHasNotDate(e.target.checked);
        removeDateError({ values, errors });
        break;
      default:
        throw new Error('Something happened');
    }
  };

  const handleChangeMultiple = (event) => {
    const {
      target: { value },
    } = event;
    setFieldValue(
      'procToDo',
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleCreditLimit = (amount) => {
    // if (parseInt(amount) > maxCreditAmt) {
    //   formik.setFieldValue("requestedAmount", maxCreditAmt);
    //   setMessage(true);
    // } else {
    //   formik.setFieldValue("requestedAmount", amount);
    //   setMessage(false);
    //   delete errors.procedureCost;
    // }

    if (parseInt(amount) > 80000) {
      formik.setFieldValue('requestedAmount', 80000);
      setMessage(true);
    } else {
      formik.setFieldValue('requestedAmount', amount);
      setMessage(false);
      delete errors.procedureCost;
    }
  };

  const renderInput = ({ label, value, disabled = false }) => {
    return (
      <InputText
        stylesInput={css.formControl}
        label={label}
        value={values[value]}
        name={value}
        handleChange={handleChange}
        errors={errors[value]}
        touched={touched[value]}
        handleBlur={handleBlur}
        disabled={disabled}
      />
    );
  };

  const renderHasCheck = ({ label, value, validResponse }) => {
    return (
      <FormControl className={`${css.checkBoxContainer} ${css.formControl}`}>
        <FormControlLabel
          control={
            <Checkbox
              name={`exist${value}`}
              size='small'
              onChange={(e) => setCheckValue(e, value)}
              disabled={!validResponse}
            />
          }
          label={
            <Typography
              className={`${validResponse} ? "" : ${css.labelColor}`}
              variant='h5'
            >
              {label}
            </Typography>
          }
        />
      </FormControl>
    );
  };

  const renderDropDown = ({ label, value, dataObj, disabled = false }) => {
    return (
      <DropdownMenu
        label={label}
        optionsObject={dataObj}
        name={value}
        handleChange={handleChange}
        value={values[value]}
        onBlur={handleBlur}
        errors={errors[value]}
        touched={touched[value]}
        disabled={disabled}
      />
    );
  };

  const renderNumberInput = ({ label, value, setField, message, ...rest }) => {
    return (
      <Grid item xs={12} md={6}>
        <InputNumber
          formControlStyles={css.formControl}
          textLabel={label}
          name={value}
          value={values[value]}
          prefix='$'
          errors={errors[value]}
          touched={touched[value]}
          setField={(_, amount) => {
            setField && setField(_, amount);
          }}
          field={value}
          handleBlur={handleBlur}
          {...rest}
        />
        {message || null}
      </Grid>
    );
  };

  if (!loading) {
    return (
      <Box className={css.container} m={4} mt={1} py={5}>
        <form onSubmit={handleSubmit} className='formWrapper'>
          <Box px={5} className={css.formBox}>
            <Grid container columnSpacing={6.25}>
              <Grid item xs={12} md={6}>
                {renderNumberInput({
                  label: 'Costo del procedimiento',
                  value: 'procedureCost',
                  disabled: !validResponse,

                  setField: (_, amount) => {
                    setFieldValue('procedureCost', amount);
                    handleCreditLimit(amount);
                  },
                  message: message && (
                    <FormHelperText style={{ width: '320px', marginTop: 0 }}>
                      {/* {`Por el momento, el monto máximo del crédito que podemos
                          otorgarte es de $${currencyFormat(maxCreditAmt)}`} */}
                      {`Por el momento, el monto máximo del crédito que podemos
                          otorgarte es de $${currencyFormat(80000)}`}
                    </FormHelperText>
                  ),
                })}
              </Grid>
              <Grid item xs={12} md={6}>
                {renderNumberInput({
                  label: 'Monto del crédito solicitado',
                  value: 'requestedAmount',
                  disabled: !validResponse,

                  setField: (_, amount) => {
                    let maximumAmt = maxCreditAmt;
                    if (!formik?.values.clinicName) {
                      maximumAmt = 80000;
                    }
                    if (Number(amount) > maximumAmt) {
                      formik.setFieldValue('requestedAmount', maximumAmt);
                    } else {
                      formik.setFieldValue('requestedAmount', amount);
                    }
                  },
                })}

                <Box className={css.límite}>
                  El límite de $80,000 aplica solamente para ciertos socios
                  comerciales; otras clínicas y médicos pueden estar sujetas a
                  un límite de crédito menor.
                </Box>
              </Grid>

              {/* Here */}
              <RenderClinicDetails
                formik={formik}
                responseData={responseData}
                disabled={!validResponse}
              />
              <Grid item xs={12} md={12}>
                {renderHasCheck({
                  label: ' No he elegido a un médico aún',
                  value: 'hasNotDoctor',
                  validResponse: validResponse,
                  doctorDisable: disableDoctorInfo,
                })}
              </Grid>

              <RenderDoctorDetails
                formik={formik}
                responseData={responseData}
                disabled={!validResponse}
                hasNotDoctor={hasNotDoctor}
              />

              <Grid item xs={12} md={12}>
                {renderHasCheck({
                  label: ' No tengo cita programada aún',
                  value: 'hasNotDate',
                  validResponse: validResponse,
                })}
              </Grid>

              <Grid item xs={12} md={6}>
                <DateInputComponent
                  formStyles={css.formControl}
                  labelTitle='Fecha de la cita'
                  name='appointmentDate'
                  dateValue={values.appointmentDate}
                  handleDate={(value) => {
                    setFieldValue('appointmentDate', value);
                  }}
                  hasDate={hasNotDate}
                  errors={errors.appointmentDate}
                  touched={touched.appointmentDate}
                  onBlur={handleBlur}
                  disabled={!validResponse || hasNotDate}
                  disablePast
                  minDate={new Date()}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RenderTime
                  formik={formik}
                  hasNotDate={hasNotDate}
                  disabled={!validResponse}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <div className='dropdownFix'>
                  <DropdownMenu
                    label={'Especialidad médica'}
                    optionsObject={specialtyList || []}
                    name={'specialtyType'}
                    handleChange={(...rest) => {
                      handleChange(...rest);
                      formik.setFieldValue('procType', '');
                      formik.setFieldValue('procToDo', []);
                      formik.setFieldValue('otherProc', '');
                    }}
                    value={values.specialtyType}
                    onBlur={handleBlur}
                    errors={errors.specialtyType}
                    touched={touched.specialtyType}
                    disabled={!validResponse}
                  />
                </div>
              </Grid>

              {isProdShow && (
                <Grid item md={6} xs={12} className={css.titleLableTime}>
                  {renderDropDown({
                    label: 'Tipo de procedimiento',
                    value: 'procType',
                    dataObj: procedureList,
                    disabled: !validResponse,
                  })}
                </Grid>
              )}
              {!canShowOtherInput && procedureToPerformList?.length > 0 && (
                <Grid item md={6} xs={12}>
                  <MultiSelectComponent
                    labelTitle='Procedimiento(s) a realizar'
                    multiSelectValue={values.procToDo}
                    handleChangeMultiple={handleChangeMultiple}
                    options={procedureToPerformList || []}
                    errors={errors.procToDo}
                    touched={touched.procToDo}
                    onBlur={handleBlur}
                    name='procToDo'
                    disabled={!validResponse}
                  />
                </Grid>
              )}
              {canShowOtherInput && (
                <Grid item md={6} xs={12}>
                  {renderInput({
                    label: 'Indicar otro(s) procedimiento(s)',
                    value: 'otherProc',
                    disabled: !validResponse,
                  })}
                </Grid>
              )}
            </Grid>

            {validResponse && (
              <Box justifyContent='center' display='flex' mt={4}>
                <ButtonComponent
                  type='submit'
                  text='Enviar'
                  disabled={!isValidForm}
                />
                {showLoader && (
                  <div style={{ marginTop: 4 }}>
                    <img src={buttonLoader} />
                  </div>
                )}
              </Box>
            )}
          </Box>
        </form>
      </Box>
    );
  }

  return <Loading />;
};

export default FormComponent;
