import moment from "moment";
import mailgo from "mailgo";

/**
  @function This function allow us to validate a RFC
  @description This function is used to validate if 
   an RFC is valid or not, it validates 2 types of RFC, 
   such as a natural person and a legal entity.
   @param {string} rfc
   @returns Boolean true or false
 * 
 */

export function rfcValidator(rfc, acceptGeneric = true) {
  let newRFC = rfc.replaceAll(/[^a-zA-Z0-9 ]/g, "").replaceAll(/ /g, "");
  if (newRFC.length !== 13) return false;

  const re =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  let valid = rfc.match(re);

  if (!valid) return false;

  const checkDigit = valid.pop(),
    rfcWithoutDigit = valid.slice(1).join(""),
    len = rfcWithoutDigit.length,
    dictionary = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
    index = len + 1;
  let sum, expectedDigit;

  if (len === 12) sum = 0;
  else sum = 481;

  for (var i = 0; i < len; i++)
    sum += dictionary.indexOf(rfcWithoutDigit.charAt(i)) * (index - i);
  expectedDigit = 11 - (sum % 11);
  if (expectedDigit === 11) expectedDigit = 0;
  else if (expectedDigit === 10) expectedDigit = "A";

  if (
    checkDigit != expectedDigit &&
    (!acceptGeneric || rfcWithoutDigit + checkDigit !== "XAXX010101000")
  )
    return false;
  else if (!acceptGeneric && rfcWithoutDigit + checkDigit === "XEXX010101000")
    return false;
  return rfcWithoutDigit + checkDigit;
}

/**
  @function This function allow us to validate a CURP
  @description This function is used to validate if 
   an CURP is valid or not.
   @param {string} curp
   @returns Boolean true or false 
 */

export function curpValidator(curp) {
  var re =
      /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
    valid = curp.match(re);

  if (!valid) return false;

  function checkDigit(curp17) {
    var dictionary = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
      lngSum = 0.0,
      lngDigit = 0.0;
    for (var i = 0; i < 17; i++)
      lngSum = lngSum + dictionary.indexOf(curp17.charAt(i)) * (18 - i);
    lngDigit = 10 - (lngSum % 10);
    if (lngDigit === 10) return 0;
    return lngDigit;
  }

  if (valid[2] != checkDigit(valid[1])) return false;

  return true;
}

/**
 *
 * @param {*} amount
 * @param {*} monthsAmount
 * @returns
 */
export function getMonthlyAmount(amount, monthsAmount) {
  let monthlyInterestRate = 0.4 / 12;
  let iva = 0.16;

  let free = amount * monthlyInterestRate;
  let amortization = amount / monthsAmount;
  let ivaAmount = free * iva;

  return Math.round(free + amortization + ivaAmount);
}

export const formatHour = (hours) => {
  let now = moment(hours, "HHmm").format("LT");
  return now;
};

export const formatDate = (date) => {
  let fecha = new Date(date);
  let day = fecha.getDate();
  let month = fecha.getMonth() + 1;
  let year = fecha.getFullYear();
  if (month < 10) {
    return `${day}-0${month}-${year}`;
  } else {
    return `${day}-${month}-${year}`;
  }
};

export const currencyFormat = (num) => {
  if (num && typeof num === "number") {
    return num.toLocaleString("en", {
      useGrouping: true,
    });
  } else {
    return num;
  }
};

/**
 *
 * @function This function initializa Mailgo
 */
let MailgoConfig = {
  mailto: true, // enable mailgo for mailto, default is obviously truee
  desktop: true, // enable mailgo for desktop, default true
  mobile: true, // enable mailgo for mobile, default true
  dark: true, // dark mode for mailgo, default false
  lang: "es", // language of the modal, default is english
  validateEmail: true, // validate an email, default is true
  validateTel: true, // validate a phone number, default is true
  office365: true, // the particular case of Outlook link: can be outlook.live.com or outlook.office365.com, by default the first but with this parameter you can change the behaviour
  showFooter: false, // show the footer with a link to mailgo.dev, default true, please!
  loadCSS: true, // loadCSS for mailgo, default true
};

export const mailOptions = () => {
  mailgo(MailgoConfig);
};
