import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../theme/customColor";


const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
    background: "linear-gradient(0deg, #EDEDF6 0%, #DAE2EE 100%)",
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    height: "max-content",
    [theme.breakpoints.down(601)]: {
      height: 270,
      backgroundSize: "cover",
    },
    "& > img": {
      width: "99vw",
      height: 690,
      objectFit: 'cover',
    },
  },
  main: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 10%",
    height: "100%",
    "& .content": {
      color: customColor.cotton,
      fontSize: 46,
      fontFamily: "SFUIText-Regular",
      fontWeight: "700",
      marginTop: "18%",
      [theme.breakpoints.down(601)]: {
        fontSize: 32,
      },
    },
  },
  title: {
    fontSize: 33,
    color: customColor.accentBlue,
    fontFamily: "SFUIText-Bold",
    fontWeight: "700",
  },
}));

export default useStyles;
