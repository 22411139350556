import { Box, Grid } from "@mui/material";

import setting from "../../assets/NotFound/setting.svg";
import left from "../../assets/NotFound/left.svg";
import right from "../../assets/NotFound/right.svg";
import useStyle from "./styles";

export const NotFound = () => {
  const css = useStyle();

  return (
    <Box className={css.container}>
      <img src={setting} />
      <Box className={css.title}>Página no encontrada</Box>
      <Box className={css.desc}>
        Lo sentimos, no encontramos la página que buscas...
      </Box>
      <Grid container>
        <Grid item xs={12} md={7} lg={7}>
          <img src={left} className={css.img} />
        </Grid>
        <Grid item xs={12} md={5} lg={5}>
          <img src={right} className={css.img} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFound;
