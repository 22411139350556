import Grid, { GridSize } from "@mui/material/Grid";
import React, { FC, ReactElement } from "react";

import useStyles from "./styles";

export interface ITwoColumns {
  leftContentComp: ReactElement;
  rightContentComp?: ReactElement;
  containerClassName?: string;
  leftSize?: GridSize;
  leftRowPadless?: boolean;
  leftRowClassName?: string;
  rightSize?: GridSize;
  rightRowPadless?: boolean;
  rightRowClassName?: string;
  rightBg?: true | any;
  showMiddleSeparator?: boolean;
  hideRightContent?: boolean;
}

const TwoColumns: FC<ITwoColumns> = ({
  leftContentComp,
  rightContentComp,
  containerClassName,
  leftRowClassName,
  rightRowClassName,
  rightBg: bg,
  leftSize = 5,
  rightSize = 7,
  leftRowPadless = false,
  rightRowPadless = false,
  showMiddleSeparator = false,
  hideRightContent = false,
}) => {
  const rightBg = bg === true ? "pearl" : bg;
  const css = useStyles({
    rightRowPadless,
    rightBg,
    leftRowPadless,
    showMiddleSeparator,
  });

  return (
    <Grid
      container
      className={`${css.twoColumnsContainer} ${containerClassName}`}
    >
      <Grid
        item
        xl={leftSize}
        xs={12}
        className={`${css.leftRow} ${leftRowClassName}`}
      >
        {leftContentComp}
      </Grid>
      {!hideRightContent && (
        <Grid
          item
          xl={rightSize}
          xs={12}
          className={`${css.rightRow} ${rightRowClassName}`}
        >
          {rightContentComp}
        </Grid>
      )}
    </Grid>
  );
};

export default TwoColumns;
