export const customColor = {
  // primary
  accentBlue: "#3552CC",
  oyster: "#1FD7AE",
  brandGreen: "#49F0AC",
  pine: "#014651",
  black: "#000",
  white: "#fff",
  celticBlue: "#1B63DB",
  maastrichtBlue: "#002539",
  caribbeanGreen: "#0ACC97",
  // secondary
  ferrari: "#FF5D51",
  water: "#5ED9ED",
  pineapple: "#FFC61F",
  // complementary
  tea: "#DCEEDF",
  opal: "#026E5E",
  peacock: "#014651",
  arctic: "#C2EBF2",
  cerulean: "#4367E2",
  navy: "#1B2C77",
  bottle: "#203C4D",
  vanilla: "#EFF3A1",
  mellow: "#E8D87D",
  cheddar: "#DF9A00",
  juice: "#F38A1B",
  chocolate: "#332E24",
  ballet: "#F2CECB",
  salmon: "#FA9E9F",
  carmine: "#992922",
  merlot: "#EB001B",
  // gray scale
  cotton: "#FFFFFF",
  pearl: "#FAFAFA",
  stone: "#EBEEF2",
  dolphin: "#BFC5CC",
  shark: "#6B7779",
  extraGrey: "#4C545B",
  charcoal: "#303133",
  obsidian: "#000000",
  crow: "#1C1C1E",
  panter: "#292929",
  ink: "rgba(18, 18, 18, 0.9)",
  oil: "#121212",
  // expenses
  ocher: "#D58807",
  mango: "#FEBB1A",
  honey: "#E2D16A",
  lime: "#EBF390",
  ruby: "#FC4440",
  crimson: "#851A1A",
  coral: "#F68A8E",
  rosewood: "#EEC2BF",
  wine: "#960042",
  rose: "#D4005B",
  barbie: "#FB1582",
  bubblegum: "#DB6AA0",
  eggplant: "#5B0B6A",
  jamisne: "#8900A0",
  magenta: "#C327DE",
  orchid: "#B36CC0",
  midnight: "#151D63",
  iris: "#344DDB",
  sky: "#51D1E8",
  aqua: "#B6E7EF",
  dirtyAqua: "#91C1C4",
  forest: "#073640",
  emerald: "#0D5C4B",
  jade: "#31997C",
  mint: "#25D19E",
  spring: "#42F19C",
  apple: "#A5D0AB",
  lightAccentBlue: "#00B4BD",
  errorTextRed: "#d32f2f",
  lightGreen: "#00B4BE",

  // v2
  headerBg: "#5A7BD7",
  accentBlueLight: "#91bae2",
};

export default customColor;
