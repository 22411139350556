import { URBANIST_FONT } from "../../constants/common";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg from "../../assets/backgroundImages/doctor-bank-details.svg";

import { customColor } from "../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: "none",
    color: customColor.lightAccentBlue,
    fontFamily: "SFUIText-Bold",
  },
  container: {
    backgroundColor: customColor.maastrichtBlue,
    minHeight: "100vh",
    flex: 1,
    height: "100%",
    width: "100%",
    textAlign: "center",
    paddingTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: theme.spacing(18),
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(8)} ${theme.spacing(1.5)} 0px`,
    },
  },
  title: {
    color: customColor.cotton,
    fontSize: 28,
    fontFamily: URBANIST_FONT.BOLD,
    fontWeight: "700",
  },
  desc: {
    color: customColor.cotton,
    fontSize: 18,
    fontFamily: URBANIST_FONT.REGULAR,
    marginTop: theme.spacing(3),
  },
  form: {
    marginTop: theme.spacing(8),
    maxWidth: "960px",
  },
  formContainer: {
    // maxWidth: 400,
    margin: "0 auto",
  },
  formControl: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    width: "100%",
    "& fieldset": {
      display: "none",
    },
    "& input": {
      background: "rgba(255, 255, 255, 0.2)",
      border: `1px solid ${customColor.cotton}`,
      borderRadius: theme.spacing(1),
      height: 36,
      padding: "0px 16px",
      fontSize: 18,
      lineHeight: "1.2",
      fontFamily: "Roboto-Regular",
      fontWeight: "500",
      color: customColor.cotton,
      "&::placeholder": {
        color: customColor.cotton,
      },
      [theme.breakpoints.down(601)]: {
        width: "100%",
        height: "34px",
        fontSize: 14,
      },
    },
    "& > h5": {
      color: customColor.cotton,
      textAlign: "left",
      marginBottom: `${theme.spacing(1)} !important`,
      fontFamily: "Roboto-Bold",
    },
  },
  select: {
    marginTop: "5px !important",
    marginBottom: "0 !important",
    outline: "none",
    width: "100%",
    "& > h5": {
      color: customColor.cotton,
      textAlign: "left",
      marginBottom: `${theme.spacing(1)} !important`,
      fontFamily: "Roboto-Bold",
    },
    "& .MuiSelect-select": {
      background: "rgba(255, 255, 255, 0.2)",
      borderRadius: theme.spacing(1),
      height: "36px !important",
      padding: "0px 16px",
      fontSize: 18,
      lineHeight: 2,
      fontFamily: "Roboto-Regular",
      fontWeight: "500",
      border: `1px solid ${customColor.cotton}`,
      color: customColor.cotton,
      textAlign: "left",
      "&::placeholder": {
        color: customColor.cotton,
      },
      [theme.breakpoints.down(601)]: {
        width: "100%",
        height: "34px",
        fontSize: 14,
      },
    },
    "& .MuiInputBase-formControl": {
      borderRadius: theme.spacing(1),
    },
    "& svg": {
      color: customColor.cotton,
    },
    "& fieldset": {
      display: "none",
    },
  },

  submitButtonStyle: {
    justifyContent: "center",
    padding: "18px 152px",
    borderRadius: 100,
    background: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
    marginTop: 96,
    "&:disabled": {
      background: "#999",
      color: customColor.cotton,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "18px 50px",
    },
  },
  contentWrapper: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },
  details: {
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40%",
    },
  },
  selectPlaceHolderColor: {
    "& .MuiSelect-select": {
      color: "rgba(255, 255, 255, 0.2)",
    },
  },
  phoneNumberDisclaimer: {
    fontSize: 14,
    marginTop: "-10px",
    fontFamily: URBANIST_FONT.MEDIUM,
    color: "#fff",
  },
  headerInput: {
    paddingLeft: "24px !important",
    color: customColor.cotton,
    textAlign: "left",
    fontWeight: "bold",
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px !important",
    },

    marginTop: 20,
  },
  phoneInput: {
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
