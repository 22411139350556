import { makeStyles } from '@mui/styles';

const DropdownMenuStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.white,
    [theme.breakpoints.only('xs')]: {
      fontSize: '17px !important',
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      fontSize: '23px !important',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '25px !important',
    },
  },
  inputSize: {
    [theme.breakpoints.only('xs')]: {
      width: 300,
    },
    [theme.breakpoints.between('sm', 'lg')]: {
      width: 350,
    },
    [theme.breakpoints.up('lg')]: {
      width: 400,
    },
    marginTop: '5px !important',
    marginBottom: '20px !important',
  },
  formHelperText: {
    color: 'red !important',
    marginTop: -15,
    marginBottom: 20,
  },
  formContainer: {
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40ch',
    },
    [theme.breakpoints.down('tablet')]: {
      marginBottom: '0px !important',
    },
    marginTop: '5px !important',
    marginBottom: '20px !important',
  },
  isLastItem: {
    marginBottom: `-${theme.spacing(1)} !important`,
    paddingBottom: `${theme.spacing(1)} !important`,
  },
  isFirstItem: {
    marginTop: `-${theme.spacing(1)} !important`,
    paddingTop: `${theme.spacing(1)} !important`,
  },
}));

export default DropdownMenuStyles;
