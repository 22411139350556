export const METTA_URLS = {
  URL: `https://zsr6mf1rp4.execute-api.us-west-2.amazonaws.com/dev/user-information`,
  URL_USERS_INFO: `https://zsr6mf1rp4.execute-api.us-west-2.amazonaws.com/dev/users-information`,
};

export const ONBOARDING_TYPE = {
  USER: "user",
  CO_SIGNER: "co-signer",
};

export const LOAN_TNC = ["cred-rqst-001", "cred-rqst-002", "cred-rqst-003"];

export const PREFERENCE_TYPE = {
  MONTHLY: "mensual",
  BI_WEEKLY: "quincenal",
};

export const OTHER_OPTION = "Otro";

export const MIN_PROCEDURE_COST = 2000;
export const STR_NA = "No especificado";
