import { FC, useEffect, useCallback, useState } from "react";

import SplashValidation from "../../components/sharedComponents/SplashValidation";
import getParams from "../../features/installmentLoan/utils/getParams";
import { useAppDispatch } from "../../store";
import {
  updateDoctorConfirmation,
  saveDoctorBankDetails,
  applicationDetailsThroughQR,
} from "../../store/slices/auth/actions";
import { API_STATUS } from "../../constants/common";
import { errorToast } from "../../utils/toast";
import confirmationBg from "../../assets/Icons/success-email.svg";
import ConfirmationView from "../../components/ConfirmationView";
import { DOCTOR_CONFIRMATION_STATUS } from "../../constants/common";

import Form from "./Form";
import useStyles from "./styles";

const ApplicationDetailPage: FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [viewDetails, setViewDetails] = useState<boolean>(false);
  const [doctorDetails, setDetails] = useState<any>(null);
  const { user_id, app_id } = getParams(["user_id", "app_id"]);
  // const appId = localStorage.getItem("application_id") || "";
  const css = useStyles();

  const onLoading = useCallback(async () => {
    const { data, status, meta } = await dispatch(
      applicationDetailsThroughQR({ user_id, appId: app_id })
    ).unwrap();
    if (status === API_STATUS.SUCCESS) {
      if (
        data?.status &&
        data?.status !== DOCTOR_CONFIRMATION_STATUS.NOT_SAVED &&
        data?.status !== DOCTOR_CONFIRMATION_STATUS.ALREADY_VERIFIED
      ) {
        setViewDetails(true);
      }
      setDetails(data);
      setLoading(false);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  }, [dispatch]);

  const saveData = async (payload) => {
    const isEdit =
      doctorDetails?.bank_name &&
      doctorDetails.number &&
      doctorDetails.payment_method;
    const { status, meta } = await dispatch(
      saveDoctorBankDetails({ ...payload, isEdit })
    ).unwrap();
    if (status === API_STATUS.SUCCESS) {
      setViewDetails(true);
    } else {
      errorToast(meta?.errorMessage);
    }
  };

  useEffect(() => {
    onLoading();
  }, [onLoading]);

  return loading ? (
    <SplashValidation image={2} trueRedirect={false} />
  ) : viewDetails ? (
    <ConfirmationView
      title="¡Muchas gracias!"
      content={
        <span className={css.contentWrapper}>
          Has confirmado con éxito la cita de{" "}
          <span className={css.link}>
            {doctorDetails?.first_name} {doctorDetails?.last_name}
          </span>{" "}
          para el próximo <br />
          <span className={css.link}>{doctorDetails?.appointment_date}</span> a
          las {"  "}
          <span className={css.link}>{doctorDetails?.appointment_time}</span>.
          <br />
          <br />
          Nos pondremos en contacto con el consultorio nuevamente en la fecha de
          la cita para confirmar que el procedimiento sea realizado y procesar
          el pago.
          <br />
          <br />
          Si la cita es cancelada o reagendada, o si tienes cualquier pregunta o
          comentario, por favor escríbenos a{" "}
          <a href="mailto:contacto@medsi.mx" className={css.link}>
            contacto@medsi.mx
          </a>
        </span>
      }
      imgSrc={confirmationBg}
    />
  ) : (
    <Form saveData={saveData} doctorDetails={doctorDetails} />
  );
};

export default ApplicationDetailPage;
