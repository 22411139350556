/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 *
 * Panel
 *
 */

import { ChevronRight } from '@mui/icons-material';
import { Box } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MoneyBag from '../../assets/MoneyBag.png';
import dollarImg from '../../assets/Icons/DollarOutlined.png';

const PanelLoan = ({
  title,
  body,
  open,
  status,
  amount,
  date,
  txn_timeStamp,
}) => {
  const [panelOpen, setPanelOpen] = useState(false);

  const [openVal, setOpenVal] = useState(open || panelOpen);

  const handlePanel = () => {
    setPanelOpen(!panelOpen);
    if (!open) {
      setOpenVal(!openVal);
    }
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div
        aria-controls='panel2a-content'
        id='panel2a-header'
        onClick={() => handlePanel()}
      >
        <Box
          sx={(theme) => ({
            background: 'rgba(53, 82, 204, 0.05)',
            padding: `10px 14px`,
            marginBottom: '8px',
            cursor: 'pointer',
            boxShadow: '0px 4px 4px rgba(53, 82, 204, 0.25)',
            textAlign: 'center',
            display: 'flex',
            [theme.breakpoints.down('laptop')]: { borderRadius: '10px' },
            [theme.breakpoints.down('lg')]: {
              paddingBottom: '1rem',
              borderRadius: '10px',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            },
          })}
        >
          <Box
            sx={(theme) => ({
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              borderRadius: '10px',
              marginRight: '20px',
              height: '24px',
              '& #dollar': { display: 'none' },
              [theme.breakpoints.up('laptop')]: {
                height: '60px',
                padding: '14px 25px',
              },
              [theme.breakpoints.down('lg')]: {
                '& img': { display: 'none' },
                '& #dollar': { display: 'block' },
                boxShadow: 'unset',
                marginRight: '20px',
                marginTop: '6px',
              },
              [theme.breakpoints.down('tablet')]: {
                '& img': { height: '16px', width: '16px' },
              },
            })}
          >
            <img alt='money bag' height='100%' src={MoneyBag} id='money_bag' />
            <img alt='' src={dollarImg} id='dollar' />
          </Box>
          <Box
            sx={(theme) => ({
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              [theme.breakpoints.down('laptop')]: {
                // flexDirection: 'column',
                // alignItems: 'flex-start',
              },
              [theme.breakpoints.down('tablet')]: {
                flexDirection: 'column',
                alignItems: 'flex-start',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                flex: 1,
                [theme.breakpoints.down('laptop')]: {
                  // maxWidth: 350,
                  // width: '100%',
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  fontFamily: 'SFUIText-bold',
                  textAlign: 'left',
                  fontSize: '0.875rem',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: 'Urbanist-Bold',
                    fontSize: '14px',
                  },
                })}
              >
                {title}
              </Box>
              <Box
                sx={(theme) => ({
                  color: '#7F8192',
                  textAlign: 'left',
                  fontSize: '0.875rem',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: 'Urbanist-Semibold',
                    fontSize: '14px',
                  },
                })}
              >
                Tomado en{' '}
                {moment(txn_timeStamp || date).format('MMMM DD, YYYY')}
              </Box>
            </Box>

            <Box
              sx={(theme) => ({
                color: 'green',
                [theme.breakpoints.down('laptop')]: {
                  // marginTop: 2,
                  // display: 'flex',
                  // maxWidth: '350px',
                  // width: '100%',
                  // flexDirection: 'row-reverse',
                },
                [theme.breakpoints.down('tablet')]: {
                  position: 'relative',
                  width: '100%',
                  marginTop: '10px',
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  color: '#000000',
                  fontFamily: 'SFUIText-bold',
                  fontSize: '14px',
                  flex: 1,
                  textAlign: 'right',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: 'Urbanist-Bold',
                    fontSize: '14px',
                  },
                  [theme.breakpoints.down('tablet')]: {
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                  },
                })}
              >
                ${amount.toLocaleString()}
              </Box>
              <Box
                sx={(theme) => ({
                  fontSize: '14px',
                  textAlign: 'left',
                  [theme.breakpoints.down('lg')]: {
                    fontFamily: 'Urbanist-Bold',
                    marginTop: '10px',
                  },
                })}
              >
                {status}
              </Box>
            </Box>
          </Box>
          {/* <span
            className={`transition-transform flex px-4 py-2 ${
              openVal ? "transform rotate-180" : ""
            }`}
          > */}
          <ChevronRight
            style={{
              color: 'black',
              transform: openVal ? 'rotate(90deg)' : '',
              transition: 'all 0.3s ease-in-out',
              alignSelf: 'center',
              marginLeft: 24,
            }}
          />{' '}
          {/* <ExpandMoreIcon /> */}
          {/* </span> */}
        </Box>
      </div>
      <div
        style={{
          overflow: 'auto',
          transition: 'all 0.3s ease-in-out',
          maxHeight: openVal ? '100vh' : '0',
          top: -9,
          position: 'relative',
          zIndex: 20,
        }}
      >
        <Box
          sx={(theme) => ({
            padding: '2rem 0.8rem 5px',
            [theme.breakpoints.up('lg')]: {
              padding: '50px 20px',
              border: '1px solid #E6E6E6',
            },
          })}
        >
          {body}
        </Box>
      </div>
    </div>
  );
};

PanelLoan.propTypes = {
  title: PropTypes.any.isRequired,
  body: PropTypes.any.isRequired,
  open: PropTypes.bool,
};

export default PanelLoan;
