const v1 = "v1";

export const coSigner = {
  checkReferenceCode: "/v1/check_reference_code/?code=",
  applicationSummary: "/v1/cosigner/application-summary/",
  curpRegistration: "/v1/cosigner/curp_registration/",
  additionalInfo: "/v1/cosigner/additional_info/",
  finalInfo: "/v1/cosigner/final_info/",
  identifyCosignerUnboardingSteps: "/v1/identify_cosigner_unboarding_steps/",
  getZipCodeInfo: `${v1}/cosigner/get-info/`,
  updatePhonenumber: `${v1}/cosigner/phone_number/`,
  agreement: `${v1}/application/get_co_signer_agreement`,
  saveCosignerSignatures: `${v1}/application/save_cosigner_signature/`,
};

export default coSigner;
