import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const newsCardContainerStyles = makeStyles((theme: Theme) => ({
  cardNewTitle: {
    color: customColor.obsidian,
    fontSize: "15px !important",
    fontFamily: "SFUIText-Bold !important",
    fontWeight:800,
    [theme.breakpoints.down(601)]: {
      fontSize: 32,
    },
  },
  cardNewTitleSpan: {
    ...theme.typography.subtitle2,
    color: "rgba(0, 0, 0, 0.5)",
  },
  cardNewSubtitle: {
    color: customColor.obsidian,
    fontSize: "16px !important",
    fontFamily: "SFUIText-Bold !important",
    fontWeight:800,
    [theme.breakpoints.down(601)]: {
      fontSize: 32,
    },
  },
  cardNewBlogDescription: {
    color: customColor.obsidian,
    fontSize: "14px !important",
    fontFamily: "SFUIText-Regular !important",
    [theme.breakpoints.down(601)]: {
      fontSize: 32,
    },
  },
  cardContent: {
    marginBottom: 10,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: "0.3em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      outline: "1px solid slategrey",
      borderRadius: 10,
    },
  },
  articleHeader: {
    marginTop: "15px !important",
    fontFamily: "SFUIText-Bold !important",
    fontWeight:800,
  },
}));

export default newsCardContainerStyles;
