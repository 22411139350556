import { makeStyles } from "@mui/styles";
import { customColor } from "../../../../theme/customColor";

// import images from '@constants/images';
// const { paymentHeader, disbursementHeader, paperCut } = images;

interface styleProps {
  isAndroid?: boolean;
  isDisbursement?: boolean;
}

const useStyles = makeStyles(() => ({
  header: {
    width: '100%',
    padding: 16,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  backIcon: {
    fontSize: '32px !important',
    color: customColor.accentBlue,
    cursor: 'pointer'
  },
  receiptWrapper: {
    marginTop: -30,
    background: customColor.cotton,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    paddingBottom: 24,
    position: 'relative',
    '&::before': {
      content: '""',
      display: 'block',
      // background: `url(${paperCut})`,
      position: 'absolute',
      width: '100%',
      height: 15,
      bottom: 0,
    },
  },
  amount: {
    fontSize: 32,
    color: customColor.charcoal,
    alignItems: 'center',
  },
  creditTitle: {
    fontSize: 24,
    margin: 0,
    lineHeight: '28px',
  },
  gridWrapper: {
    borderBottom: `solid 1px ${customColor.stone}`,
    padding: 16,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  disbursementSent: {
    background: customColor.pearl,
    color: customColor.dolphin,
    padding: '8px 16px',
    borderBottom: `solid 1px ${customColor.stone}`,
  },
  light: {
    color: customColor.shark,
  },
  iconWrapper: {
    position: 'absolute',
    top: -24,
    right: 24,
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    background: customColor.stone,
  },
}));

export default useStyles;
