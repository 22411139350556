import { FC } from "react";
import { IconProps, defaultIconColor } from "./types";

const EnvelopeIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.993164 7.50781L5.53317 22.397L29.128 15.1982L24.5908 0.314907L0.993164 7.50781ZM15.5752 13.0435L2.76367 7.8442L23.3037 1.57861L15.5752 13.0435ZM11.7129 12.3765L5.95749 20.9145L2.17236 8.5054L11.7129 12.3765ZM12.5015 12.6958L15.8877 14.0703L17.9322 11.0415L27.3555 14.8696L6.81638 21.1333L12.5015 12.6958ZM18.4107 10.3296L24.1655 1.79541L27.9483 14.2085L18.4107 10.3296Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

EnvelopeIcon.defaultProps = {
  fill: defaultIconColor,
};

export default EnvelopeIcon;
