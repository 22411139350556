import { makeStyles } from "@mui/styles";
import { customColor } from "../../../theme/customColor";

const styles = makeStyles((theme) => ({
  container: {
    background: "linear-gradient(180deg, #5C8285 0%, #3552CC 48.4%)",
    borderRadius: 30,
    width: "max-content",
    // marginLeft: 100,
    [theme.breakpoints.down(601)]: {
      width: "92vw",
      margin: "0 auto",
      marginTop: 130,
    },
  },
  title: {
    fontFamily: "SFUIText-Bold",
    fontSize: 25,
    color: "#fff",
    [theme.breakpoints.down(601)]: {
      fontSize: 18,
    },
  },
  label: {
    fontSize: 18,
    fontFamily: "SFUIText-Bold",
    color: "#fff",
    margin: "5px 0",
    [theme.breakpoints.down(601)]: {
      fontSize: 16,
    },
  },
  inputContainer: {
    marginTop: 24,
  },
  valueContainer: {
    background:
      "linear-gradient(180deg, rgba(92, 130, 133, 0.6) 0%, rgba(53, 82, 204, 0.6) 48.4%)",
  },
  calcTitle: {
    fontFamily: "SFUIText-Bold",
    fontSize: 18,
    color: "#fff",
  },
  calcValue: {
    fontFamily: "SFUIText-Bold",
    fontSize: 25,
    color: "#fff",
    marginTop: "0em",
    marginBottom: "0em",
    [theme.breakpoints.down(601)]: {
      fontSize: 16,
    },
  },
  select: {
    border: "1px solid #FFF",
    borderRadius: 8,
    padding: "0px 16px",
    width: "100%",
    height: "40px",
    backgroundColor: "transparent",
    color: "#fff",
    fontSize: 16,
    lineHeight: 1.8,
    WebkitAppearance: "none",
    backgroundImage:
      "linear-gradient(45deg, transparent 50%, white 50%), linear-gradient(135deg, white 50%, transparent 50%), linear-gradient(to right, transparent, transparent)",
    backgroundPosition:
      "calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em",
    backgroundSize: "5px 5px, 5px 5px, 1px 1.5em",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down(601)]: {
      fontSize: 13,
      height: "34px",
      appearance: "none",
      "&::-ms-expand": {
        display: "none",
      },
    },
  },
  input: {
    background: "transparent",
    border: "1px solid #fff",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    height: "40px",
    padding: "0px 20px",
    width: "274px",
    fontSize: "18px",
    lineHeight: "1.2",

    color: customColor.cotton,
    "&::placeholder": {
      color: customColor.cotton,
    },
    [theme.breakpoints.down(601)]: {
      width: "70%",
      height: "34px",
      fontSize: 14,
    },
  },
  inputCurrency: {
    position: "absolute",
    left: 4,
    color: customColor.cotton,
    height: "100%",
    fontSize: 18,
    top: 0,
    display: "flex",
    alignItems: "center",
  },
  formControl: {
    width: "274px",
    color: customColor.cotton,
    borderRadius: "8px",
    "& fieldset": {
      display: 'none'
    },
    "& input": {
      background: "transparent",
      border: "1px solid #fff",
      boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px",
      height: "40px",
      padding: "0px 20px",
      fontSize: "18px",
      lineHeight: "1.2",
      color: customColor.cotton,
      "&::placeholder": {
        color: customColor.cotton,
      },
      [theme.breakpoints.down(601)]: {
        width: "70%",
        height: "34px",
        fontSize: 14,
      },
    },
    
  },
}));

export default styles;
