import { makeStyles } from "@mui/styles";

const LogInReportStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='1925' height='1076' viewBox='0 0 1925 1076' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1925 0H0V1076H1925V0Z' fill='%233552CC'/%3E%3Cpath d='M0 625.375C0 625.375 896.168 1251.77 1925 577.088V0H0.190683L0 625.375Z' fill='url(%23paint0_linear_517_318)'/%3E%3Cpath opacity='0.24' d='M1569 1074.85C1569 1074.85 1761.53 926.962 1924.74 875V1075.84L1569 1074.85Z' fill='%23ABCED4'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_517_318' x1='324.704' y1='-323.897' x2='1573.82' y2='927.599' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23839DBE'/%3E%3Cstop offset='0.1262' stop-color='%236F8ACD'/%3E%3Cstop offset='0.3409' stop-color='%235470E2'/%3E%3Cstop offset='0.5299' stop-color='%234361EF'/%3E%3Cstop offset='0.672' stop-color='%233D5BF4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='768' height='1024' viewBox='0 0 768 1024' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M768 0H0V1024H768V0Z' fill='%233552CC'/%3E%3Cpath opacity='0.24' d='M465 1023.16C465 1023.16 628.983 897.242 768 853V1024L465 1023.16Z' fill='%23ABCED4'/%3E%3Cpath d='M2.64421 478.72C2.64421 478.72 320.311 719.928 768 628.18V0H0.691569C0.691569 0 -1.78842 478.72 2.64421 478.72Z' fill='url(%23paint0_linear_517_304)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_517_304' x1='74.512' y1='-71.9161' x2='771.839' y2='624.337' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23839DBE'/%3E%3Cstop offset='0.1262' stop-color='%236F8ACD'/%3E%3Cstop offset='0.3409' stop-color='%235470E2'/%3E%3Cstop offset='0.5299' stop-color='%234361EF'/%3E%3Cstop offset='0.672' stop-color='%233D5BF4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='414' height='735' viewBox='0 0 414 735' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M414 3H1V735H414V3Z' fill='%233552CC'/%3E%3Cpath opacity='0.24' d='M272 734.612C272 734.612 348.85 676.439 414 656V735L272 734.612Z' fill='%23ABCED4'/%3E%3Cpath d='M2.05134 480.509C2.05134 480.509 173.034 610.11 413.999 560.814V0H1C1 0 -0.334459 480.509 2.05134 480.509Z' fill='url(%23paint0_linear_517_311)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_517_311' x1='-15.0996' y1='17.1805' x2='471.221' y2='503.604' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23839DBE'/%3E%3Cstop offset='0.1262' stop-color='%236F8ACD'/%3E%3Cstop offset='0.3409' stop-color='%235470E2'/%3E%3Cstop offset='0.5299' stop-color='%234361EF'/%3E%3Cstop offset='0.672' stop-color='%233D5BF4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A")`,
    },
  },
  logo: {
    height: 90,
    marginTop: 50,
  },
  boxStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    marginTop: "50px !important",
    fontSize: "28px !important",
    fontWeight: "800 !important",
    color: theme.palette.primary.white + " !important",
  },

  input: {
    color: theme.palette.primary.white + " !important",
    borderColor: "red !important",
  },
  textCopyright: {
    color: theme.palette.primary.white + "",
    marginTop: "64px !important",
    marginBottom: "32px !important",
  },
  button: {
    marginTop: "24px !important", 
    marginBotton: "16px !important",
    background: theme.palette.primary.buttonUnselected,
    borderRadius: "10px !important",
  }
}));

export default LogInReportStyles;
