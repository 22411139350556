import { Box, Grid } from "@mui/material";

import useStyles from "./styles";
import RightContent from "./RightContent";
import LeftContent from "./LeftContent";
import FormContent from "./Form";

export const Footer = ({ isForm = false }) => {
  const css = useStyles();

  return (
    <Box className={css.container}>
      {isForm && <FormContent />}
      <Grid container>
        <Grid item md={4} xs={12}>
          <LeftContent />
        </Grid>
        <Grid item md={4} xs={12} />
        <Grid item md={4} xs={12}>
          <RightContent />
        </Grid>
      </Grid>
      <Box className={css.content}>
        <Box my={2.5} className={css.line} />
        <Box>
          Medsi es una marca en proceso de registro. El uso de este sitio
          implica la aceptación de los Términos y Condiciones, así como del
          Aviso de Privacidad de Munbrunn Sociedad Anónima de Capital Variable.
        </Box>
        <Box mt={2}>
          Munbrunn S.A. de C.V. para su constitución y operación no requiere
          autorización de la Secretaría de Hacienda y Crédito Público ni está
          sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores.
        </Box>
        <Box mt={4}>
          ©medsi {new Date().getFullYear()} Todos los derechos reservados.
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
