import { Helmet } from "react-helmet";
import Header from "../../../components/header";
import SideBar from "../../../components/sidebar";
import TwoColumns from "../../../components/twoColumns";
import useStyles from "../ApplicationStatus/styles";

import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import {
  selectLmsBalanceSummary,
  selectLmsUserAccessToken,
  selectLoanList,
} from "../../../store/slices/users";
import {
  getLoanDetailList,
  getUserAccessToken,
} from "../../../store/slices/users/actions";

import { useHistory } from "react-router-dom";

import { lmsApi } from "../../../apis/api";
import PanelLoan from "../../../components/PanelLoan";
import { LOAN_HISTORY_STATUS, URBANIST_FONT } from "../../../constants/common";
import Loans from "./Loans";
import { currencyFormat } from "../../../utils/index";

const LoanAll = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [application_id, setAppId] = useState();
  const [open, setOpen] = useState(false);
  const css = useStyles();
  const [loanType, setLoanType] = useState("active");
  const urlId = window.location.search.slice(1, window.location.search.length);
  const lmsUserAccessToken = useSelector(selectLmsUserAccessToken);

  const [showSidebar, setShowSideBar] = useState(false);

  const handleOpen = (id) => {
    setAppId(id);
    setOpen(!open);
  };
  useEffect(() => {
    try {
      dispatch(getUserAccessToken());
    } catch (err) {}
  }, []);
  useEffect(() => {
    if (lmsUserAccessToken) {
      lmsApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${lmsUserAccessToken}`;
    }
  }, [lmsUserAccessToken]);
  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);
  useEffect(() => {
    if (lmsUserAccessToken) {
      setTimeout(() => {
        dispatch(
          getLoanDetailList({
            cl_id:
              urlId ||
              LmsBalanceSummary?.cl_id ||
              localStorage.getItem("cl_id"),
            totalShow: 10,
            loanType: loanType,
          })
        );
      }, 200);
    }
  }, [loanType, lmsUserAccessToken]);

  const handleActive = () => {
    setLoanType("active");
  };

  const handleClose = () => {
    setLoanType("closed");
  };

  const loanHistory = useSelector(selectLoanList);

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Helmet>
            <title>Historial de préstamos</title>
          </Helmet>

          <Box>
            <Header isBorderBottom={false} setShowSideBar={setShowSideBar} />
          </Box>

          <Box
            sx={(theme) => ({
              color: "#6B7779",
              fontSize: "24px",
              fontFamily: "SFUIText-bold",
              marginTop: "19px",
              marginLeft: "44px",
              marginBottom: "33px",
              [theme.breakpoints.down("lg")]: {
                fontSize: "18px",
                fontFamily: URBANIST_FONT.BOLD,
                marginLeft: "95px",
                marginTop: "1.5rem",
              },
              [theme.breakpoints.down("tablet")]: { marginLeft: "35px" },
            })}
          >
            Historial de Préstamos
          </Box>

          <Box
            sx={(theme) => ({
              display: "flex",
              textAlign: "center",
              padding: "0px 44px",
              [theme.breakpoints.down("lg")]: {
                fontFamily: URBANIST_FONT.SEMIBOLD,
              },
              [theme.breakpoints.down("laptop")]: { p: 0 },
            })}
          >
            <Box
              sx={(theme) => ({
                flex: 1,
                background: loanType === "active" ? "#3552CC" : "#E6E6E6",
                color: loanType === "active" ? "#FFFFFF" : "#3552CC",
                padding: "16px 0px",
                [theme.breakpoints.up("laptop")]: {
                  borderRadius: "5px 0px 0px 5px",
                },
              })}
              onClick={handleActive}
            >
              Préstamos Activos
            </Box>
            <Box
              sx={(theme) => ({
                flex: 1,
                background: loanType == "closed" ? "#3552CC" : "#E6E6E6",
                color: loanType === "closed" ? "#FFFFFF" : "#3552CC",
                padding: "16px 0px",
                [theme.breakpoints.up("laptop")]: {
                  borderRadius: "5px 0px 0px 5px",
                },
              })}
              onClick={handleClose}
            >
              Préstamos Cerrados
            </Box>
          </Box>

          <Box
            sx={(theme) => ({
              [theme.breakpoints.down("tablet")]: {
                mx: "20px",
              },
              [theme.breakpoints.down("340")]: {
                mx: 0,
              },
              marginTop: "10px",
              mx: "44px",
            })}
          >
            {loanHistory?.results?.map((each) => (
              <PanelLoan
                key={`application-id-${each.application_id}`}
                title={`ID de Solicitud de Préstamo #${each.lp_id
                  .split("-")
                  .pop()}`}
                body={
                  <Loans
                    each={each}
                    LmsBalanceSummary={LmsBalanceSummary}
                    applicationId={each.application_id || ""}
                  />
                }
                amount={currencyFormat(each.req_amt)}
                date={each.created_at}
                status={LOAN_HISTORY_STATUS[each.status]}
              />
            ))}
            {loanHistory?.results?.length === 0 && (
              <div style={{ textAlign: "center" }}> Datos no disponibles</div>
            )}
          </Box>
        </>
      }
      rightBg
    />
  );
};

export default LoanAll;
