import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    marginTop: "5px !important",
    marginBottom: "10px !important",
  },
}));

export default useStyles;
