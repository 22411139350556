import { Box, TextField, TextareaAutosize, Typography } from "@mui/material";
import { useFormik } from "formik";

import { emailRegex } from "../../../utils/validations";
import { saveUserFeedBack } from "../../../store/slices/auth/actions";
import ButtonComponent from "../../../components/sharedComponents/ButtonComponent";
import { errorToast, successToast } from "../../../utils/toast";
import { API_STATUS } from "../../../constants/common";
import { useAppDispatch } from "../../../store";

import useStyles from "./styles";

export const Footer = () => {
  const css = useStyles();
  const dispatch = useAppDispatch();

  const initialValues = {
    name: "",
    email: "",
    message: "",
  };
  const validate = (values) => {
    !values.name
      ? (errors.name = "Este campo es requerido")
      : values.name.length < 3
      ? (errors.name = "mas de 2 caracters")
      : delete errors.name;
    !values.message
      ? (errors.message = "Este campo es requerido")
      : values.message.length < 10
      ? (errors.message = "mas de 10 caracters")
      : delete errors.message;
    !values.email || !emailRegex.test(values.email)
      ? (errors.email = "Ingresa un correo válido")
      : delete errors.email;
  };

  //formik initial state
  const formik = useFormik({
    initialValues,
    validate,
    validateOnChange: true,
    onSubmit: async (values) => {
      const res = await dispatch(saveUserFeedBack(values)).unwrap();
      if (res?.status === API_STATUS.SUCCESS) {
        successToast("Gracias, su solicitud ha sido presentada.");
      } else if (res?.status === API_STATUS.ERROR) {
        errorToast(res?.meta?.errorMessage);
      }
    },
  });

  const { errors, values, handleSubmit } = formik;

  return (
    <Box className={css.formContainer}>
      <form onSubmit={handleSubmit}>
        <Box mb={4}>
          <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 30 }}>
            {" "}
            ¡Manten protegida la salud de tu familia! Déjanos tus datos para
            ayudarte a recibir la atención que mereces.
          </Typography>
        </Box>
        <Box>
          <Box variant="h5" ml={3} className={css.headerInput}>
            Nombre*
          </Box>
          <TextField
            className={css.input}
            id="name"
            name="name"
            size="small"
            autoComplete="off"
            value={values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Box>
        <Box>
          <Box variant="h5" ml={3} className={css.headerInput}>
            Correo electrónico*
          </Box>
          <TextField
            className={css.input}
            id="email"
            name="email"
            size="small"
            autoComplete="off"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Box>
        <Box>
          <Box variant="h5" ml={3} className={css.headerInput}>
            Mensaje*
          </Box>
          <TextareaAutosize
            className={css.textArea}
            id="message"
            name="message"
            size="small"
            autoComplete="off"
            value={formik.values.message}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            minRows={6}
          />
        </Box>
        <ButtonComponent type="submit" text="Enviar" className={css.button} />
      </form>
    </Box>
  );
};

export default Footer;
