import { FC } from "react";

import { IconProps, obsidian } from "./types";

const InfoIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48 8C25.92 8 8 25.92 8 48C8 70.08 25.92 88 48 88C70.08 88 88 70.08 88 48C88 25.92 70.08 8 48 8ZM52 68H44V44H52V68ZM52 36H44V28H52V36Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

InfoIcon.defaultProps = {
  fill: obsidian,
};

export default InfoIcon;
