import { useEffect, useState } from "react";
import { Grid } from "@mui/material";

import InputText from "../../../components/sharedComponents/InputText";
import DropdownMenu from "../../../components/sharedComponents/DropdownMenu";
import InputPhone from "../../../components/sharedComponents/InputPhone/indexV2";
import useStore from "../../../store/getStore";
import data from "../../../utils/static/data";
import { getDoctorList } from "../../../store/slices/onboarding/actions";
import { useAppDispatch } from "../../../store";
import {
  doctorListOption,
  fillClinicValues,
  isOtherShowClinic,
  isOtherShowDoctor,
} from "../../common/ProcedureForm/helpers";
import { RenderTextInput } from "../../../containers/ProcessInformation/v2/FormContainer/HelperComponent";
import useStyles from "../styles";

const RenderDoctorDetails = ({
  formik,
  hasNotDoctor,
  responseData,
  disabled,
}) => {
  const css = useStyles();
  const { onboarding } = useStore();
  const dispatch = useAppDispatch();
  const isDr = Boolean(responseData?.doc_email || responseData?.doc_phone);
  const [isFirstDoctorDetails, setIsFirstDoctorDetails] = useState(isDr);

  const { branchList, doctorList } = onboarding;

  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    formik;

  const drList = doctorListOption(doctorList);
  const isBranchDrShow = branchList.length > 0 && values.clinicBranch;

  const isOtherClinic = isOtherShowClinic({
    values,
  });

  const isShowDrInput =
    isOtherShowDoctor({
      values,
    }) || isOtherClinic;

  const isDrOtherFilled =
    values.medicName && values.medicLastName && !values.clinicName;

  useEffect(() => {
    dispatch(getDoctorList());
  }, []);

  // get clinic list based on doctor selection
  useEffect(() => {
    if (values?.clinicDr) {
      if (!isFirstDoctorDetails) {
        fillClinicValues({
          values,
          doctorList,
          errors,
          responseData,
        });
      } else {
        setIsFirstDoctorDetails(false);
      }
    }
  }, [values?.clinicDr]);

  const renderInput = ({ label, value, disabled = false }) => {
    return (
      <InputText
        stylesInput={css.formControl}
        label={label}
        value={values[value]}
        name={value}
        handleChange={handleChange}
        errors={errors[value]}
        touched={touched[value]}
        handleBlur={handleBlur}
        disabled={disabled}
      />
    );
  };

  const renderDropDown = ({ label, value, dataObj, disabled = false }) => {
    return (
      <DropdownMenu
        label={label}
        optionsObject={dataObj}
        name={value}
        handleChange={handleChange}
        value={values[value]}
        onBlur={handleBlur}
        errors={errors[value]}
        touched={touched[value]}
        disabled={disabled}
      />
    );
  };

  return (
    <>
      {!isShowDrInput && (
        <Grid item xs={12} md={6}>
          <div className="dropdownFix">
            {!isShowDrInput &&
              !isOtherClinic &&
              !isBranchDrShow &&
              !isDrOtherFilled &&
              renderDropDown({
                label: "Nombre(s) del doctor",
                value: "clinicDr",
                dataObj: drList,
                disabled: hasNotDoctor || isBranchDrShow || disabled,
              })}

            {isBranchDrShow &&
              renderInput({
                label: "Nombre(s) del doctor",
                value: "clinicDr",
                disabled: hasNotDoctor || isBranchDrShow || disabled,
              })}
          </div>
        </Grid>
      )}

      {isShowDrInput && (
        <>
          <Grid item md={6} xs={12}>
            <RenderTextInput
              label="Nombre(s) del doctor"
              value="medicName"
              disabled={hasNotDoctor || isBranchDrShow || disabled}
              formik={formik}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <RenderTextInput
              label="Apellido(s) del doctor"
              value="medicLastName"
              disabled={hasNotDoctor || isBranchDrShow || disabled}
              formik={formik}
            />
          </Grid>
        </>
      )}
      {/* {isShowDrInput && (
          <Grid item xs={12} md={6}>
            {renderInput({
              label: "Nombre(s) del doctor",
              value: "medicName",
              disabled: hasNotDoctor || isBranchDrShow,
            })}
          </Grid>
        )} */}
      <Grid item xs={12} md={6}>
        {renderInput({
          label: "Correo electrónico del doctor",
          value: "medicEmail",
          disabled: hasNotDoctor || isBranchDrShow || disabled,
        })}
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="telInputOld">
          <InputPhone
            stylesInput={css.formControl}
            label="Número de teléfono del consulto"
            country="mx"
            name="medicPhone"
            handleChange={(e) => {
              setFieldValue("medicPhone", e);
            }}
            handleBlur={() => formik.setFieldTouched("medicPhone", true)}
            value={values.medicPhone}
            errors={touched.medicPhone && errors.medicPhone}
            touched={touched.medicPhone}
            disabled={hasNotDoctor || isBranchDrShow || disabled}
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        {renderDropDown({
          label: "Relación con el médico",
          value: "medicRelation",
          dataObj: data.medicRelation,
          disabled: hasNotDoctor || disabled,
        })}
      </Grid>
    </>
  );
};

export default RenderDoctorDetails;
