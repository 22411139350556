import axios from "axios";
import createApiInstance from "./createApiInstance";
import urls from "../config/url";

export const endPoint = `${urls.baseUrl}/api`;
export const dashboardEndPoint = `${urls.lmsUrl}/`;

const api = createApiInstance(endPoint, "Medsi");

export const lmsApi = axios.create({
  baseURL: dashboardEndPoint,
  timeout: 3000,
});

export default api.instance;
