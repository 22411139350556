import { URBANIST_FONT } from "../../../constants/common";
import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: 1,
    // marginTop: theme.spacing(4),
    // height: "max-content",
    height: "100%",
    width: "100%",
    textAlign: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
    [theme.breakpoints.down("sm")]: {
      padding: `${theme.spacing(4)} ${theme.spacing(4)}`,
    },
    background: "rgba(255, 255, 255, 0.1)",
    alignItems: "center",
    borderRadius: theme.spacing(1),
    "& > .row": {
      display: "flex",
      width: "100%",
      marginTop: theme.spacing(0.5),
      justifyContent: "space-between",
      columnGap: "30px",
      // alignItems: "center",
      wordWrap: "break-word",

      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
      },
      flexWrap: "wrap",
      "& > .title": {
        // color: customColor.cotton,
        color: customColor.caribbeanGreen,
        fontSize: 22,
        fontFamily: URBANIST_FONT.MEDIUM,
        width: "40%",
        textAlign: "left",
        flexGrow: 1,
      },
      "& > .desc": {
        color: customColor.cotton,
        fontSize: 22,
        fontFamily: URBANIST_FONT.SEMIBOLD,
        width: "50%",
        textAlign: "left",
      },
    },
  },
}));

export default useStyles;
