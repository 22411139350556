import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { customTheme } from '../../../features/installmentLoan/theme';

const useStyles = makeStyles((theme: Theme) => ({
  rightContainer: {
    padding: '14px 0',
  },

  creditContent: {
    marginLeft: 44,
    marginRight: 66,
    marginBottom: 36,
  },

  stepperCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    borderRadius: '50%',
    background: '#F0F0F0',
    boxShadow: 'inset 0px 4px 4px rgba(0, 0, 0, 0.25)',
  },

  stepperCircleInActive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    borderRadius: '50%',
    background: '#F1A720',
  },

  stepperCircleActive: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: '#3552CC',
    color: 'white',
  },

  stepperCircleInside: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 12,
    height: 12,
    borderRadius: '50%',
    background: '#F1A720',
    color: '#F1A720',
  },

  balanceSummaryCont: {
    borderBottom: `1px solid ${customTheme.borderGrey}`,
    background: 'rgba(53, 82, 204, 0.05)',
    borderRadius: '10px',
    padding: `17px 14px`,
    marginBottom: 8,
    cursor: 'pointer',
    boxShadow: '0px 4px 4px rgba(53, 82, 204, 0.25)',
  },

  applicationText: {
    color: '#3552CC',
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '24px',
    margin: '0px',
    [theme.breakpoints.down('lg')]: { fontFamily: 'Urbanist-Bold' },
    [theme.breakpoints.down('tablet')]: { fontSize: '18px !important' },
  },
  showAll: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '21px',
    textDecorationLine: 'underline',
    color: '#3552CC',
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Semibold',
      fontSize: '16px',
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '14px !important' },
  },
  continueButton: {
    background: '#3552CC',
    borderRadius: '10px',
    padding: '8px 40px',
    color: '#FFFFFF',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Semibold',
      fontSize: '16px',
      borderRadius: '30px',
      padding: '16px 30px',
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: '14px',
      padding: '10px 20px',
    },
  },
  transId: {
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '24px',
    color: '#3F3D56',
    margin: '0px',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Medium',
      fontSize: '14px',
    },
    [theme.breakpoints.down('tablet')]: {
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  transDate: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#7F8192',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Medium',
      fontSize: '14px',
      fontWeight: 500,
    },
  },
  transAmt: {
    fontSize: '18px',
    color: '#151940',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Medium',
      fontSize: '14px',
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '12px' },
  },
  transStatus: {
    fontSize: 16,
    fontWeight: 700,
    color: '#00B63E',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Medium',
      fontSize: '14px',
      fontWeight: 500,
    },
    [theme.breakpoints.down('tablet')]: { fontSize: '12px' },
  },
}));

export default useStyles;
