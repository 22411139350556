import React from "react";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import OdontologyStyles from "./Odontology.styles";
import odontology from "../../../../assets/procedureType/OrtodonciaIcon.svg";
const Odontology = () => {
  const classes = OdontologyStyles();
  return (
    <Box
      sx={{
        background: "#FFFFFF",
        boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
        borderBottomLeftRadius: `20px`,
        borderBottomRightRadius: `20px`,
        paddingBottom: 10,
        marginTop: 5,
        marginBottom: 10,
      }}
    >
      <Typography
        sx={{
          fontFamily: "Urbanist-SemiBold",
          fontSize: 28,
          color: "rgba(0, 0, 0, 0.5)",
          marginLeft: 18,
          mt: 5,
        }}
      >
        Odontología cosmética y funcional
      </Typography>
      <Grid container className={classes.tableView}>
        <Grid item sm={12} md={2} lg={2}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ marginTop: -3 }}
          >
            <img
              src={odontology}
              alt="odontology-icn"
              style={{ marginLeft: "-220px", width: 80, height: 80 }}
            />
            <Typography
              sx={{
                marginLeft: 3,
                fontFamily: "Urbanist-Bold",
                fontSize: 20,
              }}
            >
              Endodoncia
            </Typography>
            <br />
          </Box>
          <Box
            sx={{
              marginLeft: -4,
              marginTop: "-10px",
            }}
          >
            <Typography
              sx={{ fontFamily: "Urbanist-Medium", fontSize: 20, mt: "-37px" }}
            >
              Endodoncia
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Cirugía apical
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={2} lg={2}>
          <Box>Periodoncia</Box>
          <Box>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Limpieza dental
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Implantes dentales
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Gingivectomía (recorte de encías)
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={2} lg={2}>
          <Box>Ortodoncia</Box>
          <Typography
            sx={{
              fontFamily: "Urbanist-Medium",
              fontSize: 20,
              marginTop: "25px",
            }}
          >
            Ortodoncia con brackets Invisalign
          </Typography>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
          <Box>Prótesis y estética dental</Box>
          <Box>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Blanqueamiento dental
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Resinas estéticas
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Carillas dentales
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Coronas estéticas
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={12} md={3} lg={3}>
          <Box>Cirugía bucal y maxilofacial</Box>
          <Box>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Extracción de terceros molares
            </Typography>
            <Typography sx={{ fontFamily: "Urbanist-Medium", fontSize: 20 }}>
              Extracción dental
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Odontology;
