import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { display } from '@mui/system';
import { URBANIST_FONT } from '../../constants/common';
import { customColor } from '../../theme/customColor';

export interface IProps {
  isBorderBottom: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    box: {
      width: '100%',
      padding: `12px 44px`,
      borderBottom: (props: IProps) => {
        return props?.isBorderBottom ? '1px solid rgba(0, 0, 0, 0.1)' : 'none';
      },
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',

      [theme.breakpoints.down('xl')]: {
        flexDirection: 'column-reverse',
        rowGap: '32px',
        padding: `12px 28px`,
      },
    },
    h6: {
      color: customColor.shark,
      fontSize: '20px !important',
      lineHeight: '1.2 !important',
      fontWeight: 'bold !important',
      fontStyle: 'normal !important',
      marginLeft: '5px !important',
      [theme.breakpoints.down('lg')]: {
        fontFamily: `${URBANIST_FONT.BOLD} !important`,
        fontSize: '22px !important',
      },
      [theme.breakpoints.down('tablet')]: { fontSize: '20px !important' },
      // [theme.breakpoints.down('sm')]: {
      //   display: 'inline-block',
      //   fontSize: '22px !important',
      //   fontFamily: URBANIST_FONT.BOLD,
      // },
    },
    h4: {
      color: customColor.obsidian,
      fontSize: '28px !important',
      lineHeight: '1.2 !important',
      fontWeight: '600 !important',
      fontStyle: 'normal !important',
      [theme.breakpoints.down('lg')]: {
        fontFamily: `${URBANIST_FONT.BOLD} !important`,
        fontSize: '22px !important',
      },
      [theme.breakpoints.down('tablet')]: { fontSize: '20px !important' },
      // [theme.breakpoints.down('xl')]: {
      //   display: 'inline-block',
      //   fontSize: '22px !important',
      //   fontFamily: URBANIST_FONT.BOLD,
      // },
    },
    notificationContainer: {
      padding: '0 10px',
      display: 'flex',
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.down('xl')]: {
        padding: 0,
      },
    },
    tooltipContainer: {
      [theme.breakpoints.down('sm')]: {},
    },
    logout: {
      fontSize: '40px !important',
      color: customColor.accentBlue,
      cursor: 'pointer',
      [theme.breakpoints.down('xl')]: {
        fontSize: '24px !important',
      },
    },
    notification: {
      fontSize: '40px !important',
      height: 35,
      width: 35,
      [theme.breakpoints.down('xl')]: {
        height: 20,
        width: 20,
      },
    },
  })
);

export default useStyles;
