import { FC, useState, useEffect, useCallback } from "react";
// import { useHistory } from "react-router-dom";

import SplashValidation from "../../components/sharedComponents/SplashValidation";
import getParams from "../../features/installmentLoan/utils/getParams";
import { useAppDispatch } from "../../store";
import { emailVerify } from "../../store/slices/auth";
import useNavigationSteps from "../../hooks/useNavigationSteps";
import { API_STATUS, EMAIL_STATUS_VIEW } from "../../constants/common";

import AlreadyVerified from "./AlreadyVerified";
import VerifiedFailed from "./VerifiedFailed";
import VerifiedSuccess from "./VerifiedSuccess";

const EmailVerification: FC = () => {
  const dispatch = useAppDispatch();
  // const history = useHistory();

  const { userNavigation } = useNavigationSteps();
  const [loading, setLoading] = useState(true);
  const [viewType, setViewType] = useState("");
  const paramsData = getParams(["token"]);

  const onLoading = useCallback(async () => {
    if (paramsData?.token) {
      const res = await dispatch(emailVerify(paramsData?.token)).unwrap();
      if (res?.status === API_STATUS.SUCCESS) {
        setViewType(res?.data.msg_status);
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  const closeWindow = () => {
    // console.log(window.location.href, 'window.location.href');
    // window.open(window.location.href, "_self", "");
    window.close();
  };
  const handleNavigation = async () => {
    await userNavigation();
    // closeWindow();
  };

  const gotoHome = async () => {
    // history.push("/");
    closeWindow();
  };

  const getView = () => {
    switch (viewType) {
      case EMAIL_STATUS_VIEW.ALREADY_VERIFIED:
        return <AlreadyVerified handleClick={handleNavigation} />;
      case EMAIL_STATUS_VIEW.VERIFIED:
        return <VerifiedSuccess handleClick={handleNavigation} />;
      default:
        return <VerifiedFailed handleClick={gotoHome} />;
    }
  };

  useEffect(() => {
    onLoading();
  }, [paramsData?.token]);

  if (loading) {
    return <SplashValidation image={2} trueRedirect={false} />;
  } else {
    return getView();
  }
};

export default EmailVerification;
