import { FC } from "react";
import { IconProps, accentBlue } from "../types";

const CaretRightIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.39062 0.398438C2.13281 0.140626 1.80469 8.91303e-07 1.41797 9.25111e-07C0.644529 9.92727e-07 0.0234357 0.609376 0.0234358 1.38281C0.0234358 1.76953 0.187498 2.12109 0.45703 2.39063L8.66016 10.4063L0.457031 18.3984C0.1875 18.668 0.0234373 19.0313 0.0234374 19.4063C0.0234374 20.1797 0.644531 20.7891 1.41797 20.7891C1.80469 20.7891 2.13281 20.6484 2.39062 20.3906L11.5078 11.4844C11.8359 11.1797 11.9883 10.8047 12 10.3945C12 9.98438 11.8359 9.63281 11.5078 9.31641L2.39062 0.398438Z"
        fill={defaultColor ? props.fill : 'currentColor'}
      />
    </svg>
  );
};

CaretRightIcon.defaultProps = {
  fill: accentBlue,
};

export default CaretRightIcon;
