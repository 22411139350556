import { makeStyles } from "@mui/styles";

import { customColor } from "../../../theme/customColor";

const styles = makeStyles((theme) => ({
  // New css
  listContainer: {
    "& > div": {
      padding: "10px 30px",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      alignItems: "center",
      // fontFamily: "SFUIText-Regular",
      fontFamily: "Urbanist-Regular",
      fontSize: 18,
      fontWeight: 400,
      "& > img": {
        marginLeft: 5,
        marginTop: 2,
      },
    },
    "& .selected": {
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  register: {
    border: `0.5px solid ${customColor.cotton}`,
    borderRadius: 8,
  },
  headerButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down(601)]: {
      justifyContent: "space-between",
      alignItems: "center",
    },
    flex: 1,
  },
  drawer: {
    position: "absolute",
    textAlign: "right",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
  },
  mobileMenu: {
    [theme.breakpoints.up(601)]: {
      display: "none",
    },
    [theme.breakpoints.down(601)]: {
      width: "70%",
      background: "#3552CC",
      paddingBottom: 30,
    },
  },

  // Old css
  headerMainContainer: {
    padding: "12px 3% 15px 5%",
    background: "rgba(0, 38, 58, 0.4)",
    color: "#fff",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
    "& .img": {
      cursor: "pointer",
    },
  },
}));

export default styles;
