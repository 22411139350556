const getParams = (keys: string[]): { [key: string]: string } => {
  const searchParams = new URLSearchParams(window.location.search);
  return keys.reduce(
    (acc, val) => ({
      ...acc,
      [val]: searchParams.get(val) || '',
    }),
    {},
  );
};

export default getParams;
