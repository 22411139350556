import { makeStyles } from "@mui/styles";
import selectedNotImg from "../../assets/images/selectedNot.png";
import selectedImg from "../../assets/images/selected.png";

const CircleButtonStyles = makeStyles((theme) => ({
  circleSelected: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    background: `url(${selectedImg})`,

    height: 110,
    width: 110,
    borderRadius: "50%",
    border: "2px solid white",
    cursor: "pointer",
    [theme.breakpoints.down(600)]: {
      height: 90,
      width: 90,
    },
  },
  circleNotSelected: {
    background: `url(${selectedNotImg})`,
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    height: 110,
    width: 110,
    borderRadius: "50%",
    cursor: "pointer",
    [theme.breakpoints.down(600)]: {
      height: 90,
      width: 90,
    },
  },
  gridStyles: {
    flexDirection: "column",
    marginRight: 64,
    marginLeft: 64,
    [theme.breakpoints.down(600)]: {
      flexDirection: "row !important",
      marginRight: 0,
      marginLeft: 0,
    },
  },
  textNumberMonth: {
    color: theme.palette.primary.white,
  },
  textMonth: {
    color: theme.palette.primary.white,
    fontWeight: "600 !important",
    marginTop: "-5px !important",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      fontSize: "15px !important",
    },
  },
  boxStyle: {
    marginTop: 5,
    [theme.breakpoints.down(600)]: {
      marginTop: 16,
      marginLeft: 24,
    },
  },
  textLastAmount: {
    color: theme.palette.primary.white,
    marginBottom: "20px !important",
    width: "max-content",
    textAlign: "center",
    [theme.breakpoints.down(600)]: {
      fontSize: "25px !important",
    },
    minWidth: "100%",
  },
}));

export default CircleButtonStyles;
