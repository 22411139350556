import { Grid, Typography } from "@mui/material";

import mettaSolid from "../../../assets/Logos/gradient-logo.svg";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";

import styles from "./styles";

const Header = () => {
  const css = styles();

  return (
    <Grid item xs={12}>
      <CenteredContent direction="column" className={css.headerTextContainer}>
        <img src={mettaSolid} className={css.mettaLogo} alt="logo claro" />
        <Typography variant="h3" align="center" className={css.headerText}>
          ¿Cuál es el costo aproximado de tu procedimiento?
          <br />
          <p>Con un estimado nos es suficiente.</p>
        </Typography>
      </CenteredContent>
    </Grid>
  );
};

export default Header;
