import { makeStyles } from "@mui/styles";

import { customColor } from "../../theme/customColor";

const useStyles = makeStyles(() => ({
  title: {
    textAlign: "center",
    borderBottom: `solid 1px ${customColor.stone}`,
  },
  backButton: {
    padding: "0 22px",
  },
  borderBottom: {
    borderBottom: "none",
    paddingLeft: 8,
    paddingRight: 8,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    backgroundColor: "transparent",
    "&:hover": {
      background: "transparent !important",
    },
  },
  buttonIconContainer: {
    background:
      "linear-gradient(45deg, #3835CC 24.95%, #3754CD 51.31%, #3E59D0 60.82%, #4963D5 67.58%, #5A71DC 73.07%, #7083E6 77.75%, #8B98F2 81.83%, #A9B1FF 85.35%)",
    width: "46px",
    height: "46px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "360px",
  },
  buttonTextStyle: {
    fontWeight: 600,
    fontSize: 16,
    marginLeft: "10px !important",
  },
  resumeTitle: {
    color: customColor.shark,
    fontFamily: "SFUIText-Bold",
    fontSize: 20,
  },
  rightContainer: {
    padding: '14px 0',
  },
}));

export default useStyles;
