import { makeStyles } from "@mui/styles";

const InputTextStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.white,
    [theme.breakpoints.only("xs")]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      fontSize: "23px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "25px !important",
    },
  },
  inputSize: {
    [theme.breakpoints.only("xs")]: {
      width: 300,
    },
    [theme.breakpoints.between("sm", "lg")]: {
      width: 350,
    },
    [theme.breakpoints.up("lg")]: {
      width: 400,
    },
    marginTop: "5px !important",
    marginBottom: "20px !important",
  },
  formContainer: {
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("md")]: {
      width: "40ch",
    },
  },
}));

export default InputTextStyles;
