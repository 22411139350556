import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customTheme } from "../../features/installmentLoan/theme";

const useStyles = makeStyles((theme: Theme) => ({
  rightContainer: {
    padding: "14px 0",
  },

  creditContent: {
    marginLeft: 44,
    marginRight: 66,
    marginBottom: 36,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
      padding: "0px 8px",
    },
  },

  stepperCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "50%",

    background: "#D9D9D9",
    width: 30,
    height: 30,

    [theme.breakpoints.up("tablet")]: {
      background: "#F0F0F0",
      boxShadow: "inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
      width: 40,
      height: 40,
    },
  },

  stepperCircleActive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 27,
    height: 27,

    borderRadius: "50%",
    background: "#3552CC",
    color: "white",

    [theme.breakpoints.down("tablet")]: {
      width: 20,
      height: 20,
    },
  },

  stepperCircleInside: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 27,
    height: 27,
    border: "5px solid #3552CC",
    borderRadius: "50%",
    background: "#fff",
    color: "white",
  },

  balanceSummaryCont: {
    borderBottom: `1px solid ${customTheme.borderGrey}`,
    background: "rgba(53, 82, 204, 0.05)",
    borderRadius: "10px",
    padding: `17px 14px`,
    marginBottom: 8,
    cursor: "pointer",
    boxShadow: "0px 4px 4px rgba(53, 82, 204, 0.25)",
  },
  availableCredit: {
    color: "#9E9E9E",
    [theme.breakpoints.down("lg")]: {
      color: "#000",
      fontFamily: "Urbanist-Medium",
      fontSize: "14px",
    },
  },
  chooseTerm: {
    color: "#9E9E9E",
    fontFamily: "SFUIText-semibold",
    lineHeight: "24px",
    marginTop: 20,
    [theme.breakpoints.down("lg")]: {
      fontFamily: "Urbanist-Medium !important",
      fontSize: "14px",
    },
  },
  months: {
    padding: "5px 10px",
    marginRight: "10px",
    borderRadius: "10px",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      fontFamily: "Urbanist-Semibold",
      fontSize: "14px",
      borderRadius: "30px",
      padding: "8px 14px",
    },
  },
  calculate: {
    borderRadius: "8px",
    color: "#3552CC",
    padding: "13px 40px",
    border: "1px solid #3552CC",
    fontFamily: "SFUIText-semibold",
    marginTop: 60,
    [theme.breakpoints.down("lg")]: {
      borderRadius: "30px",
      fontFamily: "Urbanist-Semibold",
      fontSize: "14px",
      background: "#3552CC",
      color: "#fff",
    },
  },
}));

export default useStyles;
