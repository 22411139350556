import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import FooterLine from "../../assets/header/footerLine.svg";
import MettaGradient from "../../assets/Logos/gradient-logo.svg";
import EnvelopeIcon from "../../components/icons/Envelope";
import LockIcon from "../../components/icons/Lock";
import {
  API_STATUS,
  FIELD_CHANNEL,
  REQUESTED_SOURCE,
  URBANIST_FONT,
  VERIFICATION_METHOD,
} from "../../constants/common";
import { loginPage } from "../../constants/gtm";
import {
  AUTH_ROUTES,
  INSTALLMENT_LOAN_ROUTES,
  ROUTES,
} from "../../constants/routes";
import Button from "../../features/installmentLoan/components/Button";
import TextInput from "../../features/installmentLoan/components/TextInput";
import { setSidebarTabValue } from "../../features/installmentLoan/store/ui";
import { useAppDispatch } from "../../store";
import { getLogin, updateOnboardingSteps } from "../../store/slices/auth";
import { GTMClick } from "../../utils/gtm";
import { errorToast } from "../../utils/toast";

import { sendOTP, verifyOTP } from "../../store/slices/auth/actions";
import OTPPhoneNumber from "./OTPPhoneNumber";
import useStyles from "./styles";

const TIMEOUT_MINUTES = 2;
const TIMEOUT_SECONDS = 0;

const OTP_LENGTH = 4;

const Login: FC = () => {
  const css = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const search = useLocation().search;
  const flag = new URLSearchParams(search)?.get("flag");

  const [emailValue, setEmailValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>("");
  const [otpValue, setOtpValue] = useState<string>("");
  const [preferredLoginMethod, setPreferredLoginMethod] = useState<string>(
    VERIFICATION_METHOD.OTP
  );

  const [otpId, setOtpId] = useState<string>("");

  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const startTimer = () => {
    setMinutes(TIMEOUT_MINUTES);
    setSeconds(TIMEOUT_SECONDS);
    handleSendOTP();
  };

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          // setIsResendDisabled(false);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const onPressLogin = async () => {
    if (emailValue && otpValue.length === OTP_LENGTH) {
      // GTMClick(loginPage.login);
      handleVerifyOtp();
    }
    setIsLoggingIn(true);
    if (emailValue && passwordValue) {
      try {
        GTMClick(loginPage.login);
        const param = { email: emailValue, password: passwordValue };
        const { status: loginStatus } = await dispatch(
          getLogin(param)
        ).unwrap();
        if (loginStatus === API_STATUS.SUCCESS) {
          const {
            data,
            status = "",
            meta,
          } = await dispatch(updateOnboardingSteps()).unwrap();
          if (status === API_STATUS.SUCCESS) {
            const identify_step = await dispatch(
              updateOnboardingSteps()
            ).unwrap();
            // const page = stepNavigatorMapping[data?.step_no + 1];
            if (flag === "1") {
              history.push(ROUTES.underReviewKYC);
            } else {
              history.push(identify_step?.data?.next_step_url);
            }
            dispatch(setSidebarTabValue(INSTALLMENT_LOAN_ROUTES.MAIN));
          } else if (status === API_STATUS.ERROR) {
            if (meta.errorCode === 4000) {
              errorToast(meta?.errorMessage);
            }
          }
        }
        setIsLoggingIn(false);
      } catch (e) {
        console.log("Error", e);
        setIsLoggingIn(false);
      }
      // finally {
      //   dispatch(setSidebarTabValue(INSTALLMENT_LOAN_ROUTES.MAIN));
      // }
    }
  };

  const onPressSignup = () => {
    history.push(ROUTES.register);
  };

  const handleVerifyOtp = async () => {
    try {
      const data = await dispatch(
        verifyOTP({
          id: otpId,
          otp_value: otpValue,
          sent_to: phoneNumberValue,
          req_source: 1,
        })
      );

      console.log("VerifyOtpResponse", data);

      // if (status === API_STATUS.SUCCESS) {
      //   // handleClose();
      //   // await dispatch(resetUserRegisterInfo());
      //   // setOpenTnc(true);
      //   // successToast("OTP verificado con éxito");
      // }
      // if (status === API_STATUS.ERROR && error?.twilio_error) {
      //   // setVerifyError(message);
      // }
    } catch (e) {
      console.log("Error", e);
    }
  };

  const handleSendOTP = async () => {
    try {
      const data = await dispatch(
        sendOTP({
          req_source: REQUESTED_SOURCE.LOGIN,
          channel: FIELD_CHANNEL.SMS,
          platform: 1,
          send_to: phoneNumberValue,
        })
      );
    } catch (e) {}

    // finally {
    //   setPreferredLoginMethod(VERIFICATION_METHOD.OTP);
    //   // startTimer();
    // }
  };

  return (
    <Box className={css.loginMainContainer}>
      <Box className={css.loginLogoCont}>
        {/* <MettaLogo /> */}
        <img
          src={MettaGradient}
          width={92}
          alt="img blanco"
          className={css.mettaGradientLogo}
        />
        <Typography className={css.headerTitle}>Iniciar Sesión</Typography>
        <Typography variant="subtitle2" className={css.headerSubtitle}>
          Si ya tienes una cuenta con Medsi inicia sesión
        </Typography>
      </Box>
      {preferredLoginMethod === VERIFICATION_METHOD.PASSWORD && (
        <>
          <Box>
            <Typography
              style={{
                marginBottom: 5,
                color: "#fff",
                fontFamily: URBANIST_FONT.REGULAR,
                marginLeft: 60,
                fontSize: 16,
              }}
            >
              Correo electrónico
            </Typography>
            <TextInput
              id="email"
              placeholder="Ingresa tu correo electrónico"
              icon={<EnvelopeIcon className={css.textInputIconStyle} />}
              className={css.input}
              inputClassName={css.inputText}
              value={emailValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setEmailValue(e.target.value);
              }}
              iconClassName={css.iconWrapperStyle}
            />
          </Box>
          <Box>
            <Typography
              // variant="h5"
              style={{
                margin: "8px 0",
                color: "#fff",
                fontFamily: URBANIST_FONT.REGULAR,
                marginLeft: 60,
                fontSize: 16,
              }}
            >
              Contraseña
            </Typography>

            <TextInput
              id="password"
              type="password"
              placeholder="Ingresa tu contraseña"
              icon={<LockIcon className={css.textInputIconStyle} />}
              className={css.input}
              inputClassName={css.inputText}
              value={passwordValue}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPasswordValue(e.target.value);
              }}
              style={{ paddingRight: 14 }}
              iconClassName={css.iconWrapperStyle}
            />
          </Box>

          <div
            className={css.passwordAlternativeText}
            onClick={() => {
              setPreferredLoginMethod(VERIFICATION_METHOD.OTP);
            }}
          >
            ¿Iniciar sesión con número de celular?
          </div>

          <Button
            label="Iniciar Sesión"
            onClick={onPressLogin}
            className={css.loginButtonStyle}
            isSubmitting={isLoggingIn}
            disabled={!emailValue || !passwordValue}
            noCaps
          />

          <Button
            label="¿Olvidaste tu contraseña?"
            variant="text"
            className={css.forgotPasswordTextStyle}
            onClick={() => {
              history.push(AUTH_ROUTES.FORGOT_PASSWORD);
            }}
            noCaps
          />
        </>
      )}
      {preferredLoginMethod === VERIFICATION_METHOD.OTP && (
        <OTPPhoneNumber setPreferredLoginMethod={setPreferredLoginMethod} />
      )}
      <div>
        <Typography className={css.signupSentenceStyle}>
          Si aún no tienes una cuenta con nosotros,
          <Button
            label=" registrate aquí"
            variant="text"
            className={css.signupTextStyle}
            onClick={onPressSignup}
            noCaps
          />
        </Typography>
        <Grid container>
          <Grid
            item
            // md={2.5}
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            mt={2}
          >
            <Typography className={css.footerTextStyle} translate="no">
              Medsi&#169;{moment().format("YYYY")}
            </Typography>
          </Grid>
          <Grid
            item
            // md={2.5}
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            mt={2}
          >
            <Button
              label="Términos y Condiciones"
              variant="text"
              className={css.footerTextStyle}
              onClick={() => {
                window.open(ROUTES.tnc, "_blank");
              }}
            />
          </Grid>
          <Grid
            item
            // md={2.5}
            md={4}
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="flex-end"
            mt={2}
          >
            <Button
              label="Aviso de Privacidad"
              variant="text"
              className={css.footerTextStyle}
              onClick={() => {
                window.open(ROUTES.privacyPolicy, "_blank");
              }}
            />
          </Grid>
        </Grid>
        <div
          style={{
            overflow: "hidden",
            width: "100%",
            maxWidth: "100vw",
            margin: "70px auto 0",
          }}
        >
          <img
            src={FooterLine}
            style={{
              height: 138,
            }}
            alt="img blanco"
          />
        </div>
      </div>
    </Box>
  );
};

export default Login;
