import { makeStyles } from "@mui/styles";

import { customColor } from "../../theme/customColor";

const useStyles = makeStyles(() => ({
  tableCellStyle: {
    "&.MuiTableCell-root": {
      backgroundColor: "transparent",
      padding: "10px 16px",
      fontSize: 18,
      textAlign: "left",
    },
  },
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: customColor.cotton,
    },
    "&:nth-of-type(even)": {
      backgroundColor: customColor.stone,
    },
  },
  headerRow: {
    backgroundColor: customColor.accentBlue,
  },
  headerRowText: {
    color: customColor.cotton,
    fontSize: 19,
    fontFamily: "SFUIText-Bold",
  },
  label: {
    fontFamily: "SFUIText-Bold",
  },
  leftBorderTop: {
    borderTopLeftRadius: 10,
  },
  rightBorderTop: {
    borderTopRightRadius: 10,
  },
  leftBorderBottom: {
    borderBottomLeftRadius: 10,
  },
  rightBorderBottom: {
    borderBottomRightRadius: 10,
  },
  buttonIconContainer: {
    background:
      "linear-gradient(45deg, #3835CC 24.95%, #3754CD 51.31%, #3E59D0 60.82%, #4963D5 67.58%, #5A71DC 73.07%, #7083E6 77.75%, #8B98F2 81.83%, #A9B1FF 85.35%)",
    width: "46px",
    height: "46px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "360px",
  },
  buttonTextStyle: {
    fontWeight: 600,
    fontSize: 16,
    marginLeft: "10px !important",
  },
  button: {
    backgroundColor: "transparent",
    "&:hover": {
      background: "transparent !important",
    },
  },
  rightContainer: {
    padding: '14px 0',
  },
}));

export default useStyles;
