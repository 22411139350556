import { URBANIST_FONT } from '../../../../../constants/common';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles: any = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '-20px',
    paddingLeft: '15px',
  },
  radioGender: {
    width: '100%',
  },
  formControlRadio: {
    display: 'flex',
    fontSize: 16,
  },
  input: {
    width: '100%',
  },
  dateInput: {
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      // width: "40ch",
      minWidth: '100%',
    },
  },
  submitButton: {
    width: 'auto !important',
    padding: '13px 112px !important',
  },
}));

export default useStyles;
