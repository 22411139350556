import { FC } from "react";

import { IconProps, dirtyAqua } from "./types";

const NotificationIcon: FC<IconProps> = ({ defaultColor = true, ...props }) => {
  return (
    <svg
      width="39"
      height="41"
      viewBox="0 0 39 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.5694 27.4331V17.1393C33.5694 12.6879 29.2463 5.84233 22.8386 4.24671C22.856 4.13285 22.8675 4.01818 22.8732 3.90316C22.8754 3.46183 22.7898 3.02441 22.6213 2.61603C22.4527 2.20765 22.2046 1.83637 21.8912 1.52351C21.5777 1.21066 21.2051 0.962397 20.7948 0.792993C20.3844 0.623589 19.9444 0.536377 19.5 0.536377C19.0556 0.536377 18.6156 0.623589 18.2052 0.792993C17.7949 0.962397 17.4223 1.21066 17.1088 1.52351C16.7954 1.83637 16.5473 2.20765 16.3787 2.61603C16.2102 3.02441 16.1246 3.46183 16.1268 3.90316C16.1321 4.01821 16.1436 4.1329 16.1614 4.24671C9.7533 5.84233 5.43059 12.6879 5.43059 17.1393V27.4331C5.43059 33.1528 0 35.4435 0 35.4435H39C39 35.4435 33.5694 33.1528 33.5694 27.4331Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M19.5003 41C20.0406 41.0001 20.5755 40.8944 21.0747 40.6891C21.5738 40.4838 22.0274 40.1829 22.4094 39.8035C22.7915 39.4241 23.0945 38.9737 23.3012 38.478C23.5079 37.9822 23.6143 37.4509 23.6142 36.9144C23.6078 36.7502 23.5913 36.5865 23.5648 36.4243H15.4359C15.4094 36.5865 15.3929 36.7502 15.3865 36.9144C15.3865 37.9979 15.8199 39.0371 16.5914 39.8033C17.3629 40.5695 18.4092 41 19.5003 41Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

NotificationIcon.defaultProps = {
  fill: dirtyAqua,
};

export default NotificationIcon;
