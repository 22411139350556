import { FC } from "react";
import { Box } from "@mui/system";

import { getProcedureName } from "../../../store/helpers";
import { convertDateTimeToLocal } from "../../../utils/dateHelper";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { URBANIST_FONT } from "../../../constants/common";
import customColor from "../../../theme/customColor";

interface DoctorDetailsProps {
  doctorDetails?: any;
}

const DoctorDetails: FC<DoctorDetailsProps> = ({ doctorDetails }) => {
  const css = useStyles();
  if (doctorDetails) {
    const listItem: any = [];
    const { procedure_cost, requested_loan_amount, patient_pay_to_doc } =
      doctorDetails;

    if (procedure_cost) {
      listItem.push({
        label: "Costo del procedimiento",
        value: `$ ${procedure_cost}`,
      });
    }

    if (requested_loan_amount) {
      listItem.push({
        label: "Monto a pagar por Medsi",
        value: `$ ${requested_loan_amount}`,
      });
    }

    if (procedure_cost && requested_loan_amount) {
      listItem.push({
        label: "Monto restante a pagar por el paciente",
        value: `$ ${patient_pay_to_doc || 0}`,
      });
    }

    return (
      <Box
        className={css.container}
        sx={(theme) => ({
          [theme.breakpoints.up("lg")]: {
            width: "50%",
            margin: "0 auto",
          },
        })}
      >
        <Typography
          textAlign="left"
          fontFamily={URBANIST_FONT.BOLD}
          fontSize={28}
          color={customColor.cotton}
          marginBottom={2}
        >
          Resúmen de crédito
        </Typography>
        {listItem?.map((item) => {
          return (
            <Box className="row" key={item.label}>
              <Box className="title">{item.label}:</Box>
              <Box className="desc" style={{ textAlign: "right" }}>
                {item.value}
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }
  return null;
};

export default DoctorDetails;
