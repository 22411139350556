import { CONTACT_EMAIL } from '../../../config/key';

export const faqsList = {
  GET_CREDIT: [
    {
      title: "¿Cómo funciona esta solución?",
      description:
        "Medsi es tu mejor opción para financiar procedimientos médicos. Para obtener un crédito, debes completar el proceso de solicitud en línea o en la aplicación, compartirnos los datos del médico y del procedimiento que te quieres realizar y elegir el plan de pagos que más te acomoda. ",
    },
    {
      title:
        "¿Cuál es el límite de crédito y cuánto es lo máximo que puedo pedir prestado?",
      description:
        "Por el momento, estamos financiando créditos por un monto máximo de $30,000 M.N. Con Kaloni, nuestro socio exclusivo, podemos ofrecerte credito por hasta $ 80,000 M.N. Muy pronto estaremos incrementando los límites de crédito disponibles ti, para poder apoyarte en procedimientos más complejos y/o transformaciones más extensivas.",
    },
    {
      title: "¿Cuánto tiempo toma solicitar un crédito?",
      description:
        "El proceso es muy fácil. En promedio, el proceso de aplicación en línea toma ~8 minutos en completar para usuarios de primera vez; mucho menos para usuarios ya registrados. Una vez que hayas completado el proceso de aplicación, regresaremos contigo antes de 24 horas con una decisión de crédito.",
    },
    {
      title: "¿Qué documentos necesito para solicitar un crédito?",
      description:
        "En el proceso de aplicación necesitarás tu CURP y RFC. Si no tienes un RFC o si otra personal cubrirá el costo de tu procedimiento, te pediremos la información de contacto de la persona que designes como corresponsable. Necesitarás también tener a la mano una identificación oficial, como credencial para votar, pasaporte o licencia de conducir.",
    },
    {
      title: "¿Puedo obtener un crédito si no tengo una tarjeta de crédito?",
      description:
        "¡Claro! Nuestro objetivo es hacer la atención médica accesible para todos, y ayudar a todas las personas que no cuentan con un seguro de gastos médicos a recibir los servicios que desean o necesitan, aún cuando no tengan acceso a otras herramientas de crédito.",
    },
    {
      title:
        "¿Puedo obtener un crédito sin tener historial crediticio o ingresos fijos?",
      description:
        "Si puedes. De hecho, esta es una gran forma de comenzar a construir un historial de crédito propio, que te será muy útil hacia adelante. Si no cuentas con un historial crediticio, puedes pedir a una persona de confianza (como tus papás, tu pareja, etc) que te apoye en tu proceso de aplicación. Al llenar la solicitud, incluye la información de contacto de la persona que será corresponsable del crédito. Nosotros nos pondremos en contacto vía email con quien tú señales para finalizar el proceso de solicitud y te notificaremos tan pronto complete la aplicación.",
    },
    {
      title:
        "¿Puedo obtener un crédito si alguien más (mi pareja, mis papás, etc.) va a cubrir el costo de este procedimiento?",
      description:
        "¡Por supuesto! Al completar tu solicitud de crédito, incluye la información de contacto de la persona que cubrirá el costo del procedimiento. Nosotros nos pondremos en contacto vía email con quien tú señales para finalizar el proceso de solicitud y te notificaremos tan pronto complete la aplicación.",
    },
    {
      title: "¿Hasta qué plazo puedo obtener para mi crédito?",
      description:
        "Te ofrecemos la posibilidad de diferir el pago de servicios médicos desde 3 hasta 12 meses. Tú eliges el plan que más te acomode, con pagos quincenales o mensuales.",
    },
    {
      title:
        "Si aún no he elegido un médico para el procedimiento o tratamiento que quiero realizarme, ¿puedo aplicar para un crédito?",
      description:
        "¡Por supuesto! Puedes completar el proceso de solicitud en línea sin incluir esta información e indicando el monto aproximado del que quisieras disponer. De ser elegible para el crédito, recibirás una notificación de “Pre-Aprobación” con una liga para completar la información pendiente más adelante. Una vez que elijas al médico de tu preferencia y conozcas el costo exacto del procedimiento, puedes ingresar esta información adicional en nuestro portal. Nosotros realizaremos el pago directamente a tu médico una vez que se realice tu procedimiento.",
    },
    {
      title:
        "¿Qué tipo de procedimientos puedo financiar a través de esta solución?",
      description:
        "Por el momento, estamos enfocados en procedimientos electivos, principalmente cosméticos y estéticos. Nuestra aspiración es poder cubrir el espectro completo de servicios médicos a través de una mayor variedad de productos y soluciones, que iremos dándote a conocer conforme los vayamos introduciendo.",
    },
    {
      title:
        "¿Con cuánta anticipación antes de mi procedimiento tengo que solicitar el crédito?",
      description:
        "El tiempo necesario para el procesamiento de solicitudes de crédito es un máximo de 24 horas, por lo que necesitarás ingresar tu aplicación al menos un día antes de la fecha agendada para tu procedimiento. Muy pronto podremos ofrecerte respuesta a tus solicitudes en tiempo real, para que puedas acceder a más servicios o productos incluso desde el consultorio, con respuesta inmediata.",
    },
    {
      title:
        "¿Qué pasa si no conozco aún el costo del procedimiento que me quiero realizar?",
      description:
        "Puedes completar el proceso de solicitud en línea indicando el monto aproximado del que quisieras disponer. De ser elegible para el crédito, recibirás una notificación de “Pre- Aprobación” con una liga para completar esa información más adelante. Una vez que conozcas el costo exacto del procedimiento, puedes ingresar esta información pendiente en nuestro portal. Nosotros realizaremos el pago directamente a tu médico una vez que se realice tu procedimiento.",
    },
    {
      title:
        "¿Qué pasa si quiero hacerme más procedimientos (o adquirir productos, etc.) durante mi visita con el médico? ¿Se puede incrementar el monto del crédito solicitado?",
      description:
        "Por ahora, no. El tiempo necesario para el procesamiento de solicitudes de crédito es de 24 horas, por lo que necesitarás ingresar tu aplicación al menos un día antes de realizar tu procedimiento. Muy pronto pondremos a tu alcance soluciones de crédito en tiempo real, para que puedas acceder a más servicios o productos incluso desde el consultorio, con respuesta inmediata.",
    },
    {
      title:
        "Tengo cobertura de un seguro médico. ¿Puedo utilizar un crédito Medsi para financiar el deducible y/o co-aseguro para un procedimiento médico no electivo?",
      description:
        `Si. Nos dará mucho gusto poder apoyarte con este financiamiento complementario a la cobertura de tu seguro para procedimientos no electivos. Por favor contáctanos vía email a <a href=mailto:${CONTACT_EMAIL}>${CONTACT_EMAIL}</a> y recibirás atención de un especialista.`,
    },
  ],
  MY_CREDIT: [
    {
      title: "Una vez aprobado el crédito, ¿qué tengo que hacer?",
      description:
        "¡Nada! Nosotros nos encargamos del resto: contactaremos al médico que has elegido y realizaremos el pago directamente al doctor una vez que se realice tu procedimiento. ",
    },
    {
      title: "¿Cómo puedo realizar el pago de las parcialidades de mi crédito?",
      description:
        "Desde un par de días antes de tu próximo pago, recibirás un correo electrónico con un recordatorio de tu fecha de pago y con una liga para realizarlo. Con esa liga, puedes realizar tu pago con transferencia vía SPEI, con una tarjeta de crédito o débito, o en efectivo en tiendas de conveniencia o sucursales bancarias. Asimismo, desde la página principal o desde el App, puedes ingresar a tu Tablero de Control, donde tienes acceso a tus estados de cuenta, relación de pagos realizados y pendientes, etc. Desde allí puedes realizar pagos también por cualquiera de los medios antes mencionados.",
    },
    {
      title:
        "¿Qué pasa si tengo un crédito aprobado y mi procedimiento se cancela, se pospone o no se realiza?",
      description:
        "No pasa nada. Dado que Medsi realiza el pago directamente a tu médico una vez que se ha realizado el procedimiento, si este se aplaza o si no se lleva a cabo por cualquier motivo, no se originará el crédito.",
    },
    {
      title:
        "Una vez que me han otorgado el crédito y se ha realizado mi procedimiento, ¿dónde puedo consultar mi estado de cuenta, próximas fechas de pago, etc?",
      description:
        "Desde la página principal o desde el App, puedes ingresar a tu Tablero de Control, donde tienes acceso a tus estados de cuenta, relación de pagos realizados y pendientes, y otras funcionalidades. Desde allí puedes también realizar el pago de tus parcialidades con transferencia vía SPEI, con una tarjeta de crédito o débito, o en efectivo en tiendas de conveniencia o sucursales bancarias.",
    },
    {
      title: "¿Cuál es la tasa de interés / CAT de mi crédito?",
      description:
        "Medsi te ofrece una tasa de interés competitiva en función de tu perfil de crédito. El financiamiento incluye una cuota del 40% anual sobre el monto del crédito obtenido; el cálculo del CAT dependerá del plazo que elijas. En promedio, un crédito con Medsi tiene un costo ~35% menor que el promedio de las tarjetas de crédito en México y una fracción de otras alternativas de financiamiento disponibles en el mercado.",
    },
    {
      title:
        "Si ya tengo un crédito con Medsi, ¿puedo solicitar un crédito adicional?",
      description:
        "¡Por supuesto! Si ya has obtenido un crédito con nosotros, el proceso de aplicación para un nuevo crédito es aún más fácil y rápido. Sujeto al límite de crédito de $30,000 M.N., puedes tener uno o varios créditos activos con nosotros.",
    },
  ],
  DR_PROCEDURE: [
    {
      title:
        "¿Con qué médicos puedo realizarme el procedimiento o cirugía que deseo?",
      description:
        "¡Tú elijes! Con Medsi no estás limitado a una red cerrada de doctores, y puedes acudir con el médico de tu preferencia, siempre que cumpla con los criterios necesarios para poder operar en nuestra plataforma.",
    },
    {
      title:
        "¿Puedo acudir a mi médico de confianza, aunque no esté afiliado a la red de Medsi?",
      description:
        "Si tu médico aún no forma parte de nuestra red, ¡invítalo! Al momento de completar tu solicitud de crédito, te pediremos nos proporciones también la información de contacto de tu médico (correo electrónico, teléfono de consultorio). En las siguientes 24 horas, nosotros nos pondremos en contacto con el consultorio para validar sus credenciales y, si cumple con los criterios necesarios, recibirás el correo electrónico de confirmación para que puedas ir adelante con tu procedimiento.",
    },
    {
      title:
        "Si aún no he elegido un médico para el procedimiento o tratamiento que quiero realizarme, ¿puedo aplicar para un crédito?",
      description:
        "¡Por supuesto! Puedes completar el proceso de solicitud en línea sin incluir esta información e indicando el monto aproximado del que quisieras disponer. De ser elegible para el crédito, recibirás una notificación de “Pre-Aprobación” con una liga para completar la información pendiente más adelante. Una vez que elijas al médico de tu preferencia y conozcas el costo exacto del procedimiento, puedes ingresar esta información adicional en nuestro portal. Nosotros realizaremos el pago directamente a tu médico una vez que se realice tu procedimiento.",
    },
    {
      title:
        "¿Qué tipo de procedimientos puedo financiar a través de esta solución?",
      description:
        "Por el momento, estamos enfocados en procedimientos electivos, principalmente cosméticos y estéticos. Nuestra aspiración es poder cubrir el espectro completo de servicios médicos a través de una mayor variedad de productos y soluciones, que iremos dándote a conocer conforme los vayamos introduciendo.",
    },
    {
      title:
        "¿Puedo utilizar este préstamo para otros fines que no sean tratamientos o servicios médicos, estéticos o cosméticos?",
      description:
        "No. Si tu solicitud de crédito es aprobada, el pago se realizará directamente al médico una vez que se haya realizado tu procedimiento. No se realizará ningún desembolso directamente al acreditado, por lo que su uso está reservado exclusivamente para estos servicios.",
    },
  ],
};
