import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { API_STATUS } from "../../../constants/common";
import {
  setUserCookie,
  setCookie,
  onBoardingUserType,
} from "../../../utils/auth";
import { ROUTES, INSTALLMENT_LOAN_ROUTES } from "../../../constants/routes";
import { ONBOARDING_TYPE } from "../../../constants/generals";
import { logout } from "../../../utils/auth";

import {
  getLogin,
  saveUserRegisterInfo,
  requestResetEmail,
  passwordReset,
  passwordResetComplete,
  emailVerify,
  getOnboardingSteps,
  userLogout,
  updateOnboardingSteps,
  updateDoctorConfirmation,
  verifyOTP,
  loginVerifyOTP,
} from "./actions";

const initialState = {
  loading: false,
  userId: "",
  userName: "",
  email: "",
  phoneNumber: "",
  accessToken: "",
  requestResetEmailMessage: "",
  refreshToken: "",
  currentOnBoardingStep: -2,
  isLoginDataLoaded: false,
  coSignerReferenceNumber: "",
};

export const stepNavigatorMapping = {
  // generale info
  1: ROUTES.generalInformation,
  // Medical Procedure
  2: ROUTES.processInformation,
  //Credit Details
  3: ROUTES.CREDIT_REQUEST,
  //Registration details(Curp)
  4: ROUTES.generalData,
  //Additional Profile personal Info
  5: ROUTES.personalInformation,
  //Further Profile Info
  6: ROUTES.knowYouBetter,
  //Identity Information

  7: ROUTES.identityInformation,
  // 8: ROUTES.identityInformation,

  8: ROUTES.identityInformation,
  // 8: ROUTES.identityInformation,

  9: ROUTES.clientSignature,

  10: ROUTES.creditConfirmation,

  11: INSTALLMENT_LOAN_ROUTES.MAIN,

  12: INSTALLMENT_LOAN_ROUTES.MAIN,
};

const slice = createSlice({
  name: "auth",
  initialState: {
    ...initialState,
  } as AuthState,
  reducers: {
    setLoadingValue: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setAuthToken: (state, { payload = {} }) => {
      const { access_token, email, user_id } = payload;
      setUserCookie({
        access_token: access_token,
        user_id,
        email,
      });
      state.userId = user_id;
      state.email = email;
      state.accessToken = access_token;
    },
    setEmailAndName: (state, { payload }) => {
      state.userName = `${payload?.first_name} ${payload?.last_name}`;
    },
    setCoSignerReferenceNumber: (state, { payload = "" }) => {
      state.coSignerReferenceNumber = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginVerifyOTP.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      state.isLoginDataLoaded = true;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userId = data?.user_id;
        state.email = data?.email;
        state.accessToken = data.access_token;
        state.refreshToken = data.refresh_token;
        state.userName = `${data?.first_name} ${data?.last_name}`;
        state.phoneNumber = data.phone_number;
        setUserCookie(data);
        setCookie(ONBOARDING_TYPE.USER, onBoardingUserType);
      }
    });
    builder.addCase(getLogin.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      state.isLoginDataLoaded = true;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userId = data?.user_id;
        state.email = data?.email;
        state.accessToken = data.access_token;
        state.refreshToken = data.refresh_token;
        state.userName = `${data?.first_name} ${data?.last_name}`;
        state.phoneNumber = data.phone_number;
        setUserCookie(data);
        setCookie(ONBOARDING_TYPE.USER, onBoardingUserType);
      }
    });

    builder.addCase(getLogin.pending, (state) => {
      // state.isLoginDataLoaded = false;
    });
    builder.addCase(getLogin.rejected, (state, { payload }) => {
      state.isLoginDataLoaded = false;
    });

    builder.addCase(requestResetEmail.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.requestResetEmailMessage = data?.message;
        state.loading = false;
      }
    });

    builder.addCase(verifyOTP.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      state.phoneNumber = data?.phone_number || "";
      // if (API_STATUS?.SUCCESS === payload?.status) {
      //   state.requestResetEmailMessage = data?.message;
      //   state.loading = false;
      // }
    });

    builder.addCase(passwordReset.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.requestResetEmailMessage = data?.message;
        state.loading = false;
      }
    });

    builder.addCase(passwordResetComplete.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        return data;
      }
    });

    builder.addCase(emailVerify.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userId = data?.user_id;
        state.email = data?.email;
        state.accessToken = data.access_token;
        state.refreshToken = data.refresh_token;
        state.userName = `${data?.first_name} ${data?.last_name}`;
        setUserCookie(data);
        setCookie(ONBOARDING_TYPE.USER, onBoardingUserType);
      }
    });

    builder.addCase(getOnboardingSteps.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.currentOnBoardingStep = Number(data?.step_no);
      }
    });

    builder.addCase(userLogout.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        logout();
        return data;
      }
    });

    builder.addCase(saveUserRegisterInfo.fulfilled, (state, { payload }) => {
      const { data } = payload;

      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userId = data?.user_id;
        state.email = data?.email;
        state.userName = `${data?.first_name} ${data?.last_name}`;
        state.accessToken = data.access_token;
      }

      // state.isLoginDataLoaded = true;
      // if (API_STATUS?.SUCCESS === payload?.status) {
      //   state.userId = data?.user_id;
      //   state.email = data?.email;
      //   state.accessToken = data.access_token;
      //   state.refreshToken = data.refresh_token;
      //   state.userName = `${data?.first_name} ${data?.last_name}`;
      //   setUserCookie(data);
      //   setCookie(ONBOARDING_TYPE.USER, onBoardingUserType)
      // }
    });

    // builder.addCase(saveUserRegisterInfo.pending, (state) => {
    //   // state.isLoginDataLoaded = false;
    // });
    // builder.addCase(saveUserRegisterInfo.rejected, (state, { payload }) => {
    //   state.isLoginDataLoaded = false;
    // });
  },
});

export type AuthState = {
  loading: boolean;
  userId: string;
  userName: string;
  email: string;
  phoneNumber: string;
  accessToken: string;
  requestResetEmailMessage: string;
  refreshToken: string;
  currentOnBoardingStep: number;
  isLoginDataLoaded: boolean;
  coSignerReferenceNumber: string;
};

export type ILoginArgs = {
  email: string;
  password: string;
};

export type IRequestResetEmailArgs = {
  email: string;
};

export type IPasswordResetArgs = {
  token: string;
  uuid: string;
};

export type IPasswordResetCompleteArgs = {
  password: string;
  token: string;
  uuid: string;
};

export default slice.reducer;

export const {
  setLoadingValue,
  setAuthToken,
  setEmailAndName,
  setCoSignerReferenceNumber,
} = slice.actions;
export {
  getLogin,
  saveUserRegisterInfo,
  requestResetEmail,
  passwordReset,
  passwordResetComplete,
  emailVerify,
  getOnboardingSteps,
  userLogout,
  updateOnboardingSteps,
  updateDoctorConfirmation,
};

export const getAuthState = (state: any) => state.auth;
