import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  headerMainContainer: {
    padding: "12px 11% 15px 5%",
    background: "rgba(53, 82, 204, 0.3)",
    color: "#fff",
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  headerButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLoginButtonStyle: {
    background: "transparent",
    outline: "none",
    border: "none",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "38px",
    padding: "0px 10px",
    marginRight: "22px",
    borderRadius: "6px",
    '&:active': {
        background: 'rgba(53, 82, 204, 0.1)'
    }
  },
  headerLoginButtonTextStyle: {
    // fontFamily: 'SF UI Text',
    fontSize: "16px !important",
    fontWeight: "bold !important",
    lineHeight: "19px !important",
    color: "#6B7779 !important",
    marginRight: "9px !important",
  },
  headerApplyCreditButtonStyle: {
    background: "transparent",
    outline: "none",
    border: "1px solid #3552CC",
    borderRadius: "6px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "38px",
    padding: "0px 22px",

    // fontFamily: 'SF UI Text';
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#3552CC",
    '&:active': {
        background: 'rgba(53,82,204, 0.1)'
    }
  },
}));

export default styles;
