import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import cosignImage from "../../../assets/addcosigner.png";
import MoneyBag from "../../../assets/MoneyBag.png";
import DollarImg from "../../../assets/Icons/DollarOutlined.png";
import PendingFile from "../../../assets/Pending.png";
import CoSignerForm from "../../../components/ModalContents/CoSignerForm";
import DeleteConfirmation from "../../../components/ModalContents/DeleteConfirmation";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import { ALL_APPLCIATION, TRANSACTION_STATUS } from "../../../constants/common";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch } from "../../../store";

import { Box, Grid } from "@mui/material";
import { APPLICATION_COLOR_CONSTANTS } from "../../../constants/styleConstants";
import {
  getApplicationStepper,
  selectAllApplication,
  selectCosignerStatus,
  selectLmsBalanceSummary,
  selectStepperStatus,
  selectTakeLoanDetail,
  selectTransactionList,
} from "../../../store/slices/users";
import {
  getAllApplication,
  getCoSignerStatus,
  getTransactionList,
} from "../../../store/slices/users/actions";
import useStyles from "./styles";
import { currencyFormat } from "../../../utils/index";

const ApplicationStatus = (props) => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [openCosigner, setOpenCoSigner] = React.useState(false);
  const { decisionStatus, creditDecision, fetchLmsBalanceSummary } = props;
  const dispatch = useAppDispatch();
  const loanDetails = useSelector(selectTakeLoanDetail);
  const [application_id, setAppId] = React.useState();
  const canAddCosigner =
    creditDecision?.creditEligibilityData?.[0]?.can_add_co_signer || false;

  const canEditKyc = creditDecision?.user_details?.kyc_status !== "accepted";

  // const [recentPendingApplication, setRecentPendingApplication] =
  //   React.useState();

  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);

  React.useEffect(() => {
    dispatch(
      getTransactionList({
        cl_id: LmsBalanceSummary?.cl_id,
        totalShow: 3,
        txnType: "all",
      })
    );

    dispatch(getCoSignerStatus());
  }, [LmsBalanceSummary]);

  React.useEffect(() => {
    setAppId(localStorage.getItem("application_id"));
  }, []);

  const transactionList = useSelector(selectTransactionList);
  const storeCosignerStatus = useSelector(selectCosignerStatus);

  React.useEffect(() => {
    // dispatch(getApplicationStepper(application_id));
    dispatch(getAllApplication());
  }, [application_id]);

  const StepperStatus = useSelector(selectStepperStatus);
  const AllApplication = useSelector(selectAllApplication);

  const handleViewAll = () => {
    history.push(ROUTES.allApplication);
  };

  const handleKyc = () => {
    history.push(ROUTES.underReviewKYC);
  };

  const handleResume = (id) => {
    history.push(`${ROUTES.stepper}/?${id}`);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleViewTransaction = () => {
    history.push(`${ROUTES.allTransactions}/?${LmsBalanceSummary?.cl_id}`);
  };

  const hanldeCosigner = () => {
    setOpenCoSigner(true);
  };

  const recentPendingApplication =
    Array.isArray(AllApplication?.msg) &&
    AllApplication?.msg?.filter(
      (application) => application.deleted === false
    )[0];

  React.useEffect(() => {
    if (recentPendingApplication?.application_id) {
      dispatch(getApplicationStepper(recentPendingApplication?.application_id));
    }
  }, [recentPendingApplication, dispatch]);

  const css = useStyles();
  return (
    <div>
      <Box
        sx={(theme) => ({
          padding: "20px",
          [theme.breakpoints.down("md")]: {
            padding: "10px",
          },
        })}
      >
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            margin: "30px 0 20px",
            [theme.breakpoints.down("lg")]: {
              width: "62%",
              margin: "3rem 0 3rem 75px",
            },
            [theme.breakpoints.down("tablet")]: {
              width: "90%",
              margin: "1rem auto",
              fontSize: "12px",
            },
          })}
        >
          <h2 className={css.applicationText}>Estatus de la Solicitud</h2>
          <span className={css.showAll} onClick={handleViewAll}>
            Mostrar Todos ({AllApplication?.msg?.length})
          </span>
        </Box>
        {/* pending */}
        {decisionStatus === "pending" && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 30,
              paddingTop: 24,
              paddingBottom: 24,
              alignItems: "center",
              background: "#FFFFFF",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: 20,
              marginBottom: 10,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>
                <img
                  src={PendingFile}
                  style={{ height: 52, marginRight: 11 }}
                  alt=""
                />
              </div>
              <div>
                <Box
                  sx={(theme) => ({
                    fontFamily: "SFUIText-semibold",
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-SemiBold",
                    },
                  })}
                >
                  {canAddCosigner || storeCosignerStatus.has_cosigner
                    ? "Te hemos incluido en nuestra Lista de Espera. Te notificaremos cuando tengamos alguna solución de crédito adecuada para ti."
                    : "Su solicitud de crédito está bajo revisión!"}
                </Box>
              </div>
            </div>

            <div>
              {!creditDecision?.kyc_status ? (
                <div>
                  <button
                    style={{
                      padding: "8px 10px",
                      background: "#3552CC",
                      borderRadius: 10,
                      color: "#FFFFFF",
                      marginRight: 20,
                    }}
                    onClick={() => handleKyc()}
                  >
                    Iniciar verificación
                  </button>
                </div>
              ) : (
                <div>
                  <button
                    style={{
                      padding: "8px 16px",
                      background: "#3552CC",
                      borderRadius: 10,
                      color: "#FFFFFF",
                      marginRight: 20,
                      opacity: canEditKyc ? 1 : 0.5,
                    }}
                    disabled={!canEditKyc}
                    onClick={() => handleKyc()}
                  >
                    Editar Verificación
                  </button>
                </div>
              )}
              <div style={{ marginTop: 8 }}>
                {canAddCosigner && !storeCosignerStatus.has_cosigner && (
                  <button
                    style={{
                      marginTop: 12,
                      // padding: '8px 10px',
                      padding: "8px 25px",
                      background: "#3552CC",
                      borderRadius: 10,
                      color: "#FFFFFF",
                      marginRight: 20,
                    }}
                    onClick={hanldeCosigner}
                  >
                    {/* Agregar codeudor */}
                    Añadir <br /> Co-Aplicante
                  </button>
                )}
                {storeCosignerStatus.has_cosigner && (
                  <button
                    style={{
                      padding: "8px 10px",
                      background: "#3552CC",
                      borderRadius: 10,
                      color: "#FFFFFF",
                      marginRight: 20,
                    }}
                    onClick={hanldeCosigner}
                  >
                    Editar codeudor
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        {/* REJECTED */}
        {decisionStatus === "rejected" &&
          canAddCosigner &&
          !storeCosignerStatus.has_cosigner && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingLeft: 30,
                paddingTop: 24,
                paddingBottom: 24,
                alignItems: "center",
                background: "#FFFFFF",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: 20,
                marginBottom: 10,
              }}
            >
              <div>
                <Box
                  sx={(theme) => ({
                    fontFamily: "SFUIText-semibold",
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-SemiBold",
                    },
                  })}
                >
                  Tu solicitud de crédito está pendiente hasta que tu
                  corresponsable complete el proceso
                </Box>
                <div style={{ marginTop: 8 }}>
                  Comuníquese con su codeudor, solicite revisar su bandeja de
                  entrada y siga los pasos en las
                  <br />
                  próximas 24 a 48 horas.
                </div>
              </div>

              <div>
                <button
                  style={{
                    padding: "8px 8px",
                    background: "#3552CC",
                    borderRadius: 10,
                    color: "#FFFFFF",
                    marginRight: 35,
                  }}
                  onClick={hanldeCosigner}
                >
                  Agregar detalles del <br /> cosignatario
                </button>
              </div>
            </div>
          )}
        {/* addCoSigner DATA  */}
        {decisionStatus === "rejected" && storeCosignerStatus.has_cosigner && (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 30,
              paddingTop: 24,
              paddingBottom: 24,
              alignItems: "center",
              background: "#FFFFFF",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: 20,
              marginBottom: 10,
            }}
          >
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 11 }}>
                <img src={cosignImage} style={{ height: 40 }} alt="" />
              </div>
              <div>
                <Box
                  sx={(theme) => ({
                    fontFamily: "SFUIText-semibold",
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-SemiBold",
                    },
                  })}
                >
                  Tu corresponsable ha ingresado su información. Tu solicitud de
                  crédito está en proceso de revisión
                </Box>
                <div style={{ marginTop: 8 }}>
                  Regresaremos contigo en las próximas 24-48 horas hábiles
                </div>
              </div>
            </div>
            <div>
              <button
                style={{
                  padding: "8px 10px",
                  background: "#3552CC",
                  borderRadius: 10,
                  color: "#FFFFFF",
                  marginRight: 20,
                }}
                onClick={hanldeCosigner}
              >
                Editar codeudor
              </button>
            </div>
          </div>
        )}
        {/* Approved */}
        {decisionStatus === "approved" && !!recentPendingApplication && (
          <Box
            sx={(theme) => ({
              background: "#FFFFFF",
              borderRadius: "20px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              padding: "10px 20px",
              marginBottom: "30px",
              [theme.breakpoints.down("lg")]: {
                width: "70%",
                margin: "0px 0px 2rem 75px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                padding: "2rem",
              },
              [theme.breakpoints.down("tablet")]: {
                width: "90%",
                margin: "1rem auto",
                padding: "1rem 1.5rem",
              },
            })}
          >
            <Grid container rowSpacing={4}>
              <Grid item xs={12} lg={4}>
                <Box
                  sx={(theme) => ({
                    marginTop: "10px",
                    "& span": {
                      fontFamily: "SFUIText-bold",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    },
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-Medium",
                      fontSize: "14px",
                    },
                  })}
                >
                  <span>ID de la Solicitud:</span>{" "}
                  {recentPendingApplication?.application_id}
                </Box>
                <Box
                  sx={(theme) => ({
                    marginTop: "10px",
                    "& span": {
                      fontFamily: "SFUIText-bold",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    },
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-Medium",
                      fontSize: "14px",
                    },
                  })}
                >
                  <span>Monto Solicitado:</span>{" "}
                  {recentPendingApplication?.req_amount.toLocaleString()}
                </Box>
                <Box
                  sx={(theme) => ({
                    marginTop: "10px",
                    "& span": {
                      fontFamily: "SFUIText-bold",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    },
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-Medium",
                      fontSize: "14px",
                    },
                  })}
                >
                  <span>Fecha de Solicitud:</span>{" "}
                  {recentPendingApplication &&
                    moment(recentPendingApplication?.created_at).format(
                      "MMMM DD, YYYY"
                    )}
                  {!recentPendingApplication?.created_at && "Not Available"}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                lg={4}
                style={{ width: 360, margin: "0 auto", paddingLeft: 12 }}
              >
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      fontFamily: "Urbanist-Medium",
                      fontSize: "14px",
                      "& span": {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    },
                  })}
                >
                  Estado de la aplicación:{" "}
                  <span
                    style={{
                      color:
                        recentPendingApplication?.status === "SUBMITTED" ||
                        recentPendingApplication?.status === "DISBURSED"
                          ? "#128807"
                          : "#CD9C00",
                    }}
                  >
                    {ALL_APPLCIATION[recentPendingApplication?.status]}
                  </span>{" "}
                </Box>

                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.down("lg")]: {
                      padding: "0 2rem",
                    },
                  })}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <div className={css.stepperCircle}>
                      <div className={css.stepperCircleActive}>
                        <CheckIcon style={{ fontSize: 8 }} />
                      </div>
                    </div>

                    <div
                      style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                    />

                    {StepperStatus?.procedure_completed ? (
                      <div className={css.stepperCircle}>
                        <div className={css.stepperCircleActive}>
                          <CheckIcon style={{ fontSize: 8 }} />
                        </div>
                      </div>
                    ) : (
                      <div className={css.stepperCircleInActive}>
                        <div className={css.stepperCircleInside}>.</div>
                      </div>
                    )}

                    <div
                      style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                    />
                    {StepperStatus?.loan_completed ? (
                      <div className={css.stepperCircle}>
                        <div className={css.stepperCircleActive}>
                          <CheckIcon style={{ fontSize: 8 }} />
                        </div>
                      </div>
                    ) : (
                      <div className={css.stepperCircleInActive}>
                        <div className={css.stepperCircleInside}>.</div>
                      </div>
                    )}

                    <div
                      style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                    />
                    {StepperStatus?.kyc_completed ? (
                      <div className={css.stepperCircle}>
                        <div className={css.stepperCircleActive}>
                          <CheckIcon style={{ fontSize: 8 }} />
                        </div>
                      </div>
                    ) : (
                      <div className={css.stepperCircleInActive}>
                        <div className={css.stepperCircleInside}>.</div>
                      </div>
                    )}

                    <div
                      style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                    />
                    {StepperStatus?.signature_completed ? (
                      <div className={css.stepperCircle}>
                        <div className={css.stepperCircleActive}>
                          <CheckIcon style={{ fontSize: 8 }} />
                        </div>
                      </div>
                    ) : (
                      <div className={css.stepperCircleInActive}>
                        <div className={css.stepperCircleInside}>.</div>
                      </div>
                    )}
                  </div>
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      margin: "5px -30px",
                      fontSize: "12px",
                      // [theme.breakpoints.down('sm')]: {
                      //   fontSize: 10,
                      // },
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Medium",
                        fontSize: "12px",
                      },
                      [theme.breakpoints.down("tablet")]: { fontSize: "10px" },
                    })}
                  >
                    <div
                      style={{
                        maxWidth: 100,
                        color: "#3552CC",
                        textAlign: "center",
                      }}
                    >
                      Detalles del Paciente
                    </div>
                    <div style={{ flex: 1 }} />

                    <div
                      style={{
                        maxWidth: 100,
                        color: "#3552CC",
                        textAlign: "center",
                      }}
                    >
                      Información del Procedimiento
                    </div>
                    <div style={{ flex: 1 }} />
                    <div
                      style={{
                        maxWidth: 100,
                        color: "#3552CC",
                        textAlign: "center",
                      }}
                    >
                      Detalles del Préstamo
                    </div>

                    <div style={{ flex: 1 }} />
                    <div
                      style={{
                        maxWidth: 100,
                        color: "#3552CC",
                        textAlign: "center",
                      }}
                    >
                      Validación de Indentidad
                    </div>
                    <div style={{ flex: 1 }} />
                    <div
                      style={{
                        maxWidth: 100,
                        color: "#3552CC",
                        textAlign: "center",
                      }}
                    >
                      Firma del Contrato
                    </div>
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                lg={4}
                sx={(theme) => ({
                  marginTop: "10px",
                  [theme.breakpoints.down("tablet")]: {
                    marginTop: "0px",
                    paddingTop: "10px !important",
                  },
                })}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  {recentPendingApplication?.procedure_completed &&
                  recentPendingApplication?.loan_completed &&
                  recentPendingApplication?.kyc_completed &&
                  recentPendingApplication?.contract_completed ? (
                    <div>
                      <button
                        style={{
                          cursor:
                            recentPendingApplication?.status === "DISBURSED" &&
                            "not-allowed",
                        }}
                        className={css.continueButton}
                        onClick={() => {
                          recentPendingApplication?.status !== "DISBURSED" &&
                            handleResume(
                              recentPendingApplication?.application_id
                            );
                        }}
                      >
                        editar
                      </button>
                    </div>
                  ) : (
                    <div style={{ marginTop: 5 }}>
                      <button
                        className={css.continueButton}
                        onClick={() => {
                          handleResume(
                            recentPendingApplication?.application_id
                          );
                        }}
                      >
                        Continuar
                      </button>
                    </div>
                  )}

                  <Box
                    sx={(theme) => ({
                      marginTop: "5px",
                      textDecoration: "underline",
                      cursor:
                        recentPendingApplication?.status === "DISBURSED"
                          ? "not-allowed"
                          : "pointer",
                      color: "#3552CC",
                      fontFamily: "SFUIText-bold",
                      textAlign: "center",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Medium",
                        fontSize: "14px",
                        textDecoration: "none",
                        marginTop: "12px",
                      },
                    })}
                    onClick={
                      recentPendingApplication?.status !== "DISBURSED" &&
                      handleOpen
                    }
                  >
                    Borrar
                  </Box>
                </Box>
              </Grid>
              <ModalComponent
                open={open}
                handleOpen={handleOpen}
                body={
                  <DeleteConfirmation
                    setOpen={setOpen}
                    application_id={recentPendingApplication?.application_id}
                    redirect={false}
                    fetchLmsBalanceSummary={fetchLmsBalanceSummary}
                  />
                }
                hideBackdrop={false}
                onBackdropClick={() => setOpen(false)}
              />
            </Grid>
          </Box>
        )}
        <Box
          sx={(theme) => ({
            display: "flex",
            justifyContent: "space-between",
            margin: "30px 0 20px",
            rowGap: "16px",
            flexWrap: "wrap",
            [theme.breakpoints.down("lg")]: {
              width: "62%",
              margin: "3rem 0 3rem 75px",
            },
            [theme.breakpoints.down("tablet")]: {
              width: "90%",
              margin: "2rem auto 1rem",
              fontSize: "12px",
            },
          })}
        >
          <h2 className={css.applicationText}>Transacciones Recientes</h2>
          <span className={css.showAll} onClick={handleViewTransaction}>
            Mostrar Todos({transactionList?.length})
          </span>
        </Box>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("lg")]: {
              width: "75%",
              marginLeft: "75px",
            },
            [theme.breakpoints.down("tablet")]: {
              width: "90%",
              margin: "1rem auto",
            },
          })}
        >
          {transactionList?.map((each) => (
            <Box
              sx={(theme) => ({
                display: "flex",
                alignItems: "center",
                marginBottom: "20px",
                [theme.breakpoints.down("lg")]: {
                  borderRadius: "10px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
                  padding: "1rem",
                },
                [theme.breakpoints.down("tablet")]: { padding: "10px" },
              })}
            >
              <Box
                sx={(theme) => ({
                  width: "60px",
                  height: "60px",
                  background: "#FFFFFF",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  "& #dollar": { display: "none" },
                  [theme.breakpoints.down("lg")]: {
                    boxShadow: "unset",
                    "& img": { display: "none" },
                    "& #dollar": { display: "block" },
                  },
                  [theme.breakpoints.down("tablet")]: {
                    width: "30px",
                    "& #dollar": { width: "16px", height: "16px" },
                  },
                })}
              >
                <img
                  src={MoneyBag}
                  style={{ height: 30 }}
                  alt="transaction-img"
                />
                <img alt="" src={DollarImg} id="dollar" />
              </Box>
              <Box
                sx={(theme) => ({
                  flex: 1,
                  paddingLeft: "20px",
                  [theme.breakpoints.down("tablet")]: {
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  },
                })}
              >
                <h4 className={css.transId}>{each.txn_id}</h4>
                <span className={css.transDate}>
                  {moment(each.txn_timestamp).format("MMMM DD, YYYY")}
                </span>
              </Box>

              <div style={{ textAlign: "right" }}>
                <span className={css.transAmt}>
                  ${currencyFormat(each.amt)}{" "}
                </span>
                <br />
                <span className={css.transStatus}>
                  {TRANSACTION_STATUS[each.status]}
                </span>
              </div>
            </Box>
          ))}
        </Box>
      </Box>
      <ModalComponent
        open={openCosigner}
        setOpenCoSigner={setOpenCoSigner}
        body={
          <CoSignerForm
            setOpenCoSigner={setOpenCoSigner}
            application_id={application_id}
            redirect={true}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpenCoSigner(false)}
      />
    </div>
  );
};

export default ApplicationStatus;
