import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ManIcon from "../../assets/PersonalInformation/manIcon.svg";
import WomanIcon from "../../assets/PersonalInformation/womanIcon.svg";
import DateinputComponent from "../../components/sharedComponents/DateInputComponent";
import CustomInputText from "../../components/sharedComponents/InputText";
import TextInput from "../../features/installmentLoan/components/TextInput";
import { verifyPassword } from "../../store/slices/auth/actions";
import {
  getCurpInfo,
  getZipCodeInfo,
} from "../../store/slices/onboarding/actions";
import { curpValidator, rfcValidator } from "../../utils";

import { CheckCircle, CheckRounded, Circle } from "@mui/icons-material";
import { startCase, toLower } from "lodash";
import moment from "moment";
import "../../../src/input.css";
import Logo from "../../assets/Logos/gradient-logo.svg";
import Geolocation from "../../components/ModalContents/Geolocation";
import ButtonComponent from "../../components/sharedComponents/ButtonComponent";
import DropdownMenu from "../../components/sharedComponents/DropdownMenu";
import InputPhone from "../../components/sharedComponents/InputPhone";
import InputText from "../../components/sharedComponents/InputText";
import ModalComponent from "../../components/sharedComponents/ModalComponent";
import SplashValidation from "../../components/sharedComponents/SplashValidation";
import { conocerteMejor } from "../../constants";
import { API_STATUS, URBANIST_FONT } from "../../constants/common";
import {
  coSignerGeneraleInfoPage,
  generaleInfoPage,
} from "../../constants/gtm";
import { ROUTES } from "../../constants/routes";
import { setEmailAndName } from "../../store/slices/auth";
import {
  getZipCodeInfoCosigner,
  selectId,
  selectIsCosigner,
  setCosignerGeneralInformation,
} from "../../store/slices/cosigner";
import {
  getUserGeneralInformation,
  saveUserGeneralInformation,
  selectUserGeneralInformation,
  selectUserGeneralInformationV2,
  selectUserId,
} from "../../store/slices/users";
import { getUserGeneralInformationV2 } from "../../store/slices/users/actions";
import { GTMClick } from "../../utils/gtm";
import { errorToast, successToast } from "../../utils/toast";
import useStyle from "./styles";

const { ocupacion, costo, ingreso } = conocerteMejor;

function GeneralInformation() {
  const [validateCurp, setValidateCurp] = useState(false);
  const [validateRfc, setValidateRfc] = useState(false);

  const [close, setClose] = useState(false);
  const nowDate = new Date();

  const classes = useStyle();
  const history = useHistory();
  const [wasInitialized, setWasInitialized] = React.useState(false);
  const dispatch = useDispatch();
  const id_client = useSelector(selectUserId);
  const id_cosigner = useSelector(selectId);
  const userGeneralInformationV2 = useSelector(selectUserGeneralInformationV2);

  const isUserCosigner = selectIsCosigner();

  const [gotDataFromGetApi, setGotDataFromGetApi] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getData = async () => {
    await dispatch(getUserGeneralInformationV2()).unwrap();
    setLoading(false);
  };

  const getTitle = (str) => {
    return startCase(toLower(str));
  };

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  useEffect(() => {
    if (
      userGeneralInformationV2.first_name ||
      userGeneralInformationV2.last_name ||
      userGeneralInformationV2.maternal_name ||
      userGeneralInformationV2.date_of_birth ||
      userGeneralInformationV2.gender ||
      userGeneralInformationV2.rfc ||
      userGeneralInformationV2.curp
    ) {
      userGeneralInformationV2.first_name &&
        setFieldValue("name", userGeneralInformationV2.first_name);
      userGeneralInformationV2.last_name &&
        setFieldValue("paternalSurname", userGeneralInformationV2.last_name);
      userGeneralInformationV2.maternal_name &&
        setFieldValue(
          "maternalSurname",
          userGeneralInformationV2.maternal_name
        );
      userGeneralInformationV2.gender &&
        setFieldValue("gender", userGeneralInformationV2.gender);

      userGeneralInformationV2.date_of_birth &&
        setFieldValue(
          "date_of_birth",
          userGeneralInformationV2.date_of_birth &&
            userGeneralInformationV2.date_of_birth !== "None"
            ? userGeneralInformationV2.date_of_birth
            : ""
        );

      userGeneralInformationV2.curp &&
        setFieldValue("curp", userGeneralInformationV2.curp);

      userGeneralInformationV2.rfc &&
        setFieldValue("rfc", userGeneralInformationV2.rfc);

      setGotDataFromGetApi(true);
    }
  }, [userGeneralInformationV2]);

  const validate = (values) => {
    let errors = {};
    setWasInitialized(true);

    if (rfcValidator(values.rfc)) {
      setValidateRfc(true);
      delete errors.rfc;
    } else {
      setValidateRfc(false);
      errors.rfc = "El RFC no es válido";
    }

    if (curpValidator(values.curp.toUpperCase())) {
      setValidateCurp(true);
      delete errors.curp;
    } else {
      setValidateCurp(false);
      errors.curp = "La CURP no es válida";
    }

    values.occupation === ""
      ? (errors.occupation = "Este campo es requerido")
      : delete errors.occupation;
    if (values.occupation === "Empleado") {
      values.companyName === ""
        ? (errors.companyName = "Este campo es requerido")
        : delete errors.companyName;
      values.otherOcuppation = "";
    } else {
      delete errors.companyName;
    }
    if (values.occupation === "Otro") {
      values.otherOcuppation === ""
        ? (errors.otherOcuppation = "Este campo es requerido")
        : delete errors.otherOcuppation;
      values.companyName = "";
    } else {
      delete errors.otherOcuppation;
    }
    !values.name
      ? (errors.name = "Este campo es requerido")
      : values.name.length < 3
      ? (errors.name = "mas de 2 caracters")
      : delete errors.name;
    !values.maternalSurname
      ? (errors.maternalSurname = "Este campo es requerido")
      : values.maternalSurname.length < 3
      ? (errors.maternalSurname = "mas de 2 caracters")
      : delete errors.maternalSurname;
    !values.paternalSurname
      ? (errors.paternalSurname = "Este campo es requerido")
      : values.paternalSurname.length < 3
      ? (errors.paternalSurname = "mas de 2 caracters")
      : delete errors.paternalSurname;

    if (values.gender === "") errors.gender = "El campo es requerido";
    if (!values.date_of_birth) {
      errors.date_of_birth = "Selecciona una fecha";
    } else if (!moment(values.date_of_birth).isValid()) {
      errors.date_of_birth = "El formato de la fecha no es correcto";
      touched.date_of_birth = true;
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      paternalSurname: "",
      maternalSurname: "",
      gender: "",
      date_of_birth: "",
      rfc: "",
      curp: "",
    },
    validate,
    onSubmit: async (values) => {
      setIsSubmitting(true);
      let payload = {
        id: isUserCosigner ? id_cosigner : id_client,
        data: {
          corresponsibleData: {
            first_name: values.name,
            last_name: values.paternalSurname,
            maternal_name: values.maternalSurname,
            gender: values.gender,
            date_of_birth: values.date_of_birth,
            curp: values.curp,
            rfc: values.rfc
              .replaceAll(/[^a-zA-Z0-9 ]/g, "")
              .replaceAll(/ /g, ""),
            createOn: new Date(),
            gotDataFromGetApiValue: gotDataFromGetApi,
          },
        },
      };
      if (isUserCosigner) {
        GTMClick(coSignerGeneraleInfoPage.continue);
        const data = setCosignerGeneralInformation(
          payload.data.corresponsibleData
        ).unwrap();

        if (data?.status === API_STATUS.SUCCESS) {
          // dispatch(
          //   setEmailAndName({
          //     first_name: values.name,
          //     last_name: values.paternalSurname,
          //   })
          // );
          history.push(ROUTES.processInformation);
        } else if (data?.status === API_STATUS.ERROR) {
          errorToast(data?.meta?.errorMessage);
        }
      } else {
        GTMClick(generaleInfoPage.continue);
        dispatch(setEmailAndName(payload.data.corresponsibleData));
        const data = await dispatch(
          saveUserGeneralInformation(payload.data.corresponsibleData)
        ).unwrap();
        if (data?.status === API_STATUS.SUCCESS) {
          history.push(ROUTES.professionalInformation);
        } else if (data?.status === API_STATUS.ERROR) {
          errorToast(data?.meta?.errorMessage);
        }
        setIsSubmitting(false);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (values?.curp) {
      if (curpValidator(values.curp.toUpperCase())) {
        fetchData();
      }
    }
  }, [values?.curp]);

  const fetchData = async () => {
    const getData = () => {
      if (isUserCosigner) {
        return dispatch(getZipCodeInfoCosigner(values?.cp)).unwrap();
      } else {
        return dispatch(getCurpInfo(values?.curp)).unwrap();
      }
    };
    const { status, meta, data } = await getData();
    if (status === API_STATUS.SUCCESS) {
      formik.setFieldValue("gender", getTitle(data?.gender || ""));
      formik.setFieldValue(
        "date_of_birth",
        data?.dob?.replaceAll("/", "-") || ""
      );
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const handleModalGeolocation = () => {
    setClose(!close);
  };

  let isValidForm =
    Object.entries(errors).length === 0 && wasInitialized ? true : false;

  return loading ? (
    <SplashValidation image={1} trueRedirect={false} />
  ) : (
    <div className={classes.pageContainer}>
      <Box className={classes.headerPageBackground}>
        <img src={Logo} alt="logo" className={classes.logo} />
        <Typography variant="h2" className={classes.textTitle}>
          ¡Cuéntanos un poco sobre ti!
        </Typography>
      </Box>
      <div
        style={{
          height: 9,
          background: "linear-gradient(270deg, #1B63DB 19.79%, #0ACC97 100%)",
        }}
      />
      <form onSubmit={handleSubmit}>
        <Box
          className={classes.formContainer}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid
            container
            width={{ lg: 780 }}
            rowSpacing={{ lg: 1.75 }}
            columnSpacing={{ sm: 1.75, lg: 10 }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput labelMargin">
                <InputText
                  id="curp"
                  label="CURP"
                  name="curp"
                  value={values.curp.toUpperCase()}
                  errors={errors.curp}
                  helperText={
                    values.curp.length === 18 && !validateCurp
                      ? "La CURP no es válida"
                      : ""
                  }
                  inputProps={{
                    maxLength: 18,
                    className: classes.inputProps,
                  }}
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  handleBlur={handleBlur}
                  touched={touched.curp}
                  labelStyle={{
                    color: "#000",
                    fontFamily: URBANIST_FONT.REGULAR,
                  }}
                />
                {/* <Grid item xs={12} lg={6}> */}
                <Typography variant="body1" className={classes.textCurpQuery}>
                  Si no conoces tu CURP{" "}
                  <a
                    href="https://www.gob.mx/curp/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.link}
                  >
                    consúltalo aquí
                  </a>
                </Typography>
              </div>
              {/* </Grid> */}
            </Grid>

            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput labelMargin">
                <InputText
                  id="rfc"
                  label="RFC"
                  name="rfc"
                  value={values.rfc.toUpperCase()}
                  errors={errors.rfc}
                  touched={touched.rfc}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  labelStyle={{ color: "#000" }}
                />
              </div>
            </Grid>

            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput labelMargin ">
                <InputText
                  label="Nombre(s)"
                  name="name"
                  handleChange={handleChange}
                  value={values.name}
                  errors={errors.name}
                  touched={touched.name}
                  handleBlur={handleBlur}
                  labelStyle={{ color: "#000" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput labelMargin">
                <InputText
                  label="Apellido Paterno"
                  name="paternalSurname"
                  handleChange={handleChange}
                  value={values.paternalSurname}
                  errors={errors.paternalSurname}
                  touched={touched.paternalSurname}
                  handleBlur={handleBlur}
                  labelStyle={{ color: "#000" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput labelMargin">
                <InputText
                  stylesInput={classes.formControl}
                  label="Apellido Materno"
                  name="maternalSurname"
                  handleChange={handleChange}
                  value={values.maternalSurname}
                  errors={errors.maternalSurname}
                  touched={touched.maternalSurname}
                  handleBlur={handleBlur}
                  labelStyle={{ color: "#000" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} lg={6}></Grid>

            <Grid item xs={12} lg={6} sm={6}>
              <div className="myinput labelMargin">
                <Typography variant="body" className={classes.textGender}>
                  Género
                </Typography>
                <div style={{ display: "flex" }}>
                  <RadioGroup
                    aria-label="gender"
                    defaultValue="female"
                    name="gender"
                    className={classes.radioGender}
                    onChange={handleChange}
                    row
                  >
                    <FormControlLabel
                      value="Mujer"
                      control={<Radio checked={values.gender === "Mujer"} />}
                      label={
                        <span className={classes.formControlRadio}>Mujer </span>
                      }
                    />
                    <FormControlLabel
                      value="Hombre"
                      control={<Radio checked={values.gender === "Hombre"} />}
                      label={
                        <span className={classes.formControlRadio}>Hombre</span>
                      }
                    />
                  </RadioGroup>
                </div>
              </div>
            </Grid>

            <Grid item xs={12} lg={6} sm={6}>
              <Typography variant="body" className={classes.textGender}>
                Fecha de nacimiento
              </Typography>
              <div className="dateInput">
                <DateinputComponent
                  name="date_of_birth"
                  maxDateValue={new Date()}
                  dateValue={
                    values.date_of_birth
                      ? new Date(values.date_of_birth.replaceAll("-", "/"))
                      : null
                  }
                  handleDate={(value) => {
                    if (value) {
                      setFieldValue(
                        "date_of_birth",
                        moment(new Date(value)).format("YYYY-MM-DD")
                      );
                    }
                  }}
                  errors={errors.date_of_birth}
                  touched={touched.date_of_birth}
                  onBlur={handleBlur}
                  hasDate={false}
                  minDate={nowDate.setFullYear(nowDate.getFullYear() - 80)}
                />
              </div>
            </Grid>

            <Grid item xs={12} lg={6} display="flex">
              {values.occupation === "Empleado" && (
                <InputText
                  stylesInput={classes.formControl}
                  label="Nombre de la Empresa"
                  name="companyName"
                  handleChange={handleChange}
                  value={values.companyName}
                  errors={errors.companyName}
                  touched={touched.companyName}
                  handleBlur={handleBlur}
                  centeredLabel={true}
                />
              )}
              {values.occupation === "Otro" && (
                <InputText
                  stylesInput={classes.formControl}
                  label="Especifica"
                  name="otherOcuppation"
                  handleChange={handleChange}
                  value={values.otherOcuppation}
                  errors={errors.otherOcuppation}
                  touched={touched.otherOcuppation}
                  handleBlur={handleBlur}
                  centeredLabel={true}
                />
              )}
            </Grid>
          </Grid>
          <Box mt={3}>
            <ButtonComponent
              type="submit"
              text="Continuar"
              disabled={!isValidForm}
              loading={isSubmitting}
              className={classes.continueButton}
              style={{
                width: "auto",
                padding: "0px 92px",
              }}
            />
          </Box>
          <Box mt={1} className={classes.tncContainer} mb={4}>
            Al hacer clic en continuar, acepta nuestros{" "}
            <a href={ROUTES.tnc} target="_blank">
              Términos y condiciones
            </a>{" "}
            <a href={ROUTES.privacyPolicy} target="_blank">
              Aviso de privacidad
            </a>{" "}
            Registro de{" "}
            <a
              onClick={() => {
                handleModalGeolocation();
              }}
              className={classes.Geolocation}
            >
              geolocalización
            </a>
            .
          </Box>
        </Box>
      </form>
      <ModalComponent
        open={close}
        body={<Geolocation setClose={setClose} />}
        hideBackdrop={false}
        onBackdropClick={() => setClose(false)}
      />
    </div>
  );
}

export default GeneralInformation;
