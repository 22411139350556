import { makeStyles, createStyles } from "@mui/styles";
import { customColor } from "../../../../theme/customColor";

interface styleProps {
  rightIcon?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    textInputContainerStyle: {
      display: "flex",
      flexDirection: "row",
    },
    iconBoxStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "40px",
      width: "40px",
      minWidth: "40px",
      borderRadius: "360px",
      backgroundColor: "#00B4BE",
      marginRight: "18px",
    },
    rightIconBoxStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "40px",
      width: "40px",
      minWidth: "40px !important",
      maxWidth: "40px !important",
      color: "rgba(0, 0, 0, 0.54) !important",
      marginLeft: "-40px !important",
    },
    textInputStyle: {
      height: "40px",
      width: "350px",
      minWidth: "35ch",
      padding: "0px 14px",
      paddingRight: ({ rightIcon }: styleProps) =>
        rightIcon ? "54px" : "14px",
      outline: "none",
      border: "1px solid #CCCCCC",
      borderRadius: "8px",
      color: "black",
      "&:focus": {
        border: "2px solid #808080",
      },
      "&::placeholder": {
        color: "#808080" /* Chrome, Firefox, Opera, Safari 10.1+ */,
        opacity: 1 /* Firefox */,
      },
      "&:-ms-input-placeholder": {
        color: "#808080" /* Internet Explorer 10-11 */,
      },
      "&::-ms-input-placeholder": {
        color: "#808080" /* Microsoft Edge */,
      },
    },
    iconBlank: {
      backgroundColor: "transparent",
    },
    label: {
      color: customColor.accentBlue,
      paddingLeft: 10,
      paddingTop: 5,
      paddingBottom: 5,
    },
  })
);

export default useStyles;
