import { URBANIST_FONT } from "../../constants/common";
import { COLOR_CONSTANTS } from "../../constants/styleConstants";

const font = {
  fontFamily: URBANIST_FONT.REGULAR,
};

export const themeTypography = {
  fontFamily: URBANIST_FONT.REGULAR,
  h1: {
    fontStyle: `normal`,
    fontSize: 45,
    fontWeight: 700,
    ...font,
  },
  h2: {
    fontSize: 32,
    fontStyle: `normal`,
    fontWeight: 700,
    lineHeight: 1.2,
    ...font,
  },
  h3: {
    fontSize: 26,
    fontStyle: `normal`,
    ...font,
  },
  h4: {
    fontStyle: `normal`,
    fontSize: 34,
    ...font,
  },
  h5: {
    fontSize: 14,
    fontWeight: 500,
    color: COLOR_CONSTANTS.MAIN_BLUE,
    fontStyle: `normal`,
    ...font,
  },
  h6: {
    fontSize: 20,
    fontStyle: `normal`,
    ...font,
  },
  body1: {
    fontSize: 14,
    fontStyle: `normal`,
    ...font,
  },
  bodyBlack: {
    fontWeight: 800,
    fontSize: 14,
    fontStyle: `normal`,
    ...font,
  },
  body2: {
    fontSize: 13,
    fontStyle: `normal`,
    ...font,
  },
  subtitle1: {
    fontStyle: `normal`,
    fontWeight: `normal`,
    fontSize: 16,
    ...font,
  },
  subtitle2: {
    fontSize: 14,
    fontStyle: `normal`,
    ...font,
  },
  // button: {
  //   textTransform: 'none',
  // },
};
