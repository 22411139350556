import moment from "moment";
import { Box } from "@mui/material";
import { currencyFormat } from "../../../../utils/index";

const Payments = (props) => {
  const { each } = props;

  return (
    <Box
      sx={(theme) => ({
        "& #payment_details": { display: "none" },
        [theme.breakpoints.down("tablet")]: {
          "& #payment_table": { display: "none" },
          "& #payment_details": { display: "block" },
        },
      })}
    >
      <Box
        sx={(theme) => ({
          background: "#FFFFFF",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
          overflow: "hidden",
          "& td": { paddingLeft: "40px" },
          "& thead td": { fontSize: "18px" },
          [theme.breakpoints.down("lg")]: {
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            "& td": {
              paddingLeft: "20px",
              textAlign: "center",
              "&:last-child": { paddingRight: "20px" },
            },
            "& thead td": { fontSize: "16px", fontFamily: "Urbanist-Semibold" },
            "& tbody td": { fontFamily: "Urbanist-Medium" },
          },
        })}
        id="payment_table"
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead
            style={{
              backgroundColor: "#3552CC",
              boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.25)",
              color: "#fff",
              // fontSize: 18,
              height: 60,
            }}
          >
            <tr>
              <td>Plazo</td>
              <td>Fecha</td>
              <td>Amortización de principal</td>
              <td>Intereses</td>
              <td>IVA</td>
              <td>Saldo por pagar</td>
            </tr>
          </thead>
          <tbody>
            {each.items.map((item) => (
              <tr style={{ height: 60, borderBottom: "1px solid #E6E7E8" }}>
                <td>{item.term}</td>
                <td>{moment(item.due_date).format("yyyy-MM-DD hh:mm")}</td>
                <td>${currencyFormat(item.principal_amt)}</td>
                <td>${currencyFormat(item.interest_amt)}</td>
                <td>${currencyFormat(item.vat_amt)}</td>
                <td>${currencyFormat(item.term_due_amt)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Box>

      <Box id="payment_details">
        {each.items.map((item) => (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "1rem",
              background: "#FFFFFF",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "20px",
              overflow: "hidden",
              marginBottom: "1rem",
              padding: "1rem",
            }}
          >
            <Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-SemiBold",
                  fontSize: "12px",
                  color: "#3552CC",
                  marginBottom: "5px",
                }}
              >
                Plazo
              </Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                }}
              >
                {item.term}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-SemiBold",
                  fontSize: "12px",
                  color: "#3552CC",
                  marginBottom: "5px",
                }}
              >
                Fecha
              </Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                }}
              >
                {moment(item.due_date).format("yyyy-MM-DD hh:mm")}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-SemiBold",
                  fontSize: "12px",
                  color: "#3552CC",
                  marginBottom: "5px",
                }}
              >
                Amortización de principal
              </Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                }}
              >
                ${item.principal_amt}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-SemiBold",
                  fontSize: "12px",
                  color: "#3552CC",
                  marginBottom: "5px",
                }}
              >
                Intereses
              </Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                }}
              >
                ${item.interest_amt}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-SemiBold",
                  fontSize: "12px",
                  color: "#3552CC",
                  marginBottom: "5px",
                }}
              >
                IVA
              </Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                }}
              >
                ${item.vat_amt}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-SemiBold",
                  fontSize: "12px",
                  color: "#3552CC",
                  marginBottom: "5px",
                }}
              >
                Saldo por pagar
              </Box>
              <Box
                sx={{
                  fontFamily: "Urbanist-Bold",
                  fontSize: "14px",
                }}
              >
                ${item.term_due_amt}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Payments;
