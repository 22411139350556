import { useState } from "react";
import { Box, useTheme, useMediaQuery, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { GTMClick } from '../../../utils/gtm';
import { homePage } from '../././../../constants/gtm'

import MettaLogoHorizontal from "../../../assets/Logos/MettaLogoHorizontal.svg";
import LogInIcon from "./LogInIcon.svg";
import Credit from "./creditIcon.svg";
import LoginWhite from "./loginWhite.svg";
import HamburgerMenu from "./Hamburger.svg";
import useStyles from "./styles";

const Menu = () => {
  const css = useStyles();
  const history = useHistory();

  return (
    <Box className={css.headerButtonsContainer}>
      <button
        className={css.headerLoginButtonStyle}
        onClick={() => {
          GTMClick(homePage.loginHeader)
          history.push("/login");
        }}
      >
        <Typography className={css.headerLoginButtonTextStyle}>
          Log in
        </Typography>
        <img src={LogInIcon} />
      </button>
      <button
        className={css.headerApplyCreditButtonStyle}
        onClick={() => {
          GTMClick(homePage.signUpHeader)
          history.push("/registro");
        }}
      >
        Solicitar crédito
      </button>
    </Box>
  );
};

const MobileMenu = () => {
  const css = useStyles();
  const history = useHistory();
  return (
    <Box className={css.mobileMenu}>
      <Box className={css.btnContainer}>
        <button
          onClick={() => {
            GTMClick(homePage.loginHeader)
            history.push("/login");
          }}
        >
          <img src={LoginWhite} />
          <span>Log in</span>
        </button>
      </Box>
      <Box className={css.btnContainer}>
        <button
          onClick={() => {
            GTMClick(homePage.signUpHeader)
            history.push("/registro");
          }}
        >
          <img src={Credit} />
          <span>Solicitar crédito</span>
        </button>
      </Box>
    </Box>
  );
};
const Header = () => {
  const css = useStyles();
  const theme = useTheme();
  const [showMenu, setShowMenu] = useState(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <Box className={css.headerMainContainerMobile}>
        <Box
          display="flex"
          flexDirection="row"
          className={"header-row"}
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={MettaLogoHorizontal} />
          <Box>
            <img src={HamburgerMenu} onClick={() => setShowMenu(!showMenu)} />
          </Box>
        </Box>

        {showMenu && <MobileMenu />}
      </Box>
    );
  }
  return (
    <>
      <Box className={css.headerMainContainer}>
        <img src={MettaLogoHorizontal} />
        <Menu />
      </Box>
    </>
  );
};

export default Header;
