import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddCircle from "@mui/icons-material/AddCircle";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import ButtonComponent from "../../../components/sharedComponents/ButtonComponent";
import CenteredContent from "../../../components/sharedComponents/CenteredContent";
import DropdownMenu from "../../../components/sharedComponents/DropdownMenu";
import InputPhone from "../../../components/sharedComponents/InputPhone";
import InputText from "../../../components/sharedComponents/InputText";
import { conocerteMejor } from "../../../constants";
import { API_STATUS } from "../../../constants/common";
import { INSTALLMENT_LOAN_ROUTES, ROUTES } from "../../../constants/routes";
import { getBalanceSummary } from "../../../features/installmentLoan/store/installmentLoan";
import { useAppDispatch } from "../../../store";
import {
  selectCosignerDetails,
  selectCreditDecision,
} from "../../../store/slices/users";
import {
  addCoSigner,
  deleteApplicationId,
  getAllApplication,
  getCoSigner,
  getCoSignerStatus,
} from "../../../store/slices/users/actions";
import { errorToast, successToast } from "../../../utils/toast";
import TermConditionTextStyles from "./TermsAndConditions.styles";
import { validationSchema } from "./validation";

const { ingreso } = conocerteMejor;

const CoSignerForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [countryCodeLength, setCountryCodeLength] = useState("");
  const { setOpenCoSigner, application_id, redirect } = props;
  const classes = TermConditionTextStyles();

  useEffect(() => {
    dispatch(getCoSigner());
  }, []);

  const CosignerInfo = useSelector(selectCosignerDetails);
  const creditDecision = useSelector(selectCreditDecision);

  useEffect(() => {
    if (Object.values(CosignerInfo).length) {
      const phoneLength = CosignerInfo.phone_number?.slice(2) || "";
      formik.setValues(
        {
          name: CosignerInfo.first_name,
          lastname: CosignerInfo.last_name,
          motherLastname: CosignerInfo.maternal_name,
          email: CosignerInfo.email,
          relation: CosignerInfo.relation_with_user,
          phoneNumber: CosignerInfo.phone_number,
          monthlyIncome: CosignerInfo.monthly_income_range,
          lengthPhone: phoneLength,
        },
        true
      );
      setCountryCodeLength(phoneLength);
    }
  }, [CosignerInfo]);

  const validate = (values) => {
    let _errors = {};
    if (
      values.email?.toLowerCase() ===
      creditDecision?.user_details?.email?.toLowerCase()
    ) {
      _errors.email =
        "El corresponsable de tu crédito debe ser una persona distinta al solicitante. Has ingresado la misma dirección de correo electrónico para ambos; por favor ingresa una dirección de email válida.";
    }
    if (countryCodeLength.length === 0) {
      _errors.phoneError = "Este campo es requerido";
    } else if (countryCodeLength.length < 9) {
      _errors.phoneError = "numeros minios requeridos 10";
    }
    if (
      "+" + values.phoneNumber ===
      creditDecision?.user_details?.phone_number
    ) {
      _errors.phoneError =
        "El corresponsable de tu crédito debe ser una persona distinta al solicitante. Has ingresado el mismo número de teléfono para ambos; por favor ingresa número telefónico válido.";
    }
    return _errors;
  };

  const formik = useFormik({
    initialValues: {
      lastname: "",
      motherLastname: "",
      relation: "",
      name: "",
      phoneNumber: "",
      lengthPhone: "",
      email: "",
      monthlyIncome: "",
    },
    validate,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: async () => {
      const res = await dispatch(
        addCoSigner({
          first_name: values.name,
          last_name: values.lastname,
          maternal_name: values.motherLastname,
          phone_number: values.phoneNumber,
          email: values.email,
          relation_with_user: values.relation,
          monthly_income_range: values.monthlyIncome,
        })
      );

      if (res?.payload?.status === API_STATUS.SUCCESS) {
        successToast(res?.payload?.data?.Status);
        setOpenCoSigner(false);
        dispatch(getCoSignerStatus());
      } else {
        errorToast(res?.payload?.data?.Status);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
  } = formik;

  const handleClose = () => {
    setOpenCoSigner(false);
  };

  return (
    <div className={classes.mainContainer} style={{ overflow: "auto" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1
          style={{
            fontSize: 36,
            color: "#6B7779",
            fontFamily: "SFUIText-bold",
            textAlign: "left",
            marginTop: 0,
          }}
        >
          Detalles de Obligado Solidario
        </h1>
        <AddCircle
          style={{
            color: "#3552CC",
            transform: "rotate(45deg)",
            zIndex: 20,
            cursor: "pointer",
            fontSize: 36,
          }}
          onClick={handleClose}
        />
      </div>
      <div
        style={{
          color: "#3552CC",
          fontFamily: "SFUIText-bold",
          fontSize: 36,
          marginTop: 30,
        }}
      >
        <Grid container columnSpacing={4} rowSpacing={4}>
          <Grid
            item
            xs={12}
            tablet={6}
            className="myinput myinput-2 labelMargin"
          >
            <InputText
              id="Relación con el codeudor"
              label="Relación con el codeudor"
              name="relation"
              value={values.relation}
              errors={errors.relation}
              touched={touched.relation}
              handleChange={handleChange("relation")}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
            tablet={6}
            className="myinput myinput-2 labelMargin"
          >
            <InputText
              id="Nombre(s)"
              label="Nombre(s)"
              name="name"
              value={values.name}
              errors={errors.name}
              touched={touched.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
            tablet={6}
            className="myinput myinput-2 labelMargin"
          >
            <InputText
              id="Apellido Paterno"
              label="Apellido Paterno"
              name="lastname"
              value={values.lastname}
              errors={errors.lastname}
              touched={touched.lastname}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
            tablet={6}
            className="myinput myinput-2 labelMargin"
          >
            <InputText
              id="Apellido Materno"
              label="Apellido Materno"
              name="motherLastname"
              value={values.motherLastname}
              errors={errors.motherLastname}
              touched={touched.motherLastname}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Grid>

          <Grid item xs={12} tablet={6}>
            <Typography
              variant="h5"
              style={{
                fontSize: 16,
                fontFamily: "SFUIText-semibold",
              }}
            >
              Número de teléfono del consultorio
            </Typography>
            <div className="telInput telInput-2">
              <InputPhone
                country="mx"
                name="phoneNumber"
                id="phoneNumber"
                handleChange={(e, ...props) => {
                  setCountryCodeLength(e.slice(props[0].dialCode.length));
                  setFieldValue("lengthPhone", countryCodeLength);
                  setFieldValue("phoneNumber", e);
                }}
                value={values.phoneNumber}
                errors={errors.phoneError}
                touched={touched.phoneNumber}
                handleBlur={handleBlur}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            tablet={6}
            className="myinput myinput-2 labelMargin"
          >
            <InputText
              id="Correo Electrónico"
              label="Correo Electrónico"
              name="email"
              value={values.email}
              errors={errors.email}
              touched={touched.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </Grid>
          <Grid
            item
            xs={12}
            tablet={6}
            className="myinput myinput-2 labelMargin"
          >
            <DropdownMenu
              label="Ingreso Mensual"
              optionsObject={ingreso}
              name="monthlyIncome"
              handleChange={handleChange}
              value={values.monthlyIncome}
              onBlur={handleBlur}
              touched={touched.monthlyIncome}
              errors={errors.monthlyIncome}
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginTop: 40 }}>
        <CenteredContent>
          <ButtonComponent
            type="submit"
            text="Continuar"
            onClick={handleSubmit}
            disabled={Object.entries(formik.errors).length}
          />
        </CenteredContent>
        {/* <button
          style={{
            background: "#979797",
            color: "#FFFFFF",
            padding: "13px 112px",
            borderRadius: 8,
          }}
        >
          Continuar
        </button> */}
      </div>
    </div>
  );
};

export default CoSignerForm;
