import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  headerMainContainerMobile: {
    [theme.breakpoints.down(1000)]: {
      zIndex: 1,
      flex: 1,
      padding: "0 22px",
      width: "100%",
      "& > .header-row": {
        height: 65,
      },

      background: "rgba(90, 123, 215, 0.2)",
      color: "#fff",
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  drawer: {
    position: "absolute",
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    top: 0,
  },
  mobileMenu: {
    [theme.breakpoints.up(1000)]: {
      display: "none",
    },
    [theme.breakpoints.down(1000)]: {
      width: "70%",
      background: "#3552CC",
      padding: 10,
      paddingBottom: 30,
      flexDirection: "column",
      display: "flex",
      alignItems: "flex-start",
      marginRight: 22,
      "& > div": {
        padding: "10px 30px",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        alignItems: "center",
        "& > img": {
          marginLeft: 5,
          marginTop: 2,
        },
      },
    },
  },
  btnContainer: {
    [theme.breakpoints.down(1000)]: {
      margin: `${theme.spacing(2)} 0`,
      "& > button": {
        background: "transparent",
        border: "none",
        alignItems: "center",
        display: "flex",
        fontWeight: "600",
        fontFamily: "SFUIText-Regular",
        "& > span": {
          color: "#FFF",
          fontSize: 18,
          lineHeight: "21px",
          marginLeft: 10,
        },
        "& > img": {
          width: 25,
          height: 25,
        },
      },
    },
  },
}));

export default styles;
