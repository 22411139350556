import { createSlice } from "@reduxjs/toolkit";
import { API_STATUS } from "../../../constants/common";
import { getStepperProcedureInfo } from "../../../features/installmentLoan/store/installmentLoan";

import {
  convertURIToImageData,
  getAllApplication,
  getApplicationStepper,
  getCoSigner,
  getCoSignerStatus,
  getCreditDecision,
  getDashboardDetails,
  getLmsBalanceSummary,
  getLoanDetailList,
  getLoanDetails,
  getPatientGroup,
  getSignature,
  getStepperApplicationSummary,
  getTransactionList,
  getUserAccessToken,
  getUserGeneralInformation,
  getUserGeneralInformationV2,
  getUserPromoCode,
  saveTakeLoan,
  saveUseRequestedCreditInformation,
  saveUserFinalProcessInformation,
  saveUserGeneralInformation,
  saveUserIdentificationInformation,
  saveUserKnowYouBetter,
  saveUserPlaceOfBirth,
  saveUserProcedureInformation,
  saveUserProfessionalDetails,
  saveUserRegisterInfo,
  saveUserRfcAndCurp,
} from "./actions";

const initialState = {
  userRegisterInfo: {
    email: "",
    password: "",
    id: "",
    createOn: "",
  },
  userGeneralInformationV2: {
    name: "",
    paternalSurname: "",
    maternalSurname: "",
    curp: "",
    rfc: "",
    gender: "",
    date_of_birth: "",
    occupation: "",
    occupation_others: "",
    company_name: "",
    monthly_income_range: "",
    address: {
      city: "",
      colony: "",
      street: "",
      municipality: "",
      state: "",
      cp: "",
      inside_no: "",
      outside_no: "",
    },
    createOn: "",
  },
  userProcedureInformation: {
    hasNotMedic: false,
    medicName: "",
    medicLastName: "",
    hasNotDate: false,
    appointmentDate: "",
    appointmentTime: "",
    procedureType: "",
    procedureToPerform: null,
    procedureCost: "",
    requestedAmount: "",
    createOn: "",
  },
  userRequestedCreditInformation: {
    monthlyInstallments: "",
    monthlyPayment: "",
    createOn: "",
  },
  userTermsAndServices: {
    acceptedTermsAndConditions: false,
    acceptedGeolocalization: false,
    acceptedNoticeOfPrivacy: false,
    createOn: "",
  },
  userRfcAndCurp: {
    rfc: "",
    curp: "",
    createOn: "",
  },
  userPlaceofBirth: {
    stateofBirth: "",
    gender: "",
    dateOfBirth: "",
    street: "",
    externalNumber: "",
    internalNumber: "",
    suburb: "",
    state: "",
    municipality: "",
    zip: "",
    createOn: "",
  },
  userKnowYouBetter: {
    occupation: "",
    companyName: "",
    payer: "",
    monthlyIncome: "",
    hasCoSigner: false,
    createOn: "",
  },
  userCoSignerGeneralInformation: {
    name: "",
    lastName: "",
    mothersLastName: "",
    cellphoneNumber: "",
    email: "",
    monthlyIncome: "",
    createOn: "",
  },
  userCreditHistoryInformation: {
    bankName: "",
    accountType: "",
    user: "",
    password: "",
    verifyCreditHistoryAuth: false,
    accessToBankAccountAuth: false,
    acceptedNoticeOfPrivacy: false,
    createOn: "",
  },
  userIdentificationInformation: {
    typeId: "",
    frontFileName: "",
    rearFileName: "",
    createOn: "",
  },
  userFinalProcessInformation: {
    signature: "",
    directDebitAuth: false,
    paymenthPreference: "",
    createOn: "Roger19V@gmail.com",
  },
  folioNumber: {
    folio: "",
  },
  verifiedEmail: {
    verified: false,
  },
  isNotInRegister: false,
  userToken: null,
  isAuthorized: false,
  hasFolio: false,
  processComplete: false,
  emailVerifyed: false,
  dashboardDetails: null,
  userAccessToken: null,
  LmsBalanceSummary: null,
  takeLoanDetail: null,
  stepperStatus: {},
  loanDetailsStepper: {},
  stepperProcedureInfo: {},
  allApplication: {},
  transactionList: null,
  StepperApplicationSummary: {},
  StepperLoanHistory: {},
  signature: {},
  cosignerStatus: false,
  getCoSignerDetail: {},
  userPromocode: {},
  patientGroup: [],
  isApplicationSummaryLoading: false,
};

export const UserInformationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setVerifyEmail: (state, { payload }) => {
      state.emailVerifyed = payload;
    },
    setVerifiedEmail: (state, { payload }) => {
      state.verifiedEmail.verified = payload;
    },
    setIsAuthorized: (state, { payload }) => {
      state.isAuthorized = payload;
    },
    setIsNotInRegister: (state, { payload }) => {
      state.isNotInRegister = payload;
    },
    setAccessToken: (state, { payload }) => {
      state.userToken = payload;
    },
    setHasFolio: (state, { payload }) => {
      state.hasFolio = payload;
    },
    setUserRegisterInfo(state, { payload }) {
      state.userRegisterInfo = payload;
    },
    setUserGeneralInformation(state, { payload }) {
      state.userGeneralInformation = payload;
    },
    setUserProcedureInformation(state, { payload }) {
      state.userProcedureInformation = payload;
    },
    setUserRfcAndCurp(state, { payload }) {
      state.userRfcAndCurp = payload;
    },
    setUserPlaceofBirth(state, { payload }) {
      state.userPlaceofBirth = payload;
    },
    setUserKnowYouBetter(state, { payload }) {
      state.userKnowYouBetter = payload;
    },
    setUserCreditHistory(state, { payload }) {
      state.userCreditHistoryInformation = payload;
    },
    setUserCoSignerGeneralInformation(state, { payload }) {
      state.userCoSignerGeneralInformation = payload;
    },
    setUserIdentificationInformation(state, { payload }) {
      state.userIdentificationInformation = payload;
    },
    setUserFinalProcessInformation(state, { payload }) {
      state.userFinalProcessInformation = payload;
    },
    setUseRequestedCreditInformation(state, { payload }) {
      state.userRequestedCreditInformation = payload;
    },
    setUserTermsAndServices(state, { payload }) {
      state.userTermsAndServices = payload;
    },
    setFolioNumber(state, { payload }) {
      state.folioNumber.folio = payload;
    },
    setIsProcessComplete(state, { payload }) {
      state.processComplete = payload;
    },
    resetUserRegisterInfo(state) {
      state.userRegisterInfo.id = "";
      state.userRegisterInfo.email = "";
      state.userRegisterInfo.password = "";
      state.userRegisterInfo.createOn = "";
    },
    resetBooleanValuesUser(state) {
      state.isAuthorized = false;
      state.hasFolio = false;
      state.isNotInRegister = false;
    },

    setApplicationSummaryLoading(state, { payload }) {
      state.isApplicationSummaryLoading = payload;
    },
  },
  extraReducers: (builder) => {
    // basic registration
    builder.addCase(saveUserRegisterInfo.fulfilled, (state, { payload }) => {
      const { data } = payload;

      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userRegisterInfo.email = data.email;
        state.userRegisterInfo.id = data.user_id;
      }
    });

    // get general info
    builder.addCase(
      getUserGeneralInformation.fulfilled,
      (state, { payload }) => {
        const { data } = payload;

        if (API_STATUS?.SUCCESS === payload?.status) {
          state.userGeneralInformation = data;
        }
      }
    );

    // general info
    builder.addCase(
      saveUserGeneralInformation.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.userGeneralInformation = data;
        }
      }
    );

    // medical procedure
    builder.addCase(
      saveUserProcedureInformation.fulfilled,
      (state, { payload }) => {
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.userProcedureInformation = payload.payload;
        }
      }
    );

    // general data: rfc & curp
    builder.addCase(saveUserRfcAndCurp.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userRfcAndCurp = data.payload;
      }
    });

    // personal info: gender, dob & address
    builder.addCase(saveUserPlaceOfBirth.fulfilled, (state, { payload }) => {
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userPlaceofBirth = payload.payload;
      }
    });

    // income info
    builder.addCase(saveUserKnowYouBetter.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userKnowYouBetter = data.payload;
      }
    });

    // income info
    builder.addCase(
      saveUserIdentificationInformation.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.setUserIdentificationInformation = data.payload;
        }
      }
    );

    // income info
    builder.addCase(
      saveUserFinalProcessInformation.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.userFinalProcessInformation = data.payload;
        }
      }
    );

    // income info
    builder.addCase(
      saveUseRequestedCreditInformation.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.userFinalProcessInformation = data.payload;
        }
      }
    );

    // getDashboardDetails
    builder.addCase(getDashboardDetails.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.dashboardDetails = data;
      }
    });

    // getCreditDecision
    builder.addCase(getCreditDecision.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.creditDecision = data;
      }
    });

    // getStepperLoanDetails
    builder.addCase(getLoanDetails.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.loanDetailsStepper = data;
      }
    });

    //getUserInfo
    builder.addCase(
      getUserGeneralInformationV2.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.userGeneralInformationV2 = data;
        }
      }
    );

    //User Access Token
    builder.addCase(getUserAccessToken.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userAccessToken = data.access_token;
      }
    });

    //TakeLoan Detail
    builder.addCase(saveTakeLoan.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.takeLoanDetail = data;
      }
    });

    //Stepper Status
    builder.addCase(getApplicationStepper.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.stepperStatus = data;
      }
    });

    //stepper Procedure Info
    builder.addCase(getStepperProcedureInfo.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.stepperProcedureInfo = data;
      }
    });

    builder.addCase(getCoSignerStatus.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.cosignerStatus = data;
      }
    });

    builder.addCase(getAllApplication.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.allApplication = data;
      }
    });

    builder.addCase(getUserPromoCode.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.userPromocode = data;
      }
    });

    builder.addCase(getTransactionList.fulfilled, (state, { payload }) => {
      const { data } = payload;

      if ("SUCCESS" === payload?.meta?.status) {
        state.transactionList = data?.results;
      }
    });

    builder.addCase(
      getStepperApplicationSummary.fulfilled,
      (state, { payload }) => {
        const { data } = payload;
        if (API_STATUS?.SUCCESS === payload?.status) {
          state.StepperApplicationSummary = data;
        }
      }
    );

    builder.addCase(getCoSigner.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.getCoSignerDetail = data;
      }
    });

    builder.addCase(getLoanDetailList.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if ("SUCCESS" === payload?.meta?.status) {
        state.StepperLoanHistory = data;
      }
    });

    builder.addCase(getLmsBalanceSummary.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (API_STATUS?.SUCCESS === payload?.meta?.status.toLowerCase()) {
        state.LmsBalanceSummary = data;
      }
    });

    builder.addCase(getPatientGroup.fulfilled, (state, { payload }) => {
      const { data, status } = payload;
      if (API_STATUS?.SUCCESS === status.toLowerCase()) {
        state.patientGroup = data?.detail || [];
      }
    });
    builder.addCase(getSignature.pending, (state, { payload }) => {
      state.signature = "";
    });
    builder.addCase(getSignature.fulfilled, (state, { payload }) => {
      const { data, status } = payload;
      if (API_STATUS?.SUCCESS === status.toLowerCase()) {
        state.signature = data?.signature_url || "";
      } else {
        state.signature = "";
      }
    });
  },
});

export const {
  setIsAuthorized,
  setAccessToken,
  setUserRegisterInfo,
  setUserGeneralInformation,
  setUserProcedureInformation,
  setUserRfcAndCurp,
  setUserPlaceofBirth,
  setUserKnowYouBetter,
  setUserCreditHistory,
  setUserCoSignerGeneralInformation,
  setUserIdentificationInformation,
  setUserFinalProcessInformation,
  setUseRequestedCreditInformation,
  setUserTermsAndServices,
  setHasFolio,
  setFolioNumber,
  resetUserRegisterInfo,
  resetBooleanValuesUser,
  setIsNotInRegister,
  setIsProcessComplete,
  setVerifyEmail,
  setVerifiedEmail,
  setApplicationSummaryLoading,
} = UserInformationSlice.actions;

export const selectIsAuthorized = (state) => state.user.isAuthorized;
export const selectAccessToken = (state) =>
  state.user ? state.user.userToken : null;
export const selectEmailVerifyed = (state) =>
  state.user ? state.user.emailVerifyed : null;
export const selectProcessComplete = (state) =>
  state.user ? state.user.processComplete : null;
export const selectIsNotInRegister = (state) =>
  state.user ? state.user.isNotInRegister : null;
export const selectUserEmail = (state) =>
  state.user.userRegisterInfo ? state.user.userRegisterInfo.email : null;

export const selectAuthEmail = (state) => state.auth?.email ?? null;
export const selectAuthUserName = (state) => state.auth?.userName ?? null;

export const selectUserId = (state) =>
  state.user.userRegisterInfo ? state.user.userRegisterInfo.id : null;
export const selectCosignerInfo = (state) =>
  state.user ? state.user.userCoSignerGeneralInformation : null;
export const selectUserGeneralInformation = (state) =>
  state.user ? state.user.userGeneralInformation : null;
export const selectUserCoSigner = (state) =>
  state.user ? state.user?.userKnowYouBetter?.hasCoSigner : null;
export const selectFolioNumber = (state) =>
  state.user.folioNumber ? state.user.folioNumber.folio : null;
export const selectHasfolio = (state) =>
  state.user ? state.user.hasFolio : null;
export const selectUserRequestedCreditInformation = (state) =>
  state.user ? state.user.userRequestedCreditInformation : null;
export const selectUserPaymenthPreference = (state) =>
  state.user.userFinalProcessInformation
    ? state.user.userFinalProcessInformation.paymenthPreference
    : null;
export const selectEmailWasVerified = (state) =>
  state.user.verifiedEmail ? state.user.verifiedEmail.verified : null;

export const selectDashboardDetails = (state) => state.user.dashboardDetails;
export const selectCreditDecision = (state) => state.user.creditDecision;
export const selectLmsUserAccessToken = (state) => state.user.userAccessToken;
export const selectLmsBalanceSummary = (state) => state.user.LmsBalanceSummary;
export const selectTakeLoanDetail = (state) => state.user.takeLoanDetail;
export const selectStepperStatus = (state) => state.user.stepperStatus;
export const selectLoanDetailStepper = (state) => state.user.loanDetailsStepper;
export const selectAllApplication = (state) => state.user.allApplication;
export const selectTransactionList = (state) => state.user.transactionList;
export const selectLoanList = (state) => state.user.StepperLoanHistory;
export const selectCosignerStatus = (state) => state.user.cosignerStatus;
export const selectCosignerDetails = (state) => state.user.getCoSignerDetail;
export const selectUserPromoCode = (state) => state.user.userPromocode;

export const selectStepperApplicationSummary = (state) =>
  state.user.StepperApplicationSummary;

export const selectStepperProcedureInfo = (state) =>
  state.user.stepperProcedureInfo;

export const selectUserGeneralInformationV2 = (state) =>
  state.user.userGeneralInformationV2;

export const selectPatientGroup = (state) => state.user.patientGroup;

export const selectIsApplicationSummaryLoading = (state) =>
  state.user.isApplicationSummaryLoading;

export {
  saveUserRegisterInfo,
  getUserGeneralInformation,
  saveUserGeneralInformation,
  saveUserRfcAndCurp,
  saveUserProcedureInformation,
  saveUserPlaceOfBirth,
  saveUserKnowYouBetter,
  saveUserIdentificationInformation,
  saveUserFinalProcessInformation,
  convertURIToImageData,
  saveUseRequestedCreditInformation,
  saveUserProfessionalDetails,
  getDashboardDetails,
  getCreditDecision,
  getUserGeneralInformationV2,
  getUserAccessToken,
  getLmsBalanceSummary,
  saveTakeLoan,
  getApplicationStepper,
};
export default UserInformationSlice.reducer;
