import { useEffect } from "react";
import Image1 from "../../../assets/SplashValidation/validando1.svg";
import Image2 from "../../../assets/SplashValidation/validando2.svg";
import Image3 from "../../../assets/SplashValidation/validando3.svg";
import Image4 from "../../../assets/SplashValidation/validando4.svg";
import GradientLogo from "../../../assets/Logos/medsiGradientLogo.svg";
import SplashHeaderLine from "../../../assets/common/SplashHeaderLine.svg";
import SplashFooterLine from "../../../assets/common/SplashFooterLine.svg";
import { Grid, Typography, Box } from "@mui/material";
import SplashValidationStyles from "./SplashValidation.styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const images = {
  1: Image1,
  2: Image2,
  3: Image3,
  4: Image4,
};

const sizes = {
  1: 580,
  2: 600,
};

export default function SplashValidation(props) {
  const { image, redirectTo, trueRedirect } = props;
  const classes = SplashValidationStyles();
  const history = useHistory();
  const getImage = images[image];

  const getSize = sizes[image] || 540;
  //image === 1 ? 580 : image === 2 ? 600 : 540;
  useEffect(() => {
    if (trueRedirect === undefined) {
      setTimeout(() => {
        history.push(redirectTo);
      }, 5000);
    } else if (trueRedirect) {
      history.push(redirectTo);
    }
  }, [trueRedirect]);

  return (
    <Grid container component="main" className={classes.content}>
      <Box style={{ marginLeft: "auto", height: 331 }}>
        <img
          src={SplashHeaderLine}
          alt="logo-icon"
          className={classes.headerLineStyle}
          style={{ width: "100%" }}
        />
      </Box>
      <Grid xs={12}>
        <Box textAlign="center">
          <img
            src={GradientLogo}
            alt="logo-icon"
            className={classes.img}
            style={{ width: getSize }}
          />

          <Typography className={classes.textTitle}>
            Espera mientras validamos
            <br />
            la información proporcionada.
          </Typography>
          <Typography className={classes.textSubtitle}>
            Por favor no cierres la pantalla
          </Typography>
        </Box>
      </Grid>
      <Box mt={4} style={{ width: "100%" }}>
        <img
          src={SplashFooterLine}
          alt="logo-icon"
          className={classes.footerLineStyle}
          style={{ width: "100%" }}
        />
      </Box>
    </Grid>
  );
}
