import { Typography, Box } from "@mui/material";
import { debounce } from "lodash";
import { useState, useEffect } from "react";

import styles from "./styles";

const AvisoPrivacidad = ({ privacyRef, canScrollToBottom }) => {
  const classes = styles();

  const [showAcepter, setShowAcceptar] = useState(true);

  const gotoBottom = () => {
    const containerRef = document.getElementById("scrollableDiv");
    if (containerRef) {
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  };

  useEffect(() => {
    if (canScrollToBottom) {
      // used to get the scrollable element which is out side of the component
      const containerRef = document.getElementById("scrollableDiv");
      if (containerRef) {
        containerRef.addEventListener("scroll", checkScrollPosition);
      }
      return () =>
        containerRef?.removeEventListener("scroll", checkScrollPosition);
    }
  }, [canScrollToBottom]);

  const checkScrollPosition = debounce(() => {
    const containerRef = document.getElementById("scrollableDiv");
    if (containerRef) {
      // logic to check if user scrolled near to the bottom of element
      if (containerRef.scrollTop + 1000 >= containerRef.scrollHeight) {
        setShowAcceptar(false);
      } else {
        setShowAcceptar(true);
      }
    }
  }, 200);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.textWrapper} id="scrollableDiv">
        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.textCenter}`}
        >
          <strong>AVISO DE PRIVACIDAD INTEGRAL</strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Para Munbrunn, S.A. de C.V., es indispensable la protección de los
          Datos Personales, por lo que en cumplimiento a lo previsto en la Ley
          Federal de Protección de Datos Personales en Posesión de los
          Particulares (la “Ley&quot;), así como en la regulación aplicable,
          ponemos a disposición el presente aviso de privacidad (el “Aviso de
          Privacidad”), en el que se le informa de manera expresa lo siguiente:
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>
              Identidad y domicilio de los responsables del tratamiento de los
              datos personales.
            </u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Los Responsables del tratamiento de los Datos Personales en términos
          del presente Aviso de Privacidad, son:
          <ol start="1" className={classes.olStyles}>
            <li>Munbrunn, S.A. de C.V. (“Medsi”);</li>
          </ol>
          Con domicilio, para efectos de lo relacionado al presente Aviso de
          Privacidad, el ubicado en Calle Emerson # 316, segundo piso, interior
          201, colonia Polanco V sección, alcaldía Miguel Hidalgo, C.P. 11560,
          Ciudad de México, México, quienes para los efectos de este Aviso de
          Privacidad tienen el carácter de Responsables del tratamiento y
          protección de los Datos Personales.
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Datos personales sujetos a tratamiento.</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Para llevar a cabo las finalidades señaladas en el presente Aviso de
          Privacidad, se informa que cada uno de los Responsables, recabarán
          para su tratamiento los datos personales (los “Datos Personales”) de
          los cuales usted es titular (el “Titular”) y que resultan necesarios
          para la adecuada contratación de productos, operaciones y/o servicios
          que ofrecen los Responsables (los “Servicios”):
        </Typography>
        <table className={classes.table}>
          <thead>
            <tr>
              <td className={classes.tdHead}>
                <Typography variant="body1" className={classes.text12}>
                  <strong>DATOS PERSONALES</strong>
                </Typography>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Datos de identificación.
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Datos de contacto.
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Datos patrimoniales y/o financieros.
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Datos académicos.
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Datos laborales.
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Ingresos y egresos a través de acceso a su cuenta del Servicio
                  de Administración Tributaria y/o cuentas bancarias.
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="body1" className={classes.text12}>
                  <strong>DATOS PERSONALES SENSIBLES</strong>
                </Typography>
              </td>
            </tr>
            <tr>
              <td className={classes.tdBody}>
                <Typography variant="subtitle1" className={classes.text12}>
                  Datos biométricos, limitados a reconocimiento facial y/o
                  huella dactilar.
                </Typography>
              </td>
            </tr>
          </tbody>
        </table>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Los Datos Biométricos (los “Datos Sensibles”) que se recaben del
          Titular, sólo serán tratados para corroborar la identidad del Titular
          y serán obtenidos de forma directa por cualquiera de los Responsables
          o a través de Medios Electrónicos y de autenticación que los
          Responsables pongan a disposición del Titular, y que éste deberá
          aceptar de forma expresa.
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          El consentimiento expreso del Titular para el tratamiento de los Datos
          Personales será recabado a través los sitios web Medsi.com.mx (los
          “Sitios”), así como de las aplicaciones informáticas de los
          Responsables (las “App”) o por cualquier otro medio electrónico,
          telefónico, video u otra tecnología (los “Medios Electrónicos”) y, en
          su caso, a través de agentes, promotores, comisionistas, proveedores o
          socios comerciales de los Responsables.
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Los Responsables hacen del conocimiento del Titular que respecto de
          los datos personales de otras personas que el Titular proporciona a
          los Responsables, como obligados solidarios, garantes, avales,
          proveedores, beneficiarios y cualquier otra figura legal relacionada
          con los Servicios, el Titular se obliga a informar a esas personas
          sobre el tratamiento de sus datos personales y el contenido de este
          Aviso de Privacidad.
        </Typography>
        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Finalidades necesarias</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Los Datos Personales y, en su caso, los Datos Sensibles que cada
          Responsable recaba y/o recabará del Titular, son y/o serán utilizados
          para estar en posibilidades de cumplir con las siguientes finalidades:
          <ul>
            <li>
              Verificar, confirmar y validar la identidad del Titular, así como
              la veracidad de la información que proporciona como propia,
              referencias, obligados solidarios, avales, según resulte aplicable
              de acuerdo con los Servicios solicitados.{" "}
            </li>
            <li>
              Gestionar, controlar, administrar, procesar y, en su caso, otorgar
              los Servicios, así como mantener comunicación con los Usuarios.
            </li>
            <li>
              Incluir al Titular en bases de datos, integrar expedientes, de
              manera directa o a través de un tercero; así como gestionar,
              administrar y manterner actualizadas las bases de datos.
            </li>
            <li>
              Procesar las solicitudes que realice a través de Medios
              Electrónicos.
            </li>
            <li>
              Realizar actividades de inteligencia comercial, gestionar estudios
              y programas necesarios para determinar hábitos de consumo del
              Titular para la implementación de mejoras y actualizaciones en los
              Sitios, las Apps y los Servicios. Estadística y registro
              histórico.
            </li>
            <li>
              Evaluar los Servicios y enviar comunicaciones e información
              relacionada con los Servicios para ofrecer ampliación o mejora en
              los Servicios.
            </li>
            <li>
              Brindar capacitación continua en relación con los Servicios.
            </li>
            <li>
              Dar cumplimiento a los distintos ordenamientos legales vigentes y
              aplicables, así como a requerimientos de autoridades, en su caso.
            </li>
            <li>
              Verificar las huellas dactilares y/o cualquier elemento de
              reconocimiento facial proporcionadas por el Titular, en los
              registros que administra el Instituto Nacional Electoral o
              cualquier otra autoridad que emita documentos oficiales en los
              Estados Unidos Mexicanos, incluir el resultado de la verificación
              en el expediente de identificación del Titular y resguardar la
              información para la solicitud de los Servicios.
            </li>
            <li>
              Realizar el análisis de la información proporcionada por el
              Titular, a fin de determinar conforme a las disposiciones
              legcontaales vigentes el nivel de riesgo, viabilidad de pago,
              perfil transaccional, y cualquier otra evaluación que sea
              requerida por la normatividad aplicable a los Responsables.
            </li>
            <li>
              Verificar e investigar la capacidad crediticia, así como su score.
            </li>
            <li>
              Consultar y realizar investigaciones crediticias ante sociedades
              de información crediticia que se estime conveniente.
            </li>
            <li>
              Prevenir, detectar y combatir actos ilícitos, fraudes y delitos
              financieros.
            </li>
            <li>Verificar los datos a través de redes sociales.</li>
            <li>Brindar soporte técnico al Titular.</li>
          </ul>
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Finalidades no necesarias</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          <ul>
            <li>
              Envío de ofertas, promociones, mercadotecnia o eventos especiales
              que puedan ser de interés del Titular.
            </li>
            <li>
              Enviar los datos de contacto del Titular a los socios comerciales
              y a los clientes de estos a efecto de ponerlos en contacto con el
              Titular respecto de los productos y/o servicios que éste ofrece.
            </li>
            <li>Promoción de servicios y/o prospección comercial.</li>
            <li>
              Enviar el boletín de noticias y ofertas, así como beneficios y
              servicios ofrecidos por socios comerciales.
            </li>
          </ul>
          El Titular podrá negarse al tratamiento de sus Datos Personales para
          las finalidades no necesarias, enviando un correo a contacto@medsi.mx;
          en el entendido de que, en caso de no recibir la solicitud
          correspondiente, se entenderá por consentido el uso de los Datos
          Personales para las finalidades antes mencionadas.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Transferencia y remisión de datos personales.</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          El Responsable podrá transferir los Datos Personales para las
          finalidades antes señaladas, con: (i) sus sociedades controladoras,
          subsidiarias o afiliadas, así como a la sociedad matriz o a cualquier
          sociedad de su grupo corporativo que opere bajo los mismos procesos y
          políticas internas, (ii) con autoridades competentes, en caso de ser
          solicitados, en términos de la legislación aplicable, (iii) con otras
          empresas en caso de ser adquiridos, fusionados o cualquier otra
          operación similar por esa compañía, (iv) terceros, incluidos entidades
          financieras y entidades comerciales que proporcionen el servicio para
          realizar operaciones a través del SPEI, entre estos de forma
          enunciativa más no limitativa Ostrea Solutions, S.A. de C.V.; (v)
          socios comerciales con quien se tengan celebrados contratos para la
          verificación de las identificaciones de los clientes; (vi) socios
          comerciales con quien se tengan celebrados contratos para la
          comercialización de productos y/o servicios en beneficio del Usuario;
          (vii) socios comerciales con quien se tengan celebrados contratos con
          el fin de prestar servicios de gestión de cuentas y registros de
          Usuarios, enviar comunicaciones, así como comunicaciones de marketing;
          (viii) con la sociedad de información crediticia contratada, y (ix) en
          cualquier otro de los supuestos establecidos en los artículos 10 y 37
          de la Ley.
          <br />
          <br />
          De acuerdo con lo establecido en los artículos 50 y 53 del Reglamento
          de la Ley (el “Reglamento”), los Responsables podrán realizar
          remisiones de los Datos Personales con terceros en su carácter de
          Encargados para su tratamiento por cuenta de los Responsables de
          conformidad con lo establecido en el presente Aviso de Privacidad, lo
          anterior como consecuencia de la existencia de una relación jurídica
          entre el tercero y los Responsables. Los terceros en su carácter de
          Encargados, sólo recibirán aquellos Datos Personales que sean
          necesarios para la prestación de los servicios contratados por los
          Responsables conforme a las instrucciones de éstos o para el
          cumplimiento legal correspondiente, guardando estricta
          confidencialidad respecto de los Datos Personales tratados. Consulte
          el aviso de privacidad que rige la plataforma o aplicativo de dicho
          tercero para obtener más información sobre las opciones que tiene a su
          disposición.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>
              Ejercicio de derechos de acceso, rectificación, cancelación,
              oposición y revocación del consentimiento (ARCO).
            </u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          El Titular de los Datos Personales tiene derecho a acceder,
          rectificar, cancelar u oponerse al tratamiento de sus Datos Personales
          en posesión de los Responsables (los “Derechos Arco”). El ejercicio de
          los Derechos Arco deberá realizarse a través de solicitud escrita y
          presentada (la “Solicitud”): (i) mediante escrito presentado en el
          domicilio de los Responsables, dirigido a la atención de Luis Arturo
          Vega Rebolledo, persona encargada de dar trámite a las solicitudes
          relativas a los Datos Personales y fomentar la protección de los
          mismos al interior de los Responsables; o (ii) mediante envío de
          correo electrónico a contacto@medsi.mx. En cualquiera de los casos
          anteriores, el Titular deberá acompañar a la Solicitud la siguiente
          información y documentación:
          <ol start="1" className={classes.olStyles}>
            <li>
              Nombre y domicilio u otro medio para comunicarle la respuesta a la
              Solicitud, así como los documentos oficiales que acrediten su
              identidad o en su caso, la representación legal con la que
              comparece.
            </li>
            <li>
              Cualquier otro elemento o documento que facilite la localización
              de los Datos Personales.
            </li>
            <li>
              La descripción de manera clara y precisa de los Datos Personales
              respecto de los cuales se busca ejercer Derechos ARCO, así como el
              derecho o derechos que se desea ejercer, lo cual podrá hacerse en
              el texto del correo electrónico o en un documento adjunto
              escaneado y debidamente firmado al final del mismo y rubricado al
              calce de cada una de las hojas.
            </li>
          </ol>
          Si el Titular realiza la Solicitud en el domicilio de los
          Responsables, deberá firmarla al final y rubricada al calce de cada
          una de las hojas.
          <br />
          <br />
          Los Responsables darán respuesta a la Solicitud siempre y cuando no se
          actualice alguna de las excepciones contenidas en la Ley, y el
          solicitante cumpla con lo dispuesto en el artículo 29 de la Ley.
          <br />
          Una vez presentada la Solicitud en los términos antes mencionados, los
          Responsables emitirán la determinación adoptada en un plazo no mayor a
          20 (veinte) días hábiles a partir de su recepción. Si la Solicitud
          resulta procedente, ésta se hará efectiva dentro de los 15 (quince)
          días hábiles siguientes en la fecha en que los Responsables comuniquen
          la respuesta al Titular. En caso de que la información proporcionada
          en la Solicitud resulte errónea o insuficiente, o no se acompañen los
          documentos necesarios para acreditar la identidad o la representación
          legal correspondiente del Titular, los Responsables, dentro de los 5
          (cinco) días hábiles siguientes a la recepción de la Solicitud,
          requerirán la subsanación de las deficiencias para poder dar trámite a
          la misma. En estos casos, el Titular contará con 10 (diez) días
          hábiles para atender el requerimiento de subsanación, contados a
          partir del día siguiente en que lo hubiere recibido. La Solicitud
          correspondiente se tendrá por no presentada si el Titular no responde
          ni subsana lo requerido dentro de dicho plazo.
          <br />
          <br />
          El Titular será el responsable de mantener actualizados sus Datos
          Personales en posesión de los Responsables. Por lo anterior, el
          Titular garantiza y responde, en cualquier caso, de la veracidad,
          exactitud, vigencia y autenticidad de los Datos Personales
          facilitados, y se compromete a mantenerlos debidamente actualizados,
          comunicando cualquier cambio a los Responsables.
          <br />
          <br />
          Si la Solicitud de ejercicio de Derechos ARCO es acerca del ejercicio
          del derecho de acceso, los Responsables pondrán a su disposición la
          información o Datos Personales a través de copias simples y/o
          documentos electrónicos. El Titular podrá revocar el consentimiento
          para el tratamiento de los mismos conforme al procedimiento antes
          señalado.
          <br />
          <br />
          Los Responsables, podrán negar el ejercicio de Derechos ARCO en los
          supuestos que lo permita la Ley y el Reglamento.
          <br />
          <br />
          El Titular podrá revocar su consentimiento para el tratamiento de sus
          Datos Personales, sin efectos retroactivos, en todos aquellos casos en
          que dicha revocación no suponga la imposibilidad de cumplir
          obligaciones derivadas de una relación jurídica vigente entre el
          Titular y cualquiera de los Responsables. El procedimiento para la
          revocación del consentimiento, en su caso, será el mismo que el
          establecido en el apartado inmediato anterior para el ejercicio de los
          Derechos ARCO.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>
              Medios para limitar el uso o divulgación de sus datos personales.
            </u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          El Titular podrá limitar el uso o divulgación de los mismos conforme
          al procedimiento previsto en la sección 6 de este Aviso de Privacidad,
          denominado “Ejercicio de derechos de acceso, rectificación,
          cancelación, oposición y revocación del consentimiento (ARCO).
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Políticas de Seguridad</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Tus datos personales son protegidos usando el estándar de la industria
          en materia de encriptación, de esta forma garantizamos que los datos
          que se envían desde la página de Internet lleguen seguros a nuestros
          servidores. Almacenamos y procesamos tu información manteniendo
          siempre medidas de seguridad orientadas a proteger tus datos
          personales. Asimismo, asumimos medidas de seguridad físicas para
          prevenir el acceso no autorizado a nuestros sistemas e instalaciones.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Uso de cookies, web beacons y otras tecnologías similares.</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Se hace del conocimiento del Titular, que los Responsables utilizan
          mecanismos remotos o locales de comunicación electrónica, óptima u
          otra tecnología que permiten recabar Datos Personales relacionados con
          su actividad en los Medios Electrónicos y los Servicios.
          <br />
          <br />
          Una cookie es un fragmento de información que el servidor web de los
          Responsables puede enviar a la computadora o dispositivo con el que
          acceda el Titular a los Medios Electrónicos. Una cookie permite
          reconocer al Titular cuando vuelva a visitar los Medios Electrónicos.
          El Titular puede borrar, bloquear o recibir una advertencia sobre las
          cookies en los navegadores de Internet más comunes, por lo que, si el
          Titular quiere hacer esto, debe consultar las instrucciones de su
          navegador o la pantalla de ayuda para aprender más. Las cookies
          permitirán al Titular tomar ventaja de las características más
          benéficas que los Medios Electrónicos le ofrecen. Los Medios
          Electrónicos pueden incluir enlaces a sitios web de terceros ajenos a
          los Responsables, respecto de los cuales este último no asume ninguna
          titularidad, obligación o responsabilidad alguna.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Geolocalización.</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Para que los Responsables puedan proporcionarle al Titular alguno de
          los Servicios, es necesario que autoricen la geolocalización del
          dispositivo con el que ingresan a los Medios Electrónicos, la cual
          proporciona las coordenadas geográficas de latitud y longitud en que
          se encuentre dicho dispositivo. La información de geolocalización será
          utilizada para celebrar contratos o realizar Operaciones no
          presenciales.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Consentimiento.</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
        >
          Se entenderá que, al utilizar nuestros Medios Electrónicos y/o
          solicitar y/o contratar los Servicios para las finalidades aquí
          previstas, el Titular expresamente reconoce y acepta el presente Aviso
          de Privacidad, por lo que dicho consentimiento nos otorga la facultad
          de proceder al tratamiento de los Datos Personales en la forma en que
          se señala en el presente Aviso de Privacidad. Si tiene alguna duda
          acerca de este Aviso de Privacidad, por favor envíe un correo
          electrónico a contacto@medsi.mx.
        </Typography>

        <Typography
          variant="body1"
          className={`${classes.text12} ${classes.mt24}`}
        >
          <strong>
            <u>Actualizaciones al Aviso de Privacidad.</u>
          </strong>
        </Typography>
        <Typography
          variant="subtitle1"
          className={`${classes.text12} ${classes.textJustify} ${classes.mt24}`}
          ref={privacyRef || null}
        >
          En cualquier momento los Responsables podrán hacer modificaciones,
          cambios y/o actualizaciones al presente Aviso de Privacidad que sean
          necesarias para atender disposiciones legales aplicables, nuestras
          prácticas de privacidad, o por otras causas, las cuales haremos de su
          conocimiento mediante publicación en nuestros Medios Electrónicos,
          incluyéndos los Sitios y las App.
          <br />
          <br />
          Última actualización: agosto 24 de 2022.
        </Typography>
      </Box>
      {canScrollToBottom && (
        <div className={classes.buttonWrapper}>
          <div
            onClick={gotoBottom}
            className={showAcepter ? classes.acceptar : classes.hide}
          >
            Desplazarse hacia abajo
          </div>
        </div>
      )}
    </Box>
  );
};

export default AvisoPrivacidad;
