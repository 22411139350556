import React from "react";
import { Grid, List, ListItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import hairRestaurationStyles from "./hairRestauration.styles";
import hairRestaurationIcon from "../../../../assets/procedureType/hairRestaurationIcon.svg";
const HairRestauration = (props) => {
  const classes = hairRestaurationStyles();
  //CustomListItemTypography
  const ListGrayItem = ({ children }) => (
    <Typography
      sx={{
        fontFamily: "Urbanist-Medium",
        color: "rgba(0, 0, 0, 0.5)",
        fontSize: 20,
      }}
    >
      {children}
    </Typography>
  );

  const ListBlackItem = ({ children }) => (
    <Typography sx={{ fontFamily: "Urbanist-SemiBold", fontSize: 20 }}>
      {children}
    </Typography>
  );



  const ListItemsAndTitle = (props) => {
    const { title, items, headerImage,marginLeftToList } = props;
    return (
      <Box sx={{ml:4}}>
        {headerImage ? (
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src={headerImage}
              alt="hair"
              className={classes.hairReparationIcon}
            />
            <ListBlackItem>{title}</ListBlackItem>
          </Box>
        ) : (
          <Box
            display="flex"
            alignItems="center"
            mt={"28px"}
          >
            <ListBlackItem>{title}</ListBlackItem>
          </Box>
        )}

        <List
          sx={{
            listStyleType: "disc",
            pl: 2,
            "& .MuiListItem-root": {
              display: "list-item",
              color: "rgba(0, 0, 0, 0.5)",
              fontSize: 10,
            },
            marginLeft: marginLeftToList,
            marginTop: headerImage && "-20px",
          }}
        >
          {items.map((item) => (
            <ListItem>
              <ListGrayItem>{item}</ListGrayItem>
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box sx={{ marginTop: 20, maxWidth: 1400, marginLeft:10 }}>
      <Typography
        sx={{
          fontFamily: "Urbanist-SemiBold",
          fontSize: 36,
          color: "#000000",
          opacity: "0.5",
          marginLeft: 15,
          marginBottom:5
        }}
        className={classes.sectionTitle}
      >
        Restauración capilar y transplante de cabello
      </Typography>
      <Grid container>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Tratamientos intensivos"
            items={[
              "Mesoterapia",
              "Plasma rico en plaquetas (PRP)",
              " Factores de crecimiento con células madre",
            ]}
            headerImage={hairRestaurationIcon}
            marginLeftToList={15}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Microinjerto capilar"
            items={["Técnica FUE", "Técnica LHT"]}
            marginLeftToList={9}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Microinjerto de barba y bigote"
            items={["Microinjerto capilar"]}
            marginLeftToList={0}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <ListItemsAndTitle
            title="Microinjerto de ceja"
            items={["Microinjerto capilar"]}
            marginLeftToList={9}

          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default HairRestauration;
