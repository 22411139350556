import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customColor } from "../../../../theme/customColor";

const styles = makeStyles((theme: Theme) => ({
  container: {
    width: "100%",
    padding: `${theme.spacing(6)} 0`,
    marginTop: theme.spacing(5),
    [theme.breakpoints.down(601)]: {
      width: "92vw",
      margin: "0 auto",
    },
  },
  title: {
    fontSize: 28,
    fontFamily: "Nexa-Bold",
    fontWeight: "700",
    [theme.breakpoints.down(601)]: {
      fontSize: 22,
    },
  },
  calculator: {
    "& > div": {
      display: "flex",
      background: customColor.cotton,
      padding: "0 42px",
      minHeight: 60,
      alignItems: "center",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      margin: `${theme.spacing(5)} 0`,
      "& > .leftContent": {
        width: "50%",
        padding: `${theme.spacing(2)} 0`,
        // color: customColor.accentBlue,
        color: customColor.black,
        fontSize: 18,
        // fontFamily: "Nexa-Bold",
        fontFamily: "Urbanist-Regular",
        fontWeight: "500",
        [theme.breakpoints.down(601)]: {
          fontSize: 13,
          fontWeight: "600",
        },
      },
      "& > .rightContent": {
        width: "60%",
      },
      [theme.breakpoints.down(601)]: {
        padding: "0 10px",
        margin: "12px 0",
      },
    },
  },
  formControl: {
    width: "100%",
    color: customColor.obsidian,
    borderRadius: "8px",
    display: "flex",
    padding: `${theme.spacing(1)} 0 !important`,
    "& fieldset": {
      display: "none",
    },
    "& input": {
      background: "transparent",
      border: "1px solid rgba(0, 0, 0, 0.5)",
      borderRadius: 20,
      height: 36,
      padding: "0px 16px",
      fontSize: 18,
      lineHeight: "1.2",
      fontFamily: "SFUIText-Bold",
      fontWeight: "700",
      width: 155,
      color: customColor.obsidian,
      "&::placeholder": {
        color: customColor.obsidian,
      },
      [theme.breakpoints.down(601)]: {
        width: "70%",
        height: "34px",
        fontSize: 14,
      },
    },
  },
  termView: {
    background: "linear-gradient(90deg, #0ACC97 0%, #1B63DB 100%)",
    display: "flex",
    padding: "4px 12px",
    borderRadius: 14,
    width: "80%",
    [theme.breakpoints.down(601)]: {
      width: "100%",
    },
    justifyContent: "space-between",
    "& > div": {
      fontSize: 16,
      fontFamily: "SFUIText-Bold",
      fontWeight: "600",
      color: customColor.cotton,
      padding: "3px 6px",
      cursor: "pointer",
      [theme.breakpoints.down(601)]: {
        fontSize: 10,
      },
    },
    "& > .selected": {
      fontSize: 16,
      fontFamily: "SFUIText-Bold",
      fontWeight: "600",
      color: customColor.accentBlue,
      background: customColor.cotton,
      borderRadius: 14,
      [theme.breakpoints.down(601)]: {
        fontSize: 10,
      },
    },
  },
  select: {
    border: `1px solid transparent`,
    borderRadius: 8,
    padding: "0px 16px",
    width: "80%",
    height: 40,
    fontSize: 16,
    lineHeight: 1.8,
    backgroundSize: "5px 5px, 5px 5px, 1px 1.5em",
    backgroundRepeat: "no-repeat",
    [theme.breakpoints.down(601)]: {
      fontSize: 14,
    },
  },
  infoText: {
    color: customColor.mango,
    fontSize: 10,
    fontFamily: "SFUIText-Regular",
    margin: `${theme.spacing(1)} 0`,
  },
  infoText2: {
    // color: customColor.lightAccentBlue,
    color: customColor.black,
    fontSize: 14,
    fontFamily: "SFUIText-Regular",
  },
  infoText3: {
    // color: customColor.lightAccentBlue,
    color: customColor.black,
    fontSize: 12,
    fontFamily: "SFUIText-Regular",
    marginTop: `-${theme.spacing(3)}`,
    lineHeight: 1.2,
    "& a": {
      color: customColor.lightAccentBlue,
    },
  },
  gradientButton: {
    backgroundImage: "linear-gradient(90deg, #0ACC97 12.31%, #1B63DB 107.39%)",
    borderRadius: 20,
  },
}));

export default styles;
