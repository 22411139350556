import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles: any = makeStyles((theme: Theme) => ({
  summaryText: {
    color: '#6B7779',
    fontSize: '22px',
    fontFamily: 'SFUIText-bold',
    marginTop: '30px',
    paddingLeft: '28px',
    [theme.breakpoints.down('lg')]: {
      fontFamily: 'Urbanist-Bold',
      fontSize: '20px',
    },
  },
  ulList: {
    listStyle: 'number',
    color: '#6B7779',
    margin: 0,
    padding: 0,
    listStylePosition: 'inside',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px',
    },
  },
}));

export default useStyles;
