import { makeStyles } from "@mui/styles";
import BackgroundDesktop from "../../../../../../assets/ClientSignature/backgroundDesktop.png";
import BackgroundTablet from "../../../../../../assets/ClientSignature/backgroundTablet.png";
import BackgroundMobile from "../../../../../../assets/ClientSignature/backgroundMobile.png";
import { customColor } from "../../../../../../theme/customColor";

const ClientSignatureStyles = makeStyles((theme) => ({
  title: {
    fontSize: "55px !important",
    color: theme.palette.primary.white,
    marginTop: "5px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "35px !important",
    },
  },
  boxContent: {
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 520,
    marginTop: 40,
    [theme.breakpoints.down(1001)]: {
      marginTop: 110,
    },
    [theme.breakpoints.down(601)]: {
      marginTop: 76,
    },
  },
  boxContentCoSigner: {
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: 520,
    marginTop: 124,
    marginBottom: 30,
    [theme.breakpoints.down(1001)]: {
      marginTop: 110,
    },
    [theme.breakpoints.down(601)]: {
      marginTop: 76,
    },
  },
  signAgain: {
    color: theme.palette.primary.main,
    marginTop: "8px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "14px !important",
    },
    cursor: "pointer",
  },
  textChecks: {
    color: theme.palette.primary.gray,
    [theme.breakpoints.down(601)]: {
      fontSize: "14px !important",
    },
  },
  listItemTerm: {
    padding: "0 !important",
  },
  listItemBank: {
    padding: "0 !important",
    "& .label": {
      color: customColor.accentBlue,
      paddingLeft: 10,
    },
  },

  mainColor: {
    color: theme.palette.primary.main,
    "& > a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  grayColor: {
    color: theme.palette.primary.gray,
  },
  select: {
    width: 250,
    height: 40,
    borderRadius: "10px !important",
    marginTop: 10,
    marginBottom: 20,
  },
  logoText: {
    height: 55,
    marginTop: 10,
    marginBottom: -14,
    [theme.breakpoints.down(601)]: {
      height: 40,
    },
  },
  lastText: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    marginTop: 44,
    marginBottom: "32px",
    width: "max-content",
    textAlign: "center",
    fontSize: 22,
    [theme.breakpoints.down(601)]: {
      width: "100%",
      fontSize: "16px !important",
    },
    position: "relative",
    "& > img": {
      color: theme.palette.primary.main,
      height: 50,
      position: "absolute",
      bottom: 7,
      right: 0,
    },
  },
  preferencePayment: {
    color: theme.palette.primary.main,
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  completedProcess: {
    fontWeight: "700 !important",
    width: "1000px",
    textAlign: "center",
    color: theme.palette.primary.darkBlue,
    fontSize: "22px !important",
    [theme.breakpoints.down(1001)]: {
      width: "625px",
      fontSize: "20px !important",
    },
    [theme.breakpoints.down(681)]: {
      width: "560px",
      fontSize: "18px !important",
    },
    [theme.breakpoints.down(601)]: {
      width: "100%",
      fontSize: "16px !important",
    },
  },
  input: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    "& input": {
      width: 260,
      minWidth: 260,
    },
  },
  passwordView: {
    alignItems: "center",
    paddingTop: 5,
    paddingBottom: 5,
    "& .verifyButton": {
      marginBottom: "0 !important",
      marginLeft: 10,
      width: "max-content !important",
      padding: "6px 20px",
      height: "max-content !important",
    },
  },
  column: {
    flexDirection: "column",
    alignItems: "flex-start !important",
    marginTop: 8,
    marginBottom: 16,
  },
}));

export default ClientSignatureStyles;
