import React from "react";
import { Typography, Box } from "@mui/material";
import ThankYouStyles from "./ThankYou.styles";
import Logo from "../../assets/Logos/mettaLogoBlueGradient.svg";

const ThankYou = () => {
  const classes = ThankYouStyles();

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      className={classes.content}
    >
      <img src={Logo} alt="logo" className={classes.logo} />
      <Typography
        variant="h1"
        className={classes.text}
      >
        ¡Gracias por elegirnos!
      </Typography>
    </Box>
  );
};

export default ThankYou;
