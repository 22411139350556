import { FC, useState, ChangeEvent } from "react";
import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import EnvelopeIcon from "../../../components/icons/Envelope";
import TextInput from "../../../features/installmentLoan/components/TextInput";
import Button from "../../../features/installmentLoan/components/Button";
import { emailRegex } from "../../../utils/validations";
import { AUTH_ROUTES } from "../../../constants/routes";
import MettaGradient from "../../../assets/Logos/gradient-logo.svg";

import useCommonStyles from "../styles";
import useStyles from "./styles";

export interface ButtonClickProps {
  email: string;
}

interface ForgotPopupProps {
  onSubmit: (payload: ButtonClickProps) => void;
}

const ForgotPopup: FC<ForgotPopupProps> = ({ onSubmit }) => {
  const css = useStyles();
  const commonCss = useCommonStyles();
  const history = useHistory();

  const [emailValue, setEmailValue] = useState<string>("");

  return (
    <Box display={"flex"} flexDirection="column" alignItems="center">
      <img
        src={MettaGradient}
        width={92}
        alt="img blanco"
        className={css.mettaGradientLogo}
      />
      <Box className={commonCss.title} ml={3}>
        ¿Olvidaste tu contraseña?
      </Box>
      <Box width="318px" mt={4} className={commonCss.content}>
        Ingresa la cuenta de correo que tienes registrada, a la cual te
        enviaremos las instrucciones para recuperar tu contraseña.
        <Box mt={2}>Tu correo debe ser el mismo con el que inicias sesión</Box>
      </Box>
      <Box mt={5}>
        <TextInput
          id="email"
          placeholder="Ingresa tu correo electrónico"
          className={css.input}
          value={emailValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setEmailValue(e.target.value);
          }}
        />
      </Box>
      <Box textAlign="center">
        <Button
          label="Continuar"
          onClick={() =>
            onSubmit({
              email: emailValue,
            })
          }
          noCaps
          className={commonCss.continueButtonStyle}
          disabled={!emailRegex.test(emailValue)}
        />
        <Box mt={2}>
          <Button
            label="Volver a Medsi"
            variant="text"
            className={css.signupTextStyle}
            onClick={() => {
              history.push(AUTH_ROUTES.LOGIN);
            }}
            noCaps
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ForgotPopup;
