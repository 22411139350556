import { FC } from "react";

import { IconProps, obsidian } from "./types";

const PhoneIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M80.04 61.52C75.12 61.52 70.36 60.72 65.92 59.28C64.52 58.8 62.96 59.16 61.88 60.24L55.6 68.12C44.28 62.72 33.68 52.52 28.04 40.8L35.84 34.16C36.92 33.04 37.24 31.48 36.8 30.08C35.32 25.64 34.56 20.88 34.56 15.96C34.56 13.8 32.76 12 30.6 12H16.76C14.6 12 12 12.96 12 15.96C12 53.12 42.92 84 80.04 84C82.88 84 84 81.48 84 79.28V65.48C84 63.32 82.2 61.52 80.04 61.52Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

PhoneIcon.defaultProps = {
  fill: obsidian,
};

export default PhoneIcon;
