import { Box, Container, Grid, ListItem } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import buttonLoader from "../../../../../../assets/loader.svg";
import ButtonComponent from "../../../../../../components/sharedComponents/ButtonComponent";
import CustomSignaturePad from "../../../../../../components/sharedComponents/CustomSignaturePad";
import ModalComponent from "../../../../../../components/sharedComponents/ModalComponent";
import SplashValidation from "../../../../../../components/sharedComponents/SplashValidation";
import {
  API_STATUS,
  SIGNATURE_STATUS,
} from "../../../../../../constants/common";
import {
  clientSignaturePage,
  coSignerClientSignaturePage,
} from "../../../../../../constants/gtm";
import TextInput from "../../../../../../features/installmentLoan/components/TextInput";
import { useAppDispatch } from "../../../../../../store";
import { verifyPassword } from "../../../../../../store/slices/auth/actions";
import {
  checkReferenceCode,
  finalInfo,
  selectApplicantInfo,
  selectCoSignerToken,
  selectIsCosigner,
} from "../../../../../../store/slices/cosigner";
import {
  getApplicationStepper,
  saveUserFinalProcessInformation,
  selectAuthUserName,
  selectCosignerInfo,
  selectUserCoSigner,
  selectUserEmail,
  setApplicationSummaryLoading,
} from "../../../../../../store/slices/users";
import { getSignature } from "../../../../../../store/slices/users/actions";
import { GTMClick } from "../../../../../../utils/gtm";
import { errorToast, successToast } from "../../../../../../utils/toast";
import AcceptTermCondtion from "./AcceptTermCondition";
import ClientSignatureStyles from "./styles";

const ClientSignature = (props) => {
  let application_id = window.location.search.slice(
    1,
    window.location.search.length
  );
  const signature = useSelector((state) => state.user.signature);
  const { isAccepted } = props;
  const classes = ClientSignatureStyles();
  const [imageURL, setImageURL] = useState(null);
  const [imageObj, setImageObj] = useState(null);
  const [validateImage, setValidateImage] = useState(false);
  const [bureauValidate, setBureauValidate] = useState(false);
  const [isValidPassword, setValidPassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [open, setOpen] = useState(false);
  const isUserCosigner = selectIsCosigner();
  const coSignerToken = useSelector(selectCoSignerToken);
  const [showLoader, setShowLoader] = useState(false);
  const history = useHistory();
  const urlId = window.location.search.slice(1, window.location.search.length);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSignature(urlId));
    localStorage.setItem("applicationID", urlId);
  }, [urlId]);

  useEffect(() => {
    setImageURL(signature);
  }, [signature]);

  //hasfolio
  const [loader, setLoader] = useState(false);
  //CosignerInfo
  const { name, lastName, mothersLastName } = useSelector(selectCosignerInfo);

  const { applicantsName } = useSelector(selectApplicantInfo);
  const hasCoSigner = useSelector(selectUserCoSigner);

  const userEmail = useSelector(selectUserEmail);
  const userName = useSelector(selectAuthUserName);

  const fetchApplicationStepper = () => {
    try {
      let retryCount = 0;
      dispatch(setApplicationSummaryLoading(true));
      dispatch(getApplicationStepper(application_id));

      const applicationStepperInterval = setInterval(async () => {
        try {
          const { data } = await dispatch(
            getApplicationStepper(application_id)
          ).unwrap();
          if (retryCount >= 2 || data?.contract_completed) {
            dispatch(setApplicationSummaryLoading(false));
            return clearInterval(applicationStepperInterval);
          }
          retryCount += 1;
        } catch (err) {
          console.log("Error in interval", err);
          dispatch(setApplicationSummaryLoading(false));
          clearInterval(applicationStepperInterval);
        }
      }, 3000);
    } catch (err) {
      console.log("Error", err);
      dispatch(setApplicationSummaryLoading(false));
    }
  };

  const handleClick = async (e) => {
    //Upload signature image
    //if the current uses is not a cosigner, then
    setShowLoader(true);
    GTMClick(clientSignaturePage.continue);
    const res = await dispatch(
      saveUserFinalProcessInformation({
        tnc: "accepted", //have to get from BE
        signature: await imageObj,
        application_id: application_id || localStorage.getItem("applicationID"),
      })
    ).unwrap();

    console.log({ res });
    if (res?.status === API_STATUS.SUCCESS) {
      // history.push(ROUTES.creditConfirmation);
      successToast(SIGNATURE_STATUS[res?.status]);
      setShowLoader(false);

      fetchApplicationStepper();
    } else if (res?.status === API_STATUS.ERROR) {
      errorToast(res?.meta?.errorMessage);
    }
    // }
  };

  const checkPassword = async (text) => {
    setLoading(true);
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      verifyPassword({
        password: text,
      })
    ).unwrap();
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Password verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const checkReferenceCodeData = async (code) => {
    setLoading(true);
    setValidPassword(false);
    const { status, meta, data } = await dispatch(
      checkReferenceCode({
        code,
        isBureauPull: true,
      })
    ).unwrap();
    setLoading(false);

    if (status === API_STATUS.SUCCESS) {
      successToast(data?.msg || "Number verified success");
      setValidPassword(true);
    } else if (status === API_STATUS.ERROR) {
      errorToast(meta?.errorMessage);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (isUserCosigner) {
        checkReferenceCodeData(referenceNumber);
      } else {
        checkPassword(confirmationPassword);
      }
    }
  };

  const cleanSignature = () => {
    setImageURL(null);
    setImageObj(null);
    setValidateImage(false);
  };

  const isBtnEnable = isValidPassword && bureauValidate && validateImage;

  const handleCloseModal = () => {
    setBureauValidate(true);
    setValidPassword(false);
  };

  const handleBureauCheckBox = (e) => {
    if (e.target.checked) {
      setOpen(true);
    } else {
      setBureauValidate(false);
      setValidPassword(false);
      setOpen(false);
    }
  };

  if (loader) {
    return (
      <SplashValidation
        image={4}
        // trueRedirect={false}
      />
    );
  } else {
    return (
      <div className={classes.content}>
        <Box className={classes.headerContainer}></Box>
        <Container component="main">
          <Grid
            justifyContent="center"
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <Box style={{ color: "#6B7779", marginTop: 30 }}>
              Yo{" "}
              <span style={{ color: "#3552CC" }}>{userName && userName}</span>,
              acepto los términos y condiciones de Medsi
            </Box>
            <div
              style={{
                opacity: isAccepted ? 1 : 0.5,
                pointerEvents: isAccepted ? "auto" : "none",
              }}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className={
                  isUserCosigner
                    ? classes.boxContentCoSigner
                    : classes.boxContent
                }
              >
                <div style={{ position: "relative" }}>
                  <CustomSignaturePad
                    setValidateImage={setValidateImage}
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    imageObj={imageObj}
                    setImageObj={setImageObj}
                  />

                  <div style={{ position: "absolute", top: 24, right: 28 }}>
                    <button
                      style={{
                        background: "#BFC5CC",
                        borderRadius: 20,
                        padding: "4px 8px",
                        color: "#FFFFFF",
                        fontFamily: "SFUIText-bold",
                        textAlign: "center",
                      }}
                      onClick={cleanSignature}
                    >
                      Borrar
                    </button>
                  </div>
                </div>

                {bureauValidate && (
                  <ListItem
                    className={`${classes.listItemBank} ${classes.column}`}
                  >
                    {isUserCosigner ? (
                      <>
                        <Box className="label">Numero de referencia</Box>
                        <Box display="flex" className={classes.passwordView}>
                          <TextInput
                            id="referenceNumber"
                            placeholder="referencia"
                            className={classes.input}
                            value={referenceNumber}
                            onChange={(e) => {
                              setReferenceNumber(e.target.value?.toUpperCase());
                            }}
                            onKeyDown={handleKeyDown}
                          />
                          <ButtonComponent
                            onClick={() =>
                              checkReferenceCodeData(referenceNumber)
                            }
                            text="Verify"
                            className="verifyButton"
                            disabled={!referenceNumber}
                            loading={loading}
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box className="label">
                          Ingrese una credencial válida para verificar
                        </Box>
                        <Box display="flex" className={classes.passwordView}>
                          <TextInput
                            id="password"
                            type="password"
                            placeholder="Confirma tu contraseña"
                            className={classes.input}
                            value={confirmationPassword}
                            onChange={(e) => {
                              setConfirmationPassword(e.target.value);
                            }}
                            onKeyDown={handleKeyDown}
                            // onBlur={() => setCheckError(true)}
                          />
                          <ButtonComponent
                            onClick={() => checkPassword(confirmationPassword)}
                            text="Verify"
                            className="verifyButton"
                            disabled={!confirmationPassword}
                            loading={loading}
                          />
                        </Box>
                      </>
                    )}
                  </ListItem>
                )}
              </Box>
              {/* <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "-180px",
                marginTop: 20,
              }}
            >
              <span style={{ color: "#3552CC" }}>
                <Checkbox />
              </span>{" "}
              <span style={{ fontSize: 14, color: "#303133" }}>
                Usar mi firma existente
              </span>
            </Box> */}

              <div
                style={{
                  marginTop: 10,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ButtonComponent
                  onClick={handleClick}
                  text="Continuar"
                  disabled={validateImage ? false : true}
                />
                {showLoader && (
                  <div style={{ marginTop: 4 }}>
                    <img src={buttonLoader} />
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Container>
        <ModalComponent
          open={open}
          body={
            <AcceptTermCondtion setOpen={setOpen} setShow={handleCloseModal} />
          }
          hideBackdrop={false}
          onBackdropClick={handleCloseModal}
        />
      </div>
    );
  }
};

export default ClientSignature;
