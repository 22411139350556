import { Box } from "@mui/material";

import ButtonComponent from "../../../../../../../components/sharedComponents/ButtonComponent";

import useStyles from "./styles";

const AcceptTermCondition = (props) => {
  const { setOpen, setShow } = props;
  const css = useStyles();

  const onClick = async () => {
    setOpen(false);
    setShow && setShow(true);
  };

  return (
    <Box className={css.container}>
      <Box mt={2}>
        Por este conducto autorizo expresamente a Munbrunn S.A. de C.V., para
        que por conducto de sus funcionarios facultados lleve a cabo
        investigaciones, sobre mi comportamiento crediticio en las Sociedades de
        Información Crediticia que estime conveniente.
      </Box>

      <Box mt={2}>
        Así mismo, declaro que conozco la naturaleza y alcance de la información
        que se solicitará, del uso que Munbrunn S.A. de C.V. hará de tal
        información y de que esta podrá realizar consultas periódicas sobre mi
        historial, consintiendo que esta autorización se encuentre vigente por
        un periodo de 3 años contados a partir de su expedición y en todo caso
        durante el tiempo que se mantenga la relación jurídica.
      </Box>

      <Box mt={2}>Para autorizar ingrese nuevamente su contraseña.</Box>
      <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
        <ButtonComponent
          type="submit"
          text="Continuar"
          disabled={false}
          onClick={onClick}
        />
      </Box>
    </Box>
  );
};

export default AcceptTermCondition;
