import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    padding: "0 6%",
  },
  heading: {
    // color: customColor.accentBlue,
    color: customColor.black,
    fontSize: 32,
    fontFamily: "Nexa-Bold",
    fontWeight: "700",
    lineHeight: 1.2,
    textAlign: "center",
  },
  content: {
    color: "rgba(0, 0, 0, 0.5)",
    fontWeight: "500",
    lineHeight: 2,
    textAlign: "center",
    marginTop: theme.spacing(7),
    width: "85%",
  },
  text: {
    color: "rgba(0, 0, 0, 0.5)",
    marginTop: theme.spacing(7),
  },
  bottomContent: {
    marginTop: theme.spacing(18),
  },
  listContainer: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
    width: "90%",
    "& >.grid": {
      marginTop: 0,
      "& >.gridItem": {
        paddingTop: 0,
      },
    },
    "& .container": {
      padding: "0 10px",
    },
    "& img": {
      height: "100%",
      // marginTop: theme.spacing(3),
    },
    "& .title": {
      fontSize: 18,
      color: "rgba(0, 0, 0, 0.5)",
      fontWeight: "500",
      textAlign: "center",
      fontFamily: "SFUIText-Regular",
    },
    "& .desc": {
      fontSize: 18,
      marginTop: theme.spacing(2),
      fontFamily: "SFUIText-Regular",
      padding: `0 ${theme.spacing(3)}`,
    },
  },
  imageContainer: {
    backgroundColor: "#fff",
    backgroundImage:
      "linear-gradient(180deg, #EFEFEF 0%, rgba(253, 253, 253, 0) 100%)",
    borderRadius: 200,
    padding: "28px",
    filter: "drop-shadow(1px 4px 4px rgba(0, 0, 0, 0.25))",
    width: 148.66,
    height: 148.66,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
