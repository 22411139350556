import { makeStyles } from "@mui/styles";

const MultiSelectStyles = makeStyles((theme) => ({
    formContainer: {
        [theme.breakpoints.down("md")]: {
          width: 260,
        },
        [theme.breakpoints.up("md")]: {
          width: "40ch",
        },
      },
}));

export default MultiSelectStyles;