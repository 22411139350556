import api from "../api";
import medsiPayBook from "./config";

const payBookApi = {
  async getToken(data) {
    const response = await api.post(medsiPayBook.getToken, data);
    return response;
  },
  async saveTxnPayBook(data) {
    const response = await api.put(medsiPayBook.saveTxnPayBook, data);
    return response;
  },
  async saveMatiResponse(data) {
    const response = await api.put(medsiPayBook.saveMatiResponse, data);
    return response;
  },
};

export default payBookApi;
