import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";

import getParams from "../../../../features/installmentLoan/utils/getParams";
// import { useTheme } from "@emotion/react";

import BienvenidaMessage from "../../../../components/ModalContents/BienvenidaMessage";
import SubmissionModal from "../../../../components/ModalContents/SubmissionModal";
import ButtonComponent from "../../../../components/sharedComponents/ButtonComponent";
import CenteredContent from "../../../../components/sharedComponents/CenteredContent";
import ModalComponent from "../../../../components/sharedComponents/ModalComponent";
import PasswordItem from "../../../../components/sharedComponents/PasswordItem";
import {
  API_STATUS,
  FIELD_CHANNEL,
  REQUESTED_SOURCE,
} from "../../../../constants/common";
import useWindowSize from "../../../../hooks/useWindowSize";
import { setAuthToken } from "../../../../store/slices/auth";
import { saveUserRegisterInfo } from "../../../../store/slices/users";
import { errorToast, successToast } from "../../../../utils/toast";

import "../../../../../src/input.css";
import CongratulationPromoCode from "../../../../components/ModalContents/CongratulationPromocode";
import OTPVerification from "../../../../components/ModalContents/OTPVerification";
import PromoCode from "../../../../components/ModalContents/PromoCode";
import InputPhone from "../../../../components/sharedComponents/InputPhone";
import { signupPage } from "../../../../constants/gtm";
import useNavigationSteps from "../../../../hooks/useNavigationSteps";
import { useAppDispatch } from "../../../../store";
import { sendOTP } from "../../../../store/slices/auth/actions";
import { verifyPromocode } from "../../../../store/slices/users/actions";
import useDebounce from "../../../../utils/debounceHook";
import { GTMClick } from "../../../../utils/gtm";
import {
  containsAtLeastEightChars,
  containsAtLeastNumber,
  containsAtLeastOneUpperCase,
  containsSpecialChars,
} from "../../../../utils/validations";
import { validationSchema } from "./helper";
import RegistroStyles from "./styles";
import { URBANIST } from "../../../../constants/fontsConst";
//Formik an yup Validation schema

const RegisterForm = (props) => {
  const textRef = useRef(null);
  const classes = RegistroStyles();
  const windowSize = useWindowSize();
  const usableHeight = windowSize.height;
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //Password Validations
  const [hasEight, setHasEight] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const { userNavigation } = useNavigationSteps();
  const [countryCodeLength, setCountryCodeLength] = useState("");
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [openCongratPromo, setOpenCongratsPromo] = useState(false);
  const [message, setMessage] = useState();
  const [sendOtpRes, setSendOtpRes] = useState();
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_id,
    utm_term,
    utm_content,
  } = getParams([
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_id",
    "utm_term",
    "utm_content",
  ]);

  //Password input
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * @param {*} values
   * @description validate that the password comply
   * with all the password requirements
   */
  const validate = (values) => {
    let _errors = { ...errors };
    setDisabled(false);

    values.contrasena.match(containsAtLeastEightChars) === null
      ? setHasEight(false)
      : setHasEight(true);
    values.contrasena.match(containsAtLeastNumber) === null
      ? setHasNumber(false)
      : setHasNumber(true);

    values.contrasena.match(containsAtLeastOneUpperCase) === null
      ? setHasUpperCase(false)
      : setHasUpperCase(true);
    values.contrasena.match(containsSpecialChars) === null
      ? setHasSpecial(false)
      : setHasSpecial(true);

    if (values.phoneNumber) {
      if (values.phoneNumber.slice(-10).length < 10) {
        _errors.phoneNumber = "numeros minios requeridos 10";
      } else {
        _errors.phoneNumber = "";
      }
    } else {
      _errors.phoneNumber = "Este campo es requerido";
    }

    setErrors(_errors);
  };

  const handlePromoPopUp = () => {
    setPromoPopUp(true);
  };

  //formik initial state
  const formik = useFormik({
    initialValues: {
      correo: "",
      contrasena: "",
      phoneNumber: "",
      lengthPhone: "",
      promocode: "",
    },
    validationSchema: validationSchema,
    validate,
    validateOnChange: true,
    onSubmit: async () => {
      // await dispatch(resetUserRegisterInfo());
      // setOpen(true);
      setLoading(true);
      try {
        const { payload } = await dispatch(
          sendOTP({
            req_source: REQUESTED_SOURCE.REGISTRATION,
            channel: FIELD_CHANNEL.SMS,
            platform: 1,
            send_to: values.phoneNumber,
            email: values.correo,
          })
        );
        if (payload.status === API_STATUS.SUCCESS) {
          setSendOtpRes(payload || "");
          setOpenOtpVerification(true);
        }
        if (payload.status === API_STATUS.ERROR) {
          errorToast(payload?.error?.message);
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    },
  });

  const { errors, values, touched, handleBlur, setFieldValue, setErrors } =
    formik;

  useEffect(() => {
    if (props?.location?.state?.email) {
      setFieldValue("correo", props?.location?.state?.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Modal
  const [promoPopUp, setPromoPopUp] = useState(false);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openOtpVerification, setOpenOtpVerification] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const apiPromocode = useDebounce(async (promocode) => {
    const res = await dispatch(verifyPromocode({ promocode }));

    if (res?.payload?.data?.is_valid) {
      delete errors.promocode;
      setOpenCongratsPromo(!openCongratPromo);
      setMessage(res?.payload?.data?.msg);
      successToast(res?.payload?.data?.msg);
    } else {
      if (promocode === "") {
        delete errors.promocode;
      } else {
        errors.promocode = res?.payload?.data?.msg;
      }
      textRef.current.blur();
      // errorToast(res?.payload?.data?.msg);
    }
    formik.validateForm();
  }, 1000);

  const handlePromocode = (e) => {
    formik.setFieldValue("promocode", e.target.value);
    apiPromocode(e.target.value);
  };

  const handleClearPromoCode = () => {
    setShowPromoCode(false);
    formik.setFieldValue("promocode", "");
  };
  const handleContinue = async () => {
    const { values } = formik;
    GTMClick(signupPage.signUp);
    let res;
    let resAdditionalPayload = {};
    if (
      utm_source ||
      utm_medium ||
      utm_campaign ||
      utm_id ||
      utm_term ||
      utm_content
    ) {
      resAdditionalPayload = {
        utm_source,
        utm_medium,
        utm_campaign,
        utm_id,
        utm_term,
        utm_content,
      };
    }

    if (values?.promocode) {
      res = await dispatch(
        saveUserRegisterInfo({
          email: values.correo,
          password: values.contrasena,
          phone_number: `+${values.phoneNumber}`,
          promo_code: values.promocode.toUpperCase(),
          ...resAdditionalPayload,
        })
      ).unwrap();
    } else {
      res = await dispatch(
        saveUserRegisterInfo({
          email: values.correo,
          password: values.contrasena,
          phone_number: `+${values.phoneNumber}`,
          ...resAdditionalPayload,
        })
      ).unwrap();
    }

    if (res?.status === API_STATUS.SUCCESS) {
      dispatch(
        setAuthToken({ ...res?.data, phone_number: values.phoneNumber })
      );
      await userNavigation();
    } else if (res?.status === API_STATUS.ERROR) {
      errorToast(res?.meta?.errorMessage);
    }
  };

  // const theme = useTheme();
  // const isLarge = useMediaQuery(theme.breakpoints.up(1200));

  let phoneError = "";
  if (countryCodeLength.length === 0) {
    phoneError = "Este campo es requerido";
  } else if (countryCodeLength.length < 10) {
    phoneError = "numeros minios requeridos 10";
  }

  return (
    <div
      className={classes.mainRegistercontainer}
      
    >
      {/* form */}
      <CenteredContent className={classes.formMainContainer}>
        <form onSubmit={formik.handleSubmit} style={{ margin: "0 auto" }}>
          <Grid
            container
            width={{ sm:650,  md:780, lg: 780 }}
            rowSpacing={{ lg: 1.75 }}
            columnSpacing={{ sm: 1.75, lg: 10 }}
            className={classes.gridContainer}
          >
            <Grid item xs={12} sm={6}>
              <Box display="flex" alignItems="center">
                <Typography
                  sx={{
                    marginBottom: 1,
                    color: "#000",
                    fontFamily: URBANIST.REGULAR,
                    fontSize: 16,
                  }}
                 
                >
                  Correo electrónico
                </Typography>
              </Box>
              <TextField
                className="myinput"
                id="correo"
                name="correo"
                size="normal"
                autoComplete="off"
                placeholder="tucorreo@ejemplo.com"
                value={formik.values.correo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.correo && Boolean(formik.errors.correo)}
                helperText={formik.touched.correo && formik.errors.correo}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              
            >
              <Typography
                sx={{
                  margin: "4px 0",
                  color: "#000",
                  fontFamily: URBANIST.REGULAR,
                  fontSize: 16,
                }}
              >
                Crea una contraseña
              </Typography>
              <TextField
                type={showPassword ? "text" : "password"}
                className="myinput"
                id="contrasena"
                name="contrasena"
                size="normal"
                autoComplete="off"
                placeholder="Contraseña"
                value={formik.values.contrasena}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.contrasena && Boolean(formik.errors.contrasena)
                }
                helperText={
                  formik.touched.contrasena && formik.errors.contrasena
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="contrasena"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {formik.values.contrasena && (
                <Box className={classes.passwordItemsContainer}>
                  <PasswordItem
                    text="Al menos 8 caracteres"
                    success={hasEight}
                  />
                  <PasswordItem
                    text="Al menos una mayúscula"
                    success={hasUpperCase}
                  />
                  <PasswordItem
                    text="Al menos un caracter especial"
                    success={hasSpecial}
                  />
                  <PasswordItem
                    text="Al menos un caracter númerico"
                    success={hasNumber}
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontSize: 16,
                  marginTop: { xs: 2, md: "43px !important" },
                  color: "#000",
                  fontFamily: URBANIST.REGULAR,
                }}
              >
                Número de celular (10 dígitos)
              </Typography>
              <div className="telInput">
                <InputPhone
                  country="mx"
                  name="phoneNumber"
                  id="phoneNumber"
                  handleChange={(e, ...props) => {
                    setCountryCodeLength(e.slice(props[0].dialCode.length));
                    setFieldValue("lengthPhone", countryCodeLength);
                    setFieldValue("phoneNumber", e);
                  }}
                  value={values.phoneNumber}
                  errors={phoneError || errors.phoneNumber}
                  touched={touched.phoneNumber}
                  handleBlur={handleBlur}
                />
              </div>
              <div className={classes.phoneNumberDisclaimer}>
                Te enviaremos un mensaje en Whatsapp para validar tu cuenta
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography
                sx={{
                  fontSize: 16,
                  marginTop: { xs: 2, sm:"6px !important", md: "35px !important" },
                  marginBottom:2,
                  color: "#000",                
                }}
                className={classes.headerInput}
              >
                ¿Tienes algún código promocional?
              </Typography>

              <TextField
                inputRef={textRef}
                className="myinput"
                id="promocode"
                name="promocode"
                size="normal"
                autoComplete="off"
                placeholder="Su código de promoción"
                value={formik?.values?.promocode?.toUpperCase()}
                onChange={handlePromocode}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.promocode && Boolean(formik.errors.promocode)
                }
                helperText={formik.touched.promocode && formik.errors.promocode}
              />

              {/* <div style={{ marginTop: 5 }}>
                  Click here to view all{" "}
                  <span style={{ color: "#3552CC", cursor: "pointer" }}>
                    {" "}
                    Promo Codes
                  </span>
                </div> */}
            </Grid>
          </Grid>
          <div style={{ marginTop: 60, textAlign: "center" }}>
            <ButtonComponent
              className={classes.registerButton}
              loading={loading}
              style={{
                borderRadius: "100px !important",
                padding: "0px 72px",
                width: "auto",
                color:
                  Object.entries(formik.errors).length === 0 &&
                  !disabled &&
                  phoneError === ""
                    ? ""
                    : "#fff",
              }}
              type="submit"
              text="Crear cuenta"
              disabled={
                Object.entries(formik.errors).length === 0 &&
                !disabled &&
                phoneError === ""
                  ? false
                  : true
              }
            />
          </div>
        </form>
      </CenteredContent>

      {/* Modal Component */}

      <ModalComponent
        open={promoPopUp}
        handlePromoPopUp={handlePromoPopUp}
        body={
          <PromoCode
            setPromoPopUp={setPromoPopUp}
            formik={formik}
            setShowPromoCode={setShowPromoCode}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => {
          setPromoPopUp(false);
          formik.setFieldValue("promocode", "");
        }}
      />

      <ModalComponent
        open={openOtpVerification}
        // open={true}
        setOpenOtpVerification={setOpenOtpVerification}
        body={
          <OTPVerification
            phoneNumber={values?.phoneNumber}
            handleClose={() => setOpenOtpVerification(false)}
            otp_res={sendOtpRes}
            setOpenTnc={setOpen}
            email={values?.correo}
            semilla={sendOtpRes?.data?.semilla}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpenOtpVerification(false)}
      />

      <ModalComponent
        open={openCongratPromo}
        setOpenCongratsPromo={setOpenCongratsPromo}
        body={
          <CongratulationPromoCode
            setOpenCongratsPromo={setOpenCongratsPromo}
            setPromoPopUp={setPromoPopUp}
            setShowPromoCode={setShowPromoCode}
            message={message}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpenCongratsPromo(false)}
      />

      <ModalComponent
        open={open}
        handleOpen={handleOpen}
        body={
          <BienvenidaMessage
            setLoader={handleContinue}
            userEmail={formik.values.correo}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpen(false)}
      />
      <ModalComponent
        open={openAlert}
        handleOpen={handleOpenAlert}
        handleClose={handleCloseAlert}
        body={<SubmissionModal handleClose={handleCloseAlert} />}
      />
      {/* form */}
    </div>
  );
};

export default RegisterForm;
