import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ButtonComponent from "../../sharedComponents/ButtonComponent";
// import ButtonComponent from "../ButtonComponent";
import AddCircle from "@mui/icons-material/AddCircle";
// import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import { API_STATUS } from "../../../constants/common";
import { useAppDispatch } from "../../../store";
import { getApplicationAgreement } from "../../../store/slices/users/actions";
import TermConditionTextStyles from "./TermsAndConditions.styles";

const StepperAcceptTermCondtion = (props) => {
  const urlId = window.location.search.slice(1, window.location.search.length);

  const dispatch = useAppDispatch();
  const { setOpen, setIsAccepted, tncUrl, isLoadingTerms } = props;
  // const [isLoadingTerms, setIsLoadingTerms] = useState(false);
  const [agreementUrl, setAgreementUrl] = useState("");
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const classes = TermConditionTextStyles();

  // const loadAgreement = async () => {
  //   setIsLoadingTerms(true);
  //   const { status, data } = await dispatch(
  //     getApplicationAgreement(urlId)
  //   ).unwrap();

  //   if (status === API_STATUS.SUCCESS) {
  //     setAgreementUrl(data?.loan_agreement);
  //   }
  //   setIsLoadingTerms(false);
  // };

  // useEffect(() => {
  //   loadAgreement();
  // }, []);

  const onClick = async () => {
    setOpen(false);
    setIsAccepted(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.mainContainer}>
      {isLoadingTerms ? (
        <Box
          sx={(theme) => ({
            height: "300px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "24px",
            color: "#6B7779",
            fontFamily: "SFUIText-bold",
            textAlign: "center",
            [theme.breakpoints.down("lg")]: {
              fontFamily: "Urbanist-Bold",
              fontSize: "21px",
            },
            [theme.breakpoints.down("tablet")]: {
              fontSize: "18px",
            },
          })}
        >
          Has hecho tu trabajo. Siéntese y espere mientras generamos el contrato
          de solicitud de préstamo para usted......
        </Box>
      ) : (
        <>
          <div style={{ marginLeft: 40 }}>
            <Box
              sx={(theme) => ({
                fontSize: "24px",
                color: "#6B7779",
                fontFamily: "SFUIText-bold",
                padding: "30px 0",
                [theme.breakpoints.down("lg")]: {
                  fontFamily: "Urbanist-Bold !important",
                  fontSize: "21px",
                },
                [theme.breakpoints.down("tablet")]: {
                  fontSize: "18px",
                },
              })}
            >
              Términos y Condiciones de la solicitud de Préstamo
            </Box>
            {/* <div
              // href="#lastLine"
              style={{
                position: "absolute",
                bottom: 50,
                right: 80,
                zIndex: 20,
              }}
              onClick={() => {
                contractRef.current.scrollIntoView({
                  behavior: "smooth",
                  block: "nearest",
                });
              }}
            >
              <ExpandCircleDownIcon color="primary" fontSize="large" />
            </div> */}
            {/* <div
              style={{
                fontSize: 18,
                color: "#6B7779",
              }}
            >
              Last updated on 11/05/2022
            </div> */}
            <AddCircle
              style={{
                color: "#3552CC",
                transform: "rotate(45deg)",
                position: "absolute",
                right: 20,
                top: 20,
              }}
              onClick={handleClose}
            />
            {!iframeLoaded && (
              <Box
                sx={(theme) => ({
                  height: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "24px",
                  color: "#6B7779",
                  fontFamily: "SFUIText-bold",
                  textAlign: "center",
                  [theme.breakpoints.down("lg")]: {
                    fontFamily: "Urbanist-Bold",
                    fontSize: "21px",
                  },
                  [theme.breakpoints.down("tablet")]: {
                    fontSize: "18px",
                  },
                })}
              >
                Has hecho tu trabajo. Siéntese y espere mientras generamos el
                contrato de solicitud de préstamo para usted......
              </Box>
            )}
            <iframe
              title="agreementPdf"
              src={`${tncUrl}`}
              style={{
                width: iframeLoaded ? "100%" : 0,
                height: iframeLoaded ? "calc(100vh - 100px)" : 0,
              }}
              onLoadStart={() => console.log("start")}
              onLoadedData={() => console.log("loaded")}
              onLoad={() => setIframeLoaded(true)}
            />

            {/* <div
              style={{
                border: "1px solid rgba(199, 200, 206, 0.3",
                borderRadius: 10,
                marginRight: 60,
                padding: "24px 19px 100px",
                marginTop: 24,
                maxHeight: "630px",
                overflow: "auto",
                position: "relative",
              }}
            >
              <div
                style={{
                  marginBottom: 9,
                  fontSize: 24,
                  color: "#494949",
                  fontFamily: "SFUIText-bold",
                }}
              >
                1. Clause 1
              </div>
              <div style={{ fontSize: 18, marginBottom: 30 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </div>

              <div
                style={{
                  marginBottom: 9,
                  fontSize: 24,
                  color: "#494949",
                  fontFamily: "SFUIText-bold",
                }}
              >
                2. Clause 2
              </div>
              <div style={{ fontSize: 18, marginBottom: 30 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </div>

              <div
                style={{
                  marginBottom: 9,
                  fontSize: 24,
                  color: "#494949",
                  fontFamily: "SFUIText-bold",
                }}
              >
                3. Clause 3
              </div>
              <div style={{ fontSize: 18, marginBottom: 30 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </div>

              <div
                style={{
                  marginBottom: 9,
                  fontSize: 24,
                  color: "#494949",
                  fontFamily: "SFUIText-bold",
                }}
              >
                3. Clause 3
              </div>
              <div style={{ fontSize: 18, marginBottom: 30 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </div>
              <div
                style={{
                  marginBottom: 9,
                  fontSize: 24,
                  color: "#494949",
                  fontFamily: "SFUIText-bold",
                }}
              >
                3. Clause 3
              </div>
              <div style={{ fontSize: 18, marginBottom: 30 }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra
                condimentum eget purus in. Consectetur eget id morbi amet amet,
                in. Ipsum viverra pretium tellus neque. Ullamcorper suspendisse
                aenean leo pharetra in sit semper et. Amet quam placerat sem.
              </div>

              <div id="lastLine" />
            </div> */}
          </div>

          {iframeLoaded && (
            <div
              style={{
                position: "absolute",
                bottom: "-14px",
                margin: "0 auto",
                left: 0,
                right: 0,
              }}
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonComponent
                  type="submit"
                  text="Estoy De Acuerdo"
                  disabled={false}
                  onClick={onClick}
                />
              </Box>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StepperAcceptTermCondtion;
