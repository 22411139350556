import { FC, useState, ChangeEvent } from "react";
import { Box } from "@mui/material";

import LockIcon from "../../../components/icons/Lock";
import TextInput from "../../../features/installmentLoan/components/TextInput";
import PasswordItem from "../../../components/sharedComponents/PasswordItem";
import {
  containsAtLeastEightChars,
  containsAtLeastNumber,
  containsAtLeastOneUpperCase,
  containsSpecialChars,
} from "../../../utils/validations";
import Button from "../../../features/installmentLoan/components/Button";

import useStyles from "./styles";
import useCommonStyles from "../styles";

import MettaGradient from "../../../assets/Logos/gradient-logo.svg";
import { URBANIST_FONT } from "../../../constants/common";

export interface ButtonClickProps {
  password: string;
}

interface ResetPopupProps {
  onSubmit: (payload: ButtonClickProps) => void;
}

const ResetPopup: FC<ResetPopupProps> = ({ onSubmit }) => {
  const css = useStyles();
  const commonCss = useCommonStyles();

  const [passwordValue, setPasswordValue] = useState<string>("");
  const [confirmationPassword, setConfirmationPassword] = useState<string>("");
  //Password Validations
  const [hasEight, setHasEight] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasSpecial, setHasSpecial] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);
  const [checkErrorPassword, setCheckError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleInputChange = (value) => {
    value.match(containsAtLeastEightChars) === null
      ? setHasEight(false)
      : setHasEight(true);
    value.match(containsAtLeastNumber) === null
      ? setHasNumber(false)
      : setHasNumber(true);

    value.match(containsAtLeastOneUpperCase) === null
      ? setHasUpperCase(false)
      : setHasUpperCase(true);
    value.match(containsSpecialChars) === null
      ? setHasSpecial(false)
      : setHasSpecial(true);
    setPasswordValue(value);
  };

  const isValid =
    hasEight &&
    hasUpperCase &&
    hasSpecial &&
    hasNumber &&
    confirmationPassword === passwordValue;
  const isBothMatched =
    checkErrorPassword && confirmationPassword !== passwordValue;
  return (
    <Box display={"flex"} flexDirection="column" alignItems="center">
      <img src={MettaGradient} width={92} alt="img blanco" />
      <Box className={commonCss.title} ml={3} mt={5}>
        Ingresa tu nueva contraseña
      </Box>
      <Box mt={5}>
        <TextInput
          id="password"
          type={showPassword ? "text" : "password"}
          placeholder="Contraseña"
          icon={<LockIcon className={css.textInputIconStyle} />}
          className={css.input}
          value={passwordValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            handleInputChange(e.target.value);
          }}
          iconClassName={commonCss.iconWrapperStyle}
          noIcon
        />
        <TextInput
          id="confirmPassword"
          type={showPassword ? "text" : "password"}
          placeholder="Confirma tu contraseña"
          className={css.input}
          value={confirmationPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setConfirmationPassword(e.target.value);
          }}
          leftIconBlank
          onBlur={() => setCheckError(true)}
          noIcon
        />
        {isBothMatched && (
          <Box pl={8} mt={-2} className={css.errorText} mb={2}>
            Las contraseñas deben coincidir
          </Box>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            name="showPassword"
            type="checkbox"
            checked={showPassword}
            onChange={() => setShowPassword((checked) => !checked)}
            style={{ height: 18, width: 18 }}
          />{" "}
          <Box
            pl={2}
            style={{
              fontSize: 14,
              fontFamily: URBANIST_FONT.REGULAR,
            }}
          >
            Mostrar contraseña
          </Box>
        </div>
        <Box className={css.passwordItemsContainer} mt={4}>
          <PasswordItem text="Al menos 8 caracteres" success={hasEight} />
          <PasswordItem text="Al menos una mayúscula" success={hasUpperCase} />
          <PasswordItem
            text="Al menos un caracter especial"
            success={hasSpecial}
          />
          <PasswordItem
            text="Al menos un caracter númerico"
            success={hasNumber}
          />
        </Box>
      </Box>

      <Box textAlign="center">
        <Button
          label="Continuar"
          onClick={() =>
            onSubmit({
              password: passwordValue,
            })
          }
          noCaps
          className={commonCss.loginButtonStyle}
          disabled={!isValid}
        />
      </Box>
    </Box>
  );
};

export default ResetPopup;
