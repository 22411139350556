import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PocedureIconAndTitle from "../ProcedureIconAndTitle";
import MedicSpecializationStyles from "./MedicSpecialization.styles";
import Hospital from "../../../../assets/procedureType/hospital.svg";
import ortopediaIcon from "../../../../assets/procedureType/OrtopediaIcon.svg";
import oftalIcon from "../../../../assets/procedureType/OftalIcon.svg";
import dermaIcon from "../../../../assets/procedureType/DermaIcon.svg";
import ginecoIcon from "../../../../assets/procedureType/MaternidadIcon.svg";
const MedicSpecializations = () => {
  const classes = MedicSpecializationStyles();
  return (
    <Box>
      <Box className={classes.container}>
        <PocedureIconAndTitle />
        <img src={Hospital} className={classes.image} alt="espMed" />
      </Box>
      <Box
        sx={{
          background: "#FFFFFF",
          boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
          borderBottomLeftRadius: `20px`,
          borderBottomRightRadius: `20px`,
          paddingBottom: 10,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Urbanist-SemiBold",
            fontSize: 28,
            color: "rgba(0, 0, 0, 0.5)",
            marginLeft: 22,
          }}
        >
          Especialidades médicas
        </Typography>
        <Grid container spacing={12} className={classes.tableView}>
          <Grid item sm={12} md={6} lg={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={ortopediaIcon}
                alt="ortopedia-icn"
                style={{ marginLeft: "-220px", width: 80, height: 80 }}
              />
              <Typography
                sx={{
                  marginLeft: 3,
                  fontFamily: "Urbanist-Bold",
                  fontSize: 20,
                }}
              >
                Ortopedia
              </Typography>
              <br />
            </Box>
            <Box>
              Reemplazo de articulaciones (cadera, hombro, rodilla, codo)
              <br />
              <br />
              Reconstrucción de ligamentos de rodilla
              <br />
              <br />
              Artroscopía (hombro, codo, muñeca, rodilla)
              <br />
              <br />
              Reparación de tobillo
              <br />
              <br />
              Cirugía de columna vertebral (fusión, discectomía, cifoplastía,
              laminectomía)
              <br />
              <br />
              Fusión articular
              <br />
              <br />
              Descompresión de nervios (tunel carpiano, cadera, etc)
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={oftalIcon}
                alt="oftal-icn"
                style={{ marginLeft: "-190px", width: 80, height: 80 }}
              />
              <Typography
                sx={{
                  marginLeft: 3,
                  fontFamily: "Urbanist-Bold",
                  fontSize: 20,
                }}
              >
                Oftalmología
              </Typography>
              <br />
            </Box>

            <Box>
              Cirugía de cataratas
              <br />
              <br />
              Cirugía refractiva con laser (Eximer Láser)
              <br />
              <br />
              Lentes intraoculares OCL
              <br />
              <br />
              Transplante de cornea (total, lamelar, endotelio corneal)
              <br />
              <br />
              Queractetomía fotorefractiva (PKR)
              <br />
              <br />
              Cirugías de glaucoma Corrección de estrabismo, etc
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={dermaIcon}
                alt="derma-icn"
                style={{ marginLeft: "-190px", width: 80, height: 80 }}
              />
              <Typography
                sx={{
                  marginLeft: 3,
                  fontFamily: "Urbanist-Bold",
                  fontSize: 20,
                }}
              >
                Dermatología
              </Typography>
              <br />
            </Box>
            <Box>
              Eliminación de cicatrices del acné
              <br />
              <br />
              Dermoabrasión
              <br />
              <br />
              Rejuvenecimiento de la piel con láser
              <br />
              <br />
              Tratamientos por daños causados por el sol
              <br />
              <br />
              Crioterapia
              <br />
              <br />
              Exfoliación química
              <br />
              <br />
              Eliminación de tatuajes
              <br />
              <br />
              Toxinas y rellenos dérmicos, etc
            </Box>
          </Grid>
          <Grid item sm={12} md={6} lg={3}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src={ginecoIcon}
                alt="gineco-icn"
                style={{ marginLeft: "-110px", width: 80, height: 80 }}
              />
              <Typography
                sx={{
                  marginLeft: 3,
                  fontFamily: "Urbanist-Bold",
                  fontSize: 20,
                }}
              >
                Ginecología y Maternidad
              </Typography>
              <br />
            </Box>
            <Box>
              Financiamiento de paquetes para parto natural o por cesárea
              <br />
              <br />
              Histerectomía
              <br />
              <br />
              Ablación endomentrial
              <br />
              <br />
              Extirpación de pólipos, miomas, fibromas y quistes
              <br />
              <br />
              Esterilización histeroscópica, etc
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MedicSpecializations;
