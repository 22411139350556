import { FC } from "react";

import { IconProps, obsidian } from "./types";

const DownloadFileIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.63053 9.18682C5.32762 9.18682 5.85048 9.03747 6.17405 8.73877C6.52258 8.44007 6.67192 7.96704 6.67192 7.34463C6.67192 6.69738 6.49757 6.22435 6.17405 5.92546C5.85034 5.62676 5.32762 5.45239 4.60554 5.45239H2.9375V10.6308H3.98314V9.18682H4.63053ZM3.98314 6.44833H4.58069C4.92923 6.44833 5.17824 6.523 5.35243 6.64752C5.52678 6.77204 5.60146 6.99606 5.60146 7.29478C5.60146 7.59349 5.52679 7.81768 5.40227 7.96702C5.27775 8.11637 5.0289 8.19104 4.68037 8.19104H3.98328L3.98314 6.44833Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M10.7799 9.90872C11.228 9.43565 11.4272 8.81325 11.4272 7.99167C11.4272 7.19491 11.2032 6.5477 10.755 6.09961C10.3069 5.65157 9.65949 5.42737 8.81306 5.42737H7.14502V10.6058H8.76318C9.65962 10.6306 10.3319 10.3817 10.7799 9.90868L10.7799 9.90872ZM9.98336 9.21163C9.73433 9.4855 9.36096 9.61001 8.88788 9.61001H8.21564V6.44815H8.8132C9.3361 6.44815 9.73431 6.5975 10.0082 6.87136C10.2572 7.14523 10.4065 7.5436 10.4065 8.04148C10.3817 8.53936 10.2324 8.93774 9.98333 9.2116L9.98336 9.21163Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M15.2365 6.47306V5.45227H12V10.6307H13.0456V8.58928H15.112V7.59334H13.0456V6.47306H15.2365Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M16.6058 0H1.5685C0.697048 0 0 0.697093 0 1.5685V19.9419C0 20.8133 0.697093 21.5104 1.5685 21.5104H5.02907L4.08297 20.5643H1.59335C1.24482 20.5643 0.97095 20.2904 0.97095 19.9419L0.971124 1.5685C0.971124 1.21997 1.24499 0.946098 1.59353 0.946098H16.6557C17.0042 0.946098 17.2781 1.21997 17.2781 1.5685V19.9419C17.2781 20.2904 17.0042 20.5643 16.6557 20.5643H14.5146L13.5685 21.5104H16.6557C17.5272 21.5104 18.2242 20.8133 18.2242 19.9419L18.224 1.5685C18.1992 0.697226 17.4771 0 16.6059 0H16.6058Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
      <path
        d="M14.5893 18.5726L11.3278 18.5477V13.1702H6.84649V18.473L3.58496 18.4232L9.1617 23.9999L14.5893 18.5726Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

DownloadFileIcon.defaultProps = {
  fill: obsidian,
};

export default DownloadFileIcon;
