import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LogoWhite from "../../../assets/header/logo.svg";
import { GTMClick } from "../../../utils/gtm";
import { homePage } from "../../../constants/gtm";
import LogInIcon from "../../../assets/header/LogInIcon.svg";
import { ROUTES, AUTH_ROUTES } from "../../../constants/routes";
import useStyles from "./styles";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";

const Header = () => {
  const css = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname = "" } = location;

  const isMobile = useMediaQuery(theme.breakpoints.down(1000));
  const langPrefix = "instantLineCreditTS.homeV2.header";

  const handleLoginClick = () => {
    GTMClick(homePage.loginHeader);
    history.push(AUTH_ROUTES.LOGIN);
  };

  const handleRegisterClick = () => {
    GTMClick(homePage.signUpHeader);
    history.push(ROUTES.register);
  };
  const handleCreditRequestClick = () => {
    // GTMClick(homePage.signUpHeader);
    // history.push(ROUTES.register);
    history.push(ROUTES.landingCreditRequest);
  };
  const handleNewsClick = () => {
    GTMClick(homePage.newsHeader);
    history.push(ROUTES.medsiInTheNews);
  };

  const handleProcedureClick = () => {
    GTMClick(homePage.loginHeader);
    history.push(ROUTES.procedureType);
  };

  const clickAboutUs = () => {
    GTMClick(homePage.signUpHeader);
    history.push(ROUTES.aboutUs);
  };

  const homeClick = () => {
    history.push(ROUTES.splashScreen);
  };

  const clickFaqs = () => {
    GTMClick(homePage.signUpHeader);
    history.push(ROUTES.faqs);
  };

  const promocode = () => {
    history.push(ROUTES.publicPromocode);
  };

  const menuList = [
    {
      label: t(`${langPrefix}.menuList.menu1`),
      onClick: clickAboutUs,
      selected: pathname === ROUTES.aboutUs,
    },
    {
      label: t(`${langPrefix}.menuList.menu7`),
      onClick: handleNewsClick,
      selected: pathname === ROUTES.medsiInTheNews,
    },
    {
      label: t(`${langPrefix}.menuList.menu2`),
      onClick: handleProcedureClick,
      selected: pathname === ROUTES.procedureType,
    },
    {
      label: t(`${langPrefix}.menuList.menu3`),
      onClick: handleCreditRequestClick,
      selected: pathname === ROUTES.landingCreditRequest,
    },
    {
      label: t(`${langPrefix}.menuList.menu4`),
      onClick: clickFaqs,
      selected: pathname === ROUTES.faqs,
    },

    // {
    //   label: t(`${langPrefix}.menuList.menu8`),
    //   onClick: promocode,
    //   selected: pathname === ROUTES.publicPromocode,
    // },
    {
      label: t(`${langPrefix}.menuList.menu5`),
      icon: LogInIcon,
      onClick: handleLoginClick,
    },
    {
      label: t(`${langPrefix}.menuList.menu6`),
      onClick: handleRegisterClick,
      menuCss: css.register,
    },

    // { label: "EN ES" },
  ];

  if (isMobile) {
    return <MobileMenu menuList={menuList} />;
  }

  return (
    <>
      <Box className={css.headerMainContainer}>
        <Box>
          <img
            className="img"
            src={LogoWhite}
            onClick={homeClick}
            alt="medsi-logo"
          />
        </Box>
        <Menu menuList={menuList} />
      </Box>
    </>
  );
};

export default Header;
