import AddCircle from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import { Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  API_STATUS,
  FIELD_CHANNEL,
  REQUESTED_SOURCE,
  VERIFICATION_METHOD,
} from "../../../constants/common";
import Button from "../../../features/installmentLoan/components/Button";
import TextInput from "../../../features/installmentLoan/components/TextInput";
import { useAppDispatch } from "../../../store";
import { getAuthState } from "../../../store/slices/auth";
import {
  resendOTPWithAuth,
  sendOTPWithAuth,
  verifyOTPWithAuth,
  verifyPassword,
} from "../../../store/slices/auth/actions";
import {
  resetUserRegisterInfo,
  selectUserEmail,
} from "../../../store/slices/users";
import Theme from "../../../theme";
import { errorToast, successToast } from "../../../utils/toast";
import ButtonComponent from "../../sharedComponents/ButtonComponent";
import RegistrationVerifyPasswordStyles from "./RegistrationVerifyPassword.styles";

const TIMEOUT_MINUTES = 2;
const TIMEOUT_SECONDS = 0;

const MAX_LENGTH = 6;

const RegistrationVerifyPassword = (props) => {
  const {
    setLoader,
    userEmail,
    handleClose,
    onBackdropClick,
    phoneNumber = "",
    otp_res,
    setOpenTnc,
    setValidPassword,
  } = props;
  const dispatch = useAppDispatch();
  const classes = RegistrationVerifyPasswordStyles();

  const [preferredVerification, setPreferredVerification] = useState(
    VERIFICATION_METHOD.OTP
  );

  const auth = useSelector(getAuthState);
  const email = useSelector(selectUserEmail);

  const [isResendDisabled, setIsResendDisabled] = useState(true);
  const [otpId, setOtpId] = useState();
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [error, setError] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const [semilla, setSemilla] = useState();
  const [sendOtpCount, setSendOtpCount] = useState(0);

  const [minutes, setMinutes] = useState(TIMEOUT_MINUTES);
  const [seconds, setSeconds] = useState(TIMEOUT_SECONDS);
  const [otp, setOtp] = useState("");
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isVerifyLoading, setIsVerifyLoading] = useState(false);
  const [isPasswordVerifying, setIsPasswordVerifying] = useState(false);

  const sendOTP = async () => {
    const { data, status, message } = await dispatch(
      sendOTPWithAuth({
        req_source: REQUESTED_SOURCE.BUREAU_CONSENT_SAVE,
        channel: FIELD_CHANNEL.SMS,
        platform: 1,
        send_to: auth.phoneNumber?.replace("+", "") || "",
        email,
      })
    ).unwrap();

    if (status === API_STATUS.SUCCESS) {
      setOtpId(data?.id || "");
      setSendOtpCount(data?.send_count);
      setSemilla(data?.semilla || "");
    }

    if (status === API_STATUS.ERROR) {
      setError(message);
    }
  };

  useEffect(() => {
    sendOTP();
  }, []);

  //Timer
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          setIsResendDisabled(false);
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const onOtpChange = (e) => {
    if (verifyError) {
      setVerifyError("");
    }
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setOtp(e.target.value);
    }
  };

  const handleVerifyOtp = async () => {
    setIsVerifyLoading(true);
    try {
      const { status, data, meta, message } = await dispatch(
        verifyOTPWithAuth({
          id: otpId,
          otp_value: otp,
          sent_to: auth.phoneNumber?.replace("+", "") || "",
          req_source: REQUESTED_SOURCE.BUREAU_CONSENT_SAVE,
          email,
          semilla,
        })
      ).unwrap();

      if (status === API_STATUS.SUCCESS) {
        setValidPassword(true);
        successToast(data?.msg || "OTP verificado con éxito");
        handleClose();
      } else if (status === API_STATUS.ERROR) {
        // setError(message);
        setVerifyError(message);
        errorToast(meta?.errorMessage);
      }
      setIsVerifyLoading(false);
    } catch (e) {
      console.log("Error", e);
      setIsVerifyLoading(false);
    }
  };

  const handleResendOtp = async () => {
    setIsResendLoading(true);
    setError("");
    setVerifyError("");
    try {
      const {
        payload: { data, meta, status, message },
      } = await dispatch(
        resendOTPWithAuth({
          req_source: REQUESTED_SOURCE.BUREAU_CONSENT_SAVE,
          channel: FIELD_CHANNEL.SMS,
          platform: 1,
          send_to: auth?.phoneNumber?.replace("+", "") || "",
          id: otpId,
          semilla,
        })
      );

      setMinutes(TIMEOUT_MINUTES);
      setSeconds(TIMEOUT_SECONDS);
      setIsResendDisabled(true);

      if (status === API_STATUS.SUCCESS) {
        setSemilla(data?.semilla || "");
        setSendOtpCount(data?.send_count);
        setOtpId(data?.id);
      }
      if (status === API_STATUS.ERROR || message) {
        setError(message);
      }
      setIsResendLoading(false);
    } catch (error) {
      console.log("Error", error);
      setIsResendLoading(false);
    }
  };

  //password
  const checkPassword = async (text) => {
    setIsPasswordVerifying(true);
    setValidPassword(false);
    try {
      const { status, meta, data } = await dispatch(
        verifyPassword({
          password: text,
        })
      ).unwrap();

      if (status === API_STATUS.SUCCESS) {
        successToast(data?.msg || "Password verified success");
        setValidPassword(true);
        handleClose();
      } else if (status === API_STATUS.ERROR) {
        errorToast(meta?.errorMessage);
      }
      setIsPasswordVerifying(false);
    } catch (error) {
      console.log("error", error);
      setIsPasswordVerifying(false);
    }
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <AddCircle
        style={{
          color: "#3552CC",
          transform: "rotate(45deg)",
          position: "absolute",
          right: 20,
          top: 20,
          zIndex: 20,
          cursor: "pointer",
        }}
        onClick={handleClose}
      />

      {preferredVerification === VERIFICATION_METHOD.OTP && (
        <>
          <Grid item xs={12} className={classes.modalTitleContainer}>
            <Typography
              variant="subtitle2"
              align="center"
              className={classes.modalTitle}
            >
              Le hemos enviado un código de verificación en Whatsapp a su número
              de teléfono registrado {auth.phoneNumber || ""}
            </Typography>
          </Grid>

          <Grid item xs={12} mt={6}>
            <TextField
              // inputRef={textRef}
              className="myinput"
              id="otp"
              name="otp"
              size="normal"
              autoComplete="off"
              value={otp}
              onChange={onOtpChange}
              inputProps={{ maxLength: MAX_LENGTH }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {otp.length === MAX_LENGTH && (
                      <div className={classes.checkIconWrapper}>
                        <CheckIcon style={{ fontSize: 16, color: "#fff" }} />
                      </div>
                    )}
                  </InputAdornment>
                ),
              }}
              error={!!error || !!verifyError}
              helperText={error || verifyError}
            />
          </Grid>

          {(minutes !== 0 || seconds !== 0) && !verifyError ? (
            <Grid
              item
              xs={12}
              className={classes.modalTitleContainer}
              mt={1}
              display="flex"
              justifyContent="center"
            >
              <Typography
                variant="subtitle2"
                align="center"
                className={classes.timerText}
              >
                El código de verificación caduca en
                {` 0${minutes}:${seconds < 10 ? "0" : ""}${seconds}`} segundos
              </Typography>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} className={classes.buttonContainer} mt={7}>
            <Button
              label="Reenviar codigo"
              noCaps
              variant="text"
              className="resendCodeStyle"
              style={{
                background:
                  isResendDisabled || !!error || isResendLoading
                    ? Theme.palette.primary.gray
                    : "linear-gradient(270deg, #1B63DB 0%, #0ACC97 100%)",
              }}
              loading={isResendLoading}
              onClick={handleResendOtp}
              disabled={isResendDisabled || !!error}
            />
            <Button
              label="Verificar"
              noCaps
              variant="text"
              className="VerifyButton"
              style={{
                background:
                  otp.length === MAX_LENGTH && !error && !isVerifyLoading
                    ? "linear-gradient(270deg, #1B63DB 0%, #0ACC97 100%)"
                    : Theme.palette.primary.gray,
              }}
              isSubmitting={isVerifyLoading}
              disabled={otp.length < MAX_LENGTH || !!error}
              onClick={handleVerifyOtp}
            />
          </Grid>
          {(error || sendOtpCount >= 3) && (
            <div
              className={classes.otpAlternativeTitle}
              onClick={() =>
                setPreferredVerification(VERIFICATION_METHOD.PASSWORD)
              }
            >
              ¿Intentar contraseña en su lugar?
            </div>
          )}
        </>
      )}

      {preferredVerification === VERIFICATION_METHOD.PASSWORD && (
        <Grid
          container
          direction="row"
          width={{ lg: 900 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="row"
          className={classes.gridContainer}
        >
          <Grid
            item
            xs={12}
            // textAlign="center"
            justifyContent={"center"}
            alignItems="center"
            display={"grid"}
          >
            <Typography variant="body1" className={classes.textGender}>
              {/* Confirma ingresando tu contraseña */}
              Ingrese la contraseña que estableció durante el registro
            </Typography>
            <Box display="flex" className={classes.passwordView} mt={{ lg: 1 }}>
              <TextInput
                id="password"
                type="password"
                placeholder="Confirma tu contraseña"
                className={classes.input}
                value={confirmationPassword}
                onChange={(e) => {
                  setConfirmationPassword(e.target.value);
                }}
                onBlur={() => {}}
              />

              <ButtonComponent
                onClick={() => checkPassword(confirmationPassword)}
                text="Verificar"
                className="verifyButton"
                loading={isPasswordVerifying}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default RegistrationVerifyPassword;
