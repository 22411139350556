import axios from "axios";
import urls from "../../config/url";


const validateEmail = (email) => {
  const rg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return rg.test(email);
};

export const saveEmail = async ({ email }) => {
  if (validateEmail(email)) {
    try {
      const { data } = await axios.post(
        `${urls.baseUrl}/api/v1/loan-query-request/`,
        { email }
      );
      return data;
    } catch (details) {
      const { data } = details?.response;
      return {
        status: "error",
        msg: data?.error?.email?.[0],
      };
    }
  }
  return {
    status: "error",
    msg: "Email inválido",
  };
};
