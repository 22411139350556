
import { Box } from "@mui/material";
import React from "react";

const CenteredContent = ({ children, className, direction, style = null }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection={direction && direction}
      className={className}
      style={style}
    >
      {children}
    </Box>
  );
};

export default CenteredContent;
