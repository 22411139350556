import { Visibility, VisibilityOff } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { ChangeEvent, FC, ReactNode, useState } from "react";

import Button from "../Button";
import useStyles from "./styles";

const TYPE_PASSWORD = "password";

export interface InputProps {
  icon?: ReactNode;
  noIcon?: boolean;
  type?: string;
  value?: string;
  id?: string;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClickRightButton?: () => void;
  leftIconBlank?: boolean;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  maxLength?: number;
  style?: Object;
  iconClassName?: string;
}

const TextInput: FC<InputProps> = ({
  icon,
  noIcon = false,
  type = "text",
  value,
  id,
  placeholder,
  className,
  onChange,
  onClickRightButton,
  leftIconBlank = false,
  onBlur,
  label = "",
  iconClassName = "",
  inputClassName,
  ...inputProps
}) => {
  const rightIcon = type === TYPE_PASSWORD && !noIcon;
  const css = useStyles({
    rightIcon,
  });

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <Box>
      {label && <Box className={css.label}>{label}</Box>}
      <Box className={`${css.textInputContainerStyle} ${className}`}>
        {icon && (
          <Box className={`${css.iconBoxStyle} ${iconClassName}`}>{icon}</Box>
        )}
        {leftIconBlank && (
          <Box className={`${css.iconBoxStyle} ${css.iconBlank}`} />
        )}
        <input
          className={`${css.textInputStyle} ${inputClassName}`}
          type={showPassword ? "text" : type}
          value={value}
          id={id}
          placeholder={placeholder}
          spellCheck={false}
          onChange={onChange}
          onBlur={onBlur}
          {...inputProps}
        />
        {rightIcon && (
          <Button
            className={css.rightIconBoxStyle}
            onClick={() => {
              onClickRightButton && onClickRightButton();
              setShowPassword(!showPassword);
            }}
            variant="text"
            label={showPassword ? <VisibilityOff /> : <Visibility />}
          />
        )}
      </Box>
    </Box>
  );
};

export default TextInput;
