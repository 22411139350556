import moment from "moment";

import { emailRegex } from "../../utils/validations";
import { currencyFormat } from "../../utils";
import { convertTime12to24 } from "../../utils/formatter";
import { OTHER_OPTION } from "../../constants/generals";
import {
  isOtherShow,
  isProcedureShow,
  isOtherShowClinic,
  isBranchSelectionAllowed,
  getNumber,
} from "../common/ProcedureForm/helpers";

export const KNOW_BETTER_YES = "¡Si!";
export const KNOW_BETTER_NO = "Aún no";

export const initialValues = {
  medicName: "",
  medicLastName: "",
  medicRelation: "",
  medicEmail: "",
  medicPhone: "",
  appointmentDate: "",
  appointmentTime: "",
  procType: "",
  procToDo: [],
  otherProc: "",
  procedureCost: "",
  requestedAmount: "",
  hours: "--",
  minutes: "--",
  time: "--",
  clinicName: "",
  clinicBranch: "",
  specialtyType: "",
  otherClinicName: "",
  clinicDr: "",
};

export const validateForm = ({
  values,
  hasNotDoctor = false,
  errors,
  hasNotDate,
  maxCreditAmt,
  procedureToPerformList,
  minCreditAmount,
}) => {
  //If the user has not a doctor yet, we can skip this information
  const hasOtherProcess = isOtherShow({
    values,
  });
  const procedureCost = getNumber(values?.procedureCost) || 0;
  if (isKnowsBetter(values)) {
    if (!hasNotDoctor) {
      if (values?.medicName) {
        delete errors.medicName;
      } else {
        errors.medicName = "Este campo es requerido";
      }
      if (values?.medicLastName) {
        delete errors.medicLastName;
      } else {
        errors.medicLastName = "Este campo es requerido";
      }

      if (values?.medicRelation) {
        delete errors.medicRelation;
      } else {
        errors.medicRelation = "Este campo es requerido";
      }

      if (values?.medicEmail) {
        if (emailRegex.test(values?.medicEmail)) {
          delete errors.medicEmail;
        } else {
          errors.medicEmail = "ingresa un correo valido";
        }
      } else {
        errors.medicEmail = "Este campo es requerido";
      }
    }
    if (!hasNotDate) {
      //Validate date
      if (!values?.appointmentDate) {
        errors.appointmentDate = `Selecciona una fecha, de lo contrario, selecciona la opcion 'no tengo cita programada aún'`;
      } else {
        //Verify if the date is a Valid format
        if (!moment(values?.appointmentDate || "").isValid()) {
          errors.appointmentDate = "El formato de la fecha no es correcto";
        } else {
          delete errors.appointmentDate;
        }
      }
      //Validate appointment time

      values?.hours === "--" ||
      values?.minutes === "--" ||
      values?.time === "--"
        ? (errors.appointmentTime = `Selecciona una hora, de lo contrario, selecciona la opcion 'no tengo cita programada aún'`)
        : delete errors.appointmentTime; /* minutesValidation(values.appointmentTime.target.value); */
    } else {
      delete errors.appointmentDate;
      delete errors.appointmentTime;
    }
    //Validate specialtyType  types
    !values?.specialtyType
      ? (errors.specialtyType = "Selecciona el especialidad médica")
      : delete errors.specialtyType;

    //Validate procedure  types
    !values?.procType
      ? (errors.procType = "Selecciona el tipo de procedimiento")
      : delete errors.procType;

    values?.procToDo?.length === 0
      ? (errors.procToDo = "Selecciona al menos un proceso")
      : delete errors.procToDo;

    // Remove error validation is specialty type selected others
    if (!isProcedureShow({ values })) {
      delete errors.procToDo;
      delete errors.procType;
    }

    if (
      OTHER_OPTION.toUpperCase() === values.procType.toUpperCase() ||
      procedureToPerformList?.length < 1
    ) {
      delete errors.procToDo;
    }

    if (!procedureCost) {
      errors.procedureCost = "Ingresa el costo del procedimiento";
    } else {
      delete errors.procedureCost;
    }

    if (hasOtherProcess) {
      !values.otherProc
        ? (errors.otherProc = "Ingresa el/los otros procedimiento(s)")
        : delete errors.otherProc;
    }
    if (!hasOtherProcess) {
      delete errors.otherProc;
    }

    if (isBranchSelectionAllowed({ values })) {
      if (values?.clinicBranch) {
        delete errors.clinicBranch;
      } else {
        errors.clinicBranch = "Selecciona el Sucursal clínica";
      }
    } else {
      delete errors.clinicBranch;
    }
  } else {
    const index = [
      "appointmentDate",
      "medicName",
      "medicLastName",
      "medicRelation",
      "medicEmail",
      "medicPhone",
      "appointmentTime",
      "procType",
      "otherProc",
      "procToDo",
      "specialtyType",
    ];
    for (let i in index) {
      delete errors?.[index[i]];
    }
  }

  handleRequestAmount({
    maxCreditAmt,
    values,
    errors,
    minCreditAmount,
  });
  return errors;
};

export const getPayload = ({
  hasNotDoctor,
  values,
  hasNotDate,
  procedureToPerformList,
}) => {
  const hasOtherProcess = isOtherShow({
    values,
  });
  const procedureCost = getNumber(values.procedureCost) || 0;
  const requestedAmount = getNumber(values.requestedAmount) || 0;

  const payload = {
    cost: procedureCost,
    requested_loan_amount: requestedAmount,
  };

  if (isKnowsBetter(values)) {
    const clinic_info = {
      clinic_name: values.clinicName,
      clinic_location: values.clinicBranch,
      other_clinic_name: "",
    };

    if (isOtherShowClinic({ values })) {
      clinic_info.other_clinic_name = values.otherClinicName;
    }

    payload.clinic_info = clinic_info;

    if (values.specialtyType) {
      payload.speciality_type = values.specialtyType;
    }

    if (hasOtherProcess) {
      payload.other_procedure = hasOtherProcess ? values.otherProc : "";
    }
    if (!hasNotDate) {
      let appointmentDate = values.appointmentDate
        ? moment(values.appointmentDate).format("yyyy-MM-DD")
        : "";
      payload.appointment_date = appointmentDate;
      const time = convertTime12to24(
        values.hours + ":" + values.minutes + " " + values.time
      );
      payload.appointment_time = time;
    }

    if (!hasNotDoctor) {
      payload.doc_name = values.medicName;
      payload.doc_last_name = values.medicLastName;
      payload.doc_email = values.medicEmail;
      payload.doc_phone = values.medicPhone;
      payload.relation_with_doc = values.medicRelation;
    }

    if (isProcedureShow({ values })) {
      payload.procedure_type = values.procType;
    }

    if (!isOtherShow({ values })) {
      payload.procedure_to_perform = values.procToDo;
    }

    if (procedureToPerformList?.length < 1 && !isOtherShow({ values })) {
      payload.procedure_to_perform = [values.procType];
    }
  }

  return payload;
};

export const handleCreditLimit = ({
  amount,
  formik,
  maxCreditAmt,
  setMessage,
  errors,
  minCreditAmount,
}) => {
  if (!formik?.values?.clinicName) {
    maxCreditAmt = 80000;
  }
  if (parseInt(amount) > 80000) {
    setMessage(true);
  }
  if (parseInt(amount) > maxCreditAmt) {
    formik.setFieldValue("requestedAmount", maxCreditAmt);
  } else {
    formik.setFieldValue("requestedAmount", amount);
    setMessage(false);
    // if (amount < minCreditAmount) {
    //   errors.procedureCost = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    // } else delete errors.procedureCost;
    if (amount < 80000) {
      errors.procedureCost = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    } else delete errors.procedureCost;
  }
};

export const handleRequestAmount = ({
  errors,
  values,
  maxCreditAmt,
  minCreditAmount,
}) => {
  const procedureCost = getNumber(values?.procedureCost) || 0;
  const requestedAmount = getNumber(values?.requestedAmount) || 0;

  if (!procedureCost) {
    errors.procedureCost = "Ingresa el monto a solicitar";
  } else if (procedureCost && procedureCost) {
    if (procedureCost < minCreditAmount) {
      errors.procedureCost = `El costo del trámite debe ser mayor a $${minCreditAmount}`;
    } else {
      delete errors.procedureCost;
    }
  }

  if (!requestedAmount) {
    errors.requestedAmount = "Ingresa el monto a solicitar";
  } else if (requestedAmount && procedureCost) {
    if (requestedAmount > procedureCost) {
      errors.requestedAmount = `El monto del crédito solicitado no debe ser mayor que el costo del procedimiento`;
    } else if (requestedAmount < minCreditAmount) {
      errors.requestedAmount = `El monto del crédito solicitado debe ser mayor que $${minCreditAmount}`;
    } else {
      delete errors.requestedAmount;
    }
  }
};

export const isKnowsBetter = (values) => {
  return values?.isKnowDoctor === KNOW_BETTER_YES;
};
