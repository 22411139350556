import { FC, MouseEvent } from "react";

import failedBg from "../../assets/Icons/fail-email.svg";
import ConfirmationView from "../../components/ConfirmationView";

export interface AlreadyVerifiedProps {
  handleClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const AlreadyVerified: FC<AlreadyVerifiedProps> = ({ handleClick }) => {
  return (
    <ConfirmationView
      title="Verificación Fallida"
      content={
        <span>
          Lo sentimos, no hemos podido confirmar su identidad.
          <br />
          <br />
          Este enlace ha caducado. Favor de volver a la página de inicio de
          Medsi
          <br />
          <br />
          para verificar nuevamente tu correo electrónico.
        </span>
      }
      imgSrc={failedBg}
      btn={{
        label: "Cerrar",
        onClick: handleClick,
      }}
    />
  );
};

export default AlreadyVerified;
