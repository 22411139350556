import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { customTheme } from "../../theme";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
  },
  titleWrapper: {
    zIndex: 1,
    padding: "12px 16px",
    background: customTheme.pearl,
    border: `1px solid ${customTheme.stone}`,
    fontWeight: 600,
    letterSpacing: 0.2,
    color: customTheme.dolphin,
  },
  compWrapper: {
    borderBottom: `solid 1px ${customTheme.stone}`,
    padding: ({ noPadding }: { noPadding?: boolean }) =>
      noPadding ? "0 16px" : "16px",
    alignItems: "center",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  flatListWrapper: {
    borderTop: `solid 1px ${customTheme.stone}`,
  },
  light: {
    color: customTheme.shark,
  },
  right: {
    textAlign: "right",
    wordBreak: "break-word",
  },
}));

export default useStyles;
