import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { API_STATUS } from "../../../../constants/common";
import payBookApi from "../../../../apis/payBook";
import payBookEndPoint from "../../../../apis/payBook/config";

const initialState = {
  loading: false,
  widgetToken: "",
  payBookUserId: "",
  isSaved: false,
};

export const getToken = createAsyncThunk(
  payBookEndPoint.getToken,
  async (metta_user_id: string) => {
    const payload = {
      metta_user_id,
    };

    const { data } = await payBookApi.getToken(payload);
    return data;
  }
);

export const saveData = createAsyncThunk(
  payBookEndPoint.saveTxnPayBook,
  async (uid: string) => {
    const { data } = await payBookApi.saveTxnPayBook({
      metta_user_id: uid,
    });
    return data;
  }
);

export const saveMatiResponse = createAsyncThunk(
  payBookEndPoint.saveMatiResponse,
  async (arg: ISaveMatiResponseArgs) => {
    const { verification_id, metta_user_id, co_signer_flag } = arg;
    const { data } = await payBookApi.saveMatiResponse({
      verification_id,
      metta_user_id,
      co_signer_flag,
    });
    return data;
  }
);

const slice = createSlice({
  name: "auth",
  initialState: {
    ...initialState,
  } as PayBookState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getToken.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.payBookUserId = data.paybook_user_id;
        state.widgetToken = data.widget_token;
      }
    });
    builder.addCase(saveData.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        state.widgetToken = data.widget_token;
      }
    });
    builder.addCase(saveMatiResponse.fulfilled, (state, { payload }) => {
      const { data } = payload as any;
      if (API_STATUS?.SUCCESS === payload?.status) {
        console.log(payload, 'payload from api')
        // state.widgetToken = data.widget_token;
      }
    });
  },
});

export type ISaveMatiResponseArgs = {
  verification_id: string;
  metta_user_id: string;
  co_signer_flag: string;
};

export type PayBookState = {
  loading: boolean;
  payBookUserId: string;
  widgetToken: string;
  isSaved: boolean;
};

export default slice.reducer;

export const getPayBookState = (state: any) => state.payBook;
