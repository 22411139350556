import { createStyles, makeStyles } from "@mui/styles";
import { URBANIST_FONT } from "../../../constants/common";
import { themePalette } from "../../../theme/themePalette";

const CosginerSignatureStyles = makeStyles((theme) =>
  createStyles({
    content: {
      minHeight: "100vh",
      backgroundColor: themePalette.maastrichtBlue,
    },
    logo: {
      height: 65,
      marginTop: -50,
      paddingBottom: 30,
      [theme.breakpoints.down(601)]: {
        height: 30,
        paddingBottom: 25,
      },
    },
    textTitle: {
      color: themePalette.white,
      maxWidth: 888,
      fontSize: "1rem",
      textDecoration: "underline",
    },
    textDescription: {
      color: themePalette.white,
      maxWidth: 888,
      fontSize: "0.75rem",
    },
    textReference: {
      color: themePalette.white,
      [theme.breakpoints.down(601)]: {
        fontSize: "16px !important",
      },
    },
    textBox: {
      background: "rgba(255, 255, 255, 0.2) !important",
      border: "1px solid rgba(255, 255, 255, 0.2) !important",
      color: themePalette.primary.white + "!important",
      padding: "0 !important",
      textAlign: "center",
      width: "50px !important",
      height: "60px !important",
      borderRadius: "4px !important",
      fontSize: "25px !important",
      fontWeight: "bold !important",
      textTransform: "uppercase",
      [theme.breakpoints.down(601)]: {
        width: "35px !important",
        height: "45px !important",
        fontSize: "20px !important",
      },
      [theme.breakpoints.down(421)]: {
        width: "25px !important",
        height: "30px !important",
        fontSize: "15px !important",
      },
    },
    circle: {
      color: themePalette.white,
      marginRight: "20px !important",
      height: "15px !important",
      [theme.breakpoints.down(421)]: {
        marginRight: "6px !important",
        height: "11px !important",
      },
    },
    textFieldStyles: {
      marginRight: "10px !important",

      [theme.breakpoints.down(421)]: {
        marginRight: "5px !important",
      },
    },
    gridStyle: {
      maxWidth: 514,
      marginTop: 10,
      marginBottom: 10,
      [theme.breakpoints.down(601)]: {
        maxWidth: 394,
      },
      [theme.breakpoints.down(421)]: {
        maxWidth: 265,
      },
    },
    submitButton: {
      "&.MuiButton-root": {
        fontSize: "1rem",
        fontFamily: URBANIST_FONT.BOLD,
        textTransform: "none",
        marginTop: "40px",
        background: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
        "&:hover": {
          background: "#1976d2",
        },
        "&.Mui-disabled": {
          background: themePalette.gray + "",
        },
        borderRadius: "100px",
        [theme.breakpoints.down(601)]: {
          height: 45,
          width: 130,
          fontSize: "17px",
        },
      },
    },
    error: {
      color: "#db3333 !important",
      fontWeight: "bold !important",
      fontSize: "18px !important",
      textAlign: "center !important",
    },
  })
);

export default CosginerSignatureStyles;
