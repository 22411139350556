import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";

const styles = makeStyles((theme: Theme) => ({
  divContainer: {
    backgroundColor: theme.palette.primary.light,
  },
  line: {
    height: 1,
    background: "#B6B6B6",
    width: "100%",
  },
  textWrapper: {
    marginTop: 60,
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text12: {
    [theme.breakpoints.down(601)]: {
      fontSize: "12px !important",
    },
  },
  mt24: {
    marginTop: "18px !important",
  },
  textCenter: {
    textAlign: "center !important" as "center",
  },
  textJustify: {
    textAlign: "justify !important" as "justify",
  },
  olStyles: {
    listStyleType: "lower-alpha",
    marginLeft: "0in",
  },
  table: {
    maxWidth: "500pt",
    borderCollapse: "collapse",
    border: "none",
    marginTop: "16px",
  },
  tdHead: {
    border: "1pt solid windowtext",
    padding: "0.75pt",
    verticalAlign: "top",
  },
  tdBody: {
    borderRight: "1pt solid windowtext",
    borderBottom: "1pt solid windowtext",
    borderLeft: "1pt solid windowtext",
    borderImage: "initial",
    borderTop: "none",
    padding: "0.75pt",
    verticalAlign: "top",
  },
  iconBoxContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  iconBoxIconContainer: {
    width: "36px",
    minWidth: '36px',
    height: "36px",
    // backgroundColor: "#B6B6B6",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // color: "#fff",
    color: 'black',
    marginRight: 10
  },
}));

export default styles;
