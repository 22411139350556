import RegisterForm from "../../auth/Register/RegisterForm";
import { Grid, Typography, useMediaQuery } from "@mui/material";
import doctorVector from "../../../assets/backgroundImages/doctorVector.png";
import { Box } from "@mui/system";
import requestCreditLineStyles from "./requestCreditLine.styles";
import { useTheme } from "@emotion/react";

export const RequestCreditLineOnLandingPage = () => {
  const classes = requestCreditLineStyles();
  const theme = useTheme();
  const isVisible = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        sx={{
          position: "relative",
          display: "inline-block",
        }}
      >
        <img
          src={doctorVector}
          alt="docVector"
          className={classes.doctorImage}
        />
        <div style={{ position: "absolute" }}>
          <Typography
            sx={{
              fontFamily: "Urbanist-Bold",
              fontSize: 18,
              marginTop: -25,
              marginLeft: {xs:20,md:32},
              display: { lg: "none" },
            }}
          >
            Experimenta nuestra plataforma y se parte de Medsi
          </Typography>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={6}
        sx={{
          marginTop: { md: 5 },
          paddingRight: { xs: 2 },
          paddingLeft: { xs: 2 },
          ml:{lg:-20}
        }}
      >
        {isVisible && (
          <Typography
            sx={{
              fontFamily: "Urbanist-Bold",
              fontSize: 25,
              marginLeft:{md:-7, lg:5}
            }}
          >
            Experimenta nuestra plataforma y se parte de Medsi
          </Typography>
        )}

        <RegisterForm />
      </Grid>
    </Grid>
  );
};

export default RequestCreditLineOnLandingPage;
