import { makeStyles } from "@mui/styles";

const SumaryCoSignerStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='1920' height='1080' viewBox='0 0 1920 1080' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1920 0H0V1079.82H1920V0Z' fill='url(%23paint0_linear_602_298)'/%3E%3Cpath opacity='0.24' d='M1920 403.593C1920 403.593 1233.97 185.115 993.719 0H1920V403.593Z' fill='%233452CC'/%3E%3Cpath d='M0 739.014V1080H1917.96V938.105C1917.96 938.105 892.562 1064.35 0 739.014Z' fill='url(%23paint1_linear_602_298)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_602_298' x1='-218.724' y1='84.5051' x2='393.66' y2='1669.53' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23192289'/%3E%3Cstop offset='1' stop-color='%2319142C'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_602_298' x1='0' y1='405697' x2='1.85462e+06' y2='405697' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23192289'/%3E%3Cstop offset='1' stop-color='%2319142C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Blue_back' data-name='Blue back' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 968 1409.9121'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23linear-gradient);%7D.cls-2%7Bfill:%233452cc;opacity:0.24;%7D.cls-3%7Bfill:url(%23linear-gradient-2);%7D%3C/style%3E%3ClinearGradient id='linear-gradient' x1='-110.3623' y1='110.3623' x2='1079.1445' y2='1299.8691' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23192289'/%3E%3Cstop offset='1' stop-color='%2319142c'/%3E%3C/linearGradient%3E%3ClinearGradient id='linear-gradient-2' x1='0' y1='1187.6064' x2='966.9736' y2='1187.6064' xlink:href='%23linear-gradient'/%3E%3C/defs%3E%3Ctitle%3EBlue back CS%3C/title%3E%3Crect class='cls-1' width='968.7822' height='1410.2314'/%3E%3Cpath class='cls-2' d='M968,527.167S621.8447,241.7939,500.6182,0H968Z'/%3E%3Cpath class='cls-3' d='M0,964.9814v445.25H966.9736V1224.9482S450,1389.8,0,964.9814Z'/%3E%3C/svg%3E")`,
    },
  },
  logo: {
    height: 100,
    marginTop: 35,
    paddingBottom: 30,
    [theme.breakpoints.down(601)]: {
      height: 60,
      paddingBottom: 25,
    },
  },
  textCoSigner: {
    paddingBottom: "16px !important",
    color: theme.palette.primary.white,
    marginBottom: "10px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "20px !important",
    },
  },
  textWelcome: {
    fontSize: "40px !important",
    paddingBottom: "40px !important",
    color: theme.palette.primary.white,
    [theme.breakpoints.down(601)]: {
      fontSize: "20px !important",
    },
  },
  textUser: {
    textAlign: "center",
    lineHeight: "1.3 !important",
    color: theme.palette.primary.white,
    marginBottom: "35px !important",
    marginLeft: "50px !important",
    marginRight: "50px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  lightBlueColor: {
    color: theme.palette.primary.lightBlue,
  },
  textGridLeft: {
    textAlign: "left",
    lineHeight: "1.3 !important",
    color: theme.palette.primary.white,
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  textGridRight: {
    textAlign: "left",
    lineHeight: "1.3 !important",
    color: theme.palette.primary.lightBlue,
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  gridStyle: {
    maxWidth: 600,
    marginBottom: 35,
    [theme.breakpoints.down(701)]: {
      maxWidth: 550,
    },
    [theme.breakpoints.down(601)]: {
      maxWidth: 450,
    },
    [theme.breakpoints.down(501)]: {
      maxWidth: 400,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  textInformation: {
    textAlign: "center",
    lineHeight: "1.2 !important",
    color: theme.palette.primary.white,
    marginBottom: "20px !important",
    marginLeft: "20px !important",
    marginRight: "20px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  textPressButton: {
    textAlign: "center",
    lineHeight: "1.2 !important",
    color: theme.palette.primary.lightBlue,
    marginBottom: "40px !important",
    marginLeft: "50px !important",
    marginRight: "50px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  button: {
    height: 55,
    width: 220,
    fontWeight: "bold !important",
    fontSize: "20px !important",
    textTransform: "none !important",
    borderRadius: "10px !important",
    background: theme.palette.primary.buttonUnselected,
    "&:hover": {
      background: "#1976d2 !important",
    },
    [theme.breakpoints.down(601)]: {
      height: 45,
      width: 150,
      fontSize: "17px !important",
    },
  },
  gridFooterStyle: {
    marginTop: 50,
    marginBottom: 70,
  },
  gridText: {
    color: theme.palette.primary.gray,
    textAlign: "center",
    fontWeight: "bold !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "12px !important",
    },
    "& > a": {
      textDecoration: "none",
      color: theme.palette.primary.gray,
    },
  },
  textRightsReserved: {
    color: theme.palette.primary.white,
    marginBottom: "40px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "12px !important",
    },
  },
}));

export default SumaryCoSignerStyles;
