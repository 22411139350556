import { createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { themeBreakpoints } from "./themeBreakpoints";
import { themeOverrides } from "./themeOverrides";
import { themePalette } from "./themePalette";
import { themeTypography } from "./themeTypography";

let theme = createTheme();

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

const Theme = createTheme({
  breakpoints: themeBreakpoints,
  // overrides: themeOverrides,
  palette: themePalette,
  typography: themeTypography,
  // paddings: {
  //   xs: 10,
  //   sm: 15,
  //   md: 20,
  //   lg: 25,
  //   xl: 30,
  //   xxl: 40,
  //   xxxl: 65,
  // },
});

export default Theme;
