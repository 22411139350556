import { makeStyles } from "@mui/styles";
import { customColor } from "../../theme/customColor";

const styles = makeStyles((theme) => ({
  divContainer: {
    backgroundColor: theme.palette.primary.white,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    zIndex: 100,
    position: 'relative'
  },
  textWrapper: {
    padding: 20,
    overflowY: "auto",
    flex: 1,
    height: '100%',
    paddingBottom: 80,
  },
  textSize: {
    [theme.breakpoints.down(1001)]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "11px !important",
    },
  },
  textTitle: {
    fontWeight: "bold !important",
    [theme.breakpoints.down(1001)]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.down(601)]: {
      fontSize: "11px !important",
    },
  },
  alignJustify: {
    textAlign: "justify",
  },
  mt16: {
    marginTop: "16px !important",
  },
  ml_40: {
    marginLeft: "-20px !important",
  },
  textTerm: {
    marginTop: "24px !important",
    textAlign: "justify",
  },
  textSize11: {
    [theme.breakpoints.down(601)]: {
      fontSize: "11px !important",
    },
  },
  scrollToBottom: {
    position: "absolute",
    height: 100,
    width: 700,
    background: "red",
  },
  buttonWrapper: {
    position: "absolute",
    fontWeight: 600,
    letterSpacing: 0.4,
    color: customColor.accentBlue,
    padding: "12px 24px",
    width: "100%",
    textAlign: "center",
    background:
      "linear-gradient(to top, rgba(255,255,255,1) 70%,rgba(255,255,255,0) 100%)",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
    },
    height: 60,
    bottom: 0,
  },
  hide: {
    visibility: 'hidden',
  },
  acceptar: { cursor: 'pointer' },
}));

export default styles;
