import { makeStyles } from "@mui/styles";

const InputPhoneStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.white,
    [theme.breakpoints.only("xs")]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.between("sm", "lg")]: {
      fontSize: "23px !important",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "25px !important",
    },
  },
  inputSize: {
    width: 250,
    [theme.breakpoints.up(601)]: {
      width: 250,
    },
    [theme.breakpoints.up(900)]: {
      width: 400,
    },
    marginTop: "5px !important",
    marginBottom: "20px !important",
  },
  formHelperText: {
    color: "#FF0000 !important",
  },
}));

export default InputPhoneStyles;
