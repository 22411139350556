import Header from "../../../components/header";
import TwoColumns from "../../../components/twoColumns";
import SideBar from "../../../components/sidebar";
import useStyles from "../ApplicationStatus/styles";
import calendar from "../../../assets/calendar.png";
import calendarSmall from "../../../assets/calendar_small.png";

import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import {
  selectAllApplication,
  selectLmsBalanceSummary,
  selectLmsUserAccessToken,
  selectLoanDetailStepper,
  selectStepperStatus,
  selectTakeLoanDetail,
  selectTransactionList,
} from "../../../store/slices/users";
import { useAppDispatch } from "../../../store";
import {
  getAllApplication,
  getApplicationStepper,
  getLmsBalanceSummary,
  getLoanDetails,
  getTransactionList,
  getUserAccessToken,
} from "../../../store/slices/users/actions";

import moment from "moment";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";

import PanelLoan from "../../../components/PanelLoan";
import Payments from "./PaymentInfo";
import PanelPaymentSchedule from "../../../components/PanelPaymentSchedule";
import { lmsApi } from "../../../apis/api";
import { AddBoxSharp } from "@mui/icons-material";
import { currencyFormat } from "../../../utils/index";

const PaymentAll = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [application_id, setAppId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const css = useStyles();
  const [loanType, setLoanType] = React.useState("active");
  const [showSidebar, setShowSideBar] = React.useState(false);
  const urlId = window.location.search.slice(1, window.location.search.length);
  const lmsUserAccessToken = useSelector(selectLmsUserAccessToken);

  useEffect(() => {
    try {
      dispatch(getUserAccessToken());
    } catch (err) {}
  }, []);
  useEffect(() => {
    if (lmsUserAccessToken) {
      lmsApi.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${lmsUserAccessToken}`;
      setTimeout(() => {
        dispatch(getLmsBalanceSummary());
      }, 200);
    }
  }, [lmsUserAccessToken]);

  const handleOpen = (id) => {
    setAppId(id);
    setOpen(!open);
  };

  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);

  const handleActive = () => {
    setLoanType("active");
  };

  const handleClose = () => {
    setLoanType("close");
  };

  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header isBorderBottom={false} setShowSideBar={setShowSideBar} />
          </Box>
          <Box
            sx={(theme) => ({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "center",
              marginLeft: "44px",
              marginRight: "44px",
              "& h2": {
                fontFamily: "SFUIText-bold",
                fontSize: "24px",
                lineHeight: "29px",
              },
              [theme.breakpoints.down("lg")]: {
                marginLeft: "95px",
                marginTop: "1.5rem",
                "& h2": {
                  fontFamily: "Urbanist-Bold",
                  fontSize: "18px",
                  lineHeight: "22px",
                },
              },
              [theme.breakpoints.down("tablet")]: { marginLeft: "35px" },
            })}
          >
            <h2
              style={{
                textTransform: "uppercase",
                color: " #6B7779",
                margin: 0,
              }}
            >
              Próximo pago
            </h2>
            <div
              style={{
                background: "#FFFFFF",
                border: "1px solid #F5F5F5",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: 10,
              }}
            >
              {/* <select style={{ appearance: "none", padding: 10 }}>
                <option>Select Loan ID</option>
                <option>3948723</option>
                <option>3948723</option>
              </select> */}
            </div>
          </Box>
          ​
          <Box
            sx={(theme) => ({
              background: "#FFFFFF",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "10px 20px",
              marginLeft: "44px",
              marginRight: "44px",
              display: "flex",
              alignItems: "center",
              [theme.breakpoints.down("lg")]: {
                width: "93%",
                margin: "0 auto",
                borderRadius: "20px",
                boxShadow: "0px 5px 6px rgba(0, 0, 0, 0.25)",
              },
              [theme.breakpoints.down("tablet")]: { width: "87%" },
            })}
          >
            <Box
              sx={(theme) => ({
                width: "60px",
                height: "60px",
                background: "#FFFFFF",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                "& img": { height: "33px" },
                "& #calendar_small": { display: "none" },
                [theme.breakpoints.down("lg")]: {
                  boxShadow: "unset",
                  paddingTop: "10px",
                  alignItems: "start",
                  "& img": { display: "none", height: "30px", width: "30px" },
                  "& #calendar_small": { display: "block" },
                },
                [theme.breakpoints.down("tablet")]: { display: "none" },
              })}
            >
              <img src={calendar} alt="" />
              <img src={calendarSmall} alt="" id="calendar_small" />
            </Box>
            <Box
              sx={(theme) => ({
                flex: 1,
                paddingLeft: "20px",
                marginTop: "20px",
                [theme.breakpoints.down("lg")]: {
                  paddingLeft: "1rem",
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  display: "flex",
                  [theme.breakpoints.down("md")]: {
                    display: "grid",
                    gridTemplateColumns: "repeat(3, auto)",
                    gap: "1rem",
                  },
                  [theme.breakpoints.down("tablet")]: {
                    display: "grid",
                    gridTemplateColumns: "repeat(2, auto)",
                    gap: "1rem",
                  },
                })}
              >
                <Box
                  sx={(theme) => ({
                    paddingRight: "80px",
                    [theme.breakpoints.down("md")]: { paddingRight: 0 },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      color: "#9E9E9E",
                      fontSize: "18px",
                      fontFamily: "SFUIText-semibold",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    })}
                  >
                    Monto total a pagar
                  </Box>

                  <Box
                    sx={(theme) => ({
                      color: "#000000",
                      fontSize: "16px",
                      fontFamily: "SFUIText-semibold",
                      marginTop: "12px",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Bold",
                      },
                    })}
                  >
                    $
                    {LmsBalanceSummary?.repayment_amt
                      ? currencyFormat(LmsBalanceSummary?.repayment_amt)
                      : "--"}{" "}
                  </Box>
                </Box>

                <Box
                  sx={(theme) => ({
                    paddingRight: "80px",
                    [theme.breakpoints.down("md")]: { paddingRight: 0 },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      color: "#9E9E9E",
                      fontSize: "18px",
                      fontFamily: "SFUIText-semibold",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    })}
                  >
                    Próxima fecha de pago
                  </Box>
                  <Box
                    sx={(theme) => ({
                      color: "#000000",
                      fontSize: "16px",
                      fontFamily: "SFUIText-semibold",
                      marginTop: "12px",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Bold",
                      },
                    })}
                  >
                    {LmsBalanceSummary?.next_due_date
                      ? LmsBalanceSummary?.next_due_date
                      : "--"}
                  </Box>
                </Box>

                <Box
                  sx={(theme) => ({
                    paddingRight: "80px",
                    [theme.breakpoints.down("md")]: { paddingRight: 0 },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      color: "#9E9E9E",
                      fontSize: 18,
                      fontFamily: "SFUIText-semibold",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Semibold",
                        fontSize: "14px",
                      },
                    })}
                  >
                    Cargo por pago tardío
                  </Box>
                  <Box
                    sx={(theme) => ({
                      color: "#000000",
                      fontSize: "16px",
                      fontFamily: "SFUIText-semibold",
                      marginTop: "12px",
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Bold",
                      },
                    })}
                  >
                    {LmsBalanceSummary?.fee_detail?.free_sum
                      ? LmsBalanceSummary?.fee_detail?.fee_sum
                      : "--"}
                  </Box>
                </Box>
              </Box>
              ​
            </Box>
            ​
            {/* <button
              style={{
                background: "#3552CC",
                borderRadius: 8,
                color: "white",
                padding: "6px 24px",
                display: "inline-block",
              }}
            >
              Pay Now
            </button> */}
          </Box>
          <Box
            sx={(theme) => ({
              color: "#6B7779",
              fontSize: 24,
              fontFamily: "SFUIText-bold",
              marginTop: "19px",
              marginLeft: "44px",
              marginBottom: "33px",
              [theme.breakpoints.down("lg")]: {
                margin: "2.5rem 0 1.5rem 95px",
                fontFamily: "Urbanist-Bold",
                fontSize: "18px",
                lineHeight: "22px",
              },
              [theme.breakpoints.down("tablet")]: { marginLeft: "35px" },
            })}
          >
            Detalles del Préstamo
          </Box>
          <Box
            sx={(theme) => ({
              marginLeft: "44px",
              marginTop: "10px",
              marginRight: "44px",
              [theme.breakpoints.down("lg")]: {
                width: "93%",
                margin: "1rem auto 0",
              },
              [theme.breakpoints.down("tablet")]: { width: "87%" },
            })}
          >
            {LmsBalanceSummary?.ob_list?.map((each) => (
              <PanelPaymentSchedule
                title="PRÉSTAMO"
                body={<Payments each={each} />}
                each={each}
              />
            ))}
          </Box>
        </>
      }
      rightBg
    />
  );
};

export default PaymentAll;
