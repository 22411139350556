import { makeStyles } from "@mui/styles";
import BackgroundDesktop from "../../assets/QrCode/backgroundDesktop.svg";
import BackgroundTablet from "../../assets/QrCode/backgroundTablet.svg";
import BackgroundMobile from "../../assets/QrCode/backgroundMobile.svg";

const QrCodeStyles = makeStyles((theme) => ({
  content: {
    minHeight: "100vh",
    backgroundImage: `url(${BackgroundDesktop})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url(${BackgroundTablet})`,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url(${BackgroundMobile})`,
    },
  },
  textCode: {
    color: theme.palette.primary.white,
    marginTop: "20px !important",
    marginBottom: "60px !important",
    [theme.breakpoints.down(1001)]: {
      marginBottom: "40px !important",
      fontSize: "35px !important",
    },
  },
  imgQr: {
    height: 280,
    width: 280,
    paddingBottom: 35,
    [theme.breakpoints.down(601)]: {
      height: 200,
      width: 200,
    },
  },
  textEmail: {
    textAlign: "center",
    paddingBottom: "16px !important",
    color: theme.palette.primary.white,
    marginBottom: "10px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "16px !important",
    },
  },
  button: {
    height: 45,
    width: 180,
    textTransform: "none !important",
    marginTop: "10px !important",
    background: theme.palette.primary.buttonUnselected,
    "&:hover": {
      background: "#1976d2 !important",
    },
    "&.Mui-disabled": {
      background: theme.palette.primary.gray  + " !important",
    },
    borderRadius: "10px !important",
  },
  textGenerateQr: {
    textAlign: "center",
    marginTop: "30px !important",
    marginBottom: "8px !important",
    color: theme.palette.primary.white,
    [theme.breakpoints.down(601)]: {
      fontSize: "18px !important",
    },
  },
  gridStyle: {
    marginTop: 48,
    marginBottom: 20,
    "@media (min-width: 601px) and (min-height: 820px)": {
      position: "fixed",
      bottom: 0,
    },
  },
  gridText: {
    color: theme.palette.primary.white,
    textAlign: "center",
    lineHeight: "1.75 !important",
    "& > a": {
      textDecoration: "none",
      color: theme.palette.primary.white,
    },
  }
}));

export default QrCodeStyles;
