import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  backgroundContainer: {
    minHeight: "100vh",
    // backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 1920 1080'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23linear-gradient);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;isolation:isolate;%7D%3C/style%3E%3ClinearGradient id='linear-gradient' y1='540.4419' x2='1920.7266' y2='540.4419' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatos Procedimiento Back Desktop%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' width='1920.7266' height='1080.8838'/%3E%3C/g%3E%3Cg id='Onda'%3E%3Cpath class='cls-2' d='M-.137,944.9973s357.4594-12.7285,735.0723,76.3712,1183.7529-8.4857,1183.7529-57.2784v117.4048H0Z'/%3E%3C/g%3E%3C/svg%3E")`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    background: "#fff",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Capa_3' data-name='Capa 3' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 768 1024'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23Degradado_sin_nombre_3);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;isolation:isolate;%7D%3C/style%3E%3ClinearGradient id='Degradado_sin_nombre_3' y1='511.9922' x2='767.9775' y2='511.9922' gradientTransform='matrix(1, 0, 0, -1, 0, 1024)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatos del procedimiento back tablet%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' y='-0.1758' width='767.9775' height='1024.3672'/%3E%3C/g%3E%3Cpath class='cls-2' d='M5.1562,902.3828s65.9805-12.8935,140.0958-12.8935c74.1171,0,155.46,12.8935,229.5751,46.3261,74.1172,33.4317,166.3067,53.97,247.6543,53.97S768,975.4551,768,975.4551V1024H5.1562Z'/%3E%3C/svg%3E")`,
    },
    [theme.breakpoints.down(601)]: {
      backgroundImage: `url("data:image/svg+xml,%3Csvg id='Capa_1' data-name='Capa 1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 414 736'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:url(%23Degradado_sin_nombre);%7D.cls-2%7Bfill:%234d8dc6;opacity:0.3;isolation:isolate;%7D%3C/style%3E%3ClinearGradient id='Degradado_sin_nombre' x1='-0.8374' y1='368.1108' x2='413.3828' y2='368.1108' gradientTransform='matrix(1, 0, 0, -1, 0, 736)' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23c1dcf9'/%3E%3Cstop offset='0.2043' stop-color='%23d0e5fa'/%3E%3Cstop offset='0.7198' stop-color='%23f2f8fe'/%3E%3Cstop offset='1' stop-color='%23fff'/%3E%3C/linearGradient%3E%3C/defs%3E%3Ctitle%3EDatos del procedimiento back elementos mobile-01%3C/title%3E%3Cg id='Back'%3E%3Crect class='cls-1' x='-0.8374' y='0.4834' width='414.2202' height='734.8115'/%3E%3C/g%3E%3Cg id='Onda'%3E%3Cpath class='cls-2' d='M0,669.5439a449.585,449.585,0,0,1,76.5532-7.0449c40.5005,0,84.9488,7.0449,125.4473,25.3145,40.5005,18.2676,90.8755,29.49,135.3266,29.49a405.4534,405.4534,0,0,0,79.5167-7.83V736H0Z'/%3E%3C/g%3E%3C/svg%3E")`,
    },
    position: "relative",
    [theme.breakpoints.down(601)]: {
      backgroundImage: "none",
    },
  },
  mainAreaContainer: {
    width: "100%",
    // backgroundImage: `url("data:image/svg+xml,%3Csvg width='1440' height='374' viewBox='0 0 1440 374' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M176.001 293.017C176.001 293.017 395.466 335.486 601.221 248.975C601.221 248.975 466.009 198.641 176.001 293.017Z' fill='%23ABCED4'/%3E%3Cpath d='M0 364C0 364 295.159 234.795 470.903 234.795C646.647 234.795 835.91 348.976 1053.71 348.976C1271.51 348.976 1442 240.804 1442 240.804V0H0V364Z' fill='%233552CC'/%3E%3Cpath d='M957 0H1440L1439.66 237.127C1439.66 237.127 1279.01 352.491 1003.07 350.985C1003.07 350.985 921.384 190.979 957 0Z' fill='%234568D8'/%3E%3Cpath d='M0 374V353.574C10.0648 348.559 254.967 228 465.835 228C570.423 228 659.569 256.276 745.769 283.628C835.93 312.255 929.175 341.821 1044.96 341.821C1267.53 341.821 1438.36 230.912 1440 229.843L1439.9 249.732C1432.77 254.454 1282.19 355.68 1044.96 355.68C921.508 355.68 824.597 324.921 730.852 295.184C644.422 267.758 562.797 241.859 465.835 241.859C266.456 241.859 2.37239 372.823 0 374Z' fill='url(%23paint0_linear_984_341)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_984_341' x1='0' y1='301' x2='1440' y2='301' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%233552CC'/%3E%3Cstop offset='1' stop-color='%2395D0DD'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    paddingBottom: "88px",
    [theme.breakpoints.down(1001)]: {
      // backgroundImage: `url("data:image/svg+xml,%3Csvg width='1440' height='374' viewBox='0 0 1440 374' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M176.001 293.017C176.001 293.017 395.466 335.486 601.221 248.975C601.221 248.975 466.009 198.641 176.001 293.017Z' fill='%23ABCED4'/%3E%3Cpath d='M0 364C0 364 295.159 234.795 470.903 234.795C646.647 234.795 835.91 348.976 1053.71 348.976C1271.51 348.976 1442 240.804 1442 240.804V0H0V364Z' fill='%233552CC'/%3E%3Cpath d='M957 0H1440L1439.66 237.127C1439.66 237.127 1279.01 352.491 1003.07 350.985C1003.07 350.985 921.384 190.979 957 0Z' fill='%234568D8'/%3E%3Cpath d='M0 374V353.574C10.0648 348.559 254.967 228 465.835 228C570.423 228 659.569 256.276 745.769 283.628C835.93 312.255 929.175 341.821 1044.96 341.821C1267.53 341.821 1438.36 230.912 1440 229.843L1439.9 249.732C1432.77 254.454 1282.19 355.68 1044.96 355.68C921.508 355.68 824.597 324.921 730.852 295.184C644.422 267.758 562.797 241.859 465.835 241.859C266.456 241.859 2.37239 372.823 0 374Z' fill='url(%23paint0_linear_984_341)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_984_341' x1='0' y1='301' x2='1440' y2='301' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%233552CC'/%3E%3Cstop offset='1' stop-color='%2395D0DD'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E")`,
      height: 230,
    },
    [theme.breakpoints.down(601)]: {
      height: 160,
      backgroundImage: "none",
    },
    position: "relative",
  },
  mainAreaCalculatorContainer: {
    position: "absolute",
    top: "110px",
    right: "7%",
    [theme.breakpoints.down(601)]: {
      display: "none",
    },
  },
  mobileCalculator: {
    width: "100%",
  },
  mainImage: {
    [theme.breakpoints.down(601)]: {
      width: "100%",
    },
  },
  mainAreaTextContainer: {
    height: "100%",
    width: "64%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.down(1001)]: {
      // marginTop: -200,
    },
    [theme.breakpoints.down(601)]: {
      marginTop: -155,
      width: "55%",
      alignItems: "flex-start",
      padding: "0 14px",
    },
  },
  mainAreaTextBox: {
    alignItems: "flex-end",
    [theme.breakpoints.down(601)]: {
      alignItems: "flex-start",
      width: '100%',
    },
  },
  mainAreaFirstTextStyle: {
    color: "#3552CC",
    fontFamily: "Nexa-Bold !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "65px !important",
    lineHeight: "65px !important",
    width: "max-content",
    marginRight: "47px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "26px !important",
      lineHeight: "26px !important",
      textAlign: 'right !important',
      width: '100%',
    },
  },
  mainAreaSecondTextStyle: {
    color: "#91C1C4",
    fontFamily: "Nexa-Bold !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "55px !important",
    lineHeight: "55px !important",
    width: "max-content",
    marginRight: "47px !important",
    [theme.breakpoints.down(601)]: {
      fontSize: "22px !important",
      lineHeight: "22px !important",
      width: '100%',
      textAlign: 'right !important',
    },
  },
  mainAreaThirdTextStyle: {
    color: "#6B7779",
    fontFamily: "SFUIText-Bold !important",
    fontStyle: "normal !important",
    fontWeight: "700 !important",
    fontSize: "22px !important",
    lineHeight: "22px !important",
    textAlign: "left !important",
    width: "max-content",
    [theme.breakpoints.down(601)]: {
      fontSize: "12px !important",
      lineHeight: "17px !important",
      whiteSpace: 'break-spaces',
      width: '100%',
      textAlign: 'left !important',
    },
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "64%",
    marginTop: "15px",
    [theme.breakpoints.down(601)]: {
      width: "90vw",
      marginTop: "80px",
      // padding: "0 14px",
    },
  },
  inputInsideContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down(601)]: {
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
    },
  },
  inputValidateTextStyle: {
    maxWidth: "578px !important",
    minWidth: "578px !important",
    width: "578px !important",
    marginTop: "4px !important",
    fontFamily: "SFUIText-Regular !important",
    color: "#31997C",
    lineHeight: "1.2 !important",
    [theme.breakpoints.down(601)]: {
      maxWidth: "100% !important",
      minWidth: "100% !important",
      width: "100% !important",
    },
    '& > .error': {
      color: "#FF5D51",
    }
  },
  emailInputStyle: {
    background: "#FFFFFF",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    height: "46px",
    padding: "0px 40px",
    width: "274px",

    fontFamily: "SFUIText-Regular",
    fontSize: "18px",
    lineHeight: "1.2",
    fontWeight: "600",
    /* identical to box height */

    color: "#3552CC",
    "&::placeholder": {
      color: "#3552CC",
    },
    "&:focus": {
      border: "1px solid #3552CC",
      outline: 'none !important',
    },
    [theme.breakpoints.down(601)]: {
      width: "calc(100% - 70px)",
      padding: "0 20px",
    },
  },
  startGradientButtonStyle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    minWidth: "200px",
    minHeight: "48px",
    border: "none",
    fontFamily: "SFUIText-Bold",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "1.2",
    color: "#fff",
    backgroundImage: "linear-gradient(94deg, #ABCED4,#4893CC)",
    margin: "0px 0px 0px 22px",
    cursor: "pointer",
    [theme.breakpoints.down(601)]: {
      marginTop: 16,
      minWidth: "max-content",
      padding: "12px 42px",
      margin: 0,
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: 'not-allowed'
    },
  },
}));

export default styles;
