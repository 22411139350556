import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { customTheme } from "../../features/installmentLoan/theme";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    width: '100%',
    backgroundColor: customTheme.accentBlue,
    paddingTop: '12%'
  },
  title: {
    color: customTheme.cotton,
    fontSize: 26,
    fontFamily: 'Roboto-Bold',
    fontWeight: 700,
    marginTop: 4,
  },
  desc: {
    color: customTheme.cotton,
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  img: {
    width: '100%',
  },
}));

export default useStyles;
