import { Box } from "@mui/material";
import { useHistory } from "react-router-dom";

import Header from "../Header";
import useStyle from "./styles";

import twelvemonths from "../../../assets/CreditRequest/12months.svg";
import threemonths from "../../../assets/CreditRequest/3months.svg";
import sixmonths from "../../../assets/CreditRequest/6months.svg";
import GradientButton from "../../../components/GradientButton";
import { ROUTES } from "../../../constants/routes";
import Footer from "../../../containers/home-v2/Footer"
export const FaqsContent = () => {
  const css = useStyle();
  const history = useHistory();

  return (
    <Box className={css.container}>
      <Header />
      <Box className={css.content}>
        <Box mt={12} className={css.title}>
          Solicitar un crédito con Medsi es muy fácil...
        </Box>
        <Box mt={4} className={css.desc}>
          Te ayudamos a alcanzar tús objetivos más rápido. Pagar el
          procedimiento que necesitas ya es una realidad.
          <br />
          <br />
          <br />
          Completar la aplicación toma solo unos minutos y puedes obtener al
          instante hasta $80,000 pesos de crédito para tu tratamiento o cirugía.
        </Box>

        <Box mt={55} className={css.subTitleWrapper}>
          <Box className={css.subTitle}>¡A tu tiempo!</Box>
          <Box className={css.monthsDesc}>
            Elige si deseas realizar tus pagos de forma quincenal o mensual,
            Medsi es la mejor forma de cuidar tu salud y la de tus seres
            queridos.
          </Box>
        </Box>

        <Box display="flex" alignItems="center" columnGap={20}>
          <div>
            <img src={threemonths} width={253} height={251} alt="treeMonths" />
            <img src={sixmonths} width={253} height={251} alt="SixMonths" />
            <img src={twelvemonths} width={253} height={251} alt="nineMonths" />
          </div>
          <Box
            border={1}
            className={css.registerButtonContainer}
            onClick={() => {
              history.push(ROUTES.register);
            }}
          >
            Regístrate
          </Box>
        </Box>
      </Box>

      <Box mt={17} className={css.curpContainer}>
        <div className="title">
          Para solicitar tu crédito necesitarás tener a la mano
        </div>
        <div className="buttonContainer">
          <Box className="itemsWrapper">CURP</Box>
          <Box className="itemsWrapper">RFC</Box>
          <Box className="itemsWrapper">
            ID
            <br />
            Oficial
          </Box>
        </div>
      </Box>

      <Box className={css.processContainer}>
        <div className="title">¿Que tengo que hacer?</div>
        <div className="subtitle">
          Completar la aplicación toma solo 5 minutos.
        </div>
        <div className="processRow">
          <div className="bullet">1</div>
          <p className="processContent">
            Desde tu celular, descarga el app de Medsi Credit o ingresa al
            portal en línea{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://www.medsi.mx/registro"
            >
              aquí
            </a>
            .
          </p>
        </div>
        <div className="processRow">
          <div className="bullet">2</div>
          <p className="processContent">
            LLena tu solicitud de crédito, ingresando tu información personal.
          </p>
        </div>
        <div className="processRow">
          <div className="bullet">3</div>
          <p className="processContent">
            Si tu línea de crédito ha sido aprobada, inmediatamente recibirás
            notificaciones por email y WhatsApp.
          </p>
        </div>
        <div className="processRow">
          <div className="bullet">4</div>
          <p className="processContent">
            Ingresa a tu Tablero de Control Medsi{" "}
            <a
              style={{ textDecoration: "none" }}
              href="https://www.medsi.mx/login"
            >
              {" "}
              aqui{" "}
            </a>{" "}
            para programar desembolsos.
          </p>
        </div>
        <div className="processRow">
          <div className="bullet">5</div>
          <p className="processContent">
            Indica si el tratamiento será para ti o para algún miembro de tu
            familia. Compártenos información del procedimiento a realizar y el
            médico con quien prefieres tratarte.
          </p>
        </div>
        <div className="processRow">
          <div className="bullet">6</div>
          <p className="processContent">
            Completa la validación de tu identidad, subiendo una foto de tu
            identificación y un video selfie. Firma tu solicitud de desembolso.
          </p>
        </div>
        <div className="processRow">
          <div className="bullet">7</div>
          <p className="processContent">
            Al momento de recibir tu tratamiento, Medsi realiza el pago
            directamente al médico o clínica.
          </p>
        </div>
      </Box>

      <Box className={css.contactusContainer}>
        <div className="desc">
          <p className="desc1">
            Comienza hoy y dí <span style={{ color: "#0ACC97" }}>Sí</span> a
            cuidar de tu salud
          </p>
          <p className="desc2">
            Si tienes una pregunta escríbenos a contacto@medsi.mx
          </p>
        </div>
        <Box mt={4}>
          <GradientButton
            title="Quiero solicitar mi línea familiar"
            handleClick={() => history.push(ROUTES.register)}
            customCss={css.btnCredit}
          />
        </Box>
      </Box>

      {/* <Box mt={6} width="100%">
        <Grid container>
          <Grid item md={4} xs={12}>
            <Box>
              <img src={question} className={css.question} />
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className={css.content}>
              <Box textAlign="right" mt={4} className={css.linkContainer}>
                <a href="/faqs" target="_blank" className={css.link}>
                  Ver todas las preguntas
                </a>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box> */}
<Footer/> 
   </Box>
  );
};

export default FaqsContent;
