import { makeStyles } from "@mui/styles";

const PasswordItemStyles = makeStyles((theme) => ({
  "@keyframes fade": {
    from: { transform: `scale(0)` },
    to: { transform: `scale(1)` },
  },
  icon: {
    marginRight: 10,
    fontSize: "11px !important",
  },
  succesIcon: {
    color: theme.palette.success.main,
    animation: `$fade .5s `,
  },
  unSuccessIcon: {
    color: theme.palette.primary.gray,
    animation: `$fade .5s `,
  },
  passwordItemText: {
    fontSize: "11px !important",
    color: theme.palette.primary.black,
  },
  itemContainer: {},
}));

export default PasswordItemStyles;
