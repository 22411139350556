import { makeStyles } from "@mui/styles";

const hairRestaurationStyles = makeStyles((theme) => ({
  hairReparationIcon: {
    marginLeft: 20,
    paddingRight: 10,
  },
  mainContainer: {
    marginBottom: 10,
    background: "#FFFFFF",
    boxShadow: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
    borderBottomLeftRadius: `20px`,
    borderBottomRightRadius: `20px`,
    paddingBottom: 10,
    // marginTop: 20,
    // maxWidth: 1400,
    // marginLeft: 10,
  },
}));

export default hairRestaurationStyles;
