import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";

import DropdownMenu from "../../components/sharedComponents/DropdownMenu";
import InputText from "../../components/sharedComponents/InputText";

import { URBANIST_FONT } from "../../constants/common";
import customColor from "../../theme/customColor";
import { rfcValidator } from "../../utils";
import Details from "./Details";
import CreditDetails from "./Details/credit";
import DoctorDetails from "./Details/doctor";
import { getPayload, initialValues, paymentMethodsType } from "./helper";
import useStyles from "./styles";

const Form = ({ saveData, doctorDetails }) => {
  const css = useStyles();
  const [wasInitialized, setWasInitialized] = useState(false);

  const validate = (values) => {
    let errors = {};
    !wasInitialized && setWasInitialized(true);

    // Validate bank method type
    !values.paymentMethodsType
      ? (errors.paymentMethodsType = "Selecciona el método de pago")
      : delete errors.paymentMethodsType;

    // Validate bank
    !values.bankName
      ? (errors.bankName = "Selecciona el Banco")
      : delete errors.bankName;

    // Validate business name
    !values.businessName
      ? (errors.businessName = "Este campo es requerido")
      : delete errors.businessName;

    // Validate id
    !values.id ? (errors.id = "Este campo es requerido") : delete errors.id;

    // Validate fullName
    !values.fullName
      ? (errors.fullName = "Este campo es requerido")
      : delete errors.fullName;

    if (!values.cardNumber) {
      errors.cardNumber = "Este campo es requerido";
    } else if (
      (values.paymentMethodsType === paymentMethodsType[0] &&
        values.cardNumber.length !== 18) ||
      (values.paymentMethodsType === paymentMethodsType[1] &&
        values.cardNumber.length !== 16)
    ) {
      errors.cardNumber = "Introduce un número de tarjeta válido";
    } else {
      delete errors.cardNumber;
    }

    //validate rfc
    if (rfcValidator(values.rfc) || !values.rfc) {
      // setValidateRfc(true);
      delete errors.rfc;
    } else {
      // setValidateRfc(false);
      errors.rfc = "El RFC no es válido";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    validate,
    onSubmit: async (values) => {
      const payload = getPayload({
        values,
        doctorDetails,
      });
      saveData(payload);
    },
  });

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setValues,
  } = formik;

  useEffect(() => {
    const paymentMethod = paymentMethodsType.filter(
      (paymentType) =>
        paymentType?.split(" (")[0] === doctorDetails?.payment_method
    )[0];

    const updatedValues = {
      email: doctorDetails?.doc_email || "",
      phoneNumber: doctorDetails?.doc_phone || "",
      paymentMethodsType: paymentMethod || "",
      bankName: doctorDetails?.bank_name || "",
      cardNumber: doctorDetails?.number || "",
      businessName: doctorDetails?.business_name || "",
      fullName: doctorDetails?.full_name_physician || "",
      id: doctorDetails?.professional_id || "",
      rfc: doctorDetails?.rfc || "",
    };
    setValues({ ...values, ...updatedValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctorDetails]);

  const isButtonEnable = wasInitialized && Object.entries(errors).length === 0;

  const renderDropDown = ({
    label,
    value,
    dataObj,
    disabled = false,
    placeholder,
  }) => {
    return (
      <DropdownMenu
        label={label}
        optionsObject={dataObj}
        name={value}
        handleChange={handleChange}
        value={values[value] || "none"}
        onBlur={handleBlur}
        errors={errors[value]}
        touched={touched[value]}
        disabled={disabled}
        stylesInput={css.select}
        placeholder={placeholder}
        placeHolderStyle={css.selectPlaceHolderColor}
      />
    );
  };

  const renderInput = ({
    label,
    value,
    disabled = false,
    setField,
    placeholder,
  }) => {
    return (
      <InputText
        stylesInput={css.formControl}
        label={label}
        value={values[value]}
        name={value}
        handleChange={setField ? (ev) => setField(ev) : handleChange}
        errors={errors[value]}
        touched={touched[value]}
        handleBlur={handleBlur}
        disabled={disabled}
        placeholder={placeholder}
      />
    );
  };

  return (
    <Box className={css.container}>
      <Box className={css.title}>
        Muchas gracias por confirmar el procedimiento de su paciente.
      </Box>
      <Box className={css.desc}>
        A continuación, indique la cuenta a la que debemos enviar el pago a
        través del SPEI.
      </Box>
      <Grid
        className={css.details}
        container
        // justifyContent="center"
        columnSpacing={2}
        rowSpacing={2}
        marginTop={4}
        maxWidth="1270px"
        sx={(theme) => ({
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1270px",
          },
          [theme.breakpoints.up("md")]: {
            maxWidth: "760px",
          },
          [theme.breakpoints.up("sm")]: {
            maxWidth: "670px",
          },
        })}
      >
        <Grid item xs={12} lg={6}>
          <Details doctorDetails={doctorDetails} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <DoctorDetails doctorDetails={doctorDetails} />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CreditDetails doctorDetails={doctorDetails} />
        </Grid>
      </Grid>
      <Typography
        maxWidth={"620px"}
        marginTop={6}
        fontFamily={URBANIST_FONT.REGULAR}
        fontSize="1rem"
        color={customColor.cotton}
        textAlign="center"
      >
        *Estos montos están sujetos a cambios si el paciente actualiza su
        procedimiento y los detalles de la solicitud de préstamo durante el
        proceso de revisión.
      </Typography>
      <Typography
        maxWidth={"1080px"}
        marginTop={6}
        fontFamily={URBANIST_FONT.REGULAR}
        fontSize="1rem"
        color={customColor.cotton}
        textAlign="center"
      >
        Para agregar o actualizar sus datos bancarios, verifique la bandeja de
        entrada de su correo electrónico o Whatsapp enviado por Medsi una vez
        que se apruebe esta solicitud de préstamo
      </Typography>
    </Box>
  );
};

export default Form;
