import React, { useEffect } from "react";
import Button from "@mui/material/Button";

import configKeys from "../../config/key";
import config from "../../config";
import useStyles from "./styles";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      "mati-button": unknown;
    }
  }
}

interface MatiProps {
  onComplete: () => void;
  onExit: () => void;
  flowId: string;
  userId: string;
  isCosigner?: boolean;
  isAutoLoad?: boolean;
  referenceId?: string;
}

const Mati: React.FC<MatiProps> = ({
  onComplete,
  onExit,
  flowId,
  userId,
  referenceId,
  isCosigner = false,
  isAutoLoad = false,
}) => {
  const matiRef = React.useRef<HTMLElement | null>(null);
  const css = useStyles();

  const onStart = React.useCallback(() => {
    matiRef.current?.click();
  }, [matiRef]);

  const startEvent = React.useCallback(() => {}, []);

  const finishEvent = React.useCallback(() => {
    onComplete();
  }, []);

  useEffect(() => {
    if (isAutoLoad) {
      matiRef.current?.click();
    }
  }, [isAutoLoad]);

  const exitEvent = React.useCallback(() => {
    if (onExit) {
      onExit();
    } else {
      onComplete();
    }
  }, []);

  React.useEffect(() => {
    if (matiRef?.current) {
      matiRef.current.removeEventListener("mati:loaded", startEvent);
      matiRef.current.removeEventListener("mati:userFinishedSdk", finishEvent);
      matiRef.current.removeEventListener("mati:exitedSdk", exitEvent);
      matiRef.current.addEventListener("mati:loaded", startEvent);
      matiRef.current.addEventListener("mati:userFinishedSdk", finishEvent);
      matiRef.current.addEventListener("mati:exitedSdk", exitEvent);
    }
  }, [matiRef, finishEvent, exitEvent]);

  const getMetaData = () => {
    const obj = {
      fixedLanguage: config.language,
      co_signer_flag: isCosigner,
      user_id: userId,
    };
    if (isCosigner) {
      obj.user_id = referenceId || "";
    }

    return JSON.stringify(obj);
  };

  return (
    <div className={css.wrapper}>
      <mati-button
        ref={matiRef}
        clientid={configKeys.MATI_CLIENT_ID}
        flowId={flowId}
        metadata={getMetaData()}
        // metadata={JSON.stringify({
        //   user_id: userId,
        //   co_signer_flag: isCosigner,
        // })}
      />

      {!isAutoLoad && (
        <Button
          variant="text"
          size="large"
          data-testid="startKYC"
          onClick={onStart}
        >
          Start Verification
        </Button>
      )}
    </div>
  );
};

export default Mati;
// export default observer(Mati);
