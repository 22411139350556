import React from "react";
import { Box } from "@mui/material";
import moment from "moment";
import { TRANSACTION_STATUS } from "../../../../constants/common";
import { currencyFormat } from "../../../../utils/index";

const Disbursements = (props) => {
  const { each } = props;

  return (
    <div>
      <Box
        sx={(theme) => ({
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "30px",
          padding: "32px",
          background: "#FFFFFF",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "20px",
          fontFamily: "SFUIText-semibold",
          [theme.breakpoints.down("lg")]: {
            fontFamily: "Urbanist-Semibold",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: "18px",
          },
          [theme.breakpoints.down("md")]: {
            gridTemplateColumns: "repeat(3, 1fr)",
            fontSize: "14px",
          },
          [theme.breakpoints.down("tablet")]: {
            gridTemplateColumns: "repeat(2, 1fr)",
          },
        })}
      >
        <div>
          <label style={{ color: "#9E9E9E" }}>Fecha de Transacción</label>
          <div style={{ marginTop: 10 }}>
            {" "}
            {moment(each.txn_timestamp).format("MMMM DD, YYYY")}
          </div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>ID de transacción</label>
          <div style={{ marginTop: 10 }}>{each.txn_id}</div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>Estatus de la transacción</label>
          <div style={{ marginTop: 10, color: "#00B63E" }}>
            {TRANSACTION_STATUS[each.status]}
          </div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>Tipo de transacción</label>
          <div style={{ marginTop: 10 }}>
            {TRANSACTION_STATUS[each.txn_type]}
          </div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>Monto total</label>
          <div style={{ marginTop: 10 }}>${currencyFormat(each.amt)}</div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>Método de pago</label>
          <div style={{ marginTop: 10 }}>{each.payment_method}</div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>Número de tarjeta</label>
          <div style={{ marginTop: 10 }}>
            {each.card_number ? each.card_number : "--"}
          </div>
        </div>
        <div>
          <label style={{ color: "#9E9E9E" }}>Nombre del banco</label>
          <div style={{ marginTop: 10 }}>
            {each.bank_name ? each.bank_name : "--"}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Disbursements;
