import React, { useEffect } from "react";
import useStyles from "../../../../styles";
import { currencyFormat } from "../../../../../../utils";
import {
  saveApplicationLoanDetail,
  saveTakeLoan,
} from "../../../../../../store/slices/users/actions";
import { useAppDispatch } from "../../../../../../store";
import { errorToast, successToast } from "../../../../../../utils/toast";
import {
  API_STATUS,
  SIGNATURE_STATUS,
  URBANIST_FONT,
} from "../../../../../../constants/common";
import {
  selectLmsBalanceSummary,
  selectStepperStatus,
} from "../../../../../../store/slices/users";

import { useSelector } from "react-redux";
import Box from "@mui/material/Box/Box";

const CreditCalculator = (props) => {
  const {
    decisionStatus,
    creditLimit,
    procedureCost,
    months,
    requestedAmount,
    setRequestedTenure,
    setRequestAmount,
    selectLoanDetail,
    loanDetailsStepper,
    promoCreditLimit,
  } = props;
  const urlId = window.location.search.slice(1, window.location.search.length);

  let application_id;
  const initialMonth =
    loanDetailsStepper &&
    loanDetailsStepper.pricing &&
    Object.keys(loanDetailsStepper.pricing);
  const css = useStyles();
  const dispatch = useAppDispatch();
  const LmsBalanceSummary = useSelector(selectLmsBalanceSummary);
  const [creditAmount, setCreditAmount] = React.useState(30000);
  const [tenure, setTenure] = React.useState(3);
  const [error, setError] = React.useState(false);
  const [isBiweeklyPayment, setIsBiweeklyPayment] = React.useState(
    LmsBalanceSummary?.repayment_option === "2-week"
  );
  const [paymentDuration, setPaymentDuration] = React.useState(
    LmsBalanceSummary?.repayment_option || "1-month"
  );

  const stepperStatus = useSelector(selectStepperStatus);

  const disableEdit =
    stepperStatus?.application_status === "APPROVED" ||
    stepperStatus?.application_status === "LEGAL_REVIEW" ||
    stepperStatus?.application_status === "DISBURSED";

  let isQuinceal;
  let isMensual;
  if (!LmsBalanceSummary?.repayment_option_lock) {
    isQuinceal = true;
    isMensual = true;
  } else {
    if (LmsBalanceSummary?.repayment_option === "2-week") {
      isQuinceal = true;
      isMensual = false;
    } else {
      isQuinceal = false;
      isMensual = true;
    }
  }

  React.useEffect(() => {
    // if (LmsBalanceSummary?.repayment_option_lock) {
    if (LmsBalanceSummary?.repayment_option === "2-week") {
      setIsBiweeklyPayment(true);
      setPaymentDuration("2-week");
    }
    if (LmsBalanceSummary?.repayment_option === "1-month") {
      setIsBiweeklyPayment(false);
      setPaymentDuration("1-month");
    }
    // }
  }, [LmsBalanceSummary]);

  const handleAmount = (e) => {
    const value = Number(e.target.value || 0);

    if (value > procedureCost || value < 1000) {
      setError(true);
    } else {
      setError(false);
    }

    if (parseFloat(value) === 0 || parseFloat(value) === "") {
      setRequestedTenure(3);
    }

    if (promoCreditLimit) {
      if (value < promoCreditLimit) {
        setCreditAmount(parseFloat(value));
        setRequestAmount(parseFloat(value));
      } else {
        setCreditAmount(promoCreditLimit);
        setRequestAmount(parseFloat(promoCreditLimit));
      }
    } else {
      if (value < LmsBalanceSummary?.open_to_buy) {
        setCreditAmount(parseFloat(value));
        setRequestAmount(parseFloat(value));
      } else {
        setCreditAmount(LmsBalanceSummary?.open_to_buy);
        setRequestAmount(parseFloat(LmsBalanceSummary?.open_to_buy));
      }
    }
    // if (value < promoCreditLimit || LmsBalanceSummary?.open_to_buy) {
    //   setCreditAmount(parseFloat(value));
    //   setRequestAmount(parseFloat(value));
    // } else {
    //   setCreditAmount(promoCreditLimit || LmsBalanceSummary?.open_to_buy);
    //   setRequestAmount(
    //     parseFloat(promoCreditLimit || LmsBalanceSummary?.open_to_buy)
    //   );
    // }
  };

  React.useEffect(() => {
    setTenure(parseInt(initialMonth && initialMonth[0]));
    setCreditAmount(requestedAmount);
  }, [creditLimit, loanDetailsStepper, requestedAmount]);

  useEffect(() => {
    setCreditAmount(loanDetailsStepper?.req_amt);
    setTenure(loanDetailsStepper?.tenure);
    setRequestedTenure(loanDetailsStepper?.tenure);
  }, [loanDetailsStepper]);

  const handleTenure = (e, month) => {
    if (month) {
      setTenure(month);
      setRequestedTenure(month);
    }
  };

  const handleSubmit = async () => {
    console.log("inside sunmit", { tenure, creditAmount });
    if (tenure && creditAmount) {
      if (!selectLoanDetail?.application_id) {
        application_id = urlId || localStorage.getItem("application_id");
      } else {
        application_id = urlId || selectLoanDetail?.application_id;
      }

      const res = await dispatch(
        saveApplicationLoanDetail({
          application_id,
          tenure,
          req_amt: creditAmount,
          repayment_option: paymentDuration,
        })
      );
      if (res?.payload?.status === API_STATUS.SUCCESS) {
        successToast(SIGNATURE_STATUS[res?.payload?.status]);
      } else {
        errorToast(res?.payload?.status);
      }
    }
  };

  const handleTakeLoan = async () => {
    const res = await dispatch(
      saveTakeLoan({
        req_amt: creditAmount,
        tenure: tenure,
      })
    );

    if (res?.payload?.status) {
      successToast(SIGNATURE_STATUS[res?.payload?.status]);
    }
  };

  return (
    <Box
      sx={(theme) => ({
        background: "#FFFFFF",
        borderRadius: "20px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        padding: "30px 40px 15px 40px",
        paddingBottom: "16px",
        [theme.breakpoints.down("lg")]: {
          boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
          padding: "2rem",
          fontFamily: `${URBANIST_FONT.REGULAR} !important`,
          mt: "1rem",
        },
      })}
    >
      <Box
        sx={(theme) => ({
          color: "#9E9E9E",
          fontFamily: "SFUIText-semibold",
          fontSize: "16px",
          lineHeight: "24px",
          "& span": { fontFamily: "SFUIText-bold" },
          [theme.breakpoints.down("lg")]: {
            lineHeight: "17px",
            fontSize: "14px",
            fontFamily: "Urbanist-Medium",
            mb: "1rem",
            "& span": { fontFamily: "Urbanist-SemiBold" },
          },
        })}
      >
        <span>¡Empieza aquí! </span>
        Ingresa el monto solicitado para tu tratamiento
      </Box>

      <div
        style={{
          border: `1px solid ${
            (creditAmount && procedureCost && creditAmount > procedureCost) ||
            creditAmount < 1000
              ? `red`
              : `#000`
          } `,
          borderRadius: 8,
          position: "relative",
          overflow: "hidden",
          padding: "0px 10px",
        }}
      >
        <input
          style={{
            height: 40,
            width: 320,
            fontWeight: 600,
            fontSize: 18,
            color: "#000000",
            border: "none",
            outline: "none",
          }}
          onChange={(e) => {
            handleAmount(e);
          }}
          type="text"
          value={currencyFormat(creditAmount)}
        />
        <button
          style={{
            background: "#E6E6E6",
            position: "absolute",
            right: 0,
            top: 0,
            width: 50,
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          $
        </button>
      </div>
      {!!creditAmount && !!procedureCost && creditAmount > procedureCost && (
        <div style={{ color: "#686872", fontSize: "12px" }}>
          El monto del crédito no puede ser mayor que el costo del procedimiento
        </div>
      )}

      {!!creditAmount && creditAmount < 1000 && (
        <div style={{ color: "#686872", fontSize: "12px" }}>
          El monto del crédito no puede ser inferior a $ 1000
        </div>
      )}

      <p className={css.availableCredit}>
        Tu crédito disponible es de{" "}
        <span>
          <span style={{ color: "#000000" }}>
            {!promoCreditLimit ||
              (LmsBalanceSummary?.open_to_buy === "" &&
                currencyFormat(30000))}{" "}
            $
            {promoCreditLimit?.toLocaleString() ||
              LmsBalanceSummary?.open_to_buy.toLocaleString()}
          </span>
        </span>
      </p>

      <p className={css.chooseTerm}>Elegir plazo:</p>

      <div style={{ display: "flex" }}>
        {loanDetailsStepper &&
          loanDetailsStepper.pricing &&
          Object.keys(loanDetailsStepper.pricing)?.map((each, index) => (
            <span
              key={index}
              style={{
                background: tenure === parseInt(each) ? "#3552CC" : "#EBEEFA",
                color: tenure === parseInt(each) ? "white" : "black",
              }}
              className={css.months}
              onClick={(e) => {
                handleTenure(e, parseInt(each));
              }}
            >
              {each} months
            </span>
          ))}

        {Object.keys(loanDetailsStepper).length === 0 && (
          <>
            <span
              style={{
                background: tenure === 3 ? "#3552CC" : "#EBEEFA",
                color: tenure === 3 ? "white" : "black",
              }}
              className={css.months}
              onClick={(e) => {
                handleTenure(e, 3);
              }}
            >
              3 meses
            </span>
            <span
              style={{
                background: tenure === 6 ? "#3552CC" : "#EBEEFA",
                color: tenure === 6 ? "white" : "black",
              }}
              className={css.months}
              onClick={(e) => {
                handleTenure(e, 6);
              }}
            >
              6 meses
            </span>
            <span
              style={{
                background: tenure === 12 ? "#3552CC" : "#EBEEFA",
                color: tenure === 12 ? "white" : "black",
              }}
              className={css.months}
              onClick={(e) => {
                handleTenure(e, 12);
              }}
            >
              12 meses
            </span>
          </>
        )}
      </div>

      <p className={css.chooseTerm}>Preferencia de pago</p>

      {(isQuinceal || isMensual) && (
        <div style={{ display: "flex" }}>
          <>
            {isMensual && (
              <span
                style={{
                  background: !isBiweeklyPayment ? "#3552CC" : "#EBEEFA",
                  color: !isBiweeklyPayment ? "white" : "black",
                }}
                className={css.months}
                onClick={(e) => {
                  // handleTenure(e, 3);
                  setPaymentDuration("1-month");

                  setIsBiweeklyPayment(false);
                }}
              >
                Mensual
              </span>
            )}

            {isQuinceal && (
              <span
                style={{
                  background: isBiweeklyPayment ? "#3552CC" : "#EBEEFA",
                  color: isBiweeklyPayment ? "white" : "black",
                }}
                className={css.months}
                onClick={(e) => {
                  setIsBiweeklyPayment(true);
                  setPaymentDuration("2-week");
                  // handleTenure(e, 6);
                }}
              >
                Quincenal
              </span>
            )}
          </>
        </div>
      )}

      <div style={{ textAlign: "center" }}>
        <button
          style={{
            opacity: error || disableEdit ? 0.5 : 1,
          }}
          disabled={disableEdit}
          className={css.calculate}
          onClick={() => {
            if (!error) {
              handleSubmit();
            }
          }}
        >
          Actualizar
        </button>
      </div>
    </Box>
  );
};

export default CreditCalculator;
