import {
  Grid,
  Typography,
  Box,
  TextField,
  InputAdornment,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUserEmail,
  selectUserId,
  setIsAuthorized,
  setVerifyEmail,
} from '../../store/slices/users';
import { updateData, verifyEmailValidation } from '../../utils/fetchData';
import closeImage from '../../assets/Vector.png';

import ButtonComponent from '../sharedComponents/ButtonComponent';
// import ButtonComponent from "../ButtonComponent";
import TermConditionTextStyles from './PromoCode.styles';
import AddCircle from '@mui/icons-material/AddCircle';
import {
  submitPromocode,
  verifyPromocode,
} from '../../store/slices/users/actions';
import { API_STATUS } from '../../constants/common';
import { errorToast, successToast } from '../../utils/toast';
import CongratulationPromoCode from './CongratulationPromocode';
import ModalComponent from '../../components/sharedComponents/ModalComponent';
import useDebounce from '../../utils/debounceHook';
import customColor from '../../theme/customColor';

const PROMO_CODE_REG = /(?=.*[!@#\$%\^&\*])/;
const PromoCode = (props) => {
  const {
    setPromoPopUp,
    formik,
    setShowPromoCode,
    setUserPromoCode,
    userPromocode,
    setupdateData,
  } = props;
  const [errorValidate, setErrorValidate] = useState(false);
  const dispatch = useDispatch();
  const classes = TermConditionTextStyles();
  const id = useSelector(selectUserId);
  const [openCongratPromo, setOpenCongratsPromo] = useState(false);
  const [message, setMessage] = useState();
  const [code, setCode] = useState();
  const [validCode, setValidCode] = useState(false);
  const [error, setError] = useState('');

  // let error = "";

  useEffect(() => {
    setCode(userPromocode);
  }, []);

  const handleClose = () => {
    setPromoPopUp(false);
    setCode('');
  };

  const apiPromocode = useDebounce(async (code) => {
    const res = await dispatch(verifyPromocode({ promocode: code }));
    if (res?.payload?.data?.is_valid) {
      setOpenCongratsPromo(!openCongratPromo);
      setUserPromoCode(code.toUpperCase());
      setMessage(res?.payload?.data?.msg);
      successToast(res?.payload?.data?.msg);
      setValidCode(true);
      setCode(code);
    } else {
      setValidCode(false);
      setError(res?.payload?.data?.msg);
      // errorToast(res?.payload?.data?.msg);
    }
  }, 1000);

  const handleSubmit = () => {
    apiPromocode(code);
  };

  const onPromocodeChange = (e) => {
    setCode(e.target.value);
    if (!PROMO_CODE_REG.test(e.target?.value)) {
      setError('');
    } else {
      setError(
        'El código de promoción debe cumplir con los parámetros requeridos'
      );
    }
  };

  return (
    <div className={classes.mainContainer}>
      <AddCircle
        style={{
          color: '#3552CC',
          transform: 'rotate(45deg)',
          position: 'absolute',
          right: 28,
          top: 32,
          cursor: 'pointer',
          fontSize: '1.8rem',
        }}
        onClick={handleClose}
      />

      <Box
        sx={(theme) => ({
          color: customColor.celticBlue,
          fontFamily: 'SFUIText-bold',
          fontSize: '28px',
          marginTop: '38px',
          textAlign: 'center',
          [theme.breakpoints.down('lg')]: {
            fontFamily: 'Urbanist-Bold',
            fontSize: '24px',
          },
          [theme.breakpoints.down('tablet')]: { mb: '1rem' },
        })}
      >
        ¡Introduce tu código <br />
        promocional!
      </Box>
      <Typography
        variant='subtitle2'
        align='center'
        className={classes.modalDescription}
      >
        Ingresa el código de promoción a continuación para aprovechar sus
        beneficios.
      </Typography>

      <Box
        sx={(theme) => ({
          position: 'relative',
          [theme.breakpoints.down('tablet')]: { mt: '1rem' },
        })}
      >
        <TextField
          className='myinput'
          id='promocode'
          name='promocode'
          size='normal'
          autoComplete='off'
          placeholder='¡Introduce tu código promocional!'
          autoFocus={true}
          value={code?.toUpperCase()}
          error={error && error}
          helperText={error && error}
          onChange={onPromocodeChange}
          onBlur={() => setErrorValidate(true)}
          onFocus={() => setErrorValidate(false)}
          sx={(theme) => ({
            [theme.breakpoints.down('lg')]: {
              '& .MuiFormHelperText-root': {
                fontFamily: 'Urbanist-Regular !important',
              },
            },
          })}
        />
      </Box>

      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className={classes.promoBtn}
      >
        <ButtonComponent
          type='submit'
          text='Continuar'
          onClick={handleSubmit}
          className={classes.submitBtn}
        />
      </Box>

      <ModalComponent
        open={openCongratPromo}
        setOpenCongratsPromo={setOpenCongratsPromo}
        body={
          <CongratulationPromoCode
            setOpenCongratsPromo={setOpenCongratsPromo}
            setPromoPopUp={setPromoPopUp}
            setShowPromoCode={setShowPromoCode}
            message={message}
            code={code}
            validCode={validCode}
            setupdateData={setupdateData}
          />
        }
        hideBackdrop={false}
        onBackdropClick={() => setOpenCongratsPromo(false)}
      />
    </div>
  );
};

export default PromoCode;
