import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ButtonComponent from '../../sharedComponents/ButtonComponent';
// import ButtonComponent from "../ButtonComponent";
import AddCircle from '@mui/icons-material/AddCircle';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import TermConditionTextStyles from './TermsAndConditions.styles';

const MedsiTnc = (props) => {
  const { setOpen, setIsAccepted, tncUrl, isLoadingTerms = false } = props;
  const classes = TermConditionTextStyles();
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const onClick = async () => {
    setOpen(false);
    setIsAccepted(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.tncContainer}>
      {isLoadingTerms ? (
        <Box
          sx={(theme) => ({
            height: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '24px',
            color: '#6B7779',
            fontFamily: 'SFUIText-bold',
            [theme.breakpoints.down('lg')]: {
              fontFamily: 'Urbanist-Bold',
              fontSize: '21px',
            },
            [theme.breakpoints.down('tablet')]: {
              fontSize: '18px',
              pt: '3rem',
            },
          })}
        >
          Has hecho tu trabajo. Siéntese y espere mientras generamos el contrato
          de solicitud de medsi para usted...
        </Box>
      ) : (
        <>
          <div style={{ marginLeft: 40 }}>
            <Box
              sx={(theme) => ({
                fontSize: '24px',
                color: '#6B7779',
                fontFamily: 'SFUIText-bold',
                paddingTop: '30px',
                [theme.breakpoints.down('lg')]: {
                  fontFamily: 'Urbanist-Bold',
                  fontSize: '21px',
                },
                [theme.breakpoints.down('tablet')]: {
                  fontSize: '18px',
                  pt: '3rem',
                },
              })}
            >
              Términos y Condiciones de Medsi
            </Box>
            <a
              href='#lastLine'
              style={{
                position: 'absolute',
                bottom: 50,
                right: 80,
                zIndex: 20,
              }}
            >
              <ExpandCircleDownIcon color='primary' fontSize='large' />
            </a>

            {/* <div
          style={{
            fontSize: 18,
            color: "#6B7779",
          }}
        >
          Last updated on 11/05/2022
        </div> */}

            <AddCircle
              style={{
                color: '#3552CC',
                transform: 'rotate(45deg)',
                position: 'absolute',
                right: 20,
                top: 20,
              }}
              onClick={handleClose}
            />

            <div
              style={{
                border: '1px solid rgba(199, 200, 206, 0.3',
                borderRadius: 10,
                marginRight: 60,
                padding: '24px 19px 100px',
                marginTop: 24,
                maxHeight: '630px',
                overflow: 'auto',
                position: 'relative',
              }}
            >
              {!iframeLoaded && (
                <Box
                  sx={(theme) => ({
                    height: '300px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '24px',
                    color: '#6B7779',
                    fontFamily: 'SFUIText-bold',
                    [theme.breakpoints.down('lg')]: {
                      fontFamily: 'Urbanist-Bold',
                      fontSize: '21px',
                    },
                    [theme.breakpoints.down('tablet')]: {
                      fontSize: '18px',
                    },
                  })}
                >
                  Has hecho tu trabajo. Siéntese y espere mientras generamos el
                  contrato de solicitud de medsi para usted...
                </Box>
              )}
              <iframe
                title='agreementPdf'
                src={`${tncUrl}`}
                style={{
                  width: iframeLoaded ? '100%' : 0,
                  height: iframeLoaded ? 'calc(100vh - 100px)' : 0,
                }}
                onLoadStart={() => console.log('start')}
                onLoadedData={() => console.log('loaded')}
                onLoad={() => setIframeLoaded(true)}
              />
            </div>
          </div>
          {iframeLoaded && (
            <div
              style={{
                position: 'absolute',
                bottom: '-14px',
                margin: '0 auto',
                left: 0,
                right: 0,
              }}
            >
              <Box display='flex' justifyContent='center' alignItems='center'>
                <ButtonComponent
                  type='submit'
                  text='Estoy De Acuerdo'
                  disabled={false}
                  onClick={onClick}
                />
              </Box>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MedsiTnc;
