// import ButtonComponent from "../ButtonComponent";
import TermConditionTextStyles from "./TermsAndConditions.styles";

const QRCode = (props) => {
  const { setOpen, setIsAccepted, qr } = props;
  const classes = TermConditionTextStyles();
  const onClick = async () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.mainContainer}>
      <div
        style={{
          display: "flex",
          padding: "40px 50px",
          justifyContent: "center",
        }}
      >
        <img style={{ width: 227 }} src={qr} />
      </div>

      <h3
        style={{
          fontSize: 20,
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 50,
          listStylePosition: "inside",
        }}
      >
        Pasos para usar:
      </h3>
      <ul
        style={{
          listStyle: "number",
          color: "#6B7779",
          margin: 0,
          paddingHorizontal: 20,
          paddingBottom: 30,
          listStylePosition: "inside",
        }}
      >
        <li>Ponte en contacto con tu médico y comparte este QR </li>
        <li>
          Pide al médico que escanee este QR usando la cámara de su teléfono{" "}
        </li>
        <li>
          Una vez que el Doctor escanee el QR, podrá confirmar tu cita y
          completar la información necesaria para que finalicemos tu solicitud{" "}
        </li>
      </ul>
    </div>
  );
};

export default QRCode;
