import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import DropdownMenu from "../../../../../../components/sharedComponents/DropdownMenu";
import InputPhone from "../../../../../../components/sharedComponents/InputPhone/indexV2";
import InputText from "../../../../../../components/sharedComponents/InputText";
import { RenderTextInput } from "../../../../../../containers/ProcessInformation/v2/FormContainer/HelperComponent";
import { useAppDispatch } from "../../../../../../store";
import useStore from "../../../../../../store/getStore";
import { getDoctorList } from "../../../../../../store/slices/onboarding/actions";
import data from "../../../../../../utils/static/data";
import {
  doctorListOption,
  fillClinicValues,
  isOtherShowClinic,
  isOtherShowDoctor,
} from "../../../../../common/ProcedureForm/helpers";
import useStyles from "../styles";

const RenderDoctorDetails = ({
  formik,
  hasNotDoctor,
  responseData,
  disabled,
}) => {
  const css = useStyles();
  const { onboarding } = useStore();
  const dispatch = useAppDispatch();
  const isDr = Boolean(responseData?.doc_email || responseData?.doc_phone);
  const [isFirstDoctorDetails, setIsFirstDoctorDetails] = useState(isDr);

  const { branchList, doctorList } = onboarding;

  const { values, touched, errors, handleChange, handleBlur, setFieldValue } =
    formik;

  const drList = doctorListOption(doctorList);
  const isBranchDrShow = branchList.length > 0 && values.clinicBranch;

  const isOtherClinic = isOtherShowClinic({
    values,
  });

  const isShowDrInput =
    isOtherShowDoctor({
      values,
    }) || isOtherClinic;

  const isDrOtherFilled =
    values.medicName && values.medicLastName && !values.clinicName;

  useEffect(() => {
    dispatch(getDoctorList());
  }, []);

  const selectedDr = doctorList.find((dr) => {
    return (
      `${dr.drFirstName} ${dr.drLastName}` ===
      `${values.medicName} ${values.medicLastName}`
    );
  });

  const disableEmail =
    !isShowDrInput || selectedDr?.drEmail === values.medicEmail;
  const disablePhone =
    !isShowDrInput || selectedDr?.drPhone === values.medicPhone;

  // get clinic list based on doctor selection
  useEffect(() => {
    if (values?.clinicDr) {
      if (!isFirstDoctorDetails) {
        fillClinicValues({
          values,
          doctorList,
          errors,
          responseData,
        });
      } else {
        setIsFirstDoctorDetails(false);
      }
    }
  }, [values?.clinicDr]);

  const isVerifiedDoctor = doctorList?.some(
    (eachDoctor) =>
      eachDoctor.drFirstName === values.medicName &&
      eachDoctor.drLastName === values.medicLastName &&
      eachDoctor.drEmail === values.medicEmail &&
      eachDoctor.drPhone === values.medicPhone
  );

  return (
    <>
      {!isShowDrInput && (
        <Grid item xs={12} md={6}>
          <div className="dropdownFix">
            {!isShowDrInput &&
              !isOtherClinic &&
              !isBranchDrShow &&
              !isDrOtherFilled && (
                <DropdownMenu
                  label="Nombre(s) del doctor"
                  optionsObject={drList}
                  name="clinicDr"
                  handleChange={handleChange}
                  value={values["clinicDr"]}
                  onBlur={handleBlur}
                  errors={errors["clinicDr"]}
                  touched={touched["clinicDr"]}
                  disabled={hasNotDoctor || isBranchDrShow || disabled}
                />
              )}

            {isBranchDrShow && (
              <InputText
                stylesInput={css.formControl}
                label="Nombre(s) del doctor"
                value={values["clinicDr"]}
                name={"clinicDr"}
                handleChange={handleChange}
                errors={errors["clinicDr"]}
                touched={touched["clinicDr"]}
                handleBlur={handleBlur}
                disabled={hasNotDoctor || isBranchDrShow || disabled}
              />
            )}
          </div>
        </Grid>
      )}

      {isShowDrInput && (
        <>
          <Grid item md={6} xs={12}>
            <RenderTextInput
              label="Nombre(s) del doctor"
              value="medicName"
              disabled={
                hasNotDoctor || isBranchDrShow || disabled || isVerifiedDoctor
              }
              formik={formik}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <RenderTextInput
              label="Apellido(s) del doctor"
              value="medicLastName"
              disabled={
                hasNotDoctor || isBranchDrShow || disabled || isVerifiedDoctor
              }
              formik={formik}
            />
          </Grid>
        </>
      )}
      {/* {isShowDrInput && (
          <Grid item xs={12} md={6}>
            {renderInput({
              label: "Nombre(s) del doctor",
              value: "medicName",
              disabled: hasNotDoctor || isBranchDrShow,
            })}
          </Grid>
        )} */}

      <Grid item xs={12} md={6}>
        <InputText
          stylesInput={css.formControl}
          label="Correo electrónico del doctor"
          value={values["medicEmail"]}
          name={"medicEmail"}
          handleChange={handleChange}
          errors={errors["medicEmail"]}
          touched={touched["medicEmail"]}
          handleBlur={handleBlur}
          disabled={hasNotDoctor || isBranchDrShow || disabled || disableEmail}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <div className="telInputOld">
          <InputPhone
            stylesInput={css.formControl}
            label="Número de teléfono del consulto"
            country="mx"
            name="medicPhone"
            handleChange={(e) => {
              setFieldValue("medicPhone", e);
            }}
            handleBlur={() => formik.setFieldTouched("medicPhone", true)}
            value={values.medicPhone}
            errors={touched.medicPhone && errors.medicPhone}
            touched={touched.medicPhone}
            disabled={
              hasNotDoctor || isBranchDrShow || disabled || disablePhone
            }
          />
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        <DropdownMenu
          label="Relación con el médico"
          optionsObject={data.medicRelation}
          name="medicRelation"
          handleChange={handleChange}
          value={values["medicRelation"] || ""}
          onBlur={handleBlur}
          errors={errors["medicRelation"]}
          touched={touched["medicRelation"]}
          disabled={hasNotDoctor || disabled}
        />
      </Grid>
    </>
  );
};

export default RenderDoctorDetails;
