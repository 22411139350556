import { useState } from "react";
import { Box } from "@mui/material";
import Panel from "../../../../components/Panel";
import FormComponent from "../../StepperPage/StepperCard/PendingInformationV2/FormComponent";
import useStyles from "../../styles";
import FamilyModal from "./FamilyModal";
import KYCStepper from "./KYC";
import LoanDetails from "./LoanDetails";
import SigningContract from "./SigningContract";

export const STEPPER_NAME = {
  PATIENT_DETAILS: "Detalles de el paciente",
  PROCEDURE_DETAILS: "Información del Procedimiento",
  LOAN_DETAILS: "Detalles del Préstamo",
  KYC: "Validación de Indentidad",
  SIGNATURE: "Firma del Contrato",
};

const ProcedureCard = (props) => {
  const {
    selectLoanDetail,
    LmsBalanceSummary,
    loanDetailsStepper,
    StepperStatus,
    promoCreditLimit,
  } = props;

  const [procedureCost, setProcedureCost] = useState();
  const [openAccordionTitle, setOpenAccordionTitle] = useState(
    STEPPER_NAME.PATIENT_DETAILS
  );
  const { application_status } = StepperStatus;

  let firstThreeStepperStatus = false;
  firstThreeStepperStatus =
    StepperStatus.procedure_completed &&
    StepperStatus.loan_completed &&
    StepperStatus.kyc_completed;

  const css = useStyles();

  const onAccordionClick = (title) => {
    setOpenAccordionTitle(title);
  };
  return (
    <Box
      sx={(theme) => ({
        margin: "12px 44px",
        [theme.breakpoints.down("tablet")]: {
          margin: "12px 20px",
        },
      })}
    >
      <Panel
        status={StepperStatus.patientDetails || true}
        detailStatus=""
        title={STEPPER_NAME.PATIENT_DETAILS}
        open={openAccordionTitle === STEPPER_NAME.PATIENT_DETAILS}
        onAccordionClick={onAccordionClick}
        body={<FamilyModal setOpenAccordionTitle={setOpenAccordionTitle} />}
        openPanel
      />
      <Panel
        status={StepperStatus.procedure_completed}
        detailStatus={StepperStatus.procedure_status}
        title={STEPPER_NAME.PROCEDURE_DETAILS}
        open={openAccordionTitle === STEPPER_NAME.PROCEDURE_DETAILS}
        onAccordionClick={onAccordionClick}
        body={
          <FormComponent
            loanDetailsStepper={loanDetailsStepper}
            setProcedureCost={setProcedureCost}
            setOpenAccordionTitle={setOpenAccordionTitle}
          />
        }
      />
      <Panel
        status={StepperStatus.loan_completed}
        title={STEPPER_NAME.LOAN_DETAILS}
        open={openAccordionTitle === STEPPER_NAME.LOAN_DETAILS}
        onAccordionClick={onAccordionClick}
        detailStatus={StepperStatus.loan_status}
        body={
          <LoanDetails
            promoCreditLimit={promoCreditLimit}
            procedureCost={procedureCost}
            selectLoanDetail={selectLoanDetail}
            loanDetailsStepper={loanDetailsStepper}
            LmsBalanceSummary={LmsBalanceSummary}
            setOpenAccordionTitle={setOpenAccordionTitle}
          />
        }
      />
      <Panel
        status={StepperStatus.kyc_completed}
        title={STEPPER_NAME.KYC}
        open={openAccordionTitle === STEPPER_NAME.KYC}
        onAccordionClick={onAccordionClick}
        detailStatus={StepperStatus.kyc_status}
        body={<KYCStepper status={StepperStatus.kyc_completed} />}
      />

      <Panel
        firstThreeStepperStatus={firstThreeStepperStatus}
        status={StepperStatus.signature_completed}
        title={STEPPER_NAME.SIGNATURE}
        open={openAccordionTitle === STEPPER_NAME.SIGNATURE}
        onAccordionClick={onAccordionClick}
        body={<SigningContract />}
      />
    </Box>
  );
};

export default ProcedureCard;
