import { FC } from "react";
import clsx from "clsx";
import CircularProgress from "@mui/material/CircularProgress";

import useStyles from "./styles";

interface ILoader {
  className?: string;
}

const AppCircularLoader: FC<ILoader> = ({ className }) => {
  const css = useStyles();

  return (
    <div
      className={clsx(css.container, className)}
      data-testid="circularLoader"
    >
      <CircularProgress className={css.spinner} size={100} thickness={10} />
    </div>
  );
};

export default AppCircularLoader;
