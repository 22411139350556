import Header from "../../../components/header";
import SideBar from "../../../components/sidebar";
import TwoColumns from "../../../components/twoColumns";
import useStyles from "../ApplicationStatus/styles";

import CheckIcon from "@mui/icons-material/Check";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DeleteConfirmation from "../../../components/ModalContents/DeleteConfirmation";
import ModalComponent from "../../../components/sharedComponents/ModalComponent";
import { ALL_APPLCIATION } from "../../../constants/common";
import { ROUTES } from "../../../constants/routes";
import { useAppDispatch } from "../../../store";
import { selectAllApplication } from "../../../store/slices/users";
import { getAllApplication } from "../../../store/slices/users/actions";
import { URBANIST_FONT } from "../../../constants/common";

const AllApplication = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [application_id, setAppId] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [promoCreditLimit, setPromoCreditLimit] = React.useState();
  const [showSidebar, setShowSideBar] = React.useState(false);

  const handleOpen = (id) => {
    setAppId(id);
    setOpen(!open);
  };

  React.useEffect(() => {
    dispatch(getAllApplication());
  }, []);

  const AllApplication = useSelector(selectAllApplication);
  const css = useStyles();

  const handleResume = (id) => {
    history.push(`${ROUTES.stepper}/?${id}`);
  };
  return (
    <TwoColumns
      leftSize={2.5}
      rightSize={9.5}
      leftContentComp={
        <SideBar showSidebar={showSidebar} setShowSideBar={setShowSideBar} />
      }
      leftRowPadless={true}
      rightRowClassName={css.rightContainer}
      rightContentComp={
        <>
          <Box>
            <Header
              isBorderBottom={false}
              setPromoCreditLimit={setPromoCreditLimit}
              setShowSideBar={setShowSideBar}
            />
          </Box>

          <Box
            sx={(theme) => ({
              color: "#6B7779",
              fontSize: "24px",
              fontFamily: "SFUIText-bold",
              marginTop: "19px",
              marginLeft: "44px",
              marginBottom: "33px",
              [theme.breakpoints.down("lg")]: {
                fontSize: "18px",
                fontFamily: URBANIST_FONT.BOLD,
                marginLeft: "95px",
                marginTop: "1.5rem",
              },
              [theme.breakpoints.down("tablet")]: { ml: "35px", mb: 0 },
            })}
          >
            ESTADO DE SOLICITUD DE PRÉSTAMO
          </Box>

          {AllApplication?.msg?.map((each, index) => (
            <Box
              sx={(theme) => ({
                background: "#FFFFFF",
                borderRadius: "20px",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                padding: "10px 20px",
                marginBottom: "30px",
                marginLeft: "44px",
                marginRight: "44px",
                [theme.breakpoints.down("md")]: {
                  width: "70%",
                  margin: "0px 0px 2rem 95px",
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  padding: "2rem",
                },
                [theme.breakpoints.down("tablet")]: {
                  width: "90%",
                  margin: "1rem auto",
                  padding: "1rem 1.5rem",
                },
              })}
            >
              <Grid container rowSpacing={4} display="flex" alignItems="center">
                <Grid item xs={12} md={4}>
                  <Box
                    sx={(theme) => ({
                      "& span": {
                        fontFamily: "SFUIText-bold",
                        [theme.breakpoints.down("lg")]: {
                          fontFamily: "Urbanist-Semibold",
                        },
                        [theme.breakpoints.down("tablet")]: {
                          fontSize: "14px",
                        },
                      },
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Medium",
                      },
                      [theme.breakpoints.down("tablet")]: {
                        fontSize: "14px",
                      },
                    })}
                  >
                    <span>ID de la Solicitud:</span> {each.application_id}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      mt: "10px",
                      "& span": {
                        fontFamily: "SFUIText-bold",
                        [theme.breakpoints.down("lg")]: {
                          fontFamily: "Urbanist-Semibold",
                        },
                        [theme.breakpoints.down("tablet")]: {
                          fontSize: "14px",
                        },
                      },
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Medium",
                      },
                      [theme.breakpoints.down("tablet")]: {
                        mt: "5px",
                        fontSize: "14px",
                      },
                    })}
                  >
                    <span>Monto Solicitado:</span>{" "}
                    {each.req_amount.toFixed(2).toLocaleString()}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      mt: "10px",
                      "& span": {
                        fontFamily: "SFUIText-bold",
                        [theme.breakpoints.down("lg")]: {
                          fontFamily: "Urbanist-Semibold",
                        },
                        [theme.breakpoints.down("tablet")]: {
                          fontSize: "14px",
                        },
                      },
                      [theme.breakpoints.down("lg")]: {
                        fontFamily: "Urbanist-Medium",
                      },
                      [theme.breakpoints.down("tablet")]: {
                        mt: "5px",
                        fontSize: "14px",
                      },
                    })}
                  >
                    <span>Fecha de Solicitud:</span>{" "}
                    {moment(each.created_at).format("MMMM DD, YYYY")}
                    {!each.created_at && "Not Available"}
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5}
                  // style={{ width: 360, margin: '0 auto', paddingLeft: 12 }}
                  sx={(theme) => ({
                    margin: "0 auto",
                    width: "360",
                    paddingLeft: "12px",
                    [theme.breakpoints.down("md")]: {
                      pt: "20px !important",
                    },
                    [theme.breakpoints.down("tablet")]: { pl: 0 },
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      [theme.breakpoints.down("lg")]: {
                        fontSize: "17px",
                        fontFamily: "Urbanist-Bold",
                        "& span": { fontFamily: "Urbanist-Bold" },
                      },
                      [theme.breakpoints.down("tablet")]: { fontSize: "15px" },
                    })}
                  >
                    Estatus de la Solicitud:{" "}
                    {each.status === "APPROVED" && (
                      <span
                        style={{
                          color: "#128807",
                        }}
                      >
                        {ALL_APPLCIATION[each.status]}
                      </span>
                    )}
                    {each.status === "DISBURSED" && (
                      <span
                        style={{
                          color: "#128807",
                        }}
                      >
                        {ALL_APPLCIATION[each.status]}
                      </span>
                    )}
                    {each.status === "SUBMITTED" && (
                      <span
                        style={{
                          color: "#128807",
                        }}
                      >
                        {ALL_APPLCIATION[each.status]}
                      </span>
                    )}
                    {each.status === "PENDING" && (
                      <span
                        style={{
                          color: "#CD9C00",
                        }}
                      >
                        {ALL_APPLCIATION[each.status]}
                      </span>
                    )}
                    {each.status === "Deleted" && (
                      <span
                        style={{
                          color: "#E92736",
                        }}
                      >
                        {ALL_APPLCIATION[each.status]}
                      </span>
                    )}
                  </Box>
                  <Box
                    sx={(theme) => ({
                      [theme.breakpoints.down("lg")]: {
                        padding: "0 2rem",
                      },
                      [theme.breakpoints.down("tablet")]: {
                        padding: "0 10px",
                      },
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        alignItems: "center",
                        marginTop: "20px",
                        [theme.breakpoints.down("lg")]: { mt: "10px" },
                        [theme.breakpoints.down("md")]: { mt: "20px" },
                      })}
                    >
                      <div className={css.stepperCircle}>
                        <div className={css.stepperCircleActive}>
                          <CheckIcon style={{ fontSize: 8 }} />
                        </div>
                      </div>
                      <div
                        style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                      />

                      {each.procedure_completed ? (
                        <div className={css.stepperCircle}>
                          <div className={css.stepperCircleActive}>
                            <CheckIcon style={{ fontSize: 8 }} />
                          </div>
                        </div>
                      ) : (
                        <div className={css.stepperCircleInActive}>
                          <div className={css.stepperCircleInside}>.</div>
                        </div>
                      )}

                      <div
                        style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                      />
                      {each.loan_completed ? (
                        <div className={css.stepperCircle}>
                          <div className={css.stepperCircleActive}>
                            <CheckIcon style={{ fontSize: 8 }} />
                          </div>
                        </div>
                      ) : (
                        <div className={css.stepperCircleInActive}>
                          <div className={css.stepperCircleInside}>.</div>
                        </div>
                      )}

                      <div
                        style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                      />
                      {each.kyc_completed ? (
                        <div className={css.stepperCircle}>
                          <div className={css.stepperCircleActive}>
                            <CheckIcon style={{ fontSize: 8 }} />
                          </div>
                        </div>
                      ) : (
                        <div className={css.stepperCircleInActive}>
                          <div className={css.stepperCircleInside}>.</div>
                        </div>
                      )}

                      <div
                        style={{ flex: 1, height: 5, background: "#D9D9D9" }}
                      />
                      {each.signature_completed ? (
                        <div className={css.stepperCircle}>
                          <div className={css.stepperCircleActive}>
                            <CheckIcon style={{ fontSize: 8 }} />
                          </div>
                        </div>
                      ) : (
                        <div className={css.stepperCircleInActive}>
                          <div className={css.stepperCircleInside}>.</div>
                        </div>
                      )}
                    </Box>

                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        margin: "5px -30px",
                        fontSize: "12px",
                        [theme.breakpoints.down("lg")]: {
                          fontFamily: "Urbanist-Medium",
                          fontSize: "12px",
                        },
                        [theme.breakpoints.down("tablet")]: {
                          fontSize: "10px",
                        },
                      })}
                    >
                      <div
                        style={{
                          maxWidth: 100,
                          color: "#3552CC",
                          textAlign: "center",
                        }}
                      >
                        Detalles del Paciente
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          maxWidth: 100,
                          color: "#3552CC",
                          textAlign: "center",
                        }}
                      >
                        Información del Procedimiento
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          maxWidth: 100,
                          color: "#3552CC",
                          textAlign: "center",
                        }}
                      >
                        Detalles del Préstamo
                      </div>

                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          maxWidth: 100,
                          color: "#3552CC",
                          textAlign: "center",
                        }}
                      >
                        Validación de Indentidad
                      </div>
                      <div style={{ flex: 1 }} />
                      <div
                        style={{
                          maxWidth: 100,
                          color: "#3552CC",
                          textAlign: "center",
                        }}
                      >
                        Firma del Contrato
                      </div>
                    </Box>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  sx={(theme) => ({
                    mt: "10px",
                    [theme.breakpoints.down("md")]: {
                      pt: "10px !important",
                    },
                  })}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {each.status === "DISBURSED" ? (
                      <>
                        <div style={{ marginTop: 5 }}>
                          <button
                            style={{
                              background: "#FFFFFF",
                              borderRadius: 10,
                              padding: " 8px 40px",
                              color: "#FFFFFF",
                              cursor: "default",
                              disable: true,
                            }}
                          >
                            Resume
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {each.deleted ? (
                          <></>
                        ) : (
                          <>
                            {each.procedure_completed &&
                            each.loan_completed &&
                            each.kyc_completed &&
                            each.signature_completed ? (
                              <div>
                                <button
                                  style={{
                                    pointer:
                                      each.status === "DISBURSED" &&
                                      "not-allowed",
                                  }}
                                  className={css.continueButton}
                                  onClick={() => {
                                    each.status !== "DISBURSED" &&
                                      handleResume(each.application_id);
                                  }}
                                >
                                  Editar
                                </button>
                              </div>
                            ) : (
                              <div style={{ marginTop: 5 }}>
                                <button
                                  className={css.continueButton}
                                  onClick={() => {
                                    handleResume(each.application_id);
                                  }}
                                >
                                  Continuar
                                </button>
                              </div>
                            )}

                            <Box
                              sx={(theme) => ({
                                marginTop: "5px",
                                textDecoration: "underline",
                                cursor:
                                  each.status === "DISBURSED"
                                    ? "not-allowed"
                                    : "pointer",
                                color: "#3552CC",
                                fontFamily: "SFUIText-bold",
                                textAlign: "center",
                                [theme.breakpoints.down("lg")]: {
                                  fontFamily: "Urbanist-Medium",
                                  fontSize: "14px",
                                  textDecoration: "none",
                                  marginTop: "14px",
                                },
                              })}
                              onClick={() => {
                                each?.status !== "DISBURSED" &&
                                  handleOpen(each.application_id);
                              }}
                            >
                              Borrar
                            </Box>
                          </>
                        )}
                      </>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))}

          <ModalComponent
            open={open}
            handleOpen={handleOpen}
            body={
              <DeleteConfirmation
                setOpen={setOpen}
                application_id={application_id}
                redirect={false}
              />
            }
            hideBackdrop={false}
            onBackdropClick={() => setOpen(false)}
          />
        </>
      }
      rightBg
    />
  );
};

export default AllApplication;
