import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { customColor } from "../../../theme/customColor";

const useStyles = makeStyles((theme: Theme) => ({
  textCurpQuery: {
    fontFamily: "Roboto-Regular",
    color: customColor.shark,
    fontSize: 14,
    [theme.breakpoints.down(601)]: {
      fontSize: 13,
      fontWeight: 700,
      fontFamily: "Roboto-Bold",
    },
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  title: {
    color: customColor.shark,
    fontSize: 22,
    fontFamily: "SFUIText-Bold",
  },
  input: {
    width: 436,
    borderRadius: 10,
    [theme.breakpoints.down(601)]: {
      maxWidth: 350,
    },
    [theme.breakpoints.down(401)]: {
      maxWidth: 300,
    },
    [theme.breakpoints.down(321)]: {
      maxWidth: 220,
    },
  },
  inputProps: {
    textTransform: "uppercase",
    textAlign: "center",
  },
}));

export default useStyles;
