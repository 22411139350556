import { makeStyles } from "@mui/styles";
import desktop_bg from "../../../assets/SplashSqueeze/SqueezePage_bg_gradient_dk.svg";
import tablet_bg from "../../../assets/SplashSqueeze/SqueezePage_bg_tb.svg";

const SplashSqueezeStyle = makeStyles((theme) => ({
  mainContainer: {
    minHeight: "100vh",
    backgroundImage: `url(${desktop_bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down(1001)]: {
      backgroundImage: ` url(${tablet_bg})`,
    },
  },
}));

export default SplashSqueezeStyle;
