import axios from "axios";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import {
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import moment from "moment";
import { downloadFile } from "../../utils/validations";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { Headers } from "./headers";

const { REACT_APP_METTA_URL } = process.env;

const Report = () => {
  const history = useHistory();
  // async function getImageFromAws(imageName) {
  //   let url = `${GENERAL_CONSTANTS.URL_USERS_INFO}/download/${imageName}`;
  //   const response = await axios.get(url);
  //   return response.data;
  // }
  // const getImageFromAws = (imageName) => {

  //   return axios
  //     .get(url)
  //     .then((res) => res.data.data);
  // };

  if (!localStorage.getItem("loginReport")) {
    history.push(ROUTES.logInReport);
  }

  const downloadImage = (fileName) => {
    /* let url = `${GENERAL_CONSTANTS.URL_USERS_INFO}/download/${fileName}`; */
    let url = `https://${REACT_APP_METTA_URL}/user-information/download/${fileName}`;
    /* const response = */ axios.get(url).then((res) => {
      downloadFile(fileName, res.data.data);
    });
  };

  const [usersData, setUsersData] = useState([]);

  useEffect(() => {
    axios
      .get(`https://${REACT_APP_METTA_URL}/users-information`)
      .then((res) => {
        setUsersData(res.data.lista);
      });
  }, []);

  const sortOrder = (data) => {
    return data.sort((a, b) => {
      if (a.createdOn < b.createdOn) {
        return -1;
      }

      if (a.createdOn > b.createdOn) {
        return 1;
      }

      return 0;
    });
  };

  const users = sortOrder(usersData);

  const formatDate = (date) => {
    let fecha = new Date(date);
    let day = fecha.getDate();
    let month = fecha.getMonth() + 1;
    let year = fecha.getFullYear();
    if (month < 10) {
      return `${day}-0${month}-${year}`;
    } else {
      return `${day}-${month}-${year}`;
    }
  };

  const formatHour = (hours) => {
    let now = moment(hours, "HHmm").format("LT");
    return now;
  };
  const logout = () => {
    localStorage.removeItem("loginReport");
    history.push(ROUTES.logInReport);
  };

  return (
    <Fragment>
      <Typography
        variant="h5"
        align="center"
        style={{ marginTop: 10, marginBottom: 10 }}
      >
        REPORTE DE USUARIOS
      </Typography>

      <Button
        variant="contained"
        color="primary"
        style={{ marginLeft: 25 }}
        onClick={logout}
      >
        Cerrar Sesion{" "}
      </Button>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="tabla-usuarios">
          <TableHead>
            <TableRow>
              {Headers.map((header, indice) => (
                <TableCell
                  key={indice}
                  style={{ fontSize: 24, fontWeigth: 900, align: "center" }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={user.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {user.data.processStatus ? (
                    user.data.processStatus.statusDetail ? (
                      user.data.processStatus.statusDetail === "completed" ? (
                        <div
                          style={{
                            backgroundColor: "green",
                            color: "white",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          <Typography h5 align="center">
                            Compleado
                          </Typography>
                        </div>
                      ) : user.data.processStatus.statusDetail === "pending" ? (
                        <div
                          style={{
                            backgroundColor: "orange",
                            color: "white",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          <Typography h5 align="center">
                            Pendiente
                          </Typography>
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            padding: 10,
                            borderRadius: 10,
                          }}
                        >
                          <Typography h5 align="center">
                            No Compleado
                          </Typography>
                        </div>
                      )
                    ) : (
                      "-"
                    )
                  ) : (
                    <div
                      style={{
                        backgroundColor: "red",
                        color: "white",
                        padding: 10,
                        borderRadius: 10,
                      }}
                    >
                      <Typography component="h5" align="center">
                        No Compleado
                      </Typography>
                    </div>
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {user.data.corresponsibleData
                    ? user.data.corresponsibleData.name
                      ? user.data.corresponsibleData.name
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell>
                  {user.data.corresponsibleData
                    ? user.data.corresponsibleData.paternalSurname
                      ? user.data.corresponsibleData.paternalSurname
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.corresponsibleData
                    ? user.data.corresponsibleData.maternalSurname
                      ? user.data.corresponsibleData.maternalSurname
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.corresponsibleData
                    ? user.data.corresponsibleData.phoneNumber
                      ? user.data.corresponsibleData.phoneNumber
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.corresponsibleData ? (
                    user.data.corresponsibleData.createOn ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.corresponsibleData.createOn)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell align="center">
                  {user.data.userInformation
                    ? user.data.userInformation.email
                      ? user.data.userInformation.email
                      : "-"
                    : "-"}
                </TableCell>
                {/* <TableCell align="center">
                  {user.data.usuario
                    ? user.data.usuario.contrasena
                      ? user.data.usuario.contrasena
                      : "-"
                    : "-"}
                </TableCell> */}
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.userInformation ? (
                    user.data.userInformation.createOn ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.userInformation.createOn)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>

                {/* Procedure Data */}

                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.medicName
                      ? user.data.procedureData.medicName
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.medicLastName
                      ? user.data.procedureData.medicLastName
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.medicRelation
                      ? user.data.procedureData.medicRelation
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.appointmentDate
                      ? formatDate(user.data.procedureData.appointmentDate)
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.appointmentTime
                      ? formatHour(user.data.procedureData.appointmentTime)
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.procType
                      ? user.data.procedureData.procType
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.procToDo
                      ? user.data.procedureData.procToDo.join()
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.otherProc
                      ? user.data.procedureData.otherProc
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.procedureCost
                      ? user.data.procedureData.procedureCost
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.procedureData
                    ? user.data.procedureData.requestedAmount
                      ? user.data.procedureData.requestedAmount
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.procedureData ? (
                    user.data.procedureData.createOn ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.procedureData.createOn)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>

                {/* termsAndServicesAuth */}

                <TableCell align="center">
                  {user.data.termsAndServicesAuth
                    ? user.data.termsAndServicesAuth.acceptedNoticeOfPrivacy
                      ? "Si aceptó"
                      : "No aceptó"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.termsAndServicesAuth
                    ? user.data.termsAndServicesAuth.acceptedGeolocalization
                      ? "Si aceptó"
                      : "No aceptó"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.termsAndServicesAuth
                    ? user.data.termsAndServicesAuth.acceptedTermsAndConditions
                      ? "Si aceptó"
                      : "No aceptó"
                    : "-"}
                </TableCell>

                {/* financialAlternativeInformation */}
                <TableCell align="center">
                  {user.data.financialAlternativeInformation
                    ? user.data.financialAlternativeInformation
                        .monthlyInstallments
                      ? user.data.financialAlternativeInformation
                          .monthlyInstallments
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.financialAlternativeInformation
                    ? user.data.financialAlternativeInformation.monthlyPayment
                      ? user.data.financialAlternativeInformation.monthlyPayment
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.financialAlternativeInformation ? (
                    user.data.financialAlternativeInformation.createOn ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(
                          user.data.financialAlternativeInformation.createOn
                        )}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>

                {/* rfcAndCurp */}

                <TableCell align="center">
                  {user.data.rfcAndCurp
                    ? user.data.rfcAndCurp.curp
                      ? user.data.rfcAndCurp.curp
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.rfcAndCurp
                    ? user.data.rfcAndCurp.rfc
                      ? user.data.rfcAndCurp.rfc
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.rfcAndCurp ? (
                    user.data.rfcAndCurp.createOn ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.rfcAndCurp.createOn)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>

                {/* personalInformation */}
                {/* <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.lugarNacimiento
                      ? user.data.personalInformation.lugarNacimiento
                      : "-"
                    : "-"}
                </TableCell> */}
                <TableCell align="center">
                  {" "}
                  {user.data.personalInformation
                    ? user.data.personalInformation.gender
                      ? user.data.personalInformation.gender
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.dateOfBirth
                      ? formatDate(user.data.personalInformation.dateOfBirth)
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.street
                      ? user.data.personalInformation.street
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.internalNumber
                      ? user.data.personalInformation.internalNumber
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.externalNumber
                      ? user.data.personalInformation.externalNumber
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.municipality
                      ? user.data.personalInformation.municipality
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.suburb
                      ? user.data.personalInformation.suburb
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.zip
                      ? user.data.personalInformation.zip
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.personalInformation
                    ? user.data.personalInformation.state
                      ? user.data.personalInformation.state
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.personalInformation ? (
                    user.data.personalInformation.createOn ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.personalInformation.createOn)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>

                {/* knowYouBetter */}
                <TableCell align="center">
                  {user.data.knowYouBetter
                    ? user.data.knowYouBetter.occupation
                      ? user.data.knowYouBetter.occupation
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.knowYouBetter
                    ? user.data.knowYouBetter.otraOcupacion
                      ? user.data.knowYouBetter.otraOcupacion
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.knowYouBetter
                    ? user.data.knowYouBetter.companyName
                      ? user.data.knowYouBetter.companyName
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.knowYouBetter
                    ? user.data.knowYouBetter.payer
                      ? user.data.knowYouBetter.payer
                      : "-"
                    : "-"}
                </TableCell>

                {/* cosignerInformation */}
                <TableCell align="center">
                  {user.data.cosignerInformation
                    ? user.data.cosignerInformation.name
                      ? user.data.cosignerInformation.name
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.cosignerInformation
                    ? user.data.cosignerInformation.lastName
                      ? user.data.cosignerInformation.lastName
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.cosignerInformation
                    ? user.data.cosignerInformation.mothersLastName
                      ? user.data.cosignerInformation.mothersLastName
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.cosignerInformation
                    ? user.data.cosignerInformation.cellphoneNumber
                      ? user.data.cosignerInformation.cellphoneNumber
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.cosignerInformation
                    ? user.data.cosignerInformation.email
                      ? user.data.cosignerInformation.email
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {" "}
                  {user.data.cosignerInformation
                    ? user.data.cosignerInformation.monthlyIncome
                      ? user.data.cosignerInformation.monthlyIncome
                      : user.data.knowYouBetter.monthlyIncome
                    : "-"}
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.cosignerInformation ? (
                    user.data.cosignerInformation.creado ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.cosignerInformation.creado)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell> */}

                {/* credithistory */}
                <TableCell align="center">
                  {user.data.credithistory
                    ? user.data.credithistory.bankName
                      ? user.data.credithistory.bankName
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.credithistory
                    ? user.data.credithistory.accountType
                      ? user.data.credithistory.accountType
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.credithistory
                    ? user.data.credithistory.user
                      ? user.data.credithistory.user
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.credithistory
                    ? user.data.credithistory.verifyCreditHistoryAuth
                      ? "Si"
                      : "No"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.credithistory
                    ? user.data.credithistory.accessToBankAccountAuth
                      ? "Si"
                      : "No"
                    : "-"}
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.credithistory ? (
                    user.data.credithistory.creado ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.credithistory.creado)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell> */}

                {/* identityInformation */}
                <TableCell align="center">
                  {user.data.identityInformation
                    ? user.data.identityInformation.typeId
                      ? user.data.identityInformation.typeId
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.identityInformation ? (
                    user.data.identityInformation.frontFileName ? (
                      <div
                        onClick={() =>
                          downloadImage(
                            user.data.identityInformation.frontFileName
                          )
                        }
                      >
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Descargar
                        </Typography>
                      </div>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell align="center">
                  {user.data.identityInformation ? (
                    user.data.identityInformation.rearFileName ? (
                      <div
                        onClick={() =>
                          downloadImage(
                            user.data.identityInformation.rearFileName
                          )
                        }
                      >
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          Descargar
                        </Typography>
                      </div>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.identityInformation ? (
                    user.data.identityInformation.creado ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.identityInformation.creado)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell> */}

                {/* clientSignature */}

                <TableCell align="center">
                  {user.data.clientSignature
                    ? user.data.clientSignature.directDebitAuth
                      ? "Si autorizo"
                      : "No autorizo"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.clientSignature
                    ? user.data.clientSignature.paymenthPreference
                      ? user.data.clientSignature.paymenthPreference
                      : "-"
                    : "-"}
                </TableCell>
                <TableCell align="center">
                  {user.data.clientSignature ? (
                    user.data.clientSignature.signature ? (
                      <div
                        onClick={() =>
                          downloadImage(user.data.clientSignature.signature)
                        }
                      >
                        <Typography
                          style={{ color: "blue", cursor: "pointer" }}
                        >
                          {" "}
                          Descargar{" "}
                        </Typography>
                      </div>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell>
                {/* <TableCell
                  align="center"
                  style={{ backgroundColor: "#ececa3" }}
                >
                  {user.data.clientSignature ? (
                    user.data.clientSignature.creado ? (
                      <Typography style={{ fontSize: 12, fontWeight: 800 }}>
                        {formatDate(user.data.clientSignature.creado)}
                      </Typography>
                    ) : (
                      "-"
                    )
                  ) : (
                    "-"
                  )}
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

export default Report;
