import { FC } from "react";
import { Box } from "@mui/material";

import downArrow from "../../../assets/faqs/down-arrow.svg";
import nextArrow from "../../../assets/faqs/next-arrow.svg";

import useStyle from "./styles";

export interface ButtonLeftProps {
  isSelected: boolean;
  title: any;
  onClick: any;
}

export const ButtonLeft: FC<ButtonLeftProps> = ({
  isSelected,
  title,
  onClick,
}) => {
  const css = useStyle();

  return (
    <button
      className={`${css.select} ${isSelected ? css.buttonSelected : ""}`}
      onClick={onClick}
    >
      <Box>{title}</Box>
      <img src={isSelected ? downArrow : nextArrow} />
    </button>
  );
};

export default ButtonLeft;
