import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { URBANIST_FONT } from "../../constants/common";
import { customTheme } from "../../features/installmentLoan/theme";

const useStyles = makeStyles((theme: Theme) => ({
  loginMainContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    background: "#002539",
  },
  loginLogoCont: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: customTheme.cotton,
  },
  headerTitle: {
    marginTop: "10px !important",
    fontFamily: `${URBANIST_FONT.SEMIBOLD} !important`,
    // fontWeight: 600,
    [theme.breakpoints.up("sm")]: {
      fontSize: "40px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px !important",
    },
  },
  headerSubtitle: {
    marginBottom: "22px !important",
    fontWeight: "normal",
    fontFamily: `${URBANIST_FONT.REGULAR} !important`,
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      fontSize: "22px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
  },
  textInputIconStyle: {
    color: customTheme.cotton,
  },
  iconWrapperStyle: {
    background: "linear-gradient(180deg, #1B63DB 0%, #0ACC97 100%)",
  },
  phoneIconContainer: {
    background: "#00B4BE",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    height: "40px",
    width: "40px",
    minWidth: "40px",
    borderRadius: "360px",
    marginRight: "18px",
  },
  phoneIconStyle: {
    color: customTheme.cotton,
    height: 21,
    width: 24,
  },
  input: {
    marginTop: "5px !important",
    marginBottom: "20px !important",
    // width: 390,
  },
  inputText: {
    [theme.breakpoints.down(440)]: {
      minWidth: "80vw",
      width: "80vw",
    },
  },
  loginButtonStyle: {
    backgroundImage: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
    marginTop: "20px !important",
    padding: "18px 42px !important",
    fontSize: "16px !important",
    fontFamily: `${URBANIST_FONT.BOLD} !important`,
    borderRadius: "100px !important",
    "&:disabled": {
      background: "#999 !important",
      color: "#fff !important",
      opacity: 0.5,
    },
  },
  otpLoginButtonStyle: {
    backgroundImage: "linear-gradient(270deg, #1B63DB 1.32%, #0ACC97 99.08%)",
    marginTop: "-20px !important",
    padding: "18px 42px !important",
    fontSize: "16px !important",
    fontFamily: `${URBANIST_FONT.BOLD} !important`,
    borderRadius: "100px !important",
    "&:disabled": {
      background: "#999 !important",
      color: "#fff !important",
      opacity: 0.5,
    },
  },
  loginButtonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 52,
  },
  signupSentenceStyle: {
    fontWeight: "normal",
    fontSize: "16px !important",
    color: customTheme.cotton,
    marginTop: "12px !important",
    textAlign: "center",
  },
  signupTextStyle: {
    color: "#0ACC97 !important",
    textTransform: "none !important" as "none",
    padding: "0px 0px 4px !important",
    fontWeight: "600 !important",
    fontSize: "16px !important",
    fontFamily: "SFUIText-Bold !important",
  },
  forgotPasswordTextStyle: {
    color: "#fff !important",
    textTransform: "none !important" as "none",
    padding: "0px 0px 4px !important",
    fontWeight: "600 !important",
    fontSize: "18px !important",
    fontFamily: `${URBANIST_FONT.MEDIUM} !important`,
    marginTop: "12px !important",
  },
  // footerContainer: {
  //   width: "38vw",
  //   marginTop: "28px",
  //   [theme.breakpoints.down(1400)]: {
  //     width: "35vw",
  //   },
  //   [theme.breakpoints.down(1200)]: {
  //     width: "40vw",
  //   },
  //   [theme.breakpoints.down(900)]: {
  //     width: "100%",
  //   },
  // },
  footerTextStyle: {
    fontFamily: "SFUIText-Regular !important",
    color: "#99BADE !important",
    textTransform: "none !important" as "none",
    padding: "0px !important",
    fontWeight: "400 !important",
    fontSize: "18px !important",
    lineHeight: "1 !important",
  },

  passwordAlternativeText: {
    width: "410px",
    minWidth: "35ch",
    marginTop: "-15px",

    fontFamily: URBANIST_FONT.BOLD,
    fontSize: 16,
    color: "#fff",
    textAlign: "right",
    cursor: "pointer",
    [theme.breakpoints.down(440)]: {
      width: "auto",
      minWidth: "unset",
    },
  },
  otpAlternativeText: {
    width: "410px",
    textAlign: "center",
    fontFamily: URBANIST_FONT.BOLD,
    fontSize: 16,
    color: "#fff",
    cursor: "pointer",
    margin: "10px 0",
    [theme.breakpoints.down(440)]: {
      width: "auto",
      minWidth: "unset",
    },
  },
  timerText: {
    fontFamily: URBANIST_FONT.SEMIBOLD,
    fontSize: 16,
    lineHeight: "140%",
    display: "flex",
    // alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.2px",
    color: "#fff",
    justifyContent: "space-between",
    width: "100%",
    textShadow: "4px 8px 24px rgba(36, 107, 253, 0.25)",
    "& .timer": {
      fontFamily: URBANIST_FONT.BOLD,
      marginLeft: 4,
    },
    "& .resend": {
      fontFamily: URBANIST_FONT.BOLD,
      marginLeft: 4,
      cursor: "pointer",
      textDecorationLine: "underline",
    },
  },
  mettaGradientLogo: {
    padding: "19px 0",
  },
}));

export default useStyles;
