import { createStyles, makeStyles } from "@mui/styles";
import { customColor } from "../../theme/customColor";

export const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: "100vh",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "transparent",
      position: "fixed",
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    spinner: {
      color: customColor.pine,
    },
  })
);

export default useStyles;

