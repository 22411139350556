import { FC, MouseEvent } from "react";

import confirmationBg from "../../assets/Icons/success-email.svg";
import ConfirmationView from "../../components/ConfirmationView";

export interface AlreadyVerifiedProps {
  handleClick?: (e: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
}

const AlreadyVerified: FC<AlreadyVerifiedProps> = ({ handleClick }) => {
  return (
    <ConfirmationView
      title="Correo Verificado"
      content={
        <span>
          Gracias por tu apoyo. Tu correo electrónico ya ha sido verificado
          anteriormente.
          <br />
          Puedes ingresar nuevamente a la página de inicio
        </span>
      }
      imgSrc={confirmationBg}
      btn={{
        label: "Ir a página de inicio",
        onClick: handleClick,
      }}
    />
  );
};

export default AlreadyVerified;
