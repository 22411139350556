import { CapitalizeFirstLetter } from "../../utils/stringHelper";
import { TFunction } from "i18next";
import { formattedLocalDateWithDay } from "../../../../utils/dateHelper";
import { IFlatListItem } from "../../components/FlatList/flatList";
import { ILTxnDetails } from "../../store/installmentLoan";

export interface ItemsType {
  t: TFunction;
  itemValue?: ILTxnDetails | null;
}

const items = (data: ItemsType): IFlatListItem[] => {
  const { t, itemValue } = data;
  
  return [
    {
      title: "Estado de las transacciones",
      subtitle: itemValue?.txnStatus === "Successful" ? "Pagado " : "Pendiente",
      gridSize: 12,
    },
    {
      title: t("instantLineCreditTS.installmentLoan.locReceipt.contractId"),
      subtitle: itemValue?.id,
      gridSize: 12,
    },
    {
      title: t("instantLineCreditTS.installmentLoan.locReceipt.date"),
      subtitle: CapitalizeFirstLetter(
        formattedLocalDateWithDay(itemValue?.date)
      ),
      gridSize: 12,
    },
    {
      title: "Tipo de transacción",
      subtitle: itemValue?.txnType,
      gridSize: 12,
    },
    {
      title: "IVA pagada",
      subtitle: "$" + " " + `${itemValue?.vatAmtPaid.toString()}`,
      gridSize: 12,
    },
  ];
};

export default items;
