import { Modal, Fade } from "@mui/material";
import useStyles from "./styles";

const AlertComponent = (props) => {
  const {
    handleClose,
    open,
    body,
    hideBackdrop = true,
    onBackdropClick,
  } = props;
  const css = useStyles();

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="title-modal"
      aria-describedby="simple-modal-description"
      className={css.modal}
      closeAfterTransition
      hideBackdrop={hideBackdrop}
      onBackdropClick={onBackdropClick || null}
    >
      <Fade in={open}>
        <div className={css.body}>{body}</div>
      </Fade>
    </Modal>
  );
};

export default AlertComponent;
