import { FC } from "react";

import { IconProps, obsidian } from "./types";

const MailIcon: FC<IconProps> = ({ defaultColor, ...props }) => {
  return (
    <svg
      width="80"
      height="64"
      viewBox="0 0 80 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M72 0H8C3.6 0 0.04 3.6 0.04 8L0 56C0 60.4 3.6 64 8 64H72C76.4 64 80 60.4 80 56V8C80 3.6 76.4 0 72 0ZM72 56H8V16L40 36L72 16V56ZM40 28L8 8H72L40 28Z"
        fill={defaultColor ? props.fill : "currentColor"}
      />
    </svg>
  );
};

MailIcon.defaultProps = {
  fill: obsidian,
};

export default MailIcon;
