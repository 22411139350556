import { FC } from "react";
import { Box } from "@mui/system";

import { getProcedureName } from "../../../store/helpers";
import { convertDateTimeToLocal } from "../../../utils/dateHelper";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { URBANIST_FONT } from "../../../constants/common";
import customColor from "../../../theme/customColor";

interface DoctorDetailsProps {
  doctorDetails?: any;
}

const DoctorDetails: FC<DoctorDetailsProps> = ({ doctorDetails }) => {
  const css = useStyles();
  if (doctorDetails) {
    const listItem: any = [];
    const {
      appointment_time,
      appointment_date,
      first_name,
      last_name,
      medical_speciality,
      procedure_type,
      procedure_to_perform,
      other_procedure,
    } = doctorDetails;

    if (first_name && last_name) {
      listItem.push({
        label: "Nombre del paciente",
        value: `${first_name} ${last_name}`,
      });
    }

    if (medical_speciality) {
      listItem.push({
        label: "Especialidad médica",
        value: medical_speciality,
      });
    }

    if (procedure_type) {
      listItem.push({
        label: "Tipo de procedimientoa",
        value: procedure_type,
      });
    }

    if (procedure_to_perform || other_procedure) {
      listItem.push({
        label: "Procedimiento",
        value: getProcedureName(procedure_to_perform, other_procedure),
      });
    }

    if (appointment_time && appointment_date) {
      listItem.push({
        label: "Fecha de la cita",
        value: convertDateTimeToLocal(appointment_date, appointment_time),
      });
    }

    return (
      <Box className={css.container}>
        <Typography
          textAlign="left"
          fontFamily={URBANIST_FONT.BOLD}
          fontSize={28}
          color={customColor.cotton}
          marginBottom={2}
        >
          Información del paciente
        </Typography>
        {listItem?.map((item) => {
          return (
            <Box className="row" key={item.label}>
              <Box className="title">{item.label}:</Box>
              <Box className="desc">{item.value}</Box>
            </Box>
          );
        })}
      </Box>
    );
  }
  return null;
};

export default DoctorDetails;
