import { OTHER_OPTION } from "../../constants/generals";

export const getProcedureName = (list, otherName = "") => {
  if (list && Array.isArray(list)) {
    const index = list?.indexOf(OTHER_OPTION);
    if (index > -1) {
      list.splice(index, 1);
      if (otherName) {
        list.push(otherName);
      }
    }
    return list.join(", ");
  }

  if (list && typeof list === "string") {
    return `${list}, ${otherName}`;
  }
  return otherName || "";
};
