import { createAsyncThunk } from "@reduxjs/toolkit";

import authApi from "../../../apis/auth";
import authEndPoint from "../../../apis/auth/config";
import authEndPointV2 from "../../../apis/auth/configV2";
import { errorToast, successToast } from "../../../utils/toast";
import { API_STATUS } from "../../../constants/common";
import { logout } from "../../../utils/auth";
import {
  ILoginArgs,
  IRequestResetEmailArgs,
  IPasswordResetArgs,
  IPasswordResetCompleteArgs,
} from "./index";
import medsiV2Auth from "../../../../src/apis/auth/configV2";

export const getLogin = createAsyncThunk(
  authEndPoint.getToken,
  async (arg: ILoginArgs) => {
    const { email, password } = arg;
    const { data } = await authApi.login({
      email,
      password,
    });

    return data;
  }
);

export const saveUserRegisterInfo = createAsyncThunk(
  authEndPoint.register,
  async (payload) => {
    const { data } = await authApi.register(payload);
    return data;
  }
);

export const requestResetEmail = createAsyncThunk(
  authEndPoint.requestResetEmail,
  async (arg: IRequestResetEmailArgs) => {
    const { email } = arg;
    const { data } = await authApi.requestResetEmail({
      email,
    });
    return data;
  }
);

export const passwordReset = createAsyncThunk(
  authEndPoint.passwordReset,
  async (arg: IPasswordResetArgs) => {
    const { uuid, token } = arg;
    const { data } = await authApi.passwordReset({
      uuid,
      token,
    });
    return data;
  }
);

export const passwordResetComplete = createAsyncThunk(
  authEndPoint.passwordResetComplete,
  async (arg: IPasswordResetCompleteArgs) => {
    const { password, uuid, token } = arg;
    const { data } = await authApi.passwordResetComplete({
      password,
      uuid,
      token,
    });
    return data;
  }
);

export const emailVerify = createAsyncThunk(
  authEndPoint.emailVerify,
  async (token: string) => {
    const { data } = await authApi.emailVerify({
      token,
    });
    return data;
  }
);

export const getOnboardingSteps = createAsyncThunk(
  authEndPoint.getOnboardingSteps,
  async () => {
    try {
      const { data } = await authApi.getOnboardingSteps();
      return data;
    } catch (err) {
      return {
        data: {
          status: "error",
        },
      };
    }
  }
);

export const userLogout = createAsyncThunk(
  authEndPoint.logout,
  async (refresh: string) => {
    const { data } = await authApi.logout({
      refresh,
    });
    logout();
    return data;
  }
);

export const updateOnboardingSteps = createAsyncThunk(
  `${authEndPoint.getOnboardingSteps}`,
  async (payload) => {
    const { data } = await authApi.getUnboardinglatestStep();
    return data;
  }
);

export const resendEmailConfirmation = createAsyncThunk(
  `${authEndPoint.resendConfirmationMail}/post`,
  async (email: string) => {
    const { data } = await authApi.resendEmailConfirmation({ email });
    const { status, meta } = data;
    if (status === API_STATUS.SUCCESS) {
      successToast("Correo electrónico de verificación enviado con éxito!");
    } else {
      errorToast(meta?.errorMessage);
    }
    return data;
  }
);

export const updateDoctorConfirmation = createAsyncThunk(
  `${authEndPoint.doctorConfirmation}/patch`,
  async ({ token, appId }: { token: string; appId: string }) => {
    const { data } = await authApi.updateDoctorConfirmation({ token, appId });
    return data;
  }
);
export const applicationDetailsThroughQR = createAsyncThunk(
  `${authEndPoint.qrApplicationDetais}/patch`,
  async ({ user_id, appId }: { user_id: string; appId: string }) => {
    const { data } = await authApi.applicationDetailsThroughQR({
      user_id,
      appId,
    });
    return data;
  }
);

export const saveUserFeedBack = createAsyncThunk(
  `${authEndPoint.userFeedBack}/post`,
  async (payload: string) => {
    const { data } = await authApi.saveUserFeedBack(payload);
    return data;
  }
);

export const saveDoctorBankDetails = createAsyncThunk(
  `${authEndPoint.doctorBankDetails}/patch`,
  async (payload: string) => {
    const { data } = await authApi.saveDoctorBankDetails(payload);
    return data;
  }
);

export const verifyPassword = createAsyncThunk(
  `${authEndPoint.verifyPassword}/post`,
  async (payload: string) => {
    try {
      const { data } = await authApi.verifyPassword(payload);
      return data;
    } catch (err) {
      return null;
    }
  }
);

export const sendOTP = createAsyncThunk(
  `${authEndPointV2.sendOtp}/post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }): Promise<any> => {
    try {
      const { data } = await authApi.sendOTP(payload);
      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resendOTP = createAsyncThunk(
  `${authEndPointV2.resendOtp}/post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }): Promise<any> => {
    try {
      const { data } = await authApi.resendOTP(payload);
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyOTP = createAsyncThunk(
  `${authEndPointV2.verifyOtp}post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }): Promise<any> => {
    try {
      const { data } = await authApi.verifyOTP(payload);
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const loginVerifyOTP = createAsyncThunk(
  `login/${authEndPointV2.verifyOtp}post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }): Promise<any> => {
    try {
      const { data } = await authApi.verifyOTP(payload);
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const sendOTPWithAuth = createAsyncThunk(
  `${authEndPointV2.sendOtpWithAuth}/post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await authApi.sendOTPWithAuth(payload);
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const resendOTPWithAuth = createAsyncThunk(
  `${authEndPointV2.resendOtpWithAuth}/post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await authApi.resendOTPWithAuth(payload);
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const verifyOTPWithAuth = createAsyncThunk(
  `${authEndPointV2.verifyOtpWithAuth}post`,
  async (payload: any, { fulfillWithValue, rejectWithValue }) => {
    try {
      const { data } = await authApi.verifyOTPWithAuth(payload);
      return fulfillWithValue(data);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
