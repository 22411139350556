import { makeStyles } from '@mui/styles';

const SignaturePadStyles = makeStyles((theme) => ({
  signature: {
    border: '1px solid black',
    width: '100%',
    height: 'calc(100% - 84px)',
    background: '#fff',
  },
  boxSignature: {
    background: '#fff',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: 350,
    height: 200,
    borderRadius: "20px",
    [theme.breakpoints.down(601)]: {
      width: 200,
      height: 100,
    },
  },
  imageSignature: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  textSignature: {
    // color: theme.palette.primary.gray,
    color: '#808080',
    [theme.breakpoints.down(601)]: {
      fontSize: '14px !important',
    },
  },
  buttonClose: {
    float: 'right',
  },
  buttonClean: {
    width: '40%',
    marginTop: '-5px !important',
  },
  buttonSave: {
    width: '40%',
    marginTop: '-5px !important',
    float: 'right',
  },
}));

export default SignaturePadStyles;
